/* eslint-disable */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Checkbox from 'apollo-react/components/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Search from 'apollo-react/components/Search';
import Divider from 'apollo-react/components/Divider';
import Modal from 'apollo-react/components/Modal/Modal';
import IconButton from 'apollo-react/components/IconButton';
import Tooltip from 'apollo-react/components/Tooltip';
import Trash from 'apollo-react-icons/Trash';
import CloseCircle from 'apollo-react-icons/CloseCircle';
import { makeStyles } from '@mui/styles';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import { useSelector } from 'react-redux';
import { GetVariableMappingRules } from 'Redux/Service/MasteringRuleService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { SaveVariableMappingRules } from 'Redux/Service/MasteringRuleService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import SearchFields from '../Components/DomainRules/SearchFields';
import { DomainFiltersMiddlePanel } from '../Components/DomainRulesComponents';
import Footer from 'Components/Footer';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import { generateErrorString, getHtmlString, uuidv4 } from 'Utils';
import { methodsAndConstantsMapping } from '../../MethodandConstantMapping';
import _ from 'lodash';

import validate from 'Pages/Validator/validate';
import ERROR from 'Pages/Validator/messages';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { BulkEditContext } from 'Layout/BulkEditLayout/BulkEditLayout';
import { GetProductDataSourcesByMappingRuleVersionID } from 'Redux/Service/RuleEditorService';
import { referenceDataService } from 'Services/ReferenceData.Service';
import Cookies from 'js-cookie';
import CircularProgress from 'apollo-react/components/CircularProgress';
import { LoaderOverlay } from '../Components/LoaderOverlay';
import { GetAutoMappings } from 'Redux/Service/AutoMappingService';
import AutoMappingPopOver from '../Components/DomainRules/AutoMappingPopOver';
import StatusCheck from 'apollo-react-icons/StatusCheck';

const useStyles = makeStyles({
  greyTextColor: {
    color: '#787878'
  },
  accordianRoot: {
    minHeight: '48px !important'
  },
  accordionIcon: {
    top: 0,
    padding: '5px'
  },
  accordionContent: {
    margin: '3px 0px !important'
  },
  panelTitle: {
    fontWeight: 500,
    lineHeight: '30px',
    fontSize: '20px'
  },
  variableListTitle: {
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px'
  }
});

const BulkEditScreen = ({ getVariableMappingRules }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();
  const [serviceResult, setServiceResult] = useState({});

  useEffect(() => {
    (async () => {
      const selectedDataSourceData = await dispatch(
        GetProductDataSourcesByMappingRuleVersionID(mappingRuleVersionID)
      ).then(unwrapResult);
      if (
        selectedDataSourceData &&
        selectedDataSourceData.data &&
        selectedDataSourceData.data.success
      ) {
        let result = await referenceDataService(selectedDataSourceData.data.ruleStudyLibrary);
        setServiceResult(result);
      }
    })();
  }, []);

  const contextData = useContext(BulkEditContext);
  const rowDataForRuleEditor = useSelector(
    (state) => state.DataProductStudyLibrary.rowDataForRuleEditor
  );
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const {
    bulkEditDomain,
    handleVariableUpdate,
    sourceFormItems,
    methodsConstants,
    selectedFilterNameInBulkScreen,
    bulkEditFilters,
    codeListDataMapping,
    domainCode,
    formatedFormItemData,
    bulkEditDomainInitial,
    isVariablesSame,
    setIsVariablesSame,
    autoMappingConfig
  } = contextData;

  const [showDoNotMap, setShowDoNotMap] = useState('showAll');
  const [showAutoMappingRerunModal, setShowAutoMappingRerunModal] = useState(false);
  const [autoMapping, setAutoMapping] = useState({
    isLoading: false,
    predictions: {}
  });

  const [caretPosition, setCaretPosition] = useState({ position: 0, variable: '', inputIndex: -1 });
  const [variableSearch, setVariableSearch] = useState('');
  const [showDomainNameTooltip, setShowDomainNameTooltip] = useState(false);
  const [variables, setVariables] = useState([]);
  const [expressionToDeleteDetails, setExpressionToDeleteDetails] = useState({
    variable: '',
    expressionIndex: -1
  });
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);
  const [showDeleteExpressionModal, setShowDeleteExpressionModal] = useState(false);
  const [showSearchFieldsModal, setShowSearchFieldsModal] = useState(false);
  const [mappingRules, setMappingRules] = useState();
  const [allItems, setAllItems] = useState([]);
  const [allForms, setAllForms] = useState([]);
  const [formItemMapping, setFormItemMapping] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { state = {} } = useLocation();
  const { nodeId } = state;

  const showDoNotMapMenu = [
    { value: 'showAll', label: 'Show All' },
    { value: 'showDoNotMapOnly', label: 'Show only Variables Set to Do Not Map' }
  ];

  useEffect(() => {
    const prevVariables = JSON.parse(
      JSON.stringify(bulkEditFilters[selectedFilterNameInBulkScreen].variables)
    );
    const savedBulkEditDomain = { ...bulkEditDomain };
    for (let domain in savedBulkEditDomain) {
      delete savedBulkEditDomain[domain].autoNoMap;
    }

    if (!_.isEmpty(prevVariables)) {
      const isSame = _.isEqual(savedBulkEditDomain, bulkEditDomainInitial);
      setIsVariablesSame(isSame);
    } else setIsVariablesSame(false);
  }, [bulkEditDomain, bulkEditDomainInitial]);

  useEffect(() => {
    // get the rules for the domain
    (async function () {
      if (!mappingRules) {
        const MappingRules = await dispatch(
          GetVariableMappingRules({
            mappedDomains: [domainCode],
            mappingRuleVersionID
          })
        ).then(unwrapResult);
        if (MappingRules && MappingRules.data && MappingRules.data.success) {
          const itemMappingRules = MappingRules.data.itemMappingRules;
          const rules = {};

          for (let item of itemMappingRules) {
            if (!(item.rowName in rules)) {
              rules[item.rowName] = {
                defaultForm: item.defaultForm,
                sourceName: item.sourceName,
                active: item.isMapRowActive
              };
            }
          }
          setMappingRules(rules);
        }
      }
    })();
  }, [mappingRules]);

  useEffect(() => {
    if (mappingRules) {
      const sourceName =
        mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName]?.sourceName;
      const active =
        mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName]?.active;
      const vb = Object.entries(bulkEditDomain);
      vb.forEach(([key, data]) => {
        if (data.disable && !data.expressions.length) {
          handleVariableUpdate('ADD_INPUT_FIELD', key, sourceName, active);
        }
      });
    }
  }, [mappingRules, bulkEditDomain, selectedFilterNameInBulkScreen, bulkEditFilters]);

  useEffect(() => {
    let arr = [];
    let mapping = {};
    const keys = Object.keys(sourceFormItems);
    setAllForms(keys);
    keys.forEach((key) => {
      mapping[key] = sourceFormItems[key].items?.map((item) => item.srcItemName) || [];
      arr.push(...sourceFormItems[key].items);
    });
    setAllItems(arr);
    setFormItemMapping(mapping);
  }, [sourceFormItems]);

  // useEffect for variable search functionality
  useEffect(() => {
    let data = Object.entries(bulkEditDomain);

    if (showDoNotMap === 'showDoNotMapOnly') {
      data = data.filter(([, data]) => data.noMap);
    }

    if (variableSearch) {
      data = data.filter(([v]) => v.includes(variableSearch));
    }

    let suppqualData = [];
    let notSuppqualData = [];

    data.forEach((item) => {
      if (item && item[1] && item[1].isSUPPQUAL) {
        suppqualData.push({ variableName: item[0], ...item[1] });
      } else {
        notSuppqualData.push({ variableName: item[0], ...item[1] });
      }
    });

    suppqualData = suppqualData.sort(
      (variable1, variable2) => variable1?.seqForOrder - variable2?.seqForOrder
    );
    notSuppqualData = notSuppqualData.sort(
      (variable1, variable2) => variable1?.seqForOrder - variable2?.seqForOrder
    );

    data = [...notSuppqualData, ...suppqualData];
    data = data.map((item) => {
      const { variableName, ...rest } = item;
      return [variableName, rest];
    });

    setVariables(data);
  }, [bulkEditDomain, showDoNotMap, variableSearch]);

  const validateRuleExpression = (inputExp) => {
    let result = {
      isValid: true,
      message: '',
      cordinates: { start: 0, end: inputExp.length }
    };

    result = validate(
      inputExp,
      bulkEditFilters[selectedFilterNameInBulkScreen].defaultForm,
      codeListDataMapping,
      formItemMapping,
      serviceResult
    );
    return result;
  };

  const handleLeaveModal = () => {
    setShowLeavePageModal((prev) => !prev);
  };

  const handleDeleteExpressionModal = () => {
    setShowDeleteExpressionModal((prev) => !prev);
  };

  const handleAutoMappingRerunModal = () => {
    setShowAutoMappingRerunModal((prev) => !prev);
  };

  const handleReturnButton = () => {
    console.log('############################33', { domainCode, nodeId });
    navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/domain-rules`, {
      state: {
        domainCode,
        nodeId
      }
    });
  };

  const handleDeleteExpression = (variable, expressionIndex) => {
    setCaretPosition({ position: 0, variable: '', inputIndex: -1 });
    setExpressionToDeleteDetails({ variable, expressionIndex });
    handleDeleteExpressionModal();
  };

  const getVariableStructure = ({ variableName, expression, sourceName, isSuppQual }) => {
    const date = new Date().toISOString();
    const filterName = bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName;
    const defaultForm = bulkEditFilters[selectedFilterNameInBulkScreen].defaultForm;
    const defaultSourceName = bulkEditFilters[selectedFilterNameInBulkScreen].sourceName;
    const rowSeq = bulkEditFilters[selectedFilterNameInBulkScreen].rowSeq;

    return {
      iqCreateDate: date,
      iqUpdateDate: date,
      iqAuditDate: date,
      iqCreatedBy: userId,
      iqUpdatedBy: userId,
      iqAuditType: 'INSERT',
      iqActiveFlag: true,
      protocolNumber: protocol.protocolNumber,
      mappingRuleVersionId: mappingRuleVersionID,
      variableName,
      rowName: filterName,
      domainCode: domainCode,
      ruleSeq: 0,
      expression,
      defaultForm: defaultForm,
      sourceName: sourceName || defaultSourceName,
      sourceSYSID: 0,
      concatenate: 'N',
      rowSeq,
      isSuppqual: isSuppQual ? 'Y' : 'N',
      isMapRowActive: true
    };
  };

  const handleSelect = (e, type) => {
    const { position, variable, inputIndex } = caretPosition;

    if (!variable || inputIndex === -1) return;

    if (type === 'SOURCE_FIELD' && e.target.value === 'Search fields') {
      setShowSearchFieldsModal(true);
      return;
    }

    let lastSelectedValue = bulkEditDomain[variable].expressions[inputIndex].expression;

    lastSelectedValue = lastSelectedValue.split(''); // split the string

    if (type === 'SOURCE_FIELD') {
      const defaultForm = bulkEditFilters[selectedFilterNameInBulkScreen].defaultForm;
      const value = `[${defaultForm}].[${e.target.value}]`.split('');
      lastSelectedValue.splice(position, 0, ...value); // append the source field value in between
    } else if (type === 'METHOD_CONSTANT') {
      const value = methodsAndConstantsMapping[e.target.value].split('');
      lastSelectedValue.splice(position, 0, ...value); // append the method or constant value in between
    } else {
      return;
    }

    lastSelectedValue = lastSelectedValue.join(''); // join back to string
    setCaretPosition((prev) => ({ ...prev, position: lastSelectedValue.length }));
    handleVariableUpdate(
      'TYPE_INPUT_FIELD',
      variable,
      mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName]?.sourceName,
      mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName]?.active,
      inputIndex,
      lastSelectedValue
    );
  };

  const handleAddBySearchField = (formName, itemName, sourceName) => {
    const { position, variable, inputIndex } = caretPosition;
    let lastSelectedValue = bulkEditDomain[variable].expressions[inputIndex].expression;

    const value = `[${formName}].[${itemName}]`.split('');

    lastSelectedValue = lastSelectedValue.split(''); // split the string
    lastSelectedValue.splice(position, 0, ...value); // append the source field value in between
    lastSelectedValue = lastSelectedValue.join(''); // join back to string

    setCaretPosition((prev) => ({ ...prev, position: lastSelectedValue.length }));
    handleVariableUpdate(
      'TYPE_INPUT_FIELD',
      variable,
      sourceName,
      mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName]?.active,
      inputIndex,
      lastSelectedValue
    );
    setShowSearchFieldsModal(false);
  };

  const handleCaretPositionChange = (pos, variable, inputIndex) => {
    setCaretPosition((prev) => ({
      ...prev,
      position: pos,
      variable,
      inputIndex
    }));
  };

  const handleSave = async () => {
    setErrors({});
    setIsLoading(true);
    const errors = {};
    try {
      let payload = { itemMappingRules: [] };
      for (let key in bulkEditDomain) {
        // if the variable is disable then it must have a expression
        const condition1 =
          bulkEditDomain[key].disable &&
          (!bulkEditDomain[key].expressions.length ||
            !bulkEditDomain[key].expressions[0].expression);
        if (condition1) {
          dispatch(
            showBanner({ variant: 'error', message: 'Disable variables must have expression' })
          );
          setIsLoading(false);
          return;
        }

        // if their is no nomap and either it don't have expressions or have expression but don't have value in expression
        const condition2 =
          !bulkEditDomain[key].noMap &&
          (!bulkEditDomain[key].expressions.length ||
            !bulkEditDomain[key].expressions[0].expression);
        if (condition2) {
          dispatch(
            showBanner({
              variant: 'error',
              message: 'Either check the NO MAP or provide atleast one expression'
            })
          );
          setIsLoading(false);
          return;
        }

        if (bulkEditDomain[key].noMap && !bulkEditDomain[key].disable) {
          payload.itemMappingRules.push(
            getVariableStructure({
              variableName: key,
              expression: '"$NOMAP"',
              isSuppQual: bulkEditDomain[key].isSUPPQUAL
            })
          );
        } else {
          bulkEditDomain[key].expressions.forEach((exp, i) => {
            let result = {
              isValid: true,
              message: '',
              cordinates: { start: 0, end: exp.length - 1 }
            };
            if (!bulkEditDomain[key].disable) {
              if (exp.expression.toLowerCase() === '"$sysmap"') {
                result.isValid = false;
                result.message = ERROR.USER_DEFINED_VARIABLE;
              }
            }

            if (result.isValid) {
              result = validateRuleExpression(exp.expression);
            }

            if (!result.isValid) {
              errors[`${key}-${i}`] = result;
            }
            payload.itemMappingRules.push(
              getVariableStructure({
                variableName: key,
                expression: exp.expression.trim(),
                sourceName: exp.sourceName,
                isSuppQual: bulkEditDomain[key].isSUPPQUAL
              })
            );
          });
        }
      }

      if (Object.keys(errors).length) {
        setErrors(errors);
        setIsLoading(false);
        return;
      }

      const response = await dispatch(SaveVariableMappingRules(payload))
        .then(unwrapResult)
        .catch((error) => {
          dispatch(showBanner({ variant: 'error', message: 'Something went wrong' }));
          console.log('Error while SaveVariableMappingRules ::', error);
          setIsLoading(false);
          return error;
        });

      if (response && response?.data?.success) {
        dispatch(showBanner({ variant: 'success', message: 'Expression Saved Successfully' }));
        getVariableMappingRules(domainCode);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Error while saving', error);
    }
  };

  const getAccordianDetails = () => {
    const OPERATORS = { '"$OR"': 'OR', '"$AND"': 'AND' };
    const operator = bulkEditFilters[selectedFilterNameInBulkScreen]?.operator;
    const expressions = bulkEditFilters[selectedFilterNameInBulkScreen]?.expressions;
    const arr = [];
    expressions.forEach((exp, index) => {
      arr.push(exp.expression);
      if (index < expressions.length - 1) {
        arr.push(OPERATORS[operator]);
      }
    });
    return arr.map((exp, i) => <Typography key={i}>{exp}</Typography>);
  };

  const handleAddInputField = (variable, idx) => {
    setCaretPosition({ position: 0, variable, inputIndex: idx });
    handleVariableUpdate(
      'ADD_INPUT_FIELD',
      variable,
      mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName].sourceName,
      mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName].active
    );
  };

  const handleTrashDisable = (variable) => {
    if (variable.disable) {
      if (variable.expressions.length === 1) return true;
      return false;
    }

    return variable.noMap;
  };

  const sourceFormName = bulkEditFilters[selectedFilterNameInBulkScreen]?.defaultForm;
  const sourceFields = sourceFormItems[sourceFormName]?.items || [];
  const sourceFormOid = sourceFormItems[sourceFormName]?.srcFormOid || '';

  const [isShowAutoMapping, canEnableAutoMapping, autoMappingTooltipMessage] = useMemo(() => {
    const sourceFormName = bulkEditFilters[selectedFilterNameInBulkScreen]?.defaultForm;
    const isShowAutoMapping =
      Boolean(process.env.REACT_APP_AIAAS_API_URL) &&
      Boolean(process.env.REACT_APP_AIAAS_API_KEY) &&
      Boolean(autoMappingConfig.allowedTargets?.includes(rowDataForRuleEditor.targetDataModelType));

    const canEnableAutoMapping = Boolean(
      autoMappingConfig.allowedSources?.includes(sourceFormItems[sourceFormName]?.sourceName) &&
        autoMappingConfig.autoMappingDomains?.includes(domainCode)
    );

    let message = 'Automapping feature is not available for this Domain.';
    if (canEnableAutoMapping) {
      if (autoMapping.isLoading) message = 'Automapping is Running';
      else if (autoMapping.predictions[sourceFormName]) message = 'Automapping is Enabled';
      else
        message =
          'Automapping predicts most commonly used source items and expressions for each Domain Variable if available.';
    }
    return [isShowAutoMapping, canEnableAutoMapping, message];
  }, [
    autoMappingConfig,
    autoMapping,
    sourceFormItems,
    domainCode,
    bulkEditFilters,
    selectedFilterNameInBulkScreen,
    rowDataForRuleEditor
  ]);

  const refreshAutoMapping = async () => {
    let isLoading = true;
    let predictionForSourceForm = {};
    setAutoMapping((prev) => {
      delete prev.predictions[sourceFormName];
      return {
        ...prev,
        isLoading
      };
    });
    try {
      const payload = {
        PROTOCOL_NAME: protocol.protocolNumber,
        CUSTOMER: protocol.sponsor,
        THERAPEUTIC_AREA: protocol.therapeuticArea,
        TARGET_DOMAIN_CODE: domainCode,
        SRC_FORM_NAME: sourceFormName,
        SRC_FORM_OID: sourceFormOid,
        TRANSACTION_ID: uuidv4(),
        SRC_ITEMS: sourceFields.map((item) => ({
          SRC_SAS_FIELD_NAME: '',
          SRC_QUESTION_TEXT: '',
          SRC_ITEM_NAME: item.srcItemName,
          SRC_ITEM_OID: item.srcItemOid,
          SRC_CODELIST_NAME: ''
        }))
      };
      const apiResponse = await dispatch(GetAutoMappings(payload)).then(unwrapResult);
      if (apiResponse?.['PREDICTIONS']) {
        const response = JSON.parse(JSON.stringify(apiResponse));
        response['PREDICTIONS'].forEach((prediction) => {
          const validPredictions = [];
          prediction['ITEMS']?.forEach((item, index) => {
            /* Exculde No Expression predictions*/
            const isPredictionAvailable = Object.values(item['EXPR_PREDICTIONS'])?.some(
              (predExp) => Array.isArray(predExp) && predExp.length === 2
            );
            if (isPredictionAvailable) {
              validPredictions.push({
                SRC_ITEM: item[`SRC_ITEM${index + 1}`],
                EXPR_PREDICTIONS: item['EXPR_PREDICTIONS']
              });
            }
          });
          if (validPredictions?.length > 0) {
            prediction = { ...prediction, ['ITEMS']: validPredictions };
            predictionForSourceForm[prediction['TGT_VARIABLE_NAME']] = prediction;
          }
        });
        dispatch(
          showBanner({
            variant: 'success',
            message: 'AI-generated expressions are available now for some of the variables. '
          })
        );
      } else {
        dispatch(showBanner({ variant: 'error', message: 'Error while fetching auto mappings' }));
      }
      isLoading = false;
    } catch (error) {
      isLoading = false;
      predictionForSourceForm = {};
      dispatch(showBanner({ variant: 'error', message: 'Error while fetching auto mappings' }));
      console.log('Error while fetching automappings', error);
    }
    setAutoMapping((prev) => ({
      ...prev,
      isLoading,
      predictions: _.isEmpty(predictionForSourceForm)
        ? prev.predictions
        : { ...prev.predictions, [sourceFormName]: predictionForSourceForm }
    }));
  };

  const handleAutoMapSelection = (value, variable, index) => {
    handleVariableUpdate(
      'AUTOMAP_EXPRESSION_SELECT',
      variable,
      mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName].sourceName,
      mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName].active,
      index,
      value
    );
    setErrors((prev) => {
      const key = `${variable}-${index}`;
      delete prev[key];
      return { ...prev };
    });
  };
  return (
    <Box display="flex" alignItems="start" overflow="hidden">
      {isLoading && (
        <LoaderOverlay>
          <CircularProgress variant="indeterminate" size={51} />
        </LoaderOverlay>
      )}
      <DomainFiltersMiddlePanel />
      <Box boxSizing="border-box" pl={3} pr={3} overflow="auto" height="calc(100vh - 114px)">
        <Breadcrumb style={{ background: 'transparent', padding: '0.5rem 0rem' }} />
        <Grid container style={{ marginBottom: '1rem' }}>
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            <Button
              size="small"
              icon={ChevronLeft}
              style={{
                marginRight: 10,
                marginBottom: '10px',
                backgroundColor: 'rgba(35, 114, 253, 0.08)'
              }}
              onClick={handleLeaveModal}>
              Return to domain rules
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            <Typography variant="title1" style={{ marginBottom: '1rem' }}>
              Batch Edit
            </Typography>
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.greyTextColor}>Domain Name:</Typography>
                <Box display="flex" alignItems="center" jus>
                  {/* <Tooltip
                    open={showDomainNameTooltip}
                    variant="light"
                    title="Lorem ipsum"
                    placement="top">
                    <IconButton
                      onMouseEnter={() => setShowDomainNameTooltip(true)}
                      onMouseLeave={() => setShowDomainNameTooltip(false)}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip> */}
                  <Typography variant="body2" className={classes.greyTextColor}>
                    <b>{domainCode}</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.greyTextColor}>Product Mnemonic:</Typography>
                <Typography variant="body2" className={classes.greyTextColor}>
                  <b>{rowDataForRuleEditor.productNnemonic}</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.greyTextColor}>Description:</Typography>
                <Typography variant="body2" className={classes.greyTextColor}>
                  <b>{rowDataForRuleEditor.description}</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.greyTextColor}>Default Form:</Typography>
                <Typography variant="body2" className={classes.greyTextColor}>
                  <b>{bulkEditFilters[selectedFilterNameInBulkScreen]?.defaultForm}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              position: 'sticky',
              padding: '1rem 0rem 0rem 0rem',
              top: 0,
              left: 0,
              zIndex: 9,
              background: '#f5f6fa'
            }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <Grid item xs={6}>
                    <Typography className={classes.panelTitle}>Expressions Builder</Typography>
                  </Grid>
                  <Box display="flex" justifyContent="end">
                    <Button
                      variant="primary"
                      onClick={handleSave}
                      style={{ marginRight: '1rem' }}
                      disabled={isLoading || isVariablesSame}>
                      Save
                    </Button>
                    <Button variant="secondary" onClick={handleLeaveModal}>
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid container style={{ marginTop: '5px' }}>
                  <Grid item xs={12} style={{ marginBottom: '1rem', marginTop: '13px' }}>
                    <Accordion>
                      <AccordionSummary
                        classes={{
                          root: classes.accordianRoot,
                          expandIcon: classes.accordionIcon,
                          content: classes.accordionContent
                        }}
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ marginLeft: '30px', marginTop: '9px' }}>
                          Filter
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ flexDirection: 'column' }}>
                        {getAccordianDetails()}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex" alignItems="center">
                    <Search
                      style={{ margin: '0px', width: '250px' }}
                      placeholder="Search Variables"
                      onChange={(e) => setVariableSearch(e.target.value)}
                      value={variableSearch}
                    />
                  </Box>
                </Grid>
                <Grid item xs={9} display="flex" justifyContent="end">
                  <Box display="flex" alignItems="center" justifyContent="end">
                    {isShowAutoMapping && (
                      <Tooltip title={autoMappingTooltipMessage} placement="bottom">
                        <span>
                          <Button
                            variant="primary"
                            icon={
                              autoMapping.isLoading ? (
                                <CircularProgress
                                  variant="indeterminate"
                                  size={18}
                                  color="inherit"
                                  sx={{ marginRight: 0.4 }}
                                />
                              ) : (
                                autoMapping.predictions[sourceFormName] && <StatusCheck />
                              )
                            }
                            data-testid="enableautomap-btn"
                            disabled={!canEnableAutoMapping || autoMapping.isLoading}
                            style={{ marginTop: '5px', marginRight: '10px', lineHeight: 0 }}
                            onClick={() => {
                              autoMapping.predictions[sourceFormName]
                                ? handleAutoMappingRerunModal()
                                : refreshAutoMapping();
                            }}>
                            {autoMapping.predictions[sourceFormName] || autoMapping.isLoading
                              ? 'Automapping'
                              : 'Enable Automapping'}
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                    <Select
                      onChange={(e) => handleSelect(e, 'SOURCE_FIELD')}
                      placeholder={
                        <Typography style={{ color: '#0768fd', borderColor: '#787878' }}>
                          Select Source Field
                        </Typography>
                      }
                      style={{ marginBottom: 0, marginTop: 0, marginRight: '10px' }}>
                      {sourceFields.map((s) => (
                        <MenuItem value={s.srcItemName} key={s.srcItemName}>
                          {s.srcItemName}
                        </MenuItem>
                      ))}
                      <MenuItem value="Search fields">Search fields</MenuItem>
                    </Select>
                    <Select
                      onChange={(e) => handleSelect(e, 'METHOD_CONSTANT')}
                      placeholder={
                        <Typography style={{ color: '#0768fd', borderColor: '#787878' }}>
                          Add Method Constant
                        </Typography>
                      }
                      style={{ margin: 0 }}>
                      {methodsConstants
                        .filter((m) => m !== 'QCVSEQ()')
                        .map((m) => (
                          <MenuItem value={m} key={m}>
                            {m}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ width: '99%', marginTop: '1rem' }}>
              <Box
                style={{
                  padding: '0 1.5rem 0 1.5rem',
                  background: '#fff',
                  borderRadius: '10px 10px 0 0'
                }}
                fullWidth>
                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <Grid item xs={3}>
                    <Typography className={classes.variableListTitle}>
                      Variable List (20)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      id="showDoNotMapMenu"
                      name="showDoNotMapMenu"
                      data-testid="showDoNotMapMenu"
                      value={showDoNotMap}
                      onChange={(e) => setShowDoNotMap(e.target.value)}
                      fullWidth>
                      {showDoNotMapMenu.map((el) => (
                        <MenuItem value={el.value} key={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '1rem', width: '99%' }}>
            <Box
              style={{
                padding: '0 1.5rem 1.5rem 1.5rem',
                background: '#fff',
                borderRadius: '0 0 10px 10px'
              }}
              fullWidth>
              {variables.map(([variable, data]) => (
                <Grid item xs={12} key={variable}>
                  <Divider style={{ marginBottom: '10px' }} />
                  {/* data.disable means that variable is System */}
                  {data.disable && (
                    <Typography style={{ color: 'red' }}>
                      DO NOT MAP option is not allowed for system variables. A valid source item or
                      $SYSMAP should be used.
                    </Typography>
                  )}
                  <Grid container style={{ marginBottom: '1rem' }}>
                    <Grid item xs={3}>
                      <Box fullWidth>{data?.isSUPPQUAL ? `${variable}(Supp Qual)` : variable}</Box>
                      <Checkbox
                        label={<Typography style={{ fontSize: '13px' }}>Do not Map</Typography>}
                        checked={data.noMap}
                        onChange={() =>
                          handleVariableUpdate(
                            'TOGGLE_CHECKBOX',
                            variable,
                            mappingRules[
                              bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName
                            ].sourceName,
                            mappingRules[
                              bulkEditFilters[selectedFilterNameInBulkScreen]?.filterName
                            ].active
                          )
                        }
                        disabled={data.disable}
                        data-testid="donotmap-checkbox"
                      />
                      {autoMapping.predictions[sourceFormName]?.[variable] &&
                        (data.noMap || data.expressions.length === 0) && (
                          <Box style={{ marginLeft: '14px', marginBottom: '6px' }}>
                            <AutoMappingPopOver
                              predictions={
                                autoMapping.predictions[sourceFormName]?.[variable].ITEMS
                              }
                              onSelectHandler={(value) =>
                                handleAutoMapSelection(value, variable, 0)
                              }
                              popOverPosition={{
                                anchorOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                                },
                                transformOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                }
                              }}
                            />
                          </Box>
                        )}
                    </Grid>
                    <Grid item xs={10}>
                      {data.expressions.length ? (
                        <>
                          {data.expressions.map((exp, index) => (
                            <Box key={exp.id}>
                              <Grid container>
                                <b style={{ paddingTop: '10px', paddingRight: '10px' }}>
                                  {' '}
                                  {index + 1}
                                </b>
                                <Grid item xs={10}>
                                  <CustomeTextField
                                    disabled={data.disable ? false : data.noMap}
                                    fullWidth
                                    placeholder="Placeholder"
                                    onCaretPositionchange={(pos) =>
                                      handleCaretPositionChange(pos, variable, index)
                                    }
                                    onChange={(value) => {
                                      handleVariableUpdate(
                                        'TYPE_INPUT_FIELD',
                                        variable,
                                        mappingRules[
                                          bulkEditFilters[selectedFilterNameInBulkScreen]
                                            ?.filterName
                                        ].sourceName,
                                        mappingRules[
                                          bulkEditFilters[selectedFilterNameInBulkScreen]
                                            ?.filterName
                                        ].active,
                                        index,
                                        value
                                      );
                                      setErrors((prev) => {
                                        const key = `${variable}-${index}`;
                                        delete prev[key];
                                        return { ...prev };
                                      });
                                    }}
                                    error={Boolean(errors[`${variable}-${index}`])}
                                    helperText={errors[`${variable}-${index}`]?.message}>
                                    {errors[`${variable}-${index}`]
                                      ? generateErrorString(
                                          exp.expression,
                                          errors[`${variable}-${index}`]?.cordinates?.start,
                                          errors[`${variable}-${index}`]?.cordinates?.end
                                        )
                                      : getHtmlString(
                                          exp.expression,
                                          formatedFormItemData,
                                          bulkEditFilters[selectedFilterNameInBulkScreen]
                                            ?.defaultForm
                                        )}
                                  </CustomeTextField>
                                  {/* <TextField
                                    disabled={data.disable ? false : data.noMap}
                                    fullWidth
                                    placeholder="Placeholder"
                                    style={{ margin: 0 }}
                                    value={exp.expression}
                                    // onBlur={(e) => handleCaretPositionChange(e, variable, index)}
                                    onChange={(e) =>
                                      handleVariableUpdate(
                                        'TYPE_INPUT_FIELD',
                                        variable,
                                        mappingRules[
                                          bulkEditFilters[selectedFilterNameInBulkScreen]
                                            ?.filterName
                                        ].sourceName,
                                        mappingRules[
                                          bulkEditFilters[selectedFilterNameInBulkScreen]
                                            ?.filterName
                                        ].active,
                                        index,
                                        e.target.value
                                      )
                                    }
                                    error={Boolean(errors[`${variable}-${index}`])}
                                    helperText={errors[`${variable}-${index}`]}
                                  /> */}
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    display: 'flex',
                                    marginLeft: '2px'
                                  }}>
                                  {autoMapping.predictions[sourceFormName]?.[variable] &&
                                    !data.noMap && (
                                      <AutoMappingPopOver
                                        predictions={
                                          autoMapping.predictions[sourceFormName]?.[variable].ITEMS
                                        }
                                        onSelectHandler={(value) =>
                                          handleAutoMapSelection(value, variable, index)
                                        }
                                      />
                                    )}
                                  <IconButton
                                    disabled={handleTrashDisable(data)}
                                    onClick={() => handleDeleteExpression(variable, index)}
                                    style={{ cursor: 'pointer' }}
                                    data-testid={`${variable}-dltExprn-${index + 1}`}>
                                    <Trash />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column">
                          {data.disable ? (
                            <Typography variant="title2">
                              Mandatory variables Must contains Expression
                            </Typography>
                          ) : (
                            <>
                              <Typography
                                variant="title2"
                                style={{ color: '#787878', fontSize: '14px' }}>
                                Uncheck <b> &quot;Do Not Map&quot;</b> to create an
                              </Typography>
                              <Typography
                                variant="title2"
                                style={{ color: '#787878', fontSize: '14px' }}>
                                Expression for this Domain Variable
                              </Typography>
                            </>
                          )}
                        </Box>
                      )}
                      <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                        <IconButton
                          color="primary"
                          disabled={data.disable ? false : data.noMap}
                          style={{ transform: 'rotate(45deg)', color: '#0768fd' }}
                          onClick={() => handleAddInputField(variable, data.expressions.length)}
                          data-testid={`${variable}-add-input-field`}>
                          <CloseCircle />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Footer />
        {/* Modal For Back and cancel */}
        <Modal
          open={showLeavePageModal}
          variant="warning"
          onClose={handleLeaveModal}
          title="Are you sure you want to leave this page?"
          message="Your progress will not be saved, Would you still like to leave?"
          buttonProps={[
            { label: 'Cancel', onClick: handleLeaveModal },
            { label: 'Leave', onClick: handleReturnButton }
          ]}
          id="warning"
        />

        {/* Modal For Delete Expression */}
        <Modal
          open={showDeleteExpressionModal}
          variant="warning"
          onClose={handleDeleteExpressionModal}
          title="Delete Expression?"
          message="Are you sure you want to delete this expression?"
          buttonProps={[
            { label: 'Cancel', onClick: handleDeleteExpressionModal },
            {
              label: 'Delete',
              onClick: () => {
                handleVariableUpdate(
                  'DELETE_INPUT_FIELD',
                  expressionToDeleteDetails.variable,
                  mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen].filterName]
                    ?.sourceName,
                  mappingRules[bulkEditFilters[selectedFilterNameInBulkScreen].filterName]?.active,
                  expressionToDeleteDetails.expressionIndex
                );
                handleDeleteExpressionModal();
                dispatch(
                  showBanner({ variant: 'success', message: 'Expression deleted Successfully' })
                );
              }
            }
          ]}
          id="warning"
        />
        {/* Modal for Auto Mapping re run */}
        <Modal
          open={showAutoMappingRerunModal}
          variant="warning"
          onClose={handleAutoMappingRerunModal}
          title="Rerun Automapping"
          message="Are you sure you want to rerun automapping?"
          buttonProps={[
            { label: 'Cancel', onClick: handleAutoMappingRerunModal },
            {
              label: 'Yes, Continue',
              onClick: () => {
                refreshAutoMapping();
                handleAutoMappingRerunModal();
              }
            }
          ]}
          id="warning"
        />
        {showSearchFieldsModal && (
          <SearchFields
            sourceFormItems={sourceFormItems}
            onAdd={handleAddBySearchField}
            onCancel={() => setShowSearchFieldsModal(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default BulkEditScreen;
