import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetExportDomainsAndColumns = createAsyncThunk(
  'DataExport/GetExportDomainsAndColumns',
  (payload) => {
    return axios.post(`${API_URL}/api/Publish/GetExportDomainsAndColumns`, payload);
  }
);

export const GetExportTypes = createAsyncThunk('DataExport/GetexportTypes', async () => {
  return await axios.get(`${API_URL}/api/Publish/GetexportTypes`);
});

export const GetExportScheduleTypes = createAsyncThunk(
  'DataExport/GetExportScheduleTypes',
  async () => {
    return await axios.get(`${API_URL}/api/Publish/GetExportScheduleTypes`);
  }
);

export const GetExportPreferencesList = createAsyncThunk(
  'DataExport/GetExportPreferencesList',
  async (dataProductId) => {
    return await axios.get(
      `${API_URL}/api/Publish/GetExportPreferencesList?DataProductId=${dataProductId}`
    );
  }
);

export const GetSelectedPreferencesData = createAsyncThunk(
  'DataExport/GetSelectedPreferencesData',
  async (preferenceId) => {
    return await axios.get(
      `${API_URL}/api/Publish/GetSelectedPreferencesData?PreferenceId=${preferenceId}`
    );
  }
);

export const SaveSelectedPreferencesData = createAsyncThunk(
  'DataExport/SaveSelectedPreferencesData',
  (payload) => {
    return axios.post(`${API_URL}/api/Publish/SaveSelectedPreferencesData`, payload);
  }
);