/* eslint-disable no-unused-vars */
import validate from 'Pages/Validator/validate';
import { referenceDataService } from 'Services/ReferenceData.Service';
import { ErrorConstants } from './ErrorConstants';
const {
  numberError,
  ctpSvtUniqueError,
  visitTypeNameError,
  mandatoryError,
  yesNoValueError,
  blankOrFilled,
  nameAndNumberMapError,
  visitNameSeqNumError,
  ctpVisitNameError
} = ErrorConstants;

const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};
const mergeCordinates = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

const isEmptyString = (value) => {
  if (value === '' || value === null || value === undefined) {
    return true;
  }
  return false;
};

const isYesOrNo = (value) => {
  if (value !== undefined && value !== null && (value.trim() === 'N' || value.trim() === 'Y')) {
    return true;
  }
  return false;
};
const checkIsYesOrNo = (record) => {
  const { cmpltd_flg, cascade_flg, interface: _interface } = record;
  let obj = {};
  if (!isYesOrNo(cmpltd_flg)) {
    obj.cmpltd_flg = { yesNo: yesNoValueError };
  }
  if (!isYesOrNo(cascade_flg)) {
    obj.cascade_flg = { yesNo: yesNoValueError };
  }
  if (!isYesOrNo(_interface) && !isEmptyString(_interface)) {
    obj.interface = { yesNo: yesNoValueError };
  }
  return obj;
};
const isNumberAndGreaterThanZero = (value) => {
  if (!isEmptyString(value) && !isNaN(value) && value > 0) {
    return true;
  }
  return false;
};

const checkIsNumber = (record) => {
  const { visitnum } = record;
  let obj = {};

  if (!isNumberAndGreaterThanZero(visitnum)) {
    obj.visitnum = { number: numberError };
  }
  return obj;
};
const checkVisitType = (record, visitType) => {
  let obj = {};
  let isValidVisitType = true;
  let isValidVisitName = true;
  isValidVisitType = visitType.includes(record.visittype.trim());
  if (!isValidVisitType) {
    obj.visittype = { visittype: visitTypeNameError };
  }
  return obj;
};

const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);

const truncateSpace = (x) => {
  return x.replace(/ +(?= )/g, '').trim();
};

const truncateNumber = (x) => {
  let strings = x.split(' ');
  if (isNaN(strings[strings.length - 1].trim())) return x;
  strings.pop();
  return strings.join(' ');
};

const toLowercase = (seqName) => {
  return seqName.toLowerCase().trim();
};

const replaceSeqNumWithRegex = (seqName) => {
  if (seqName.toLowerCase().indexOf('$seqnum') !== -1) {
    let seqnumRegex = /\$seqnum\s*\(\s*(\d+)\s*(,\s*\d+)\s*(,\s*\d+|)\s*\)/i;
    if (seqName.match(seqnumRegex)) {
      return seqName.replace(seqnumRegex, '\\d*');
    }
    return seqName;
  }
  return seqName;
};

const isSeqNumValid = (e) => {
  if (e.toLowerCase().indexOf('$seqnum') !== -1) {
    let seqnumRegex = /\$seqnum\s*\(\s*(\d+)\s*(,\s*\d+)\s*(,\s*\d+|)\s*\)/i;
    if (e.toLowerCase().match(seqnumRegex)) {
      let args = /\(\s*([^)]+?)\s*\)/.exec(e);
      if (args[1]) {
        args = args[1].split(/\s*,\s*/);
        if (args.length === 3 && parseInt(args[2]) > parseInt(args[0])) return true;
        else if (args.length == 2) return true;
        return false;
      }
      return false;
    }
    return false;
  }
  return true;
};

const isSeqNumExists = (e) => {
  if (e.toLowerCase().indexOf('$seqnum') !== -1) {
    return true;
  }
  return false;
};

const isVisitNameExists = (e, visitName) => {
  return (
    visitName.findIndex((arg) => {
      return arg.match(e);
    }) !== -1
  );
};

const getVisitNameAfterFormatting = pipe(toLowercase, truncateSpace);

const getVisitNameWithTruncateSeqNum = pipe(truncateSpace, toLowercase, replaceSeqNumWithRegex);

const validateEdcDefaultForm = (record, forms) => {
  let obj = {};
  if (record.edcdefaultform && !forms.includes(record.edcdefaultform)) {
    obj.edcdefaultform = { edcdefaultform: 'INVALID FORM NAME' };
  }
  return obj;
};

const validateCdrDefaultForm = (record, forms) => {
  let obj = {};
  if (record.cdrdefaultform && !forms.includes(record.cdrdefaultform)) {
    obj.cdrdefaultform = { cdrdefaultform: 'INVALID FORM NAME' };
  }
  return obj;
};

const checkFilterCondition = (record, codeListDataMapping, serviceResult, formsObj, cdrTabular) => {
  let { edcdefaultform, edccondition, cdrcondition, cdrdefaultform } = record;
  let filtercondtion = {};
  let expressionResult = { isValid: true };
  let cdrExpressionResult = { isValid: true };
  let errorCordinatesObj = {};
  let cdrErrorCordinates = {};
  if (edccondition) {
    edccondition = `IF ${edccondition} THEN "TRUE"`;
    expressionResult = validate(
      edccondition,
      edcdefaultform,
      codeListDataMapping,
      formsObj,
      serviceResult
    );
  }
  if (cdrcondition) {
    cdrcondition = `IF ${cdrcondition} THEN "TRUE"`;
    cdrExpressionResult = validate(
      cdrcondition,
      cdrdefaultform,
      codeListDataMapping,
      cdrTabular,
      serviceResult
    );
  }

  if (!expressionResult.isValid) {
    filtercondtion.edccondition = { edccondition: expressionResult.message };
    errorCordinatesObj.edccondition = { cordinates: expressionResult.cordinates };
  }
  if (!cdrExpressionResult.isValid) {
    filtercondtion.cdrcondition = { cdrcondition: cdrExpressionResult.message };
    cdrErrorCordinates.cdrcondition = { cordinates: cdrExpressionResult.cordinates };
  }

  return { filtercondtion, errorCordinatesObj, cdrErrorCordinates };
};

const checkMandatory = (record, flag) => {
  const { visittype, visitname, cmpltd_flg, cascade_flg, visitnum, interface: _interface } = record;
  let obj = {};
  if (!visittype) {
    obj.visittype = { mandatory: mandatoryError };
  }
  if (!visitnum) {
    obj.visitnum = { mandatory: mandatoryError };
  }
  if (!visitname) {
    obj.visitname = { mandatory: mandatoryError };
  }
  if (!cmpltd_flg) {
    obj.cmpltd_flg = { mandatory: mandatoryError };
  }
  if (!cascade_flg) {
    obj.cascade_flg = { mandatory: mandatoryError };
  }
  if (!_interface) {
    obj.interface = { mandatory: mandatoryError };
  }
  return obj;
};

export const ctpSvtValidator = async (
  records,
  selectedDataSourcesData,
  ctmsVisitsData,
  codeListDataMapping,
  formsObj,
  cdrTabular,
  isCtmsConfigured
) => {
  let serviceResult = await referenceDataService(selectedDataSourcesData);

  let errorColumnCount = 0;
  let errorRowsId = [];
  let error = {};
  let errorCordinates = {};
  let visitType = [];
  let visitName = [];
  let uniqueObjects = {};
  ctmsVisitsData.forEach((visit) => {
    visitType.push(visit.visittype);
    visitName.push(getVisitNameAfterFormatting(visit.visitname));
  });

  const checkUniqueVisitName = (record) => {
    const { visitname } = record;
    let value = visitname?.toString().toLowerCase();
    if (Object.keys(uniqueObjects).length > 0 && uniqueObjects[value]) {
      return {
        visitname: { visitname: ctpVisitNameError }
      };
    } else {
      uniqueObjects[value] = true;
      return {};
    }
  };

  records.forEach((record, i) => {
    let obj = {};
    let cordinates = {};
    const forms = Object.keys(formsObj);
    const mandatory = checkMandatory(record);
    const uniqueCheck = checkUniqueVisitName(record);
    const visitMetaData = checkVisitType(record, visitType);
    const edcObj = serviceResult.isValidEDC(record.edc);
    const checkSources = serviceResult.checkIsEDCSelected(record.edc, '', '', record.cdr);
    const defaultFormValidation =
      record.interface === 'Y' &&
      serviceResult.checkDefaultFormForEDCandCDR(record.edcdefaultform, record.cdrdefaultform);

    if (isSeqNumExists(record.visitname) && isSeqNumValid(record.visitname)) {
      if (!isVisitNameExists(getVisitNameWithTruncateSeqNum(record.visitname), visitName)) {
        visitMetaData.visitname = { visitname: visitTypeNameError };
      }
    } else if (isSeqNumExists(record.visitname)) {
      visitMetaData.visitname = { visitname: visitNameSeqNumError };
    } else {
      if (!visitName.includes(getVisitNameAfterFormatting(record.visitname))) {
        visitMetaData.visitname = { visitname: visitTypeNameError };
      }
    }
    const isNumber = checkIsNumber(record);
    const isYesNo = checkIsYesOrNo(record);
    const validEdcDefaultForm = validateEdcDefaultForm(record, forms);
    const validCdrDefaultForm = validateCdrDefaultForm(record, Object.keys(cdrTabular));
    const { filtercondtion, errorCordinatesObj, cdrErrorCordinates } = checkFilterCondition(
      record,
      codeListDataMapping,
      serviceResult,
      formsObj,
      cdrTabular
    );
    mergeObject(obj, defaultFormValidation);
    mergeObject(obj, checkSources);
    mergeObject(obj, edcObj);
    mergeObject(obj, isNumber);
    mergeObject(obj, isYesNo);
    mergeObject(obj, mandatory);
    mergeObject(obj, filtercondtion);
    mergeObject(obj, validEdcDefaultForm);
    mergeObject(obj, uniqueCheck);
    isCtmsConfigured && mergeObject(obj, visitMetaData);
    mergeObject(obj, validCdrDefaultForm);
    mergeCordinates(cordinates, errorCordinatesObj);
    mergeCordinates(cordinates, cdrErrorCordinates);

    if (obj && Object.keys(obj).length > 0) {
      errorRowsId.push(record.id);
      errorColumnCount = errorColumnCount + Object.keys(obj).length;
    }
    if (obj && obj.tabular && obj.tabular.tabular) {
      obj = { ...obj, cdr: { cdr: obj.tabular.tabular } };
    }
    error[record.id] = obj;
    errorCordinates[record.id] = cordinates;
  });

  const errorCount = () => {
    let count = 0;
    for (let i = 0; i <= error.length; i++) {
      if (error[i]) {
        count = count + Object.keys(error[i]).length;
      }
    }
    return count;
  };

  const errorKeys = () => {
    let array = [];
    error &&
      error.map((el, index) => {
        if (Object.keys(el).length > 0) {
          array.push(index);
        }
      });

    return array;
  };
  const isColumnValid = (uuid, columnName) => {
    let columnError = '';
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      columnError = columnName && row[columnName] ? row[columnName] : '';
    }
    return columnError;
  };

  const isExpressionValid = (uuid, key) => {
    let cordinates = {};
    let row = errorCordinates[uuid] ? errorCordinates[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      cordinates = row[key] ? row[key] : '';
    }
    return cordinates;
  };

  const isRowValid = (uuid) => {
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row[uuid]).length > 0) {
      return false;
    }
    return true;
  };

  const getErrorRowIndex = () => {
    return errorRowsId;
  };

  const getColumnErrorCount = () => {
    return errorColumnCount;
  };

  return {
    isColumnValid: isColumnValid,
    isRowValid: isRowValid,
    errorCount: errorCount,
    errorKeys: errorKeys,
    getErrorRowIndex: getErrorRowIndex,
    getErrorCount: getColumnErrorCount,
    isExpressionValid: isExpressionValid
  };
};

export default { ctpSvtValidator };
