/*eslint-disable */
import React, { useEffect, useState } from 'react';
import Footer from 'Components/Footer';
import TextField from 'apollo-react/components/TextField';
import PasswordInput from 'apollo-react/components/PasswordInput';
import Grid from 'apollo-react/components/Grid';
import Button from 'apollo-react/components/Button';
import Iqvia from 'Images/IQVIA.svg';
import Checkbox from 'apollo-react/components/Checkbox';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  loginFormData,
  setComponentsDisable,
  setUserType
} from 'Redux/Slice/loginFormDetailsSlice';
import Radio from 'apollo-react/components/Radio';
import { GetLoginInfo } from 'Redux/Service/LoginFormService';
import { unwrapResult } from '@reduxjs/toolkit';
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
// import Cookies from 'js-cookie';

const RADIO_STYLE = 'radio-style';
const useStyles = makeStyles({
  'radio-style': {
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      lineHeight: '25px'
    },
    '& .MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em'
    }
  }
});

const LoginForm = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const loginStyle = {
    height: '38px',
    width: '303px',
    color: '#000000',
    fontFamily: 'Proxima Nova',
    fontSize: '32px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '38px',
    marginBottom: '10px'
  };
  const [value, setValue] = React.useState(false);
  const [userID, setUserID] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [flagUser, setFlagUser] = React.useState(false);
  const [flagPassword, setFlagPassword] = React.useState(false);
  const [userRole, setUserRole] = useState('ADMIN');
  const [roleInfo, setRoleInfo] = useState({});
  const [errorInfo, setErrorInfo] = useState('');
  const [loginID, setLoginID] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e, checked) => {
    setValue(checked);
  };

  useEffect(() => {
    setLoginID('');
  }, []);

  const validateLoginDetails = () => {
    let refArr = ['q', 'u', 'Q', 'U'];
    if (refArr.includes(loginID.charAt(0)) && loginID.length >= 5 && loginID.length <= 10) {
      setFlagUser(false);
      if (password === 'Admin@CDM') {
        setFlagPassword(false);
        setUserID(loginID);
      } else {
        setFlagPassword(true);
      }
    } else {
      setFlagUser(true);
    }
  };

  useEffect(() => {
    let path = '/dashboard';
    if (userID && password && roleInfo) {
      navigate(path);
    }
  }, [userID, password, roleInfo]);

  useEffect(() => {
    if (userID && password) {
      dispatch(loginFormData(userID));
    }
  }, [userID, password]);

  const getPassword = (e) => {
    setPassword(e.target.value);
  };

  const handleUserData = (type) => {
    let userList = {
      readOnly: 'RO',
      readWrite: 'RW',
      admin: 'ADMIN'
    };
    setUserRole(userList[type]);
    return userList[type];
  };

  useEffect(() => {
    setRoleInfo({});
    setErrorInfo('');
  }, []);

  useEffect(() => {
    (async () => {
      setErrorInfo('');
      let payload = {
        modules: ['GlobalLibrary', 'StudyLibrary', 'ProductDesigner', 'Dashboard'],
        userType: userRole
      };
      let getRoleInfo =
        userRole !== '' && (await dispatch(GetLoginInfo(payload)).then(unwrapResult)); //api change required here
      if (getRoleInfo?.data?.success) {
        setRoleInfo(getRoleInfo);
        dispatch(setComponentsDisable(getRoleInfo.data.userAuthMappings));
        dispatch(setUserType(getRoleInfo.data.userType));
      } else {
        setErrorInfo(getRoleInfo?.data?.message);
      }
    })();
  }, [userRole]);

  const styles = {
    paperContainer: {
      backgroundRepeat: 'no-repeat'
    }
  };
  const getLoginId = (e) => {
    setLoginID(e.target.value);
  };

  return (
    <React.Fragment>
      <Grid container style={{ alignContent: 'space-between', height: '100vh' }}>
        <Grid item xs={12} style={{ padding: '20px' }}>
          <img src={`${Iqvia}`} style={styles.paperContainer} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Grid item xs={12} style={loginStyle} data-testid="login-label">
            Login
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="User ID"
              placeholder="Enter User ID"
              fullWidth
              error={flagUser}
              helperText={flagUser ? 'Please Enter a Valid User ID' : ''}
              onChange={(e) => getLoginId(e)}
              data-testid="user-id"
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              placeholder="Enter Password"
              helperText={flagPassword ? 'Please Enter a Valid Password' : ''}
              defaultValue="admin"
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => getPassword(e)}
              error={flagPassword}
              data-testid="password"
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '46px' }}>
            <Checkbox label="Remember my username" checked={value} onChange={handleChange} />
          </Grid>
          <Typography
            variant="title1"
            style={{ fontSize: '13px', color: '#444444', fontWeight: '400', marginTop: '-24px' }}>
            Select Role
          </Typography>
          <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item>
              <Radio
                className={classes[RADIO_STYLE]}
                value="RO"
                label="Read Only"
                checked={userRole === 'RO'}
                onChange={() => handleUserData('readOnly')}
              />
            </Grid>
            <Grid item>
              <Radio
                className={classes[RADIO_STYLE]}
                value="Read Write"
                label="Read Write"
                checked={userRole === 'RW'}
                onChange={() => handleUserData('readWrite')}
              />
            </Grid>
            <Grid item>
              <Radio
                className={classes[RADIO_STYLE]}
                value="Admin"
                label="Admin"
                checked={userRole === 'ADMIN'}
                onChange={() => handleUserData('admin')}
              />
            </Grid>
          </Grid>
          {errorInfo ? (
            <Typography style={{ color: 'red', fontSize: '13px', marginTop: '6px' }}>
              Invalid Role
            </Typography>
          ) : roleInfo && roleInfo.data && roleInfo.data.success ? (
            <Typography style={{ color: 'green', fontSize: '13px', marginTop: '6px' }}>
              User role selected!
            </Typography>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            <Button
              variant="primary"
              fullWidth
              onClick={validateLoginDetails}
              disabled={roleInfo === ''}>
              Log In
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '20px',
              color: '#297DFD',
              fontFamily: 'Proxima Nova',
              fontSize: '16px',
              letterSpacing: 0,
              lineHeight: '24px'
            }}>
            Forgot your password?
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LoginForm;
