import React, { useMemo } from 'react';
import Typography from 'apollo-react/components/Typography';
import Table, { createStringSearchFilter } from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { compareStrings } from './utilites';

const EmptyTableTypographyStyleTitle = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const EmptyTableComponent = () => {
  return (
    <Typography variant="title2" style={EmptyTableTypographyStyleTitle}>
      No Data found
    </Typography>
  );
};

const columns = [
  {
    header: 'Rule Name',
    accessor: 'ruleName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('ruleName')
  },
  {
    header: 'Dataset',
    accessor: 'dataset',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('dataset')
  }
];

const VLCRulesTab = (props) => {
  const { vlcRowData, cloneFlag } = props;
  const columnsConfig = useMemo(() => {
    let newColumn = [...columns];
    if (cloneFlag) {
      newColumn.push({
        header: 'Warnings',
        accessor: 'message',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('message')
      });
    }
    return newColumn;
  }, []);

  return (
    <Table
      title={'Mapping Spec'}
      subtitle={'VLC Rules'}
      columns={columnsConfig}
      rows={vlcRowData}
      rowsPerPageOptions={[10, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      rowProps={{ hover: false }}
      initialSortOrder="asc"
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      emptyProps={{
        text: <EmptyTableComponent />
      }}
    />
  );
};

export default VLCRulesTab;
