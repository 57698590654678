/*eslint-disable*/
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Card from 'apollo-react/components/Card';
import Button from 'apollo-react/components/Button';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import Table, { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import _ from 'lodash';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import {
  SaveSupplementalQualifiersData,
  GetSupplementalQualifiersData,
  GetSDTMDomainPrefix
} from 'Redux/Service/ReferenceDataCardsService';
import { useSelector, useDispatch } from 'react-redux';
import { jsonToExcel, uuidv4 } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Trash from 'apollo-react-icons/Trash';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Switch from 'apollo-react/components/Switch';
import { supplQualValidator } from 'Validators/SupplQual.Validator';
import { unwrapResult } from '@reduxjs/toolkit';
import { showLoader, closeLoader } from 'Redux/Slice/LoaderSlice';
import Loader from 'Components/Loader/Loader';
import DataVizCard from 'apollo-react/components/DataVizCard';
import { useNewRow, TableNewRow } from './TableNewRow';
import PlusIcon from 'apollo-react-icons/Plus';
import CustomModal from 'Components/Modal';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import { passDmData } from 'Redux/Slice/RuleEditorSlice';
import Cookies from 'js-cookie';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};
const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};
export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};
const SupplementalQualifierPreview = () => {
  const navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState(false);
  const [validationData, setValidationData] = useState({});
  const [editedRows, setEditedRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [resetPage, setPageReset] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [newRow, setNewRow, editNewRow] = useNewRow({});
  const [isAdding, setIsAdding] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const dispatch = useDispatch();
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { dmArray } = useSelector((state) => state.RuleEditorData);
  const [errorCount, setErrorCount] = useState(0);
  const [errorRowId, setErrorRowId] = useState([]);
  const { loading } = useSelector((state) => state.LoaderData);
  const editMode = editedRows.length > 0;
  const classes = useStyles();
  const { id: mappingRuleVersionId } = useParams();
  const SUPP_QUAL_DATA = 'supp-qual-data';
  const SUPP_QUAL_VALIDATION = 'supp-qual-validation';
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);

  useEffect(() => {
    dispatch(showLoader());
    if (getReferenceData(SUPP_QUAL_DATA) && getReferenceData(SUPP_QUAL_DATA).length > 0) {
      dispatch(closeLoader());
      setRows(getReferenceData(SUPP_QUAL_DATA));
      setValidationData(getReferenceData(SUPP_QUAL_VALIDATION));
      setIsPreviewDataUploaded(true);
    } else {
      dispatch(closeLoader());
      getSupplQualData();
    }
  }, []);

  useEffect(() => {
    if (validationData && Object.keys(validationData).length > 0) {
      setErrorCount(validationData && validationData.getErrorCount());
      setErrorRowId(validationData && validationData.getErrorRowIndex());
    }
  }, [validationData]);

  const onEditAll = () => {
    setEditedRows(rows);
  };

  useEffect(() => {
    setPageReset(true);
    setImmediate(() => {
      setPageReset(false);
    });
  }, [errorFlag]);

  useEffect(() => {
    if (errorFlag && !editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = rows.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setFilteredRows(filteredData);
    } else if (!errorFlag && !editMode) {
      const newRows =
        rows.length > 0
          ? rows
          : getReferenceData(SUPP_QUAL_DATA)
          ? getReferenceData(SUPP_QUAL_DATA)
          : [];
      setRows(newRows);
    } else if (errorFlag && editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = editedRows.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setEditedRows(filteredData);
    } else if (!errorFlag && editMode) {
      if (editedRows.length > 0 && rows.length > 0) {
        const idToIndexMap = {};
        const arr = JSON.parse(JSON.stringify(rows));
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            arr[idToIndexMap[data.id]] = data;
          } else {
            arr.push(data);
          }
        });
        setEditedRows(arr);
      }
    }
  }, [errorFlag, editMode]);
  const getValidTitle = (data) => {
    return data.mandatory
      ? data.mandatory
      : data.variablename ||
          data.variablelabel ||
          data.domain ||
          data.unique ||
          data.domainCheck ||
          data.stringCheck;
  };
  const getErrorData = (getData, row, key) => {
    return (
      <Tooltip title={getValidTitle(getData)}>
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            backgroundColor: '#f9cccc',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key] === '' ? 'No Data' : row[key]}
        </Typography>
      </Tooltip>
    );
  };
  const ActionCell = useCallback(({ row: { id, editMode, onDelete } }) => {
    let isDisabled = errorFlag ? rows && rows.length < 1 : rows && rows.length < 2;

    return (
      editMode || (
        <Tooltip title={!isDisabled ? 'Delete' : 'Table cannot be empty'} disableFocusListener>
          <Button
            size="small"
            style={isDisabled ? { cursor: 'not-allowed' } : { color: 'black' }}
            onClick={!isDisabled ? () => onDelete(id) : null}>
            <Trash />
          </Button>
        </Tooltip>
      )
    );
  });
  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      const getData =
        (validationData &&
          Object.keys(validationData).length > 0 &&
          validationData?.isColumnValid(row['id'], key)) ||
        {};

      return row.editMode ? (
        <TextField
          size="small"
          fullWidth
          value={row[key]}
          onChange={(e) => row.editRow(row.id, key, e.target.value)}
          {...fieldStyles}
          {...(Object.keys(getData).length > 0
            ? {
                helperText: getValidTitle(getData),
                error: true
              }
            : {})}
        />
      ) : Object.keys(getData).length > 0 ? (
        getErrorData(getData, row, key)
      ) : (
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key]}
        </Typography>
      );
    },
    [validationData]
  );
  const columns = useMemo(
    () => [
      {
        header: 'DOMAIN',
        accessor: 'domain',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('domain'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'VARIABLE NAME',
        accessor: 'variablename',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('variablename'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'VARIABLE LABEL',
        accessor: 'variablelabel',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('variablelabel'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        accessor: 'action',
        customCell: ActionCell,
        align: 'right'
      }
    ],
    [EditableCell, ActionCell]
  );

  const CustomButtonHeader = useCallback((props) => {
    const {
      editMode,
      onCancel,
      onSave,
      onEditAll,
      toggleFilters,
      rows,
      handleStepValue,
      handleShowPreview,
      setIsPreview,
      validationData,
      setConfirmAlert,
      isPreviewDataUploaded
    } = props;

    const onHandleDownload = () => {
      let newFileArr = [];
      rows.map((obj) => {
        delete obj['id'];
        var upperObj = _.transform(obj, function (result, val, key) {
          result[key.toUpperCase()] = val;
        });
        newFileArr.push(upperObj);
      });
      jsonToExcel(newFileArr, 'SupplQual.xlsx');
    };

    const handleErrorData = (e) => {
      setErrorFlag(e.target.checked);
    };

    const returnToSupplQualUpload = () => {
      setReferenceData(SUPP_QUAL_DATA, []);
      setReferenceData(SUPP_QUAL_VALIDATION, {});
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/supp-qual`);
      if (isPreviewDataUploaded) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Uploaded data would not saved',
          variant: 'warning',
          onConfirm: () => {
            setIsPreviewDataUploaded(false);
            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      }
    };

    return (
      <>
        <div style={{ width: '100%' }}>
          <div>
            <Button
              icon={<ChevronLeft />}
              size="small"
              onClick={returnToSupplQualUpload}
              style={{
                marginRight: 10,
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Return to supplemental qualifier upload
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ fontWeigth: 600, fontSize: '20px' }}>
                    Supplemental Qualifiers
                  </Typography>
                  {errorCount > 0 && (
                    <div
                      style={{
                        backgroundColor: errorCount === 0 ? 'green' : '#E20000',
                        float: 'left',
                        width: '83px',
                        paddingBottom: '2px',
                        paddingLeft: '2px',
                        border: '1px solid white',
                        borderRadius: '4px'
                      }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                        <span
                          style={{
                            color: 'white'
                          }}>
                          <StatusExclamation
                            style={{
                              color: 'white',
                              float: 'left',
                              height: '16px',
                              marginTop: '2px'
                            }}
                          />
                          {errorCount !== 1 ? `${errorCount} errors` : `${errorCount} error`}
                        </span>
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={9} style={{ marginRight: '4px' }}>
                    {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                  </Grid>
                  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="secondary" size="small" onClick={toggleFilters}>
                      <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} />{' '}
                      Filter
                    </Button>
                    <Button size="small" onClick={onHandleDownload} style={{ color: 'black' }}>
                      <Download />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <hr style={{ margin: '22px 0px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
                variant="subtitle"
                gutterBottom>
                {errorFlag ? 'Show only rows with errors' : 'Show All'}
              </Typography>
              <Switch size={'small'} checked={errorFlag} onChange={handleErrorData} />
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={isAdding || editMode}
                size="small"
                variant="secondary"
                icon={PlusIcon}
                onClick={() => setIsAdding((adding) => !adding)}
                style={{ marginRight: 8 }}>
                {'Add row'}
              </Button>
              {!editMode && (
                <Button size="small" variant="primary" onClick={onEditAll}>
                  {'Edit all'}
                </Button>
              )}
              {editMode && (
                <Button size="small" onClick={onCancel}>
                  {'Cancel'}
                </Button>
              )}
              {(enableSave || editMode || isPreviewDataUploaded) && (
                <Button
                  size="small"
                  variant="primary"
                  style={{ marginLeft: 8 }}
                  onClick={() => setOpenConfirmModal(true)}>
                  {'Save'}
                </Button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  });

  const getSupplQualData = async () => {
    let _domainPrefixes = [];

    const domainPrefixData = await dispatch(GetSDTMDomainPrefix(rowDataForRuleEditor)).then(
      unwrapResult
    );

    if (domainPrefixData?.data?.success) {
      _domainPrefixes = domainPrefixData.data.domainPrefix;
      dispatch(passDmData(_domainPrefixes));
    }

    let payload = {
      mappingRuleVersionID: mappingRuleVersionId
    };
    const SupplQualData = await dispatch(GetSupplementalQualifiersData(payload)).then(unwrapResult);
    if (SupplQualData && SupplQualData.data && SupplQualData.data.success) {
      if (
        SupplQualData.data.supplementalQualifiers &&
        SupplQualData.data.supplementalQualifiers.length > 0
      ) {
        const newData = SupplQualData.data.supplementalQualifiers.map((item) => {
          return {
            domain: item.domainNm,
            variablename: item.variableName,
            variablelabel: item.variableLabel,
            id: uuidv4()
          };
        });
        setRows(newData);
        const validationResult = supplQualValidator(newData, _domainPrefixes);
        setValidationData(validationResult);
        dispatch(closeLoader());
      }
    } else {
      setRows([]);
      dispatch(closeLoader());
    }
  };
  const getSavePayload = (data, auditType) => {
    const supplementalQualifiers = data.map((item) => {
      let { domain, variablename, variablelabel } = item;
      return {
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: auditType,
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        suppQualifierId: '',
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingRuleVersionId,
        domainNm: domain,
        // domain: domain,
        variableName: variablename,
        variableLabel: variablelabel,
        enabled: 'Y'
      };
    });
    const payload = {
      supplementalQualifiers
    };
    return payload;
  };

  const onSave = async () => {
    let payload;
    let validationResult;
    let newData = JSON.parse(JSON.stringify(rows));
    let arr = [];
    if (rows.length !== 0) {
      if (editMode && !errorFlag) {
        setRows(editedRows);
        validationResult = supplQualValidator(editedRows, dmArray);
        setValidationData(validationResult);
        payload = getSavePayload(editedRows, 'UPDATE');
      } else if (editMode && errorFlag) {
        const idToIndexMap = {};
        if (editedRows.length > 0 && newData.length > 0) {
          arr = JSON.parse(JSON.stringify(newData));
          arr.forEach((data, i) => {
            idToIndexMap[data.id] = i;
          });
          editedRows.forEach((item) => {
            if (item.id in idToIndexMap) {
              arr[idToIndexMap[item.id]] = item;
            } else {
              arr.push(item);
            }
          });
        }
        validationResult = supplQualValidator(arr, dmArray);
        setValidationData(validationResult);
        payload = getSavePayload(arr, 'INSERT');
      } else {
        validationResult = supplQualValidator(rows, dmArray);
        setValidationData(validationResult);
        payload = getSavePayload(rows, 'INSERT');
      }
      if (validationResult.getErrorCount() > 0) {
        if (editMode) {
          setRows(editedRows);
          if (errorFlag) {
            setRows(arr);
          }
        } else {
          setRows([...rows]);
        }
        setOpenConfirmModal(false);
        dispatch(showBanner({ variant: 'error', message: 'Data still has some error' }));
      } else {
        const saveResponse = await dispatch(SaveSupplementalQualifiersData(payload));

        if (
          saveResponse &&
          saveResponse.payload &&
          saveResponse.payload.data &&
          saveResponse.payload.data.success
        ) {
          if (editMode && !errorFlag) {
            setRows(editedRows);
          } else if (editMode && errorFlag) {
            setRows(arr);
          } else {
            setRows([...rows]);
          }
          getSupplQualData();
          setIsPreviewDataUploaded(false);
          dispatch(showBanner({ variant: 'success', message: saveResponse.payload.data.message }));
        } else {
          if (editMode) {
            setRows(editedRows);
          } else {
            setRows([...rows]);
          }
          dispatch(showBanner({ variant: 'error', message: 'something went wrong' }));
        }
        setOpenConfirmModal(false);
        setEnableSave(false);
        setEditedRows([]);
        setReferenceData(SUPP_QUAL_DATA, []);
        setReferenceData(SUPP_QUAL_VALIDATION, {});
      }
    }
  };
  const onCancel = () => {
    setEditedRows([]);
  };

  const handleClose = () => {
    setOpenConfirmModal(false);
  };
  const onDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
    setFilteredRows(filteredRows.filter((row) => row.id !== id));
    setEnableSave(true);
  };
  const editRow = (id, key, value) => {
    setEditedRows((rows) => rows.map((row) => (row.id === id ? { ...row, [key]: value } : row)));
  };

  const SupplQual = [
    {
      DOMAIN: '',
      VARIABLENAME: '',
      VARIABLELABEL: ''
    }
  ];

  const onAddNewRowSave = () => {
    const supplQualApiColumns = Object.keys(SupplQual[0]).map((columnName) =>
      columnName.toLowerCase()
    );
    const copyRows = [...rows];
    if (newRow && Object.keys(newRow).length > 0) {
      const obj = {
        id: uuidv4(),
        ...supplQualApiColumns.reduce((acc, columnName) => {
          acc[columnName] = newRow[columnName];
          return acc;
        }, {})
      };
      copyRows.unshift(obj);
      const validationResult = supplQualValidator(copyRows, dmArray);
      setValidationData(validationResult);
      setRows(copyRows);
      if (validationResult?.getErrorCount() === 0) {
        setOpenConfirmModal(true);
      }
    }
  };

  return (
    <>
      {loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={(editMode ? editedRows : errorFlag ? filteredRows : rows).map((row, i) => {
                return {
                  ...row,
                  onDelete,
                  editRow,
                  editMode,
                  key: row.id
                };
              })}
              initialSortedColumn="domain"
              initialSortOrder="asc"
              page={resetPage ? 0 : undefined}
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                //   labelDisplayedRows: ({ from, to, count }) =>
                //     `${count === 1 ? 'Employee' : 'Employees'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={(props) => <CustomButtonHeader {...props} />}
              headerProps={{
                onEditAll,
                onSave,
                onCancel,
                editMode,
                rows,
                // handleStepValue,
                // handleShowPreview,
                validationData,
                setConfirmAlert,
                isPreviewDataUploaded
                // setIsPreview,
              }}
              CustomSubHeader={() => (
                <TableNewRow
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  onSave={onAddNewRowSave}
                  newRow={newRow}
                  editNewRow={editNewRow}
                  setNewRow={setNewRow}
                  columns={columns}
                />
              )}
            />
          </Card>
        </>
      )}

      <CustomModal
        display={openConfirmModal}
        handleClose={handleClose}
        title={'Do you want to Save the Data?'}
        message={
          'Please check the information before submitting as the edits/deletes cannot be retrieved once the data is saved'
        }
        variant={'warning'}
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={onSave}
        buttonSecondardyLabel={'Cancel'}
        handleSecondaryAction={() => setOpenConfirmModal(false)}
      />
    </>
  );
};
export default SupplementalQualifierPreview;
