import Download from 'apollo-react-icons/Download';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContentHolder from '../../ReviewAndPublish/components/ContentHolder';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { DownloadMappingSpecData } from 'Redux/Service/PublishMappingSpecService';

import { showBanner } from 'Redux/Slice/BannerSlice';
import { downloadFile } from '../../publishUtils';
import { dateFormatByType } from 'Utils';
import useDisplayName from 'Utils/useDisplayName';

const PublishSuccess = (props) => {
  const navigate = useNavigate();
  const { publishInfo } = props;
  const dispatch = useDispatch();
  const { mappingSpecDetails } = useSelector((state) => state.PublishMappingSpecData);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const getDisplayName = useDisplayName();

  const {
    sourceConn,
    exportLocation,
    productMnemonic,
    mappingSpecVersionName,
    targetDataModelName,
    environment,
    trialLists
  } = publishInfo;

  const returnToDataProduct = () => {
    props &&
      (props.pathName === '/manage-data-pipeline/manage-data-pipeline-version'
        ? navigate('/manage-data-pipeline')
        : navigate('/product-designer'));
  };

  const downloadFunction = async () => {
    const {
      mappingRuleVersionID,
      mappingSpecVersionName,
      productMnemonic,
      protocolName,
      targetDataModelType,
      targetDataModelName,
      targetDataModelVersion
    } = mappingSpecDetails;
    const { sponsor, projectCode } = protocol;
    const params = {
      protocolName: protocolName,
      projectCode: projectCode,
      sponsor: sponsor,
      mappingSpecVersionName: mappingSpecVersionName,
      productMnemonic: productMnemonic,
      targetDataModelName: targetDataModelName,
      targetDataModelVersion: targetDataModelVersion,
      targetDataModelType: targetDataModelType,
      getDisplayName
    };
    const downloadResult = await dispatch(
      DownloadMappingSpecData({
        mappingRuleVersionID: mappingRuleVersionID
      })
    ).then(unwrapResult);
    if (downloadResult && downloadResult.data && downloadResult.data.success) {
      downloadFile(
        downloadResult.data,
        params,
        `${protocolName}_${productMnemonic}_${mappingSpecVersionName}`,
        {
          createdBy:
            downloadResult.data.lastUpdatedBy === null
              ? 'Created By would be populated once user begins the Rule Processing'
              : downloadResult.data.lastUpdatedBy,
          createdDate:
            downloadResult.data.lastUpdatedBy === null
              ? 'Created Date would be populated once user begins the Rule Processing'
              : dateFormatByType(downloadResult.data.lastUpdatedDate, 'Table')
        }
      );
    } else {
      dispatch(showBanner({ variant: 'error', message: 'Something Went Wrong!' }));
    }
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3rem',
        marginBottom: '3rem'
      }}>
      <Grid item xs={8}>
        <Card style={{ width: '100%', padding: '5%' }}>
          <div style={{ textAlign: 'center' }}>
            <StatusCheck
              style={{ color: '#00C221', width: '63px', height: '63px' }}
              fontSize="large"
            />
          </div>
          <Typography
            style={{ textAlign: 'center', fontSize: '32px', marginTop: '1%', marginBottom: '1%' }}
            variant="title1">
            Publish Succeed
          </Typography>
          <Typography style={{ textAlign: 'center' }}>
            Congratulations! This Mapping Specification has been successfully <br /> published and
            the data pipeline is now active in the chosen environment.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2%',
              marginBottom: '1%'
            }}>
            <Button
              variant="secondary"
              icon={<Download />}
              style={{ marginRight: 10 }}
              data-testid="download-btn"
              onClick={downloadFunction}>
              Download
            </Button>
            <Button variant="primary" style={{ marginRight: 10 }} onClick={returnToDataProduct}>
              {props.pathName === '/manage-data-pipeline/manage-data-pipeline-version'
                ? 'Monitor Data Pipeline'
                : 'Return to Data Products'}
            </Button>
          </div>
        </Card>
        <Card style={{ width: '100%', padding: '5%' }}>
          <Grid container style={{ justifyContent: 'space-around', marginBottom: '25px' }}>
            <Grid item xs={3}>
              <ContentHolder title={'Product Mnemonic'} value={productMnemonic} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Mapping Spec Version'} value={mappingSpecVersionName} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Target Data Model'} value={targetDataModelName} />
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: 'space-around', marginBottom: '25px' }}>
            <Grid item xs={3}>
              <ContentHolder title={'Environment'} value={environment} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Source Connections'} value={sourceConn.toString()} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Trial Name'} value={Object.values(trialLists).toString()} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Data Export Destination'} value={exportLocation.toString()} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PublishSuccess;
