/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import Blade from 'apollo-react/components/Blade';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import Radio from 'apollo-react/components/Radio';
import RadioGroup from 'apollo-react/components/RadioGroup';
import { GetEnvironments } from 'Redux/Service/PublishMappingSpecService';
import Environment from 'Pages/ProductDesigner/Components/PublishMappingSpec/SelectEnvironment/Environment';

const useStyles = makeStyles({
  expandPanelButton: {
    display: 'none'
  },
  backdrop: {
    zIndex: 3999
  },
  paper: {
    zIndex: 4000,
    fontFamily: 'Proxima Nova',
    background: 'white',
    paddingBottom: '4.5rem'
  },
  environmentsTitle: {
    fontSize: 'larger',
    fontWeight: 'bold',
    opacity: 0.7
  },
  envCard: {
    width: '100%',
    height: '6rem',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.04)',
    backgroundColor: 'white',
    margin: '1rem 2rem 0rem 0rem',
    padding: '0rem 1rem',

    '&> span:nth-child(2)': {
      padding: '0rem',
      paddingLeft: '1rem',
      height: '100%'
    },

    '&:hover': {
      border: '1px solid #0768FD'
    }
  },
  refreshBtnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '1rem',
    borderTop: '1px solid #E9E9E9',
    width: 'calc(100%)',
    display: 'flex',
    justifyContent: 'flex-end',
    background: 'white'
  }
});

const RefreshMappingSpec = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    reload,
    displayOverlay,
    toggleOverlayDisplay,
    productMnemonic,
    productID,
    onRefreshData
  } = props;

  const [environments, setEnvironments] = useState([]);
  const [selectedEnvID, setSelectedEnvID] = useState('');
  const { userRoleType } = useSelector((state) => state.LoginFormData);

  useEffect(() => {
    document.getElementsByTagName('body')[0].style = 'padding-right: 17px; overflow: hidden;';
    return () => {
      document.getElementsByTagName('body')[0].style = undefined;
    };
  }, []);

  useEffect(() => {
    (async () => {
      const {
        data: { success, environments: envs, message }
      } = await dispatch(
        GetEnvironments({
          ProductId: productID
        })
      ).then(unwrapResult);
      if (success) {
        setEnvironments(envs);
      } else {
        console.log('Failed to retrive environments :: ', message);
      }
    })();
  }, [productID]);

  /**
   * Memoized pageAction
   */
  const pageAction = useMemo(() => (reload ? 'Reload Data' : 'Refresh Data'), [reload]);

  return (
    <Blade
      open={displayOverlay}
      onClose={() => toggleOverlayDisplay(false)}
      title={pageAction}
      subtitle={`Product Mnemonic: ${productMnemonic}`}
      side="right"
      classes={{
        expandPanelButton: classes.expandPanelButton,
        backdrop: classes.backdrop,
        paper: classes.paper
      }}
      width={'550px'}
      hasBackdrop>
      <Typography className={classes.environmentsTitle}>Environments:</Typography>
      <RadioGroup
        aria-label="label"
        name="label"
        value={selectedEnvID}
        onChange={(e) => setSelectedEnvID(e.target.value)}>
        <Grid container>
          {environments.map((env, index) => (
            <Radio
              disabled={
                (env.environmentName === 'Prod' && userRoleType !== 'ADMIN') || !env.mappingSpecName
              }
              key={`env-${env.environmentID}`}
              value={env.environmentID}
              label={
                <Environment {...env} selected={env.environmentID === selectedEnvID} horizontal />
              }
              className={classes.envCard}
              style={
                env.environmentID === selectedEnvID
                  ? { backgroundColor: '#ECF3FF', borderColor: '#0768FD' }
                  : ((env.environmentName === 'Prod' && userRoleType !== 'ADMIN') || !env.mappingSpecName) ? { opacity: '0.6'}
                  : {}
              }
            />
          ))}
        </Grid>
      </RadioGroup>
      <Box className={classes.refreshBtnContainer}>
        <Button
          disabled={!selectedEnvID}
          variant={'primary'}
          onClick={() =>
            onRefreshData(environments.find((env) => env.environmentID === selectedEnvID))
          }>
          {pageAction}
        </Button>
      </Box>
    </Blade>
  );
};

export default RefreshMappingSpec;
