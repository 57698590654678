/*eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Box from 'apollo-react/components/Box';
import Button from 'apollo-react/components/Button';
import ArrowRight from 'apollo-react-icons/ArrowRight';
import { Notification } from 'Components/Common/Notification';
import Card from 'apollo-react/components/Card';
import TextField from 'apollo-react/components/TextField';
import Select from 'apollo-react/components/Select';
import PreSQLEditor from './PreSQLEditor';
import Divider from 'apollo-react/components/Divider';
import MenuItem from 'apollo-react/components/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { GetStudyLibraryType, GetStudyLibrarySource } from 'Redux/Service/AddStudyLibraryService';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useMemo } from 'react';
import { validateEDCQuery, validatePreSQLQuery } from './preSQLValidator';
import {
  GetStudyDataSetName,
  GetCustomSQLQueryIntellisense
} from 'Redux/Service/PreSQLEditorService';
import Tooltip from 'apollo-react/components/Tooltip';
import { useLocation } from 'react-router-dom';
import Typography from 'apollo-react/components/Typography';
import Grid from 'apollo-react/components/Grid';
import useDisplayName from 'Utils/useDisplayName';
import { useMonaco } from '@monaco-editor/react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  helperText: { color: '#e20000', fontSize: '13px', fontWeight: '400' }
});

const PreSQLAddNewDataset = (props) => {
  const classes = useStyles();
  const { formik, handleRun, isLoading, isSavedInDB } = props;
  const [isDatasetPresent, setIsDatasetPresent] = useState(false);
  const [existingDatasetNames, setExistingDatasetNames] = useState([]);
  const [intellisenseQueryData, setIntellisenseQueryData] = useState([]);
  const selectedProtocol = useSelector((state) => state.StudyLibraryData.protocol);
  const { dataSet } = useSelector((state) => state.AddStudyLibrary.studyObj);
  const { dataSourceTrailValues, silentProperties } = useSelector((state) => state.AddStudyLibrary);
  const dispatch = useDispatch();
  const location = useLocation();
  const getDisplayName = useDisplayName();

  const [sqlQuery, setSqlQuery] = [
    formik.getFieldMeta('sqlQuery')?.value,
    formik.getFieldHelpers('sqlQuery')?.setValue
  ];

  const isRunDisabled = () => {
    const { datasetName, sqlQuery } = formik.values;
    if (!datasetName || !sqlQuery || isLoading || Boolean(formik.errors.datasetName)) return true;
  };

  const getStudyDataSetName = async () => {
    const response = await dispatch(
      GetStudyDataSetName({ protocolName: selectedProtocol.protocolNumber })
    ).then(unwrapResult);
    if (response && response.data && response.data.success) {
      const { listOfStudyDataSetNames } = response.data;
      setExistingDatasetNames(listOfStudyDataSetNames);
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
  };

  const getCustomSQLQueryIntellisense = async () => {
    const payload = {
      protocolNumber: selectedProtocol.protocolNumber,
      dataSource: dataSourceTrailValues?.selectedDataSource,
      ...(dataSourceTrailValues?.selectedDataSource === 'CDISC ODM' &&
        !silentProperties?.isAzureEnv && {
          edcStudyID: dataSourceTrailValues?.selectedTrialName?.studyID
        })
    };

    const response = await dispatch(GetCustomSQLQueryIntellisense(payload)).then(unwrapResult);

    if (response && response.data && response.data.success) {
      const { queryIntellisenseLookUp } = response.data;
      setIntellisenseQueryData(queryIntellisenseLookUp);
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
  };

  useEffect(() => {
    getStudyDataSetName();
    // getCustomSQLQueryIntellisense();
  }, []);

  const monaco = useMonaco();

  useEffect(() => {
    if (monaco && intellisenseQueryData?.queryIntellisenseResult) {
      const intellisenseMetaDataArr = JSON.parse(intellisenseQueryData?.queryIntellisenseResult);
      const _handleCreateSuggestion = (mapping) => ({
        filterText: `${mapping.TABLE_NAME}.${mapping.COLUMN_NAME}`,
        label: mapping.COLUMN_NAME,
        detail: `${mapping.TABLE_NAME}.${mapping.COLUMN_NAME}`,
        documentation: `Table: ${mapping.TABLE_NAME}\nColumn: ${mapping.COLUMN_NAME}`,
        kind: monaco.languages.CompletionItemKind.Property,
        insertText: mapping.COLUMN_NAME
      });

      return monaco.languages?.registerCompletionItemProvider('sql', {
        provideCompletionItems: () => {
          const suggestions = [];
          intellisenseMetaDataArr.forEach((mapping) => {
            suggestions.push(_handleCreateSuggestion(mapping));
          });
          return { suggestions };
        }
      }).dispose;
    }
  }, [monaco, intellisenseQueryData]);

  const validateDatasetNameAndSource = () => {
    const dataset = formik.values.datasetName;
    let isValid = true;
    let savedDataSetName = [];
    dataSet?.forEach((data) => {
      const { datasetName } = data;
      savedDataSetName.push(datasetName);
    });

    if (
      (existingDatasetNames.includes(dataset) || savedDataSetName.includes(dataset)) &&
      location?.state?.datasetName !== dataset
    ) {
      setIsDatasetPresent(true);
      isValid = false;
    } else {
      setIsDatasetPresent(false);
    }
    return isValid;
  };

  const handleValidateAndRun = () => {
    if (validateDatasetNameAndSource()) {
      handleRun();
    }
  };

  return (
    <>
      <Box pt={0} pl={2.5} pr={3}>
        <Notification
          variant={'info'}
          message={
            <>
              Only <span style={{ fontWeight: '600' }}>SELECT </span>statements are allowed
            </>
          }
          style={{ marginBottom: '8px' }}
        />
        <Card style={{ overflow: 'visible' }}>
          <Box style={{ display: 'flex' }}>
            <div style={{ display: 'flex', padding: '16px' }}>
              <TextField
                id="datasetName"
                label="Dataset Name"
                placeholder="Add new dataset"
                size="small"
                name="datasetName"
                disabled={isSavedInDB}
                style={{ minWidth: 300 }}
                onChange={formik.handleChange}
                value={formik.values.datasetName}
                error={
                  (formik.touched.datasetName && Boolean(formik.errors.datasetName)) ||
                  isDatasetPresent
                }
                helperText={
                  (formik.touched.datasetName && formik.errors.datasetName) ||
                  (isDatasetPresent && 'Dataset name already exists in study')
                }
                onBlur={formik.handleBlur}
              />
            </div>
            <Grid container spacing={1} style={{ padding: '1rem', marginTop: '0.5rem' }}>
              <Grid item xs={4}>
                <Typography>Data Source</Typography>
                <Typography>{getDisplayName(dataSourceTrailValues?.selectedDataSource)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {dataSourceTrailValues?.selectedDataSource === 'CDISC ODM'
                    ? 'Trial Name'
                    : 'Data Flow Type'}
                </Typography>
                <Typography>{dataSourceTrailValues?.selectedTrialName?.trialName}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <PreSQLEditor sqlQuery={sqlQuery} onQueryChange={setSqlQuery} />
        </Card>
        <Card>
          <TextField
            id="datasetDescription"
            label="Add Description (Optional)"
            placeholder="Enter Text"
            name="datasetDescription"
            multiline
            minRows={1}
            InputProps={{
              inputProps: {
                maxLength: 500,
                style: {
                  padding: '0px',
                  border: 'none',
                  fontSize: '14px'
                }
              }
            }}
            helperText={
              formik.values.datasetDescription?.length === 500 ? (
                <Typography className={classes.helperText}>
                  Maximum 500 characters are allowed
                </Typography>
              ) : null
            }
            value={formik.values.datasetDescription}
            onChange={formik.handleChange}
            style={{
              padding: '0px 15px',
              margin: '8px 0px',
              width: '100%'
            }}
          />
        </Card>
        <Card sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', pt: 1, pl: 1 }}>
          <Tooltip
            title={
              isRunDisabled() &&
              'Dataset Name, Data Source, Trial Name(for CDISC ODM) OR Data Flow Type(for CDR Tabular) & Query are required'
            }
            placement={'left'}>
            <span>
              <Button
                variant="secondary"
                size="small"
                icon={
                  isLoading ? () => <CircularProgress size={16} sx={{ mr: '5px' }} /> : ArrowRight
                }
                onClick={handleValidateAndRun}
                disabled={isRunDisabled()}>
                Run
              </Button>
            </span>
          </Tooltip>
        </Card>
      </Box>
    </>
  );
};

export default PreSQLAddNewDataset;
