import { createSlice } from '@reduxjs/toolkit';
export const DataProductLibrarySlice = createSlice({
  /**DPD ->> Data Product Designer */

  name: 'dataProduct',
  initialState: {
    targetModal: '',
    projectHeaderShow: false,
    rowDataForRuleEditor: {},
    radioCheck: false,
    loadDataProducts: false,
    openPanelDPD: true,
    previewDataLoading: false,
    editDataProduct : '',
    filteredRulesetInfo : []
  },
  reducers: {

    setTargetDataModal(state, { payload }) {
      state.targetModal = payload;
    },
    setPreviewDataLoading(state, { payload }) {
      state.previewDataLoading = payload;
    },
    showProjectHeaderFromDataProducts(state, { payload }) {
      state.projectHeaderShow = payload;
    },
    projectHeaderDisplay(state, { payload }) {
      state.projectHeaderShow = payload;
    },
    getRowDataForRuleEditor(state, { payload }) {
      state.rowDataForRuleEditor = payload;
    },
    setbuttondatacheck(state, { payload }) {
      state.radioCheck = payload;
    },
    getLoadedDataProducts: (state, action) => {
      state.loadDataProducts = action.payload;
    },
    openDataProductPanel(state, { payload }) {
      state.openPanelDPD = payload;
    }, 
    setEditDataProduct(state, { payload }) {
      state.editDataProduct = payload;
    }, 
    setFilteredRulesetInfo(state, { payload }) {
      state.filteredRulesetInfo = payload;
    }, 
  }
});

export const {
  getLoadedDataProducts,
  setbuttondatacheck,
  setPreviewDataLoading,
  projectHeaderDisplay,
  getRowDataForRuleEditor,
  setTargetDataModal,
  showProjectHeaderFromDataProducts,
  openDataProductPanel,
  setEditDataProduct,
  setFilteredRulesetInfo
} = DataProductLibrarySlice.actions;
