/*eslint-disable*/
import { DATAEXPORT_DESTINATIONS } from 'Constants/DataExportDestinations';
import { setDataExportDetails } from 'Redux/Slice/PublishMappingSpecSlice';
import { Cog, ChevronLeft, Plus } from 'apollo-react-icons';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import Checkbox from 'apollo-react/components/Checkbox';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import Table from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import Loader from 'Components/Loader/Loader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FolderManager from './ExportSettings/FolderManager';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { GetConfigurefolders } from 'Redux/Service/PublishMappingSpecService';
import { unwrapResult } from '@reduxjs/toolkit';
import CircularProgress from '@mui/material/CircularProgress';

const CheckboxCell = ({ row }) => {
  return (
    <div style={{ width: 16 }}>
      <Checkbox disabled={!row.isActive} checked={row.selected} onChange={row.onSelectChange} />
    </div>
  );
};

const DestinationCell = ({ row, column }) => {
  return <div>{row[column.accessor]}</div>;
};

const DatabaseCell = ({ row }) => {
  return row.locationOptions ? (
    <div className="db_domain_preview" style={{ paddingRight: '20px' }}>
      <Select
        value={row.location}
        fullWidth
        disabled={!row.isActive}
        onChange={row.onDatabaseChange}
        placeholder="Select a Database">
        {row.locationOptions.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;
};

const ExportSettingCell = ({ row }) => {
  return (
    row.selected && (
      <Button
        variant="text"
        icon={<Cog />}
        size="small"
        style={{ marginRight: 10 }}
        disabled={!row.location}
        onClick={row.onExportSettingClick}>
        {row?.exportPreferences ? 'User Defined' : 'System Defined'}
      </Button>
    )
  );
};

const PROXIMA_NOVA_FONT = 'Proxima Nova';

const ExportFolderCell = ({ row }) => {
  return (
    row.selected &&
    (row.destination === DATAEXPORT_DESTINATIONS.CDR ? (
      <div>Not Applicable</div>
    ) : (
      <>
        <Tooltip title={row.location === '' && 'Please select a loction to export'} placement="top">
          <span>
            <Button
              variant="secondary"
              icon={
                row.isLoading &&
                row.exportLocationID === row.selectExportDetails?.exportLocationID ? (
                  () => <CircularProgress size={16} sx={{ mr: '5px' }} />
                ) : Object.values(row?.exportLocationNewFolder || {}).length > 0 ||
                  Object.values(row?.exportLocationBaseFolder || {}).length > 0 ? (
                  <StatusCheck />
                ) : (
                  <Plus />
                )
              }
              onClick={row.onConfigureFolderClick}
              size="small"
              disabled={!row.location || row.isLoading}
              style={{ marginRight: 10 }}>
              Configure Folder
            </Button>
          </span>
        </Tooltip>
      </>
    ))
  );
};

const columns = [
  {
    header: '',
    accessor: '',
    customCell: CheckboxCell,
    width: 50,
    fixedWidth: false
  },
  {
    header: 'Destination',
    accessor: 'destination',
    customCell: DestinationCell,
    width: 200,
    fixedWidth: false
  },
  {
    header: 'Database / Location',
    accessor: 'location',
    customCell: DatabaseCell,
    width: 300,
    fixedWidth: false
  },
  {
    header: 'Username',
    accessor: 'userName',
    width: 250,
    fixedWidth: false
  },
  {
    header: 'Preferences',
    accessor: 'connection',
    customCell: ExportSettingCell,
    width: 200,
    fixedWidth: false
  },
  {
    header: 'Export Folder',
    accessor: 'connection',
    customCell: ExportFolderCell,
    width: 200,
    fixedWidth: false
  }
];

const ExportConfigurationTable = (props) => {
  const {
    handleReturn,
    dataExport,
    setDataExport,
    setActiveStep,
    activeStep,
    loading,
    handleExportSetting
  } = props;
  const dispatch = useDispatch();
  const [configureFolderModal, setConfigureFolderModal] = useState(false);
  const { mappingSpecDetails } = useSelector((state) => state.PublishMappingSpecData);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [configureFolderStructures, setConfigureFolderStructures] = useState([]);
  const [selectExportDetails, setSelectExportDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsNextDisabled(
      !dataExport?.every(
        (row) =>
          !row.selected ||
          (row.location && (row.destination === 'CDR' || row.exportLocationBaseFolder?.length))
      )
    );
  }, [dataExport]);

  const handleClickConfigureFolder = async (row) => {
    if (row.selected && row.destination !== DATAEXPORT_DESTINATIONS.CDR && row.location !== '') {
      setSelectExportDetails({ ...row });
    } else {
      setSelectExportDetails({});
    }
  };

  /**
   * Fetches the folder structure for the selected export location,
   * by default only 2 levels of subfolders are fetched,
   * for fetching depper folder structure the targetPath need to be provided
   * @param {string[]} folderpath
   */
  const getConfigurefolders = async (folderpath) => {
    try {
      if (Object.values(selectExportDetails || {}).length > 0) {
        setIsLoading(true);
        const configFolder = await dispatch(
          GetConfigurefolders({
            destination: selectExportDetails?.destination,
            location: selectExportDetails?.location,
            folderpath
          })
        ).then(unwrapResult);

        if (configFolder?.data?.success) {
          const { folderList } = configFolder.data;
          setIsLoading(false);
          return JSON.parse(folderList);
        } else {
          dispatch(showBanner({ variant: 'error', message: configFolder.data.message }));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(
        showBanner({
          variant: 'error',
          message: 'Error while parsing folder structure | GetConfigurefolders'
        })
      );
    }
    setIsLoading(false);
    return;
  };

  const getFolderStructure = async () => {
    const folderList = await getConfigurefolders();
    if (folderList) {
      setConfigureFolderStructures(folderList);
      setConfigureFolderModal(true);
    }
  };

  useEffect(() => {
    getFolderStructure();
  }, [selectExportDetails]);

  const handleNext = () => {
    dispatch(setDataExportDetails(dataExport));
    setActiveStep(activeStep + 1);
  };

  const updateRow = (thisRow, valuesToUpdate) => {
    const keyName = Object.keys(valuesToUpdate).toString();
    const keyValue = Object.values(valuesToUpdate).toString();
    const sameUpdate = keyName === 'location' ? thisRow[keyName].includes(keyValue) : false;
    const value = keyName === 'location' ? (item) => item === keyValue : undefined;

    let newRows = dataExport?.map((row) => {
      if (row.destination === thisRow.destination && !sameUpdate) {
        const updateRow = {
          ...valuesToUpdate,
          password:
            value !== undefined
              ? row.passwordOptions[thisRow.locationOptions.findIndex(value)]
              : row.password,
          userName:
            value !== undefined
              ? row.userNameOptions[thisRow.locationOptions.findIndex(value)]
              : row.userName,
          exportLocationID:
            value !== undefined
              ? row.exportLocationIdOptions[thisRow.locationOptions.findIndex(value)]
              : row.exportLocationID,
          connection: keyName === 'location' ? keyValue : row.connection
        };
        row = {
          ...row,
          ...updateRow
        };
        return row;
      } else {
        return row;
      }
    });
    setDataExport(newRows);
  };

  const handleSaveExportPath = (exportPath, isBacthId, isNewFolderCreated) => {
    if (isNewFolderCreated) {
      updateRow(selectExportDetails, {
        exportLocationNewFolder: isNewFolderCreated.newFolderPath,
        exportLocationBaseFolder: isNewFolderCreated.existingFolderPath,
        isBacthId
      });
    } else {
      updateRow(selectExportDetails, {
        exportLocationBaseFolder: exportPath,
        exportLocationNewFolder: [],
        isBacthId
      });
    }
    setConfigureFolderModal(false);
  };

  const handleCancel = () => {
    setConfigureFolderModal(false);
    setConfigureFolderStructures([]);
  };

  return (
    <>
      <Button
        icon={
          <ChevronLeft
            style={{
              height: '15px'
            }}
          />
        }
        style={{
          backgroundColor: 'white',
          color: '#0768FD',
          fontFamily: 'Proxima Nova',
          fontSize: '13px',
          fontWeight: 600,
          marginBottom: '1rem'
        }}
        variant={'text'}
        size={'small'}
        onClick={handleReturn}
        data-testid="return-to-data-products">
        {location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
          ? 'Return to Manage Data Pipelines'
          : 'Return to data products'}
      </Button>
      <Typography
        variant="title1"
        gutterBottom
        style={{
          fontSize: '20px',
          color: '#000000',
          lineHeight: '48px',
          letterSpacing: 0,
          height: '24px',
          fontFamily: PROXIMA_NOVA_FONT
        }}>
        Data Export <span style={{ fontWeight: 'normal' }}>(Optional)</span>
      </Typography>

      {loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <Card style={{ marginTop: '3%' }}>
          <CardContent style={{ padding: '0px' }}>
            <Grid container style={{ padding: '20px' }}>
              <Grid item xs={6}>
                <Typography
                  variant="title2"
                  gutterBottom
                  style={{
                    fontSize: '16px',
                    color: '#000000',
                    fontWeight: '600',
                    lineHeight: '24px',
                    fontFamily: PROXIMA_NOVA_FONT
                  }}>
                  Export Configuration
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  style={{
                    fontSize: '14px',
                    color: '#595959',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    fontFamily: PROXIMA_NOVA_FONT
                  }}>
                  Select the location where you would like to export your data
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Table
                  columns={columns}
                  rows={dataExport?.map((row) => ({
                    ...row,
                    key: row.destination,
                    targetDataModelType: mappingSpecDetails.targetDataModelType,
                    onSelectChange: () => updateRow(row, { selected: !row.selected }),
                    onDatabaseChange: (e) => updateRow(row, { location: e.target.value }),
                    onExportSettingClick: () => handleExportSetting(row),
                    onConfigureFolderClick: () => handleClickConfigureFolder(row),
                    isLoading: isLoading,
                    selectExportDetails: selectExportDetails
                  }))}
                  rowsPerPageOptions={[5]}
                  hasScroll
                  maxHeight={650}
                  tablePaginationProps={{
                    labelDisplayedRows: () => {}
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="text"
          size="small"
          data-testid="previousBtn"
          style={{ padding: 0 }}
          onClick={() => setActiveStep(activeStep - 1)}>
          Previous
        </Button>
        <Tooltip title={isNextDisabled && 'Configure Folder to proceed'} placement="left">
          <span style={{ display: 'inline-block' }}>
            <Button
              variant="primary"
              onClick={handleNext}
              size="small"
              data-testid="nextBtn"
              disabled={isNextDisabled}>
              {'Next'}
            </Button>
          </span>
        </Tooltip>
      </div>
      {configureFolderStructures.length > 0 && (
        <FolderManager
          configureFolderModal={configureFolderModal}
          configureFolderStructures={configureFolderStructures}
          handleSaveExportPath={handleSaveExportPath}
          handleCancel={handleCancel}
          setConfigureFolderModal={setConfigureFolderModal}
          setConfigureFolderStructures={setConfigureFolderStructures}
          getFolderStructure={getConfigurefolders}
        />
      )}
    </>
  );
};

export default ExportConfigurationTable;
