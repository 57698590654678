import axios from 'axios';
import { getHeadersFromCookies, getHeadersForAIAAS } from '../service/header.service';

export const goToCore = () => {
  window.location.href = process.env.REACT_APP_CORE_URL;
};

axios.interceptors.request.use(
  (request) => {
    let customHeaders;
    if (
      request.url?.startsWith(process.env.REACT_APP_AIAAS_API_URL) &&
      Boolean(process.env.REACT_APP_AIAAS_API_URL)
    ) {
      customHeaders = getHeadersForAIAAS();
    } else {
      customHeaders = getHeadersFromCookies();
    }
    request.headers = { ...request.headers, ...customHeaders };
    return request;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    goToCore()
  }
  return error;
});
