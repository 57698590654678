/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const BreadcrumbSlice = createSlice({
  name: 'BreadcrumbData',
  initialState: {
    items: []
  },
  reducers: {
    breadCrumbData(state, { payload }) {
      state.items = payload;
    },
    logout(state, { payload }) {}
  }
});

export const { breadCrumbData, logout } = BreadcrumbSlice.actions;
