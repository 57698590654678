import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useFetch = (props = '') => {
  const [cardTitle, setCardTitle] = useState('');
  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  useEffect(() => {
    if (props !== '') {
      let title = workFlowModel.referenceData.filter((card) => {
        if (props === card.moduleName) {
          return card.displayName;
        }
      });
      setCardTitle(title);
    }
  }, [workFlowModel.referenceData]);
  return { cardTitle };
};

export default useFetch;
