/* eslint-disable */
import { ErrorConstants } from './ErrorConstants';
import { referenceDataService } from 'Services/ReferenceData.Service';
const {
  timePointsUniqueError,
  flagValueError,
  numberError,
  mandatoryError,
  blankError,
  referencePointError,
  blankOrFilled,
  nameAndNumberMapError,
  unplannedVisitDescBlankError,
  invalidVisitError
} = ErrorConstants;

let uniqueObj = {};
let nameNumberMapping = {};

const checkUnique = (record) => {
  const { visit, visitnum, tvgrpid, tvclrid, tptmref, tptmpnt, tptmnum } = record;
  let value = `${visit?.toString().toLowerCase()}-${visitnum?.toString().toLowerCase()}-${tvgrpid
    ?.toString()
    .toLowerCase()}-${tvclrid?.toString().toLowerCase()}-${tptmref
    ?.toString()
    .toLowerCase()}-${tptmpnt?.toString().toLowerCase()}-${tptmnum?.toString().toLowerCase()}`;
  if (Object.keys(uniqueObj).length > 0 && uniqueObj[value]) {
    return {
      visit: { unique: timePointsUniqueError },
      visitnum: { unique: timePointsUniqueError },
      tvgrpid: { unique: timePointsUniqueError },
      tvclrid: { unique: timePointsUniqueError },
      tptmref: { unique: timePointsUniqueError },
      tptmpnt: { unique: timePointsUniqueError },
      tptmnum: { unique: timePointsUniqueError }
    };
  } else {
    uniqueObj[value] = true;
    return {};
  }
};

const checkVisitPlan = (record, trialVisitValidationResult) => {
  let value = `${record.visit}-${record.visitnum}-${record.tvgrpid}-${record.tvclrid}`;
  if (trialVisitValidationResult && Object.keys(trialVisitValidationResult).length > 0) {
    return trialVisitValidationResult.getIsVisitPlanned(value);
  }
  return null;
};

const checkMandatory = (record, flag) => {
  let { visit, visitnum, tpupdes } = record;
  let obj = {};
  if (flag) {
    if (!visit) {
      obj.visit = { mandatory: mandatoryError };
    }
    if (!visitnum) {
      obj.visitnum = { mandatory: mandatoryError };
    }
    if (!tpupdes) {
      obj.tpupdes = { mandatory: mandatoryError };
    }
  } else {
    if (!visit) {
      obj.visit = { mandatory: mandatoryError };
    }
    if (!visitnum) {
      obj.visitnum = { mandatory: mandatoryError };
    }
  }
  return obj;
};

const checkIsFilledOrBlank = (record) => {
  const { tptmref, tptmpnt, tptmnum } = record;
  if (!((!tptmref && !tptmpnt && !tptmnum) || (tptmref && tptmpnt && tptmnum))) {
    return {
      tptmref: { blankFilled: blankOrFilled },
      tptmpnt: { blankFilled: blankOrFilled },
      tptmnum: { blankFilled: blankOrFilled }
    };
  }
};

const checkNameAndNumberMapping = (record) => {
  const { tptmpnt, tptmnum } = record;
  if (
    Object.keys(nameNumberMapping).length > 0 &&
    nameNumberMapping[tptmpnt] &&
    nameNumberMapping[tptmpnt] !== tptmnum
  ) {
    return {
      tptmpnt: { nameAndNumberMap: nameAndNumberMapError },
      tptmnum: { nameAndNumberMap: nameAndNumberMapError }
    };
  } else {
    nameNumberMapping[tptmpnt] = tptmnum;
    return {};
  }
};

const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

const checkUnplannedVisitDescBlank = (record) => {
  let obj = {};
  if (record.tpupdes) {
    obj.tpupdes = { visitDescBlank: unplannedVisitDescBlankError };
  }
  return obj;
};

export const timePointValidator = async (
  records,
  trialVisitValidationResult,
  selectedDataSourcesData,
  trialVisitData
) => {
  let serviceResult = await referenceDataService(selectedDataSourcesData);
  let errorColumnCount = 0;
  let errorRowsId = [];
  let error = {};
  records.forEach((record) => {
    let obj = {};
    const unplannedVisit = checkVisitPlan(record, trialVisitValidationResult);
    const unique = checkUnique(record);
    let mandatory = {};
    let unplannedVisitDescBlank = {};
    mandatory = checkMandatory(record, false);
    const filledOrBlank = checkIsFilledOrBlank(record);
    const nameAndNumberMapping = checkNameAndNumberMapping(record);
    if (unplannedVisit && unplannedVisit.trim().toLowerCase() === 'yes') {
      mandatory = checkMandatory(record, true);
    } else if (unplannedVisit && unplannedVisit.trim().toLowerCase() === 'no') {
      unplannedVisitDescBlank = checkUnplannedVisitDescBlank(record);
    }
    // else {
    //   alert('There is no mapping for this row');
    // }
    const edcObj = serviceResult.isValidEDC(record.edc);
    const qecgObj = serviceResult.isValidQecg(record.qecg);
    const qlabObj = serviceResult.isValidLab(record.qlab);
    const checkSources = serviceResult.checkIsEDCSelected(
      record.edc,
      record.qlab,
      record.qecg,
      record.tabular
    );
    const checkTimeVisit = validateTimepoint(record.visit);
    mergeObject(obj, checkSources);
    mergeObject(obj, qecgObj);
    mergeObject(obj, qlabObj);
    mergeObject(obj, edcObj);
    mergeObject(obj, unique);
    mergeObject(obj, checkTimeVisit);
    mergeObject(obj, mandatory);
    mergeObject(obj, nameAndNumberMapping);
    mergeObject(obj, filledOrBlank);
    mergeObject(obj, unplannedVisitDescBlank);
    if (obj && Object.keys(obj).length > 0) {
      errorRowsId.push(record.id);
      errorColumnCount = errorColumnCount + Object.keys(obj).length;
    }

    error[record.id] = obj;
  });
  uniqueObj = {};
  nameNumberMapping = {};

  function validateTimepoint(visit) {
    for (const trial of trialVisitData) {
      if (trial.visit === visit) {
        return {};
      }
    }

    return { visit: { mandatory: invalidVisitError } };
  }

  const errorCount = () => {
    let count = 0;
    for (let i = 0; i <= error.length; i++) {
      if (error[i]) {
        count = count + Object.keys(error[i]).length;
      }
    }
    return count;
  };

  const errorKeys = () => {
    let array = [];
    error &&
      error.map((el, index) => {
        if (Object.keys(el).length > 0) {
          array.push(index);
        }
      });

    return array;
  };

  const isColumnValid = (uuid, columnName) => {
    let columnError = '';
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      columnError = columnName && row[columnName] ? row[columnName] : '';
    }
    return columnError;
  };

  const isRowValid = (uuid) => {
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row[uuid]).length > 0) {
      return false;
    }
    return true;
  };

  const getErrorRowIndex = () => {
    return errorRowsId;
  };

  const getColumnErrorCount = () => {
    return errorColumnCount;
  };

  const isVisitPlanned = (key) => {
    return plannedMapping[key];
  };

  return {
    isColumnValid: isColumnValid,
    isRowValid: isRowValid,
    errorCount: errorCount,
    errorKeys: errorKeys,
    getErrorRowIndex: getErrorRowIndex,
    getErrorCount: getColumnErrorCount,
    getIsVisitPlanned: isVisitPlanned
  };
};
export default { timePointValidator };
