/*eslint-disable */
import { IconButton, Tooltip } from 'apollo-react';
import ConfigSection from '../Components/ConfigSection';
import Radio from 'apollo-react/components/Radio';
import RadioGroup from 'apollo-react/components/RadioGroup';
import { Info } from 'apollo-react-icons';

const ExportType = (props) => {
  const {
    exportTypeMetaData,
    selectedExportType,
    setSelectedExportType,
    exportTypeTooltipMessage,
    exportTypeMetaDataLoading
  } = props;

  const handleChange = (e) => {
    setSelectedExportType(e.target.value);
  };

  return (
    <ConfigSection
      title="Export Type"
      subtitle="Set up automated data exports to maintain timely and accurate information effortlessly."
      loading={exportTypeMetaDataLoading}>
      <RadioGroup
        label={
          <>
            Export Type
            <Tooltip
              variant="dark"
              extraLabels={exportTypeTooltipMessage}
              placement="right"
              sx={{ height: '1rem', width: '1rem', marginLeft: '4px' }}>
              <IconButton color="neutral7">
                <Info style={{ width: '16px', maxHeight: '16px' }} />
              </IconButton>
            </Tooltip>
          </>
        }
        aria-label="export-type"
        name="export-type"
        value={selectedExportType}
        onChange={handleChange}
        sx={{ pl: '0.5rem', pt: '0.5rem' }}>
        {exportTypeMetaData.map((e, index) => (
          <Radio value={e.exportTypeName} disabled={props.readOnly} label={e.exportTypeName} />
        ))}
      </RadioGroup>
    </ConfigSection>
  );
};

export default ExportType;
