import { unwrapResult } from '@reduxjs/toolkit';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetCloneDataProductSteppers } from 'Redux/Service/CloneDataProductService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import styled from 'styled-components';

export const Title = styled(Typography)({
  fontWeight: '600',
  fontSize: '20px',
  lineHeight: '29px',
  color: '#444444',
  fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
});

export const Caption = styled(Typography)(({ sx = {} }) => ({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#595959',
  fontFamily: 'Proxima Nova, Nunito Sans, sans-serif',
  ...sx
}));

export const StudyInfoWrapper = styled(Grid)({
  marginTop: '1rem',
  marginBottom: '0.5rem'
});

export const TableWrapper = styled(Grid)({
  marginTop: '1rem',
  '& td.expandedRowEditMode': {
    padding: 0,
    '& .MuiCollapse-wrapperInner>.MuiGrid-container': {
      padding: '0 1rem',
      backgroundColor: '#f6f7fb'
    }
  }
});

export const useCloneWorkflow = () => {
  const [functionData, setFunctionData] = useState([]);
  const dispatch = useDispatch();

  const getCloneDataProductSteppers = async () => {
    try {
      const response = await dispatch(GetCloneDataProductSteppers()).then(unwrapResult);
      if (response?.data?.success && response?.data?.functionData) {
        setFunctionData(response.data.functionData);
      } else {
        dispatch(showBanner({ variant: 'error', message: response.data.success }));
      }
    } catch (error) {
      console.log('Error ::', error);
    }
  };

  useEffect(() => {
    getCloneDataProductSteppers();
  }, []);

  return functionData;
};
