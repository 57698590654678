import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// export const GetLoginInfo = createAsyncThunk(
//     'RuleEditor/GetUserAuth',
//     async (data) => {
//       return await axios.post(`${API_URL}/api/RuleEditor/GetUserAuth`, data);
//     }
//   );

  export const GetLoginInfo = createAsyncThunk(
    'RuleEditor/GetUserAuth',
    async (userID) => await axios.get(`${API_URL}/api/UserAuth/GetUserMiscellaneousAuth?userId=${userID}`)
  );