import { useDispatch } from 'react-redux';
import { GetMappingSpecViewComments } from 'Redux/Service/PublishMappingSpecService';
import ActivityCreate from 'Images/activity-create.svg';
import ActivityCopy from 'Images/activity-copy.svg';
import ActivityPublish from 'Images/activity-publish.svg';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';

export const useMappingSpecActivity = () => {
  const dispatch = useDispatch();

  const handleFetchMappingSpecActivity = async (mapSpec) => {
    const {
      data: { success, mappingSpecViewComments, message }
    } = await dispatch(
      GetMappingSpecViewComments({
        mappingRuleVersionID: mapSpec.mappingRuleVersionID
      })
    ).then(unwrapResult);
    if (success) {
      const mappingSpecViewCommentsSorted = [
        ...mappingSpecViewComments.map((comment) => {
          if (comment.comments !== 'Copied to draft') {
            return { ...comment, icon: ActivityPublish };
          } else return { ...comment, icon: ActivityCopy };
        })
      ];
      mappingSpecViewCommentsSorted.sort(function (a, b) {
        return Date.parse(b.createdDate) - Date.parse(a.createdDate);
      });
      return [
        {
          icon: ActivityCreate,
          createdBy: mapSpec.createdBy,
          createdDate: mapSpec.createdDate,
          comments: mapSpec.comments
        }
      ].concat(mappingSpecViewCommentsSorted.reverse());
    }
    dispatch(showBanner({ variant: 'error', message }));
    return [];
  };

  return [handleFetchMappingSpecActivity];
};
