/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Divider from 'apollo-react/components/Divider';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Button from 'apollo-react/components/Button';
import Step from 'apollo-react/components/Step';
import StepLabel from 'apollo-react/components/StepLabel';
import Stepper from 'apollo-react/components/Stepper';
import { useSelector, useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveAddStudyLibrary } from 'Redux/Service/AddStudyLibraryService';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomModal from '../Modal';
import TextField from 'apollo-react/components/TextField';
import md5 from 'md5';
import Cookies from 'js-cookie';
import { getVariableRuleSets } from 'Redux/Service/AddGlobalLibraryService';
import { setDisableFinishButton } from 'Redux/Slice/AddStudyLibrarySlice';
import { getPreSQLQueries } from 'Redux/Service/PreSQLEditorService';
import { DATA_FLOW_TYPES } from 'Constants/DataFlowTypes';

export default function StudyLibrarySideBar({
  onSubmitRef,
  isValidate,
  validateRuleSet,
  handleStepperback,
  getPayload
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddForm, setIsAddForm] = useState(true);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [reasonForChange, setReasonForChange] = React.useState(false);
  const [justification, setJustification] = React.useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [isReason, setIsReason] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtn] = useState(false);

  const studyLibraries = useSelector(
    (state) => state.StudyLibraryData?.studyLibraries?.data?.studyLibraries
  );
  const { studyLibraryRowData } = useSelector((state) => state.EditStudyData);
  const { disableFinishButton, studyObj } = useSelector((state) => state.AddStudyLibrary);

  const dispatch = useDispatch();

  let steps = [isAddForm ? 'Add Study Library' : 'Edit Study Library', 'Preview'];
  const protocol = useSelector((state) => {
    return state.StudyLibraryData.protocol;
  });
  const studyLibrary_path = '/data-standard-library/study-library';
  const stepName = activeStep === steps.length - 1 ? 'Finish' : 'Next';

  useEffect(() => {
    if (
      !location.pathname
        .substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length)
        .startsWith('add')
    ) {
      setIsAddForm(false);
    }
  });

  const versionIncrement = () => {
    let version = 0;
    if (!isAddForm) {
      const filteredStudyData = studyLibraries.filter((studyLibrary) => {
        return studyLibrary.libraryID === studyLibraryRowData.libraryID;
      });
      filteredStudyData.forEach((f) => {
        if (version < f.libraryVersion) {
          version = f.libraryVersion;
        }
      });
    }
    return version + 1;
  };

  const studyLibraryPayload = () => {
    const data = getPayload();
    const variableRulesets = [];
    const preSQLQueryDetails = [];

    const version = versionIncrement();
    const studyId = [];
    const studyVersion = [];
    const studyMetadataVersion = [];
    if (data.metadataVersion) {
      data.metadataVersion.forEach((item) => {
        const data = item.split(',');
        studyId.push(data[0]);
        studyVersion.push(data[1]);
        studyMetadataVersion.push(data[2]);
      });
    }

    data.type === 'Ruleset' &&
      data.variableRulesets.forEach((item) => {
        variableRulesets.push({
          variableName: item.variableName,
          label: item.label,
          expression: item.expression.trim(),
          status: item.status
        });
      });

    const variableRuleSetsObj =
      data.type === 'Ruleset'
        ? {
            variableRulesets: variableRulesets
            // domainColumns: null,
          }
        : {};

    const nonRuleSetData = {
      studyProtocolNumber: protocol.protocolNumber,
      libraryID: isAddForm ? '' : studyLibraryRowData.libraryID,
      libraryType: data.type,
      libraryName: data.name,
      libraryDescription: data.description ? data.description : '',
      libraryVersion: version,
      source:
        data.type === 'CDR Tabular'
          ? data.source.join(',')
          : !data.source?.length
          ? ''
          : data.source,
      dataSetTypeID: data.CDRDatasetType || undefined,
      dataSetDisplayName: data.dataSetDisplayName || '',
      metaDataVersions: studyMetadataVersion.length > 0 ? studyMetadataVersion : [],
      isactive: true,
      userId: Cookies.get('user.id'),
      vendorName: data.vendor,
      createdDate: new Date().toISOString(),
      auditType: isAddForm ? 'INSERT' : 'UPDATE',
      isCDRTestFlow:
        data.type === 'Custom SQL Library' &&
        data.dataSourceTrailValues?.selectedDataSource === 'CDR Tabular'
          ? data.dataSourceTrailValues?.selectedTrialName?.trialName === DATA_FLOW_TYPES.TEST
          : studyObj.isCDRTestFlow,
      auditDate: new Date().toISOString(),
      studyVersions: studyVersion.length > 0 ? studyVersion : [],
      isCustomSQLLibrary: data.type === 'Custom SQL Library',
      ecrfTrialName: data.eCRFTrialName,
      edcTrailName:
        data.type === 'Custom SQL Library' &&
        data.dataSourceTrailValues?.selectedDataSource === 'CDISC ODM'
          ? data.dataSourceTrailValues?.selectedTrialName?.trialName
          : undefined
    };

    data.type === 'Custom SQL Library' &&
      data.dataSet.forEach((item, index) => {
        preSQLQueryDetails.push({
          queryID: item.queryID,
          libraryID: isAddForm ? '' : studyLibraryRowData.libraryID,
          libraryVersion: version,
          datasetName: item.datasetName,
          source: item.source,
          trialName: item.trialName,
          studyID: item.studyID,
          datasetDescription: item.datasetDescription,
          queryString: item.queryString,
          queryType: 'select',
          queryColumns: item.queryColumns,
          executionOrder: index + 1,
          isQueryActive: item.isQueryActive,
          iqActiveFlag: true,
          iqAuditType: isAddForm ? 'INSERT' : 'UPDATE',
          iqAuditDate: new Date().toISOString(),
          iqCreateBy: Cookies.get('user.id'),
          isAzureEnv: item.isAzureEnv,
          dataBaseName: item.dataBaseName
        });
      });

    if (data.type === 'Ruleset') {
      return {
        studyLibraryData: nonRuleSetData,
        variableRulesets: variableRuleSetsObj.variableRulesets
      };
    } else if (data.type === 'Custom SQL Library') {
      return {
        studyLibraryData: nonRuleSetData,
        preSQLQueryDetails: preSQLQueryDetails
      };
    } else {
      return {
        studyLibraryData: nonRuleSetData,
        studyLibrarySources: data.type === 'CDR Tabular' ? data.source : [],
        studyLibraryEDCStudyID: studyId.length > 0 ? studyId[0] : 0
      };
    }
  };

  const compareBothVariableRuleSets = (oldExpValidation, newExpValidation) => {
    let flagCheck = true;
    for (var i = 0; i < oldExpValidation.length; i++) {
      if (oldExpValidation[i]?.variableRuleSetID !== newExpValidation[i]?.variableRuleSetID)
        flagCheck = false;
    }
    return (
      oldExpValidation.length === newExpValidation.length &&
      flagCheck &&
      oldExpValidation.every((oldExp) =>
        newExpValidation.some(
          (newExp) =>
            oldExp.variableName === newExp.variableName &&
            oldExp.label === newExp.label &&
            oldExp.status === newExp.status &&
            oldExp.expression === newExp.expression &&
            oldExp.variableRuleSetID === newExp.variableRuleSetID
        )
      )
    );
  };

  const compareBothPreSQL = (oldExpValidation, newExpValidation) => {
    return (
      oldExpValidation.length === newExpValidation.length &&
      oldExpValidation.every((oldExp) =>
        newExpValidation.some(
          (newExp) =>
            oldExp.datasetName === newExp.datasetName &&
            oldExp.queryColumns === newExp.queryColumns &&
            oldExp.queryString === newExp.queryString &&
            oldExp.source === newExp.source &&
            oldExp.trialName === newExp.trialName &&
            oldExp.datasetDescription === newExp.datasetDescription &&
            oldExp.isQueryActive === newExp.isQueryActive
        )
      )
    );
  };

  const getPreSQLQueriesOnEdit = async () => {
    const preSQLQueries = await dispatch(
      getPreSQLQueries({
        libraryID: studyLibraryRowData.libraryID,
        version: studyLibraryRowData.libraryVersion
      })
    ).then(unwrapResult);
    if (preSQLQueries?.data?.success) {
      return preSQLQueries.data.preSQLQueries;
    } else {
      dispatch(showBanner({ variant: 'error', message: preSQLQueries?.data?.message }));
    }
  };

  const getVariableRuleSetsOnEdit = async () => {
    const variableRuleSets = await dispatch(getVariableRuleSets(studyLibraryRowData)).then(
      unwrapResult
    );
    if (variableRuleSets?.data?.success) {
      return variableRuleSets.data.variableRulesets;
    } else {
      dispatch(showBanner({ variant: 'error', message: variableRuleSets?.data?.message }));
    }
  };

  const computeRulesetHash = async () => {
    let isFormValueChanged = false;
    if (studyLibraryRowData) {
      const variableRuleSetData = await getVariableRuleSetsOnEdit();
      const data = await getPayload();
      const oldExpValidation = variableRuleSetData;
      const newExpValidation =
        data.variableRulesets.length === 0 && !isAddForm
          ? variableRuleSetData
          : data.variableRulesets;

      const flag = await compareBothVariableRuleSets(oldExpValidation, newExpValidation);

      const oldUserData = {
        libraryType: studyLibraryRowData.libraryType,
        libraryName: studyLibraryRowData.libraryName,
        description: studyLibraryRowData.libraryDescription,
        source: studyLibraryRowData.source,
        metaDataVersion: studyLibraryRowData.metaDataVersion
      };
      const newUserData = {
        libraryType: data.type,
        libraryName: data.name,
        description: data.description,
        source: data.source,
        metaDataVersion: data.metadataVersion.join(',')
      };
      isFormValueChanged =
        flag && md5(JSON.stringify(oldUserData)) === md5(JSON.stringify(newUserData));
    }
    return isFormValueChanged;
  };

  const computePreSQLHash = async () => {
    let isFormValueChanged = false;
    const preSQLQueries = await getPreSQLQueriesOnEdit();
    const data = studyLibraryPayload();
    const oldExpValidation = preSQLQueries;
    const newExpValidation = data.preSQLQueryDetails;

    const flag = compareBothPreSQL(oldExpValidation, newExpValidation);

    const oldUserData = {
      libraryType: studyLibraryRowData.libraryType,
      libraryName: studyLibraryRowData.libraryName,
      description: studyLibraryRowData.libraryDescription,
      metaDataVersion: studyLibraryRowData.metaDataVersion
    };
    const newUserData = {
      libraryType: data.studyLibraryData.libraryType,
      libraryName: data.studyLibraryData.libraryName,
      description: data.studyLibraryData.libraryDescription,
      metaDataVersion: data.studyLibraryData.metaDataVersions.join(',')
    };
    isFormValueChanged =
      flag && md5(JSON.stringify(oldUserData)) === md5(JSON.stringify(newUserData));
    return isFormValueChanged;
  };

  const computeHash = async () => {
    let isFormValueChanged = false;
    if (studyLibraryRowData && studyLibraryRowData.libraryType === 'Custom SQL Library') {
      isFormValueChanged = computePreSQLHash();
    } else if (studyLibraryRowData) {
      const data = studyLibraryPayload();
      const oldUserData = {
        libraryType: studyLibraryRowData.libraryType,
        libraryName: studyLibraryRowData.libraryName,
        description: studyLibraryRowData.libraryDescription,
        source: studyLibraryRowData.source,
        metaDataVersion: studyLibraryRowData.metaDataVersion
      };
      const newUserData = {
        libraryType: data.studyLibraryData.libraryType,
        libraryName: data.studyLibraryData.libraryName,
        description: data.studyLibraryData.libraryDescription,
        source: data.studyLibraryData.source,
        metaDataVersion: data.studyLibraryData.metaDataVersions.join(',')
      };
      isFormValueChanged = md5(JSON.stringify(oldUserData)) === md5(JSON.stringify(newUserData));
    }

    return isFormValueChanged;
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      if (!isAddForm) {
        (studyLibraryRowData.libraryType !== 'Ruleset' ? computeHash() : computeRulesetHash()).then(
          (isFormChanged) => {
            if (!isFormChanged) {
              setReasonForChange(true);
            } else {
              setReasonForChange(false);
              handleSubmitRef();
              dispatch(showBanner({ variant: 'info', message: 'No data to persist' }));
            }
          }
        );
      } else {
        setIsSubmitBtn(true);
        const payload = studyLibraryPayload();
        const saveResponse = await dispatch(saveAddStudyLibrary(payload)).then(unwrapResult);
        if (saveResponse && saveResponse.data.success) {
          dispatch(
            showBanner({ variant: 'success', message: saveResponse.data.message, propagate: true })
          );
          navigate(studyLibrary_path);
        } else {
          dispatch(showBanner({ variant: 'error', message: saveResponse.data.message }));
        }
        setIsSubmitBtn(false);
        return;
      }
    } else {
      handleSubmitRef();
    }
  };

  const handleSubmitRef = () => {
    validateRuleSet();
    onSubmitRef();
    const isFormValid = isValidate();
    if (isFormValid) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    handleStepperback();
    setActiveStep(activeStep - 1);
    setIsSubmitBtn(false);
    dispatch(setDisableFinishButton(false));
  };

  const handleBackToEdit = () => {
    handleStepperback();
    setActiveStep(activeStep - 2);
    setIsSubmitBtn(false);
  };

  const reasonForChangeValue = (e) => {
    setJustification(e.target.value);
  };

  const handleReset = () => {
    handleStepperback();
    setActiveStep(0);
    setConfirmAlert(false);
    navigate(studyLibrary_path);
  };

  const handleClose = () => {
    setConfirmAlert(false);
  };

  const openModal = async () => {
    setConfirmAlert(true);
  };

  const reasonForChangeClose = () => {
    setReasonForChange(false);
  };

  const reasonForChangeReset = async () => {
    if (justification) {
      setIsReason(true);
      const payload = studyLibraryPayload();
      payload.studyLibraryData.reasonForChange = justification;
      const saveResponse = await dispatch(saveAddStudyLibrary(payload)).then(unwrapResult);
      handleSubmitRef();
      if (saveResponse && saveResponse.data.success) {
        dispatch(
          showBanner({ variant: 'success', message: saveResponse.data.message, propagate: true })
        );
        navigate(studyLibrary_path);
      } else {
        dispatch(showBanner({ variant: 'error', message: saveResponse.data.message }));
      }
    } else {
      setIsReason(false);
    }
  };

  const handleCancel = () => {
    navigate(studyLibrary_path);
  };

  return (
    <>
      <Box>
        <Box pt={3} pb={3} px={3}>
          <Typography variant="h3">Workflow</Typography>
        </Box>
        <Divider type="dark" />
        <Box style={{ padding: '30px 20px' }}>
          <div style={{ maxWidth: 400 }}>
            <Stepper box activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label} style={{ padding: '18px 16px' }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Box display="flex" justifyContent="flex-end" flex={0.6}>
                    <Button
                      variant="secondary"
                      onClick={handleBackToEdit}
                      style={{ marginRight: '16px' }}>
                      Back
                    </Button>
                    <Button variant="secondary" onClick={handleReset}>
                      Reset
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    flex={0.4}
                    variant="text"
                    size="small"
                    // disabled={activeStep === 0}
                    label="Back"
                    onClick={openModal}
                    data-testid="Back"
                    style={{ paddingLeft: 0 }}>
                    Cancel
                  </Button>
                  <Box display="flex" justifyContent="flex-end" flex={0.6}>
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={handleBack}
                        style={{ marginRight: '16px' }}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      size="small"
                      onClick={handleNext}
                      disabled={disableFinishButton || isSubmitBtnDisabled}>
                      {stepName}
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        </Box>
      </Box>
      <CustomModal
        display={confirmAlert}
        title={'Leave Page?'}
        buttonPrimaryLabel={'Ok'}
        message={
          'Changes could not be saved if you leave this page, Would you still like to leave?'
        }
        handlePrimaryAction={() => handleReset()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => handleClose()}
      />
      {!isAddForm && (
        <CustomModal
          display={reasonForChange}
          title={'Reason For Change'}
          buttonPrimaryLabel={'Ok'}
          message={
            <>
              <Typography>Please provide a reason for the update(s) made.</Typography>
              <div style={{ maxWidth: 400 }}>
                <TextField
                  id="reasonForChange"
                  label=""
                  name="reasonForChange"
                  value={justification}
                  placeholder="Enter Text"
                  onChange={reasonForChangeValue}
                  fullWidth
                />
              </div>
              {!isReason && <div style={{ color: 'red' }}>Required Field</div>}
            </>
          }
          handlePrimaryAction={() => reasonForChangeReset()}
          buttonSecondardyLabel={'Cancel'}
          handleClose={() => reasonForChangeClose()}
        />
      )}
    </>
  );
}
