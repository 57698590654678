import React, { useMemo } from 'react';

import Warning from 'Images/Warning.svg';
import Info from 'Images/Info.svg';
import { makeStyles } from '@mui/styles';
import Close from 'apollo-react-icons/Close';

const useStyles = makeStyles({
  notification: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0.5rem',
    boxSizing: 'border-box',
    borderRadius: '4px',
    margin: '1rem 0',
    width: '100%',
    color: '#444444',
    minHeight: '48px',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
  },
  closeBtn: {
    height: '1rem',
    width: '1rem',
    cursor: 'pointer',
    float: 'right'
  },
  warning: {
    backgroundColor: '#FFEED6',
    border: '1px solid #FFD396',
    borderLeft: '4px solid #FF9300'
  },
  warningIcon: {
    '&:before': {
      verticalAlign: '-10%',
      margin: '0.5rem',
      content: `url(${Warning})`
    }
  },
  info: {
    backgroundColor: '#ECF3FF',
    border: '1px solid #C7DCFE',
    borderLeft: '4px solid #0768FD'
  },
  infoIcon: {
    '&:before': {
      verticalAlign: '-10%',
      margin: '0.5rem',
      content: `url(${Info})`
    }
  },

  fw600: { fontWeight: '600' }
});

/**
 * Notification component
 * TODO: Only info, warning variant implemented, need to implement other variants on need basis
 * @prop {string} variant
 * @prop {string} message
 * @prop {boolean} hide
 * @prop {string} header
 * @prop {function} onClose
 * @returns {React.JSXElement}
 */
export const Notification = ({
  variant = 'info',
  message = '',
  hide,
  header,
  onClose,
  className,
  ...restProps
}) => {
  const classes = useStyles();
  const _header = useMemo(() => {
    if (header !== undefined) {
      return header;
    }
    switch (variant) {
      case 'warning': {
        return 'Warning';
      }
      case 'info':
      default: {
        return 'Note';
      }
    }
  }, [variant, header]);

  if (hide) return <span></span>;

  return (
    <div className={`${classes.notification} ${classes[variant]} ${className}`} {...restProps}>
      <span className="wrapper">
        <span className={`icon ${classes.fw600} ${classes[variant + 'Icon']}`}>
          {_header ? `${_header}:` : ''}
        </span>
        &nbsp;
        <span className="message">{message}</span>
      </span>
      {onClose ? <Close className={classes.closeBtn} onClick={onClose} /> : <></>}
    </div>
  );
};
