import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  protocolDetails: {
    protocolNumber: '',
    sponsor: '',
    productMnemonic: '',
    mappingSpecVersion: '',
    environment: '',
    mappingRuleVersionID: '',
    dataSources: '',
    trialName: '',
    endTime: ''
  }
};

/**
 * DataRefreshHistorySlice - Action creator and action types corresponding to the
 * DataRefreshHistory reducers and state.
 */
export const DataRefreshHistorySlice = createSlice({
  name: 'DataRefreshHistory',
  initialState: INITIAL_STATE,
  reducers: {
    setRefreshHistoryProtocol(state, { payload }) {
      state.protocolDetails = payload;
    }
  }
});

export const { setRefreshHistoryProtocol } = DataRefreshHistorySlice.actions;
