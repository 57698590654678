/*eslint-disable */
import React from 'react';
import LoginForm from './Components/LoginForm';
import LeftView from './Components/LeftView';
import Grid from 'apollo-react/components/Grid';
import { goToCore } from 'App';

const layout = {
  // padding: "15px 20px",
  boxSizing: 'border-box'
};
export const LoginPageForm = () => {
  return (
    <React.Fragment>
      {process.env.REACT_APP_LOCAL ? (
        <Grid container style={layout}>
          <Grid item xs={6}>
            <LeftView />
          </Grid>
          <Grid xs={6} style={layout}>
            <LoginForm />
          </Grid>
        </Grid>
      ) : (
        goToCore()
      )}
    </React.Fragment>
  );
};
