import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetLibraryType = createAsyncThunk('library/GetLibraryType', async () => {
  return await axios.get(`${API_URL}/api/Library/GetLibraryType`);
  // return await axios.get(`http://morsqdap001d/studymappercoreapi/api/Library/GetLibraryType`);
});

export const GetLibraryCategory = createAsyncThunk('library/GetLibraryCategory', async () => {
  return await axios.get(`${API_URL}/api/Library/GetLibraryCategory`);
  // return await axios.get(`http://morsqdap001d/studymappercoreapi/api/Library/GetLibraryCategory`);
});

export const PostGlobalLibrary = createAsyncThunk('library/PostGlobalLibrary', async (data) => {
  return await axios.post(`${API_URL}/api/librarytype`, data);
});

export const PostGlobalLibraryPage = createAsyncThunk(
  'library/PostGlobalLibraryPage',
  async (payload) => {
    return await axios.post(`${API_URL}/api/LibraryDomainCols`, payload);
  }
);

export const getVariableRuleSets = createAsyncThunk(
  'GlobalLibraryPage/getVariableRuleSets',
  async (payload) => {
    return await axios.get(`${API_URL}/api/library/GetVariableRuleSetData?libId=${payload.libraryID}&libVersion=${(payload.version || payload.libraryVersion)}`);
    
  }
);
