// /*eslint-disable */
import { MenuItem, Select } from 'apollo-react';
import ConfigSection from '../Components/ConfigSection';

const ExportFrequency = (props) => {
  const {
    readOnly,
    scheduleFrequencies,
    selectedScheduleExportFrequency,
    setSelectedScheduleExportFrequency,
    scheduleFrequenciesLoading
  } = props;

  const handleSchdeuleChange = (e) => {
    setSelectedScheduleExportFrequency(e.target.value);
  };

  return (
    <ConfigSection
      title="Export Frequency"
      subtitle="The default frequency setting exports the data upon the successful completion of dataset creation."
      loading={scheduleFrequenciesLoading}>
      <Select
        disabled={readOnly}
        data-testid="select-btn"
        label="Frequency"
        value={selectedScheduleExportFrequency || ''}
        onChange={handleSchdeuleChange}
        placeholder="Select a frequency..."
        canDeselect={false}
        sx={{ width: '300px', my: '0' }}>
        <MenuItem value={''} key={'Default'}>
          {'Default'}
        </MenuItem>
        {scheduleFrequencies?.map((frequency) => (
          <MenuItem value={frequency.scheduleFrequency} key={frequency.scheduleFrequency}>
            {frequency.scheduleFrequency}
          </MenuItem>
        ))}
      </Select>
    </ConfigSection>
  );
};

export default ExportFrequency;
