import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import CaretPositioning from './EditCaretPositioning';
import { debounce } from 'lodash';

const border_color = 'border-color';
const BORDER_STYLE = '1px solid #d9d9d9';
const useStyles = makeStyles({
  input: {
    font: 'inherit',
    color: '#000000',
    width: '100%',
    border: BORDER_STYLE,
    marginBottom: '10px',
    display: 'block',
    whiteSpace: 'break-spaces',
    padding: '8px 8px 8px 7px',
    minWidth: 0,
    background: 'none',
    boxSizing: 'border-box',
    lineHeight: 1.5,
    borderRadius: '4px',
    animationName: 'mui-auto-fill-cancel',
    letterSpacing: 'inherit',
    animationDuration: '10ms',
    transitionDuration: '0.4s',
    transitionProperty: border_color,
    '&:hover': {
      borderColor: '#0768fd',
      transitionDuration: '0.2s',
      transitionProperty: border_color
    },
    '&:focus': {
      outline: 0,
      borderColor: '#0768fd',
      transitionDuration: '0.2s',
      transitionProperty: border_color
    },
    '&[contenteditable="false"]': {
      border: BORDER_STYLE,
      backgroundColor: '#fafafa',
      cursor: 'default',
      userSelect: 'none',
      opacity: 0.6
    },
    '&[aria-invalid="true"]': {
      border: '2px solid #e20000',
      cursor: 'default',
      userSelect: 'none',
      backgroundColor: '#fff'
    }
  },
  helpText: {
    color: 'red',
    margin: 0,
    fontSize: '13px',
    marginTop: '5.5px',
    textAlign: 'left',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif',
    fontWeight: 400,
    lineHeight: 1
  },
  ctpStyles: {
    font: 'inherit',
    color: '#000000',
    width: '100%',
    border: BORDER_STYLE,
    marginBottom: '10px',
    display: 'block',
    whiteSpace: 'break-spaces',
    padding: '2px 7px',
    marginTop: '9px',
    minWidth: 0,
    background: 'none',
    boxSizing: 'border-box',
    lineHeight: 1.5,
    borderRadius: '4px',
    animationName: 'mui-auto-fill-cancel',
    letterSpacing: 'inherit',
    animationDuration: '10ms',
    transitionDuration: '0.4s',
    transitionProperty: border_color,
    '&:hover': {
      borderColor: '#0768fd',
      transitionDuration: '0.2s',
      transitionProperty: border_color
    },
    '&:focus': {
      outline: 0,
      borderColor: '#0768fd',
      transitionDuration: '0.2s',
      transitionProperty: border_color
    },
    '&[contenteditable="false"]': {
      border: 'none',
      // backgroundColor: '#fafafa',
      cursor: 'default',
      userSelect: 'none'
      // opacity: 0.6
    },
    '&[contenteditable="true"]': {
      backgroundColor: 'white'
    },
    '&[aria-invalid="true"]': {
      border: '2px solid #e20000',
      cursor: 'default',
      userSelect: 'none',
      backgroundColor: '#fff'
    }
  }
});

const CustomeTextField = (props) => {
  const classes = useStyles();
  const ref = useRef();

  const handleChange = debounce((event) => {
    let targetValue = event.target.textContent;
    let savedCaretPosition = CaretPositioning.saveSelection(event.target);
    props.onChange(targetValue);
    props.onCaretPositionchange(savedCaretPosition.start);
    CaretPositioning.restoreSelection(ref.current, savedCaretPosition);
  }, 200);

  const handleClick = () => {
    let savedCaretPosition = CaretPositioning.saveSelection(ref.current);
    props.onCaretPositionchange(savedCaretPosition.start);
  };

  return (
    <>
      <div
        data-testid="inputField"
        className={props.isCtpCard ? classes.ctpStyles : classes.input}
        ref={ref}
        contentEditable={!props.disabled}
        suppressContentEditableWarning
        onInput={handleChange}
        onClick={handleClick}
        aria-invalid={props.error}
        // html={props.children}
        dangerouslySetInnerHTML={{ __html: props.children }}
        {...(props?.customProps || {})}
      />
      {props.error && <p className={classes.helpText}>{props.helperText}</p>}
    </>
  );
};

CustomeTextField.defaultProps = {
  onCaretPositionchange: (/* pos */) => {},
  disabled: false,
  error: false,
  onChange: (/* value */) => {}
};

export default React.memo(CustomeTextField);
