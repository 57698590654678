/* eslint-disable */
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from 'Components/Controls';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Card from 'apollo-react/components/Card';
import DataGridTable from '../DataGridTable';
import { downloadGlobalLibrary } from '../../../../Redux/Service/GlobalLibraryService';
import { setPreviewDataLoading, setFilteredRulesetInfo } from 'Redux/Slice/DataProductLibrarySlice';
import PreviewTable from 'Pages/DataStandardLibrary/AddGlobalLibrary/PreviewTable';
import PreviewRulesetTable from './Components/PreviewRulesetTable';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Product Mnemonic is required')
    .max(15, 'Maximum ${max} characters are allowed')
    .matches(/^[a-zA-Z0-9\\ \\]+$/g, 'Only alphanumeric with spaces are allowed for Library name'),
  description: yup.string().max(400, 'Maximum ${max} characters are allowed')
});

const mnemonicText = {
  color: 'var(--neutrals-color-neutral-9, #000)',
  fontFamily: 'Proxima Nova',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px'
};

const AddDataProducts = forwardRef((props, ref) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isStudyPreview, setIsPreviewStudy] = useState(false);
  const { editDataProduct } = useSelector((state) => state.DataProductStudyLibrary);
  const targetModalData = useSelector((state) => state.DataProductStudyLibrary.targetModal);
  const [libraryRows, setLibraryRows] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);
  const [libraryName, setLibraryName] = useState();
  const [error, setError] = useState('');
  const [previewRulesetData, setPreviewRulesetData] = useState([]);
  const [studyDataBack, setStudydataBack] = useState(false);
  const [rulesetData, setRulesetData] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      global: '',
      study: {},
      studyFlag: undefined
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      if (rowSelected) {
        setIsPreviewStudy(true);
      } else if (previewRulesetData.length > 0) {
        setIsPreviewStudy(true);
      } else {
        setError('Target Data Model is Required');
        setIsPreviewStudy(false);
      }
    }
  });

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title:
            location.pathname === '/product-designer/edit-data-product'
              ? 'Edit Data Product'
              : 'Add Data Product',
          path: '/product-designer/add-data-product'
        }
      ])
    );

    if (!_.isEmpty(editDataProduct)) {
      formik.setFormikState((prevState) => {
        return {
          ...prevState,
          values: {
            name: editDataProduct.productNnemonic,
            description: editDataProduct.description,
            global: '',
            study: {},
            studyFlag: undefined
          }
        };
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (rowSelected) {
        dispatch(setPreviewDataLoading(true));
        if (editDataProduct?.targetDataModelID || targetModalData.libraryID) {
          const globalLibraryResponse = await downloadGlobalLibrary(
            editDataProduct?.targetDataModelID
              ? {
                  libraryId: editDataProduct.targetDataModelID,
                  libraryVersion: editDataProduct.latestTargetDataModelVersion
                }
              : {
                  libraryId: targetModalData.libraryID,
                  libraryVersion: targetModalData.version
                }
          );
          setLibraryRows(globalLibraryResponse?.data?.downloadDomainColumns || []);
        }
        dispatch(setPreviewDataLoading(false));
      }
    })();
  }, [targetModalData, rowSelected]);

  const hasValidate = () => {
    if (!_.isEmpty(editDataProduct) && rowSelected && !Object.keys(formik.errors).length) {
      setIsPreviewStudy(true);
      return true;
    } else if (
      formik.dirty &&
      !Object.keys(formik.errors).length &&
      previewRulesetData.length > 0
    ) {
      setIsPreviewStudy(true);
      return true;
    } else if (!formik.dirty || Object.keys(formik.errors).length > 0 || !rowSelected) {
      return false;
    }
    return true;
  };

  const isRowSelected = (data) => {
    if (data && Object.keys(data).length > 0) {
      setRowSelected(true);
      setLibraryName(data.libraryName);
      setError('');
    } else {
      setRowSelected(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: formik.handleSubmit,
    hasValidate,
    getPayload: () => {
      return { ...formik.values, previewRulesetData };
    },
    handleStepperback: () => {
      targetModalData?.libraryID ? setStudydataBack(false) : setStudydataBack(true);
      setIsPreviewStudy(false);
    }
  }));

  useEffect(() => {
    let subSourceArray = [];
    rulesetData.forEach((ruleset) => {
      ruleset?.subSource?.forEach((subItem) => {
        if (ruleset.isConfigured || ruleset.checked) {
          subSourceArray.push({
            source: subItem.source,
            configured: subItem.isConfigured,
            checked: subItem.checked,
            updateAvailable: ruleset.updateAvailable
          });
        }
      });
    });
    dispatch(setFilteredRulesetInfo(subSourceArray));
  }, [rulesetData]);

  return (
    <>
      <Box pt={0} pl={3} pr={3}>
        <div style={{ marginBottom: '28px' }}>
          <Typography variant="h3">
            {!isStudyPreview && _.isEmpty(editDataProduct)
              ? 'Add Data Product'
              : !isStudyPreview && !_.isEmpty(editDataProduct)
              ? 'Edit Data Product'
              : 'Preview'}
          </Typography>
        </div>
        {/* {loading ? (
          <DataVizCard>
            <Loader />
          </DataVizCard>
        ) : ( */}
        <>
          {!isStudyPreview ? (
            <>
              <Box mt={2}>
                {!_.isEmpty(editDataProduct) ? (
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body2">Product Mnemonic</Typography>
                      <Typography variant="title" style={{ mnemonicText }}>
                        {editDataProduct?.productNnemonic}
                      </Typography>
                    </Grid>

                    {editDataProduct?.targetDataModelType !== 'Ruleset' && (
                      <Grid item xs={3}>
                        <Typography variant="body2">Target Data Model Version</Typography>
                        <Typography variant="title">
                          {editDataProduct?.targetDataModelVersion}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <TextField
                        id="name"
                        label="Product Mnemonic"
                        name="name"
                        // data-testid="name-input"
                        // required
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Grid container spacing={0}>
                <Grid item xs={5}>
                  <TextField
                    id="description"
                    label="Description (Optional)"
                    name="description"
                    // data-testid="description-input"
                    multiline
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.description)}
                    helperText={formik.errors.description}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <span
                    style={{ fontWeight: 700, fontSize: '13px', marginLeft: '16px', color: 'red' }}>
                    {' '}
                    {error ? error : ''}{' '}
                  </span>
                  <Card>
                    {/* <p style={{ fontWeight: 700, fontSize: '13px', marginLeft: '16px' }}>
                      Select Target Data Model
                    </p> */}
                    <DataGridTable
                      formik={formik}
                      isRowSelected={isRowSelected}
                      setPreviewRulesetData={setPreviewRulesetData}
                      studyDataBack={studyDataBack}
                      setRulesetData={setRulesetData}
                      rulesetData={rulesetData}
                      editDataProduct={editDataProduct}
                    />
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : !previewRulesetData.length ? (
            <PreviewTable rows={libraryRows} title={libraryName} />
          ) : (
            <PreviewRulesetTable previewRulesetData={previewRulesetData} />
          )}
        </>
        {/* // )} */}
      </Box>
    </>
  );
});

export default AddDataProducts;
