import React from 'react';
import { makeStyles } from '@mui/styles';
import Warning from 'Images/Warning.svg';

const useStyles = makeStyles({
  elapsedTime: {
    fontWeight: 'bold',
    '&:before': {
      verticalAlign: '-10%',
      margin: '4px 4px 0 0 ',
      content: `url(${Warning})`
    }
  }
});

/**
 * Component to render Elapsed time based on isSLABreached flag
 * @param {object} row
 * @returns JSX element
 */
const ElapsedTime = ({ row }) => {
  const classes = useStyles();

  return (
    <div className={`tooltip ${row.isSLABreached && classes.elapsedTime}`}>
      {row.elapsedTime !== '' && `${row.elapsedTime} Hours`}
      {row.isSLABreached && (
        <>
          <br />
          <span className="tooltiptext tooltip-bottom">
            Elapsed Time exceeded
            <br />
            Service Level Agreement
          </span>
        </>
      )}
    </div>
  );
};

export default ElapsedTime;
