/* eslint-disable */
import { Grid, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from 'apollo-react/components/TextField';
import Button from 'apollo-react/components/Button';
import Typography from 'apollo-react/components/Typography';
import Tooltip from 'apollo-react/components/Tooltip';
import { SaveMappingSpec } from 'Redux/Service/CreateMappingSpecService';
import { unwrapResult } from '@reduxjs/toolkit';
import TabComponent from './MappingSpecComponents/TabComponent';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Link from 'apollo-react/components/Link';
import { makeStyles } from '@mui/styles';
import MappingSpecDetails from '../../PublishMappingSpec/MappingSpecDetails';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useStyles = makeStyles({
  linkRoot: {
    fontWeight: '500',
    '&:hover': {
      borderBottom: 'none',
      textDecoration: 'none'
    }
  }
});

const MappingSpec = () => {
  const classes = useStyles();

  const stylesMappingSpec = {
    column6: {
      display: 'flex'
    },
    column12: {
      display: 'flex'
    }
  };

  const navigate = useNavigate();
  const [mappingSpecVersion, setMappingSpecVersion] = useState(undefined);
  const [mappingSpecTitle, setMappingSpecTitle] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  const [mappingSpecComments, setMappingSpecComments] = useState('');
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [disabledMappingSpec, setDisabledMappingSpec] = useState(true);

  const [displayMappingSpecOverlay, toggleMappingSpecOverlay] = useState(false);

  const dispatch = useDispatch();

  const { id: mappingRuleVersionID } = useParams();

  const handleMappingSpecVersion = (e) => {
    if (e.target.value === '') {
      setErrorFlag(true);
    } else {
      setErrorFlag(false);
    }
    setMappingSpecVersion(e.target.value);
  };

  const handleSaveMappingSpec = async () => {
    if (mappingSpecVersion === undefined) {
      setErrorFlag(true);
      return;
    }
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID,
      mappingSpecVersionName: mappingSpecVersion?.trim(),
      title: mappingSpecTitle,
      comments: mappingSpecComments,
      createdDate: new Date().toISOString(),
      createdBy: Cookies.get('user.id'),
      protocolNumber: protocol.protocolNumber
    };

    setDisabledMappingSpec(true);
    const response = await dispatch(SaveMappingSpec(payload))
      .then(unwrapResult)
      .catch((error) => {
        console.log('Error while SaveVariableMappingRules ::', error);
        return error;
      });
    if (response && response?.data?.success) {
      dispatch(showBanner({ variant: 'success', message: response.data.message, propagate: true }));
      navigate('/product-designer');
      setDisabledMappingSpec(false);
      return;
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
      setDisabledMappingSpec(false);
      return;
    }
  };

  const handleDisableMappingButton = (flag) => {
    setDisabledMappingSpec(flag);
  };

  useEffect(() => {
    setErrorFlag(false);
  }, []);

  return (
    <>
      <Box pt={0} pl={3} pr={3}>
        <Box
          gutterBottom
          style={{
            color: '#000000',
            fontFamily: 'Proxima Nova',
            fontSize: '20px',
            fontWeight: 600,
            letterSpacing: 0,
            lineHeight: '48px',
            height: '28px'
          }}>
          Create Mapping Spec
        </Box>
        <Box mt={2}>
          <Grid container spacing={0} style={{ marginTop: '60px' }}>
            <Grid item xs={4}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Product Mnemonic
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Description
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.description}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Mapping Spec ID (Draft)
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {mappingRuleVersionID}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            style={{ marginTop: '30px', position: 'relative', right: '1.2rem' }}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection={'column'} alignItems="flex-end">
                <TextField
                  style={{
                    ...stylesMappingSpec.column6,
                    paddingRight: '20px',
                    width: 'calc(100% - 20px)'
                  }}
                  label="New Mapping Spec Version"
                  placeholder="SDM <user specified name>"
                  onChange={handleMappingSpecVersion}
                  helperText={errorFlag && 'Please enter mapping spec version name'}
                  required
                  error={errorFlag}
                  data-testid={'mapping-spec-version-textfield'}
                />
                <Link
                  onClick={() => toggleMappingSpecOverlay(true)}
                  style={{
                    paddingRight: '20px'
                  }}
                  size={'small'}
                  classes={{ root: classes.linkRoot }}>
                  View Existing Mapping Specs
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Title (optional)"
                placeholder="Enter text..."
                style={stylesMappingSpec.column6}
                onChange={(e) => setMappingSpecTitle(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: '0.5rem' }}>
            <Grid item xs={12}>
              <TextField
                label="Comments (optional)"
                placeholder="Enter text..."
                sizeAdjustable
                style={stylesMappingSpec.column12}
                onChange={(e) => setMappingSpecComments(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <TabComponent disableMappingButton={handleDisableMappingButton} />
        </Box>
        <Box mt={6}>
          {disabledMappingSpec ? (
            <Tooltip
              variant="dark"
              title="Please fix all mapping spec errors to continue"
              placement="top">
              <Button
                variant="primary"
                size="small"
                style={{ float: 'right', marginRight: '50px' }}
                onClick={handleSaveMappingSpec}
                disabled={disabledMappingSpec || errorFlag}>
                Create Mapping Spec
              </Button>
            </Tooltip>
          ) : (
            <Button
              variant="primary"
              size="small"
              style={{ float: 'right', marginRight: '50px' }}
              onClick={handleSaveMappingSpec}>
              Create Mapping Spec
            </Button>
          )}
          <br />
          <br />
        </Box>
      </Box>

      {displayMappingSpecOverlay ? (
        <MappingSpecDetails
          {...rowDataForRuleEditor}
          displayMappingSpecOverlay={displayMappingSpecOverlay}
          toggleMappingSpecOverlay={toggleMappingSpecOverlay}
          fetchList
          viewOnly
        />
      ) : null}
    </>
  );
};

export default MappingSpec;
