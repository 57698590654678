/*eslint-disable */
import React from 'react';
import Grid from 'apollo-react/components/Grid';
import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import Typography from 'apollo-react/components/Typography';
import styled from 'styled-components';
import useDisplayName from 'Utils/useDisplayName';

const bodyStyle = {
  fontSize: '16px',
  fontWeight: '500'
};

const accordionDetails = {
  padding: '24px 16px 24px 16px'
};

const accordionSummary = {
  borderBottom: '1px solid #DADADA'
};

const Label = styled(Typography)(() => ({
  fontWeight: '400',
  lineHeight: '24px',
  color: '#595959'
}));

const Text = styled(Typography)(() => ({
  fontWeight: '500',
  lineHeight: '24px',
  color: '#444444',
  overflowWrap: 'break-word'
}));

const StudyInfo = ({ productInfo, label, style }) => {
  const getDisplayName = useDisplayName();
  return (
    <div style={style}>
      <Accordion defaultExpanded>
        <AccordionSummary style={accordionSummary}>
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails style={accordionDetails}>
          <Grid container spacing={2}>
            {productInfo !== undefined &&
              Object.keys(productInfo)?.map((studyData) => {
                const displayValue =
                  studyData === 'source'
                    ? productInfo[studyData]?.value
                        ?.split(',')
                        .map((source) => getDisplayName(source))
                        .join(',')
                    : productInfo[studyData]?.value;
                return (
                  <Grid item xs={2}>
                    <Label>{productInfo[studyData]?.label}</Label>
                    <Text>{displayValue} </Text>
                  </Grid>
                );
              })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default StudyInfo;
