import React, { useRef } from 'react';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import DataSidebar from 'Components/DataProductSideBar';
import Grid from 'apollo-react/components/Grid';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import { useSelector } from 'react-redux';
import { BannerExample } from 'Components/Banner/Banner';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';

const useStyles = makeStyles({
  studySidebar: {
    backgroundColor: '#fff',
    borderRight: '1px solid #ccc',
    marginTop: '56px',
    height: `calc(100vh - 113px)`
  },
  container: {
    maxHeight: `calc(100vh - 113px)`,
    marginTop: '57px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
});

export const DataProductSideBarLayout = (props) => {
  const childRef = useRef();
  const classes = useStyles();
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const handleSubmit = () => {
    childRef.current.handleSubmit();
  };

  return (
    <React.Fragment>
      <Header />
      <Grid container>
        <BannerExample />
        <Grid item xs={3} className={classes.studySidebar}>
          <DataSidebar
            onSubmitRef={handleSubmit}
            isValidate={() => childRef.current.hasValidate()}
            handleStepperback={() => childRef.current.handleStepperback()}
            getPayload={() => childRef.current.getPayload()}
          />
        </Grid>
        <Grid item xs={9} className={classes.container}>
          <>
            {protocol !== undefined && <ProjectStudyHeader />}
            <Breadcrumb addStudy={true} />
          </>
          {React.cloneElement(props.children, { ref: childRef }, null)}
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
