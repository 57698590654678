import { createSlice } from '@reduxjs/toolkit';
import dataProductAdapter from '../Entity/DataProducts';
import { dataProducts } from 'MockApis/dataProduct';

const emptyInitialState = dataProductAdapter.getInitialState();
const filledState = dataProductAdapter.upsertMany(emptyInitialState, dataProducts);

export const DataProductsSlice = createSlice({
  name: 'DataProduct',
  initialState: filledState,
  reducers: {
    setDataProducts(state, { payload }) {
      dataProductAdapter.setAll(state, payload);
    },
    addDataProduct: dataProductAdapter.addOne
  }
});

export const { setDataProducts, addDataProduct } = DataProductsSlice.actions;

export const DataProductSelector = dataProductAdapter.getSelectors((state) => state.DataProduct);
