/*eslint-disable */

const API_URL = process.env.REACT_APP_API_URL;
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getHeadersFromCookies } from 'service/header.service';

export const submitDeleteLibrary = async (payload) => {
  const api = await fetch(`${API_URL}/api/Library/DeleteGlobalLibrary`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

// export const getAuditHistory = createAsyncThunk(
//   'GlobalLibrary/getAuditHistory',
//   async (_, { dispatch, rejectWithValue }) => {
//     dispatch(showLoader());
//     try {
//       const response = await fetch(`${API_URL}/api/Library/GetGloblalLibraryAuditHistory`, {
//         method: 'POST',
//         headers: { 'Content-type': 'application/json;charset=UTF-8' },
//         body: JSON.stringify(payload)
//       });
//       dispatch(closeLoader());
//       return response;
//     } catch (error) {
//       dispatch(closeLoader());
//       rejectWithValue([], error);
//     }
//   }
// );

export const getAuditHistory = async (payload) => {
  const api = await fetch(`${API_URL}/api/Library/GetGloblalLibraryAuditHistory`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const studyAuditHistory = createAsyncThunk(
  'ModalData/studyAuditHistory',
  async (payload) => {
    return await axios.post(`${API_URL}/api/Protocol/GetStudyLibraryAuditHistory`, payload);
  }
);

export const GetDataProductAuditHistory = createAsyncThunk(
  'ModalData/GetDataProductAuditHistory',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/GetDataProducts`, payload);
  }
)