/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { getStudyLibraryGrid } from '../Service/StudyLibraryGridService';
import { getAssignedStudies } from '../Service/StudyConfigLibrary';

export const StudyLibrarySlice = createSlice({
  name: 'Studylibrary',

  /**DSL ->> Data Standard Library */

  initialState: {
    studyLibraries: [],
    protocol: {},
    testConnection: false,
    protocols: [],
    checkboxEnable: false,
    previewData: {},
    updatedStudyLibraries: false,
    metadataVersionFromParent: '',
    openPanelDSL: true,
    closePanelText: true,
    selectedLibrary: [],
    studyLibraryTypeValues : []
  },

  reducers: {
    setSelectedLibrary(state, { payload }) {
      state.selectedLibrary = payload;
    },
    getMetadataVersionFromParent(state, { payload }) {
      state.metadataVersionFromParent = payload;
    },
    openStudyPanel(state, { payload }) {
      state.openPanelDSL = payload;
    },
    setClosePanelText(state, { payload }) {
      state.closePanelText = payload;
    },
    getProtocol(state, { payload }) {
      state.protocol = payload;
    },
    setTestConnection(state, { payload }) {
      state.testConnection = payload;
    },
    getCheckboxFlag(state, { payload }) {
      state.checkboxEnable = payload;
    },
    getPreviewData(state, { payload }) {
      state.previewData = payload;
    },
    getUpdatedStudyLibraries(state, { payload }) {
      state.updatedStudyLibraries = payload;
    },
    getDisableCheck(state, { payload }) {
      state.disableCheck = payload;
    },
    setStudyLibraryTypeValues(state, { payload }) {
      state.studyLibraryTypeValues = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getStudyLibraryGrid.fulfilled, (state, { payload }) => {
      state.studyLibraries = payload;
    });
    builder.addCase(getStudyLibraryGrid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStudyLibraryGrid.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(getAssignedStudies.fulfilled, (state, { payload }) => {
      state.protocols = { ...payload.data };
    });
    builder.addCase(getAssignedStudies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssignedStudies.rejected, (state, action) => {
      state.error = action.error.message;
    });
  }
});

export const {
  getProtocol,
  setTestConnection,
  getMetadataVersionFromParent,
  getCheckboxFlag,
  getPreviewData,
  getUpdatedStudyLibraries,
  openStudyPanel,
  setClosePanelText,
  getDisableCheck,
  setSelectedLibrary,
  setStudyLibraryTypeValues
} = StudyLibrarySlice.actions;
