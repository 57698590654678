/*eslint-disable */
import React, { useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import Grid from 'apollo-react/components/Grid';

import Header from 'Components/Header';
import Footer from 'Components/Footer';
import ProjectStudyHeader from 'Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Panel from 'apollo-react/components/Panel';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import CloneDataProductSidebar from './CloneDataProductSidebar';
import { BannerExample as Banner } from 'Components/Banner/Banner';

const useStyles = (displaySidebar) =>
  makeStyles({
    sidebar: {
      backgroundColor: '#fff',
      borderRight: '1px solid #ccc',
      marginTop: '60px'
    },
    children: {
      padding: '0rem 1.5rem'
    },
    main: {
      display: 'flex',
      height: 'calc(100vh - 113px)',
      overflow: 'auto',
      '&> div:nth-child(1)': {
        position: 'sticky',
        top: '0px',
        minHeight: 'calc(100vh - 113px)',
        height: 'fit-content',
        zIndex: 1
      },
      '&> div:nth-child(2)': {
        width: `calc(100% - ${displaySidebar ? '354px' : '26px'}) !important`,
        backgroundColor: '#f5f6fa'
      }
    },
    hidden: {
      visibility: 'hidden'
    },
    breadCrumbs: {
      backgroundColor: 'transparent'
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '113px'
    },
    panelRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: `calc(100vh - 113px)`
    }
  });

export const CloneDataProductLayout = () => {
  const [displaySidebar, toggleSidebar] = useState(true);
  const classes = useStyles(displaySidebar)();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [isNextDisabled, setNextDisabled] = useState(false);

  const callbackRef = useRef([]);

  const addEventListener = (callback) => {
    callbackRef.current.push(callback);
  };

  const removeEventListener = (callback) => {
    const index = callbackRef.current.indexOf(callback);
    if (index === -1) return;
    callbackRef.current.splice(index, 1);
  };

  return (
    <Grid className={classes.root}>
      <Header />
      <Banner />
      {protocol !== undefined && <ProjectStudyHeader />}
      <Grid item xs={12} className={classes.main}>
        <Panel
          open={displaySidebar}
          onClose={() => {
            toggleSidebar(false);
          }}
          onOpen={() => {
            toggleSidebar(true);
          }}
          width={350}>
          <Grid
            item
            xs={12}
            className={classes.sidebar + displaySidebar ? '' : `${classes.hidden}`}>
            <CloneDataProductSidebar
              isNextDisabled={isNextDisabled}
              setNextDisabled={setNextDisabled}
            />
          </Grid>
        </Panel>
        <Panel hideButton className={classes.panelRight}>
          <Grid
            item
            className={displaySidebar ? `${classes.compactPreview}` : `${classes.expandedPreview}`}
            style={{ display: 'flex', flexDirection: 'column' }}>
            <Breadcrumb style={{ backgroundColor: 'transparent', padding: '1rem 1.5rem' }} />
            <Grid className={classes.children}>
              <Outlet
                context={{
                  setNextDisabled,
                  addEventListener,
                  removeEventListener
                }}
              />
            </Grid>
          </Grid>
          <Footer />
        </Panel>
      </Grid>
    </Grid>
  );
};

export default CloneDataProductLayout;
