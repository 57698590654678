import { createSlice } from '@reduxjs/toolkit';

export const PublishMappingSpecSlice = createSlice({
  name: 'PublishMappingSpec',

  /** MDP ->> Manage Data Pipelines */

  initialState: {
    savedEnvironments: [],
    selectedEnvironment: {},
    selectedScehduler: {},
    mappingSpecDetails: {},
    selectSourceDetails: {},
    dataExportDetails: [],
    openPanelMDP: true,
    selectedSourceData: [],
    testInfo: '',
    displayChangeScheduler: { display: false },
    stepperStatus: {
      newStep: -1,
      isCurrentPageEdit: false
    },
    domainAndColumnMetaData: [],
    exportTypeMetaData: [],
    scheduleFrequencies: [],
    selectExportDetails: {},
    selectedPreferences: {}
  },
  reducers: {
    setTestInfo(state, { payload }) {
      state.testInfo = payload;
    },
    setMappingSpecDetails(state, { payload }) {
      state.mappingSpecDetails = payload;
    },
    setDisplayChangeScheduler(state, { payload }) {
      state.displayChangeScheduler = payload;
    },
    saveEnvironments(state, { payload }) {
      state.savedEnvironments = payload;
    },
    setSelectedEnvironment(state, { payload }) {
      state.selectedEnvironment = payload;
    },
    setSelectedScehduler(state, { payload }) {
      state.selectedScehduler = payload;
    },
    setSelectSourceDeatils(state, { payload }) {
      state.selectSourceDetails = payload;
    },
    setDataExportDetails(state, { payload }) {
      state.dataExportDetails = payload;
    },
    openDataPipelinePanel(state, { payload }) {
      state.openPanelMDP = payload;
    },
    setSelectedSourceData(state, { payload }) {
      state.selectedSourceData = payload;
    },
    setStepperStatus(state, { payload }) {
      state.stepperStatus = payload;
    },
    setDomainAndColumnMetaData(state, { payload }) {
      state.domainAndColumnMetaData = payload;
    },
    setExportTypeMetaData(state, { payload }) {
      state.exportTypeMetaData = payload;
    },
    setScheduleFrequencies(state, { payload }) {
      state.scheduleFrequencies = payload;
    },
    setSelectedPreferences(state, { payload }) {
      state.selectedPreferences = payload;
    }
  }
});

export const {
  setMappingSpecDetails,
  setDisplayChangeScheduler,
  setSelectedEnvironment,
  saveEnvironments,
  setSelectedScehduler,
  setSelectSourceDeatils,
  setDataExportDetails,
  openDataPipelinePanel,
  setSelectedSourceData,
  setTestInfo,
  setStepperStatus,
  setDomainAndColumnMetaData,
  setExportTypeMetaData,
  setScheduleFrequencies,
  setSelectExportDetails,
  setSelectedPreferences
} = PublishMappingSpecSlice.actions;
