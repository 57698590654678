let referenceData = {};

export const setReferenceData = (key, value) => {
  if (typeof key === 'undefined') throw new Error('KEY CANNOT BE NULL');
  referenceData[key] = value;
  return value;
};

export const getReferenceData = (key) => {
  if (typeof key === 'undefined') throw new Error('KEY CANNOT BE NULL');
  return referenceData[key];
};
