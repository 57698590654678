/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from 'apollo-react/components/Switch';
import TextField from 'Components/Controls/TextField';
import { uuidv4 } from 'Utils';
import Tooltip from 'apollo-react/components/Tooltip/Tooltip';
import FilterExpression from './FilterExpression';
import Banner from 'apollo-react/components/Banner';
import Fade from '@mui/material/Fade';
import validate from 'Pages/Validator/validate';
import Pencil from 'Images/Pencil.svg';
import Trash from 'Images/Trash.svg';
import { useStyles } from './style';
import { Box, Divider, Typography } from 'apollo-react';
import { CloseCircle } from 'apollo-react-icons';

const EllipsisTitle = ({ children, ...props }) => {
  return (
    <div
      {...props}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '45%'
      }}>
      {children}
    </div>
  );
};

const Filter = (props) => {
  const {
    expressions: filterExpressions = [],
    id: filterId,
    active,
    inProgress,
    filterName,
    operator,
    handleFilterUpdate,
    defaultForm,
    rowSeq,
    sourceName,
    deleteVariableExpression,
    handleIsActiveUpdate,
    deleteVariableRule,
    isLoading,
    refDataService,
    selectedParentFilters,
    setConfirmAlert,
    sourceFormItems,
    codeListDataMapping,
    methodsConstants,
    expressionTemplates,
    displaySearchFields,
    formatedFormItemData
  } = props;

  const classes = useStyles();
  const [localFilterName, setFilterName] = useState(filterName || '');
  const [expressions, setExpressions] = useState([]);
  const [activeToggleDisabled, disableActiveToggle] = useState(filterName ? false : true);
  const [localOperator, setOperator] = useState(operator || '"$OR"');
  const [localDefaultForm, setDefaultForm] = useState(defaultForm);
  const [banner, setBanner] = useState({ enabled: false, variant: 'info', message: '' });
  const [filterNameError, setFilterNameError] = useState();
  const [localSourceName, setSourceName] = useState(sourceName);
  const [allItems, setAllItems] = useState([]);
  const [allForms, setAllForms] = useState([]);
  const [accordianExpanded, setAccordianExpanded] = useState(false);
  const [formItemMapping, setFormItemMapping] = useState({});

  const { disableDelete, disableActiveFlag } = useMemo(() => {
    const disableDelete =
      selectedParentFilters?.length <= 1 && selectedParentFilters?.filter(Boolean)[0]?.filterName;

    const disableActiveFlag = selectedParentFilters?.every((_filter) =>
      _filter?.id === filterId ? true : !_filter?.active
    );

    return { disableDelete, disableActiveFlag };
  }, [selectedParentFilters]);

  useEffect(() => {
    let arr = [];
    let mapping = {};
    const keys = Object.keys(sourceFormItems);
    setAllForms(keys);
    keys.forEach((key) => {
      mapping[key] = sourceFormItems[key].items;
      arr.push(...sourceFormItems[key].items);
    });
    setAllItems(arr);
    setFormItemMapping(mapping);
  }, [sourceFormItems]);

  useEffect(() => {
    setExpressions(JSON.parse(JSON.stringify(filterExpressions)));
  }, [filterExpressions]);

  const handleAddExpression = () => {
    setExpressions((exps) => [
      ...exps,
      { id: uuidv4(), expression: '', isValid: true, inProgress: true }
    ]);
  };

  const validateRuleExpression = (inputExp) => {
    let result = {
      isValid: true,
      message: '',
      cordinates: { start: 0, end: inputExp.length }
    };
    result = validate(inputExp, defaultForm, codeListDataMapping, formItemMapping, refDataService);
    return result;
  };
  const saveFilterDataForm = (modifiedExpressions) => {
    handleFilterUpdate(
      'update',
      filterId,
      {
        sourceName: localSourceName,
        defaultForm: localDefaultForm,
        inProgress: false,
        expressions: modifiedExpressions,
        operator: localOperator,
        filterName: localFilterName
      },
      true,
      ({ variant, message }) => {
        setBanner({ enabled: true, variant, message });
        setTimeout(() => {
          disableActiveToggle(false);
          setBanner({ enabled: false });
        }, 3000);
      }
    );
  };
  const handleExpressionUpdate = (type, expId, modifiedExpression, modifiedSourceName) => {
    switch (type) {
      case 'save-exp': {
        setExpressions((exps) => {
          const modifiedExps = [...exps];
          modifiedExps.forEach((exp) => {
            if (exp.id === expId) {
              exp.expression = modifiedExpression;
              exp.isValid = true;
              exp.result = {};
            }
          });
          return modifiedExps;
        });
        break;
      }
      case 'save-source-name': {
        setExpressions((exps) => {
          const modifiedExps = [...exps];
          modifiedExps.forEach((exp) => {
            if (exp.id === expId) {
              exp.sourceName = modifiedSourceName;
              exp.expression = modifiedExpression || exp.expression;
            }
          });
          return modifiedExps;
        });
        break;
      }
      case 'save-all': {
        const modifiedExps = [...expressions];
        let isError = false;
        modifiedExps.forEach((exp) => {
          const result = validateRuleExpression(exp.expression);
          exp.isValid = result.isValid;
          exp.result = result;
          if (!exp.isValid) {
            isError = true;
            exp.inProgress = true;
          } else {
            exp.inProgress = false;
          }
        });
        setExpressions(modifiedExps);
        let isFilterNameUnique = true;
        selectedParentFilters.forEach((filter) => {
          if (
            filter?.filterName.toLowerCase() === localFilterName.toLowerCase() &&
            filter?.id !== filterId
          ) {
            isFilterNameUnique = false;
          }
        });
        if (isFilterNameUnique) {
          if (!isError) {
            if (localDefaultForm !== defaultForm && defaultForm !== undefined) {
              setConfirmAlert({
                enabled: true,
                variant: 'warning',
                title: 'Warning',
                message:
                  'You are about to change the default form, which may require additional changes in the filter and variable expression. Would you like to proceed with changes?',
                onConfirm: () => {
                  saveFilterDataForm(modifiedExps);
                  setConfirmAlert({
                    enabled: false
                  });
                },
                onCancel: () => {
                  setConfirmAlert({
                    enabled: false
                  });
                }
              });
            } else {
              saveFilterDataForm(modifiedExps);
            }
          }
        } else setFilterNameError('Filter name should be unique!');
        break;
      }
      case 'edit-exp': {
        setExpressions((exps) => {
          const modifiedExps = [...exps];
          modifiedExps.forEach((exp) => {
            if (exp.id === expId) {
              exp.inProgress = true;
              exp.isValid = true;
            }
          });
          return modifiedExps;
        });

        handleFilterUpdate('update', filterId, {
          inProgress: true
        });
        break;
      }
      case 'cancel-all': {
        if (!filterName && !defaultForm) {
          handleFilterUpdate('delete', filterId);
        } else if (
          filterName === localFilterName &&
          defaultForm === localDefaultForm &&
          operator === localOperator &&
          JSON.stringify(expressions) === JSON.stringify(filterExpressions)
        ) {
          handleFilterUpdate('update', filterId, {
            inProgress: false
          });
        } else {
          {
            setConfirmAlert({
              enabled: true,
              variant: 'warning',
              title: 'Warning',
              message: `Are you sure you want to cancel editing ${localFilterName}?`,
              onConfirm: () => {
                handleFilterUpdate('update', filterId, {
                  inProgress: false
                });
                setOperator(operator);
                setFilterName(filterName);
                setDefaultForm(defaultForm);
                setExpressions(JSON.parse(JSON.stringify(filterExpressions)));
                setConfirmAlert({
                  enabled: false
                });
              },
              onCancel: () => {
                setConfirmAlert({
                  enabled: false
                });
              }
            });
          }
        }
        break;
      }
      case 'delete-exp': {
        let modifiedExps = [...expressions];
        modifiedExps.splice(
          modifiedExps.findIndex((exp) => {
            return exp.id === expId;
          }),
          1
        );

        setExpressions(modifiedExps);

        deleteVariableExpression([expId], (variant, message) => {
          if (variant === 'success') {
            handleFilterUpdate('update', filterId, {
              inProgress: false,
              expressions: modifiedExps
            });
            setBanner({ enabled: true, variant: 'success', message: message });
            setTimeout(() => setBanner({ enabled: false }), 3000);
          } else {
            setBanner({ enabled: true, variant: 'error', message: message });
            setTimeout(() => setBanner({ enabled: false }), 3000);
          }
        });
        break;
      }
    }
  };

  const handleDefaultFormChange = (e) => {
    setDefaultForm(e.target.value);
    setSourceName(sourceFormItems[e.target.value].sourceName);
  };

  const handleDelete = () => {
    !disableDelete &&
      !inProgress &&
      setConfirmAlert({
        enabled: true,
        variant: 'warning',
        title: 'Warning',
        message:
          'Are you sure you want to delete this filter? It will also delete the filter from this domain’s variable rules.',
        onConfirm: () => {
          deleteVariableRule(filterId, filterName, rowSeq);
          setConfirmAlert({
            enabled: false
          });
        },
        onCancel: () => {
          setConfirmAlert({
            enabled: false
          });
        }
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setAccordianExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={accordianExpanded === filterId}
      onChange={handleChange(filterId)}
      className={classes.filterType}>
      <AccordionSummary className={classes.accordianSummary} expandIcon={<ExpandMoreIcon />}>
        <div className={classes.filterTypeHeader}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <EllipsisTitle className={classes.filterTypeTitle}>
              {(inProgress ? localFilterName : filterName) || 'Filter Name'}
            </EllipsisTitle>
            <Box sx={{ mx: '5px', fontSize: '24px' }}>|</Box>
            <EllipsisTitle className={classes.filterTypeTitle}>
              {(inProgress ? localDefaultForm : defaultForm) || 'Source Form'}
            </EllipsisTitle>
          </Box>

          <div onClick={(e) => e.stopPropagation()} className={classes.buttonContainer}>
            <span>Active</span>
            <Switch
              disabled={disableActiveFlag || activeToggleDisabled}
              onChange={(e) => {
                handleIsActiveUpdate(
                  e.target.checked,
                  filterId,
                  filterName,
                  rowSeq,
                  disableActiveToggle,
                  setBanner
                );
              }}
              checked={active === undefined ? true : active}
              size="small"
              className={classes.switch}
            />
            <div className={`tooltip`}>
              <img
                onClick={() =>
                  !inProgress && handleFilterUpdate('update', filterId, { inProgress: true })
                }
                src={`${Pencil}`}
                style={{
                  ...(!inProgress ? { color: 'black', cursor: 'pointer' } : {})
                }}
                className={classes.pencilIcon}
              />
              <span className="tooltiptext4 tooltip-bottom4">{'Edit'}</span>
            </div>

            <div className={`tooltip`}>
              <img
                onClick={handleDelete}
                style={{
                  ...(!disableDelete && !inProgress ? { opacity: '1', cursor: 'pointer' } : {})
                }}
                src={`${Trash}`}
                className={classes.editIcon}
              />
              <span className="tooltiptext4 tooltip-bottom4">{'Delete'}</span>
            </div>
          </div>
        </div>
      </AccordionSummary>
      {accordianExpanded === filterId && (
        <AccordionDetails className={classes.filterExpressionContainer}>
          <Grid
            container
            className={classes.headerContainer}
            style={{ paddingBottom: filterNameError ? '1.5rem' : '0.5rem' }}>
            <Grid
              item
              xs={9}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                gap: '15px'
              }}>
              <TextField
                error={filterNameError}
                helperText={filterNameError}
                id="filterName"
                label="Filter Name"
                placeholder="Add name.."
                name="expression"
                data-testid="expression-input"
                value={localFilterName}
                onChange={(e) => {
                  filterNameError && setFilterNameError();
                  setFilterName(e.target.value);
                }}
                disabled={!inProgress}
                fullWidth
                style={{
                  marginBottom: filterNameError ? '-0.65rem' : ''
                }}
                required
              />
              <Select
                canDeselect={false}
                label="Default Form"
                value={localDefaultForm}
                onChange={handleDefaultFormChange}
                placeholder={'Select...'}
                style={{ width: '100%' }}
                disabled={!inProgress}
                required>
                {Object.keys(sourceFormItems).map((item, index) => {
                  return (
                    <MenuItem value={item} key={`SourceName-${index}`}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <button
                  disabled={!inProgress}
                  onClick={() => setOperator('"$AND"')}
                  className={`${classes.andButton} ${
                    localOperator === '"$AND"' ? classes.primaryButton : classes.secondaryButton
                  } ${!inProgress && classes.disabledButton}`}>
                  And
                </button>
                <button
                  disabled={!inProgress}
                  onClick={() => setOperator('"$OR"')}
                  className={`${classes.orButton} ${
                    localOperator === '"$OR"' ? classes.primaryButton : classes.secondaryButton
                  } ${!inProgress && classes.disabledButton}`}>
                  Or
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginBottom: '0.5rem'
              }}>
              <button
                style={{ marginRight: '0.5rem' }}
                className={`${classes.secondaryButton} ${!inProgress && classes.disabledButton}`}
                disabled={!inProgress}
                onClick={() => handleExpressionUpdate('cancel-all')}
                data-testid="cancel">
                Cancel
              </button>
              <button
                className={`${classes.primaryButton} ${
                  (!inProgress || !localFilterName || !localDefaultForm) && classes.disabledButton
                }`}
                onClick={() => handleExpressionUpdate('save-all')}
                disabled={!inProgress || !localFilterName || !localDefaultForm || isLoading}
                data-testid="save">
                Save
              </button>
            </Grid>
          </Grid>
          <div style={{ width: '100%' }}>
            {expressions?.map((exp, index) => (
              <FilterExpression
                key={'FilterExpression-' + exp.id}
                index={index}
                sourceFieldList={sourceFormItems[localDefaultForm] || []}
                handleExpressionUpdate={handleExpressionUpdate}
                defaultForm={localDefaultForm}
                allForms={allForms}
                allItems={allItems}
                setConfirmAlert={setConfirmAlert}
                methodsConstants={methodsConstants}
                expressionTemplates={expressionTemplates}
                displaySearchFields={displaySearchFields}
                formatedFormItemData={formatedFormItemData}
                {...exp}
              />
            ))}
          </div>
          <span
            style={{
              marginTop: '1rem',
              width: '100%',
              minWidth: '100%',
              backgroundColor: '#D5D5D5',
              height: '1px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '2rem',
              justifyContent: 'center'
            }}>
            <Tooltip
              title={
                !localFilterName || !localDefaultForm
                  ? 'Complete Filter Name & Default Form'
                  : 'Add filter expression'
              }>
              <CloseCircle
                onClick={handleAddExpression}
                disabled={!inProgress || !localFilterName || !localDefaultForm}
                data-testid="add-filter-expression"
                style={{
                  transform: 'rotate(45deg)',
                  ...(!inProgress || !localFilterName || !localDefaultForm
                    ? { color: '#0768FD88', cursor: 'default', pointerEvents: 'none' }
                    : { color: '#0768FD', cursor: 'pointer' })
                }}
              />
            </Tooltip>
          </span>
        </AccordionDetails>
      )}
      <Banner
        variant={banner.variant}
        open={banner.enabled}
        onClose={() => setBanner({ enabled: false })}
        message={banner.message}
        style={{ position: 'absolute' }}
        TransitionComponent={Fade}
      />
    </Accordion>
  );
};

export default React.memo(Filter);
