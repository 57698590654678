const allowedExtensions = ['xlsx', 'xls'];

export const checkFileType = (fileName, setError) => {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    const isExcelFile = allowedExtensions.includes(extension);
    if (isExcelFile) {
      return true;
    } else {
      setError('Only Excel File Supported');
      return false;
    }
  };

export const checkFileData = (records) => {
    let isValid = true;
    if (records.length > 0) {
      if (records.length === 1) {
        isValid = Object.values(records[0]).some((x) => {
          return x !== '';
        });
      }
    } else {
      isValid = false;
    }
    return isValid;
  };

export const checkColumns = (headers, columns) => {
    let isColumnsValid = true;
    if (columns.length !== headers.length) {
      return false;
    }
    headers.forEach((header) => {
      if (!columns.includes(header)) {
        isColumnsValid = false;
      }
    });
    return isColumnsValid;
  };

  export const fileValidation = (fileData, headers, columns) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    }
    return error;
  };