/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const EditGlobalLibrarySlice = createSlice({
  name: 'EditGlobalLibraryData',

  initialState: {
    rowData: '',
    filterAddPage: false
  },
  reducers: {
    setEditGlobalLibraryData(state, { payload }) {
      state.rowData = payload;
    },
    setFilterAddGlobalPage(state, { payload }) {
      state.filterAddPage = payload;
    }
  }
});

export const { setEditGlobalLibraryData, setFilterAddGlobalPage } = EditGlobalLibrarySlice.actions;
