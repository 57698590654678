import Modal from 'apollo-react/components/Modal';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ProtocolDetails from '../DataRefreshHistory/Components/ProtocolDetails';
import { makeStyles } from '@mui/styles';
import Download from 'apollo-react-icons/Download';
import Grid from 'apollo-react/components/Grid';

const useStyles = makeStyles({
  container: {
    maxHeight: '90vh',
    overflow: 'hidden',
    maxWidth: '90vw',
    width: '80vw'
  },
  logContainer: {
    width: '100%',
    border: '1px solid #E9E9E9',
    borderRadius: '0.25rem',
    maxHeight: '55vh',
    overflow: 'hidden'
  },
  logHeader: {
    height: '2rem',
    backgroundColor: '#F6F7FB',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    color: '#595959',
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    fontWeight: '500'
  },
  logText: {
    height: 'calc(55vh - 2rem)',
    overflow: 'auto',
    color: '#595959',
    fontFamily: 'Courier New, Monospace',
    fontSize: '14px',
    lineHeight: '16px',

    padding: '0.5rem',
    '& > div': {
      whiteSpace: 'pre',
      wordWrap: 'revert'
    }
  }
});

/**
 * ViewLog - Modal Component to display Refresh History logs
 * @props {boolean} open
 * @props {string} logText
 * @props {string} size
 * @props {function} setViewLogConfig
 */
const ViewLog = ({ open = false, logText, size, setViewLogConfig, file, fileName }) => {
  const classes = useStyles();
  const { protocolDetails } = useSelector((state) => state.DataRefreshHistory);

  const handleDownload = () => {
    if (window?.navigator?.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
    } else {
      const downloadLogAnchor = document.createElement('a');
      downloadLogAnchor.href = URL.createObjectURL(file, { oneTimeOnly: true });
      downloadLogAnchor.download = fileName;
      document.body.appendChild(downloadLogAnchor);
      downloadLogAnchor.click();
      document.body.removeChild(downloadLogAnchor);
    }
  };

  const buttonProps = useMemo(
    () => [
      {
        label: 'Download',
        variant: 'secondary',
        icon: Download,
        onClick: handleDownload,
        'data-testid': `download-${fileName}`
      },
      {
        label: 'Close',
        variant: 'primary',
        onClick: () => setViewLogConfig({ open: false }),
        'data-testid': `close-${fileName}`
      }
    ],
    [file, fileName]
  );

  return (
    <Modal
      title={'Data Refresh Log Details'}
      open={open}
      onClose={() => setViewLogConfig({ open: false })}
      buttonProps={buttonProps}
      id={'view-log-modal'}
      className={classes.container}
      data-testid="view-log-modal">
      <ProtocolDetails protocolDetails={protocolDetails} />
      <Grid container className={classes.logContainer}>
        <Grid item xs={12} className={classes.logHeader}>
          <span>File Size: {size}</span>
        </Grid>
        <Grid item xs={12} className={classes.logText}>
          <div>{logText}</div>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ViewLog;
