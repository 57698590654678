import { useSelector } from 'react-redux';

/**
 * Custom hook:
 * If a mapping exists in 'nameMappings', it returns the mapped name; otherwise, it returns the original value.
 * 
 * @returns {function} - Returns a function to get the display name for a given value.
 - Usage: `const getDisplayName = useDisplayName(); const displayName = getDisplayName('originalValue')`;
 */
export const useDisplayName = () => {
  const { nameMappings = {} } = useSelector((state) => state.AppData);
  return (originalValue) => nameMappings[originalValue] || originalValue;
};

export default useDisplayName;
