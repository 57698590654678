/*eslint-disable */
import React, { useMemo, useState } from 'react';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import { makeStyles } from '@mui/styles';
import Card from 'apollo-react/components/Card';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import { generateErrorString, getHtmlString } from 'Utils';
import { methodsAndConstantsMapping } from '../../../MethodandConstantMapping';
import Search from 'apollo-react-icons/Search';

const SEARCH_FIELDS = 'Search fields';

const FilterExpression = ({
  defaultForm,
  sourceFieldList,
  expression = '',
  handleExpressionUpdate,
  id: expId,
  index,
  inProgress,
  isValid,
  result,
  setConfirmAlert,
  methodsConstants,
  expressionTemplates,
  displaySearchFields,
  formatedFormItemData
}) => {
  const [caretPosition, setCaretPosition] = useState(0);
  const [searchFieldFormItem, setSearchFieldFormItem] = useState();

  const selectClasses = makeStyles({
    hint: {
      color: '#0768FD'
    },
    menuPlaceHolder: {
      '&:hover': {
        color: 'blue'
      }
    }
  })();

  const menuItems = useMemo(
    () => [
      {
        text: 'Edit expression',
        onClick: () => handleExpressionUpdate('edit-exp', expId)
      },
      {
        text: 'Delete expression',
        onClick: () => {
          setConfirmAlert({
            enabled: true,
            variant: 'warning',
            title: 'Warning',
            message: 'Are you sure you want to delete this filter condition?',
            onConfirm: () => {
              handleExpressionUpdate('delete-exp', expId);
              setConfirmAlert({
                enabled: false
              });
            },
            onCancel: () => {
              setConfirmAlert({
                enabled: false
              });
            }
          });
        }
      }
    ],
    []
  );

  const handleSourceFieldChange = (e) => {
    if (e.target.value && e.target.value !== SEARCH_FIELDS) {
      const exp = `${expression.slice(0, caretPosition)}[${defaultForm}].[${
        e.target.value
      }]${expression.slice(caretPosition)}`;
      setCaretPosition(exp.length);
      handleExpressionUpdate('save-exp', expId, exp);
      setSearchFieldFormItem(e.target.value);
    } else if (e.target.value === SEARCH_FIELDS) {
      displaySearchFields({
        enabled: true,
        onAdd: (formName, itemName, sourceName) => {
          const exp = `${expression.slice(
            0,
            caretPosition
          )}[${formName}].[${itemName}]${expression.slice(caretPosition)}`;
          setCaretPosition(exp.length);
          handleExpressionUpdate('save-source-name', expId, exp, sourceName);
          setSearchFieldFormItem(`${formName}/${itemName}`);
          displaySearchFields({ enabled: false });
        },
        onCancel: () => {
          displaySearchFields({ enabled: false });
        }
      });
    }
  };

  const handleMethodSelect = (e) => {
    const value = methodsAndConstantsMapping[e.target.value];
    const exp = `${expression.slice(0, caretPosition)}${value}${expression.slice(caretPosition)}`;
    handleExpressionUpdate('save-exp', expId, exp);
    setCaretPosition(exp.length);
  };

  const handleExpressionTemplateSelect = (e) => {
    const exp = `${expression.slice(0, caretPosition)}${e.target.value}${expression.slice(
      caretPosition
    )}`;
    handleExpressionUpdate('save-exp', expId, exp);
    setCaretPosition(exp.length);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '1rem 2rem'
      }}>
      <Card
        interactive
        style={{
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1rem 1rem 0rem 1rem'
          }}>
          <span data-testid="filter-id">Filter Condition {index + 1}</span>
          <span>
            <IconMenuButton id="actions" menuItems={menuItems} disabled={inProgress} size="small">
              <MoreVertIcon />
            </IconMenuButton>
          </span>
        </div>
        {inProgress ? (
          <div style={{ padding: '0rem 1rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                gap: '15px'
              }}>
              <Select
                label="Source Field"
                onChange={handleSourceFieldChange}
                placeholder={searchFieldFormItem || 'Select one...'}
                style={{ width: '25%' }}
                required>
                {sourceFieldList?.items?.map((item, index) => {
                  return (
                    <MenuItem value={item} key={`SourceField-${index}`}>
                      {item}
                    </MenuItem>
                  );
                })}
                <MenuItem value={SEARCH_FIELDS} key={`SourceField-Serach-Fields`}>
                  <span class={selectClasses?.menuPlaceHolder} style={{ display: 'flex' }}>
                    <Search /> Search fields
                  </span>
                </MenuItem>
              </Select>
              <Select
                onChange={handleMethodSelect}
                placeholder="Add Method/Constant"
                classes={selectClasses}
                style={{ width: '25%' }}>
                {methodsConstants
                  .filter((m) => m !== 'QCVSEQ()')
                  .map((item, index) => (
                    <MenuItem value={item} key={`Method-${index}`}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
              <Select
                onChange={handleExpressionTemplateSelect}
                placeholder="Add Expression Template"
                classes={selectClasses}
                style={{ width: '25%' }}>
                {expressionTemplates
                  .filter((item) => item.templateExpressionType.includes('D'))
                  .map((item, index) => (
                    <MenuItem value={item.templateExpression} key={`Method-${index}`}>
                      {item.templateExpression}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <CustomeTextField
              id="expression"
              onChange={(value) => {
                handleExpressionUpdate('save-exp', expId, value);
              }}
              onCaretPositionchange={(pos) => setCaretPosition(pos)}
              name="expression"
              data-testid="expression-input"
              error={!isValid}
              helperText={isValid ? null : result?.message}>
              {isValid
                ? getHtmlString(expression, formatedFormItemData, defaultForm)
                : generateErrorString(
                    expression,
                    result?.cordinates?.start,
                    result?.cordinates?.end
                  )}
            </CustomeTextField>
          </div>
        ) : (
          <div style={{ padding: '1rem' }}>
            <CustomeTextField
              id="expression-preview"
              name="expression-preview"
              data-testid="expression-preview"
              disabled>
              {getHtmlString(expression, formatedFormItemData, defaultForm)}
            </CustomeTextField>
          </div>
        )}
      </Card>
    </div>
  );
};
export default React.memo(FilterExpression);
