/*eslint-disable */
// Frameworks
import { useState, useEffect } from 'react';
// UI Components
import Switch from 'apollo-react/components/Switch';
import Button from 'apollo-react/components/Button';
import { Grid, Typography } from '@mui/material';
import Table, { createStringSearchFilter } from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import TextField from 'apollo-react/components/TextField';
import { EllipsisCell } from 'Components/Ellipsis/Ellipsis';

// Icons
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Refresh from 'apollo-react-icons/Refresh';
import Info from 'apollo-react-icons/Info';
import Filter from 'apollo-react-icons/Filter';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import Pencil from 'apollo-react-icons/Pencil';
import ApolloProgress from 'apollo-react/components/ApolloProgress';
import { neutral7 } from 'apollo-react/colors';
import {
  compareStrings,
  iconDisplay,
  domainDisplayBesideIcon,
  warningIconDisplay
} from './utilites';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Failure from 'Images/status-failure.svg';

const EmptyTableTypographyStyle = { color: neutral7, lineHeight: '24px', width: '100%' };

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};
const EmptyTableTypographyStyleTitle2 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999'
};
const Miscellaneous = (props) => {
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (props.unknownData.length) {
      let filteredRows = props.unknownData;
      filteredRows.map((row) => {
        if (row.errorCode !== undefined && (row.logType === 'Error' || row.logType === null)) {
          row.isError = true;
          row.isErrorText = 'Yes';
        } else row.isErrorText = 'No';
      });

      setRows(filteredRows);
    }
  }, [props.unknownData]);

  const handleShowError = (e, checked) => {
    setShowError(checked);
  };

  const handleRefresh = () => {
    setShowLoader(true);
    //Make API calls instead of timeout
    setTimeout(() => setShowLoader(false), 5000);
  };

  const isErrorCell = ({ row }) => {
    let copyOfIconDisplay = JSON.parse(JSON.stringify(iconDisplay));
    let copyOfWarningIconDisplay = JSON.parse(JSON.stringify(warningIconDisplay));
    return (
      <div
        style={{
          fontSize: '14px'
        }}>
        {row.isError ? (
          <>
            <img src={Failure} style={Object.assign(copyOfIconDisplay, { color: 'red' })} />{' '}
            <span style={domainDisplayBesideIcon}>{'Yes'}</span>
          </>
        ) : row.logType === 'Warning' ? (
          <Tooltip placement={'bottom'} variant="dark" title="Warning">
            <span>
              <Info style={Object.assign(copyOfWarningIconDisplay, { color: 'orange' })} />{' '}
              <span style={domainDisplayBesideIcon}>{'No'}</span>
            </span>
          </Tooltip>
        ) : (
          <>
            <StatusCheck style={Object.assign(copyOfIconDisplay, { color: '#00C221' })} />{' '}
            <span style={domainDisplayBesideIcon}>{row.domainName}</span>
          </>
        )}
      </div>
    );
  };

  const hasUpdateCell = ({ row }) => {
    return row.isError ? (
      <Button size="small">
        <Pencil
          style={{
            fontSize: '17px',
            marginRight: '5px'
          }}
        />{' '}
        Update
      </Button>
    ) : null;
  };

  const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        fullWidth
        margin="none"
        size="small"
      />
    );
  };

  const columns = [
    !props.cloneFlag && {
      header: 'Is Error',
      accessor: 'isErrorText',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('isErrorText')
    },
    {
      header: props.cloneFlag ? 'Warnings' : 'Error Description',
      accessor: 'message',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('message'),
      customCell: EllipsisCell
    }
  ];

  useEffect(() => {
    if (showError) {
      let this_filteredRows = rows.filter((row) => row.isError);
      setFilteredRows(this_filteredRows);
    }
  }, [showError]);

  const CustomHeader = ({ toggleFilters }) => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={7} style={{ marginTop: '0.5rem' }}>
          <Typography
            style={{
              color: '#000000',
              fontFamily: 'Proxima Nova',
              fontSize: '16px',
              fontWeight: 600,
              letterSpacing: 0,
              display: 'flex',
              alignItems: 'center'
            }}>
            Mapping Spec
            {/* <Refresh
              onClick={handleRefresh}
              style={{
                fontSize: '22px',
                color: '#0768fd',
                marginLeft: '10px',
                cursor: 'pointer'
              }}
            /> */}
          </Typography>
          <Typography
            style={{
              color: 'grey',
              fontFamily: 'Proxima Nova',
              fontSize: '14px',
              fontWeight: 400,
              letterSpacing: 0
            }}>
            Miscellaneous Errors
          </Typography>
        </Grid>
        <Grid item xs={5} style={{ padding: '15px', display: 'flex', justifyContent: 'end' }}>
          {!props.cloneFlag && (
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <span
                style={{
                  fontSize: '13px',
                  fontWeight: '400',
                  marginRight: '13px',
                  marginTop: '2px'
                }}>
                {'Only show errors'}
              </span>
              <Switch size="small" checked={showError} onChange={handleShowError} />
            </Grid>
          )}
          <Button variant="secondary" size="small" data-testid="filter-btn" onClick={toggleFilters}>
            <Filter
              style={{
                fontSize: '18px',
                marginRight: '8px'
              }}
            />
            Filter
          </Button>
        </Grid>
      </Grid>
    );
  };

  const EmptyTableComponent = () => {
    return props.errorMsg ? (
      <>
        <StatusExclamation fontSize="large" style={EmptyTableTypographyStyle} />
        <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
          Something Went Wrong
        </Typography>
        <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
          We couldnt load the data
        </Typography>
        <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
          please try again
        </Typography>
        <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
          <Button
            variant="secondary"
            icon={<Refresh />}
            size="small"
            style={{ marginRight: 10, marginTop: '16px' }}
            onClick={props.triggerGetApi}>
            Retry
          </Button>
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
          No errors found
        </Typography>
        {/* <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
          We couldnt load the data please try again
        </Typography> */}
      </>
    );
  };

  return (
    <>
      {showLoader && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '9',
            backgroundColor: 'rgba(255,255,255,0.6)'
          }}>
          <ApolloProgress />
        </div>
      )}
      <Table
        columns={columns}
        rows={showError ? filteredRows : rows}
        rowsPerPageOptions={[10, 50, 100, 'All']}
        hasScroll
        maxHeight={650}
        rowProps={{ hover: false }}
        initialSortOrder="asc"
        initialSortedColumn={columns[1].accessor}
        tablePaginationProps={{
          labelDisplayedRows: DisplayedRowsLabel,
          truncate: true
        }}
        CustomHeader={(props) => <CustomHeader {...props} />}
        emptyProps={{
          text: <EmptyTableComponent />
        }}
      />
    </>
  );
};
export default Miscellaneous;
