import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetAssignedProtocolDetails = createAsyncThunk(
  'CloneDataProductService/GetAssignedProtocolDetails',
  () => axios.get(`${API_URL}/api/DataProduct/GetAssignedProtocolDetails`)
);
export const GetCloneDataProductSteppers = createAsyncThunk(
  'CloneDataProductService/GetCloneDataProductSteppers',
  () => axios.get(`${API_URL}/api/RuleEditor/GetWorkFlowModel?LibraryType=CloneDataProduct`)
);

export const getDataProductsForClone = createAsyncThunk(
  'CloneDataProductService/GetDataProductsForClone',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/api/Publish/GetDataProductsForClone`, payload);
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
export const saveCloneDataProduct = createAsyncThunk(
  'CloneDataProductService/SaveCloneDataProduct',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/api/DataProduct/CloneDataProduct`, payload);
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const GetStudyLibrariesForCloneDP = createAsyncThunk(
  'CloneDataProductService/GetStudyLibrariesForCloneDP',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/DataProduct/GetStudyLibrariesForCloneDP`,
        payload
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
