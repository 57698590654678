
import React, { useState } from 'react';
import Card from 'apollo-react/components/Card';
import { Select } from 'Components/Controls';

const CardEmptyLayout = (props) => {
  const { dropdownCards } = props;
  const [cardItems, setCardItems] = useState([]);

  React.useEffect(() => {
    setCardItems(dropdownCards.map((el) => ({ label: el.name, value: el.module })));
  }, [dropdownCards]);

  const handleCardChange = (e) => {
    const copyItems = [...cardItems];
    setCardItems(
      copyItems.filter((el) => {
        if (el.value === e.target.value) {
          el.visible = true;
          props.configuration.setCardVisibility(el.value, el.visible);
        }
        return el.value !== e.target.value;
      })
    );
  };

  return (
    <>
      {cardItems.length !== 0 && (
        <>
          <div style={{ maxWidth: '325px', marginTop: '-10px' }}>
            <Select
              id="type"
              name=""
              label=""
              data-testid="type-input"
              placeholder={<span style={{ color: '#0768FD' }}>Add Reference Data(Optional)</span>}
              value=""
              onChange={handleCardChange}
              fullWidth
              items={cardItems}
              disabled={false}
            />
            <Card style={{ height: '310px', padding: '15px' }} disabled={false} />
          </div>
        </>
      )}
    </>
  );
};

export default CardEmptyLayout;
