import styled from 'styled-components';

export const LoaderOverlay = styled('div')({
  width: '100%',
  height: 'calc(100vh - 114px)',
  position: 'fixed',
  zIndex: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fffa'
});
