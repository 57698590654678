/*eslint-disable */
import ChevronDown from 'apollo-react-icons/ChevronDown';
import Button from 'apollo-react/components/Button';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import React, { useEffect, useState } from 'react';
import { neutral7, neutral8 } from 'apollo-react/colors';
import Checkbox from 'apollo-react/components/Checkbox';
import PreviewTable from 'Pages/DataStandardLibrary/AddGlobalLibrary/PreviewTable';
import Switch from 'apollo-react/components/Switch';
import IconButton from 'apollo-react/components/IconButton';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import Refresh from 'apollo-react-icons/Refresh';
import ChartBar from 'apollo-react-icons/ChartBar';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Loader from 'Components/Loader';
import AuditTable from '../../../../Components/Modal/Components/AuditHistoryTable';
import {
  downloadGlobalLibrary,
  saveAddGlobalLibrary,
  GetViewGlobalLibraries
} from 'Redux/Service/GlobalLibraryService';
import { makeStyles } from '@mui/styles';
import CustomModal from '../../../../Components/Modal';
import { getAuditHistory } from 'Redux/Service/ModalService';
import {
  setEditGlobalLibraryData,
  setFilterAddGlobalPage
} from 'Redux/Slice/EditGlobalLibrarySlice';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Filter from 'apollo-react-icons/Filter';

import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  fileByteToFileObject,
  jsonToExcel,
  dateFormatByType,
  dateFilter,
  compareDates
} from 'Utils';
import { unwrapResult } from '@reduxjs/toolkit';
import { getUpdatedGlobalLibraries } from 'Redux/Slice/GlobalLibrarySlice';
import { hasNoAccess } from 'service/AppPermissions';
import { DateFilter } from 'Components/Common/DateFilter';
import { getVariableRuleSets } from 'Redux/Service/AddGlobalLibraryService';
import RuleSetEditor from 'Components/RuleSet/RuleSetEditor';
import Box from 'apollo-react/components/Box';
import styled from 'styled-components';
import TableWrapper from 'Components/Common/TableWrapper';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      borderRadius: '100px',
      boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75);'
    }
  },
  popover: {
    pointerEvents: 'none',
    wordBreak: 'break-all'
  },
  button: {
    marginRight: 8,
    marginTop: 8
  },
  modal: {
    '&': {
      maxWidth: '100%'
    }
  },
  modalDelete: {
    '&': {
      width: '472px'
    }
  },
  modalAudit: {
    '&': {
      width: '1257px',
      maxWidth: 'none'
    }
  },
  ruleSetModal: {
    '&': {
      width: '1000px',
      maxWidth: 'none'
    }
  }
});

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const ExpandCell = ({ row: { id, handleToggleRow, expanded } }) => {
  return (
    <div style={{ width: 12 }}>
      <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
        <IconButton id="expand" size="small" onClick={() => handleToggleRow(id)}>
          {expanded ? <ChevronDown /> : <ChevronRight />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

const ActiveCell = ({ row, column: { accessor } }) => {
  const isActive = row[accessor];
  if (isActive === 'Active') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0 0' }}>
        <span
          style={{
            width: '5px',
            height: '5px',
            borderRadius: '100%',
            background: '#00C221',
            marginRight: '4px'
          }}></span>
        <Typography variant="title1" style={{ fontSize: '14px', margin: '3px 0' }}>
          {isActive}
        </Typography>
      </div>
    );
  } else {
    return <div>{isActive}</div>;
  }
};

const versionCell = ({ row: { version } }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '24px' }}>
      <span>{version}</span>
    </div>
  );
};

const EllipsisCell = ({ row, column: { accessor } }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '10rem' }}>
      <Tooltip variant="light" title={row.description} placement="bottom" open={open}>
        <Typography
          noWrap
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {row.description}
        </Typography>
      </Tooltip>
    </div>
  );
};

const Cell = ({ row, column }) => (
  <div style={{ paddingTop: row.editMode ? 12 : 0 }}>{row[column.accessor]}</div>
);

const GlobalLibrary = (props) => {
  const classes = useStyles();
  const [expandedRows, setExpandedRows] = useState([]);
  const [isActive, setActive] = useState(true);
  const [globalLibraries, setGlobalLibraries] = useState([]);
  const [globalDataList, setGlobalDataList] = useState([]);
  const loading = useSelector((state) => state.LoaderData.loading);
  const { updatedGlobalLibraries } = useSelector((state) => state.ViewGlobalLibrary);
  const { disableComponentsBasedOnRole } = useSelector((state) => state.LoginFormData);

  const CHANGE_HISTORY = 'Change History';
  const DASHBOARD_PATH = '/dashboard';
  const DATA_STANDARD_LIBRARIES = 'Data Standard Libraries';
  const DATA_STANDARD_LIBRARIES_PATH = '/data-standard-library/global-library';
  const globalLibraryCount = globalLibraries.length !== 1 ? 'items' : 'item';

  const ActionCell = ({ row }) => {
    const { editMode } = row;
    const [downloadModal, setDownloadModal] = React.useState(false);
    const [previewModal, setPreviewModal] = React.useState(false);
    const [fileName, setFileName] = React.useState('');
    const [auditModal, setAuditModal] = React.useState(false);
    const [auditData, setAuditData] = React.useState('');
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [deleteData, setData] = React.useState({});
    const [value, setValue] = React.useState(false);
    const [libraryRows, setLibraryRows] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [deleteButton, showDeleteButton] = React.useState(false);
    const [reasonForDelete, setReasonFordelete] = React.useState('');
    const [helpText, setHelpText] = React.useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let menuItems = [];

    const onRowAudit = async (row) => {
      setAuditModal(true);
      setData({
        type: 'neutral',
        title: CHANGE_HISTORY,
        label: 'Close',
        libraryName: row.libraryName
      });
      let resArr = [];
      let payload = { libraryId: row.libraryID };

      const auditHistory = getAuditHistory(payload);
      auditHistory
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.success) {
            setLoading(false);
            response.auditHistory.map((el) => {
              resArr.push({
                userId: el.userId,
                auditDate: dateFormatByType(el.auditDate, 'Table'),
                libraryVersion: el.libraryVersion,
                reasonForChange: el.reasonForChange ? el.reasonForChange : '-',
                auditType: el.auditType
              });
            });

            setAuditData(resArr);
          } else {
            setAuditModal(false);
            dispatch(showBanner({ variant: 'error', message: response.message }));
          }
        });
    };

    const onRowDelete = (row) => {
      showDeleteButton(false);
      setDeleteModal(true);
      setData({
        type: 'error',
        message:
          'Please provide a reason for deleting this library.Once you delete, this action cannot be undone.',
        title: 'Delete Library',
        label: 'Delete',
        subtitle: 'Optional Subtitle'
      });
    };

    const onRowEdit = (data) => {
      dispatch(setEditGlobalLibraryData(data));
      dispatch(setFilterAddGlobalPage(false));
      dispatch(
        breadCrumbData([
          { path: DASHBOARD_PATH },
          {
            title: DATA_STANDARD_LIBRARIES,
            path: DATA_STANDARD_LIBRARIES_PATH
          },
          {
            title: 'Edit Global Library',
            path: '/edit-standard-library/add-global-library'
          }
        ])
      );
      navigate('/data-standard-library/edit-global-library');
    };

    const onExport = (row) => {
      setDownloadModal(true);
    };

    const getVariableRuleSetsOnEdit = async (rowData) => {
      const variableRuleSets = await dispatch(getVariableRuleSets(rowData)).then(unwrapResult);
      if (variableRuleSets?.data?.success) {
        setLibraryRows(variableRuleSets.data.variableRulesets);
        setLoading(false);
        return variableRuleSets.data.variableRulesets;
      } else {
        setLibraryRows([]);
        dispatch(showBanner({ variant: 'error', message: variableRuleSets?.data?.message }));
      }
    };

    const onPreview = async (row) => {
      setPreviewModal(true);
      if (row.libraryType !== 'Ruleset') {
        setFileName(row.fileName);
        const globalLibraryResponse = await downloadGlobalLibrary({
          libraryId: row.libraryID,
          libraryVersion: row.version
        });
        setLibraryRows(globalLibraryResponse?.data?.downloadDomainColumns || []);
        setLoading(false);
      } else {
        getVariableRuleSetsOnEdit(row);
      }
    };

    const handleClose = () => {
      setDownloadModal(false);
      setDeleteModal(false);
      setAuditModal(false);
      setReasonFordelete('');
    };

    const handlePreviewModal = () => {
      setPreviewModal(false);
    };

    {
      row.auditType === 'DELETE' || row.activeFlag === 'Inactive'
        ? (menuItems = [
            {
              text: 'Preview',
              onClick: () => onPreview(row),
              disabled: hasNoAccess('globalLibrary', 'previewGlobalLibrary')
            },
            {
              text: 'Export',
              onClick: () => onExport(row),
              disabled: hasNoAccess('globalLibrary', 'exportGlobalLibrary')
            },
            {
              text: CHANGE_HISTORY,
              onClick: () => onRowAudit(row),
              disabled: hasNoAccess('globalLibrary', 'changeHistoryGlobalLibrary')
            }
          ])
        : (menuItems = [
            {
              text: 'Edit',
              onClick: () => onRowEdit(row),
              disabled: hasNoAccess('globalLibrary', 'editGlobalLibrary')
            },
            {
              text: 'Preview',
              onClick: () => onPreview(row),
              disabled: hasNoAccess('globalLibrary', 'previewGlobalLibrary')
            },
            {
              text: 'Export',
              onClick: () => onExport(row),
              disabled: hasNoAccess('globalLibrary', 'exportGlobalLibrary')
            },
            {
              text: 'Delete',
              onClick: () => onRowDelete(row),
              disabled: hasNoAccess('globalLibrary', 'deleteGlobalLibrary')
            },
            {
              text: CHANGE_HISTORY,
              onClick: () => onRowAudit(row),
              disabled: hasNoAccess('globalLibrary', 'changeHistoryGlobalLibrary')
            }
          ]);
    }

    const handleDownload = async () => {
      if (row.libraryType !== 'Ruleset') {
        downloadGlobalLibrary({
          libraryId: row.libraryID,
          libraryVersion: row.version,
          isAllColumns: value
        })
          .then((res) => {
            res.data.downloadDomainColumns.forEach((item) => {
              if (!value) {
                let row = item;
                Object.keys(row).forEach((key) => {
                  if (row[key] === null) {
                    delete row[key];
                  }
                });
              }
            });
            jsonToExcel(res.data.downloadDomainColumns, res.data.filename);
          })
          .catch((err) => console.log(err));
      } else {
        const rulesets = await getVariableRuleSetsOnEdit(row);
        const newFormattedRuleSets = [];
        rulesets.forEach((item) => {
          newFormattedRuleSets.push({
            VariableName: item.variableName,
            Label: item.label,
            Expression: item.expression
          });
        });
        jsonToExcel(newFormattedRuleSets, 'RuleSet.xlsx', 'Ruleset');
      }
      setDownloadModal(false);
    };

    const handleDelete = () => {
      let payload = {
        libraries: {
          libraryType: row.libraryType,
          libraryName: row.libraryName,
          description: row.description,
          version: row.version,
          userid: row.userid,
          comments: row.comments,
          category: row.category,
          libraryID: row.libraryID,
          auditType: 'DELETE',
          reasonForChange: reasonForDelete,
          isActive: false,
          auditDate: new Date().toISOString()
        }
      };

      const deleteDataLibrary = saveAddGlobalLibrary(payload);
      deleteDataLibrary
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.success) {
            dispatch(showBanner({ variant: 'success', message: response.message }));
            dispatch(GetViewGlobalLibraries({ IsActive: true, exceptRuleset: false }));
            dispatch(getUpdatedGlobalLibraries(true));
            setDeleteModal(false);
          } else {
            dispatch(showBanner({ variant: 'error', message: response.message }));
          }
        });
    };

    const handleChange = (e, checked) => {
      setValue(checked);
    };

    const reasonforDeleteValue = (e) => {
      showDeleteButton(false);
      if (e.target.value !== '') {
        if (e.target.value.length > 400) {
          setReasonFordelete(e.target.value.substring(0, e.target.value.length - 1));
          showDeleteButton(true);
          setHelpText(true);
        } else {
          setReasonFordelete(e.target.value);
          showDeleteButton(true);
          setHelpText(false);
        }
      } else {
        setReasonFordelete('');
        showDeleteButton(false);
        setHelpText(false);
      }
    };

    const previewModalBodyConfig = () => {
      if (loading) {
        return <Loader />;
      } else if (row.libraryType !== 'Ruleset') {
        return <PreviewTable rows={libraryRows} />;
      } else {
        return (
          <RuleSetEditor
            preview
            previewTitle={row.libraryName}
            uploadLibraryRuleSet={libraryRows}
          />
        );
      }
    };

    return (
      <>
        {editMode ? (
          <div style={{ marginTop: 8, whiteSpace: 'nowrap' }}>
            <Button size="small" style={{ marginRight: 8 }} onClick={onCancel}>
              {'Cancel'}
            </Button>
            <Button
              size="small"
              variant="primary"
              onClick={onRowSave}
              disabled={
                Object.values(editedRow).some((item) => !item) ||
                (editMode && !Object.keys(editedRow).some((key) => editedRow[key] !== row[key]))
              }>
              {'Save'}
            </Button>
          </div>
        ) : (
          <div className={classes.root}>
            <Tooltip disableFocusListener>
              <IconMenuButton id="actions" menuItems={menuItems} className={classes.menuOverflow}>
                <EllipsisVerticalIcon />
              </IconMenuButton>
            </Tooltip>
          </div>
        )}

        <CustomModal
          display={downloadModal}
          handleClose={handleClose}
          title={'Export Library'}
          subtitle={'This file will be downloaded as Excel file.'}
          message={
            row.libraryType !== 'Ruleset' && (
              <Checkbox label="Include System fields" checked={value} onChange={handleChange} />
            )
          }
          buttonPrimaryLabel={'Export'}
          handlePrimaryAction={handleDownload}
          buttonSecondardyLabel={'Cancel'}
          handleSecondaryAction={() => setDownloadModal(false)}
        />

        <CustomModal
          display={previewModal}
          handleClose={handlePreviewModal}
          title={row.libraryType !== 'Ruleset' ? row.libraryName : 'Preview'}
          subtitle={fileName}
          type="Preview"
          buttonSecondardyLabel={'Close'}
          body={previewModalBodyConfig()}
          handleSecondaryAction={() => setPreviewModal(false)}
          width={row.libraryType !== 'Ruleset' ? classes.modal : classes.ruleSetModal}
        />

        <CustomModal
          type="Delete"
          show={deleteButton}
          display={deleteModal}
          data={row}
          // capture={deleteData.type}
          title={
            <>
              {deleteData.title}
              <br />
              <span
                style={{
                  fontSize: '13px',
                  color: 'black',
                  width: '100%',
                  float: 'left',
                  marginTop: '10px'
                }}>
                {' '}
                {`Library Name: ${row.libraryName}`}
              </span>
              <span style={{ fontSize: '13px', color: 'black' }}>
                {' '}
                {`Library ID: ${row.libraryID}`}
              </span>
              {/* <span style={{ fontSize: '13px', color: 'black' }}>{row.libraryID}</span> */}
            </>
          }
          message={
            <>
              <Typography>
                Please provide a reason for deleting this library.
                <br />
                Once you delete, this action cannot be undone.
              </Typography>
              <div style={{ maxWidth: 400 }}>
                <TextField
                  id="reasonforDelete"
                  label=""
                  name="reasonforDelete"
                  value={reasonForDelete}
                  placeholder="Enter Text"
                  onChange={(e) => reasonforDeleteValue(e)}
                  multiline
                  fullWidth
                  // inputProps={{
                  //   maxLength: 5,
                  // }}

                  helperText={
                    helpText && (
                      <span style={{ color: 'red' }}>Maximum character limit reached</span>
                    )
                  }
                  // error={!deleteButton}
                />
              </div>
            </>
          }
          // message={deleteData.message}
          buttonPrimaryLabel={'Delete'}
          handlePrimaryAction={() => handleDelete()}
          buttonSecondardyLabel={'Cancel'}
          handleClose={handleClose}
          variant={'error'}
          width={classes.modalDelete}
        />

        <CustomModal
          display={auditModal}
          data={row}
          title={`${CHANGE_HISTORY + ' ' + deleteData.libraryName}`}
          // subtitle={row.libraryId}
          message={deleteData.message}
          subtitle={row.libraryID}
          handleClose={handleClose}
          type="History"
          handleSecondaryAction={() => setAuditModal(false)}
          buttonSecondardyLabel={'Close'}
          body={
            loading ? (
              <div
                style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}>
                <Loader />
              </div>
            ) : (
              <AuditTable rows={auditData} />
            )
          }
          width={classes.modalAudit}
        />
      </>
    );
  };

  const DetailRow = ({ row }) => {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        style={{ backgroundColor: '#f8f9fb', width: '100%', padding: '0.5rem 2rem' }}>
        <Box
          style={{
            minWidth: '100%',
            marginBottom: '0.5rem'
          }}>
          <Typography style={{ fontWeight: 500, color: neutral8, width: '100%' }}>
            {'Library ID'}
          </Typography>
          <Typography style={{ color: neutral7, width: '100%' }} variant="body2">
            {row.libraryID}
          </Typography>
        </Box>
        <Box
          style={{
            minWidth: '100%'
          }}>
          <Typography style={{ fontWeight: 500, color: neutral8, width: '100%' }}>
            {'Comments'}
          </Typography>
          <Typography style={{ color: neutral7, width: '100%' }} variant="body2">
            {row.comments ? row.comments : 'No Data'}
          </Typography>
        </Box>
      </Box>
    );
  };

  const Columns = [
    {
      accessor: 'expand',
      customCell: ExpandCell
    },
    {
      header: 'Category',
      accessor: 'category',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('category'),
      fixedWidth: false
    },

    {
      header: 'Type',
      accessor: 'libraryType',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryType'),
      fixedWidth: false
    },
    {
      header: 'Name',
      accessor: 'libraryName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryName'),
      fixedWidth: false
    },
    {
      header: 'Description',
      accessor: 'description',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('description'),
      customCell: EllipsisCell,
      fixedWidth: false
    },
    {
      header: 'Version',
      accessor: 'version',
      sortFunction: compareNumbers,
      filterFunction: numberSearchFilter('version'),
      filterComponent: IntegerFilter,
      customCell: versionCell,
      width: 68,
      fixedWidth: false
    },
    {
      header: 'Last Updated User',
      accessor: 'userid',
      sortFunction: compareStrings,
      filterFunction: createStringSearchFilter('userid'),
      filterComponent: TextFieldFilter,
      fixedWidth: false
    },
    {
      header: 'Last Updated Date',
      accessor: 'auditDate',
      sortFunction: compareDates,
      filterFunction: dateFilter('auditDate'),
      filterComponent: DateFilter,
      fixedWidth: false
    },
    {
      header: 'Audit Type',
      accessor: 'auditType',
      sortFunction: compareStrings,
      filterFunction: createStringSearchFilter('auditType'),
      filterComponent: TextFieldFilter,
      fixedWidth: false
    },
    {
      header: 'Status',
      accessor: 'activeFlag',
      sortFunction: compareStrings,
      filterFunction: createStringSearchFilter('activeFlag'),
      filterComponent: TextFieldFilter,
      customCell: ActiveCell,
      fixedWidth: false
    },
    {
      accessor: 'action',
      customCell: ActionCell,
      width: 68
    }
  ];

  useEffect(() => {
    let getGlobalData = [];
    disableComponentsBasedOnRole &&
      disableComponentsBasedOnRole.map((roleObj) => {
        if (roleObj.module === 'GlobalLibrary') {
          roleObj.mappings.map((eachID) => {
            if (eachID.isDisabled) {
              getGlobalData.push(eachID.feature);
            }
          });
        }
      });

    setGlobalDataList(getGlobalData);
  }, [disableComponentsBasedOnRole]);
  const dispatch = useDispatch();
  const globalViewLibrary = async () => {
    let globalData;
    try {
      globalData = await dispatch(
        GetViewGlobalLibraries({ IsActive: isActive, exceptRuleset: false })
      ).then(unwrapResult);
      if (globalData && globalData.data && globalData.data.success) {
        let response = globalData.data.libraries;
        let libraries = [];
        response.map((el, i) => {
          libraries.push({
            libraryType: el.libraryType,
            libraryName: el.libraryName,
            description: el.description,
            version: el.version,
            userid: el.userid,
            insertedDate: el.insertedDate,
            id: i,
            comments: el.comments,
            category: el.category,
            fileName: el.fileName,
            fileBytes: el.fileBytes,
            libraryID: el.libraryID,
            auditType: el.auditType,
            auditDate: dateFormatByType(el.auditDate, 'Table'),
            activeFlag: el.isActive === false ? 'Inactive' : 'Active'
          });
        });
        setGlobalLibraries(libraries);
      } else {
        dispatch(showBanner({ variant: 'error', message: globalData.data.message }));
      }
    } catch (err) {
      dispatch(showBanner({ variant: 'error', message: err.message }));
    }
  };

  useEffect(() => {
    globalViewLibrary();
  }, [isActive]);

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  useEffect(() => {
    if (updatedGlobalLibraries) {
      globalViewLibrary();
      dispatch(getUpdatedGlobalLibraries(false));
    }
  }, [updatedGlobalLibraries === true]);

  const EmptyTableTypographyStyle = { color: neutral7, lineHeight: '24px' };
  const EmptyTableTypographyStyleTitle1 = {
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '22px',
    color: '#595959'
  };
  const EmptyTableComponent = () => (
    <>
      <ChartBar fontSize="large" style={EmptyTableTypographyStyle} />
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        {/* Nothing to See Here */}
        No data to display
      </Typography>
      {/* <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
        Click Add Global Library to begin.
      </Typography> */}
    </>
  );

  const handleToggleChange = (e, checked) => {
    setActive(checked);
    dispatch(GetViewGlobalLibraries({ IsActive: checked, exceptRuleset: false }));
  };

  const CustomButtonHeader = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(
        breadCrumbData([
          { path: DASHBOARD_PATH },
          {
            title: DATA_STANDARD_LIBRARIES,
            path: DATA_STANDARD_LIBRARIES_PATH
          }
        ])
      );
    }, []);

    return (
      <div>
        <Typography
          style={{ fontSize: '13px', margin: '0px 6px 0px 0px' }}
          variant="subtitle"
          gutterBottom
          data-testid="active-flag">
          {'View Active Libraries'}
        </Typography>
        <Switch
          checked={isActive}
          onChange={handleToggleChange}
          size={'small'}
          style={{ flexDirection: 'row-reverse' }}
        />
        <Button
          size="small"
          variant="secondary"
          icon={Filter}
          style={{ marginRight: 15 }}
          onClick={props.toggleFilters}
          data-testid="filter-button">
          Filter
        </Button>
        <Link
          to="/data-standard-library/add-global-library"
          style={{ pointerEvents: hasNoAccess('globalLibrary', 'addGlobalLibrary') && 'none' }}>
          <Button
            size="small"
            variant="primary"
            onClick={() => {
              dispatch(
                breadCrumbData([
                  { path: DASHBOARD_PATH },
                  {
                    title: DATA_STANDARD_LIBRARIES,
                    path: DATA_STANDARD_LIBRARIES_PATH
                  },
                  {
                    title: 'Add Global Library',
                    path: '/data-standard-library/add-global-library'
                  }
                ])
              );
              dispatch(setFilterAddGlobalPage(true));
              dispatch(setEditGlobalLibraryData(null));
            }}
            disabled={hasNoAccess('globalLibrary', 'addGlobalLibrary')}>
            Add Global Library
          </Button>
        </Link>
      </div>
    );
  };

  return (
    <div style={{ padding: '1.5% 2.7%', backgroundColor: '#f8f9fb' }}>
      {loading ? (
        <div style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}>
          <Loader />
        </div>
      ) : (
        <TableWrapper>
          <Table
            data-testid="table-data"
            title={
              <div style={{ display: 'block' }}>
                <div>
                  Global Libraries
                  <Button size="small" onClick={globalViewLibrary}>
                    <Refresh />
                  </Button>
                </div>
              </div>
            }
            subtitle={
              <div>
                <Typography variant="body2" gutterBottom>
                  {`${globalLibraries.length} ${globalLibraryCount}`}
                </Typography>
              </div>
            }
            columns={Columns}
            rows={
              globalLibraries &&
              globalLibraries.map((row) => {
                return {
                  ...row,
                  key: row.id,
                  expanded: expandedRows.includes(row.id) ? true : false,
                  handleToggleRow
                };
              })
            }
            initialSortedColumn="auditDate"
            initialSortOrder="desc"
            rowsPerPageOptions={[10, 20, 50, 100, 'All']}
            tablePaginationProps={{
              truncate: true
            }}
            style={{ padding: '16px 16px 16px 16px' }}
            defaultRowsPerPage={10}
            ExpandableComponent={DetailRow}
            CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
            hasScroll
            maxHeight={650}
            emptyProps={{
              text: <EmptyTableComponent />
            }}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default GlobalLibrary;
