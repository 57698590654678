/*eslint-disable*/
import { unwrapResult } from '@reduxjs/toolkit';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Loader from 'Components/Loader/Loader';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveLoaderQueue } from 'Redux/Service/PublishDataProductService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setReloadData, setRowdata, setView } from 'Redux/Slice/DashboardSlice';
import CustomModal from '../../../Components/Modal/Modal';
import MappingSpecDetails from 'Pages/ProductDesigner/Components/PublishMappingSpec/MappingSpecDetails';
import DataProductSummaryTable from 'Pages/Dashboard/DataProductSummary/Components/DataProductSummaryTable';
import { columnsWithSorting } from 'Pages/Dashboard/DataProductSummary/Components/columns';
import { GetDataProductSummary } from 'Redux/Service/DashboardService';
import Cookies from 'js-cookie';
import { showLoader, closeLoader } from 'Redux/Slice/LoaderSlice';
import { ApolloProgress } from 'apollo-react';
import { dateFormatByType, uuidv4 } from 'Utils';
import StudySpecificSummaryTable from './StudySpecificSummaryTable';
import ChangeScheduler from './ChangeScheduler';
import { setDisplayChangeScheduler } from 'Redux/Slice/PublishMappingSpecSlice';

const StudySpecificDataPipeline = forwardRef(({ rowData, getDataProductSummaryGrid }, ref) => {
  const dispatch = useDispatch();
  const { reloadData, view, cardName, isAllFlag } = useSelector(
    (state) => state.DashboardSliceData
  );
  const userId = Cookies.get('user.id');

  const [columnsData, setColumnsData] = useState(columnsWithSorting);
  const { loading } = useSelector((state) => state.LoaderData);
  const { displayChangeScheduler } = useSelector((state) => state.PublishMappingSpecData);

  const toggleMappingSpecOverlay = () => {
    dispatch(setView(false));
  };

  useEffect(() => {
    setColumnsData(
      columnsData
        .filter((col) => col.accessor !== 'protocolNumber')
        .map((el) => ({
          ...el,
          frozen: false,
          hidden: false
        }))
    );
  }, []);

  const onAcceptData = async () => {
    const payloadData = { type: '', selectedRow: {} };
    const now = new Date().toISOString();

    const payload = {
      loaderQueue: {
        iqCreateDate: now,
        iqUpdateDate: now,
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: 'UPDATE',
        iqAuditDate: now,
        iqActiveFlag: true,
        protocolName: reloadData.selectedRow.protocolNumber,
        productName: reloadData.selectedRow.productMnemonic,
        environment: reloadData.selectedRow.environment,
        mappingRuleVersionID: reloadData.selectedRow.mappingRuleVersionID,
        isReload: reloadData.type === 'Reload' ? true : false,
        loaderStatus: 'queued',
        userName: userId
      }
    };

    const saveLoaderQueueResponse = await dispatch(saveLoaderQueue(payload)).then(unwrapResult);
    if (saveLoaderQueueResponse?.data?.success) {
      dispatch(showBanner({ variant: 'success', message: saveLoaderQueueResponse.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: saveLoaderQueueResponse.data.message }));
    }

    dispatch(setReloadData(payloadData));
  };

  const typeCheck = reloadData.type === 'Reload' ? 'Reload' : 'Refresh';

  return (
    <React.Fragment>
      <>
        {loading && (
          <div
            style={{
              width: 'calc(100% - 3rem)',
              height: '700px',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '9',
              backgroundColor: 'rgba(255,255,255,0.6)'
            }}>
            <ApolloProgress />
          </div>
        )}
        <StudySpecificSummaryTable
          // history={history}
          rows={rowData}
          //   path={path}
          columnsData={columnsData}
          setColumnsData={setColumnsData}
          //   onViewAll={onViewAll}
          // paginationData={paginationData}
          // paginationFunctions={paginationFunctions}
        />
      </>
      <CustomModal
        display={reloadData.type !== ''}
        variant={'warning'}
        title={`Are you sure you want to ${typeCheck} Data?`}
        message={
          'This process can take several minutes/hours to complete. Are you sure you want to continue?'
        }
        buttonPrimaryLabel={`${typeCheck} Data`}
        handlePrimaryAction={onAcceptData}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => dispatch(setReloadData({ type: '', selectedRow: {} }))}
      />
      {view && (
        <MappingSpecDetails
          displayMappingSpecOverlay={view}
          toggleMappingSpecOverlay={toggleMappingSpecOverlay}
        />
      )}

      {displayChangeScheduler?.display && (
        <ChangeScheduler
          displayOverlay={displayChangeScheduler?.display}
          selectedRow={displayChangeScheduler.row}
          closeChangeScheduler={(refresh) => {
            dispatch(setDisplayChangeScheduler({ display: false }));
            refresh && getDataProductSummaryGrid();
          }}
        />
      )}
    </React.Fragment>
  );
});

export default StudySpecificDataPipeline;
