import DataStandardLibraryRoutes from 'Pages/DataStandardLibrary/Routes';
import ProductDesignerRoutes from 'Pages/ProductDesigner/Routes';
import PublishRoutes from 'Pages/Publish/Routes';
import LoginPageRoutes from 'Pages/WelcomePage/Routes';
import DashboardPage from 'Pages/Dashboard/Route';
import ValRoutes from 'Pages/Validator/Routes';
import CloneDataProductRoutes from 'Pages/ProductDesigner/Components/CloneDataProduct';

const routes = [
  ...DataStandardLibraryRoutes,
  ...ProductDesignerRoutes,
  ...PublishRoutes,
  ...LoginPageRoutes,
  ...DashboardPage,
  ...ValRoutes,
  ...CloneDataProductRoutes,
];

export default routes;
