import { createSlice } from '@reduxjs/toolkit';
import { getDataProductGrid } from 'Redux/Service/DataProductGridService';
import { GetAssignedStudyLibraries } from 'Redux/Service/DataProductsService';
// import { GetDomainVariables } from '../Service/DataProductGridService';
export const DataProductGridSlice = createSlice({
  name: 'domainData',
  initialState: {
    domainAndVarArr: [],
    domainValue: 'AE',
    domainRowCount: 0,
    dataProducts : [],
  
  },
  reducers: {
    updateDomainData: (state, action) => {
      state.domainValue = action.payload;
    },
   
  },
  extraReducers: (builder) => {
    // builder.addCase(GetDomainVariables.fulfilled, (state, action) => {
    //   state.dataProducts = action.payload;
    // });
    // builder.addCase(GetDomainVariables.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(GetDomainVariables.rejected, (state, action) => {
    //   state.error = action.error.message;
    // });

    builder.addCase(getDataProductGrid.fulfilled, (state, action) => {
      state.dataProducts = action.payload.data;
    });
    builder.addCase(getDataProductGrid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDataProductGrid.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(GetAssignedStudyLibraries.fulfilled, (state, { payload }) => {
      state.dataProducts = { ...payload.data };
    });
    builder.addCase(GetAssignedStudyLibraries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAssignedStudyLibraries.rejected, (state, action) => {
      state.error = action.error.message;
    });

    // builder.addCase(PostGlobalLibraryPage.fulfilled, (state, action) => {
    //     state.success = action.payload.data.success
    // });
    // builder.addCase(PostGlobalLibraryPage.pending, (state, action) => {
    //     state.loading = true
    // });
    // builder.addCase(PostGlobalLibraryPage.rejected, (state, action) => {
    //     state.error = action.error.message
    // });
  }
});

export const { updateDomainData } = DataProductGridSlice.actions;
