/* eslint-disable */
import { ErrorConstants } from './ErrorConstants';
let uniqueObjects = {};
const {
  mandatoryError,
  trialIEUniqueError,
  trialIEColumnError,
  trialIELengthError,
  IETESTCDValidationError,
  validIetestcdError
} = ErrorConstants;

const checkMandatory = (record) => {
  const { ietestcd, ietest, iecat } = record;
  let obj = {};
  if (!ietestcd) {
    obj.ietestcd = { mandatory: mandatoryError };
  }
  if (!ietest) {
    obj.ietest = { mandatory: mandatoryError };
  }
  if (!iecat) {
    obj.iecat = { mandatory: mandatoryError };
  }

  return obj;
};

const checkUnique = (record) => {
  let value = record.ietestcd?.toString().toLowerCase();
  if (Object.keys(uniqueObjects).length > 0 && uniqueObjects[value]) {
    return {
      ietestcd: { unique: trialIEUniqueError },
    };
  } else {
    uniqueObjects[value] = true;
    return {};
  }
};

const checkLengthOfColumn = (record) => {
  const { ietestcd} = record;
  let obj = {};
  if (ietestcd.length > 8 && !isNaN(ietestcd.charAt(0))) {
    obj.ietestcd = { validColumn: IETESTCDValidationError }
  }

  if (ietestcd.length > 8 && isNaN(ietestcd.charAt(0))) {
    obj.ietestcd = { validColumn: trialIELengthError }
  } 

  if (ietestcd.length <= 8 && !isNaN(ietestcd.charAt(0))) {
    obj.ietestcd = { validColumn: trialIEColumnError }
  }

  return obj;
};

const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

const checkValidIetestcd = (record) => {
  const {ietestcd} = record;
  let obj = {};
  var regex = /^\w+$/;

  if (!regex.test(ietestcd)) {
    obj.ietestcd = { isError: validIetestcdError }
  }
  return obj
}

export const trialIEValidator = (records) => {
  let errorColumnCount = 0;
  let errorRowsId = [];

  let error = {};
  records.forEach((record, index) => {
    let obj = {};
    let mandatory = {};
    let unique = {};
    let validColumn = {};
    let isError = {};
    unique = checkUnique(record);
    mandatory = checkMandatory(record);
    validColumn = checkLengthOfColumn(record);
    isError = checkValidIetestcd(record);

    mergeObject(obj, mandatory);
    mergeObject(obj, unique);
    mergeObject(obj, validColumn);
    mergeObject(obj, isError);

    if (obj && Object.keys(obj).length > 0) {
      errorRowsId.push(record.id);
      errorColumnCount = errorColumnCount + Object.keys(obj).length;
    }

    error[record.id] = obj;
  });

  uniqueObjects = {};

  const isColumnValid = (uuid, columnName) => {
    let columnError = '';
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      columnError = columnName && row[columnName] ? row[columnName] : '';
    }
    return columnError;
  };

  const isRowValid = (uuid) => {
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row[uuid]).length > 0) {
      return false;
    }
    return true;
  };

  const getErrorRowId = () => {
    return errorRowsId;
  };

  const getColumnErrorCount = () => {
    return errorColumnCount;
  };

  return {
    isColumnValid: isColumnValid,
    isRowValid: isRowValid,
    getErrorRowId: getErrorRowId,
    getErrorCount: getColumnErrorCount
  };
};

export default { trialIEValidator };
