/*eslint-disable */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import Editor from '@monaco-editor/react';
import Box from 'apollo-react/components/Box';
import Typography from 'apollo-react/components/Typography';
import { parse, toSql, astMapper, parseFirst } from 'pgsql-ast-parser';
import { useRef } from 'react';
import { formatErrorMessage, validatePgSqlQuery } from './pgsqlValidator';
import { neutral1, neutral2, neutral3, neutral4, red } from 'apollo-react/colors';

export const transformSqlQueryToTemplate = (sqlQuery) => {
  const ast = parse(sqlQuery);

  const withAliases = [];
  const withMapper = astMapper((map) => ({
    with: (exp) => {
      exp?.bind?.[0]?.alias?.name && withAliases.push(exp?.bind?.[0]?.alias?.name);
      return exp;
    }
  }));

  const mapper = astMapper((map) => ({
    tableRef: (t) => {
      if (withAliases?.includes(t.name)) return t;
      return {
        ...t,
        name: `{${t.name}}`
      };
    }
  }));

  // parse + map + reconvert to sql
  withMapper.statement(ast[0]);
  const modified = mapper.statement(ast[0]);
  return toSql
    .statement(modified)
    .replaceAll(' .', '.')
    .replaceAll('  ', ' ')
    .replaceAll('"{', '{')
    .replaceAll('}"', '}');
};

const PgSQLEditor = forwardRef((props, ref) => {
  const {
    monacoEditorProps = {},
    readOnly,
    sqlQuery: savedSqlQuery,
    onQueryChange,
    domainCode
  } = props;

  const [errorText, setErrorText] = useState('');
  const [sqlQuery, setSqlQuery] = useState(savedSqlQuery);

  const handleQueryChange = (value) => {
    setSqlQuery(value);
    onQueryChange(value);
  };

  const validateQuery = (domainVairableMapping, isExistingDomain) => {
    const result = { isValid: false, error: '', sqlQuery: '', queryType: '' };
    try {
      setErrorText('');
      const ast = validatePgSqlQuery(sqlQuery, domainVairableMapping, isExistingDomain, domainCode);

      result.isValid = true;
      result.sqlQuery = sqlQuery;
      result.queryType = ast.type;
    } catch (e) {
      console.log(':e', e);
      result.error = formatErrorMessage(e);
      setErrorText(result.error);
    }
    return result;
  };

  const resetErrorText = () => {
    setErrorText('');
  };

  useImperativeHandle(ref, () => ({
    validateQuery,
    resetErrorText
  }));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          // pointerEvents: readOnly ? 'none' : undefined,
          '& section ': { pt: '5px', pb: '10px' },
          '& section, section >*>*': {
            backgroundColor: readOnly ? neutral1 : undefined
          },
          resize: 'vertical',
          height: '100px',
          overflow: 'auto'
        }}>
        <Editor
          value={savedSqlQuery}
          defaultLanguage="pgsql"
          onChange={handleQueryChange}
          options={{ minimap: { enabled: false }, readOnly }}
          {...monacoEditorProps}
        />
      </Box>
      {errorText && (
        <Box
          component={'div'}
          sx={{
            whiteSpace: 'pre-line',
            fontFamily: 'Menlo, Courier New, Monospace',
            px: '22px',
            py: '10px',
            backgroundColor: neutral3,
            color: red
          }}
          dangerouslySetInnerHTML={{ __html: errorText }}
          data-testid='error-textbox'
        />
      )}
    </>
  );
});

export default PgSQLEditor;
