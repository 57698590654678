/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const EditStudyLibrarySlice = createSlice({
  name: 'EditStudyLibraryData',

  initialState: {
    studyLibraryRowData: ''
  },
  reducers: {
    setEditStudyLibraryData(state, { payload }) {
      state.studyLibraryRowData = payload;
    }
  }
});

export const { setEditStudyLibraryData } = EditStudyLibrarySlice.actions;
