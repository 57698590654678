/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ProjectHeader from 'apollo-react/components/ProjectHeader';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getStudyLibraryGrid } from 'Redux/Service/StudyLibraryGridService';
import { unwrapResult } from '@reduxjs/toolkit';
import { getProtocol } from 'Redux/Slice/StudyLibrarySlice';
import useDisplayName from 'Utils/useDisplayName';

const useStyles = makeStyles({
  test: {
    zIndex: 1000,
    height: '60px'
  }
});

const ProjectStudyHeader = (props) => {
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();

  const [studyLibraryTypeValues, setStudyLibraryTypeValues] = useState([]);
  let publishPaths = [
    '/product-designer/manage-data-pipeline-version',
    '/manage-data-pipeline',
    '/manage-data-pipeline/manage-data-pipeline-version'
  ];

  useEffect(() => {
    (async () => {
      let arrayList = [];
      if (!protocol.hasOwnProperty('sources')) {
        let studyData = await dispatch(getStudyLibraryGrid(protocol.protocolNumber)).then(
          unwrapResult
        );
        if (studyData && studyData.data && studyData.data.success) {
          let response = studyData.data.studyLibraries;
          if (response.length > 0) {
            response.map((el, i) => {
              if (el.isactive && el.libraryType !== 'Ruleset') {
                arrayList.push(el.libraryType);
              }

              setStudyLibraryTypeValues([...new Set(arrayList)]);
            });
            if (arrayList.length === 0) {
              setStudyLibraryTypeValues(['No Data To Show']);
            }
          } else {
            setStudyLibraryTypeValues(['No Data To Show']);
          }
        }
        dispatch(getProtocol({ ...protocol, sources: [...new Set(arrayList)] }));
      }
    })();
  }, [protocol]);

  const menuItems = [
    { label: 'Protocol Number', value: protocol.protocolNumber, maxWidth: 'fit-content' },
    { label: 'Project Code', value: protocol.projectCode },
    { label: 'Phase', value: protocol.phase },
    { label: 'Therapeutic Area', value: protocol.therapeuticArea },
    { label: 'Sponsor', value: protocol.sponsor },
    { label: 'Study Status', value: protocol.studyStatus },
    {
      label: 'Data Sources',
      value: (protocol?.sources ? protocol?.sources : studyLibraryTypeValues).map((_source) =>
        getDisplayName(_source)
      )
    }
  ];
  if (publishPaths.includes(window.location.pathname)) {
    menuItems.pop();
  }

  return (
    <>
      <ProjectHeader menuItems={menuItems} className={classes.test} />
    </>
  );
};

export default ProjectStudyHeader;
