/*eslint-disable */
import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import StandardScheduler from 'Images/scheduler-standard.svg';
import SmartScheduler from 'Images/scheduler-smart.svg';
import EventDrivenScheduler from 'Images/scheduler-event-driven.svg';

const useStyles = (horizontal) =>
  makeStyles({
    schdlrCardContent: {
      display: 'flex',
      flexDirection: horizontal ? 'row' : 'column',
      height: '100%',
      alignItems: 'center',
      justifyContent: horizontal ? 'flex-start' : 'center'
    },
    schdlrCardDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: horizontal ? 'flex-start' : 'center',
      justifyContent: 'center',
      marginLeft: horizontal ? '1rem' : '0'
    },
    schdlrType: {
      fontFamily: 'Proxima Nova',
      fontSize: horizontal ? '1rem' : '1.2rem',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: horizontal ? '1.5rem' : '3rem'
    },
    description: {
      fontSize: horizontal ? '1rem' : '0.8rem',
      textAlign : horizontal ? 'flex-start' : 'center',
      lineBreak: 'auto'
    }
  });

const Scheduler = (props) => {
  const {
    schedulerType,
    schedulerTypeLabel,
    schedulerTypeDescription,
    mappingSpecName,
    selected,
    horizontal
  } = props;
  const classes = useStyles(horizontal)();

  const [icon, name, description] = useMemo(() => {
    switch (schedulerType) {
      case 'Standard': {
        return [StandardScheduler, `${schedulerTypeLabel} Scheduler`, schedulerTypeDescription];
      }
      case 'Smart': {
        return [SmartScheduler, `${schedulerTypeLabel} Scheduler`, schedulerTypeDescription];
      }
      case 'External': {
        return [EventDrivenScheduler, schedulerTypeLabel, schedulerTypeDescription];
      }
      default: {
        return [StandardScheduler, schedulerTypeLabel, schedulerTypeDescription];
      }
    }
  }, [schedulerType]);

  return (
    <Box
      className={classes.schdlrCardContent}
      style={selected ? { color: '#0768FD' } : { color: '#595959' }}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img
          alt={'scheduler-icon'}
          src={icon}
          style={{
            height: horizontal ? '2rem' : '3rem',
            filter: selected
              ? 'invert(0.5) sepia(1) saturate(91) hue-rotate(217deg)'
              : 'invert(0.349) hue-rotate(0deg)'
            // blue -- 'invert(0.5) sepia(1) saturate(91) hue-rotate(217deg)'
            // gray -- 'invert(0.349) hue-rotate(0deg)'
          }}
        />
      </Box>
      <Box className={classes.schdlrCardDetails}>
        <span className={classes.schdlrType}>{name}</span>
        <Box className={classes.description}>{description}</Box>
      </Box>
    </Box>
  );
};

export default Scheduler;
