import {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import { TextFieldFilter, IntegerFilter } from 'Pages/Dashboard/Components/Filters';
import { DateFilter } from 'Components/Common/DateFilter';
import { compareDates, dateFilter } from 'Utils';

const CommonColumns = [
  {
    header: 'Name',
    accessor: 'libraryName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('libraryName'),
    width: '20%'
  },
  {
    header: 'Version',
    accessor: 'version',
    sortFunction: compareNumbers,
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('version'),
    align: 'center',
    width: '10%'
  },
  {
    header: 'Last Updated',
    accessor: 'lastUpdated',
    sortFunction: compareDates,
    filterComponent: DateFilter,
    filterFunction: dateFilter('lastUpdated')
  },
  {
    header: 'User',
    accessor: 'userid',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('userid')
  }
];

/**
 * Columns config for the Global Rule Set Selector
 */
const GlobalColumns = [...CommonColumns];
GlobalColumns.splice(2, 0, {
  header: 'Category',
  accessor: 'category',
  sortFunction: compareStrings,
  filterComponent: TextFieldFilter,
  filterFunction: createStringSearchFilter('category')
});

/**
 * Columns config for the Study Rule Set Selector
 */
const StudyColumns = [...CommonColumns];
StudyColumns.splice(1, 0, {
  header: 'Protocol Number',
  accessor: 'protocolName',
  sortFunction: compareStrings,
  filterComponent: TextFieldFilter,
  filterFunction: createStringSearchFilter('protocolName')
});

export { GlobalColumns, StudyColumns };
