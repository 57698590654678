import React, { useMemo } from 'react';
import Header from 'Components/Header';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import { neutral1 } from 'apollo-react/colors';
import Footer from 'Components/Footer';
import { makeStyles } from '@mui/styles';
import { BannerExample } from 'Components/Banner/Banner';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import Typography from 'apollo-react/components/Typography';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginTop: '113px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: neutral1,
    boxSizing: 'border-box',
    justifyContent: 'flex-start'
  }
});

const PreviewPage = (props) => {
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);

  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const projectHeaderDisplayed = useMemo(() => {
    return Boolean(protocol && Object.keys(protocol).length !== 0);
  }, [protocol]);

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Header />
      {projectHeaderDisplayed && <ProjectStudyHeader />}
      <BannerExample />
      <div className={`${classes.container}`}>
        <Breadcrumb addStudy={true} />
        <Box pt={0} pl={3} pr={3}>
          <Typography variant="h3">{'Reference Data'}</Typography>
          <Box mt={2}>
            <Grid container spacing={0} style={{ marginTop: '1rem' }}>
              <Grid item xs={3}>
                <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                  Product Mnemonic
                </Typography>
                <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                  {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                  Description
                </Typography>
                <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                  {rowDataForRuleEditor && rowDataForRuleEditor.description}
                </Typography>
              </Grid>
            </Grid>
            <Typography style={{ color: 'red', marginTop: '10px' }}></Typography>
          </Box>
          <Box width={'100%'}>
            <Box>{props.children}</Box>
            <Footer width={'100%'} />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export default PreviewPage;
