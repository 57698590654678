/*eslint-disable */
import { Box } from '@mui/material';
import Button from 'apollo-react/components/Button';
import { useState } from 'react';
import TabComponent from './TabComponent';
const ValidateSource = (props) => {
  const { handleBack, handleNext } = props;
  const [disableNextButton, setDisableNextButton] = useState(true);

  const handleDisableNext = (flag) => {
    setDisableNextButton(flag);
  };

  return (
    <>
      <Box pt={0}>
        <Box
          gutterBottom
          style={{
            color: '#000000',
            fontFamily: 'Proxima Nova',
            fontSize: '20px',
            fontWeight: 600,
            letterSpacing: 0,
            lineHeight: '48px',
            height: '28px'
          }}>
          Validate Rules
        </Box>
        <Box mt={2}>
          <TabComponent disableMappingButton={handleDisableNext} />
        </Box>
      </Box>
      <Box container display={'flex'} justifyContent={'space-between'} mt={'1rem'}>
        <Button
          variant="text"
          size="small"
          onClick={handleBack}
          data-testid="cancel"
          style={{ marginRight: '1rem' }}>
          Previous
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={handleNext}
          data-testid="save_progress"
          disabled={disableNextButton}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default ValidateSource;
