/* eslint-disable */
const EditCaretPositioning = {};
if (window.getSelection && document.createRange) {
  //saves caret position(s)
  EditCaretPositioning.saveSelection = function (containerEl) {
    const range = window.getSelection().getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(containerEl);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    const start = preSelectionRange.toString().length;

    return {
      start: start,
      end: start + range.toString().length
    };
  };
  //restores caret position(s)
  EditCaretPositioning.restoreSelection = function (containerEl, savedSel) {
    let charIndex = 0,
      range = document.createRange();
    range.setStart(containerEl, 0);
    range.collapse(true);
    const nodeStack = [containerEl];
    let foundStart = false;
    let stop = false;
    let node;

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length;
        if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
          range.setStart(node, savedSel.start - charIndex);
          foundStart = true;
        }
        if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
          range.setEnd(node, savedSel.end - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  };
} else if (document.selection && document.body.createTextRange) {
  //saves caret position(s)
  EditCaretPositioning.saveSelection = function (containerEl) {
    const selectedTextRange = document.selection.createRange();
    const preSelectionTextRange = document.body.createTextRange();
    preSelectionTextRange.moveToElementText(containerEl);
    preSelectionTextRange.setEndPoint('EndToStart', selectedTextRange);
    const start = preSelectionTextRange.text.length;

    return {
      start: start,
      end: start + selectedTextRange.text.length
    };
  };
  //restores caret position(s)
  EditCaretPositioning.restoreSelection = function (containerEl, savedSel) {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(containerEl);
    textRange.collapse(true);
    textRange.moveEnd('character', savedSel.end);
    textRange.moveStart('character', savedSel.start);
    textRange.select();
  };
}

export default EditCaretPositioning;
