import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import Question from 'apollo-react-icons/Question';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Failure from 'Images/status-failure.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentHolder from '../../ReviewAndPublish/components/ContentHolder';

const PublishFailure = (props) => {
  const { errorMessage, publishInfo } = props;
  const [showContent, setShowContent] = useState(true);
  const navigate = useNavigate();

  const {
    sourceConn,
    exportLocation,
    productMnemonic,
    mappingSpecVersionName,
    targetDataModelName,
    environment,
    trialLists
  } = publishInfo;

  const returnToDataProduct = () => {
    props &&
      (props.pathName === '/manage-data-pipeline/manage-data-pipeline-version'
        ? navigate('/manage-data-pipeline')
        : navigate('/product-designer'));
  };
  const PROXIMA_NOVA_FONT = 'Proxima Nova';

  return (
    <Grid
      container
      spacing={2}
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3rem',
        marginBottom: '3rem'
      }}>
      <Grid item xs={8}>
        <Card style={{ width: '100%', padding: '5%' }}>
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <img src={Failure} style={{ width: '63px', height: '63px', marginRight: '5px' }} />
          </div>
          <Typography
            style={{ textAlign: 'center', fontSize: '32px', marginTop: '1%', marginBottom: '1%' }}
            variant="title1">
            Publish Failed
          </Typography>
          <Typography
            style={{
              textAlign: 'center',
              color: '#444444',
              fontFamily: PROXIMA_NOVA_FONT,
              fontSize: '16px',
              letterSpacing: 0,
              lineHeight: '24px'
            }}>
            {`Failed to publish Mapping Spec ${mappingSpecVersionName} due to Error`}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '3%',
              marginBottom: '1%'
            }}>
            <Button
              variant="secondary"
              icon={<Question style={{ height: '16px', width: '16px', marginBottom: '2px' }} />}
              style={{ marginRight: 10 }}
              onClick={() =>
                window.open(
                  'https://wiki.quintiles.net/pages/viewpage.action?pageId=48137565',
                  '_blank',
                  'noopener'
                )
              }>
              User Manual
            </Button>
            <Button variant="primary" style={{ marginRight: 10 }} onClick={returnToDataProduct}>
              {props.pathName === '/manage-data-pipeline/manage-data-pipeline-version'
                ? 'Return to Manage Data Pipelines'
                : 'Return to Data Products'}
            </Button>
          </div>
        </Card>
        <Card style={{ width: '100%', padding: '5%' }}>
          <Grid container style={{ justifyContent: 'space-around', marginBottom: '25px' }}>
            <Grid item xs={3}>
              <ContentHolder title={'Product Mnemonic'} value={productMnemonic} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Mapping Spec Version'} value={mappingSpecVersionName} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Target Data Model'} value={targetDataModelName} />
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: 'space-around', marginBottom: '25px' }}>
            <Grid item xs={3}>
              <ContentHolder title={'Environment'} value={environment} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Source Connections'} value={sourceConn.toString()} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Trial Name'} value={Object.values(trialLists).toString()} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Data Export Destination'} value={exportLocation.toString()} />
            </Grid>
          </Grid>
          <div style={{ display: 'flex', margin: '4%', flexDirection: 'column' }}>
            <Grid style={{ display: 'flex' }}>
              {showContent ? (
                <ChevronDown onClick={() => setShowContent(!showContent)} />
              ) : (
                <ChevronRight onClick={() => setShowContent(!showContent)} />
              )}
              <Typography
                variant="title2"
                style={{
                  marginLeft: '5px',
                  marginTop: '2px',
                  color: '#000000',
                  fontFamily: PROXIMA_NOVA_FONT,
                  fontSize: '14px',
                  fontWeight: 600,
                  letterSpacing: 0,
                  lineHeight: '24px'
                }}>
                Hide Details
              </Typography>
            </Grid>
            <Typography
              style={{
                visibility: `${!showContent ? 'hidden' : 'visible'}`,
                marginLeft: '30px',
                marginTop: '20px',
                color: '#595959',
                fontFamily: PROXIMA_NOVA_FONT,
                fontSize: '14px',
                letterSpacing: 0,
                lineHeight: '24px'
              }}>
              {errorMessage}
              {/* <Button variant="text" style={{ padding: 0 }}>
                More Information
              </Button> */}
            </Typography>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PublishFailure;
