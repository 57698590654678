/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import PlusIcon from 'apollo-react-icons/Plus';
import CustomModal from 'Components/Modal';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Trash from 'apollo-react-icons/Trash';
import Button from 'apollo-react/components/Button';
import { jsonToFileData } from 'Utils';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Switch from 'apollo-react/components/Switch';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveTrialIEData } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { jsonToExcel, uuidv4 } from 'Utils';
import { trialIEValidator } from 'Validators/TrialIE.Validator';
import { TableNewRow, useNewRow } from './TableNewRow';
import { unwrapResult } from '@reduxjs/toolkit';
import { getTrialIE } from 'Redux/Service/ReferenceDataCardsService';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import useGetTitle from '../../../hooks/useGetTitle';
import Loader from 'Components/Loader/Loader';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Cookies from 'js-cookie';
const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const TrialIEPreview = () => {
  const { cardTitle } = useGetTitle('trial-IE');
  const navigate = useNavigate();
  const params = useParams();
  const [isPreview, setIsPreview] = useState(false);
  const [trialIEValidationResult, setTrialIEValidationResult] = useState({});
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [trialIEData, setTrialIEData] = useState([]);
  const [mappingId, setMappingId] = useState(params.id);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [editedRows, setEditedRows] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [newRow, setNewRow, editNewRow] = useNewRow({});
  const [isAdding, setIsAdding] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [isLoading, setIsLoading] = useState(true);
  const [errorRowId, setErrorRowId] = useState({});
  const [errorCount, setErrorCount] = useState(0);
  const editMode = editedRows.length > 0;
  const classes = useStyles();
  const [error, setError] = useState();
  const [trialIEMessage, setTrialIEMessage] = useState('');
  const TRIAL_IE_VALIDATION_RESULT = 'trial-IE-validationResult';

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingId
      };
      if (getReferenceData('trial-IE') && getReferenceData('trial-IE').length > 0) {
        setTrialIEData(getReferenceData('trial-IE'));
        const validationResult = getReferenceData(TRIAL_IE_VALIDATION_RESULT);
        setTrialIEValidationResult(validationResult);
        setEnableSave(true);
        setIsLoading(false);
        setIsPreviewDataUploaded(true);
      } else {
        const trialIEGetAPIData = await dispatch(getTrialIE(payload)).then(unwrapResult);
        if (trialIEGetAPIData && trialIEGetAPIData.data && trialIEGetAPIData.data.success) {
          if (
            trialIEGetAPIData.data.trialinclusionExclusions &&
            trialIEGetAPIData.data.trialinclusionExclusions.length > 0
          ) {
            setError('');
            const newData = trialIEGetAPIData.data.trialinclusionExclusions.map((item) => {
              return {
                ietestcd: item.ietestcd,
                ietest: item.ietest,
                iecat: item.iecat,
                iescat: item.iescat,
                ierl: item.ierl,
                ievers: item.ievers,
                id: uuidv4()
              };
            });
            let validationResult = trialIEValidator(newData);
            setTrialIEValidationResult(validationResult);
            setTrialIEData(newData);
            setIsPreviewDataUploaded(false);
            setIsLoading(false);
          } else {
            setTrialIEData([]);
            setIsLoading(false);
          }
        } else {
          setTrialIEMessage(trialIEData?.data?.message);
          setTrialIEData([]);
          setIsLoading(false);
        }
      }
    })();
  }, []);

  //useEffect for updating erros
  useEffect(() => {
    if (trialIEValidationResult && Object.keys(trialIEValidationResult).length > 0) {
      setErrorRowId(trialIEValidationResult && trialIEValidationResult.getErrorRowId());
      setErrorCount(trialIEValidationResult && trialIEValidationResult.getErrorCount());
    }
  }, [trialIEValidationResult]);

  useEffect(() => {
    setRows(trialIEData);
  }, [trialIEData]);

  useEffect(() => {
    if (errorFlag && !editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = trialIEData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else if (!errorFlag && !editMode) {
      setRows(trialIEData);
    } else if (errorFlag && editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = editedRows.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setEditedRows(filteredData);
    } else if (!errorFlag && editMode) {
      if (editedRows.length > 0 && trialIEData.length > 0) {
        const idToIndexMap = {};
        const arr = [...trialIEData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            arr[idToIndexMap[data.id]] = data;
          } else {
            arr.push(data);
          }
        });
        setEditedRows(arr);
      }
    }
  }, [errorFlag, editMode]);

  const getSavePayload = (data, auditType) => {
    const trialinclusionExclusions = data.map((item) => {
      let { ietestcd, ietest, iecat, iescat, ierl, ievers } = item;
      return {
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: auditType,
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        mappingTrialIEID: null,
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingId,
        domainName: '',
        ietestcd: ietestcd,
        ietest: ietest,
        iecat: iecat,
        iescat: iescat,
        ierl: ierl,
        ievers: ievers
      };
    });
    const payload = {
      trialinclusionExclusions
    };
    return payload;
  };

  const onCancel = () => {
    setEditedRows([]);
  };

  const onSave = async () => {
    let payload;
    let validationResult;
    let newData = [...trialIEData];
    setOpenConfirmModal(false);
    if (editMode && !errorFlag) {
      validationResult = trialIEValidator(editedRows);
      setTrialIEValidationResult(validationResult);
      payload = getSavePayload(editedRows, 'UPDATE');
    } else if (editMode && errorFlag) {
      const idToIndexMap = {};
      if (editedRows.length > 0 && trialIEData.length > 0) {
        const arr = [...trialIEData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            newData[idToIndexMap[data.id]] = data;
          } else {
            newData.push(data);
          }
        });
      }
      if (newData.length > 0) {
        validationResult = trialIEValidator(newData);
        setTrialIEValidationResult(validationResult);

        payload = getSavePayload(newData, 'INSERT');
      }
    } else {
      validationResult = trialIEValidator(trialIEData);
      setTrialIEValidationResult(validationResult);
      payload = getSavePayload(trialIEData, 'INSERT');
    }

    if (validationResult?.getErrorCount() > 0) {
      if (editMode) {
        setRows(editedRows);
        setTrialIEData(editedRows);
        if (errorFlag) {
          setTrialIEData(newData);
          setRows(newData);
        }
      } else {
        setRows([...rows]);
      }

      dispatch(showBanner({ variant: 'error', message: 'Data still has some error' }));
    } else {
      const saveResponse = await dispatch(SaveTrialIEData(payload));
      if (
        saveResponse &&
        saveResponse.payload &&
        saveResponse.payload.data &&
        saveResponse.payload.data.success
      ) {
        setReferenceData('trial-IE', []);
        setReferenceData(TRIAL_IE_VALIDATION_RESULT, {});
        if (editMode && !errorFlag) {
          setRows(editedRows);
          setTrialIEData(editedRows);
        } else if (editMode && errorFlag) {
          setRows(newData);
          setTrialIEData(newData);
        } else {
          setRows([...rows]);
          setTrialIEData(rows);
        }
        setIsPreviewDataUploaded(false);
        dispatch(showBanner({ variant: 'success', message: saveResponse.payload.data.message }));
      } else {
        if (editMode) {
          setRows(editedRows);
        } else {
          setRows(trialIEData);
        }

        dispatch(showBanner({ variant: 'error', message: 'something went wrong' }));
      }
      setEditedRows([]);
      setEnableSave(false);
    }
  };

  const getconfirmationBeforeSave = () => {
    setConfirmAlert({
      enabled: true,
      title: 'Do you want to Save the Data?',
      message:
        'Please check the information before submitting as the edits/deletes cannot be retrieved once the data is saved',
      variant: 'warning',
      onConfirm: () => {
        onSave();
        setConfirmAlert({
          enabled: false
        });
      },
      onCancel: () => {
        setOpenConfirmModal(false);
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  useEffect(() => {
    if (openConfirmModal) {
      getconfirmationBeforeSave();
    }
  }, [openConfirmModal]);

  const CustomButtonHeader = useCallback((props) => {
    const {
      onEditAll,
      toggleFilters,
      trialIEData,
      handleStepValue,
      handleShowPreview,
      setIsPreview,
      errorCount,
      setConfirmAlert,
      isPreviewDataUploaded
    } = props;

    const onHandleDownload = () => {
      let newFileArr = [];
      trialIEData.map((obj) => {
        delete obj['id'];
        let upperObj = _.transform(obj, function (result, val, key) {
          result[key.toUpperCase()] = val;
        });
        newFileArr.push(upperObj);
      });
      jsonToExcel(newFileArr, 'TrialIE.xlsx');
    };

    const handleErrorData = (e) => {
      setErrorFlag(e.target.checked);
    };

    const returnToReferenceData = () => {
      if (isPreviewDataUploaded) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Uploaded data would not be saved',
          variant: 'warning',
          onConfirm: () => {
            setReferenceData('trial-IE', []);
            setReferenceData(TRIAL_IE_VALIDATION_RESULT, {});
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else if (errorCount) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Added or Edited data would not be saved',
          variant: 'warning',
          onConfirm: () => {
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else {
        navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
        setIsPreview(false);
      }
    };

    return (
      <>
        <div style={{ width: '100%' }}>
          <div>
            <Button
              icon={<ChevronLeft />}
              size="small"
              onClick={returnToReferenceData}
              style={{
                marginRight: 10,
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Return to reference data upload
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 600,
                      fontSize: '20px',
                      float: 'left',
                      marginRight: '20px'
                    }}>
                    {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
                  </Typography>
                  {errorCount > 0 && (
                    <div
                      style={{
                        backgroundColor: '#E20000',
                        float: 'left',
                        width: '83px',
                        paddingBottom: '2px',
                        paddingLeft: '2px',
                        border: '1px solid white',
                        borderRadius: '4px'
                      }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                        <span
                          style={{
                            color: 'white'
                          }}>
                          <StatusExclamation
                            style={{
                              color: 'white',
                              float: 'left',
                              height: '16px',
                              marginTop: '2px'
                            }}
                          />
                          {`${errorCount} errors`}
                        </span>
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={9} style={{ marginRight: '4px' }}>
                    {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                  </Grid>

                  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="secondary" size="small" onClick={toggleFilters}>
                      <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} />{' '}
                      Filter
                    </Button>
                    <Button size="small" onClick={onHandleDownload} style={{ color: 'black' }}>
                      <Download />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <hr style={{ margin: '22px 0px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
                variant="subtitle"
                gutterBottom>
                {errorFlag ? 'Show only rows with errors' : 'Show All'}
              </Typography>
              <Switch size={'small'} checked={errorFlag} onChange={handleErrorData} />
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={isAdding || editMode}
                size="small"
                variant="secondary"
                icon={PlusIcon}
                onClick={() => setIsAdding((adding) => !adding)}
                style={{ marginRight: 8 }}>
                {'Add row'}
              </Button>
              {!editMode && (
                <Button size="small" variant="primary" onClick={onEditAll}>
                  {'Edit all'}
                </Button>
              )}
              {editMode && (
                <Button size="small" onClick={onCancel}>
                  {'Cancel'}
                </Button>
              )}
              {(enableSave || editMode || isPreviewDataUploaded) && (
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  variant="primary"
                  onClick={() => setOpenConfirmModal(true)}>
                  {'Save'}
                </Button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  });

  const getValidTitle = (data) => {
    return data.mandatory
      ? data.mandatory
      : data.number || data.unique || data.validColumn || data.isError;
  };

  const getErrorData = (getData, row, key) => {
    return (
      <Tooltip title={getValidTitle(getData)}>
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            backgroundColor: '#f9cccc',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key] === '' ? 'No Data' : row[key]}
        </Typography>
      </Tooltip>
    );
  };

  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      const getData =
        trialIEValidationResult &&
        Object.keys(trialIEValidationResult).length &&
        trialIEValidationResult.isColumnValid(row['id'], key);

      return row.editMode ? (
        <TextField
          size="small"
          fullWidth
          value={row[key]}
          onChange={(e) => row.editRow(row.id, key, e.target.value)}
          {...fieldStyles}
          {...(Object.keys(getData).length > 0
            ? {
                helperText: getValidTitle(getData),
                error: true
              }
            : {})}
        />
      ) : Object.keys(getData).length > 0 ? (
        getErrorData(getData, row, key)
      ) : (
        // row[key]
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key]}
        </Typography>
      );
    },
    [trialIEValidationResult]
  );

  const ActionCell = useCallback(({ row: { id, editMode, onDelete } }) => {
    let isDisabled = trialIEData.length < 2;
    return (
      editMode || (
        <Tooltip title={!isDisabled ? 'Delete' : 'Table cannot be empty'} disableFocusListener>
          <Button
            size="small"
            style={isDisabled ? { cursor: 'not-allowed' } : {}}
            onClick={!isDisabled ? () => onDelete(id) : null}>
            <Trash
              style={
                isDisabled
                  ? {
                      color: 'grey'
                    }
                  : {
                      color: 'black'
                    }
              }
            />
          </Button>
        </Tooltip>
      )
    );
  });

  const columns = useMemo(
    () => [
      {
        header: 'IETESTCD',
        accessor: 'ietestcd',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('ietestcd'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'IETEST',
        accessor: 'ietest',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('ietest'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'IECAT',
        accessor: 'iecat',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('iecat'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'IESCAT',
        accessor: 'iescat',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('iescat'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'IERL',
        accessor: 'ierl',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('ierl'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'IEVERS',
        accessor: 'ievers',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('ievers'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },

      {
        accessor: 'action',
        customCell: ActionCell,
        align: 'right'
      }
    ],
    [EditableCell, ActionCell]
  );
  const onEditAll = () => {
    setEditedRows(rows);
  };

  const onDelete = (id) => {
    const updatedRows = trialIEData.filter((row) => row.id !== id);
    setTrialIEData(updatedRows);
    const validationResult = trialIEValidator(updatedRows);
    setTrialIEValidationResult(validationResult);
    setEnableSave(true);
  };

  const editRow = (id, key, value) => {
    setEditedRows((trialIEData) =>
      trialIEData.map((row) => (row.id === id ? { ...row, [key]: value } : row))
    );
  };

  const trialIEColumn = [
    {
      IETESTCD: '',
      IETEST: '',
      IECAT: '',
      IESCAT: '',
      IERL: '',
      IEVERS: ''
    }
  ];

  const onAddNewRowSave = () => {
    const trialIEApiColumns = Object.keys(trialIEColumn[0]).map((columnName) =>
      columnName.toLowerCase()
    );
    const copyTrialIEData = [...trialIEData];
    if (newRow && Object.keys(newRow).length > 0) {
      const obj = {
        id: uuidv4(),
        ...trialIEApiColumns.reduce((acc, columnName) => {
          acc[columnName] = newRow[columnName];
          return acc;
        }, {})
      };
      copyTrialIEData.unshift(obj);
      const validationResult = trialIEValidator(copyTrialIEData);
      setTrialIEValidationResult(validationResult);
      setTrialIEData(copyTrialIEData);
      setRows(copyTrialIEData);
      if (validationResult?.getErrorCount() === 0) {
        setOpenConfirmModal(true);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={(editMode ? editedRows : rows).map((row, i) => {
                return {
                  ...row,
                  onDelete,
                  editRow,
                  editMode,
                  key: row.id,
                  index: i
                };
              })}
              initialSortedColumn="ierl"
              initialSortOrder="asc"
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                //   labelDisplayedRows: ({ from, to, count }) =>
                //     `${count === 1 ? 'Employee' : 'Employees'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={(props) => <CustomButtonHeader {...props} />}
              headerProps={{
                onEditAll,
                onSave,
                onCancel,
                editMode,
                trialIEData,
                // handleStepValue,
                // handleShowPreview,
                setIsPreview,
                errorCount,
                setConfirmAlert,
                isPreviewDataUploaded
              }}
              CustomSubHeader={() => (
                <TableNewRow
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  onSave={onAddNewRowSave}
                  newRow={newRow}
                  editNewRow={editNewRow}
                  setNewRow={setNewRow}
                  columns={columns}
                />
              )}
            />
          </Card>
          <CustomModal
            display={confirmAlert.enabled}
            title={confirmAlert.title}
            message={confirmAlert.message}
            body={confirmAlert.body}
            variant={confirmAlert.variant}
            buttonPrimaryLabel={'Ok'}
            handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
            buttonSecondardyLabel={'Cancel'}
            handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
          />
        </>
      )}
    </>
  );
};

export default TrialIEPreview;
