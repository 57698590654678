/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Divider from 'apollo-react/components/Divider';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Button from 'apollo-react/components/Button';
import Step from 'apollo-react/components/Step';
import StepLabel from 'apollo-react/components/StepLabel';
import Stepper from 'apollo-react/components/Stepper';
import { useSelector, useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveDataProduct } from 'Redux/Service/AddDataProductService';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomModal from '../Modal';
import { setTargetDataModal } from 'Redux/Slice/DataProductLibrarySlice';
import Cookies from 'js-cookie';
import _ from 'lodash';
import TextField from 'apollo-react/components/TextField';
import md5 from 'md5';

export default function DataProductSideBar({
  onSubmitRef,
  isValidate,
  handleStepperback,
  getPayload
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddForm, setIsAddForm] = useState(true);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [reasonForChange, setReasonForChange] = useState(false);
  const [justification, setJustification] = useState('');
  const [isReason, setIsReason] = useState(true);

  const { targetModal, previewDataLoading, editDataProduct, filteredRulesetInfo } = useSelector(
    (state) => {
      return state.DataProductStudyLibrary;
    }
  );
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const selectedLibrary = { ...protocol };
  const dispatch = useDispatch();

  let steps = [isAddForm ? 'Add Data Product' : 'Edit Data Product', 'Preview Target Model'];

  useEffect(() => {
    if (
      !location.pathname
        .substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length)
        .startsWith('add')
    ) {
      setIsAddForm(false);
    }
  });

  const versionIncrement = () => {
    let version = 0;
    return version + 1;
  };

  const reasonForChangeReset = () => {
    if (justification) {
      saveDataProducts();
    } else {
      setIsReason(false);
    }
  };

  const reasonForChangeClose = () => {
    setReasonForChange(false);
  };

  const dataProductPayload = async () => {
    const data = await getPayload();
    const version = versionIncrement();
    const cdrRecords = [];
    data.previewRulesetData.forEach((item) => {
      cdrRecords.push({
        libraryName: item.libraryName,
        libraryID: item.libraryID,
        libraryVersion: item.newVersion || item.libraryVersion || item.currentVersion,
        libraryType: item.libraryType,
        description: item.description,
        clinicalDataType: item.clinicalDataType,
        clinicalDataFlow: item.clinicalDataFlow,
        source: item.source,
        auditDate: item.lastUpdatedDate
      });
    });
    const payload = {
      dataProduct: {
        protocolNumber: selectedLibrary.protocolNumber,
        productMnemonic: data.name,
        productId: !_.isEmpty(editDataProduct) ? editDataProduct.productID : '',
        productVersion: !_.isEmpty(editDataProduct) ? editDataProduct.productVersion + 1 : version,
        description: data.description,
        targetDataModelID: targetModal.libraryID,
        targetDataModel: targetModal.libraryName,
        targetDataModelVersion: targetModal.version,
        isCurrent: true,
        mappingRuleVersion: editDataProduct?.mappingRuleversion || '',
        mappingRuleVersionStatus: editDataProduct?.mappingRuleVersionStatus || '',
        userID: Cookies.get('user.id'),
        auditType: !_.isEmpty(editDataProduct) ? 'UPDATE' : 'INSERT',
        auditDate: new Date().toISOString(),
        reasonForChange: justification,
        targetDataModelType:
          cdrRecords.length > 0
            ? 'Ruleset'
            : !_.isEmpty(editDataProduct)
            ? editDataProduct.targetDataModelType
            : targetModal.libraryType
      },
      cdrStudyLibraries: cdrRecords
    };
    return payload;
  };

  const saveDataProducts = async () => {
    const payload = await dataProductPayload();
    const saveResponse = await dispatch(saveDataProduct(payload)).then(unwrapResult);
    if (saveResponse && saveResponse.data.success) {
      dispatch(setTargetDataModal(''));
      dispatch(
        showBanner({ variant: 'success', message: saveResponse.data.message, propagate: true })
      );
      navigate('/product-designer', { state: { loadData: true } });
      return;
    } else {
      dispatch(showBanner({ variant: 'error', message: saveResponse.data.message }));
      return;
    }
  };

  const computeHash = async () => {
    let isFormValueChanged = false;
    if (targetModal) {
      const data = await dataProductPayload();
      const oldUserData = {
        targetDataModelVersion: editDataProduct.targetDataModelVersion,
        description: editDataProduct.description
      };
      const newUserData = {
        targetDataModelVersion: data.dataProduct.targetDataModelVersion,
        description: data.dataProduct.description
      };
      isFormValueChanged = md5(JSON.stringify(oldUserData)) === md5(JSON.stringify(newUserData));
    }

    return isFormValueChanged;
  };

  const computeCDRHash = async () => {
    let matchingData = false;
    const data = await dataProductPayload();
    const oldUserData = {
      description: editDataProduct.description
    };
    const newUserData = {
      description: data.dataProduct.description
    };
    matchingData = md5(JSON.stringify(oldUserData)) === md5(JSON.stringify(newUserData));
    let rulesetsConfigured = filteredRulesetInfo.every(
      (item) => item.checked === item.configured && !item.updateAvailable
    );

    if (!matchingData) {
      return false;
    } else if (rulesetsConfigured) {
      return true;
    } else {
      return false;
    }
  };

  const handleFormValidation = () => {
    onSubmitRef();
    const isFormValid = isValidate();
    if (isFormValid) {
      setActiveStep(activeStep + 1);
      setSubmitDisabled(false);
    }
  };

  const handleNext = async () => {
    if (location.pathname !== '/product-designer/add-data-product') {
      if (activeStep === steps.length - 1) {
        if (!isAddForm) {
          (editDataProduct.targetDataModelType !== 'Ruleset'
            ? computeHash()
            : computeCDRHash()
          ).then((isFormChanged) => {
            if (!isFormChanged) {
              setReasonForChange(true);
            } else {
              dispatch(showBanner({ variant: 'info', message: 'No data to persist' }));
            }
          });
        }
      } else {
        handleFormValidation();
      }
    } else {
      if (activeStep === steps.length - 1) {
        setSubmitDisabled(true);
        saveDataProducts();
      } else {
        handleFormValidation();
      }
    }
  };

  const handleBack = () => {
    handleStepperback();
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    handleStepperback();
    setActiveStep(0);
    setConfirmAlert(false);
    navigate('/product-designer');
  };

  const handleBackToEdit = () => {
    handleStepperback();
    setActiveStep(activeStep - 2);
  };

  const handleCancel = () => {
    navigate('/product-designer');
  };

  const handleClose = () => {
    setConfirmAlert(false);
  };

  const openModal = async () => {
    setConfirmAlert(true);
  };

  const reasonForChangeValue = (e) => {
    setJustification(e.target.value);
  };

  return (
    <>
      <Box>
        <Box pt={3} pb={3} px={3}>
          <Typography variant="h3" data-testid="workflow">
            Workflow
          </Typography>
        </Box>
        <Divider type="dark" />
        <Box style={{ padding: '30px 20px' }}>
          <div style={{ maxWidth: 400 }}>
            <Stepper box activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label} style={{ padding: '18px 16px' }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Box display="flex" justifyContent="flex-end" flex={0.6}>
                    <Button
                      variant="secondary"
                      onClick={handleBackToEdit}
                      style={{ marginRight: '16px' }}>
                      Back
                    </Button>
                    <Button variant="secondary" onClick={handleReset} data-testid="reset">
                      Reset
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    flex={0.4}
                    variant="text"
                    size="small"
                    label="Back"
                    onClick={openModal}
                    style={{ paddingLeft: 0 }}
                    data-testid="cancel">
                    Cancel
                  </Button>
                  <Box display="flex" justifyContent="flex-end" flex={0.6}>
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={handleBack}
                        style={{ marginRight: '16px' }}
                        data-testid="back">
                        Back
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      size="small"
                      onClick={handleNext}
                      disabled={submitDisabled || previewDataLoading}
                      data-testid="next">
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        </Box>
      </Box>
      <CustomModal
        display={confirmAlert}
        title={'Leave Page?'}
        buttonPrimaryLabel={'Ok'}
        message={
          'Changes could not be saved if you leave this page, Would you still like to leave?'
        }
        data-testid="custom-modal"
        handlePrimaryAction={() => handleReset()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => handleClose()}
      />
      {!isAddForm && (
        <CustomModal
          display={reasonForChange}
          title={'Reason For Change'}
          buttonPrimaryLabel={'Ok'}
          message={
            <>
              <Typography>Please provide a reason for the update(s) made.</Typography>
              <div style={{ maxWidth: 400 }}>
                <TextField
                  id="reasonForChange"
                  label=""
                  name="reasonForChange"
                  value={justification}
                  placeholder="Enter Text"
                  onChange={reasonForChangeValue}
                  fullWidth
                />
              </div>
              {!isReason && <div style={{ color: 'red' }}>Required Field</div>}
            </>
          }
          handlePrimaryAction={() => reasonForChangeReset()}
          buttonSecondardyLabel={'Cancel'}
          handleClose={() => reasonForChangeClose()}
        />
      )}
    </>
  );
}
