/* eslint-disable */
import { ErrorConstants } from './ErrorConstants';
import _ from 'lodash';
const errors = [];
let uniqueObjects = {};
const {
  variableNameUniqueError,
  mandatoryError,
  variableNameError,
  variableLabelError,
  validVariableNameError,
  blankError,
  stringError,
  domainError
} = ErrorConstants;

const checkUnique = (record) => {
  let value = `${record.domain}-${record.variablename}`.toString().toLowerCase();

  if (Object.keys(uniqueObjects).length > 0 && uniqueObjects[value]) {
    return {
      variablename: { unique: variableNameUniqueError }
    };
  } else {
    uniqueObjects[value] = true;
    return {};
  }
};

const checkMandatory = (record) => {
  const { domain, variablename, variablelabel } = record;
  let obj = {};
  if (!domain) {
    obj.domain = { mandatory: mandatoryError };
  }
  if (!variablename) {
    obj.variablename = { mandatory: mandatoryError };
  }
  if (!variablelabel) {
    obj.variablelabel = { mandatory: mandatoryError };
  }
  return obj;
};

const checkValidVariableNameAndVariableLabel = (record) => {
  let obj = {};
  if (!(record.variablename && record.variablename.toString().length <= 8)) {
    obj.variablename = { variablename: variableNameError };
  }
  if (!(record.variablelabel && record.variablelabel.toString().length <= 40)) {
    obj.variablelabel = { variablelabel: variableLabelError };
  }
  return obj;
};

const checkIsValidDomainData = (record, domainData) => {
  let obj = {};

  if (!domainData.includes(record.domain)) {
    obj.domain = { domain: domainError };
  }
  return obj;
};

const checkIsString = (record) => {
  const { domain, variablename, variablelabel } = record;
  let obj = {};
  if (!_.isString(domain)) {
    obj.domain = { stringCheck: stringError };
  }
  if (!_.isString(variablename)) {
    obj.variablename = { stringCheck: stringError };
  }
  if (!_.isString(variablelabel)) {
    obj.variablelabel = { stringCheck: stringError };
  }

  return obj;
};

const checkVistName = (record) => {
  let obj = {};
  var regex = /^\w+$/;

  if (!regex.test(record.variablename)) {
    obj.variablename = { variablename: validVariableNameError };
  }
  return obj;
};

const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

export const supplQualValidator = (records, domainRulesData) => {
  let errorColumnCount = 0;
  let errorRowsIndex = [];
  let error = [];
  records.forEach((record, index) => {
    let obj = {};
    const unique = checkUnique(record);

    let mandatory = {};
    let validVariableNameVariableLabel = {};
    let validVisitName = {};
    let isBlank = {};
    let isString = {};
    let isValidDomainRule = {};
    mandatory = checkMandatory(record);
    validVariableNameVariableLabel = checkValidVariableNameAndVariableLabel(record);
    validVisitName = checkVistName(record);
    isString = checkIsString(record);
    isValidDomainRule = checkIsValidDomainData(record, domainRulesData);

    mergeObject(obj, unique);
    mergeObject(obj, mandatory);
    mergeObject(obj, validVariableNameVariableLabel);
    mergeObject(obj, validVisitName);
    mergeObject(obj, isString);
    mergeObject(obj, isValidDomainRule);

    if (obj && Object.keys(obj).length > 0) {
      errorRowsIndex.push(record.id);
      errorColumnCount = errorColumnCount + Object.keys(obj).length;
    }
    error[record.id] = obj;
  });
  uniqueObjects = {};

  const isColumnValid = (uuid, columnName) => {
    let columnError = '';
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      columnError = columnName && row[columnName] ? row[columnName] : '';
    }
    return columnError;
  };

  const isRowValid = (uuid) => {
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row[uuid]).length > 0) {
      return false;
    }
    return true;
  };

  const getErrorRowIndex = () => {
    return errorRowsIndex;
  };

  const getColumnErrorCount = () => {
    return errorColumnCount;
  };

  return {
    isColumnValid: isColumnValid,
    isRowValid: isRowValid,
    getErrorRowIndex: getErrorRowIndex,
    getErrorCount: getColumnErrorCount
  };
};

export default { supplQualValidator };
