/*eslint-disable*/
import React, { useMemo, useState } from 'react';
import Table from 'apollo-react/components/Table';
import Button from 'apollo-react/components/Button';

import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { makeStyles } from '@mui/styles';
import { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import { DataSetStatus } from 'Pages/Dashboard/Components/Status';
import { compareDates, dateFilter } from 'Utils';
import { TextFieldFilter, IntegerFilter } from 'Pages/Dashboard/Components/Filters';
import { numberSearchFilter } from 'Pages/Dashboard/DataProductSummary/Components/columns';
import { DateFilter } from 'Components/Common/DateFilter';
import { readAsText } from 'Utils';
import { GetRefreshLogDetails } from 'Redux/Service/DataRefreshHistoryService';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import InfoIcon from 'apollo-react-icons/Info';
import Tooltip from 'apollo-react/components/Tooltip';
import Modal from 'apollo-react/components/Modal';
import VLCRulesDataTable from './VLCRulesDataTable';
import { GetVLCStats } from 'Redux/Service/DashboardService';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  },
  container: {
    minWidth: '70%',
    height: '80%',
    '&>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9',
      boxShadow: '0px 0px 15px #0002'
    },
    '&>div:nth-child(2)>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9'
    },
  },
});

const ViewLogButton = ({ row }) => {
  return (
    <div>
      {row?.jobRunID && (
        <Button size="small" data-testid={`${row.jobRunID}-ViewLogButton`} onClick={row.onViewLog}>
          {row.jobRunID}
        </Button>
      )}
    </div>
  );
};

const ViewVLCRulesButton = ({ row }) => {
  return (
    <div>
      {(row?.vlcRulesTotalCount || row?.vlcRulesTotalCount === 0) && (
        <Button
          size="small"
          data-testid={`${row.vlcRulesTotalCount}-VLCRulesButton`}
          onClick={row.handleVLCRulesModal}>
          {row.vlcRulesTotalCount}
        </Button>
      )}
    </div>
  );
};

const columns = [
  {
    header: 'Job Run ID',
    accessor: 'jobRunID',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('jobRunID'),
    fixedWidth: false,
    customCell: ViewLogButton
  },
  {
    header: 'Data Set Name',
    accessor: 'datasetName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetName')
  },
  {
    header: 'Data Set Type',
    accessor: 'datasetType',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetType')
  },
  {
    header: 'VLC Rules',
    accessor: 'vlcRulesTotalCount',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('vlcRulesTotalCount'),
    fixedWidth: false,
    customCell: ViewVLCRulesButton
  },
  {
    header: 'Total Number of records',
    accessor: 'recordsCount',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('recordsCount')
  },
  {
    header: 'Last Successful Processed Date',
    accessor: 'lastUpdatedDate',
    sortFunction: compareDates,
    filterComponent: DateFilter,
    filterFunction: dateFilter('lastUpdatedDate')
  },
  {
    header: (
      <>
        <span>Source Data Retrieval Date</span>
        <Tooltip
          title="Date on which new or updated data was last found in the linked source and retrieved for processing. Each new run will check the source for new/updated data for all datasets when the run is initiated; if no updates were found, the date remains the last time new/updated data was found during a run"
          placement="bottom">
          <InfoIcon fontSize="extra small" sx={{ marginTop: '-2px' }} />
        </Tooltip>
      </>
    ),
    accessor: 'cacheStartDate',
    sortFunction: compareDates,
    filterComponent: DateFilter,
    filterFunction: dateFilter('cacheStartDate')
  },
  {
    header: 'Status',
    accessor: 'datasetStatus',
    customCell: DataSetStatus,
    sortFunction: compareStrings,
    align: 'center',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetStatus')
  }
];

const DataSetsTable = (props) => {
  const classes = useStyles();
  const { rowData, setViewLogConfig } = props;
  const dispatch = useDispatch();
  const [vlcStatsModal, setVlcStatsModal] = useState(false);
  const [vlcStats, setVlcStats] = useState([]);
  const [dataSetsRowVal, setDataSetsRowVal] = useState({});
  const { protocolDetails } = useSelector((state) => state.DataRefreshHistory);

  const onViewLog = async ({ jobRunID }) => {
    const {
      data: { success, message, viewLogData }
    } = await dispatch(GetRefreshLogDetails({ jobRunID })).then(unwrapResult);

    try {
      const fileData = readAsText(viewLogData);
      if (success) setViewLogConfig({ open: true, ...fileData });
      else dispatch(showBanner({ variant: 'error', message }));
    } catch (error) {
      dispatch(showBanner({ variant: 'error', message: error.message }));
    }
  };

  const handleVLCRulesModal = async (row) => {
    setDataSetsRowVal({
      jobRunID: row?.jobRunID,
      datasetName: row?.datasetName,
      datasetType: row?.datasetType,
      vlcRulesTotalCount: row?.vlcRulesTotalCount
    })
    const payload = {
      jobRunID: row?.jobRunID,
      mappingRuleVersionID: protocolDetails.mappingRuleVersionID,
      datasetName: row?.datasetName,
    }
    const response = await dispatch(GetVLCStats(payload)).then(unwrapResult);
    if(response?.data?.success){
      const { dataSetStats } = response.data
      setVlcStats(dataSetStats);
      setVlcStatsModal(true);
    }else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
  };
  const mappedRows = useMemo(
    () =>
      rowData.map((row) => ({
        ...row,
        onViewLog: () => onViewLog(row),
        handleVLCRulesModal: () => handleVLCRulesModal(row)
      })),
    [rowData]
  );
  return (
    <>
      <Table
        title={<div style={{ whiteSpace: 'nowrap' }}>Data Sets</div>}
        classes={classes}
        columns={columns}
        rows={mappedRows}
        rowId="dataSetName"
        initialSortedColumn="lastUpdatedDate"
        initialSortOrder="desc"
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 50, 100, 'All']}
        hasScroll
        maxHeight={650}
        tablePaginationProps={{
          labelDisplayedRows: DisplayedRowsLabel,
          truncate: true
        }}
      />
      <Modal
        open={vlcStatsModal}
        onClose={() => setVlcStatsModal(false)}
        title={'VLC Rules'}
        id="neutral"
        width={classes.modal}
        hideButtons={true}
        className={classes.container}>
        <VLCRulesDataTable vlcStats={vlcStats} dataSetsRowVal={dataSetsRowVal}  />
      </Modal>
    </>
  );
};

export default DataSetsTable;
