/*eslint-disable*/
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Grid from 'apollo-react/components/Grid';
import Card from 'apollo-react/components/Card';
import Button from 'apollo-react/components/Button';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Typography from 'apollo-react/components/Typography';

import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import { makeStyles } from '@mui/styles';
import ProtocolDetails from './Components/ProtocolDetails';
import RefreshHistoryTable from './Components/RefreshHistoryTable';
import ViewLog from '../ViewLog';
import { GetDataProductSummeriesHistory } from 'Redux/Service/DataRefreshHistoryService';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { dateFormatByType } from 'Utils';
import { useNavigate } from 'react-router-dom';
import DataSetsTable from './Components/DataSetsTable';
import { GetDataSetStats } from 'Redux/Service/DashboardService';

const useStyles = makeStyles({
  container: {
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    backgroundColor: 'white'
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '24px',
    marginLeft: '2.5rem'
  },
  backButton: {
    margin: '0.5rem 0 1rem 2rem',
    width: 'fit-content',
    fontWeight: 'bold',
    backgroundColor: '#9991'
  },
  protocolCard: {
    padding: '1rem 2rem'
  },
  table: {
    padding: '0 2rem'
  }
});

const MAP_LOADER_RUN_TYPE = {
  INCR: 'Incremental',
  RELOAD: 'Reload'
};

/**
 * Function to transform the dataProductSummeriesHistories to required format
 * @param {Array} refreshHistoryData
 * @returns {Array} Transformed data
 */
const transformRefreshHistoryData = (refreshHistoryData) =>
  refreshHistoryData.map((refreshJob) => ({
    jobRunID: refreshJob.jobRunID,
    loaderStatusType: refreshJob.loaderStatusType,
    loaderStatusMessageLable: refreshJob.loaderStatusMessageLable,
    subjectProcessed: refreshJob.subjectProcessed,
    startDateTime: dateFormatByType(refreshJob.startDateTime, 'Table'),
    endDateTime: dateFormatByType(refreshJob.endDateTime, 'Table'),
    elapsedTime: refreshJob.elapsedTime,
    isSLABreached: refreshJob.isSLABreached,
    refreshType: MAP_LOADER_RUN_TYPE[refreshJob.loaderRunType]
  }));

/**
 * Component to render Data Refresh History for the selected protocol/mapping spec
 */
const DataRefreshHistory = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { protocolDetails } = useSelector((state) => state.DataRefreshHistory);
  const [viewLog, setViewLogConfig] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  let checkProtocolValue = Object.keys(protocol).length !== 0;
  let isMDSFlow = protocolDetails?.targetDataModelType === 'Ruleset';
  /**
   * Initializing breadcrumbs on page load
   */
  useEffect(() => {
    (async () => {
      dispatch(
        breadCrumbData([
          { path: '/dashboard' },
          {
            title: `${checkProtocolValue ? 'Manage Data Pipelines' : 'Data Product Pipelines'}`,
            path: `${
              checkProtocolValue ? '/manage-data-pipeline' : '/dashboard/data-product-pipelines'
            }`
          },
          {
            title: 'Data Refresh History'
          }
        ])
      );
    })();
  }, [checkProtocolValue]);

  /**
   * Fetching Data Refresh History for the selected mappingpsec version and environment
   */
  useEffect(() => {
    (async () => {
      const payload = {
        protocolNumber: protocolDetails.protocolNumber,
        mappingRuleVersionID: protocolDetails.mappingRuleVersionID,
        environment: protocolDetails.environment
      };
      const {
        data: { success, message, dataProductSummeriesHistories }
      } = await dispatch(GetDataProductSummeriesHistory(payload)).then(unwrapResult);

      if (success) {
        setHistoryData(transformRefreshHistoryData(dataProductSummeriesHistories));
      } else {
        dispatch(showBanner({ variant: 'error', message }));
      }
    })();
  }, []);

  useEffect(() => {
    if (isMDSFlow) {
      (async () => {
        const payload = {
          mappingRuleVersionID: protocolDetails.mappingRuleVersionID,
          environment: protocolDetails.environment
        };
        let dataSetValue = [];
        const {
          data: { success, message, dataSetStats }
        } = await dispatch(GetDataSetStats(payload)).then(unwrapResult);
        if (success) {
          dataSetStats.map((el) => {
            dataSetValue.push({
              datasetName: el.datasetName,
              datasetType: el.datasetType || 'N/A',
              recordsCount: el.recordsCount,
              lastUpdatedDate: dateFormatByType(el.lastUpdatedDate, 'Table'),
              datasetStatus: el.datasetStatus,
              jobRunID: el.jobRunID,
              cacheStartDate: dateFormatByType(el.cacheStartDate, 'Table'),
              vlcRulesTotalCount: el.vlcRulesTotalCount
            });
          });
          setRowData(dataSetValue);
        } else {
          dispatch(showBanner({ variant: 'error', message }));
        }
      })();
    }
  }, []);

  const returnToMDP = () => {
    if (checkProtocolValue) {
      navigate('/manage-data-pipeline');
    } else {
      navigate('/dashboard/data-product-pipelines');
    }
  };

  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  return (
    <>
      <Grid container className={classes.container} spacing={0}>
        <Grid item xs={12} className={classes.header}>
          <Breadcrumb
            style={{
              padding: checkProtocolValue ? '5rem 0rem 1rem 2rem' : '1rem 2rem'
            }}
          />
          <Typography data-testid="publish-title" className={classes.pageTitle}>
            Data Refresh History
          </Typography>
          <Button
            icon={<ChevronLeft />}
            className={classes.backButton}
            variant={'text'}
            size={'small'}
            data-testid={'return-to-manage-data-pipelines'}
            onClick={() => returnToMDP()}>
            {checkProtocolValue
              ? 'Return to Manage Data Pipelines'
              : 'Return to data product pipelines'}
          </Button>
        </Grid>
        {isMDSFlow && (
          <Grid item xs={12} style={{ padding: '0 2rem' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} truncate>
              <Tab label="Data Sets Status" className="sm-tab" />

              <Tab label="Data Refresh Log" className="sm-tab" />
            </Tabs>
          </Grid>
        )}
        <Grid item xs={12} className={classes.protocolCard}>
          <Card style={{ padding: 0 }}>
            <ProtocolDetails protocolDetails={protocolDetails} tabValue={tabValue} />
          </Card>
        </Grid>

        <Grid item xs={12} className={classes.table}>
          {tabValue === 0 && isMDSFlow ? (
            <DataSetsTable rowData={rowData} setViewLogConfig={setViewLogConfig} />
          ) : (
            <RefreshHistoryTable
              setViewLogConfig={setViewLogConfig}
              historyData={historyData}
              mappingSpecVersion={protocolDetails.mappingSpecVersion}
            />
          )}
        </Grid>
      </Grid>
      <ViewLog
        {...viewLog}
        setViewLogConfig={setViewLogConfig}
        fileName={`${protocolDetails.protocolNumber}_${protocolDetails.productMnemonic}_${protocolDetails.mappingSpecVersion}_${protocolDetails.environment}.log`}
      />
    </>
  );
};

export default DataRefreshHistory;
