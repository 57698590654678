/*eslint-disable */
import React, { useMemo } from 'react';
import Typography from 'apollo-react/components/Typography';
import Table, { createStringSearchFilter } from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { compareStrings, iconDisplay } from './utilites';
import StatusCheck from 'apollo-react-icons/StatusCheck';

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const iconCell = ({ row }) => {
  let copyOfIconDisplay = JSON.parse(JSON.stringify(iconDisplay));
  return (
    <div style={{ display: 'flex' }}>
      <StatusCheck style={Object.assign(copyOfIconDisplay, { color: '#00C221' })} />{' '}
      <Typography style={{ color: '#595959', fontSize: '14px' }}>{row.queryName}</Typography>
    </div>
  );
};

const EmptyTableComponent = () => {
  return (
    <>
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        No Data found
      </Typography>
    </>
  );
};

const columns = [
  {
    header: 'Query Name',
    accessor: 'queryName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('queryName'),
    customCell: iconCell
  },
  {
    header: 'Domain Name',
    accessor: 'domainCode',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('domainCode')
  },
];

const PostSQL = (props) => {
  const { postSQLRowData, cloneFlag } = props;
  
  const columnsConfig = useMemo(() => {
    let newColumn = [...columns];
    if (cloneFlag) {
      newColumn.push({
        header: 'Warnings',
        accessor: 'message',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('message')
      });
    }
    return newColumn;
  }, []);

  return (
    <>
      <Table
        title={'Mapping Spec'}
        subtitle={'Additional Domain Rules'}
        columns={columnsConfig}
        rows={postSQLRowData}
        rowsPerPageOptions={[10, 50, 100, 'All']}
        hasScroll
        maxHeight={650}
        rowProps={{ hover: false }}
        initialSortOrder="asc"
        tablePaginationProps={{
          labelDisplayedRows: DisplayedRowsLabel,
          truncate: true
        }}
        emptyProps={{
          text: <EmptyTableComponent />
        }}
      />
    </>
  );
};

export default PostSQL;
