import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import Switch from 'apollo-react/components/Switch';
import Table, { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import Loader from 'Components/Loader/Loader';
import CustomModal from 'Components/Modal';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GetFormRepeatFlag,
  GetFormSheetsData,
  SaveFormSheetsData
} from 'Redux/Service/ReferenceDataCardsService';
import { GetSDTMDomainPrefix } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import { jsonToExcel, uuidv4 } from 'Utils';
import { formSheetValidator } from 'Validators/FormSheet.Validator';
import useGetTitle from '../../../hooks/useGetTitle';
import { formsheetColumnNames, getTrimmedValue } from '../FormsheetUtils';
import Box from 'apollo-react/components/Box';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  },
  editTextField: {
    '&> .MuiFormHelperText-root.Mui-disabled': { color: '#e20000' }
  }
});

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};
const FORMSHEET = 'forms-sheet';
const FORMSHEET_VALIDATION_RESULT = 'forms-sheet-validationResult';

const CustomButtonHeader = ({
  formSheetData,
  errorCount,
  formSheetValidationResult,
  isPreviewDataUploaded,
  enableSave,
  editMode,
  errorFlag,
  setErrorFlag,
  setConfirmAlert,
  getConfirmationBeforeSave,
  onCancel,
  onEditAll,
  toggleFilters
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const mappingId = params.id;
  const { cardTitle } = useGetTitle('forms-sheet');

  const onHandleDownload = () => {
    let newFileArr = [];
    formSheetData.map((obj) => {
      let newObj = { ...obj };
      delete newObj['id'];
      let upperObj = Object.keys(newObj).reduce((acc, key) => {
        acc[formsheetColumnNames[key]] = newObj[key];
        return acc;
      }, {});
      newFileArr.push(upperObj);
    });
    jsonToExcel(newFileArr, 'FormsSheet.xlsx');
  };

  const handleErrorData = (e) => {
    setErrorFlag(e.target.checked);
  };

  const returnToReferenceData = () => {
    if (isPreviewDataUploaded) {
      setConfirmAlert({
        enabled: true,
        title: 'Are you sure, you want to return to reference data?',
        message: 'Uploaded data would not saved',
        variant: 'warning',
        onConfirm: () => {
          setReferenceData(FORMSHEET, []);
          setReferenceData(FORMSHEET_VALIDATION_RESULT, {});
          navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
          setConfirmAlert({
            enabled: false
          });
        },
        onCancel: () => {
          setConfirmAlert({
            enabled: false
          });
        }
      });
    } else if (errorCount) {
      setConfirmAlert({
        enabled: true,
        title: 'Are you sure, you want to return to reference data?',
        message: 'Edited data would not be saved',
        variant: 'warning',
        onConfirm: () => {
          navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
          setConfirmAlert({
            enabled: false
          });
        },
        onCancel: () => {
          setConfirmAlert({
            enabled: false
          });
        }
      });
    } else {
      navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Button
          icon={<ChevronLeft />}
          size="small"
          onClick={returnToReferenceData}
          style={{
            marginRight: 10,
            marginBottom: '10px',
            marginTop: '10px'
          }}>
          Return to reference data upload
        </Button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  fontSize: '20px',
                  float: 'left',
                  marginRight: '20px'
                }}>
                {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
              </Typography>
              {errorCount > 0 && (
                <div
                  style={{
                    backgroundColor: '#E20000',
                    float: 'left',
                    width: '83px',
                    paddingBottom: '2px',
                    paddingLeft: '2px',
                    border: '1px solid white',
                    borderRadius: '4px'
                  }}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                    <span
                      style={{
                        color: 'white'
                      }}>
                      <StatusExclamation
                        style={{
                          color: 'white',
                          float: 'left',
                          height: '16px',
                          marginTop: '2px'
                        }}
                      />
                      {`${errorCount} errors`}
                    </span>
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={9} style={{ marginRight: '4px' }}>
                {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
              </Grid>

              <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="secondary" size="small" onClick={toggleFilters}>
                  <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} /> Filter
                </Button>
                <Button
                  size="small"
                  onClick={onHandleDownload}
                  style={{ color: 'black' }}
                  data-testid="download-btn">
                  <Download />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {formSheetValidationResult.isFormMissMatch() && (
        <div style={{ paddingTop: '10px' }}>
          <Box
            sx={{
              borderLeft: '4px solid #E20000',
              backgroundColor: '#FCEBEB',
              borderRadius: '4px',
              width: '100%',
              padding: '1rem',
              mt: 2,
              whiteSpace: 'pre-line'
            }}>
            {formSheetValidationResult.getFormMissMatchError()}
          </Box>
        </div>
      )}
      <hr style={{ margin: '22px 0px' }} />
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
            variant="subtitle"
            gutterBottom>
            {errorFlag ? 'Show only rows with errors' : 'Show All'}
          </Typography>
          <Switch
            size={'small'}
            checked={errorFlag}
            onChange={handleErrorData}
            data-testid="switch-btn"
          />
        </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {!editMode && (
            <Button
              size="small"
              variant="primary"
              disabled={formSheetValidationResult.isFormMissMatch()}
              onClick={onEditAll}>
              {'Edit all'}
            </Button>
          )}
          {editMode && (
            <Button size="small" onClick={onCancel}>
              {'Cancel'}
            </Button>
          )}
          {(enableSave || editMode || isPreviewDataUploaded) && (
            <Button
              size="small"
              style={{ marginLeft: 8 }}
              variant="primary"
              disabled={formSheetValidationResult.isFormMissMatch()}
              onClick={getConfirmationBeforeSave}>
              {'Save'}
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

const getValidTitle = (data) => {
  return data.mandatory
    ? data.mandatory
    : data.inValidFormName || data.inValidFormRepeat || data.domain || data.charLength || data.flag;
};

const getErrorData = (errorData, row, key) => {
  return (
    <Tooltip title={getValidTitle(errorData)}>
      <Typography
        variant="title2"
        style={{
          fontSize: '14px',
          backgroundColor: '#f9cccc',
          color: '#595959',
          padding: '15px 11px'
        }}>
        {!getTrimmedValue(row[key]) ? 'No Data' : row[key]}
      </Typography>
    </Tooltip>
  );
};

const FormSheetPreview = () => {
  const params = useParams();
  const mappingId = params.id;
  const [formSheetData, setFormSheetData] = useState([]);
  const [formSheetValidationResult, setFormSheetValidationResult] = useState({});
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [enableSave, setEnableSave] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorRowId, setErrorRowId] = useState({});
  const [errorCount, setErrorCount] = useState({});
  const [rows, setRows] = useState([]);
  const [validForms, setValidForms] = useState([]);
  const [domainPrefixes, setDomainPrefixes] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  const dispatch = useDispatch();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);

  const userId = Cookies.get('user.id');
  const editMode = editedRows.length > 0;

  const classes = useStyles();

  const getFormNameAndFormRepeat = async () => {
    let formNameAndRepeats = [];
    let payload = {
      mappingRuleVersionID: mappingId
    };
    const response = await dispatch(GetFormRepeatFlag(payload)).then(unwrapResult);
    if (response?.data?.success) {
      formNameAndRepeats =
        response.data.formsDetails?.map((item) => ({
          formName: getTrimmedValue(item.formName),
          formRepeatFlag: getTrimmedValue(item.formRepeatFlag)
        })) || [];
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
    return formNameAndRepeats;
  };

  const getDomainPrefixes = async () => {
    let domainPrefixes = [];
    const response = await dispatch(GetSDTMDomainPrefix(rowDataForRuleEditor)).then(unwrapResult);
    if (response?.data?.success) {
      domainPrefixes = response.data.domainPrefix?.map((domain) => getTrimmedValue(domain)) || [];
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
    return domainPrefixes;
  };

  const getSavePayload = (data, auditType) => {
    let formSheetApiColumns = Object.values(formsheetColumnNames);
    const formSheet = data.map((currentRow) => {
      return {
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: auditType,
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        mappingFormSheetId: 'FORM_SHEET',
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingId,
        ...formSheetApiColumns.reduce((acc, column) => {
          acc[column] = getTrimmedValue(currentRow[column]);
          return acc;
        }, {})
      };
    });
    return { formSheet };
  };

  const onSave = async () => {
    let payload;
    let validationResult;
    let newData = [...formSheetData];
    if (editMode && !errorFlag) {
      validationResult = formSheetValidator(editedRows, validForms, domainPrefixes);
      setFormSheetValidationResult(validationResult);
      payload = getSavePayload(editedRows, 'UPDATE');
    } else if (editMode && errorFlag) {
      const idToIndexMap = {};
      if (editedRows.length > 0 && formSheetData.length > 0) {
        const arr = [...formSheetData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            newData[idToIndexMap[data.id]] = data;
          } else {
            newData.push(data);
          }
        });
      }
      if (newData.length > 0) {
        validationResult = formSheetValidator(newData, validForms, domainPrefixes);
        setFormSheetValidationResult(validationResult);
        payload = getSavePayload(newData, 'INSERT');
      }
    } else {
      validationResult = formSheetValidator(formSheetData, validForms, domainPrefixes);
      setFormSheetValidationResult(validationResult);
      payload = getSavePayload(formSheetData, 'INSERT');
    }

    if (validationResult.isFormMissMatch() || validationResult?.getErrorCount() > 0) {
      if (editMode) {
        if (errorFlag) {
          setFormSheetData(newData);
          setRows(newData);
        } else {
          setRows(editedRows);
          setFormSheetData(editedRows);
        }
      }

      dispatch(showBanner({ variant: 'error', message: 'Data still has some error' }));
    } else {
      const saveResponse = await dispatch(SaveFormSheetsData(payload)).then(unwrapResult);
      if (saveResponse?.data?.success) {
        setReferenceData(FORMSHEET, []);
        setReferenceData(FORMSHEET_VALIDATION_RESULT, {});
        if (editMode && !errorFlag) {
          setRows(editedRows);
          setFormSheetData(editedRows);
        } else if (editMode && errorFlag) {
          setRows(newData);
          setFormSheetData(newData);
        } else {
          setFormSheetData(rows);
        }
        setIsPreviewDataUploaded(false);
        dispatch(showBanner({ variant: 'success', message: saveResponse.data.message }));
      } else {
        if (editMode) {
          setRows(editedRows);
        } else {
          setRows(formSheetData);
        }
        dispatch(showBanner({ variant: 'error', message: 'Something went wrong' }));
      }
      setEditedRows([]);
      setEnableSave(false);
    }
  };

  const getConfirmationBeforeSave = () => {
    setConfirmAlert({
      enabled: true,
      title: 'Do you want to Save the Data?',
      message:
        'Please check the information before submitting as the edits cannot be retrieved once the data is saved',
      variant: 'warning',
      onConfirm: () => {
        onSave();
        setConfirmAlert({
          enabled: false
        });
      },
      onCancel: () => {
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  const editRow = (id, key, value) => {
    setEditedRows((formSheetData) =>
      formSheetData.map((row) => (row.id === id ? { ...row, [key]: value } : row))
    );
  };

  const onEditAll = () => {
    setEditedRows(rows);
  };

  const onCancel = () => {
    setEditedRows([]);
  };

  useEffect(() => {
    (async () => {
      const _validForms = await getFormNameAndFormRepeat();
      setValidForms(_validForms);
      const _domainPrefixes = await getDomainPrefixes();
      setDomainPrefixes(_domainPrefixes);

      if (getReferenceData(FORMSHEET) && getReferenceData(FORMSHEET).length > 0) {
        setFormSheetData(getReferenceData(FORMSHEET));
        const validationResult = getReferenceData(FORMSHEET_VALIDATION_RESULT);
        setFormSheetValidationResult(validationResult);
        setIsLoading(false);
        setEnableSave(true);
        setIsPreviewDataUploaded(true);
      } else {
        const formSheetGetAPIData = await dispatch(
          GetFormSheetsData({
            mappingRuleVersionID: mappingId
          })
        ).then(unwrapResult);
        if (formSheetGetAPIData?.data?.success) {
          if (formSheetGetAPIData.data.formSheet?.length > 0) {
            const newData = formSheetGetAPIData.data.formSheet.map((item) => {
              let formSheetItem = {};
              for (let apiLabel of Object.values(formsheetColumnNames)) {
                formSheetItem[apiLabel] = item[apiLabel];
              }
              formSheetItem.id = uuidv4();
              return formSheetItem;
            });
            let validationResult = formSheetValidator(newData, _validForms, _domainPrefixes);
            setFormSheetValidationResult(validationResult);
            setFormSheetData(newData);
            setIsLoading(false);
            setIsPreviewDataUploaded(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (formSheetValidationResult && Object.keys(formSheetValidationResult).length > 0) {
      setErrorRowId(formSheetValidationResult.getErrorRowId());
      setErrorCount(formSheetValidationResult.getErrorCount());
    }
  }, [formSheetValidationResult]);

  useEffect(() => {
    if (errorFlag && !editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = formSheetData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else if (!errorFlag && !editMode) {
      setRows(formSheetData);
    } else if (errorFlag && editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = editedRows.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setEditedRows(filteredData);
    } else if (!errorFlag && editMode) {
      if (editedRows.length > 0 && formSheetData.length > 0) {
        const idToIndexMap = {};
        const arr = [...formSheetData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            arr[idToIndexMap[data.id]] = data;
          } else {
            arr.push(data);
          }
        });
        setEditedRows(arr);
      }
    }
  }, [errorFlag, editMode]);

  useEffect(() => {
    if (errorFlag) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = formSheetData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else {
      setRows(formSheetData);
    }
  }, [formSheetData]);

  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      const getData =
        formSheetValidationResult &&
        Object.keys(formSheetValidationResult).length &&
        formSheetValidationResult.isColumnValid(row['id'], key);
      return row.editMode ? (
        <TextField
          size="small"
          fullWidth
          className={classes.editTextField}
          value={row[key]}
          disabled={!formsheetColumnNames[`${key}_props`].isEditable()}
          onChange={(e) => row.editRow(row.id, key, e.target.value)}
          {...fieldStyles}
          {...(Object.keys(getData).length > 0
            ? {
                helperText: getValidTitle(getData),
                error: true
              }
            : {})}
        />
      ) : Object.keys(getData).length > 0 ? (
        getErrorData(getData, row, key)
      ) : (
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key]}
        </Typography>
      );
    },
    [formSheetValidationResult]
  );

  const columns = useMemo(() => {
    let thisColumns = [];
    for (let accessor of Object.values(formsheetColumnNames)) {
      thisColumns.push({
        header: formsheetColumnNames[accessor],
        accessor,
        customCell: EditableCell,
        fixedWidth: false,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter(accessor),
        sortFunction: compareStrings
      });
    }
    return thisColumns;
  }, [EditableCell]);

  return (
    <>
      {isLoading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={(editMode ? editedRows : rows).map((row, i) => {
                return {
                  ...row,
                  editRow,
                  editMode,
                  key: row.id,
                  index: i
                };
              })}
              initialSortedColumn="formName"
              initialSortOrder="asc"
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                truncate: true
              }}
              CustomHeader={CustomButtonHeader}
              headerProps={{
                formSheetData,
                errorCount,
                formSheetValidationResult,
                isPreviewDataUploaded,
                enableSave,
                editMode,
                errorFlag,
                setErrorFlag,
                setConfirmAlert,
                getConfirmationBeforeSave,
                onCancel,
                onEditAll
              }}
            />
          </Card>
          <CustomModal
            display={confirmAlert.enabled}
            title={confirmAlert.title}
            message={confirmAlert.message}
            body={confirmAlert.body}
            variant={confirmAlert.variant}
            buttonPrimaryLabel={'Ok'}
            handlePrimaryAction={() => confirmAlert?.onConfirm()}
            buttonSecondardyLabel={'Cancel'}
            handleClose={() => confirmAlert?.onCancel()}
          />
        </>
      )}
    </>
  );
};

export default FormSheetPreview;
