const ERROR = {
  MISSING_THEN: 'Missing then',
  INVALID_PLUS_SIGN: 'Invalid + in between if/then expression',
  MULTIPLE_IF: 'Multiple if detected',
  MULTIPLE_THEN: 'Multiple then detected',
  MISSING_IF: 'Missing If',
  MISSING_IF_THEN: 'Missing If & Then',
  MISSING_BRACKETS: 'missing closing bracket for ',
  BLANK: '',
  MISSING_DOT_OR_PLUS: 'missing . or +',
  MISSING_DOT: ' missing . or invalid operator',
  MISSING_CONJUCTION: 'Missing $and/$or',
  EXPRESSION_NOT_EXISTS_CONJUCTION: 'Expression not exists after or before $and/$or',
  EXPRESSION_NOT_EXISTS_IF_THEN: 'Expression not exists in between if and then',
  INVALID_OPERATOR: 'Missing or invalid operator',
  INVALID_AND_OR: 'Missing or invalid $and/$or.',
  LEFTSIDE_STRING: "Left side of operator can't be empty string",
  RIGHTSIDE_STRING: "Right side of operator can't be empty string",
  EXPRESSION_NOT_EXISTS_OPERATOR: 'Expression not exists after or before operator',
  FORM_LITERAL:
    'Invalid Expression: Needs to be a literal expression or delimiter/ operator has to be used.',
  MISSING_PLUS: 'Missing +',
  MISSING_EXPRESSION: 'missing expression',
  STRING_LITERAL: 'Form Literal can not start with string literal',
  MISSING_NAME: 'Missing form name',
  START_IF: 'If/Then Expression Must Start with If',
  START_OR: 'Expression must starts with [ or "',
  LITERAL_THEN: 'literal not exists after then',
  START_SQUARE_BRACKET_OR_DOUBLE_QUOTES: 'It must starts with [ or "',
  MISSING_ITEM_OR_METHOD: 'missing item name or method',
  INVALID_FORMNAME: 'Invalid FormName',
  INVALID_ITEM: 'Invalid ItemName',
  INVALID_METHOD: 'Invalid method name',
  INVALID_COMMA: 'Invalid Comma',
  INVALID_METHOD_ARGS: 'Invalid args found with method: ',
  MISSING_DOUBLE_QUOTES: 'Missing double quotes ',
  INVALID_ARGTYPE: 'Invalid arg type',
  UNEXPECTED_SPACES: 'Unexpected Spaces',
  MUST_METHOD: 'must be method or invalid method ',
  MISSING_METHOD: 'must be method or missing +',
  INVALID_PARENTHESIS: 'Missing ()',
  CANNOT_CHAIN_DoesVisitExist_METHOD: "can't chain DoesVisitExist method",
  DUPLICATE_METHOD: 'Duplicate Method ',
  USER_DEFINED_VARIABLE:
    'This is a User Defined variable and $SYSMAP is not allowed. User needs to select either Do Not Map or must map it using a valid Item.',
  INVALID_EXPRESSION: 'Invalid Expression',
  SDM_CODE_LIST: 'SDM is not avaiable in Code List',
  DECODE_CODE_LIST : 'No Codelist data available',
  DECODE_MUST_HAVE_ARGS : 'Decode must have args',
  ARGUMENT_NOT_ALLOWED: 'Argument Not Allowed',
  INVALID_DECODE_ARG: 'Invalid Decode Argument',
  SOURCE_VISIT_NAME: 'Inavlid Source Visit Name',
  CONTAIN_BOTH: 'Expression must contain both Form Name and Patient ID',
  MISSING_FORM_OR_ITEM_NAME: 'Missing form name or itemname',
  INVALID_SOURCE_CODE_LIST:'is not valid source code list ',
  FROM_ITEM_NOT_FOUND: 'form-item not found in codelist'
};

export default ERROR;
