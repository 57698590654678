/*eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import Button from 'apollo-react/components/Button';
import Typography from 'apollo-react/components/Typography';
import Grid from 'apollo-react/components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import Chip from 'apollo-react/components/Chip';
import ChipGroup from 'apollo-react/components/ChipGroup';
import { DomainRulesContext } from './DomainRulesProvider';
import { SaveSequenceOrder, GetSequenceOrder } from 'Redux/Service/DomainRuleService';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { makeStyles } from '@mui/styles';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
const COLOR_WHITE_IMPORTANT = 'white !important';

const useStyles = makeStyles({
  whiteCloseIcon: {
    margin: '0 -5px 0 5px !important',
    padding: '0 !important',
    color: COLOR_WHITE_IMPORTANT
  },
  whiteStyle: {
    fontSize: '14px',
    color: COLOR_WHITE_IMPORTANT,
    fontWeight: '600',
    backgroundColor: '#0768fd !important',
    cursor: 'default !important',
    marginTop: '9px',
    marginLeft: '9px'
  },
  disabledStyle: {
    alignItems: 'center',
    fontSize: '14px',
    color: COLOR_WHITE_IMPORTANT,
    fontWeight: '600',
    backgroundColor: '#0768fd !important',
    cursor: 'default !important',
    marginTop: '9px',
    marginLeft: '9px'
  },
  accordianSummary: {
    alignItems: 'center',
    justifyContent: 'center',
    // height: '60px',
    paddingRight: 0
  }
});

const SequenceOrder = () => {
  const context = useContext(DomainRulesContext);
  const dispatch = useDispatch();
  const [seqVariables, setSeqVariables] = useState([]);
  const [selectedChip, setSelectedChip] = useState([]);
  const [defaultChips, setDefaultChips] = useState([]);
  const [sequenceData, setSeqData] = useState('');
  const [enableSave, setEnableSave] = useState(false);
  const [referenceOfSelectedFromApi, setReferenceOfSelectedFromApi] = useState([]);

  const { id: mappingRuleVersionID } = useParams();
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const classes = useStyles();
  const { parentNodeName, domainFormattedData } = context;

  const getSequenceOrder = async (variableList) => {
    setReferenceOfSelectedFromApi([]);
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID,
      protocolNumber: protocol.protocolNumber,
      domainCodes: [parentNodeName]
    };

    let _variableList = variableList || seqVariables;

    const getSeqOrder = await dispatch(GetSequenceOrder(payload)).then(unwrapResult);
    if (getSeqOrder?.data?.success) {
      let seqData = getSeqOrder.data.sequence;
      let defaultSeqData = getSeqOrder.data.defaultSequence;
      let array = [];
      setSelectedChip([]);
      let defaultArray = [];
      seqData.length > 0 &&
        seqData.map((seq) => {
          array = seq.order !== '' ? seq.order.split(',') : [];
          setSelectedChip(array);
          setReferenceOfSelectedFromApi(array);
          if (array.length > 0) {
            array.map((chip) => {
              if (_variableList.includes(chip)) {
                let index = _variableList.indexOf(chip);
                if (index > -1) {
                  _variableList.splice(index, 1);
                }
              }
            });

            setSeqVariables(_variableList);
          }
          //  else {
          //   setChips(fullVariablesList);
          // }
        });
      setSeqData(array);
      defaultSeqData.forEach((defaultSeq) => {
        defaultArray = defaultSeq.order.split(',');
        if (defaultArray.length > 0) {
          defaultArray.map((defaultArr) => {
            if (_variableList.includes(defaultArr)) {
              let index = _variableList.indexOf(defaultArr);
              if (index > -1) {
                _variableList.splice(index, 1);
              }
            }
          });

          setSeqVariables(_variableList);
        }
      });
      setDefaultChips(defaultArray);

      if (array.length === 0 && defaultArray.length > 0) {
        setSelectedChip(defaultArray);
      } else {
        if (array.length) {
          setSelectedChip([...defaultArray, ...array]);
        } else {
          setSelectedChip([...defaultArray]);
        }
      }
      return;
    } else {
      dispatch(showBanner({ variant: 'error', message: getSeqOrder.data.message }));
      // return;
    }
  };

  useEffect(() => {
    setEnableSave(false);
    let flag = false;
    let nodes = Object.keys(domainFormattedData);
    let fullVariablesList = [];
    if (nodes.includes(parentNodeName)) {
      let copyOfParentData = JSON.parse(JSON.stringify(domainFormattedData[parentNodeName]));
      copyOfParentData.map((ele) => {
        fullVariablesList.push(ele.variableName);
      });
      setSeqVariables(fullVariablesList);
      // setFlag(true)
      flag = true;
    }
    if (flag) {
      getSequenceOrder(fullVariablesList);
    }
  }, [parentNodeName, domainFormattedData]);

  const handleFilteredChips = (_, value) => {
    setSelectedChip([...selectedChip, value]);
    setReferenceOfSelectedFromApi([...referenceOfSelectedFromApi, value]);
    setEnableSave(true);
    const modifiedSeqVariables = [...seqVariables];
    const index = modifiedSeqVariables.indexOf(value);
    if (index > -1) {
      // setSelectValue(selectedChip)
      modifiedSeqVariables.splice(index, 1);
      setSeqVariables(modifiedSeqVariables);
    }
  };

  const handleChipDelete = (value) => {
    let modifiedChipArr = JSON.parse(JSON.stringify(seqVariables));
    let copyOfReferenceSelectedChip = JSON.parse(JSON.stringify(referenceOfSelectedFromApi));
    let copyOfSelectedChip = JSON.parse(JSON.stringify(selectedChip));
    const index = copyOfReferenceSelectedChip.indexOf(value);
    const selectedChipIndex = copyOfSelectedChip.indexOf(value);
    if (index > -1 && selectedChipIndex > -1) {
      copyOfReferenceSelectedChip.splice(index, 1);
      copyOfSelectedChip.splice(selectedChipIndex, 1);
      setSelectedChip(copyOfSelectedChip);
      setReferenceOfSelectedFromApi(copyOfReferenceSelectedChip);
      setSeqVariables(modifiedChipArr.length > 0 ? [...modifiedChipArr, value] : [value]);
    }
    setEnableSave(true);
    // buttonLabel()
  };

  const saveSequenceOrder = async () => {
    const seqData = [];
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID,
      protocolNumber: protocol.protocolNumber,
      sequence: seqData,
      defaultSequence: []
    };
    // referenceOfSelectedFromApi.toString() !== '' &&
    seqData.push({
      iqCreateDate: new Date().toISOString(),
      iqUpdateDate: new Date().toISOString(),
      iqCreatedBy: userId,
      iqUpdatedBy: userId,
      iqAuditType: 'INSERT',
      iqAuditDate: new Date().toISOString(),
      iqActiveFlag: true,
      domainCode: parentNodeName,
      order: referenceOfSelectedFromApi.toString()
    });

    const saveSequenceOrderData = await dispatch(SaveSequenceOrder(payload)).then(unwrapResult);
    if (saveSequenceOrderData && saveSequenceOrderData.data.success) {
      setEnableSave(false);
      dispatch(showBanner({ variant: 'success', message: saveSequenceOrderData.data.message }));
      getSequenceOrder();
      return;
    } else {
      dispatch(showBanner({ variant: 'error', message: saveSequenceOrderData.data.message }));
      return;
    }
  };

  const buttonLabel = () => {
    let boolean = JSON.stringify(selectedChip) === JSON.stringify(defaultChips);
    return boolean ? 'Save' : 'Edit';
  };

  return (
    <>
      <Accordion style={{ width: '100%', marginTop: '30px' }}>
        <AccordionSummary
          style={{ flexDirection: 'row-reverse' }}
          className={classes.accordianSummary}>
          <Grid container>
            <Grid xs={11}>
              <Typography
                style={{
                  marginTop: '18px',
                  float: 'left',
                  color: '#595959',
                  fontWeight: 500,
                  fontSize: '15px'
                }}>
                Sequence Order
              </Typography>
              <span>
                <ChipGroup>
                  {selectedChip && selectedChip.length > 0
                    ? selectedChip.map((el) => {
                        if (defaultChips.includes(el)) {
                          return (
                            <Chip
                              label={el}
                              value={el}
                              classes={classes}
                              onDelete={() => handleChipDelete(el)}
                              disabled
                            />
                          );
                        } else {
                          return (
                            <Chip
                              label={el}
                              value={el}
                              classes={classes}
                              color={'white'}
                              onDelete={() => handleChipDelete(el)}
                            />
                          );
                        }
                      })
                    : defaultChips.length > 0 &&
                      defaultChips.map((eachDefChip) => {
                        return (
                          <Chip
                            label={eachDefChip}
                            value={eachDefChip}
                            classes={classes}
                            onDelete={() => handleChipDelete(eachDefChip)}
                            disabled
                          />
                        );
                      })}
                </ChipGroup>
              </span>
            </Grid>
            <Grid xs={1}>
              {enableSave && (
                <Button
                  variant="secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  onClick={saveSequenceOrder}
                  disabled={!sequenceData.length && !referenceOfSelectedFromApi.length}>
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid xs={12} item>
              <div
                style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '3px',
                  padding: '20px',
                  maxHeight: '70px',
                  overflowY: 'auto'
                }}>
                <ChipGroup onChange={(e, value) => handleFilteredChips(e, value)}>
                  {seqVariables &&
                    seqVariables.map((variable) => {
                      return (
                        <Chip
                          label={variable}
                          value={variable}
                          key={variable}
                          style={{
                            fontSize: '14px',
                            marginBottom: '10px',
                            color: 'black',
                            borderColor: '#e6e6e6',
                            fontWeight: '600'
                          }}
                        />
                      );
                    })}
                </ChipGroup>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default React.memo(SequenceOrder);
