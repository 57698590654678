import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Typography } from 'apollo-react';
import { neutral4, neutral8 } from 'apollo-react/colors';
import PropTypes from 'prop-types';
import ApolloProgress from 'apollo-react/components/ApolloProgress';
import ErrorIcon from 'Images/error.svg';

const ConfigSection = (props) => {
  const { title = '', subtitle = '', loading = false, children, hasError } = props;
  return (
    <Card
      sx={{
        mb: '1rem',
        p: 0,
        '& .MuiAccordion-root': {
          borderColor: 'transparent'
        },
        pointerEvents: loading && 'none',
        opacity: loading && 0.8
      }}>
      <Accordion>
        <AccordionSummary>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0.75rem 0'
            }}>
            <Box>
              <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>{title}</Typography>
              <Typography sx={{ fontSize: '14px', color: neutral8 }}>{subtitle}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {hasError && <img alt="error-icon" style={{ paddingLeft: '5px' }} src={ErrorIcon} />}
              {loading && <ApolloProgress />}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: `1px solid ${neutral4}`, p: 2 }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

ConfigSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.func
};

export default ConfigSection;
