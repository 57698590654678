/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoader, closeLoader } from '../Slice/LoaderSlice';
import axios from 'axios';
import { getHeadersFromCookies } from 'service/header.service';

const API_URL = process.env.REACT_APP_API_URL;
const CONTENT_TYPE = 'application/json;charset=UTF-8';

export const getEdcOdmVisits = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/GetEdcOdmVisitsPreview`, {
    method: 'POST',
    headers: {
      'Content-type': CONTENT_TYPE,
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const getEdcOdmForms = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/GetEdcOdmFormsPreview`, {
    method: 'POST',
    headers: {
      'Content-type': CONTENT_TYPE,
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const getEdcOdmCodelist = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/GetEdcOdmCodeListPreview`, {
    method: 'POST',
    headers: {
      'Content-type': CONTENT_TYPE,
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};
export const getEdcOdmMeasure = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/GetEdcOdmMeasurementPreview`, {
    method: 'POST',
    headers: {
      'Content-type': CONTENT_TYPE,
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};
export const getQECG = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/GetECGPreview`, {
    method: 'POST',
    headers: {
      'Content-type': CONTENT_TYPE,
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const getQLAB = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/GetQLABPreview`, {
    method: 'POST',
    headers: {
      'Content-type': CONTENT_TYPE,
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const getCDR = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/GetCDRTabularPreview`, {
    method: 'POST',
    headers: {
      'Content-type': CONTENT_TYPE,
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const getData = async (payload) => {
  const api = await axios.post(`${API_URL}/api/Protocol/ExportStudyLibrary`, payload);
  const { data } = api;
  return data;
};

export const getEdcOdmFormItemEproInfo = async ({ studyLibraryID, studyLibraryVersion }) => {
  const api = await axios.get(
    `${API_URL}/api/Protocol/GetEdcOdmFormsItems?LibraryID=${studyLibraryID}&LibraryVersion=${studyLibraryVersion}`
  );
  const { data } = api;
  return data;
};
