/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showLoader, closeLoader } from '../Slice/LoaderSlice';
import Cookies from 'js-cookie';

// eslint-disable-next-line

const API_URL = process.env.REACT_APP_API_URL;

export const GetDataProductSummary = createAsyncThunk('Dashboard/ProductSummary', async (payload) => {
  return await axios.post(
    `${API_URL}/api/Dashboard/GetDataProductSummeries`, payload
  );
});

export const SaveDashboardPipeline = createAsyncThunk('Dashboard/SavePipelineStatus', async (payload) => {
  return await axios.post(
    `${API_URL}/api/Dashboard/SavePipelineStatus`, payload
  );
});

export const GetDashBoardCardData = createAsyncThunk(
  'Publish/GetDashBoardCardData',
  async (payloadObj, { dispatch, rejectWithValue }) => {
    dispatch(showLoader());
    try {
      const response = await axios.get(
        `${API_URL}/api/Dashboard/GetDashBoardCardData?userId=${Cookies.get(
          'user.id'
        )}&IsAll=${payloadObj.existingFlag}&protocolName=${payloadObj.protocolName}`
      );
      dispatch(closeLoader());
      return response;
    } catch (error) {
      dispatch(closeLoader());
      rejectWithValue([], error);
    }
  }
);

export const GetDataSetStats = createAsyncThunk('Dashboard/DataSetStats', async (payload) => {
  return await axios.get(
    `${API_URL}/api/Dashboard/GetDataSetStats?mappingRuleVersionID=${payload.mappingRuleVersionID}&environment=${payload.environment}`
  );
});

export const GetVLCStats = createAsyncThunk('Dashboard/VLCStats', async (payload) => {
  return await axios.get(
    `${API_URL}/api/Dashboard/GetVLCStats?JobRunID=${payload.jobRunID}&mappingRuleVersionID=${payload.mappingRuleVersionID}&datasetName=${payload.datasetName}`
  );
});
