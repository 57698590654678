/*eslint-disable */
import React from 'react';
import ApolloProgress from 'apollo-react/components/ApolloProgress';

const Loader = (props) => {
  return (
    <>
      <ApolloProgress {...props} />
    </>
  );
};

export default Loader;
