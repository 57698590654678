/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoader, closeLoader } from '../Slice/LoaderSlice';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
import Cookies from 'js-cookie';

export const getStudyLibraryGrid = createAsyncThunk(
  'StudyLibrary/getStudyLibraryGrid',
  async (protocol, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/Protocol/GetStudyLibraries?studyProtocolNumber=${protocol}`
      );
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const getProtocolAuth = createAsyncThunk(
  'StudyLibrary/getProtocolAuth',
  async (protocol, { dispatch, rejectWithValue }) => {
    // dispatch(showLoader());
    try {
      const response = await axios.get(
        `${API_URL}/api/UserAuth/GetStudyAuthByProtocolId?userId=${Cookies.get('user.id')}&protocol=${protocol}`
      );
      // dispatch(closeLoader());
      return response;
    } catch (error) {
      // dispatch(closeLoader());
      rejectWithValue([], error);
    }
  }
);

export const getCDRStudlyLibraries = createAsyncThunk(
  'StudyLibrary/GetCDRStudyLibraries',
  async (protocol, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/DataProduct/GetCDRStudyLibraries?protocolName=${protocol}`
      );
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const getLatestCDRStudies = async (payload) => {
  return await axios.post(`${API_URL}/api/DataProduct/GetLatestStudyLibraries`, payload);
};


