/* eslint-disable*/
import Bullseye from 'apollo-react-icons/Bullseye';
import Cog from 'apollo-react-icons/Cog';
import OpenNew from 'apollo-react-icons/OpenNew';
import Pencil from 'apollo-react-icons/Pencil';
import Rocket from 'apollo-react-icons/Rocket';
import Services from 'apollo-react-icons/Services';
import User3 from 'apollo-react-icons/User3';
import React from 'react';

import Autocomplete from 'apollo-react/components/Autocomplete';
import IconButton from 'apollo-react/components/IconButton';
import {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
/* istanbul ignore next */
const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};
/* istanbul ignore next */
export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};
/* istanbul ignore next */
const createAutocompleteFilter =
  (source) =>
  ({ accessor, filters, updateFilterValue }) => {
    const ref = React.useRef();
    const [height, setHeight] = React.useState(0);
    const [isFocused, setIsFocused] = React.useState(false);
    const value = filters[accessor];

    React.useEffect(() => {
      const curHeight = ref?.current?.getBoundingClientRect().height;
      if (curHeight !== height) {
        setHeight(curHeight);
      }
    }, [value, isFocused, height]);

    return (
      <div
        style={{
          minWidth: 144,
          maxWidth: 200,
          position: 'relative',
          height
        }}>
        <Autocomplete
          style={{ position: 'absolute', left: 0, right: 0 }}
          value={value ? value.map((label) => ({ label })) : []}
          name={accessor}
          source={source}
          onChange={(event, value) =>
            updateFilterValue({
              target: { name: accessor, value: value.map(({ label }) => label) }
            })
          }
          fullWidth
          multiple
          chipColor="white"
          size="small"
          showCheckboxes
          limitChips={1}
          filterSelectedOptions={false}
          blurOnSelect={false}
          clearOnBlur={false}
          disableCloseOnSelect
          showSelectAll
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={ref}
        />
      </div>
    );
  };

const departmentIcons = {
  Design: Rocket,
  Engineering: Cog,
  'Human Resources': User3,
  Marketing: Services,
  QA: Bullseye
};
/* istanbul ignore next */
const ActionCell = ({ row }) => {
  return (
    <div style={{ width: 68 }}>
      <IconButton size="small" data-id={row.employeeId} style={{ marginRight: 4 }}>
        <Pencil />
      </IconButton>
      <IconButton size="small" data-id={row.employeeId}>
        <OpenNew />
      </IconButton>
    </div>
  );
};
/* istanbul ignore next */
const VersionCell = ({ row, column: { accessor } }) => {
  const versionRight = row[accessor];
  return <Typography style={{ float: 'right', width: '30%' }}>{versionRight}</Typography>;
};
/* istanbul ignore next */
export function createStringArraySearchFilter(accessor) {
  return (row, filters) =>
    !Array.isArray(filters[accessor]) ||
    filters[accessor].length === 0 ||
    filters[accessor].some((value) => value.toUpperCase() === row[accessor]?.toUpperCase());
}

const columns = [
  {
    accessor: 'versionName',
    header: 'Version',
    minWidth: '100',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('versionName'),
    sortFunction: compareStrings,
    fixedWidth: false
  },
  {
    accessor: 'seqForOrder',
    header: 'Seq. For Order',
    minWidth: '100',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('seqForOrder'),
    customCell: VersionCell,
    sortFunction: compareNumbers,
    fixedWidth: false
  },
  {
    accessor: 'observationClass',
    header: 'Observation Class',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('observationClass'),
    fixedWidth: false
  },
  {
    accessor: 'domainPrefix',
    header: 'Domain Prefix',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('domainPrefix'),
    fixedWidth: false
  },
  {
    accessor: 'domainName',
    header: 'Domain Name',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('domainName'),
    fixedWidth: false
  },
  {
    accessor: 'domainStructure',
    header: 'Domain Structure',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('domainStructure'),
    fixedWidth: false
  },
  {
    accessor: 'variableNameminusdomainprefix',
    header: 'Variable Name (minus domain prefix)',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('variableNameminusdomainprefix'),
    fixedWidth: false
  },
  {
    accessor: 'variableName',
    header: 'Variable Name',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('variableName'),
    fixedWidth: false
  },
  {
    accessor: 'variableLabel',
    header: 'Variable Label',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('variableLabel'),
    fixedWidth: false
  },
  {
    accessor: 'type',
    header: 'Type',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('type'),
    fixedWidth: false
  },
  {
    accessor: 'controlledTerms',
    header: 'Controlled Terms or Format',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('controlledTerms'),
    fixedWidth: false
    // sortFunction: compareStrings,
    // filterComponent: TextFieldFilter,
    // filterFunction:   createStringSearchFilter('Controlled Terms or Format'),
  },
  {
    accessor: 'role',
    header: 'Role',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('role'),
    fixedWidth: false
  },
  {
    accessor: 'cdiscNotesfordomainsDescriptionforGeneralClasses',
    header: 'CDISC Notes (for domains) Description (for General Classes)',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('cdiscNotesfordomainsDescriptionforGeneralClasses'),
    fixedWidth: false
  },
  {
    accessor: 'core',
    header: 'Core',
    minWidth: '100',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('core'),
    fixedWidth: false
    // sortFunction: compareStrings,
    // filterFunction: createStringSearchFilter('employmentStatus'),
    // filterComponent: createSelectFilterComponent(['Contractor', 'Full-time'], { size: 'small' }),
  },
  {
    accessor: 'data_length',
    header: 'Data Length',
    minWidth: '100',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('data_length'),
    sortFunction: compareNumbers,
    fixedWidth: false
    // filterFunction: createStringSearchFilter('employmentStatus'),
    // filterComponent: createSelectFilterComponent(['Contractor', 'Full-time'], { size: 'small' }),
  },
  {
    accessor: 'data_precision',
    header: 'Data Precision',
    minWidth: '100',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('data_precision'),
    sortFunction: compareNumbers,
    fixedWidth: false
    // sortFunction: compareStrings,
    // filterFunction: createStringSearchFilter('employmentStatus'),
    // filterComponent: createSelectFilterComponent(['Contractor', 'Full-time'], { size: 'small' }),
  },
  {
    accessor: 'data_scale',
    header: 'Data Scale',
    minWidth: '100',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('data_scale'),
    sortFunction: compareNumbers,
    fixedWidth: false
    // sortFunction: compareStrings,
    // filterFunction: createStringSearchFilter('employmentStatus'),
    // filterComponent: createSelectFilterComponent(['Contractor', 'Full-time'], { size: 'small' }),
  },
  {
    accessor: 'is_sdtm_yn',
    header: 'is sdtm yn',
    minWidth: '100',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('is_sdtm_yn'),
    sortFunction: compareStrings,
    fixedWidth: false
  },
  {
    accessor: 'mapped_By',
    header: 'MappedBy',
    minWidth: '100',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('mapped_By'),
    sortFunction: compareStrings,
    fixedWidth: false
  },
  {
    accessor: 'action'
    // customCell: ActionCell,
    // width: 68,
  }
];

export default columns;
