export const detailsObj = {
    details: {
        margin: '1rem 2rem 1rem 0',
        fontFamily: 'Proxima Nova, Nunito Sans, sans-serif',
        minWidth: '150px'
    },
    detailsLabel: {
        color: '#444444',
        fontSize: '14px',
        marginBottom: '0.5rem'
        // fontWeight: '400'
    },
    detailsContent: {
        color: '#000000',
        fontSize: '16px',
        fontWeight: '500'
    },
};