/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import PlusIcon from 'apollo-react-icons/Plus';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Trash from 'apollo-react-icons/Trash';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Switch from 'apollo-react/components/Switch';
import Loader from 'Components/Loader/Loader';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCodeLists,
  GetCtmsVisitsData,
  GetProductCTPData,
  saveCtpSvt
} from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { generateErrorString, getHtmlString, jsonToExcel, uuidv4 } from 'Utils';
import { TableNewRow, useNewRow } from './TableNewRow';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import CustomModal from 'Components/Modal/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import useGetTitle from '../../../hooks/useGetTitle';
import useSelectedDatasourcesData from '../../../hooks/useSelectedDatasourcesData';
import { unwrapResult } from '@reduxjs/toolkit';
import { ctpSvtValidator } from 'Validators/CtpSvt.Validator';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import { white } from 'apollo-react/colors';
import data from '../DomainRulesComponents/domain';
import { GetRuleMetadataFormItems } from 'Redux/Service/MasteringRuleService';
import Cookies from 'js-cookie';
// import { data } from 'msw/lib/types/context';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const mapping_obj = {
  visittype: '',
  visitname: '',
  visitnum: '',
  grpid: '',
  clrid: '',
  updes: '',
  edc: '',
  cdr: '',
  cmpltd_flg: '',
  cascade_flg: '',
  interface: ''
};

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};
const customTextFieldStyles = {
  style: {
    marginTop: 7,
    height: 30,
    backgroundColor: 'white',
    width: '100%',
    fontSize: 14
  }
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const CtpSvtPreview = () => {
  const params = useParams();
  const mappingRuleVersionID = params.id;
  const { cardTitle } = useGetTitle('ctp-svt');
  const {
    data: selectedDataSourcesData,
    loading,
    error: apiError
  } = useSelectedDatasourcesData(mappingRuleVersionID);
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(false);
  const [ctpSvtData, setCtpSvtData] = useState([]);
  const [ctpSvtValidationResult, setCtpSvtValidationResult] = useState({});
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [error, setError] = useState();
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [editedRows, setEditedRows] = useState([]);
  const [newRow, setNewRow, editNewRow] = useNewRow({});
  const [isAdding, setIsAdding] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [isEditDisable, setIsEditDisable] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  const [ctpSvtMessage, setCtpSvtMessage] = useState('');
  const [resetPage, setPageReset] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [errorRowId, setErrorRowId] = useState({});
  const [errorCount, setErrorCount] = useState({});
  const classes = useStyles();
  const [dataSourceData, setDataSourceData] = useState([]);
  const editMode = editedRows.length > 0;
  const [isLoading, setIsLoading] = useState(true);
  const [ctmsVisitsData, setCtmsVisitsData] = useState([]);
  const [codeListDataMapping, setCodeListDataMapping] = useState(null);
  const [formsObj, setFormsObj] = useState({});
  const [cdrTabular, setCDRTabular] = useState(undefined);
  const [formatedFormData, setFormatedFormData] = useState([]);
  const [formatedCDRTabular, setFormatedCDRTabular] = useState([]);
  const [isCtmsConfigured, setIsCtmsConfigured] = useState(true);

  const convertToUpper = (obj) => {
    let cmpltd_flg = obj.cmpltd_flg;
    let cascade_flg = obj.cascade_flg;
    let interfaces = obj.interface;
    if (obj.cmpltd_flg === 'y') {
      cmpltd_flg = 'Y';
    } else if (obj.cmpltd_flg === 'n') {
      cmpltd_flg = 'N';
    }
    if (obj.cascade_flg === 'y') {
      cascade_flg = 'Y';
    } else if (obj.cascade_flg === 'n') {
      cascade_flg = 'N';
    }
    if (obj.interface === 'y') {
      interfaces = 'Y';
    } else if (obj.interface === 'n') {
      interfaces = 'N';
    }
    let newObj = { cmpltd_flg, cascade_flg, interface: interfaces };
    const modifiedObj = { ...obj, ...newObj };
    return modifiedObj;
  };

  //cdrTabular and formsObj check
  useEffect(() => {
    (async () => {
      let formsObj = {};
      let itemNames = [];
      let cdrTabular = {};
      let formatedFormItemData = [];
      let formatedCDRTabularData = [];
      try {
        const payload = {
          mappingRuleVersionID: mappingRuleVersionID,
          forms: [1, 2]
        };
        setIsLoading(true);
        const getFormsData = await dispatch(GetRuleMetadataFormItems(payload)).then(unwrapResult);
        if (getFormsData && getFormsData.data && getFormsData.data.success) {
          const allMetadataFormItems = [
            ...getFormsData.data.formItemsDict['CDISC ODM'],
            ...getFormsData.data.formItemsDict['PSeudo Forms']
          ];
          allMetadataFormItems.forEach((obj) => {
            formatedFormItemData.push({
              formName: obj.formName,
              itemName: obj.itemName,
              libraryType: 'CDISC ODM',
              formatedString: `[${obj.formName}].[${obj.itemName}]`
            });
            if (!formsObj[obj.formName]) {
              formsObj[obj.formName] = [obj.itemName];
            } else {
              formsObj[obj.formName].push(obj.itemName);
            }
          });
          getFormsData.data.formItemsDict['CDR Tabular'].forEach((obj) => {
            formatedCDRTabularData.push({
              formName: obj.formName,
              itemName: obj.itemName,
              libraryType: 'CDR Tabular',
              formatedString: `[${obj.formName}].[${obj.itemName}]`
            });
            if (!cdrTabular[obj.formName]) {
              cdrTabular[obj.formName] = [obj.itemName];
            } else {
              cdrTabular[obj.formName].push(obj.itemName);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
      setFormsObj(formsObj);
      setCDRTabular(cdrTabular);
      setFormatedFormData(formatedFormItemData);
      setFormatedCDRTabular(formatedCDRTabularData);
      setIsLoading(false);
    })();
  }, []);

  //Ctms Configration Check
  useEffect(() => {
    (async () => {
      let payload = {
        pageNumber: 0,
        pageSize: 0,
        protocolName: protocol.protocolNumber
      };
      const getCtmsVisits = await dispatch(GetCtmsVisitsData(payload)).then(unwrapResult);
      if (getCtmsVisits && getCtmsVisits.data && getCtmsVisits.data.success) {
        setIsCtmsConfigured(getCtmsVisits.data.isCtmsConfigured);
        // if (!getCtmsVisits.data.isCtmsConfigured) {
        //   navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
        // }
        if (getCtmsVisits.data.edcOdmCtmsVisits && getCtmsVisits.data.edcOdmCtmsVisits.length > 0) {
          const newData = getCtmsVisits.data.edcOdmCtmsVisits.map((item) => {
            return {
              visitname: item.visitName,
              visittype: item.visitTypeCode
            };
          });
          setCtmsVisitsData(newData);
        } else {
          setCtmsVisitsData([]);
        }
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!codeListDataMapping) {
        try {
          const payload = {
            mappingRuleVersionID: mappingRuleVersionID
          };
          setIsLoading(true);
          const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
          if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
            const mapping = {};
            const codelists = codeListsGetAPIData.data.ruleCodeLists || [];
            for (let { odmForm, odmItem, sdmCodelistLabel } of codelists) {
              const key = `${odmForm.toLowerCase()}#-#${odmItem.toLowerCase()}`;
              if (!(key in mapping)) {
                mapping[key] = [];
              }
              if (sdmCodelistLabel.trim()) mapping[key].push(sdmCodelistLabel.toLowerCase());
            }
            setCodeListDataMapping(mapping);
          }
        } catch (error) {
          setCodeListDataMapping({});
          console.log(error);
        }
      }
      setIsLoading(false);
    })();
  }, [codeListDataMapping]);

  //for DataSourcesData fetching
  useEffect(() => {
    if (!loading) {
      if (
        selectedDataSourcesData &&
        selectedDataSourcesData.data &&
        selectedDataSourcesData.data.success
      ) {
        setDataSourceData(
          selectedDataSourcesData?.data?.ruleStudyLibrary.filter(
            (row) => row.isMappingRuleConfigured
          )
        );
      } else {
        apiError && dispatch(showBanner({ variant: 'error', message: apiError }));
      }
    }
  }, [selectedDataSourcesData, loading]);

  //fetching data from direct upload OR from API
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let payload = {
        mappingRuleVersionID: mappingRuleVersionID
      };
      if (getReferenceData('ctp-svt') && getReferenceData('ctp-svt').length > 0) {
        const newData = getReferenceData('ctp-svt');

        setCtpSvtData(newData);
        setIsPreviewDataUploaded(true);
        setIsLoading(false);
        setEnableSave(true);
      } else {
        const ctpSvtGetAPIData = await dispatch(GetProductCTPData(payload)).then(unwrapResult);
        if (ctpSvtGetAPIData && ctpSvtGetAPIData.data && ctpSvtGetAPIData.data.success) {
          if (ctpSvtGetAPIData.data.timePoints && ctpSvtGetAPIData.data.timePoints.length > 0) {
            setError('');
            const newData = ctpSvtGetAPIData.data.timePoints.map((item) => {
              return {
                visittype: item.visitType,
                visitname: item.visit,
                visitnum: item.vistnum,
                grpid: item.tvgrpid,
                clrid: item.tvclrid,
                updes: item.tpupdes,
                edc: item.edc,
                cdr: item.tabular,
                cmpltd_flg: item.completedFlag,
                cascade_flg: item.cascadeFlag,
                interface: item.interface,
                edcdefaultform: item.edcDefaultForm,
                edccondition: item.edcCondition,
                cdrdefaultform: item.cdrDefaultForm,
                cdrcondition: item.cdrCondition,
                id: uuidv4()
              };
            });
            setIsLoading(false);
            setCtpSvtData(newData);
            setIsPreviewDataUploaded(false);
          } else {
            setIsLoading(false);
            setCtpSvtData([]);
            setIsEditDisable(true);
          }
        } else {
          setIsLoading(false);
          setCtpSvtMessage(ctpSvtGetAPIData.data.message);
          setCtpSvtData([]);
        }
      }
    })();
  }, []);

  //ctpSVt Validation
  useEffect(() => {
    (async () => {
      // validate ctpSvtData
      setIsLoading(false);
      // if (ctmsVisitsData.length === 0) {
      //   setIsLoading(true);
      // }
      if (
        ctpSvtData.length &&
        dataSourceData.length &&
        ctmsVisitsData.length &&
        Object.keys(formsObj).length > 0 &&
        cdrTabular !== undefined
      ) {
        const validationResult = await ctpSvtValidator(
          ctpSvtData,
          dataSourceData,
          ctmsVisitsData,
          codeListDataMapping,
          formsObj,
          cdrTabular,
          isCtmsConfigured
        );
        setCtpSvtValidationResult(validationResult);
        setErrorRowId(validationResult.getErrorRowIndex());
        setErrorCount(validationResult.getErrorCount());
      }
    })();
  }, [ctpSvtData, dataSourceData, ctmsVisitsData, codeListDataMapping, formsObj, cdrTabular]);

  const getValidTitle = (data) => {
    return data.mandatory
      ? data.mandatory
      : data.number ||
          data.blankFilled ||
          data.nameAndNumberMap ||
          data.visitDescBlank ||
          data.edc ||
          data.cdr ||
          data.edcBlankError ||
          data.yesNo ||
          data.visittype ||
          data.visitname ||
          data.edcdefaultform ||
          data.edccondition ||
          data.cdrdefaultform ||
          data.cdrcondition;
  };

  useEffect(() => {
    if (errorFlag) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = ctpSvtData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else setRows(ctpSvtData);
  }, [ctpSvtData]);

  useEffect(() => {
    setPageReset(true);
    setImmediate(() => {
      setPageReset(false);
    });
  }, [errorFlag, editMode]);

  useEffect(() => {
    if (errorFlag && !editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = ctpSvtData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else if (!errorFlag && !editMode) {
      setRows(ctpSvtData);
    } else if (errorFlag && editMode) {
      let filteredData = [];
      let modifiedFilteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = editedRows.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      filteredData.forEach((obj) => {
        modifiedFilteredData.push(convertToUpper(obj));
      });
      setEditedRows(modifiedFilteredData);
    } else if (!errorFlag && editMode) {
      if (editedRows.length > 0 && ctpSvtData.length > 0) {
        const idToIndexMap = {};
        const arr = [...ctpSvtData];
        let modifiedArr = [];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            arr[idToIndexMap[data.id]] = data;
          } else {
            arr.push(data);
          }
        });
        arr.forEach((obj) => {
          return modifiedArr.push(convertToUpper(obj));
        });
        setEditedRows(modifiedArr);
      }
    }
  }, [errorFlag, editMode]);

  const getSavePayload = (data, auditType) => {
    const timePoints = data.map((item) => {
      const {
        visitname,
        visitnum,
        visittype,
        grpid,
        clrid,
        updes,
        edc,
        cdr,
        cmpltd_flg,
        cascade_flg,
        interface: interfaces,
        edccondition,
        edcdefaultform,
        cdrdefaultform,
        cdrcondition
        // data_flow_value,
        // data_flow_id
      } = item;
      return {
        // mappingTimePointId: 'TP',
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingRuleVersionID,
        domainNm: '',
        visit: visitname,
        vistnum: visitnum,
        visitType: visittype,
        tvgrpid: grpid,
        tvclrid: clrid,
        tpupdes: updes,
        edc: edc,
        tabular: cdr,
        completedFlag: cmpltd_flg,
        cascadeFlag: cascade_flg,
        interface: interfaces,
        edcCondition: edccondition,
        edcDefaultForm: edcdefaultform,
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreateBy: userId,
        iqUpdateBy: userId,
        iqAuditType: auditType,
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        cdrCondition: cdrcondition,
        cdrDefaultForm: cdrdefaultform
      };
    });
    const payload = { timePoints };
    return payload;
  };

  const onSave = async () => {
    let payload;
    let validationResult;
    let newData = [...ctpSvtData];
    setOpenConfirmModal(false);
    if (editMode && !errorFlag) {
      validationResult = await ctpSvtValidator(
        editedRows,
        dataSourceData,
        ctmsVisitsData,
        codeListDataMapping,
        formsObj,
        cdrTabular
      );
      setCtpSvtValidationResult(validationResult);
      payload = getSavePayload(editedRows, 'UPDATE');
    } else if (editMode && errorFlag) {
      const idToIndexMap = {};
      if (editedRows.length > 0 && ctpSvtData.length > 0) {
        const arr = [...ctpSvtData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            newData[idToIndexMap[data.id]] = data;
          } else {
            newData.push(data);
          }
        });
      }
      if (newData.length > 0) {
        validationResult = await ctpSvtValidator(
          editedRows,
          dataSourceData,
          ctmsVisitsData,
          codeListDataMapping,
          formsObj,
          cdrTabular
        );
        setCtpSvtValidationResult(validationResult);
        payload = getSavePayload(newData, 'INSERT');
      }
    } else {
      validationResult = await ctpSvtValidator(
        ctpSvtData,
        dataSourceData,
        ctmsVisitsData,
        codeListDataMapping,
        formsObj,
        cdrTabular
      );
      setCtpSvtValidationResult(validationResult);
      payload = getSavePayload(ctpSvtData, 'INSERT');
    }
    if (validationResult?.getErrorCount() > 0) {
      if (editMode) {
        setRows(editedRows);
        setCtpSvtData(editedRows);
        if (errorFlag) {
          setCtpSvtData(newData);
          setRows(newData);
        }
      } else {
        setRows([...rows]);
      }

      setErrorRowId(validationResult.getErrorRowIndex());
      setErrorCount(validationResult.getErrorCount());
      dispatch(showBanner({ variant: 'error', message: 'Data still has some error' }));
    } else {
      const saveResponse = await dispatch(saveCtpSvt(payload));
      if (
        saveResponse &&
        saveResponse.payload &&
        saveResponse.payload.data &&
        saveResponse.payload.data.success
      ) {
        setReferenceData('ctp-svt', []);
        if (editMode && !errorFlag) {
          setRows(editedRows);
          setCtpSvtData(editedRows);
        } else if (editMode && errorFlag) {
          setRows(newData);
          setCtpSvtData(newData);
        } else {
          setRows([...rows]);
          setCtpSvtData(rows);
        }
        setIsPreviewDataUploaded(false);
        dispatch(showBanner({ variant: 'success', message: saveResponse.payload.data.message }));
      } else {
        if (editMode) {
          setRows(editedRows);
        } else {
          setRows(ctpSvtData);
        }
        dispatch(showBanner({ variant: 'error', message: 'something went wrong' }));
      }
      setEnableSave(false);
      setEditedRows([]);
    }
  };

  const getconfirmationBeforeSave = () => {
    setConfirmAlert({
      enabled: true,
      title: 'Do you want to Save the Data?',
      message:
        'Please check the information before submitting as the edits/deletes cannot be retrieved once the data is saved',
      variant: 'warning',
      onConfirm: () => {
        onSave();
        setConfirmAlert({
          enabled: false
        });
      },
      onCancel: () => {
        setOpenConfirmModal(false);
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  useEffect(() => {
    if (openConfirmModal) {
      getconfirmationBeforeSave();
    }
  }, [openConfirmModal]);

  const getErrorData = (getData, row, key) => {
    return (
      <Tooltip title={getValidTitle(getData)}>
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            backgroundColor: '#f9cccc',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key] === '' ? 'No Data' : row[key]}
        </Typography>
      </Tooltip>
    );
  };

  const getTextField = (
    row,
    key,
    getData,
    getDataCordinates,
    formatedFormData,
    formatedCDRTabular
  ) => {
    if (row.editMode && key === 'edccondition' && Object.keys(getData).length > 0) {
      return (
        <CustomeTextField
          id="expression"
          isCtpCard={true}
          onChange={(value) => row.editRow(row.id, key, value)}
          name="expression"
          data-testid="expression-input"
          error={true}
          helperText={getValidTitle(getData)}>
          {generateErrorString(
            row[key],
            getDataCordinates?.cordinates?.start - 4,
            getDataCordinates?.cordinates?.end - 4
          )}
        </CustomeTextField>
      );
    } else if (row.editMode && key === 'edccondition' && formatedFormData.length > 0) {
      return (
        <CustomeTextField
          id="expression"
          isCtpCard={true}
          onChange={(value) => row.editRow(row.id, key, value)}
          name="expression"
          data-testid="expression-input">
          {getHtmlString(row[key], formatedFormData, row['edcdefaultform'])}
        </CustomeTextField>
      );
    } else if (row.editMode && key === 'cdrcondition' && Object.keys(getData).length > 0) {
      return (
        <CustomeTextField
          id="expression"
          isCtpCard={true}
          onChange={(value) => row.editRow(row.id, key, value)}
          name="expression"
          data-testid="expression-input"
          error={true}
          helperText={getValidTitle(getData)}>
          {generateErrorString(
            row[key],
            getDataCordinates?.cordinates?.start - 4,
            getDataCordinates?.cordinates?.end - 4
          )}
        </CustomeTextField>
      );
    } else if (row.editMode && key === 'cdrcondition' && formatedCDRTabular.length > 0) {
      return (
        <CustomeTextField
          id="expression"
          isCtpCard={true}
          onChange={(value) => row.editRow(row.id, key, value)}
          name="expression"
          data-testid="expression-input">
          {getHtmlString(row[key], formatedCDRTabular, row['cdrdefaultform'])}
        </CustomeTextField>
      );
    } else if (row.editMode) {
      return (
        <TextField
          size="small"
          fullWidth
          value={row[key]}
          onChange={(e) => row.editRow(row.id, key, e.target.value)}
          {...fieldStyles}
          {...(Object.keys(getData).length > 0
            ? {
                helperText: getValidTitle(getData),
                error: true
              }
            : {})}
        />
      );
    } else if (Object.keys(getData).length > 0) {
      return getErrorData(getData, row, key);
    } else if (key === 'edccondition' && formatedFormData.length > 0) {
      return (
        <CustomeTextField
          id="expression"
          disabled={true}
          isCtpCard={true}
          name="expression"
          data-testid="expression-input">
          {getHtmlString(row[key], formatedFormData, row['edcdefaultform'])}
        </CustomeTextField>
      );
    } else if (key === 'cdrcondition' && formatedCDRTabular.length > 0) {
      return (
        <CustomeTextField
          id="expression"
          disabled={true}
          isCtpCard={true}
          name="expression"
          data-testid="expression-input">
          {getHtmlString(row[key], formatedCDRTabular, row['cdrdefaultform'])}
        </CustomeTextField>
      );
    } else {
      return (
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key]}
        </Typography>
      );
    }
  };

  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      const getData =
        ctpSvtValidationResult && Object.keys(ctpSvtValidationResult).length > 0
          ? ctpSvtValidationResult.isColumnValid(row['id'], key)
          : {};
      const getDataCordinates =
        ctpSvtValidationResult &&
        Object.keys(ctpSvtValidationResult).length &&
        ctpSvtValidationResult.isExpressionValid(row['id'], key);
      return getTextField(
        row,
        key,
        getData,
        getDataCordinates,
        formatedFormData,
        formatedCDRTabular
      );
    },
    [ctpSvtValidationResult, formatedCDRTabular, formatedFormData]
  );

  const CustomButtonHeader = useCallback((props) => {
    const {
      editMode,
      onCancel,
      onEditAll,
      toggleFilters,
      ctpSvtData,
      setIsPreview,
      setIsAdding,
      isAdding,
      setConfirmAlert,
      isPreviewDataUploaded
    } = props;

    const onHandleDownload = () => {
      let newFileArr = [];
      ctpSvtData.map((obj) => {
        delete obj['id'];
        let upperObj = _.transform(obj, function (result, val, key) {
          result[key.toUpperCase()] = val;
        });
        newFileArr.push(upperObj);
      });
      jsonToExcel(newFileArr, 'CtpSvt.xlsx');
    };

    const returnToReferenceData = () => {
      if (isPreviewDataUploaded) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Uploaded data would not saved',
          variant: 'warning',
          onConfirm: () => {
            setReferenceData('ctp-svt', []);
            navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else if (errorCount) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Added or Edited data would not be saved',
          variant: 'warning',
          onConfirm: () => {
            navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else {
        navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/reference-data`);
        setIsPreview(false);
      }
    };

    const handleErrorData = (e) => {
      setErrorFlag(e.target.checked);
    };

    return (
      <>
        <div style={{ width: '100%' }}>
          <div>
            <Button
              icon={<ChevronLeft />}
              size="small"
              onClick={returnToReferenceData}
              style={{
                marginRight: 10,
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Return to reference data upload
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 600,
                      fontSize: '20px',
                      float: 'left',
                      marginRight: '20px'
                    }}>
                    {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
                  </Typography>
                  {errorCount > 0 && (
                    <div
                      style={{
                        backgroundColor: '#E20000',
                        float: 'left',
                        width: '83px',
                        paddingBottom: '2px',
                        paddingLeft: '2px',
                        border: '1px solid white',
                        borderRadius: '4px'
                      }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                        <span
                          style={{
                            color: 'white'
                          }}>
                          <StatusExclamation
                            style={{
                              color: 'white',
                              float: 'left',
                              height: '16px',
                              marginTop: '2px'
                            }}
                          />
                          {`${errorCount} errors`}
                        </span>
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={9} style={{ marginRight: '4px' }}>
                    {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                  </Grid>

                  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="secondary" size="small" onClick={toggleFilters}>
                      <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} />{' '}
                      Filter
                    </Button>
                    <Button size="small" onClick={onHandleDownload} style={{ color: 'black' }}>
                      <Download />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <hr style={{ margin: '22px 0px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
                variant="subtitle"
                gutterBottom>
                {errorFlag ? 'Show only rows with errors' : 'Show All'}
              </Typography>
              <Switch
                size={'small'}
                data-testid={'switch-btn'}
                checked={errorFlag}
                onChange={handleErrorData}
              />
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={isAdding || editMode}
                size="small"
                variant="secondary"
                icon={PlusIcon}
                onClick={() => setIsAdding((adding) => !adding)}
                style={{ marginRight: 8 }}>
                {'Add row'}
              </Button>
              {!editMode && (
                <Button size="small" variant="primary" onClick={onEditAll}>
                  {'Edit all'}
                </Button>
              )}
              {editMode && (
                <Button size="small" onClick={onCancel}>
                  {'Cancel'}
                </Button>
              )}
              {(enableSave || editMode || isPreviewDataUploaded) && (
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  variant="primary"
                  onClick={() => setOpenConfirmModal(true)}>
                  {'Save'}
                </Button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  });

  const ActionCell = useCallback(({ row: { id, editMode, onDelete } }) => {
    let isDisabled = ctpSvtData.length < 2;
    return (
      editMode || (
        <Tooltip title={!isDisabled ? 'Delete' : 'Table cannot be empty'} disableFocusListener>
          <Button
            size="small"
            style={isDisabled ? { cursor: 'not-allowed' } : {}}
            onClick={!isDisabled ? () => onDelete(id) : null}>
            <Trash
              style={
                isDisabled
                  ? {
                      color: 'grey'
                    }
                  : {
                      color: 'black'
                    }
              }
            />
          </Button>
        </Tooltip>
      )
    );
  });
  const columns = useMemo(
    () => [
      {
        header: 'VISITTYPE',
        accessor: 'visittype',
        filterComponent: TextFieldFilter,
        filterFunction: numberSearchFilter('visittype'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'VISITNAME',
        accessor: 'visitname',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('visitname'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'VISITNUM',
        accessor: 'visitnum',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('visitnum'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'GRPID',
        accessor: 'grpid',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('grpid'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'CLRID',
        accessor: 'clrid',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('clrid'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'UPDES',
        accessor: 'updes',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('updes'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'EDC',
        accessor: 'edc',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('edc'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'CDR',
        accessor: 'cdr',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('cdr'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'CMPLTD_FLG',
        accessor: 'cmpltd_flg',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('cmpltd_flg'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'CASCADE_FLG',
        accessor: 'cascade_flg',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('cascade_flg'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'INTERFACE',
        accessor: 'interface',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('interface'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },

      {
        header: 'EDCDEFAULTFORM',
        accessor: 'edcdefaultform',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('edcdefaultform'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'EDCCONDITION',
        accessor: 'edccondition',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('edccondition'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'CDRDEFAULTFORM',
        accessor: 'cdrdefaultform',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('cdrdefaultform'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'CDRCONDITION',
        accessor: 'cdrcondition',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('cdrcondition'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        accessor: 'action',
        customCell: ActionCell,
        align: 'right'
      }
    ],
    [EditableCell, ActionCell, ctpSvtValidationResult]
  );

  const onEditAll = () => {
    setEditedRows(rows);
  };

  const onCancel = () => {
    setEditedRows([]);
  };

  const onDelete = async (id) => {
    const updatedRows = ctpSvtData.filter((row) => row.id !== id);
    const validationResult = await ctpSvtValidator(
      updatedRows,
      dataSourceData,
      ctmsVisitsData,
      codeListDataMapping,
      formsObj,
      cdrTabular
    );
    setCtpSvtValidationResult(validationResult);
    setErrorRowId(validationResult.getErrorRowIndex());
    setErrorCount(validationResult.getErrorCount());

    setCtpSvtData(updatedRows);
    setEnableSave(true);
  };
  const enteredValue = (row, key, value) => {
    const obj = { ...row, [key]: value };
    let convertedObj = convertToUpper(obj);
    return convertedObj;
  };
  const editRow = (id, key, value) => {
    setEditedRows((ctpSvtData) =>
      ctpSvtData.map((row) => (row.id === id ? enteredValue(row, key, value) : row))
    );
  };

  const onAddNewRowSave = async () => {
    const copyCtpSvtData = [...ctpSvtData];
    if (newRow && Object.keys(newRow).length > 0) {
      const newRowModified = convertToUpper(newRow);
      const newRowData = { ...newRowModified, id: uuidv4() };
      const obj = { ...mapping_obj, ...newRowData };
      copyCtpSvtData.unshift(obj);
      const validationResult = await ctpSvtValidator(
        copyCtpSvtData,
        dataSourceData,
        ctmsVisitsData,
        codeListDataMapping,
        formsObj,
        cdrTabular
      );
      setCtpSvtValidationResult(validationResult);
      setCtpSvtData(copyCtpSvtData);
      setRows(copyCtpSvtData);
      if (validationResult?.getErrorCount() === 0) {
        setOpenConfirmModal(true);
      }
    }
  };

  return (
    <>
      {isLoading || loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={(editMode ? editedRows : rows).map((row) => ({
                ...row,
                onDelete,
                editRow,
                editMode,
                key: row.id
              }))}
              initialSortedColumn="visitnum"
              initialSortOrder="asc"
              page={resetPage ? 0 : undefined}
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                //   labelDisplayedRows: ({ from, to, count }) =>
                //     `${count === 1 ? 'Employee' : 'Employees'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={CustomButtonHeader}
              headerProps={{
                onEditAll,
                onSave,
                onCancel,
                editMode,
                ctpSvtData,
                // handleStepValue,
                // handleShowPreview,
                setIsPreview,
                setIsAdding,
                isAdding,
                setConfirmAlert,
                isPreviewDataUploaded
              }}
              CustomSubHeader={() => (
                <TableNewRow
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  onSave={onAddNewRowSave}
                  newRow={newRow}
                  editNewRow={editNewRow}
                  setNewRow={setNewRow}
                  columns={columns}
                />
              )}
            />
          </Card>
          <CustomModal
            display={confirmAlert.enabled}
            title={confirmAlert.title}
            message={confirmAlert.message}
            body={confirmAlert.body}
            variant={confirmAlert.variant}
            buttonPrimaryLabel={'Ok'}
            handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
            buttonSecondardyLabel={'Cancel'}
            handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
          />
        </>
      )}
    </>
  );
};

export default CtpSvtPreview;
