import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import ChartBar from 'apollo-react-icons/ChartBar';
import FilterIcon from 'apollo-react-icons/Filter';
import { neutral7 } from 'apollo-react/colors';
import ApolloProgress from 'apollo-react/components/ApolloProgress';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Table from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Loader from 'Components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCDR,
  getEdcOdmCodelist,
  getEdcOdmForms,
  getEdcOdmMeasure,
  getEdcOdmVisits,
  getQECG,
  getQLAB
} from 'Redux/Service/PreviewService';
import { setDisableFinishButton } from 'Redux/Slice/AddStudyLibrarySlice';
import { showBanner } from 'Redux/Slice/BannerSlice';
import {
  CODE_LISTS,
  VISITS,
  CRF_METADATA,
  MEASUREMENT_UNITS,
  QECG,
  Q2LAB,
  CDR_TABULAR
} from './constants';

const useStyles = makeStyles({
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  }
});

const EmptyTableTypographyStyle = { color: neutral7, lineHeight: '24px' };
const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};

const EmptyTableComponent = () => (
  <>
    <ChartBar fontSize="large" style={EmptyTableTypographyStyle} />
    <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
      No data to display
    </Typography>
  </>
);

const CustomButtonHeader = ({ toggleFilters }) => {
  const studyObj = useSelector((state) => state.AddStudyLibrary.studyObj);
  const metadataVersionData =
    studyObj.studyMetadataVersion.length > 0 ? studyObj.studyMetadataVersion.join(',') : [];
  return (
    <>
      <div style={{ width: '50%' }}>
        <p
          style={{
            color: '#000000',
            fontSize: '16px',
            width: '100%',
            fontWeight: 600
          }}>
          {studyObj.name}
        </p>
        {studyObj.type === 'CDISC ODM' && (
          <p style={{ width: '100%' }}>{`Metadataversion: ${metadataVersionData}`}</p>
        )}
      </div>
      <div style={{ width: '50%', textAlign: 'right' }}>
        <Button
          size="small"
          variant="secondary"
          icon={FilterIcon}
          style={{ marginRight: 15 }}
          onClick={toggleFilters}>
          Filter
        </Button>
      </div>
    </>
  );
};

const PreviewTable = (props) => {
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const studyObj = useSelector((state) => state.AddStudyLibrary.studyObj);
  const studyId = studyObj.studyId.length > 0 ? studyObj.studyId : [];
  const studyVersion = studyObj.studyVersion.length > 0 ? studyObj.studyVersion : [];
  const [loading, setLoading] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [updating, setUpdating] = useState(false);
  const screenSizeRef = useRef(null);
  const { rowDataInfo, CDRDatasetType,eCRFTrialName } = props;
  const { sortOrder, pageNumber, sortedColumn, rowsCount, paginationFilter } = props.paginationData;
  const { setRowsCount, setSortOrder, setSortedColumn, setPageNumber, setPaginationFilter } =
    props.paginationFunctions;
  const types = [CODE_LISTS, VISITS, CRF_METADATA, MEASUREMENT_UNITS, QECG, Q2LAB, CDR_TABULAR];
  const isTestFlow = props.isTestFlow;
  const rowsPerPageOptionsDisplay =
    totalRowCount > 2000 ? [10, 20, 50, 100, 1000] : [10, 20, 50, 100, 'All'];

  const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        fullWidth
        // style={{ width: 160 }}
        margin="none"
        size="small"
      />
    );
  };

  function sortDataFunction(data, accessor, sortOrder) {
    return data.sort((rowA, rowB) => {
      let stringA = rowA[accessor] ? rowA[accessor].toUpperCase() : '';
      let stringB = rowB[accessor] ? rowB[accessor].toUpperCase() : '';
      if (sortOrder === 'asc') {
        if (stringA < stringB) {
          return -1;
        }
        if (stringA > stringB) {
          return 1;
        }
        return 0;
      } else {
        if (stringA < stringB) {
          return 1;
        }
        if (stringA > stringB) {
          return -1;
        }
        return 0;
      }
    });
  }
  function compareStrings(accessor, sortOrder) {
    return function (rowA, rowB) {
      let stringA = rowA[accessor] ? rowA[accessor].toUpperCase() : '';
      let stringB = rowB[accessor] ? rowB[accessor].toUpperCase() : '';
      if (sortOrder === 'asc') {
        if (stringA < stringB) {
          return -1;
        }
        if (stringA > stringB) {
          return 1;
        }
        return 0;
      } else {
        if (stringA < stringB) {
          return 1;
        }
        if (stringA > stringB) {
          return -1;
        }
        return 0;
      }
    };
  }

  function createStringSearchFilter(accessor) {
    return function (row, filters) {
      let rowVal = row[accessor] ? row[accessor].toUpperCase() : '';
      let filterVal = filters[accessor] ? filters[accessor].toUpperCase() : '';
      return rowVal.includes(filterVal);
    };
  }

  const formatHeader = (data) => {
    let formattedData = [];
    let newData = data.split(/(?=[A-Z])/);
    newData.map((el, index) => {
      if (index === 0) {
        formattedData.push(el.charAt(0).toUpperCase() + el.slice(1));
      } else {
        formattedData.push(el);
      }
    });
    return formattedData.join(' ');
  };

  const getPayloadByType = (type) => {
    let payload = {};
    switch (type) {
      case VISITS:
      case CRF_METADATA:
      case MEASUREMENT_UNITS:
      case CODE_LISTS:
        payload.protocolName = protocol.protocolNumber;
        payload.studyID = studyId[0];
        payload.studyVersions = studyVersion;
        payload.pageNumber = pageNumber + 1;
        payload.pageSize = rowsCount === 'All' ? totalRowCount : rowsCount;
        payload.filters = paginationFilter;
        return payload;
      case QECG:
      case Q2LAB:
        payload.protocolID = protocol.protocolNumber;
        payload.pageNumber = pageNumber + 1;
        payload.pageSize = rowsCount === 'All' ? totalRowCount : rowsCount;
        payload.filters = paginationFilter;
        payload.trialName = rowDataInfo;
        return payload;
      case CDR_TABULAR:
        payload.protocolID = protocol.protocolNumber;
        payload.dataSetName = studyObj.source.filter((item) => item !== 'Select All');
        payload.pageNumber = pageNumber + 1;
        payload.pageSize = rowsCount === 'All' ? totalRowCount : rowsCount;
        payload.filters = paginationFilter;
        payload.isTestFlow = isTestFlow;
        payload.dataSetTypeID = CDRDatasetType;
        payload.ecrfTrialName = eCRFTrialName;
        return payload;
    }
  };

  const updateFilter = (data) => {
    const updatedData = Object.keys(data).map((key) => {
      return {
        columnName: key,
        text: data[key]
      };
    });
    setPaginationFilter(updatedData);
  };

  const generateColumnsRows = (res) => {
    let columnArr = [];
    let emptArr = [];
    let ignoreColumns = ['studyLibraryID', 'studyLibraryVersion', 'createDate', 'userID'];
    if (res.length !== 0) {
      Object.keys(res[0]).forEach((key) => {
        if (!ignoreColumns.includes(key)) {
          columnArr.push({
            header: formatHeader(key),
            accessor: key,
            minWidth: '100',
            sortFunction: compareStrings,
            fixedWidth: false,
            filterComponent: TextFieldFilter,
            filterFunction: createStringSearchFilter(key)
          });
        }
      });
      setColumns(columnArr);
      res.map((el) => {
        for (let key in el) {
          el[key] = el[key] === '' || el[key] === null ? '-' : el[key].toString();
        }
        emptArr.push(el);
      });
      setRowData(emptArr);
    } else {
      setColumns([]);
      setRowData([]);
      setLoading(false);
    }
  };

  const getAPICallResponse = (type) => {
    switch (type) {
      case VISITS:
        return getEdcOdmVisits(getPayloadByType(type));
      case CRF_METADATA:
        return getEdcOdmForms(getPayloadByType(type));
      case MEASUREMENT_UNITS:
        return getEdcOdmMeasure(getPayloadByType(type));
      case CODE_LISTS:
        return getEdcOdmCodelist(getPayloadByType(type));
      case QECG:
        return getQECG(getPayloadByType(type));
      case Q2LAB:
        return getQLAB(getPayloadByType(type));
      case CDR_TABULAR:
        return getCDR(getPayloadByType(type));
    }
  };

  const getResponseByType = (type, response) => {
    switch (type) {
      case VISITS:
        return response.edcOdmVisits;
      case CRF_METADATA:
        return response.edcOdmForms;
      case MEASUREMENT_UNITS:
        return response.measurements;
      case CODE_LISTS:
        return response.codeLists;
      case QECG:
        return response.ecgVisits;
      case Q2LAB:
        return response.qlapVisits;
      case CDR_TABULAR:
        return response.cdrDataSetColumns;
    }
  };

  useEffect(() => {
    if (types.includes(props.data)) {
      dispatch(setDisableFinishButton(true));
      setLoading(true);
      let getData = getAPICallResponse(props.data);
      getData
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          setLoading(false);
          if (response.success) {
            setTotalRowCount(response.totalCount);
            generateColumnsRows(getResponseByType(props.data, response));
            dispatch(setDisableFinishButton(false));
          } else {
            dispatch(showBanner({ variant: 'error', message: response.message }));
          }
        })
        .catch((err) => {
          dispatch(showBanner({ variant: 'error', message: err.message }));
        });
    }
  }, [props.data]);

  useEffect(() => {
    if (columns.length > 0 && types.includes(props.data)) {
      let ResponseData = getAPICallResponse(props.data);
      setUpdating(true);
      ResponseData.then((res) => {
        return res.json();
      })
        .then((response) => {
          if (response.success) {
            setUpdating(false);
            setTotalRowCount(response.totalCount);
            let res = getResponseByType(props.data, response);
            let emptArr = [];
            if (res.length !== 0) {
              res.map((el) => {
                for (let key in el) {
                  el[key] = el[key] === '' || el[key] === null ? '-' : el[key].toString();
                }
                emptArr.push(el);
              });
              if (sortedColumn !== undefined) {
                setRowData(sortDataFunction(emptArr, sortedColumn, sortOrder));
              } else {
                setRowData(emptArr);
              }
            } else {
              setRowData([]);
              setUpdating(false);
            }
          } else {
            dispatch(showBanner({ variant: 'error', message: response.message }));
          }
        })
        .catch((err) => {
          dispatch(showBanner({ variant: 'error', message: err.message }));
        });
    }
  }, [pageNumber, rowsCount, sortOrder, sortedColumn, paginationFilter]);

  return (
    <Box mt={2} className={classes.tableHorizontalOverflow}>
      {loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        columns.length > 0 && (
          <Card ref={screenSizeRef}>
            {updating && (
              <div
                style={{
                  width: `${screenSizeRef.current?.offsetWidth}px`,
                  height: `${screenSizeRef.current?.offsetHeight}px`,
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: '9',
                  backgroundColor: 'rgba(255,255,255,0.6)'
                }}>
                <ApolloProgress />
              </div>
            )}
            <Table
              classes={classes.tableHorizontalOverflow}
              columns={columns}
              rows={rowData}
              CustomHeader={(props) => <CustomButtonHeader {...props} />}
              size={totalRowCount}
              sortOrder={sortOrder}
              sortedColumn={sortedColumn}
              page={pageNumber}
              filterDelay={1500}
              rowsPerPage={rowsCount}
              initialSortedColumn={columns[1].accessor}
              initialSortOrder="asc"
              rowsPerPageOptions={rowsPerPageOptionsDisplay}
              tablePaginationProps={{
                labelDisplayedRows: DisplayedRowsLabel,
                truncate: true
              }}
              onChange={(args) => {
                setRowsCount(args.rowsPerPage);
                setSortedColumn(args.sortedColumn);
                updateFilter(args.filters);
                setSortOrder(args.sortOrder);
                setPageNumber(args.page);
              }}
              defaultRowsPerPage={10}
              hasScroll
              maxHeight={650}
              emptyProps={{
                text: <EmptyTableComponent />
              }}
            />
          </Card>
        )
      )}
    </Box>
  );
};

export default PreviewTable;
