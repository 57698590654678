/* eslint-disable */
import NavigationBar from 'apollo-react/components/NavigationBar';
import Typography from 'apollo-react/components/Typography';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Cookies from 'js-cookie';
import HelpIcon from '@mui/icons-material/Help';
import { logout } from 'Redux/Slice/BreadcrumbSlice';
import { setClosePanelText } from 'Redux/Slice/StudyLibrarySlice';
import { Logout } from 'Redux/Service/LogoutService';
import AppSwitcher from './AppSwitcher/AppSwitcher';
import { hasNoAccess } from 'service/AppPermissions';
import { makeStyles } from '@mui/styles';
import { TIMEZONES_MAPPING } from 'Constants/Timezones';

const useStyles = makeStyles({
  toolbar: {
    width: '100%'
  }
});

const JS_VOID = 'javascript:void(0);';
const HELP_LINK = process.env.REACT_APP_HELP_LINK;

const Header = (props) => {
  const navigate = useNavigate();
  const userRoleType = 'ADMIN';
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();

  const navigateData = (data) => {
    let path;
    dispatch(setClosePanelText(true));
    if (data === 'Data Standard Libraries') {
      let ls = JSON.parse(localStorage.getItem('state'));
      ls.StudyLibraryData = {};
      localStorage.setItem('state', JSON.stringify(ls));
      path = '/data-standard-library/study-library';
    } else if (data === 'Data Product Designer') {
      path = '/product-designer';
    } else if (data === 'Dashboard') {
      path = '/dashboard';
    } else if (data === 'Manage Data Pipelines') {
      path = '/manage-data-pipeline';
    }

    path && navigate(path);
  };

  const loginFormDetails = useSelector((state) => state.LoginFormData.userLoginID);

  const navBarOBj = {
    clinicalDataMapper: {
      name: 'Dashboard',
      filter: location?.pathname?.startsWith('/dashboard')
    },
    dataStandardLibrary: {
      name: 'Data Standard Libraries',
      filter: location?.pathname?.startsWith('/data-standard-library')
    },
    dataProductDesigner: {
      name: 'Data Product Designer',
      filter: location?.pathname?.startsWith('/product-designer')
    },
    manageDataPipelines: {
      name: 'Manage Data Pipelines',
      filter: location?.pathname?.startsWith('/manage-data-pipeline')
    }
  };

  let keysOfNavBar = Object.keys(navBarOBj);
  const menuItems = [];

  keysOfNavBar.forEach((item) => {
    if (!hasNoAccess('application', item) || navBarOBj[item].name === 'Dashboard') {
      menuItems.push({
        text: navBarOBj[item].name,
        href: JS_VOID,
        onClick: () => {
          navigateData(navBarOBj[item].name);
        },
        active: navBarOBj[item].filter
      });
    }
  });

  const gotoLoginPage = async () => {
    let path = '/logout';
    dispatch(logout());
    navigate(path, {
      state: {
        logout: true
      }
    });
    await Logout();
  };

  const getUserName = () => {
    const FIRST_NAME = Cookies.get('user.first_name');
    const LAST_NAME = Cookies.get('user.last_name');
    if (userRoleType === 'RO') {
      return `${
        Object.keys(Cookies.get()).length > 0
          ? `${FIRST_NAME} ${LAST_NAME}`
          : `${loginFormDetails} (Read Only)`
      }`;
    } else if (userRoleType === 'RW') {
      return `${
        Object.keys(Cookies.get()).length > 0
          ? `${FIRST_NAME} ${LAST_NAME}`
          : `${loginFormDetails} (Read Write)`
      }`;
    } else if (userRoleType === 'ADMIN') {
      return `${
        Object.keys(Cookies.get()).length > 0
          ? `${FIRST_NAME} ${LAST_NAME}`
          : `${loginFormDetails} (Admin User)`
      }`;
    }
  };

  const getLoginDate = () => {
    const date = moment(Cookies.get('user.last_login_ts'));
    const isDateValid = date.isValid();
    console.log('>>>>>>>. ', isDateValid);
    return isDateValid ? moment(date).format('LLL') : moment().format('LLL');
  };

  const profileMenuProps = {
    name: getUserName(),
    title: Cookies.get('user.email') ? Cookies.get('user.email') : 'User Info',
    email: Cookies.get('user.last_login_ts')
      ? `Last Login : ${getLoginDate()} ${
          TIMEZONES_MAPPING[Intl.DateTimeFormat().resolvedOptions().timeZone]
        }`
      : loginFormDetails,
    logoutText: 'Log out',
    menuItems: [],
    onClick: () => gotoLoginPage()
  };

  const onHelpClick = () => {
    console.log('Opening expernal Help link in new tab ::', HELP_LINK);
    HELP_LINK && window.open(HELP_LINK, '_blank');
  };
  return (
    <>
      <NavigationBar
        LogoComponent={() => (
          <>
            <AppSwitcher />
            <Link to="/dashboard" style={{ textDecoration: 'none' }}>
              <Typography
                style={{
                  color: 'white',
                  lineHeight: '56px',
                  marginRight: 24,
                  zIndex: 2,
                  whiteSpace: 'nowrap'
                }}
                data-testid="dashboard">
                IQVIA™ <span style={{ fontWeight: 600 }}>Clinical Data Mapper</span>
              </Typography>
            </Link>
          </>
        )}
        menuItems={menuItems}
        otherButtons={
          <HelpIcon
            onClick={() => onHelpClick()}
            data-testid="help-icon"
            style={{ marginTop: '15px', marginRight: '10px', cursor: 'pointer' }}
          />
        }
        profileMenuProps={profileMenuProps}
        waves
        data-testid="navigation-bar"
        classes={classes}
      />
      <Box style={{ marginBottom: '56px' }} />
    </>
  );
};

export default React.memo(Header);
