import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Table, { createStringSearchFilter } from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import moment from 'moment';
import { compareStrings } from '../../RuleEditor/SelectDataSource/MappingSpecComponents/utilites';

const DateCell = ({ row, column: { accessor } }) => {
  const rowValue = row[accessor];
  const date =
    rowValue && moment(rowValue, 'M/DD/YYYY').isValid()
      ? moment(rowValue, 'M/DD/YYYY').format('M/D/YYYY')
      : rowValue;
  return <span>{date}</span>;
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const CustomButtonHeader = (props) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="title1" gutterBottom>
          Mastered Visits
        </Typography>

        <Typography variant="body2" gutterBottom>
          {props.domainCode}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="secondary" icon={Filter} onClick={props.toggleFilters} size={'small'}>
            Filter
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

const MasteredVisitsTable = (props) => {
  const { domainCode, visitMasteredData } = props;

  //dynamic columns for visit data
  const columns = visitMasteredData?.length
    ? Object.keys(visitMasteredData[0]).map((column) => {
        if (column === 'BRTHDTC') {
          return {
            header: column || '',
            accessor: column.toLowerCase(),
            sortFunction: compareStrings,
            filterComponent: TextFieldFilter,
            filterFunction: createStringSearchFilter(column.toLowerCase()),
            customCell: DateCell,
            width: 300,
            fixedWidth: false
          };
        } else {
          return {
            header: column || '',
            accessor: column.toLowerCase(),
            sortFunction: compareStrings,
            filterComponent: TextFieldFilter,
            filterFunction: createStringSearchFilter(column.toLowerCase()),
            width: 300,
            fixedWidth: false
          };
        }
      })
    : [];

  let currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const rowData = visitMasteredData?.length
    ? visitMasteredData.map((item) => {
        let newObj = {};
        Object.keys(item).forEach(
          (row) => (newObj[row.toLowerCase()] = item[row] === null ? '' : item[row].toString())
        );
        return newObj;
      })
    : [];

  return (
    <Table
      columns={columns}
      rows={rowData.map((row) => ({
        ...row
      }))}
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      // initialSortedColumn={
      //   rowData?.length ? (columns.length > 0 ? columns[0].accessor : '') : columns[0].accessor
      // }
      initialSortedColumn={''}
      initialSortOrder="asc"
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      CustomHeader={(prop) => (
        <CustomButtonHeader {...prop} currentPath={currentPath} domainCode={domainCode} />
      )}
    />
  );
};

export default MasteredVisitsTable;
