const methodsAndConstantsMapping = {
  'Min()': '.Min()',
  'Max()': '.Max()',
  'Min(SourceVisitName)': '.Min(SourceVisitName)',
  'Max(SourceVisitName)': '.Max(SourceVisitName)',
  'Choose()': '.Choose(0,0)',
  'DataEntryDateTime()': '.DataEntryDateTime()',
  'DatePart()': '.DatePart()',
  'Decode()': '.Decode()',
  'Decode(sdm)': '.Decode(SDM)',
  'Upcase()': '.Upcase()',
  'Substr()': '.Substr(0,0)',
  'Units()': '.Units()',
  'Units(text)': '.Units(TEXT|NAME)',
  'TimePart()': '.TimePart()',
  'ODMEventName()': '.ODMEventName()',
  'Pad()': '.Pad(0,0,LEFT|RIGHT)',
  'SpecifyValue()': '.SpecifyValue()',
  'Remove()': '.Remove(0,..args)',
  'Replace()': '.Replace("str1","str2")',
  'DoesValueExist()': '.DoesValueExist("visitname", "Visitvalue", "Operator")',
  'Last()': '.Last(0)',
  'ODMEventCreateDate()': '.ODMEventCreateDate()',
  'ODMEventSequence()': '.ODMEventSequence(0)',
  'Length()': '.Length()',
  'DoesVisitExist()': '.DoesVisitExist("visitName")',
  'ISODATETIME()': '.ISODATETIME("date")',
  'DecodeSRC()': '.DecodeSRC()',
  'DecodeModifiedText()': '.DecodeModifiedText()',
  DOBMATCH: '"DOBMATCH"',
  $STUDYID: '"$STUDYID"',
  $FALSE: '"$FALSE"',
  $TRUE: '"$TRUE"',
  $AND: '"$AND"',
  '$QCVSEQ[]': '"$QCVSEQ[]"',
  $GRPID: '"$GRPID"',
  $MAXVISITDY: '"$MAXVISITDY"',
  $MONTHYEAR: '"$MONTHYEAR"',
  $NULL: '"$NULL"',
  $QCVSEQ: '"$QCVSEQ"',
  $VISIT: '"$VISIT"',
  $YEAR: '"$YEAR"',
  $OR: '"$OR"',
  $DAYMONTHYEAR: '"$DAYMONTHYEAR"',
  QCVSEQ: '"QCVSEQ"',
  $SYSMAP: '"$SYSMAP"',
  $NOMAP: '"$NOMAP"',
  $USUBJID: '"$USUBJID"'
};

export { methodsAndConstantsMapping };
