import Box from 'apollo-react/components/Box';
import Card from 'apollo-react/components/Card';
import Divider from 'apollo-react/components/Divider';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import Paper from 'apollo-react/components/Paper';
import Popover from 'apollo-react/components/Popover';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AutomapOutline from 'Images/automap-outline.svg';
import AutomapSolid from 'Images/automap-solid.svg';

const useStyles = makeStyles({
  autoMapButton: { cursor: 'pointer' },
  layout: {
    width: '760px',
    margin: '0px',
    padding: '0px',
    backgroundColor: '#F4F8FF',
    boxShadow: 'none'
  },
  headerLayout: {
    marginTop: '12px',
    marginBottom: '10px',
    marginLeft: '10px'
  },
  divider: {
    margin: '0px'
  },
  tabsLayout: {
    margin: '2px 10px 10px 10px'
  },
  expressionLayout: {
    height: '230px',
    overflowY: 'auto',
    paddingTop: '8px'
  },
  expressionCard: {
    marginBottom: '5px',
    paddingBottom: 0,
    cursor: 'pointer',
    border: '1px solid transparent',
    '&:hover': {
      borderColor: '#0768FD'
    }
  },
  scoreBadge: {
    background: '0768FD',
    color: '#444444',
    position: 'relative',
    borderRadius: '7.5px',
    width: '39px',
    padding: '1px 0px 1px 0px',
    fontSize: '13px',
    display: 'inline-block',
    textAlign: 'center'
  },
  tooltip: {
    maxWidth: 'none'
  }
});

const AUTOMAP_POPOVER_HEADER_TITLE = 'Suggestions by AI assist';
const AUTOMAP_POPOVER_HEADER_SUBTITLE =
  'Automapping predicts most commonly used source items and its top three expressions for each Domain Variable if available.';

const getRangeForScore = (score) => (score < 40 ? 'low' : score < 80 ? 'moderate' : 'high');
const getBadgeColorForScore = (score) =>
  score < 40 ? '#FBE0E0' : score < 80 ? '#FFEED6' : '#B3ECBC';

function AutoMappingPopOver(props) {
  const { predictions, onSelectHandler, popOverPosition } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChangeTab = (event, val) => {
    setValue(val);
  };

  const EpressionCard = ({ expression, score }) => {
    return (
      <Card
        interactive
        className={classes.expressionCard}
        tabIndex={0}
        onClick={() => {
          onSelectHandler(expression);
          setAnchorEl(null);
        }}>
        <Grid container spacing={1.2} style={{ padding: '8px' }}>
          <Grid item xs={12}>
            <Tooltip
              title={`This expression has ${getRangeForScore(score)} confidence score`}
              placement="right"
              classes={{ tooltip: classes.tooltip }}>
              <span
                className={classes.scoreBadge}
                style={{
                  background: getBadgeColorForScore(score)
                }}>
                {score}
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">
              {expression}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  };
  return (
    <>
      <IconButton
        color="primary"
        className={classes.autoMapButton}
        data-testid="automap-icon"
        onClick={(e) => setAnchorEl(e.currentTarget)}>
        {anchorEl ? <img src={AutomapSolid} /> : <img src={AutomapOutline} />}
      </IconButton>

      {anchorEl && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          data-testid="automap-popover"
          PaperProps={{
            style: {
              borderColor: 'transparent',
              boxShadow: 'none',
              paddingBottom: '8px',
              backgroundColor: 'transparent'
            }
          }}
          anchorOrigin={
            popOverPosition?.anchorOrigin || {
              vertical: 'top',
              horizontal: 'right'
            }
          }
          transformOrigin={
            popOverPosition?.transformOrigin || {
              vertical: 'bottom',
              horizontal: 'right'
            }
          }>
          <Paper className={classes.layout}>
            <Box className={classes.headerLayout}>
              <Typography
                variant="title1"
                style={{ fontSize: '16px', fontWeight: 700, marginBottom: '3px' }}>
                {AUTOMAP_POPOVER_HEADER_TITLE}
              </Typography>
              <Typography variant="subtitle" style={{ fontSize: '13px' }}>
                {AUTOMAP_POPOVER_HEADER_SUBTITLE}
              </Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.tabsLayout}>
              <Tabs value={value} onChange={handleChangeTab} truncate>
                {predictions.map((item, index) => {
                  const sourcePrediction = item['SRC_ITEM'];
                  return (
                    <Tab
                      key={index}
                      style={{ display: 'flex', flexDirection: 'row' }}
                      label={
                        <>
                          <span>{`${sourcePrediction[0]},`}</span>
                          <Tooltip
                            key={index}
                            title={`This source item has ${getRangeForScore(
                              sourcePrediction[1]
                            )} confidence score`}
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <span
                              className={classes.scoreBadge}
                              style={{
                                background: getBadgeColorForScore(sourcePrediction[1]),
                                marginLeft: '3px'
                              }}>
                              {sourcePrediction[1]}
                            </span>
                          </Tooltip>
                        </>
                      }
                    />
                  );
                })}
              </Tabs>
              <Box className={classes.expressionLayout}>
                {predictions.map((item, index) => {
                  const expPredictions = item['EXPR_PREDICTIONS'];
                  return (
                    value === index && (
                      <Grid xs={12} key={index}>
                        {Object.keys(expPredictions).map(
                          (key) =>
                            expPredictions[key]?.length > 1 && (
                              <EpressionCard
                                key={key}
                                expression={expPredictions[key][0]}
                                score={expPredictions[key][1]}
                              />
                            )
                        )}
                      </Grid>
                    )
                  );
                })}
              </Box>
            </Box>
          </Paper>
        </Popover>
      )}
    </>
  );
}

export default AutoMappingPopOver;
