/* eslint-disable*/
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Table, { createStringSearchFilter, compareStrings } from 'apollo-react/components/Table';
import React from 'react';
import TextField from 'apollo-react/components/TextField';
import FilterIcon from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';

const useStyles = makeStyles({
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  }
});

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const CustomButtonHeader = ({ toggleFilters }) => {
  return (
    <div>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: 15 }}
        onClick={toggleFilters}>
        Filter
      </Button>
    </div>
  );
};

const columns = [
  {
    header: 'Protocol Name',
    accessor: 'protocolNumber',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('protocolNumber'),
    fixedWidth: false
  },
  {
    header: 'Study ID',
    accessor: 'studyID',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('studyID'),
    fixedWidth: false
  },
  {
    header: 'Sponsor',
    accessor: 'sponsor',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('sponsor'),
    fixedWidth: false
  },
  {
    header: 'Dataset Name',
    accessor: 'datasetName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetName'),
    fixedWidth: false
  },
  {
    header: 'Columns',
    accessor: 'queryColumns',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('queryColumns'),
    fixedWidth: false
  },
  {
    header: 'Source',
    accessor: 'source',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('source'),
    fixedWidth: false
  }
];

const PreSQLPreviewTable = (props) => {
  const { value, version, previewPreSQLData, isPreview } = props;
  const classes = useStyles();

  return (
    <Box mt={2} className={classes.tableHorizontalOverflow}>
      <Table
        classes={classes.tableHorizontalOverflow}
        title={value}
        subtitle={isPreview && (version === undefined ? 'V1' : `V${version}`)}
        columns={columns}
        rows={previewPreSQLData}
        hasScroll
        maxHeight={650}
        initialSortedColumn="studyID"
        initialSortOrder="asc"
        rowsPerPageOptions={[10, 20, 50, 100, 'All']}
        CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
        tablePaginationProps={{
          truncate: true
        }}
        defaultRowsPerPage={10}
      />
    </Box>
  );
};

export default PreSQLPreviewTable;
