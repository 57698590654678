const generateSchemaRegex = (prefix = '') => new RegExp(`(${prefix}\\w+\\.)`, 'ig');

const QUOTE_TYPES = [
  "(\\'[\\w\\s\\$~&+,:;=?@#|`\"'<>.^\\*(){}\\[\\]%!-\\\\/]+\\')",
  '(\\"[\\w\\s\\$~&+,:;=?@#|`"\'<>.^\\*(){}\\[\\]%!-\\\\/]+\\")',
  '(\\`[\\w\\s\\$~&+,:;=?@#|`"\'<>.^\\*(){}\\[\\]%!-\\\\/]+\\`)'
];
const QUOTED_STRING_REGEX = new RegExp(QUOTE_TYPES.join('|'), 'gi');
const INVALID_STATEMENT_LIST = [
  '(\\*)',
  '\\b([\\s\\n]*select[\\s\\n]+\\*)',
  '\\b([\\s\\n]*update)[\\s\\n]+',
  '\\b([\\s\\n]*insert)[\\s\\n]+',
  '\\b([\\s\\n]*merge)[\\s\\n]+',
  '\\b([\\s\\n]*delete)[\\s\\n]+',
  '\\b([\\s\\n]*create)[\\s\\n]+',
  '\\b([\\s\\n]*alter)[\\s\\n]+',
  '\\b([\\s\\n]*truncate)[\\s\\n]+',
  '\\b([\\s\\n]*drop)[\\s\\n]+',
  '\\b([\\s\\n]*grant)[\\s\\n]+',
  '\\b([\\s\\n]*revoke)[\\s\\n]+',
  '\\b([\\s\\n]*commit)[\\s\\n]+',
  '\\b([\\s\\n]*rollback)[\\s\\n]+',
  '\\b([\\s\\n]*view)[\\s\\n]+',
  '\\b([\\s\\n]*views)[\\s\\n]+',
  '\\b([\\s\\n]*index)[\\s\\n]+',
  '\\b([\\s\\n]*indexs)[\\s\\n]+'
];
const INVALID_STATEMENT_TYPES_REGEX = new RegExp(INVALID_STATEMENT_LIST?.join('|'), 'ig');

/**
 * validateAllowedStatements
 * @param {string} query
 * @returns {{ isValid: boolean, invalidStatements: string[] }}
 */
const validateAllowedStatements = (query) => {
  const invalidStatements = query
    .replaceAll(QUOTED_STRING_REGEX, '')
    .match(INVALID_STATEMENT_TYPES_REGEX);
  return {
    isValid: !invalidStatements?.length,
    invalidStatements: invalidStatements?.map((statement) =>
      statement === '*' ? 'SELECT *' : statement
    )
  };
};

/**
 * validateSchema - Validates the provided query against any schema presence
 * @param {string} query
 * @param {string} schemaPrefix
 * @returns {{ isValid: boolean, studyMismatchedSchemas: string[] }}
 */
const validateCDRSchema = (query = '', schemaPrefix = '') => {
  const templateMatchedSchemas = query.match(generateSchemaRegex(schemaPrefix));

  return {
    isValid: !templateMatchedSchemas?.length,
    templateMatchedSchemas
  };
};

/**
 *validatePreSQLQuery
 * @param {string} query
 * @param {string} selectedDataSource
 * @param {string} schemaPrefix
 * @returns
 */
export const validatePreSQLQuery = (query = '', selectedDataSource = '', schemaPrefix = '') => {
  let result = validateAllowedStatements(query);
  console.log('validateAllowedStatements Result', result);
  if (!result?.isValid) {
    result.errorMessage = `The following statement types are not allowed: 
    [${result?.invalidStatements.map((statement) => statement.trim()).join(', ')}]`;
  } else if (selectedDataSource === 'CDR Tabular') {
    result = validateCDRSchema(query, schemaPrefix);
    if (!result?.isValid) {
      result.errorMessage = `Schema usage is not allowed, following schema usages need to be removed in order to run the query: 
      [${result?.templateMatchedSchemas?.join(', ')}]`;
    }
  }
  console.log('validateSchema Result', result);
  return result;
};
