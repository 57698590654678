import Sync from 'apollo-react-icons/Sync';
import Grid from 'apollo-react/components/Grid';
import Table from 'apollo-react/components/Table';
import Typography from 'apollo-react/components/Typography';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Info from 'apollo-react-icons/Info';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';

const CustomTableHeader = (props) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="title1" gutterBottom>
          Errors
          <Sync
            style={{
              color: `${props.ErrorData?.length ? '#015FF1' : '#B5B5B5'}`,
              cursor: `${props.ErrorData?.length ? 'pointer' : ''}`,
              margin: '0px 10px -5px 10px'
            }}
            onClick={
              props.ErrorData?.length
                ? () => props.setReloadFunction(!props.reloadFunction)
                : () => {}
            }
            data-testid={'reload-function'}
          />
        </Typography>
        <Typography variant="body2" gutterBottom>
          {`${props.ErrorData?.length} Error${props.ErrorData?.length !== 1 ? 's' : ''} Found`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ErrorIconCell = ({ row }) => {
  return (
    <span style={{ display: 'flex', flexDirection: 'row' }}>
      {row.logType === 'Warning' ? (
        <Info style={{ color: 'orange' }} />
      ) : (
        <StatusExclamation style={{ color: 'red' }} />
      )}
    </span>
  );
};

const compareStrings = (accessor, sortOrder) => {
  return function (rowA, rowB) {
    let stringA = rowA[accessor] ? rowA[accessor].toString().toUpperCase() : '';
    let stringB = rowB[accessor] ? rowB[accessor].toString().toUpperCase() : '';
    if (sortOrder === 'asc') {
      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      return 0;
    } else {
      if (stringA < stringB) {
        return 1;
      }
      if (stringA > stringB) {
        return -1;
      }
      return 0;
    }
  };
};

const columns = [
  {
    accessor: 'action',
    customCell: ErrorIconCell,
    width: 5
  },
  {
    header: 'Error Code',
    accessor: 'errorCode',
    sortFunction: compareStrings,
    width: 100,
    fixedWidth: false
  },
  {
    header: 'Is Error',
    accessor: 'isError',
    sortFunction: compareStrings,
    width: 100,
    fixedWidth: false
  },
  {
    header: 'Category',
    accessor: 'category',
    sortFunction: compareStrings,
    width: 150,
    fixedWidth: false
  },
  {
    header: 'Error Message',
    accessor: 'message',
    sortFunction: compareStrings,
    width: 300,
    fixedWidth: false
  }
];

const ErrorsDataTable = (props) => {
  const { ErrorData, reloadFunction, setReloadFunction } = props;
  return (
    <Table
      columns={columns}
      rows={ErrorData.map((row) => ({
        ...row
      }))}
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      initialSortedColumn={columns[1].accessor}
      initialSortOrder="asc"
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      CustomHeader={(prop) => (
        <CustomTableHeader
          {...prop}
          ErrorData={props.ErrorData}
          reloadFunction={reloadFunction}
          setReloadFunction={setReloadFunction}
        />
      )}
    />
  );
};

export default ErrorsDataTable;
