/* eslint-disable */
import { ErrorConstants } from './ErrorConstants';
const errors = [];
let uniqueObjects = {};

const {
  trialElementUniqueError,
  mandatoryError,
  etcdLengthError
} = ErrorConstants;

const checkUnique = (record) => {
  let value = record.etcd?.toString().toLowerCase();
  if (Object.keys(uniqueObjects).length > 0 && uniqueObjects[value]) {
    return {
      etcd: { unique: trialElementUniqueError },
    };
  } else {
    uniqueObjects[value] = true;
    return {};
  }
};

const checkMandatory = (record) => {
  const { etcd, element , testrl} = record;
  let obj = {};
  
    if (!etcd) {
      obj.etcd = { mandatory: mandatoryError };
    }
    if (!element) {
      obj.element = { mandatory: mandatoryError };
    }
    if (!testrl) {
      obj.testrl = { mandatory: mandatoryError };
    }
    
  return obj;
};

const checkLength = (record) => {
  const { etcd } = record;
  let obj = {};
  
    if (etcd.length > 8) {
      obj.etcd = { length: etcdLengthError };
    }
    
  return obj;
}

const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

export const TrialElementsValidator = (records) => {
  let errorColumnCount = 0;
  let errorRowsId = [];
 
  let error = {};
  records.forEach((record, index) => {
    let obj = {};
    const unique = checkUnique(record);
    let mandatory = {};
    let length = checkLength(record);
    mandatory = checkMandatory(record);

    mergeObject(obj, unique);
    mergeObject(obj, mandatory);
    mergeObject(obj, length)

    if (obj && Object.keys(obj).length > 0) {
      errorRowsId.push(record.id);
      errorColumnCount = errorColumnCount + Object.keys(obj).length;
    }

    error[record.id] = obj;
  });
  uniqueObjects = {};

  const isColumnValid = (uuid, columnName) => {
    let columnError = '';
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      columnError = columnName && row[columnName] ? row[columnName] : '';
    }
    return columnError;
  };

  const isRowValid = (uuid) => {
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row[uuid]).length > 0) {
      return false;
    }
    return true;
  };

  const getErrorRowId = () => {
    return errorRowsId;
  };

  const getColumnErrorCount = () => {
    return errorColumnCount;
  };

  return {
    isColumnValid: isColumnValid,
    isRowValid: isRowValid,
    getErrorRowId: getErrorRowId,
    getErrorCount: getColumnErrorCount,
  };
};

export default { TrialElementsValidator };
