/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getHeadersFromCookies } from 'service/header.service';
import { showLoader, closeLoader } from '../Slice/LoaderSlice';

// eslint-disable-next-line

const API_URL = process.env.REACT_APP_API_URL;

export const editGlobalLibrary = async ({ libraryID, version }) =>
  await axios.get(
    `${API_URL}/api/Library/GetLibraryFileByte?libID=${libraryID}&libVersion=${version}`
  );

export const GetViewGlobalLibraries = createAsyncThunk(
  'GlobalLibraryPage/GetViewGlobalLibraries',
  async (libObj, { dispatch, rejectWithValue }) => {
    dispatch(showLoader());
    try {
      const response = await axios.get(`${API_URL}/api/library/GetLibraries?IsActive=${libObj.IsActive}&exceptRuleset=${libObj.exceptRuleset}`);
      dispatch(closeLoader());
      return response;
    } catch (error) {
      dispatch(closeLoader());
      rejectWithValue([], error);
    }
  }
);

export const GetLatestGlobalLibrary = createAsyncThunk(
  'GlobalLibraryPage/GetGlobalLibrary',
  async (libObj, { dispatch, rejectWithValue }) => {
    dispatch(showLoader());
    try {
      const response = await axios.get(`${API_URL}/api/library/GetGlobalLibrary?LibraryID=${libObj.LibraryID}&LibraryVersion=${libObj.LibraryVersion}`);
      dispatch(closeLoader());
      return response;
    } catch (error) {
      dispatch(closeLoader());
      rejectWithValue([], error);
    }
  }
);

export const getDomainColumnAttributes = async () =>
  await axios.get(`${API_URL}/api/Library/GETDomainColumnAttributes`);

export const saveAddGlobalLibrary = async (payload) => {
  const api = await fetch(`${API_URL}/api/Library/SaveLibrary`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const downloadGlobalLibrary = async (payload) => {
  return await axios.post(`${API_URL}/api/Library/DownloadLibraryDomainColumns`, payload);
};

export const ImportMasteredDataSets = createAsyncThunk(
  'GlobalLibraryPage/ImportMasteredDataSets',
  async (flag = true, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/Library/ImportMasteredDataSets?IsActive=${flag}`
      );
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);
