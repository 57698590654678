import axios from 'axios';
import Cookies from 'js-cookie';

const getDomainName = () => {
  const urlParts = window.location.hostname.split('.');
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

export function deleteAllCookies() {
  const domain = getDomainName() || '';
  const cookies = Cookies.get();
  for (let cookieName in cookies) {
    Cookies.set(cookieName, cookies[cookieName], {
      expires: new Date().toUTCString(),
      domain
    });
  }
  return true;
}

export const Logout = async () => {
  return axios
    .get(`https://cdasserver-dev2.gdev-car3-k8s.work.iqvia.com/logout`)
    .then(async (res) => {
      if (res.data) {
        const deleted = await deleteAllCookies();
        return deleted;
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
    });
};
