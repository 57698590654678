/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Blade from 'apollo-react/components/Blade';
import { makeStyles } from '@mui/styles';
import Typography from 'apollo-react/components/Typography';
import { App, Arrow2Down } from 'apollo-react-icons';
import { Box, Button, Tooltip } from 'apollo-react';
import { gradientHorizontal, neutral8 } from 'apollo-react/colors';
import './AppSwitcher.css';
import { hasNoAccess } from 'service/AppPermissions';

const CORE_URL = process.env.REACT_APP_CORE_URL;
const CDI_URL = process.env.REACT_APP_CDI_URL;

const styles = {
  content: {
    color: neutral8,
    lineHeight: '24px'
  },
  customBlade: {
    background: gradientHorizontal
  },
  toLauchpad: {
    transform: 'rotate(-90deg)',
    marginLeft: 7
  },
  line: {
    background: '#fff',
    opacity: 0.35,
    width: 307,
    height: 1,
    marginLeft: 0,
    marginRight: -20
  }
};

const AppSwitcher = () => {
  const linksArr = [
    {
      title: 'Clinical Data Ingestion',
      imgUrl: '/icon/CDI_ICON_96x96.svg',
      url: `${CDI_URL}`,
      disabled: hasNoAccess('appSwitcher', 'clinicalDataIngestion')
    },
    {
      title: 'Clinical Data Mapper',
      imgUrl: '/icon/CDM_ICON_96x96.svg',
      url: '/',
      disabled: hasNoAccess('appSwitcher', 'clinicalDataMapper')
    },
    {
      title: 'Clinical Data Review',
      imgUrl: '/icon/CDR_ICON_96x96.svg',
      url: `${CORE_URL}/cdr`,
      disabled: hasNoAccess('appSwitcher', 'clinicalDataReview')
    },
    {
      title: 'Clinical Analytics',
      imgUrl: '/icon/CA_ICON_96x96.svg',
      url: `${CORE_URL}/ca`,
      disabled: hasNoAccess('appSwitcher', 'clinicalAnalytics')
    },
    {
      title: 'Data Science Workbench',
      imgUrl: '/icon/DSW_ICON_96x96.svg',
      url: `${CORE_URL}/dsw`,
      disabled: hasNoAccess('appSwitcher', 'dataScienceWorkbench')
    }
  ];
  const [state, setState] = useState({ open: false });
  const { open } = state;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const onClose = () => {
    setState({ open: false });
  };
  const onOpen = () => {
    setState({ open: true });
  };
  const onChange = () => {
    console.log('onChange');
  };

  const handleClick = (link) => {
    if (!link.disabled) {
      window.location.href = link.url;
    }
  };

  const goToCore = () => {
    window.location.href = `${CORE_URL}/launchpad`;
  };
  return (
    <>
      <App
        variant="primary"
        onClick={onOpen}
        data-testid="app-icon"
        style={{ marginTop: '15px', marginRight: '15px', cursor: 'pointer' }}
      />

      <Blade
        className="navigation-panel"
        width={350}
        hasBackdrop
        BackdropProps={{
          onClick: onClose,
          style: { background: 'transparent' }
        }}
        onChange={onChange}
        open={open}
        onClose={onClose}
        title="">
        <div className="waves">
          <img src="/icon/waves.svg" alt="waves" />
        </div>
        <App onClick={onClose} className="close-panel" data-testid="close-icon" />
        <Box display="flex" m={1} mt={5}>
          <Typography variant="title1" gutterBottom darkMode>
            Clinical Data Analytics Suite
          </Typography>
        </Box>
        <Box display="flex" m={1} mt={2}>
          <Typography
            onClick={() => goToCore()}
            className="link flex flex-center"
            variant="body2"
            gutterBottom>
            To Launchpad
            <Arrow2Down className={classes.toLauchpad} />
          </Typography>
        </Box>

        <div className={classes.line} />
        <Box display="flex" m={1} mt={3}>
          <Typography gutterBottom darkMode>
            Modules
          </Typography>
        </Box>
        <Box display="flex" className="flexWrap left-align">
          {linksArr.map((link, i) => {
            const buttonLInk = (
              <div>
                <Button
                  darkMode
                  className={link.disabled ? 'clickDisabled' : ''}
                  variant="text"
                  onClick={() => handleClick(link)}>
                  <img src={link.imgUrl} alt={link.title} />
                  {link.title}
                </Button>
              </div>
            );
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                {link.disabled ? (
                  <Tooltip
                    variant="dark"
                    title="Contact your System Administrator for access"
                    placement="right">
                    {buttonLInk}
                  </Tooltip>
                ) : (
                  buttonLInk
                )}
              </div>
            );
          })}
        </Box>
      </Blade>
    </>
  );
};

export default withStyles(styles)(AppSwitcher);
