/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import Grid from 'apollo-react/components/Grid';
import Card from './Card';
import FileUpload from 'apollo-react/components/FileUpload';
import {
  jsonToExcel,
  convertExcelToJson,
  jsonToFileData,
  uuidv4,
  getHeaders
} from 'Utils';
import {
  getCodeLists,
  GetCtmsVisitsData,
  GetProductCTPData,
  GetTimePointsData
} from 'Redux/Service/ReferenceDataCardsService';
import { getTimePointsSheet } from '../Utils/CtpSvtUtils';
import { checkColumns, checkFileData, checkFileType } from '../Utils/FileValidationUtils';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { DeleteReferenceData } from 'Redux/Service/ReferenceDataCardsService';
import CustomModal from 'Components/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import './Trials.css';
import { useNavigate, useParams } from 'react-router-dom';
import { setReferenceData } from 'service/reference-data.service';
import useSelectedDatasourcesData from '../../hooks/useSelectedDatasourcesData';
import { ctpSvtValidator } from 'Validators/CtpSvt.Validator';
import { getCodeListReferenceData } from 'Services/CodeListReferenceData.Service';
import { referenceDataService } from 'Services/ReferenceData.Service';
import { GetRuleMetadataFormItems } from 'Redux/Service/MasteringRuleService';
import IconButton from 'apollo-react/components/IconButton';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Tooltip from 'apollo-react/components/Tooltip';
const ctpSvtColumn = [
  {
    VISITTYPE: '',
    VISITNAME: '',
    VISITNUM: '',
    GRPID: '',
    CLRID: '',
    UPDES: '',
    EDC: '',
    CDR: '',
    CMPLTD_FLG: '',
    CASCADE_FLG: '',
    INTERFACE: '',
    EDCDEFAULTFORM: '',
    EDCCONDITION: '',
    CDRDEFAULTFORM: '',
    CDRCONDITION: ''
  }
];

const columns = [
  'VISITTYPE',
  'VISITNAME',
  'VISITNUM',
  'GRPID',
  'CLRID',
  'UPDES',
  'EDC',
  'CDR',
  'CMPLTD_FLG',
  'CASCADE_FLG',
  'INTERFACE',
  'EDCDEFAULTFORM',
  'EDCCONDITION',
  'CDRDEFAULTFORM',
  'CDRCONDITION'
];

const allowedExtensions = ['xlsx', 'xls'];
const CTMSMsg =
  'The downloaded excel template auto populates the subject visit information if CTMS source is configured and the study is available in CTMS. Additionally, the visit name will be validated as per Subject Visit template';

const CtpSvt = (props) => {
  const { id: mappingRuleVersionID } = useParams();
  const {
    data: selectedDataSourcesData,
    loading,
    error: apiError
  } = useSelectedDatasourcesData(mappingRuleVersionID);
  const navigate = useNavigate();
  const [ctpSvtData, setCtpSvtData] = useState([]);
  const [ctmsVisitsData, setCtmsVisitsData] = useState([]);
  const [mappingError, setMappingError] = useState('');
  const [cards, setCardArray] = useState([]);
  const [ctpSvtValidationResult, setCtpSvtValidationResult] = useState({});
  const [dataSourceData, setDataSourceData] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [isEditDisable, setIsEditDisable] = useState(true);
  const [isCtmsmetadataSuccess, setIsCtmsmetadataSuccess] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);
  const [error, setError] = useState();
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [ctpSvtMessage, setCtpSvtMessage] = useState('');
  const dispatch = useDispatch();
  const [loadProgress, setLoadProgress] = useState(false);
  const [edcOdmInfo, setEdcOdmInfo] = useState({});
  const [isCtmsConfigured, setIsCtmsConfigured] = useState(true);
  const [codeListDataMapping, setCodeListDataMapping] = useState(null);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [serviceResult, setServiceResult] = useState({});
  const [ctpFormsObj, setCtpFormsObj] = useState({});
  const [formatedFormData, setFormatedFormData] = useState([]);
  const [cdrTabular, setCDRTabular] = useState({});
  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  //for DataSourcesData fetching
  useEffect(() => {
    (async () => {
      setLoadProgress(true);
      let isEproInfo = {};
      if (!loading) {
        if (
          selectedDataSourcesData &&
          selectedDataSourcesData.data &&
          selectedDataSourcesData.data.success
        ) {
          let result = await referenceDataService(selectedDataSourcesData.data.ruleStudyLibrary);
          setDataSourceData(selectedDataSourcesData.data.ruleStudyLibrary);
          setServiceResult(result);

          isEproInfo = await getCodeListReferenceData(
            selectedDataSourcesData.data.ruleStudyLibrary,
            {
              protocolNumber: protocol.protocolNumber,
              neededLibraries: ['CDISC ODM'],
              neededVendors: ['CLINICAL_INK', 'MERGE']
            }
          );
          //eslint-disable-next-line
          globalThis.cdassm = {
            edcOdmInfo: isEproInfo.edcOdmInfo,
            cdrInfo: isEproInfo.cdrInfo
          };
          setEdcOdmInfo(isEproInfo);
          setReferenceData('code-list-ctp-edcOdmInfo', isEproInfo);
          setLoadProgress(false);
        } else {
          setLoadProgress(false);
          apiError && dispatch(showBanner({ variant: 'error', message: apiError }));
        }
      }
    })();
  }, [selectedDataSourcesData, loading]);

  useEffect(() => {
    (async () => {
      let formsObj = {};
      let cdrTabular = {};
      let itemNames = [];
      let formatedFormItemData = [];
      try {
        const payload = {
          mappingRuleVersionId: mappingRuleVersionID,
          forms: [1, 2]
        };
        const getFormsData = await dispatch(GetRuleMetadataFormItems(payload)).then(unwrapResult);
        if (getFormsData && getFormsData.data && getFormsData.data.success) {
          getFormsData.data.formItemsDict['CDISC ODM'].forEach((obj) => {
            if (!formsObj[obj.formName]) {
              formsObj[obj.formName] = [obj.itemName];
            } else {
              formsObj[obj.formName].push(obj.itemName);
            }
          });
          getFormsData.data.formItemsDict['CDR Tabular'].forEach((obj) => {
            if (!cdrTabular[obj.formName]) {
              cdrTabular[obj.formName] = [obj.itemName];
            } else {
              cdrTabular[obj.formName].push(obj.itemName);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
      setCtpFormsObj(formsObj);
      setCDRTabular(cdrTabular);
      setFormatedFormData(formatedFormItemData);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!codeListDataMapping) {
        try {
          const payload = {
            mappingRuleVersionID
          };
          const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
          if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
            const mapping = {};
            const codelists = codeListsGetAPIData.data.ruleCodeLists || [];
            for (let { odmForm, odmItem, sdmCodelistLabel } of codelists) {
              const key = `${odmForm.toLowerCase()}#-#${odmItem.toLowerCase()}`;
              if (!(key in mapping)) {
                mapping[key] = [];
              }
              if (sdmCodelistLabel.trim()) mapping[key].push(sdmCodelistLabel.toLowerCase());
            }
            setCodeListDataMapping(mapping);
          }
        } catch (error) {
          setCodeListDataMapping({});
          console.log(error);
        }
      }
    })();
  }, [codeListDataMapping]);

  useEffect(() => {
console.log(loadProgress, "loadPro")
  },[loadProgress])

  useEffect(() => {
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['CtpSvt']) {
      if (!isEditDisable) {
        const element = document.getElementById('anchor-preview');
        element.click();
        setMappingError(props.createMappingObj['message']);
        props.createMappingObj['CtpSvt'] = false;
      }
    }
  }, [isEditDisable]);

  //Ctms Visit Data fetching
  useEffect(() => {
    (async () => {
      let payload = {
        protocolName: protocol.protocolNumber
      };
      const getCtmsVisits = await dispatch(GetCtmsVisitsData(payload)).then(unwrapResult);
      if (getCtmsVisits && getCtmsVisits.data && getCtmsVisits.data.success) {
        setIsCtmsConfigured(getCtmsVisits.data.isCtmsConfigured);
        setIsCtmsmetadataSuccess(false);
        if (getCtmsVisits.data.edcOdmCtmsVisits && getCtmsVisits.data.edcOdmCtmsVisits.length > 0) {
          setError('');
          const newData = getCtmsVisits.data.edcOdmCtmsVisits.map((item) => {
            return {
              visitname: item.visitName,
              visittype: item.visitTypeCode
            };
          });
          setCtmsVisitsData(newData);
        } else {
          setCtmsVisitsData([]);
        }
      } else {
        setCtmsVisitsData([]);
      }
    })();
  }, []);

  const convertToUpper = (obj) => {
    let CMPLTD_FLG = obj.CMPLTD_FLG;
    let CASCADE_FLG = obj.CASCADE_FLG;
    let INTERFACE = obj.INTERFACE;
    if (obj.CMPLTD_FLG === 'y') {
      CMPLTD_FLG = 'Y';
    } else if (obj.CMPLTD_FLG === 'n') {
      CMPLTD_FLG = 'N';
    }
    if (obj.CASCADE_FLG === 'y') {
      CASCADE_FLG = 'Y';
    } else if (obj.CASCADE_FLG === 'n') {
      CASCADE_FLG = 'N';
    }
    if (obj.INTERFACE === 'y') {
      INTERFACE = 'Y';
    } else if (obj.INTERFACE === 'n') {
      INTERFACE = 'N';
    }
    let newObj = { CMPLTD_FLG, CASCADE_FLG, INTERFACE };
    const modifiedObj = { ...obj, ...newObj };
    return modifiedObj;
  };
  //fetching already saved data/data from API
  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingRuleVersionID
      };
      const ctpSvtGetAPIData = await dispatch(GetProductCTPData(payload)).then(unwrapResult);
      props.configuration.setIsCompleted('ctp-svt', true);
      if (ctpSvtGetAPIData && ctpSvtGetAPIData.data && ctpSvtGetAPIData.data.success) {
        if (ctpSvtGetAPIData.data.timePoints && ctpSvtGetAPIData.data.timePoints.length > 0) {
          setError('');
          const newData = ctpSvtGetAPIData.data.timePoints.map((item) => {
            return {
              visitname: item.visit,
              visitnum: item.vistnum,
              visittype: item.visitType,
              grpid: item.tvgrpid,
              clrid: item.tvclrid,
              updes: item.tpupdes,
              edc: item.edc,
              cdr: item.tabular,
              cmpltd_flg: item.completedFlag,
              cascade_flg: item.cascadeFlag,
              interface: item.interface,
              id: uuidv4(),
              edcdefaultform: item.edcDefaultForm,
              edccondition: item.edcCondition,
              cdrCondition: item.cdrCondition,
              cdrdefaultform: item.cdrDefaultForm
            };
          });
          setCtpSvtData(newData);
          let fileObj = jsonToFileData(newData, 'CtpSvt.xlsx');
          setSelectedFile([fileObj]);
          setIsEditDisable(false);
          props.configuration.setModuleValidation('ctp-svt', true);
        } else {
          props.configuration.setModuleValidation('ctp-svt', false);
          setCtpSvtData([]);
          setIsEditDisable(true);
        }
      } else {
        props.configuration.setModuleValidation('ctp-svt', false);
        setCtpSvtMessage(ctpSvtGetAPIData.data.message);
        setCtpSvtData([]);
      }
    })();
  }, []);

  //ctpSVt Validation
  useEffect(() => {
    (async () => {
      // validate ctpSvtData
      const validateWith = dataSourceData.filter((row) => row.isMappingRuleConfigured);

      if (
        ctpSvtData.length &&
        dataSourceData.length &&
        ctmsVisitsData.length &&
        Object.keys(ctpFormsObj).length > 0 &&
        cdrTabular
      ) {
        const validationResult = await ctpSvtValidator(
          ctpSvtData,
          validateWith,
          ctmsVisitsData,
          codeListDataMapping,
          ctpFormsObj,
          cdrTabular,
          isCtmsConfigured
        );
        setCtpSvtValidationResult(validationResult);
        const errorCountFlag = validationResult.getErrorCount();
        if (errorCountFlag > 0) {
          props?.configuration?.setModuleValidation('ctp-svt', false);
          setErrorFlag(true);
        } else {
          props?.configuration?.setModuleValidation('ctp-svt', true);
          setErrorFlag(false);
        }
      }
    })();
  }, [ctpSvtData, dataSourceData, ctmsVisitsData, codeListDataMapping, ctpFormsObj, cdrTabular]);

  const downloadSheet = () => {
    const newData = !ctmsVisitsData.length
      ? [
          {
            VISITTYPE: '',
            VISITNAME: '',
            VISITNUM: '',
            GRPID: '',
            CLRID: '',
            UPDES: '',
            EDC: '',
            CDR: '',
            CMPLTD_FLG: '',
            CASCADE_FLG: '',
            INTERFACE: '',
            EDCDEFAULTFORM: '',
            EDCCONDITION: '',
            CDRDEFAULTFORM: '',
            CDRCONDITION: ''
          }
        ]
      : ctmsVisitsData.map((item) => {
          return {
            VISITTYPE: item.visittype,
            VISITNAME: item.visitname,
            VISITNUM: '',
            GRPID: '',
            CLRID: '',
            UPDES: '',
            EDC: '',
            CDR: '',
            CMPLTD_FLG: '',
            CASCADE_FLG: '',
            INTERFACE: '',
            EDCDEFAULTFORM: '',
            EDCCONDITION: '',
            CDRDEFAULTFORM: '',
            CDRCONDITION: ''
          };
        });
    jsonToExcel(newData, 'CtpSvt.xlsx', 'CTP');
  };

  const handleTimePointsSheetDownload = async () => {
    const timePointsData = await dispatch(
      GetTimePointsData({
        mappingRuleVersionID: mappingRuleVersionID
      })
    ).then(unwrapResult);
    if (timePointsData?.data?.success) {
      const finalTimePointsData = getTimePointsSheet(timePointsData.data);
      jsonToExcel(finalTimePointsData, 'Source Event.xlsx', 'Source Event');
    } 
    else dispatch(showBanner({ variant: 'error', message: timePointsData.data.message }));
  };

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const handleUpload = async (selectedFiles) => {
    setLoadProgress(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJsonData = await convertExcelToJson(selectedFiles[0]);
      if (fileJsonData && fileJsonData.length > 0) {
        const headers = await getHeaders(selectedFiles[0]);
        const isError = fileValidation(fileJsonData, headers);
        if (!isError) {
          let newFileArr = [];
          fileJsonData.map((obj) => {
            let modifiedObj = convertToUpper(obj);
            const newObj = { ...ctpSvtColumn[0], ...modifiedObj };
            var lowerObj = _.transform(newObj, function (result, val, key) {
              result[key.toLowerCase()] = val;
            });
            lowerObj.id = uuidv4();
            newFileArr.push(lowerObj);
          });
          setError('');
          setCtpSvtData(newFileArr);
          setSelectedFile([selectedFiles[0]]);
          setIsEditDisable(false);
          setReferenceData('ctp-svt', newFileArr);
          navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/ctp-svt`);
        } else {
          setCtpSvtData([]);
          setSelectedFile([]);
          setError(isError);
          setIsEditDisable(true);
        }
      } else {
        setLoadProgress(false);
        setError('File is Empty');
      }
    }
    setLoadProgress(false);
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'CTP SVT',
      mappingRuleVersionID: mappingRuleVersionID
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
      setDeleteFile(false);
      setError('');
      setSelectedFile([]);
      setCtpSvtData([]);
      setIsEditDisable(true);
      cardItems.push({ name: 'CTP SVT', module: 'ctp-svt' });
      setCardItems(cardItems);
      const index = cards.findIndex((el) => el === 'ctp-svt');
      props.configuration.setCardVisibility('ctp-svt', false);
      cards.splice(index, 1);
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
  };

  const handleEditPreview = (isUploaded) => {
    if (isUploaded || ctpSvtData) {
      navigate({
        pathname: `/product-designer/rule-editor/${mappingRuleVersionID}/ctp-svt`
      });
    }
  };

  const confirmOnDelete = () => {
    setDeleteFile(true);
    ctpSvtData.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          onConfirm: () => {
            deleteUploadedFile();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''} </span>
        {!isCtmsConfigured && (
          <Tooltip interactive={true} placement={'top'} size={'small'} title={CTMSMsg}>
            <IconButton style={{ height: '30px', color: '#FF9300' }}>
              <StatusExclamation />
            </IconButton>
          </Tooltip>
        )}
      </h4>
      <Card
        isCtmsmetadataSuccess={isCtmsmetadataSuccess}
        isCtmsConfigured={isCtmsConfigured}
        deleteCardInParent={confirmOnDelete}
        refMsg={ctpSvtMessage}
        setErrorFlag={errorFlag}
        required={props.required}
        handleDownload={downloadSheet}
        handleTimePointsSheetDownload={handleTimePointsSheetDownload}
        isEditDisable={loadProgress || isEditDisable}
        cardTitle={props.title}
        handleEditPreview={() => handleEditPreview()}
        >
        <div style={{ margin: '0px 10px' }}>
          {(loadProgress || loading) && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={loadProgress}
              value={selectedFile}
              // disabled={isDisable}
              onUpload={handleUpload}
              // onFileDelete={openDeleteModal}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
              //   {...this.props}
            />
          </div>
          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        // handlePrimaryAction={() => handleDelete()}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </Grid>
  );
};

export default CtpSvt;
