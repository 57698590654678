/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from 'apollo-react/components/Grid';
import { BannerExample } from 'Components/Banner/Banner';
import Header from '../../Components/Header';
import Panel from 'apollo-react/components/Panel';
import { useDispatch, useSelector } from 'react-redux';
import SelectSourcesSideBar1 from 'Components/SelectSourcesSideBar/SelectSourcesSideBar1';
import { useParams } from 'react-router-dom';
import { setRuleEditorCards } from 'Redux/Slice/RuleEditorSlice';
import { GetWorkflowModel } from 'Redux/Service/RuleEditorService';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Breadcrumb from 'Components/Breadcrumb';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';

const useStyles = makeStyles({
  studySidebar: {
    backgroundColor: '#fff'
  },
  toggleButton: {},
  expandedPreview: {
    width: 'calc(100% - 24px)',
    zIndex: 3001
  },
  compactPreview: {
    width: 'calc(100% - 352px)',
    zIndex: 3001
  },
  fullwidthPreview: {
    width: '100%'
  },
  hidden: {
    visibility: 'hidden'
  },
  main: {
    height: 'calc(100vh - 113px)',
    marginTop: '113px',
    overflow: 'auto',
    '&>div': {
      position: 'sticky',
      top: '0px',
      minHeight: 'calc(100vh - 113px)',
      height: 'fit-content'
    },
    '&>div:nth-child(1)': {
      zIndex: 1
    }
  },
  root: {
    overflow: 'hidden'
  },
  rightPanel: {
    zIndex: 3001
    // marginTop: '20px'
  }
});
const PATHS = ['mastering-rules', 'domain-rules'];
export const RuleEditorSummaryLayout = (props) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [toggleSidebar, SetToggleSidebar] = useState(true);
  const { id: mappingRuleVersionId } = useParams();

  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  let pathObj = {
    'data-sources': 'Select Data Source',
    'reference-data': 'Upload Reference Data',
    'mastering-rules': 'Mastering Rules',
    'domain-rules': 'Domain Rules',
    'mds-domain-rules': 'Domain Rules',
    'apply-ruleset': 'Apply Ruleset',
    'create-mapping-spec': 'Create Mapping Spec',
    'supp-qual': 'Supplemental Qualifiers',
    'additional-domain-rules': 'Additional Domain Rules',
    'vlc-rules': 'VLC Rules'
  };

  useEffect(() => {
    async function getWorkflow() {
      let payload;
      if (rowDataForRuleEditor.targetDataModelType === 'CTP') {
        payload = 'IQVIA-CTP';
      } else {
        payload = rowDataForRuleEditor.targetDataModelType;
      }
      const workflowData = await dispatch(GetWorkflowModel(payload)).then(unwrapResult);
      if (workflowData) {
        workflowData.functionData = workflowData.functionData.sort((a, b) => a.order - b.order);
        dispatch(setRuleEditorCards(workflowData));
      } else {
        dispatch(showBanner({ variant: 'error', message: 'Error Getting Workflow Model' }));
      }
    }

    getWorkflow();
  }, []);

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { title: '', path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title: pathObj[currentPath],
          path: `/product-designer/rule-editor/${mappingRuleVersionId}/${currentPath}`
        }
      ])
    );
  });

  return (
    <Grid className={classes.root}>
      <Header />
      {protocol !== undefined && <ProjectStudyHeader />}
      <Grid container className={classes.main}>
        <BannerExample />
        <Panel
          // classes={{
          //   toggleButton: classes.toggleButton,
          //   container: classes.leftPanelContainer
          // }}
          // className={classes.leftPanelContainer}
          data-testid="open-panel"
          open={toggleSidebar}
          onClose={() => {
            SetToggleSidebar(false);
          }}
          onOpen={() => {
            SetToggleSidebar(true);
          }}
          width={350}>
          <Grid
            item
            xs={12}
            className={`${classes.studySidebar} ${toggleSidebar ? '' : classes.hidden}`}>
            <SelectSourcesSideBar1 />
          </Grid>
        </Panel>
        <Grid className={toggleSidebar ? classes.compactPreview : classes.expandedPreview}>
          <Grid item xs={12} className={classes.rightPanel}>
            {!PATHS.includes(currentPath) ? <Breadcrumb addStudy={true} /> : null}
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
