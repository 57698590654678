/*eslint-disable*/
import React, { useState } from 'react';
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import StudyDashboard from 'Pages/Publish/Components/StudyDashboard';
import Publish from 'Pages/Publish/PublishDataProduct';

const StudySpecificDashboard = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { loading } = useSelector((state) => state.LoaderData);
  const { userLoginID: userId, disableComponentsBasedOnRole } = useSelector(
    (state) => state.LoginFormData
  );
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const handleChangeTab = (event, value) => {
    setValue(value);
  };

  return (
    <>
      <div>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          truncate
          style={{
            paddingTop: '14px',
            paddingLeft: '24px'
          }}>
          <Tab label="Data Pipeline Summary" />
          <Tab label="Data Products" />
        </Tabs>
        <div style={{ padding: 24 }}>
          {value === 0 && (
            <Typography variant="body2">
              <StudyDashboard />
            </Typography>
          )}
          {value === 1 && (
            <Typography variant="body2">
              <Publish />
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default StudySpecificDashboard;
