import React, { useMemo, useRef } from 'react';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Sidebar from 'Components/Sidebar';
import { useLocation } from 'react-router-dom';
import StudyLibrarySideBar from 'Components/StudyLibrarySidebar';
import Grid from 'apollo-react/components/Grid';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import { useSelector } from 'react-redux';
import { BannerExample } from 'Components/Banner/Banner';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import { Box } from '@mui/material';
const useStyles = (isStudyLibrary) =>
  makeStyles({
    sideBar: {
      backgroundColor: '#fff',
      borderRight: '1px solid #ccc'
    },
    studySidebar: {
      backgroundColor: '#fff',
      borderRight: '1px solid #ccc',
      marginTop: '57px',
      height: `calc(100vh - ${isStudyLibrary ? 113 : 56}px)`
    },
    container: {
      maxHeight: `calc(100vh - ${isStudyLibrary ? 113 : 56}px)`,
      marginTop: `${isStudyLibrary && '56px'}`,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: `calc(100vh - 113px)`
    }
  });

export const SideBarLayout = (props) => {
  const location = useLocation();
  const childRef = useRef();
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const isStudyLibrary = useMemo(() => {
    return (
      location.pathname === '/data-standard-library/add-study-library' ||
      location.pathname === '/data-standard-library/edit-study-library'
    );
  }, [location.pathname]);

  const classes = useStyles(isStudyLibrary)();

  const handleSubmit = () => {
    childRef.current.handleSubmit();
  };

  return (
    <React.Fragment>
      <Header />
      <Grid container>
        <BannerExample />
        <Grid item xs={3} className={isStudyLibrary ? classes.studySidebar : classes.sideBar}>
          {isStudyLibrary ? (
            <StudyLibrarySideBar
              onSubmitRef={handleSubmit}
              isValidate={() => childRef.current.hasValidate()}
              getPayload={() => childRef.current.getPayload()}
              validateRuleSet={() => childRef.current.validateRuleSet()}
              handleStepperback={() => childRef.current.handleStepperback()}
              resetForm={() => childRef.current.resetForm()}
            />
          ) : (
            <Sidebar
              onSubmitRef={handleSubmit}
              isValidate={() => childRef.current.hasValidate()}
              validateRuleSet={() => childRef.current.validateRuleSet()}
              handleStepperback={() => childRef.current.handleStepperback()}
              resetForm={() => childRef.current.resetForm()}
              getPayload={() => childRef.current.getPayload()}
              path={location.pathname}
            />
          )}
        </Grid>
        <Grid item xs={9} className={classes.container}>
          <Box>
            {isStudyLibrary ? (
              <>
                <Breadcrumb addStudy={true} />
                {protocol !== undefined && <ProjectStudyHeader />}{' '}
              </>
            ) : (
              <Breadcrumb sidebar={true} />
            )}
            {React.cloneElement(props.children, { ref: childRef }, null)}
          </Box>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
