export const dataProducts = [
  {
    id: 8473,
    employeeId: 'EMR200136_573',
    name: 'SDM',
    dept: 'Subject Data Mart',
    email: 'SDTM3.2',
    versionDate: '01-Jan-2021',
    hireDate: 'Q806659',
    status: 'Live',
    version: 'V18'
  },
  {
    id: 8474,
    employeeId: 'EMR200136_573',
    name: 'SDM',
    dept: 'Subject Data Mart',
    email: 'SDTM3.2',
    versionDate: '01-Jan-2021',
    hireDate: 'Q806659',
    status: 'Live',
    version: 'V200'
  }
];
