import { createSlice } from '@reduxjs/toolkit';

export const BannerSlice = createSlice({
  name: 'Banner',
  initialState: {
    variant: '',
    message: '',
    open: false,
    url: '',
    propagate: false
  },
  reducers: {
    showBanner(state, { payload }) {
      const { variant, message, propagate = false } = payload;
      state.variant = variant;
      state.message = message;
      state.open = true;
      state.propagate = propagate;
    },
    closeBanner(state) {
      state.open = false;
      state.variant = '';
      state.message = '';
    },
    getUrl(state, { payload }) {
      state.url = payload;
    },
    clearPropagation(state) {
      state.propagate = false;
    }
  }
});

export const { showBanner, closeBanner, getUrl, clearPropagation } = BannerSlice.actions;
