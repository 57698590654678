/*eslint-disable */
import React, { useContext, useCallback , useState} from 'react';
import { useEffect } from 'react';
import TreeViewExample from 'Layout/RulesLayout/Components/TreeView';
import { DomainRulesContext } from '../../DomainRules/DomainRulesProvider';
import Search from 'apollo-react/components/Search';
import debounce from 'lodash.debounce';

const DomainRulesMiddlePanel = () => {
  const [childNodes, setChildeNodes] = useState({})
  const context = useContext(DomainRulesContext);
  const {
    domainData,
    selected,
    setSelected,
    expanded,
    setExpanded,
    setSelectedNodeText,
    setParentNodeID,
    parentNodeID,
    setParentNodeName,
    parentNodeName,
    domainFormattedData,
    setNewDomainFormattedData,
    setDisableVariable,
    disableVariable,
    filters
  } = context;

  const handleToggle = (e, nodeIds) => {
    if (nodeIds.includes(expanded[0])) {
      const index = nodeIds.indexOf(expanded[0]);
      if (index > -1) {
        nodeIds.splice(index, 1);
        setExpanded(nodeIds);
      }
    } else {
      setExpanded(nodeIds);
    }
  };

  const handleSelect = (e, nodeIds) => {
    let parentNodes = Object.keys(domainFormattedData);
    if (e.target.outerText === undefined) {
      if (parentNodeID !== '' && Object.values(childNodes[parentNodeID[0]]).includes(nodeIds[0])) {
        if (parentNodes.includes(parentNodeName)) {
          domainData.find(domainNode => {
            if (domainNode.nodeText === parentNodeName) {
              domainNode.childNodes.find(variableNode => {
                if(variableNode.nodeId === nodeIds[0]) {
                  setSelectedNodeText(variableNode.nodeText);
                  setSelected(nodeIds);
                }
              })
            }
          })
        }
      } else {
        domainData.find(node => {
          if (node.nodeId === nodeIds[0]) {
            setSelectedNodeText('');
            setParentNodeName(node.nodeText);
            setSelected(nodeIds);
            setParentNodeID(nodeIds);
          }
        })
      }
    }else if (parentNodes.includes(e.target.outerText)) {
      setSelectedNodeText('');
      setParentNodeName(e.target.outerText);
      setParentNodeID(nodeIds);
      setSelected(nodeIds);
    } else {
      setSelectedNodeText(e.target.outerText);
      setParentNodeName(parentNodeName ? parentNodeName : '');
      setSelected(nodeIds);
      setParentNodeID(parentNodeID ? parentNodeID : '');
    }
  };

  const debouncedData = useCallback(
    debounce((e) => {
      let copyfDomainFormattedData = JSON.parse(JSON.stringify(domainFormattedData));
      let domainData = Object.keys(copyfDomainFormattedData);
      let newObj = {};
      if (e.target.value !== '') {
        domainData.forEach((el) => {
          let variableList = [];
          if (el.includes(e.target.value)) {
            newObj[el] = copyfDomainFormattedData[el];
          } else if (copyfDomainFormattedData[el].length) {
            copyfDomainFormattedData[el].forEach((domain) => {
              variableList.push(domain.variableName);
            });
          }
          if (variableList.includes(e.target.value)) {
            newObj[el] = copyfDomainFormattedData[el];
          }
        });
        setNewDomainFormattedData(newObj);
      } else {
        setNewDomainFormattedData(domainFormattedData);
      }
    }, 3000),
    [domainFormattedData]
  );

  useEffect(() => {
    setDisableVariable(false);
    if (Object.keys(filters).length > 0) {
      let domains = Object.keys(filters);
      if (domains.length > 0 && !domains.includes(parentNodeName)) {
        setDisableVariable(true);
      }
    }
  }, [filters, parentNodeName]);

  useEffect(() => {
    const childNodeObj = {};
    domainData.forEach(domain => {
      const childNodes = [];
      domain.childNodes?.forEach(node => {
        childNodes.push(node.nodeId)
      })
      childNodeObj[domain.nodeId] = childNodes
    });
    setChildeNodes(childNodeObj);
  }, [domainData])

  const modifiedDomainData = (e) => {
    debouncedData(e);
  };

  return (
    <div
      style={{
        overflowY: 'auto',
        position: 'sticky',
        top: '0px',
        height: 'fit-content',
        maxHeight: 'calc(100vh - 113px)'
      }}>
      <div
        style={{
          paddingTop: '1.5rem',
          borderBottom: '1px solid #e9e9e9',
          margin: '0 2rem',
          marginBottom: '1rem'
        }}>
        <span
          style={{
            fontSize: '16px',
            fontWeight: 500
          }}>
          Domains
        </span>
        <Search placeholder="Search" fullWidth onChange={modifiedDomainData} />
      </div>

      <TreeViewExample
        data={domainData}
        handleSelect={handleSelect}
        handleToggle={handleToggle}
        expanded={expanded}
        selected={selected}
        domainsConfigured={filters}
        disableVar={disableVariable}
      />
    </div>
  );
};

export default DomainRulesMiddlePanel;
