/* eslint-disable react/prop-types */
import React from 'react';
import { BannerExample } from 'Components/Banner/Banner';

export const LoginLayout = (props) => {
  return (
    <>
     <BannerExample data = {'login'}/>
    {props.children}
    </>
  )
}

