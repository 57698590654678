import { makeStyles } from '@mui/styles';
import { BannerExample } from 'Components/Banner/Banner';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import React from 'react';

const useStyles = makeStyles({
  sideBar: {
    backgroundColor: '#fff',
    borderRight: '1px solid #ccc'
  },
  divContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: `calc(100vh - 113px)`
  }
});

const StudyConfigLayout = (props) => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <BannerExample />
      <div className={classes.divContainer}>
        {props.children}
        <Footer />
      </div>
    </>
  );
};

export default StudyConfigLayout;
