/* eslint-disable no-unused-vars */
import React from 'react';
import Cookies from 'js-cookie';

// export const isAuth2 = () => {
//   return localStorage.getItem('state') &&
//     JSON.parse(localStorage.getItem('state')).LoginFormData.userLoginID
//     ? true
//     : false;
// };

const isAuth = () => {
  // Cookies.set('user.current_login_ts', '1657085178');
  // Cookies.set('user.token', 'ggJ4NXQiOiJOelE0TWpFNU5HVTRPR05oT1RFeVpU');
  // Cookies.set('user.first_name', 'Vikas');
  // Cookies.set('user.id', 'q1066927');
  // Cookies.set('user.email', 'vikas.bansal@iqvia.com');
  // Cookies.set('user.last_name', 'Bansal');
  // Cookies.set('user.last_login_ts', '1657024924');
  // return Cookies.get('user.token') ||
  //   (localStorage.getItem('state') &&
  //     JSON.parse(localStorage.getItem('state')).LoginFormData.userLoginID)
  //   ? true
  //   : false;
  return Cookies.get('user.email') ||
    Cookies.get('user.id') ||
    (localStorage.getItem('state') &&
      JSON.parse(localStorage.getItem('state')).LoginFormData.userLoginID)
    ? true
    : false;
};

export default isAuth;
