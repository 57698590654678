import FilterIcon from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import React from 'react';
import { dateFilter, compareDates } from 'Utils';
import { DateFilter } from 'Components/Common/DateFilter';

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const columns = [
  {
    header: 'User',
    accessor: 'userId',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('userId'),
    filterComponent: TextFieldFilter,
    fixedWidth: false
  },
  {
    header: 'Date',
    accessor: 'createDate',
    sortFunction: compareDates,
    filterFunction: dateFilter('createDate'),
    filterComponent: DateFilter,
    fixedWidth: false
  },
  {
    header: 'Version',
    accessor: 'studylibraryVersion',
    sortFunction: compareNumbers,
    filterFunction: numberSearchFilter('studylibraryVersion'),
    filterComponent: IntegerFilter,
    fixedWidth: false
  },
  {
    header: 'Reason For Change',
    accessor: 'reasonForChange',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('reasonForChange'),
    filterComponent: TextFieldFilter,
    fixedWidth: false
  },
  {
    header: 'Audit',
    accessor: 'auditType',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('auditType'),
    filterComponent: TextFieldFilter,
    fixedWidth: false
  }
];

const CustomButtonHeader = ({ toggleFilters }) => {
  return (
    <div>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: 15 }}
        onClick={toggleFilters}>
        Filter
      </Button>
    </div>
  );
};

const StudyAuditHistory = ({ rows }) => {
  return (
    <div>
      {rows.length > 0 ? (
        <Table
          columns={columns}
          rows={rows}
          initialSortedColumn="createDate"
          initialSortOrder="desc"
          rowsPerPageOptions={[10, 20, 50, 100, 'All']}
          hasScroll
          maxHeight={650}
          CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
          tablePaginationProps={{
            // labelDisplayedRows: ({ from, to, count }) =>
            //   `${count === 1 ? 'Employee' : 'Employees'} ${from}-${to} of ${count}`,
            truncate: true
          }}
        />
      ) : null}
    </div>
  );
};

export default StudyAuditHistory;
