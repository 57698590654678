/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import Table from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Search from 'apollo-react-icons/Search';
import styled from 'styled-components';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { GetAssignedProtocolDetails } from 'Redux/Service/CloneDataProductService';
import { columns } from './columns.data';
import { useDispatch } from 'react-redux';
import { uuidv4 } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Caption, TableWrapper, Title } from '../../Common';
import useDisplayName from 'Utils/useDisplayName';

const CustomHeader = ({ searchText, setSearchText }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'92px'}>
      <TextField
        type={'search'}
        value={searchText}
        startIcon={<Search />}
        iconProps={{ size: 'small' }}
        size={'small'}
        label={'Search for a study'}
        placeholder={'Enter text here to search'}
        InputLabelProps={{ style: { color: 'black', fontWeight: '400' } }}
        onChange={(e) => setSearchText(e.target.value)}
        fullWidth
      />
    </Box>
  );
};

function SelectSourceStudy() {
  const { setNextDisabled } = useOutletContext();
  const [isLoading, setLoading] = useState(false);
  const [assignedStudies, setAssignedStudies] = useState([]);
  const [selectedStudy, setSelectedStudy] = useState('');
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();

  const getAssignedStudies = async () => {
    setLoading(true);
    try {
      const response = await dispatch(GetAssignedProtocolDetails()).then(unwrapResult);
      if (response?.data?.success && response?.data?.assignedProtocol) {
        setAssignedStudies(
          response.data.assignedProtocol?.map((study) => {
            const id = uuidv4();
            return {
              ...study,
              key: id,
              id,
              sourceDisplayName: study.source
                ?.split(',')
                .map((source) => getDisplayName(source))
                .join(',')
            };
          })
        );
      } else {
        dispatch(showBanner({ variant: 'error', message: response.data.success }));
      }
      setLoading(false);
    } catch (error) {
      console.log('Error ::', error);
      setLoading(false);
    }
  };

  const handleSelectRow = (protocolNumber) => {
    setSelectedStudy(protocolNumber);
  };

  useEffect(() => {
    getAssignedStudies();
  }, []);

  useEffect(() => {
    const selectedSourceStudy = location?.state?.selectedSourceStudy;
    if (selectedSourceStudy?.protocolNumber) {
      setSelectedStudy(selectedSourceStudy.protocolNumber.value);
      setNextDisabled(false);
    }
  }, [location.state]);

  useEffect(() => {
    if (selectedStudy && assignedStudies?.length) {
      const { projectCode, protocolNumber, source, sponsor, therapeuticArea } =
        assignedStudies.find((study) => study.protocolNumber === selectedStudy);
      navigate('.', {
        state: {
          selectedSourceStudy: {
            protocolNumber: { label: 'Protocol Number', value: protocolNumber, order: 1 },
            sponsor: { label: 'Sponsor', value: sponsor, order: 2 },
            projectCode: { label: 'Project Code', value: projectCode, order: 3 },
            therapeuticArea: { label: 'Therapeutic Area', value: therapeuticArea, order: 4 },
            source: { label: 'Source', value: source, order: 5 }
          }
        }
      });
      setNextDisabled(false);
    }
  }, [selectedStudy]);

  const rowData = useMemo(() => {
    return assignedStudies
      .filter((study) => {
        const { protocolNumber, sponsor, projectCode, therapeuticArea, source } = study;
        return [protocolNumber, sponsor, projectCode, therapeuticArea, source]
          .filter(Boolean)
          .join('')
          .toLowerCase()
          .includes(searchText.toLowerCase());
      })
      .map((study) => ({ ...study, handleSelectRow, selectedStudy, searchText }));
  }, [assignedStudies, selectedStudy, searchText]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Title>Select a study</Title>
        <Caption>
          Choose the study containing the data product to clone. (Only listing studies with
          validated mapping specs.)
        </Caption>
      </Grid>
      <TableWrapper item xs={12}>
        <Table
          isLoading={isLoading}
          columns={columns}
          rows={rowData}
          rowsPerPageOptions={[10, 50, 100, 'All']}
          hasScroll
          maxHeight={650}
          tablePaginationProps={{
            truncate: true
          }}
          CustomHeader={CustomHeader}
          headerProps={{ searchText, setSearchText }}
          defaultRowsPerPage={10}
        />
      </TableWrapper>
    </Grid>
  );
}

export default SelectSourceStudy;
