export const TIMEZONES_MAPPING = {
  'Africa/Abidjan': 'GMT',
  'Africa/Accra': 'GMT',
  'Africa/Addis_Ababa': 'EAT',
  'Africa/Algiers': 'CEST',
  'Africa/Asmera': 'EAT',
  'Africa/Bamako': 'GMT',
  'Africa/Bangui': 'WAST',
  'Africa/Banjul': 'GMT',
  'Africa/Bissau': 'GMT',
  'Africa/Blantyre': 'CAT',
  'Africa/Brazzaville': 'WAST',
  'Africa/Bujumbura': 'CAT',
  'Africa/Cairo': 'EEST',
  'Africa/Casablanca': 'WEST',
  'Africa/Ceuta': 'CEST',
  'Africa/Conakry': 'GMT',
  'Africa/Dakar': 'GMT',
  'Africa/Dar_es_Salaam': 'EAT',
  'Africa/Djibouti': 'EAT',
  'Africa/Douala': 'WAST',
  'Africa/El_Aaiun': 'WS',
  'Africa/Freetown': 'GMT',
  'Africa/Gaborone': 'CAT',
  'Africa/Harare': 'CAT',
  'Africa/Johannesburg': 'SAST',
  'Africa/Juba': 'CAT',
  'Africa/Kampala': 'EAT',
  'Africa/Khartoum': 'CAT',
  'Africa/Kigali': 'CAT',
  'Africa/Kinshasa': 'WAST',
  'Africa/Lagos': 'WAST',
  'Africa/Libreville': 'WAST',
  'Africa/Lome': 'GMT',
  'Africa/Luanda': 'WAST',
  'Africa/Lubumbashi': 'CAT',
  'Africa/Lusaka': 'CAT',
  'Africa/Malabo': 'WAST',
  'Africa/Maputo': 'CAT',
  'Africa/Maseru': 'SAST',
  'Africa/Mbabane': 'SAST',
  'Africa/Mogadishu': 'EAT',
  'Africa/Monrovia': 'GMT',
  'Africa/Nairobi': 'EAT',
  'Africa/Ndjamena': 'WAST',
  'Africa/Niamey': 'WAST',
  'Africa/Nouakchott': 'GMT',
  'Africa/Ouagadougou': 'GMT',
  'Africa/Porto-Novo': 'WAST',
  'Africa/Sao_Tome': 'GMT',
  'Africa/Tripoli': 'EEST',
  'Africa/Tunis': 'CEST',
  'Africa/Windhoek': 'CAT',
  'America/Adak': 'HST',
  'America/Anchorage': 'AST',
  'America/Anguilla': 'AST',
  'America/Antigua': 'AST',
  'America/Araguaina': 'BST',
  'America/Argentina/La_Rioja': 'AST',
  'America/Argentina/Rio_Gallegos': 'AST',
  'America/Argentina/Salta': 'AST',
  'America/Argentina/San_Juan': 'AST',
  'America/Argentina/San_Luis': 'AST',
  'America/Argentina/Tucuman': 'AST',
  'America/Argentina/Ushuaia': 'AST',
  'America/Aruba': 'AST',
  'America/Asuncion': 'PST',
  'America/Bahia': 'BST',
  'America/Bahia_Banderas': 'CST',
  'America/Barbados': 'AST',
  'America/Belem': 'BST',
  'America/Belize': 'CST',
  'America/Blanc-Sablon': 'AST',
  'America/Boa_Vista': 'AST',
  'America/Bogota': 'CST',
  'America/Boise': 'MST',
  'America/Buenos_Aires': 'AST',
  'America/Cambridge_Bay': 'MST',
  'America/Campo_Grande': 'AST',
  'America/Cancun': 'EST',
  'America/Caracas': 'VT',
  'America/Catamarca': 'AST',
  'America/Cayenne': 'FGT',
  'America/Cayman': 'EST',
  'America/Chicago': 'CST',
  'America/Chihuahua': 'CST',
  'America/Ciudad_Juarez': 'MST',
  'America/Coral_Harbour': 'EST',
  'America/Cordoba': 'AST',
  'America/Costa_Rica': 'CST',
  'America/Creston': 'MST',
  'America/Cuiaba': 'AST',
  'America/Curacao': 'AST',
  'America/Danmarkshavn': 'GMT',
  'America/Dawson': 'YT',
  'America/Dawson_Creek': 'MST',
  'America/Denver': 'MST',
  'America/Detroit': 'EST',
  'America/Dominica': 'AST',
  'America/Edmonton': 'MST',
  'America/Eirunepe': 'AST',
  'America/El_Salvador': 'CST',
  'America/Fort_Nelson': 'MST',
  'America/Fortaleza': 'BST',
  'America/Glace_Bay': 'AST',
  'America/Godthab': 'WGST',
  'America/Goose_Bay': 'AST',
  'America/Grand_Turk': 'EST',
  'America/Grenada': 'AST',
  'America/Guadeloupe': 'AST',
  'America/Guatemala': 'CST',
  'America/Guayaquil': 'ET',
  'America/Guyana': 'GT',
  'America/Halifax': 'AST',
  'America/Havana': 'CST',
  'America/Hermosillo': 'MPST',
  'America/Indiana/Knox': 'CST',
  'America/Indiana/Marengo': 'EST',
  'America/Indiana/Petersburg': 'EST',
  'America/Indiana/Tell_City': 'CST',
  'America/Indiana/Vevay': 'EST',
  'America/Indiana/Vincennes': 'EST',
  'America/Indiana/Winamac': 'EST',
  'America/Indianapolis': 'EST',
  'America/Inuvik': 'MST',
  'America/Iqaluit': 'EST',
  'America/Jamaica': 'EST',
  'America/Jujuy': 'AST',
  'America/Juneau': 'AST',
  'America/Kentucky/Monticello': 'EST',
  'America/Kralendijk': 'AST',
  'America/La_Paz': 'BT',
  'America/Lima': 'PST',
  'America/Los_Angeles': 'PST',
  'America/Louisville': 'EST',
  'America/Lower_Princes': 'AST',
  'America/Maceio': 'BST',
  'America/Managua': 'CST',
  'America/Manaus': 'AST',
  'America/Marigot': 'AST',
  'America/Martinique': 'AST',
  'America/Matamoros': 'CST',
  'America/Mazatlan': 'MPST',
  'America/Mendoza': 'AST',
  'America/Menominee': 'CST',
  'America/Merida': 'CST',
  'America/Metlakatla': 'AST',
  'America/Mexico_City': 'CST',
  'America/Miquelon': 'SP&MST',
  'America/Moncton': 'AST',
  'America/Monterrey': 'CST',
  'America/Montevideo': 'UST',
  'America/Montserrat': 'AST',
  'America/Nassau': 'EST',
  'America/New_York': 'EST',
  'America/Nipigon': 'EST',
  'America/Nome': 'AST',
  'America/Noronha': 'FdNST',
  'America/North_Dakota/Beulah': 'CST',
  'America/North_Dakota/Center': 'CST',
  'America/North_Dakota/New_Salem': 'CST',
  'America/Ojinaga': 'CST',
  'America/Panama': 'EST',
  'America/Pangnirtung': 'EST',
  'America/Paramaribo': 'ST',
  'America/Phoenix': 'MST',
  'America/Port-au-Prince': 'EST',
  'America/Port_of_Spain': 'AST',
  'America/Porto_Velho': 'AST',
  'America/Puerto_Rico': 'AST',
  'America/Punta_Arenas': 'CLST',
  'America/Rainy_River': 'CST',
  'America/Rankin_Inlet': 'CST',
  'America/Recife': 'BST',
  'America/Regina': 'CST',
  'America/Resolute': 'CST',
  'America/Rio_Branco': 'AST',
  'America/Santa_Isabel': 'NMST',
  'America/Santarem': 'BST',
  'America/Santiago': 'CST',
  'America/Santo_Domingo': 'AST',
  'America/Sao_Paulo': 'BST',
  'America/Scoresbysund': 'EGST',
  'America/Sitka': 'AST',
  'America/St_Barthelemy': 'AST',
  'America/St_Johns': 'NST',
  'America/St_Kitts': 'AST',
  'America/St_Lucia': 'AST',
  'America/St_Thomas': 'AST',
  'America/St_Vincent': 'AST',
  'America/Swift_Current': 'CST',
  'America/Tegucigalpa': 'CST',
  'America/Thule': 'AST',
  'America/Thunder_Bay': 'EST',
  'America/Tijuana': 'PST',
  'America/Toronto': 'EST',
  'America/Tortola': 'AST',
  'America/Vancouver': 'PST',
  'America/Whitehorse': 'YT',
  'America/Winnipeg': 'CST',
  'America/Yakutat': 'AST',
  'America/Yellowknife': 'MST',
  'Antarctica/Casey': 'CT',
  'Antarctica/Davis': 'DT',
  'Antarctica/DumontDUrville': 'DT',
  'Antarctica/Macquarie': 'AEDT',
  'Antarctica/Mawson': 'MT',
  'Antarctica/McMurdo': 'NZDT',
  'Antarctica/Palmer': 'G',
  'Antarctica/Rothera': 'RT',
  'Antarctica/Syowa': 'ST',
  'Antarctica/Troll': 'GMT',
  'Antarctica/Vostok': 'VT',
  'Arctic/Longyearbyen': 'CEST',
  'Asia/Aden': 'AST',
  'Asia/Almaty': 'EKT',
  'Asia/Amman': 'EEST',
  'Asia/Anadyr': 'AST',
  'Asia/Aqtau': 'WKT',
  'Asia/Aqtobe': 'WKT',
  'Asia/Ashgabat': 'TST',
  'Asia/Atyrau': 'WKT',
  'Asia/Baghdad': 'AST',
  'Asia/Bahrain': 'AST',
  'Asia/Baku': 'AST',
  'Asia/Bangkok': 'IT',
  'Asia/Barnaul': 'G',
  'Asia/Beirut': 'EEST',
  'Asia/Bishkek': 'KT',
  'Asia/Brunei': 'BDT',
  'Asia/Calcutta': 'IST',
  'Asia/Chita': 'YST',
  'Asia/Choibalsan': 'UST',
  'Asia/Colombo': 'IST',
  'Asia/Damascus': 'G',
  'Asia/Dhaka': 'BST',
  'Asia/Dili': 'ETT',
  'Asia/Dubai': 'GST',
  'Asia/Dushanbe': 'TT',
  'Asia/Famagusta': 'G',
  'Asia/Gaza': 'EEST',
  'Asia/Hebron': 'EEST',
  'Asia/Hong_Kong': 'HKST',
  'Asia/Hovd': 'HST',
  'Asia/Irkutsk': 'IST',
  'Asia/Jakarta': 'WIT',
  'Asia/Jayapura': 'EIT',
  'Asia/Jerusalem': 'IST',
  'Asia/Kabul': 'AT',
  'Asia/Kamchatka': 'PST',
  'Asia/Karachi': 'PST',
  'Asia/Katmandu': 'NT',
  'Asia/Khandyga': 'YST',
  'Asia/Krasnoyarsk': 'KST',
  'Asia/Kuala_Lumpur': 'MT',
  'Asia/Kuching': 'MT',
  'Asia/Kuwait': 'AST',
  'Asia/Macau': 'CST',
  'Asia/Magadan': 'MST',
  'Asia/Makassar': 'CIT',
  'Asia/Manila': 'PST',
  'Asia/Muscat': 'GST',
  'Asia/Nicosia': 'EEST',
  'Asia/Novokuznetsk': 'KST',
  'Asia/Novosibirsk': 'NST',
  'Asia/Omsk': 'OST',
  'Asia/Oral': 'WKT',
  'Asia/Phnom_Penh': 'IT',
  'Asia/Pontianak': 'WIT',
  'Asia/Pyongyang': 'KST',
  'Asia/Qatar': 'AST',
  'Asia/Qostanay': 'EKT',
  'Asia/Qyzylorda': 'WKT',
  'Asia/Rangoon': 'MT',
  'Asia/Riyadh': 'AST',
  'Asia/Saigon': 'IT',
  'Asia/Sakhalin': 'SST',
  'Asia/Samarkand': 'UST',
  'Asia/Seoul': 'KST',
  'Asia/Shanghai': 'CST',
  'Asia/Singapore': 'SST',
  'Asia/Srednekolymsk': 'G',
  'Asia/Taipei': 'TST',
  'Asia/Tashkent': 'UST',
  'Asia/Tbilisi': 'GST',
  'Asia/Tehran': 'IST',
  'Asia/Thimphu': 'BT',
  'Asia/Tokyo': 'JST',
  'Asia/Tomsk': 'G',
  'Asia/Ulaanbaatar': 'UST',
  'Asia/Urumqi': 'G',
  'Asia/Ust-Nera': 'VST',
  'Asia/Vientiane': 'IT',
  'Asia/Vladivostok': 'VST',
  'Asia/Yakutsk': 'YST',
  'Asia/Yekaterinburg': 'YST',
  'Asia/Yerevan': 'AST',
  'Atlantic/Azores': 'AST',
  'Atlantic/Bermuda': 'AST',
  'Atlantic/Canary': 'WEST',
  'Atlantic/Cape_Verde': 'CVST',
  'Atlantic/Faeroe': 'WEST',
  'Atlantic/Madeira': 'WEST',
  'Atlantic/Reykjavik': 'GMT',
  'Atlantic/South_Georgia': 'SGT',
  'Atlantic/St_Helena': 'GMT',
  'Atlantic/Stanley': 'FIST',
  'Australia/Adelaide': 'ACDT',
  'Australia/Brisbane': 'AEST',
  'Australia/Broken_Hill': 'ACDT',
  'Australia/Currie': 'AEDT',
  'Australia/Darwin': 'ACST',
  'Australia/Eucla': 'ACWST',
  'Australia/Hobart': 'AEDT',
  'Australia/Lindeman': 'AEST',
  'Australia/Lord_Howe': 'LHDT',
  'Australia/Melbourne': 'AEDT',
  'Australia/Perth': 'AWST',
  'Australia/Sydney': 'AEDT',
  'Europe/Amsterdam': 'CEST',
  'Europe/Andorra': 'CEST',
  'Europe/Astrakhan': 'G',
  'Europe/Athens': 'EEST',
  'Europe/Belgrade': 'CEST',
  'Europe/Berlin': 'CEST',
  'Europe/Bratislava': 'CEST',
  'Europe/Brussels': 'CEST',
  'Europe/Bucharest': 'EEST',
  'Europe/Budapest': 'CEST',
  'Europe/Busingen': 'CEST',
  'Europe/Chisinau': 'EEST',
  'Europe/Copenhagen': 'CEST',
  'Europe/Dublin': 'GMT',
  'Europe/Gibraltar': 'CEST',
  'Europe/Guernsey': 'GMT',
  'Europe/Helsinki': 'EEST',
  'Europe/Isle_of_Man': 'GMT',
  'Europe/Istanbul': 'G',
  'Europe/Jersey': 'GMT',
  'Europe/Kaliningrad': 'EEST',
  'Europe/Kiev': 'EEST',
  'Europe/Kirov': 'G',
  'Europe/Lisbon': 'WEST',
  'Europe/Ljubljana': 'CEST',
  'Europe/London': 'GMT',
  'Europe/Luxembourg': 'CEST',
  'Europe/Madrid': 'CEST',
  'Europe/Malta': 'CEST',
  'Europe/Mariehamn': 'EEST',
  'Europe/Minsk': 'MST',
  'Europe/Monaco': 'CEST',
  'Europe/Moscow': 'MST',
  'Europe/Oslo': 'CEST',
  'Europe/Paris': 'CEST',
  'Europe/Podgorica': 'CEST',
  'Europe/Prague': 'CEST',
  'Europe/Riga': 'EEST',
  'Europe/Rome': 'CEST',
  'Europe/Samara': 'SST',
  'Europe/San_Marino': 'CEST',
  'Europe/Sarajevo': 'CEST',
  'Europe/Saratov': 'G',
  'Europe/Simferopol': 'MST',
  'Europe/Skopje': 'CEST',
  'Europe/Sofia': 'EEST',
  'Europe/Stockholm': 'CEST',
  'Europe/Tallinn': 'EEST',
  'Europe/Tirane': 'CEST',
  'Europe/Ulyanovsk': 'G',
  'Europe/Uzhgorod': 'EEST',
  'Europe/Vaduz': 'CEST',
  'Europe/Vatican': 'CEST',
  'Europe/Vienna': 'CEST',
  'Europe/Vilnius': 'EEST',
  'Europe/Volgograd': 'VST',
  'Europe/Warsaw': 'CEST',
  'Europe/Zagreb': 'CEST',
  'Europe/Zaporozhye': 'EEST',
  'Europe/Zurich': 'CEST',
  'Indian/Antananarivo': 'EAT',
  'Indian/Chagos': 'IOT',
  'Indian/Christmas': 'CIT',
  'Indian/Cocos': 'CIT',
  'Indian/Comoro': 'EAT',
  'Indian/Kerguelen': 'FS&AT',
  'Indian/Mahe': 'ST',
  'Indian/Maldives': 'MT',
  'Indian/Mauritius': 'MST',
  'Indian/Mayotte': 'EAT',
  'Indian/Reunion': 'RT',
  'Pacific/Apia': 'AST',
  'Pacific/Auckland': 'NZDT',
  'Pacific/Bougainville': 'G',
  'Pacific/Chatham': 'CDT',
  'Pacific/Easter': 'EIST',
  'Pacific/Efate': 'VST',
  'Pacific/Enderbury': 'PIT',
  'Pacific/Fakaofo': 'TT',
  'Pacific/Fiji': 'FST',
  'Pacific/Funafuti': 'TT',
  'Pacific/Galapagos': 'GT',
  'Pacific/Gambier': 'GT',
  'Pacific/Guadalcanal': 'SIT',
  'Pacific/Guam': 'CST',
  'Pacific/Honolulu': 'HST',
  'Pacific/Johnston': 'HST',
  'Pacific/Kiritimati': 'LIT',
  'Pacific/Kosrae': 'KT',
  'Pacific/Kwajalein': 'MIT',
  'Pacific/Majuro': 'MIT',
  'Pacific/Marquesas': 'MT',
  'Pacific/Midway': 'SST',
  'Pacific/Nauru': 'NT',
  'Pacific/Niue': 'NT',
  'Pacific/Norfolk': 'NIDT',
  'Pacific/Noumea': 'NCST',
  'Pacific/Pago_Pago': 'SST',
  'Pacific/Palau': 'PT',
  'Pacific/Pitcairn': 'PT',
  'Pacific/Ponape': 'PT',
  'Pacific/Port_Moresby': 'PNGT',
  'Pacific/Rarotonga': 'CIST',
  'Pacific/Saipan': 'CST',
  'Pacific/Tahiti': 'TT',
  'Pacific/Tarawa': 'GIT',
  'Pacific/Tongatapu': 'TST',
  'Pacific/Truk': 'CT',
  'Pacific/Wake': 'WIT',
  'Pacific/Wallis': 'W&FT'
};
