import LoginPageForm from './LoginPage';
import LoginLayout from 'Layout/LoginLayout';
import Logout from './LogoutPage/Logout';
const routes = [
  // {
  //   path: '/login-screen',
  //   component: LoginPageForm,
  //   layout: LoginLayout,
  //   exact: true,
  //   authentication: false
  // },
  {
    path: '/logout',
    component: Logout,
    layout: Logout,
    exact: true,
    authentication: false
  },
  {
    path: '/',
    component: LoginPageForm,
    layout: LoginLayout,
    exact: true,
    authentication: false
  }
];

export default routes;
