export const getMethodsList = (methodsAndConstantsResponse) => {
    if (
      methodsAndConstantsResponse &&
      methodsAndConstantsResponse.data &&
      methodsAndConstantsResponse.data.success
    ) {
      let methodsAndConstants = [];
      let methods = [];
      methodsAndConstantsResponse.data.listMethodsConstants.forEach((item) => {
        item.configName === "Method" && methods.push(item.displayName)
        methodsAndConstants.push(item.displayName);
      });
    return {methodsAndConstants , methods};
    } else {
        return false;
    }
  };

  export const getRuleList = (expressionTemplatesResponse) => {
    if (expressionTemplatesResponse?.data?.success) {
      let expressionTemplates = [];
      expressionTemplatesResponse.data.ruleExpressionTemplates.forEach((item) => {
        expressionTemplates.push(item);
      });
      return expressionTemplates;
    } else {
        return false;
    }
  };