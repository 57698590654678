/*eslint-disable*/
import CircularProgress from '@mui/material/CircularProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import FileUpload from 'apollo-react/components/FileUpload';
import CustomModal from 'Components/Modal';
import _ from 'lodash';
import Grid from 'apollo-react/components/Grid';

import React, { useEffect, useState } from 'react';
import { checkColumns, checkFileData, checkFileType } from '../Utils/FileValidationUtils';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteReferenceData, GetTrialArmsData } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setReferenceData } from 'service/reference-data.service';
import { convertExcelToJson, getHeaders, jsonToExcel, jsonToFileData, uuidv4 } from 'Utils';
import { trialArmsValidator } from 'Validators/TrialArms.Validator';
import Card from './Card';
import { trialArmsColumnNames } from './TrialArmsUtils';
import './Trials.css';
const allowedExtensions = ['xlsx', 'xls'];

const TrialArms = (props) => {
  const navigate = useNavigate();
  const { id: mappingRuleVersionId } = useParams();
  const [trialArmsData, setTrialArmsData] = useState([]);
  const [cardArray, setCardArray] = useState([]);
  const [trialArmsValidationResult, setTrialArmsValidationResult] = useState({});
  const [mappingError, setMappingError] = useState('');

  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [isFileUploadDisable, setIsFileUploadDisable] = useState(true);
  const [trialArmsUploadedFile, setTrialArmsUploadedFile] = useState([]);
  const [trialArmsPreview, setTrialArmsPreview] = useState(true);
  const [error, setError] = useState('');
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [trialArmsMessage, setTimeArmsMessage] = useState('');
  const dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(false);
  const [isValidData, setIsValidData] = useState(false);
  const TRIAL_ARMS = 'trial-arms';

  const downloadSheet = () => {
    let trialArmsEmptyRow = {};
    for (let displayName of Object.keys(trialArmsColumnNames)) {
      trialArmsEmptyRow[displayName] = '';
    }
    jsonToExcel([trialArmsEmptyRow], 'TrialArms.xlsx');
    setIsFileUploadDisable(false);
  };

  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };
  useEffect(() => {
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['TrialArms']) {
      if (!trialArmsPreview) {
        const element = document.getElementById('anchor-preview');
        element.click();
        setMappingError(props.createMappingObj['message']);
        props.createMappingObj['TrialArms'] = false;
      }
    }
  }, [trialArmsPreview]);

  const confirmOnDelete = () => {
    trialArmsUploadedFile.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          onConfirm: () => {
            setConfirmAlert({
              enabled: false
            });
            deleteUploadedFile();
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'TA',
      mappingRuleVersionID: mappingRuleVersionId
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
    setTrialArmsUploadedFile([]);
    setTrialArmsPreview(true);
    setTrialArmsData([...trialArmsData]);
    const index = cardArray.findIndex((el) => el === TRIAL_ARMS);
    props.configuration.setCardVisibility(TRIAL_ARMS, false);
    cardArray.splice(index, 1);
  };

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingRuleVersionId
      };
      const trialArmsGetAPIData = await dispatch(GetTrialArmsData(payload)).then(unwrapResult);
      props.configuration.setIsCompleted(TRIAL_ARMS, true);
      props.configuration.setModuleValidation(TRIAL_ARMS, true);
      if (trialArmsGetAPIData && trialArmsGetAPIData.data && trialArmsGetAPIData.data.success) {
        if (trialArmsGetAPIData.data.trailArms && trialArmsGetAPIData.data.trailArms.length > 0) {
          const newData = trialArmsGetAPIData.data.trailArms.map((item) => {
            let trialArmsItem = {};
            for (let apiLabel of Object.values(trialArmsColumnNames)) {
              trialArmsItem[apiLabel] = item[apiLabel];
            }
            trialArmsItem.id = uuidv4();
            return trialArmsItem;
          });
          const validationResult = trialArmsValidator(newData);
          const errorCountFlag = validationResult.errorCount;
          errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
          setTrialArmsData(newData);
          setTrialArmsValidationResult(validationResult);
          let fileObj = jsonToFileData(newData, 'TrialArms.xlsx');
          setTrialArmsUploadedFile([fileObj]);
          setTrialArmsPreview(false);
          setIsValidData(true);
          props.configuration.setModuleValidation(TRIAL_ARMS, errorCountFlag > 0 ? false : true);
          props.configuration.setCardVisibility(TRIAL_ARMS, true);
        } else {
          props.required && props.configuration.setModuleValidation(TRIAL_ARMS, false);
        }
      } else {
        setTimeArmsMessage(trialArmsGetAPIData.data.message);
        setIsValidData(false);
        // dispatch(showBanner({ variant: 'error', message: trialArmsData.data.message }));
      }
    })();
  }, []);

  const columns = ['ARMCD', 'ARM', 'TAETORD', 'ETCD', 'ELEMENT', 'TABRANCH', 'TATRANS', 'EPOCH'];

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const handleUpload = async (selectedFiles) => {
    setUploadProgress(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJSON = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);
      const isError = fileValidation(fileJSON, headers);
      let newFileArr = [];
      if (!isError) {
        fileJSON.map((obj) => {
          var lowerObj = _.transform(obj, function (result, val, key) {
            result[trialArmsColumnNames[key]] = val;
          });
          lowerObj.id = uuidv4();
          newFileArr.push(lowerObj);
        });
        const validationResult = trialArmsValidator(newFileArr);
        const errorCountFlag = validationResult.errorCount;
        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        setTrialArmsValidationResult(validationResult);
        setTrialArmsData(newFileArr);
        setError('');
        setTrialArmsUploadedFile([selectedFiles[0]]);
        setTrialArmsPreview(false);
        setReferenceData('trial-arm', newFileArr);
        setReferenceData('trial-arm-validationResult', validationResult);
        navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/trial-arm`);
      } else {
        setTrialArmsData([]);
        setTrialArmsUploadedFile([]);
        // props.configuration.setModuleValidation('trial-arms', false, isError);
        setError(isError);
        setTrialArmsPreview(true);
      }
    }
    setUploadProgress(false);
  };

  const handleEditPreview = (isUploaded, newFileArr) => {
    if (isUploaded || trialArmsData) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/trial-arm`);
    }
  };

  const handleDelete = () => {
    deleteUploadedFile();
    setDeleteFile(false);
    setError('');
  };

  const openDeleteModal = () => {
    setDeleteFile(true);
  };
  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        required={props.required}
        refMsg={trialArmsMessage}
        setErrorFlag={errorFlag}
        handleDownload={downloadSheet}
        isEditDisable={trialArmsPreview}
        handleEditPreview={() => handleEditPreview()}>
        <div style={{ margin: '0px 10px' }}>
          {uploadProgress && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={uploadProgress}
              value={trialArmsUploadedFile}
              // disabled={isFileUploadDisable}
              onUpload={handleUpload}
              onFileDelete={openDeleteModal}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
              //   {...this.props}
            />
          </div>

          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        // handlePrimaryAction={() => handleDelete()}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </Grid>
  );
};
export default TrialArms;
