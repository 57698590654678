/*eslint-disable */

import { createSlice } from '@reduxjs/toolkit';
export const DebounceSlice = createSlice({
  name: 'DebounceData',
  initialState: {
    filteredProtocols: [],
    noResults: false,
    filteredText:''
  },

  reducers: {
    setFilteredProtocols(state, { payload }) {
      state.filteredProtocols = payload;
    },
    setNoResults(state, { payload }) {
      state.noResults = payload;
    },
    setFilteredText(state, { payload }) {
      state.filteredText = payload;
    },
  }
});

export const { setFilteredProtocols, setNoResults, setFilteredText } = DebounceSlice.actions;