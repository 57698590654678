import Grid from 'apollo-react/components/Grid';
import { makeStyles } from '@mui/styles';
import { neutral1 } from 'apollo-react/colors';
import Panel from 'apollo-react/components/Panel';
import { BannerExample } from 'Components/Banner/Banner';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import StandardLibraryHeader from 'Components/StandardLibraryHeader';
import StudyLibraryDrawer from 'Components/StudyLibraryDrawer';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProtocol } from 'Redux/Slice/StudyLibrarySlice';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import { openStudyPanel, setClosePanelText } from '../../Redux/Slice/StudyLibrarySlice';
import './StudyLibraryLayout.css';
import { useLocation } from 'react-router-dom';

const useStyles = ([projectHeaderVisible, panelOpen]) =>
  makeStyles({
    container: {
      width: '100%',
      marginTop: projectHeaderVisible ? '113px' : '57px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: neutral1,
      boxSizing: 'border-box',
      justifyContent: 'flex-start'
    },
    root: {
      backgroundColor: '#f5f6fa'
    },
    panelRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: `calc(100vh - ${projectHeaderVisible ? '113px' : '57px'})`
    },
    panelsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',

      '&> div:nth-child(1)': {
        height: `calc(100vh - ${projectHeaderVisible ? '113px' : '57px'})`,
        position: 'sticky',
        top: projectHeaderVisible ? '113px' : '57px',
        zIndex: 1,
        overflowY: 'clip'
      },

      '&> div:nth-child(2)': {
        width: `calc(100% - ${panelOpen ? '354px' : '26px'}) !important`,
        backgroundColor: '#f5f6fa'
      }
    }
  });

const StudyLibraryLayout = (props) => {
  const dispatch = useDispatch();
  const { protocol, openPanelDSL, protocols } = useSelector((state) => state.StudyLibraryData);
  const location = useLocation();
  let path = location.pathname;
  const projectHeaderDisplayed = useMemo(() => {
    return Boolean(protocol && Object.keys(protocol).length !== 0);
  }, [protocol]);

  const classes = useStyles([projectHeaderDisplayed, openPanelDSL])();

  const onPanelClose = () => {
    dispatch(openStudyPanel(false));
    dispatch(setClosePanelText(false));
  };

  const onPanelOpen = () => {
    dispatch(setClosePanelText(true));
    dispatch(openStudyPanel(true));
  };

  useEffect(() => {
    if (
      protocol &&
      Object.keys(protocol).length !== 0 &&
      path !== '/data-standard-library/study-library'
    ) {
      protocols?.sMProtocols?.map((protocolData) => {
        if (protocolData.protocolNumber === protocol.protocolNumber) {
          dispatch(getProtocol(protocolData));
        }
      });
    } else {
      dispatch(getProtocol(protocol));
    }
  }, [protocol]);

  useEffect(() => {
    if (path === '/data-standard-library/study-library') {
      dispatch(getProtocol(protocol));
    }
  }, [path]);

  return (
    <Grid container className={classes.root}>
      <Header />
      {projectHeaderDisplayed && <ProjectStudyHeader />}
      <BannerExample />

      <div className={`${classes.container}`}>
        <Breadcrumb studyGridPin={true} />
        <div className="standard-library" style={{ width: '100%' }}>
          <div className="standard-container">
            <StandardLibraryHeader tabValue={0} />
          </div>
        </div>
        <Grid className={classes.panelsContainer}>
          <Panel width={350} onClose={onPanelClose} onOpen={onPanelOpen} open={openPanelDSL}>
            <StudyLibraryDrawer />
          </Panel>
          <Panel hideButton className={classes.panelRight}>
            {props.children}
            <Footer width={'100%'} />
          </Panel>
        </Grid>
      </div>
    </Grid>
  );
};

export default StudyLibraryLayout;
