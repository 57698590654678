/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import validate from 'Pages/Validator/validate';
import { generateErrorString, getHtmlString } from 'Utils';

const allForms = ['SV', 'CM'];
const allItems = ['SITE', 'RANDOM', 'TEST'];

const forms = { SV: ['SITE', 'RANDOM'], CM: ['TEST'] };

const Validator = () => {
  const [formName, setFormName] = useState('');
  const [itemName, setItemName] = useState('');
  const [errors, setErrors] = useState([]);
  const [result, setResult] = useState({ isValid: true });

  const [caretPos, setCaretPos] = useState(0);

  const onValidate = () => {
    const editor = document.getElementById('editor');
    const textContent = editor.textContent;
    let result = {
      isValid: true,
      message: '',
      cordinates: { start: 0, end: textContent.length }
    };

    result = validate(textContent, allForms[0], {}, forms);

    const string = !result.isValid
      ? generateErrorString(textContent, result?.cordinates?.start, result?.cordinates?.end)
      : getHtmlString(textContent, allForms, allItems);

    editor.innerHTML = string;

    setResult(result);
  };

  const getCaretPosition = (editableDiv) => {
    let caretPos = 0,
      sel,
      range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode === editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() === editableDiv) {
        var tempEl = document.createElement('span');
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint('EndToEnd', range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  };

  const handleForm = (e) => {
    const div = document.getElementById('editor');
    const data = document.getElementById('editor').textContent;
    let caretPosition = getCaretPosition(div);
    if (caretPosition === 0) {
      caretPosition += data.length;
    }
    let formName = `[${e.target.value}].`;
    if (data) {
      formName =
        data.substring(0, caretPosition) + formName + data.substring(caretPosition, data.length);
      div.innerHTML = formName;
    } else {
      div.innerHTML = formName;
    }
    setCaretPos(formName.length);
    setFormName(e.target.value);
  };

  const handleItem = (e) => {
    const div = document.getElementById('editor');
    const data = document.getElementById('editor').textContent;
    let caretPosition = getCaretPosition(div);
    if (caretPosition === 0) {
      caretPosition += data.length;
    }
    let itemName = `[${e.target.value}]`;
    if (data) {
      itemName =
        data.substring(0, caretPosition) + itemName + data.substring(caretPosition, data.length);
      div.innerHTML = itemName;
    } else {
      div.innerHTML = itemName;
    }
    setCaretPos(itemName.length);
    setItemName(e.target.value);
  };

  const handleSelect = (e) => {
    const div = document.getElementById('editor');
    const data = document.getElementById('editor').textContent;
    let caretPosition = getCaretPosition(div);
    if (caretPosition === 0) {
      caretPosition += data.length;
    }
    let method = `.${e.target.value}`;
    if (data) {
      method =
        data.substring(0, caretPosition) + method + data.substring(caretPosition, data.length);
      div.innerHTML = method;
    } else {
      div.innerHTML = method;
    }
    setCaretPos(method.length);
  };

  return (
    <>
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <div>
          <label htmlFor="formName">Form Name</label>
          <select name="formName" id="formName" onChange={handleForm}>
            <option value=""></option>
            <option value="SV">SV</option>
            <option value="CM">CM</option>
          </select>

          <label htmlFor="itemName">Item Name</label>
          <select name="itemName" id="itemName" onChange={handleItem}>
            <option value=""></option>
            <option value="SITE">SITE</option>
            <option value="RANDOM">RANDOM</option>
            <option value="TEST">TEST</option>
          </select>

          <label htmlFor="itemName">Method</label>
          <select name="method" id="method" onChange={handleSelect}>
            <option value=""></option>
            <option value="Min()">Min()</option>
            <option value="Max()">Max()</option>
            <option value="Decode()">Decode()</option>
            <option value="DecodeModifiedText()">DecodeModifiedText()</option>
            <option value="Units()">Units()</option>
            <option value="Choose()">Choose(length, ordinal)</option>
            <option value="DoesVisitExist()">DoesVisitExist()</option>
            <option value="Length()">Length()</option>
            <option value="ODMEventCreateDate()">ODMEventCreateDate()</option>
            <option value="DataEntryDateTime()">DataEntryDateTime()</option>
            <option value="Upcase()">Upcase()</option>
            <option value="ODMEventName()">ODMEventName()</option>
            <option value="Remove()">Remove()</option>
            <option value="SpecifyValue()">SpecifyValue()</option>
            <option value="Substr()">Substr(startIndex, length)</option>
            <option value="DatePart()">DatePart()</option>
            <option value="TimePart()">TimePart()</option>
            <option value="ODMEventSequence()">ODMEventSequence()</option>
            <option value="Pad()">Pad(size, character, left|right)</option>
            <option value="Last()">Last()</option>
          </select>
        </div>

        <div>
          {/* <h1 style={{ color: 'blue', fontSize: '20px', fontWeight: 700 }}>{result.toString()}</h1> */}
          <h1
            style={{
              color: 'red',
              fontSize: '20px',
              fontWeight: 700,
              marginTop: '40px',
              marginLeft: '50px'
            }}>
            {errors}
          </h1>
          <h1 style={{ color: 'red', fontSize: '20px', fontWeight: 700 }}>{result.message}</h1>
        </div>

        <div style={{ textAlign: 'center' }}>
          <div
            key="editor"
            id="editor"
            contentEditable={true}
            style={{
              width: '50%',
              height: '200px',
              border: '1px solid #ccc',
              padding: '50px',
              marginLeft: '300px'
            }}></div>

          <button onClick={onValidate}>Validate</button>
        </div>
      </div>
    </>
  );
};

export default Validator;
