export const getValidateTabs = (mappingRuleVersionID, protocol, rowDataForRuleEditor, userLoginID) => {
    let payloadObj = {
        previewSelectedDatasources: [],
        selectedCDRDataSets: [],
        mappingRuleVersionID: mappingRuleVersionID,
        domainToProcess: '',
        protocolNumber: protocol.protocolNumber,
        productID: rowDataForRuleEditor.productID,
        productVersion: rowDataForRuleEditor.productVersion,
        productName: rowDataForRuleEditor.productNnemonic,
        userId: userLoginID,
        trialNames: {}
    };

    return payloadObj
}

export const getValidateTabsClonePayload = (data, protocol, userLoginID) => {
    let payloadObj = {
        productInfo: {
            "sourceMappingRuleVersionID": data.mappingVersionID,
            "sourceProtocolName": data.sourceProtocol,
            "targetProtocolName": protocol.protocolNumber,
            "sourceDataProductID": data.productID,
            "sourceDataProductVersion": data.productVersion,
            "sourceTargetModelID": data.targetDataModelID,
            "sourceTargetModelVersion": data.targetDataModelVersion,
            "sourceTargetModelName": data.targetDataModelName,
            "targetDataModelType": data.targetDataModelType
        },
        cloneDataMappings: data.cloneDataMappings,
        houseKeepColumns: {
            "iqCreateDate": new Date().toISOString(),
            "iqUpdateDate": new Date().toISOString(),
            "iqCreatedBy": userLoginID,
            "iqUpdatedBy": userLoginID,
            "iqAuditType": 'INSERT',
            "iqAuditDate": new Date().toISOString(),
            "iqActiveFlag": true
        }

    };

    return payloadObj
}