let permissions = {};

export const setPermissions = (value) => {
  permissions = value;
  return value;
};

export const setProtocolPermissions = (value) => {
  permissions = {...permissions , ...value};
  return permissions;
};

export const hasNoAccess = (module, feature) => {
  if (permissions && permissions[module] && permissions[module][feature]) {
    return !permissions[module][feature];
  } else {
    return true;
  }
};

export const hasDashboardPipelineAccess = (permissions, key) => {
  if(permissions && permissions[key]){
    return !permissions[key];
  } 
  return true;
}
