/* eslint-disable */
import ProjectStudyHeader from '../Components/ProjectStudyHeader';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import SourceConfigTable from '../Components/SourceConfigTable';
import Grid from 'apollo-react/components/Grid';
const StudyConfigLibrary = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProjectStudyHeader />
        </Grid>
        <Grid item xs={12}>
        <Breadcrumb sourceConfig={true}/>
        </Grid>
        <Grid item xs={12}>
          <SourceConfigTable />
        </Grid>
      </Grid>
    </>
  );
};

export default StudyConfigLibrary;
