import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

export const GetAssignedStudyLibraries = createAsyncThunk(
  '/dataProduct/GetAssignedStudyLibraries',
  async () => {
    return await axios.get(`${API_URL}/api/DataProduct/GetAssignedStudyLibraries?userId=${Cookies.get('user.id')}`);
  }
);
export const SavePinnedStudies = createAsyncThunk(
  '/dataProduct/SavePinnedStudies',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/SavePinnedStudies`, payload);
  }
);
// export const GetAssignedStudyLibraries = createAsyncThunk(
//   '/dataProduct/GetAssignedStudyLibraries',
//   async () => {
//     return await axios.get(`${API_URL}/api/Library/GetDomainVariables?libraryVersion=SDTM34`);
//   }
// );
