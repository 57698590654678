/*eslint-disable */
import React, { useState } from 'react';
import Modal from 'apollo-react/components/Modal';
import Box from '@mui/material/Box';

import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    zIndex: '5004 !important'
  },
  modal: {
    maxWidth: 980
  }
});

const SearchFields = (props) => {
  const classes = useStyles();
  const { sourceFormItems = {}, onAdd, onCancel } = props;

  const [sourceName, setSourceName] = useState();
  const [formName, setFormName] = useState('');
  const [itemName, setItemName] = useState('');

  const handleFormNameChange = (e) => {
    if (e.target.value && e.target.value !== formName) {
      setItemName();
      setFormName(e.target.value);
      setSourceName(sourceFormItems[e.target.value]?.sourceName);
    }
  };

  const handleSourceFieldChange = (e) => {
    setItemName(e.target.value);
  };

  const buttonProps = [
    {
      label: 'Cancel',
      id: 'btn-cancel',
      onClick: () => {
        onCancel && onCancel();
      }
    },
    {
      label: 'Add',
      id: 'btn-add',
      onClick: () => {
        onAdd && onAdd(formName, itemName, sourceName);
      },
      disabled: !itemName
    }
  ];

  return (
    <>
      <Modal
        {...props}
        open={true}
        onClose={onCancel}
        title={'Search Fields'}
        buttonProps={buttonProps}
        data-testid="SearchFieldsPopUp">
        <Box
          display={'flex'}
          justifyContent={'center'}
          style={{ marginTop: '1rem', marginBottom: '2rem' }}>
          <Select
            label="Source Form/Domain"
            value={formName}
            onChange={handleFormNameChange}
            placeholder="Select one..."
            style={{ width: '20rem', marginRight: '2rem' }}
            SelectProps={{
              MenuProps: {
                PopoverClasses: classes
              }
            }}>
            {Object.keys(sourceFormItems).map((item, index) => {
              return (
                <MenuItem value={item} key={`SourceField-${index}`}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            label="Source Field/Variable"
            value={itemName}
            onChange={handleSourceFieldChange}
            placeholder="Select one..."
            style={{ width: '20rem' }}
            SelectProps={{
              MenuProps: {
                PopoverClasses: classes
              }
            }}>
            {sourceFormItems[formName]?.items?.map((item, index) => {
              return (
                <MenuItem value={item.srcItemName || item} key={`SourceField-${index}`}>
                  {item.srcItemName || item}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Modal>
    </>
  );
};
export default SearchFields;
