/* eslint-disable */
import Radio from 'apollo-react/components/Radio';
import React, { useState, useEffect } from 'react';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import {
  GetViewGlobalLibraries,
  GetLatestGlobalLibrary
} from './../../../Redux/Service/GlobalLibraryService';
import { setTargetDataModal } from './../../../Redux/Slice/DataProductLibrarySlice';
import { showBanner } from 'Redux/Slice/BannerSlice';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import Tooltip from 'apollo-react/components/Tooltip';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import IconButton from 'apollo-react/components/IconButton';
import Typography from 'apollo-react/components/Typography';
import { neutral7, neutral8 } from 'apollo-react/colors';
import Button from 'apollo-react/components/Button';
import Filter from 'apollo-react-icons/Filter';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { dateFilter, compareDates, dateFormatByType, uuidv4 } from 'Utils';
import { DateFilter } from 'Components/Common/DateFilter';
import StudyLibrariesTable from './AddDataProducts/Components/StudyLibrariesTable';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'Components/Common/Notification';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

const NotificationObj = {
  header: ['Target data model updates available', 'Target data model up-to-date'],
  variant: ['warning', 'info'],
  message: [
    'There are updates available in the target data model libraries for this study',
    'There are no updates available for the target data model libraries in this study'
  ]
};

const ActionCell = ({ row, props }) => {
  return (
    <div style={{ marginBottom: '15px' }}>
      <Radio
        key={row.libraryID}
        value={row.libraryID || props.formik.values.global}
        checked={row.selectedRow}
        onChange={() => row.handleSelectRow(row.libraryID)}
      />
      {/* </RadioGroup> */}
    </div>
  );
};

const DetailRow = ({ row }) => {
  return (
    <div style={{ display: 'flex', padding: '8px 0px 8px 8px', flexWrap: 'wrap' }}>
      <Typography style={{ fontWeight: 500, color: neutral8, width: '100%' }}>
        {'Library ID'}
      </Typography>
      <Typography style={{ color: neutral7, width: '100%' }} variant="body2">
        {row.libraryID}
      </Typography>
    </div>
  );
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const ExpandCell = ({ row: { libraryID, handleToggleRow, expanded } }) => {
  return (
    <div style={{ width: 25, display: 'contents', justifyContent: 'end' }}>
      <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
        <IconButton libraryID="expand" size="small" onClick={() => handleToggleRow(libraryID)}>
          {expanded ? <ChevronDown /> : <ChevronRight />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

const EllipsisCell = ({ row, column: { accessor } }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  if (row[accessor]) {
    return (
      <div
        style={{
          whiteSpace: 'nowrap',
          width: '100px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
        <Tooltip variant="light" title={row[accessor]} placement="bottom" open={open}>
          <Typography
            style={{ color: '#595959', fontSize: '14px' }}
            noWrap
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
            {row[accessor]}
          </Typography>
        </Tooltip>
      </div>
    );
  } else {
    return '-';
  }
};

function TableSimple(props) {
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);
  const [globalFlag, setGlobalFlag] = useState(true);
  const [studyFlag, setStudyFlag] = useState(props.studyDataBack || false);
  const [disableStudy, setDisableStudy] = useState(false);
  const [previewRulesetData, setPreviewRulesetData] = useState([]);
  const [rulesetData, setRulesetData] = useState([]);
  const [disableGlobal, setDisableGlobal] = useState(false);
  const { editDataProduct } = useSelector((state) => state.DataProductStudyLibrary);

  const getGlobalOrStudyList = (value, flag) => {
    switch (value) {
      case 'global': {
        setGlobalFlag(flag);
        setStudyFlag(!flag);
        props.formik.values.studyFlag = !flag;
        break;
      }
      case 'study': {
        setGlobalFlag(!flag);
        setStudyFlag(flag);
        props.formik.values.studyFlag = flag;
        break;
      }
    }
  };

  const checkDisable = (type) => {
    if (_.isEmpty(editDataProduct)) {
      if (type === 'study') {
        return disableStudy;
      } else {
        return disableGlobal;
      }
    } else if (editDataProduct?.targetDataModelType === 'Ruleset' && type === 'global') {
      return true;
    } else if (editDataProduct?.targetDataModelType !== 'Ruleset' && type === 'study') {
      return true;
    }
  };

  const CustomButtonHeader = ({ toggleFilters }) => {
    let rulesetFlag = rulesetData?.some((item) => item.newVersion !== item.currentVersion);
    const getNotifications = (type) => {
      if (
        (!_.isEmpty(editDataProduct) &&
          editDataProduct?.latestTargetDataModelVersion > editDataProduct.targetDataModelVersion) ||
        rulesetFlag
      ) {
        return NotificationObj[type][0];
      } else {
        return NotificationObj[type][1];
      }
    };
    return (
      <>
        <div>
          <Tooltip title={disableGlobal && 'Global and Study target models are incompatible.'}>
            <span>
              <Button
                style={{
                  borderRadius: 0,
                  borderBottomLeftRadius: '4px',
                  borderTopLeftRadius: '4px',
                  width: '8rem'
                }}
                onClick={() => getGlobalLibraries()}
                variant={globalFlag ? 'primary' : 'secondary'}
                disabled={checkDisable('global')}>
                Global
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={disableStudy && 'Global and Study target models are incompatible.'}>
            <span>
              <Button
                style={{
                  borderRadius: 0,
                  margin: 0,
                  borderBottomRightRadius: '4px',
                  borderTopRightRadius: '4px',
                  width: '8rem'
                }}
                variant={studyFlag ? 'primary' : 'secondary'}
                onClick={() => getGlobalOrStudyList('study', true)}
                disabled={checkDisable('study')}>
                Study
              </Button>
            </span>
          </Tooltip>
        </div>
        <div>
          <Button
            size="small"
            variant="secondary"
            icon={Filter}
            style={{ marginRight: 15 }}
            onClick={toggleFilters}
            data-testid="filter-button">
            Filter
          </Button>
        </div>
        {!_.isEmpty(editDataProduct) && (
          <div style={{ position: 'absolute', left: 16, right: rulesetFlag ? 30 : 16, top: 75 }}>
            <Notification
              header={getNotifications('header')}
              variant={getNotifications('variant')}
              message={getNotifications('message')}
              style={{ margin: '0 0 1.5rem 0' }}
            />
          </div>
        )}
      </>
    );
  };

  const dispatch = useDispatch();
  const useStyles = makeStyles({
    root: {
      '& .MuiTable-root': {
        marginTop: editDataProduct ? '60px' : 'auto'
      }
    }
  });

  const classes = useStyles();

  const columns = [
    {
      accessor: 'action',
      customCell: ActionCell,
      width: 40
    },
    {
      accessor: 'expand',
      customCell: ExpandCell
    },
    {
      header: 'Category',
      accessor: 'category',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('category'),
      fixedWidth: false
    },
    {
      header: 'Type',
      accessor: 'libraryType',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryType'),
      fixedWidth: false
    },
    {
      header: 'Name',
      accessor: 'libraryName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryName'),
      fixedWidth: false
    },
    {
      header: 'Description',
      accessor: 'description',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('description'),
      fixedWidth: false,
      customCell: EllipsisCell
    },
    {
      header: 'Version',
      accessor: 'version',
      sortFunction: compareNumbers,
      filterFunction: numberSearchFilter('version'),
      filterComponent: IntegerFilter,
      fixedWidth: false
    },
    !_.isEmpty(editDataProduct) && {
      header: 'Reason for Change',
      accessor: 'reasonForChange',
      sortFunction: compareNumbers,
      filterFunction: createStringSearchFilter('reasonForChange'),
      filterComponent: TextFieldFilter,
      fixedWidth: false,
      customCell: EllipsisCell
    },
    {
      header: 'Updated User',
      accessor: 'createdUserid',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('createdUserid'),
      fixedWidth: false
    },
    {
      header: 'Last updated Date',
      accessor: 'createdDate',
      sortFunction: compareDates,
      filterFunction: dateFilter('createdDate'),
      filterComponent: DateFilter,
      fixedWidth: false
    }
  ];

  const getGlobalLibraries = () => {
    setStudyFlag(false);
    props.formik.values.study = {};
    getGlobalOrStudyList('global', true);
    let studyLibrariesData = [];
    let globalLibrariesData = [];
    dispatch(
      editDataProduct
        ? GetLatestGlobalLibrary({
            LibraryID: editDataProduct.targetDataModelID,
            LibraryVersion: editDataProduct.latestTargetDataModelVersion
          })
        : GetViewGlobalLibraries({ IsActive: true, exceptRuleset: true })
    ).then((res) => {
      if (res.payload.data.success) {
        if (editDataProduct) {
          const { libraryDetails } = res.payload.data;
          globalLibrariesData.push({
            ...libraryDetails,
            id: uuidv4(),
            createdDate: dateFormatByType(libraryDetails.auditDate, 'Table')
          });
          setTableData(globalLibrariesData);
          dispatch(setTargetDataModal(res.payload.data.libraryDetails));
          setSelectedRow(res.payload.data?.libraryDetails?.libraryID);
          props.isRowSelected(res.payload.data.libraryDetails);
        } else {
          studyLibrariesData = res.payload.data.libraries.map((el) => ({
            ...el,
            id: uuidv4(),
            createdDate: dateFormatByType(el.auditDate, 'Table')
          }));
          setTableData(studyLibrariesData);
        }
      } else {
        setTableData([]);
        dispatch(showBanner({ variant: 'error', message: res.payload.data.message }));
      }
    });
  };

  const getGlobalOrStudyFlag = () => {
    if (editDataProduct?.targetDataModelType === 'Ruleset' || props.formik.values.studyFlag) {
      setGlobalFlag(false);
      setStudyFlag(true);
    } else {
      setGlobalFlag(true);
      setStudyFlag(false);
      getGlobalLibraries();
    }
  };

  useEffect(() => {
    getGlobalOrStudyFlag();
  }, [editDataProduct]);

  useEffect(() => {
    if (selectedRow !== '') {
      setDisableStudy(true);
    } else {
      setDisableStudy(false);
      props.isRowSelected('');
    }
  }, [selectedRow]);

  const handleSelectRow = (id) => {
    if (selectedRow === id) {
      setSelectedRow('');
      dispatch(setTargetDataModal(''));
      props.formik.values.global = '';
    } else {
      const selectedData = tableData.find((item) => item.libraryID === id);
      props.isRowSelected(selectedData);
      setSelectedRow(id);
      dispatch(setTargetDataModal(selectedData));
      props.formik.values.global = id;
    }
  };
  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  useEffect(() => {
    props.setPreviewRulesetData(previewRulesetData);
  }, [previewRulesetData]);

  useEffect(() => {
    props.setRulesetData(rulesetData);
  }, [rulesetData]);

  useEffect(() => {
    if (props.formik.values.global && !props.formik.studyFlag) {
      const selectedData = tableData.find((item) => item.libraryID === props.formik.values.global);
      props.isRowSelected(selectedData);
      dispatch(setTargetDataModal(selectedData));
      setSelectedRow(props.formik.values.global);
    } else if (props.formik.values.studyFlag) setStudyFlag(true);
  }, []);

  return (
    <>
      {studyFlag || props.formik.values.studyFlag ? (
        <StudyLibrariesTable
          studyFlag
          previewRulesetData={previewRulesetData}
          setPreviewRulesetData={setPreviewRulesetData}
          setGlobalList={getGlobalOrStudyList}
          setRulesetData={setRulesetData}
          cdrRules={props.rulesetData}
          editDataProduct={editDataProduct}
          ellipsisCell={EllipsisCell}
          CustomButtonHeader={(prop) => <CustomButtonHeader {...prop} />}
          setDisableGlobal={setDisableGlobal}
          setDisableStudy={setDisableStudy}
          formik={props.formik}
        />
      ) : (
        <Table
          classes={{ root: classes.root }}
          title={'Select Target Data Model'}
          columns={columns}
          rows={
            tableData &&
            tableData.map((row) => {
              const rowdata = {
                ...row,
                handleSelectRow,
                key: row.libraryID,
                expanded: expandedRows.includes(row.libraryID) ? true : false,
                handleToggleRow
              };
              rowdata.selectedRow = row.libraryID === selectedRow ? selectedRow : '';
              return rowdata;
            })
          }
          rowsPerPageOptions={[10, 20, 50, 100, 'All']}
          hasScroll
          maxHeight={650}
          tablePaginationProps={{
            labelDisplayedRows: DisplayedRowsLabel,
            truncate: true
          }}
          headerProps={{
            globalFlag,
            disableStudy,
            studyFlag,
            editDataProduct,
            getGlobalOrStudyList,
            disableGlobal,
            rulesetData
          }}
          CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
          ExpandableComponent={DetailRow}
          initialSortedColumn="createdDate"
          initialSortOrder="desc"
        />
      )}
    </>
  );
}

export default TableSimple = React.memo(TableSimple);
