/*eslint-disable*/
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from 'Components/Loader/Loader';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveLoaderQueue } from 'Redux/Service/PublishDataProductService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setReloadData, setRowdata, setView } from 'Redux/Slice/DashboardSlice';
import CustomModal from '../../../Components/Modal/Modal';
import MappingSpecDetails from '../../ProductDesigner/Components/PublishMappingSpec/MappingSpecDetails';
import DataProductSummaryTable from './Components/DataProductSummaryTable';
import columns from './Components/columns';
import { GetDataProductSummary } from 'Redux/Service/DashboardService';
import Cookies from 'js-cookie';
import { showLoader, closeLoader } from 'Redux/Slice/LoaderSlice';
import { ApolloProgress } from 'apollo-react';
import { dateFormatByType, uuidv4 } from 'Utils';
import { useNavigate } from 'react-router-dom';
import { setDisplayChangeScheduler } from 'Redux/Slice/PublishMappingSpecSlice';
import ChangeScheduler from 'Pages/Publish/Components/ChangeScheduler';

const PROD_ENV_FILTER = {
  columnName: 'environment',
  text: 'PROD',
  isExactMatch: true
};

const DataProductSummary = forwardRef(({ path, onViewAll, hidePagination }, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboard_path = '/dashboard';
  const { reloadData, view, rowData, cardName, isAllFlag } = useSelector(
    (state) => state.DashboardSliceData
  );
  const userId = Cookies.get('user.id');
  const [filteredRows, setFilteredRows] = useState([]);
  const [columnsData, setColumnsData] = useState(columns);
  const [rowsCount, setRowsCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationFilter, setPaginationFilter] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState();
  const { displayChangeScheduler } = useSelector((state) => state.PublishMappingSpecData);
  const { loading } = useSelector((state) => state.LoaderData);

  const toggleMappingSpecOverlay = () => {
    dispatch(setView(false));
  };

  useImperativeHandle(ref, () => ({
    refreshDataGrid: () => {
      getDataProductSummaryGrid();
    }
  }));

  const onAcceptData = async () => {
    const payloadData = { type: '', selectedRow: {} };
    const now = new Date().toISOString();

    const payload = {
      loaderQueue: {
        iqCreateDate: now,
        iqUpdateDate: now,
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: 'UPDATE',
        iqAuditDate: now,
        iqActiveFlag: true,
        protocolName: reloadData.selectedRow.protocolNumber,
        productName: reloadData.selectedRow.productMnemonic,
        environment: reloadData.selectedRow.environment,
        mappingRuleVersionID: reloadData.selectedRow.mappingRuleVersionID,
        isReload: reloadData.type === 'Reload' ? true : false,
        loaderStatus: 'queued',
        userName: userId
      }
    };

    const saveLoaderQueueResponse = await dispatch(saveLoaderQueue(payload)).then(unwrapResult);
    if (saveLoaderQueueResponse?.data?.success) {
      dispatch(showBanner({ variant: 'success', message: saveLoaderQueueResponse.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: saveLoaderQueueResponse.data.message }));
    }

    dispatch(setReloadData(payloadData));
  };

  const getfileredListOFRows = (rowsData) => {
    let rowfiltered = [];
    if (!isAllFlag) {
      rowfiltered = rowsData.filter((row) => row.environment === 'PROD');
    } else {
      rowfiltered = rowsData;
    }
    return rowfiltered;
  };

  function sortDataFunction(data, accessor, sortOrder) {
    return data.sort((rowA, rowB) => {
      let stringA = rowA[accessor] ? rowA[accessor].toUpperCase() : '';
      let stringB = rowB[accessor] ? rowB[accessor].toUpperCase() : '';
      if (sortOrder === 'asc') {
        if (stringA < stringB) {
          return -1;
        }
        if (stringA > stringB) {
          return 1;
        }
        return 0;
      } else {
        if (stringA < stringB) {
          return 1;
        }
        if (stringA > stringB) {
          return -1;
        }
        return 0;
      }
    });
  }
  useEffect(() => {
    let filteredData = [];
    if (
      rowData &&
      rowData.length &&
      cardName !== '' &&
      path !== dashboard_path &&
      cardName === 'queued'
    ) {
      if (!isAllFlag) {
        filteredData = rowData.filter(
          (row) => row.queueStatusType === cardName && row.environment === 'PROD'
        );
      } else {
        filteredData = rowData.filter((row) => row.queueStatusType === cardName);
      }

      setFilteredRows(filteredData);
    } else if (
      rowData &&
      rowData.length &&
      cardName !== '' &&
      path !== dashboard_path &&
      cardName === 'failed'
    ) {
      if (!isAllFlag) {
        filteredData = rowData.filter(
          (row) => row.loaderStatusType === cardName && row.environment === 'PROD'
        );
      } else {
        filteredData = rowData.filter((row) => row.loaderStatusType === cardName);
      }
      setFilteredRows(filteredData);
    } else if (
      rowData &&
      rowData.length &&
      cardName !== '' &&
      path !== dashboard_path &&
      cardName === 'Data Latency Warnings'
    ) {
      if (!isAllFlag) {
        filteredData = rowData.filter(
          (row) => Boolean(row.isSLABreached) && row.environment === 'PROD'
        );
      } else {
        filteredData = rowData.filter((row) => Boolean(row.isSLABreached));
      }
      setFilteredRows(filteredData);
    } else if (
      rowData &&
      rowData.length &&
      cardName !== '' &&
      path !== dashboard_path &&
      cardName === 'all'
    ) {
      if (!isAllFlag) {
        filteredData = rowData.filter((row) => row.environment === 'PROD');
      } else {
        filteredData = rowData;
      }
      setFilteredRows(filteredData);
    } else if (rowData && rowData.length && path !== '/dashboard' && cardName === '') {
      const data = getfileredListOFRows(rowData);
      setFilteredRows(data);
    } else {
      setFilteredRows(rowData);
    }
  }, [rowData, cardName]);

  const typeCheck = reloadData.type === 'Reload' ? 'Reload' : 'Refresh';

  useEffect(() => {
    getDataProductSummaryGrid();
  }, [rowsCount, pageNumber, sortOrder, sortedColumn, paginationFilter]);

  const getDataProductSummaryGrid = async () => {
    dispatch(showLoader());
    paginationFilter.forEach((key) => {
      if (key.text && typeof key.text !== 'string') {
        key.text = key.text.toString();
      }
    });
    let payload = {
      filters:
        !paginationFilter.length && isAllFlag
          ? []
          : !paginationFilter.length && !isAllFlag
          ? [
              {
                columnName: 'environment',
                text: 'PROD',
                isExactMatch: true
              }
            ]
          : paginationFilter,
      userId: userId,
      pageNumber: pageNumber + 1,
      pageSize: rowsCount === 'All' ? totalCount : rowsCount
    };
    let summaryData = await dispatch(GetDataProductSummary(payload)).then(unwrapResult);
    if (summaryData.data.success) {
      const productSummaryData = summaryData.data.dataProductSummaries;
      const protocolAuth = summaryData.data.protocolAuth;
      const protocolAuthKeys = Object.keys(protocolAuth);
      // Added new key in the productSummaryData object
      // from the protocolAuth Object
      // with key name as protocolNumber
      productSummaryData.forEach((el) => {
        if (protocolAuthKeys.includes(el.protocolNumber)) {
          el[el.protocolNumber] = protocolAuth[el.protocolNumber];
        }
        el.isActivePipeline = el.isActivePipeline === true ? 'Active' : 'Inactive';
        el.dataSources = el.dataSources instanceof Array ? el.dataSources.join() : el.dataSources;
        el.trialName = el.trialName instanceof Array ? el.trialName.join() : el.trialName;
        el.endTime = dateFormatByType(el.endTime, 'Table');
        el.startTime = dateFormatByType(el.startTime, 'Table');
        el.createdDate = dateFormatByType(el.createdDate, '');
        el.key = uuidv4();
      });
      setTotalCount(summaryData.data.totalCount);
      summaryData.data.totalCount < rowsCount * pageNumber && setPageNumber(0);
      if (sortedColumn !== undefined) {
        dispatch(setRowdata(sortDataFunction(productSummaryData, sortedColumn, sortOrder)));
      } else {
        dispatch(setRowdata(productSummaryData));
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: summaryData.data.message }));
    }
    dispatch(closeLoader());
  };
  const paginationData = {
    sortOrder,
    pageNumber,
    sortedColumn,
    rowsCount,
    paginationFilter,
    totalCount,
    hidePagination
  };
  const paginationFunctions = {
    setRowsCount,
    setSortOrder,
    setSortedColumn,
    setPageNumber,
    setPaginationFilter: (_filters) => {
      const _updatedFilters = [..._filters];
      if (!isAllFlag) {
        const envFilterIndex = _updatedFilters.findIndex(
          (_filter) => _filter.columnName === 'environment'
        );
        if (envFilterIndex !== -1) _updatedFilters.splice(envFilterIndex, 1, PROD_ENV_FILTER);
        else {
          _updatedFilters.push(PROD_ENV_FILTER);
        }
      }
      setPaginationFilter(_updatedFilters);
    }
  };

  return (
    <React.Fragment>
      <>
        {loading && (
          <div
            style={{
              width: 'calc(100% - 3rem)',
              height: '700px',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '9',
              backgroundColor: 'rgba(255,255,255,0.6)'
            }}>
            <ApolloProgress />
          </div>
        )}
        <DataProductSummaryTable
          history={navigate}
          rows={filteredRows}
          path={path}
          columnsData={columnsData}
          setColumnsData={setColumnsData}
          onViewAll={onViewAll}
          paginationData={paginationData}
          paginationFunctions={paginationFunctions}
        />
      </>
      <CustomModal
        display={reloadData.type !== ''}
        variant={'warning'}
        title={`Are you sure you want to ${typeCheck} Data?`}
        message={
          'This process can take several minutes/hours to complete. Are you sure you want to continue?'
        }
        buttonPrimaryLabel={`${typeCheck} Data`}
        handlePrimaryAction={onAcceptData}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => dispatch(setReloadData({ type: '', selectedRow: {} }))}
      />
      {view && (
        <MappingSpecDetails
          displayMappingSpecOverlay={view}
          toggleMappingSpecOverlay={toggleMappingSpecOverlay}
        />
      )}

      {displayChangeScheduler?.display && (
        <ChangeScheduler
          displayOverlay={displayChangeScheduler?.display}
          selectedRow={displayChangeScheduler.row}
          closeChangeScheduler={(refresh) => {
            dispatch(setDisplayChangeScheduler({ display: false }));
            refresh && getDataProductSummaryGrid();
          }}
        />
      )}
    </React.Fragment>
  );
});

export default DataProductSummary;
