import React from 'react';
import Grid from 'apollo-react/components/Grid';
import { makeStyles } from '@mui/styles';
import Typography from 'apollo-react/components/Typography';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem'
  },
  content: {
    minWidth: '10%'
  },

  header: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  value: {
    color: '#595959',
    fontSize: '14px',
    lineHeight: '24px'
  }
});

/**
 * ProtocolDetails - Component to render the provided protocol details
 * @props {*} protocolDetails
 */
const ProtocolDetails = ({ protocolDetails, tabValue }) => {
  const { protocolNumber, sponsor, productMnemonic, mappingSpecVersion, environment, dataSources, targetDataModelType, trialName, endTime } =
    protocolDetails;
  const classes = useStyles();

  return (
    <Grid item className={classes.container}>
      <Grid item className={classes.content}>
        <Typography className={classes.header}>Protocol</Typography>
        <Typography className={classes.value}>{protocolNumber}</Typography>
      </Grid>

      <Grid item className={classes.content}>
        <Typography className={classes.header}>Sponsor</Typography>
        <Typography className={classes.value}>{sponsor}</Typography>
      </Grid>

      <Grid item className={classes.content}>
        <Typography className={classes.header}>Product Name</Typography>
        <Typography className={classes.value}>{productMnemonic}</Typography>
      </Grid>

      <Grid item className={classes.content}>
        <Typography className={classes.header}>Mapping Spec Version</Typography>
        <Typography className={classes.value}>{mappingSpecVersion}</Typography>
      </Grid>

      <Grid item className={classes.content}>
        <Typography className={classes.header}>Environment</Typography>
        <Typography className={classes.value}>{environment}</Typography>
      </Grid>

      {(targetDataModelType === 'Ruleset' && tabValue === 0) && (
        <>
          <Grid item className={classes.content}>
            <Typography className={classes.header}>Data Sources</Typography>
            <Typography className={classes.value}>{dataSources}</Typography>
          </Grid>
          <Grid item className={classes.content}>
            <Typography className={classes.header}>Trial Name/Data Flow Type</Typography>
            <Typography className={classes.value}>{trialName}</Typography>
          </Grid>
          <Grid item className={classes.content}>
            <Typography className={classes.header}>Last Run Completion Date</Typography>
            <Typography className={classes.value}>{endTime}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProtocolDetails;
