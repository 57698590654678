import React, { useMemo } from 'react';
import Header from 'Components/Header';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import { neutral1 } from 'apollo-react/colors';
import Panel from 'apollo-react/components/Panel';
import Footer from 'Components/Footer';
import { makeStyles } from '@mui/styles';
import { BannerExample } from 'Components/Banner/Banner';
import DataProductDrawer from '../../Pages/ProductDesigner/Components/DataProductDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { setClosePanelText } from 'Redux/Slice/StudyLibrarySlice';
import { openDataPipelinePanel } from 'Redux/Slice/PublishMappingSpecSlice';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';

const useStyles = ([projectHeaderVisible, panelOpen]) =>
  makeStyles({
    container: {
      width: '100%',
      marginTop: projectHeaderVisible ? '113px' : '57px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: neutral1,
      boxSizing: 'border-box',
      justifyContent: 'flex-start'
    },
    root: {
      backgroundColor: '#f5f6fa'
    },
    panelRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: `calc(100vh - ${projectHeaderVisible ? '113px' : '57px'})`
    },
    panelsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',

      '&> div:nth-child(1)': {
        height: `calc(100vh - ${projectHeaderVisible ? '113px' : '57px'})`,
        position: 'sticky',
        top: projectHeaderVisible ? '113px' : '57px',
        zIndex: 1,
        overflowY: 'clip'
      },

      '&> div:nth-child(2)': {
        width: `calc(100% - ${panelOpen ? '354px' : '26px'}) !important`,
        backgroundColor: '#f5f6fa'
      }
    },
    libraryHeader: {
      backgroundColor: 'white',
      fontFamily: 'Proxima Nova',
      fontSize: '20px',
      fontWeight: '600',
      letterSpacing: '0px',
      padding: '0 0 10px 24px',
      borderBottom: '1px solid #e9e9e9'
    }
  });

const PublishLayout = (props) => {
  const dispatch = useDispatch();
  const { openPanelMDP } = useSelector((state) => state.PublishMappingSpecData);
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const projectHeaderDisplayed = useMemo(() => {
    return Boolean(protocol && Object.keys(protocol).length !== 0);
  }, [protocol]);

  const classes = useStyles([projectHeaderDisplayed, openPanelMDP])();

  const onPanelClose = () => {
    dispatch(openDataPipelinePanel(false));
    dispatch(setClosePanelText(false));
  };

  const onPanelOpen = () => {
    dispatch(setClosePanelText(true));
    dispatch(openDataPipelinePanel(true));
  };

  return (
    <Grid container className={classes.root}>
      <Header />
      {projectHeaderDisplayed && <ProjectStudyHeader />}

      <BannerExample />
      <div className={`${classes.container}`}>
        <Breadcrumb studyGridPin={true} />
        <div className={classes.libraryHeader}>Manage Data Pipelines</div>

        <Grid className={classes.panelsContainer}>
          <Panel width={350} onClose={onPanelClose} onOpen={onPanelOpen} open={openPanelMDP}>
            <DataProductDrawer />
          </Panel>
          <Panel hideButton className={classes.panelRight}>
            <Box>{props.children}</Box>
            <Footer width={'100%'} />
          </Panel>
        </Grid>
      </div>
    </Grid>
  );
};

export default PublishLayout;
