/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Card from './Card';
import FileUpload from 'apollo-react/components/FileUpload';
import { jsonToExcel, convertExcelToJson, jsonToFileData, getHeaders, uuidv4 } from 'Utils';
import { DeleteReferenceData, getTrialElement } from 'Redux/Service/ReferenceDataCardsService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Grid from 'apollo-react/components/Grid';
import _ from 'lodash';
import { TrialElementsValidator } from 'Validators/TrialElements.Validator';
import CustomModal from 'Components/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import './Trials.css';
import { useNavigate, useParams } from 'react-router-dom';
import { setReferenceData } from 'service/reference-data.service';
import { checkColumns, checkFileData, checkFileType } from '../Utils/FileValidationUtils';

const trialElementsColumn = [
  {
    ETCD: '',
    ELEMENT: '',
    TESTRL: '',
    TEENRL: '',
    TEDUR: ''
  }
];

const columns = ['ETCD', 'ELEMENT', 'TESTRL', 'TEENRL', 'TEDUR'];
const allowedExtensions = ['xlsx', 'xls'];
const TrialElements = (props) => {
  const { id: mappingRuleVersionId } = useParams();
  const [trialElementData, setTrialElementData] = useState([]);
  const [trialElementValidationResult, setTrialElementValidationResult] = useState({});
  const [mappingError, setMappingError] = useState('');
  const [cards, setCardArray] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  const [trialElementUploadedFile, setTrialElementUploadedFile] = useState([]);
  const navigate = useNavigate();
  const [trialElementPreview, setTrialElementPreview] = useState(true);
  const [error, setError] = useState();
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [timeElementsMessage, setTimeElementsMessage] = useState('');
  const dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(false);
  const [isValidData, setIsValidData] = useState(false);
  const TRIAL_ELEMENTS = 'trial-elements';

  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  const isNull = (value) => {
    if (value === null) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // setReferenceData('trial-elements-title', props.title);
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['TrialElement']) {
      if (!trialElementPreview) {
        const element = document.getElementById('anchor-preview');
        element.click();
        setMappingError(props.createMappingObj['message']);
        props.createMappingObj['TrialElement'] = false;
      }
    }
  }, [trialElementPreview]);

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingRuleVersionId
      };
      const trialElementGetAPIData = await dispatch(getTrialElement(payload)).then(unwrapResult);
      props.configuration.setIsCompleted(TRIAL_ELEMENTS, true);
      props.configuration.setModuleValidation(TRIAL_ELEMENTS, true);
      if (
        trialElementGetAPIData &&
        trialElementGetAPIData.data &&
        trialElementGetAPIData.data.success
      ) {
        if (
          trialElementGetAPIData.data.trialElements &&
          trialElementGetAPIData.data.trialElements.length > 0
        ) {
          setError('');
          const newData = trialElementGetAPIData.data.trialElements.map((item) => {
            return {
              etcd: item.etcd,
              element: item.element,
              testrl: item.testrl,
              teenrl: item.teenrl,
              tedur: item.tedur,
              id: uuidv4()
            };
          });
          const validationResult = TrialElementsValidator(newData);
          const errorCountFlag = validationResult.getErrorCount();
          errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
          setTrialElementValidationResult(validationResult);
          setTrialElementData(newData);
          let fileObj = jsonToFileData(newData, 'TrialElements.xlsx');
          setTrialElementUploadedFile([fileObj]);
          setTrialElementPreview(false);
          setIsValidData(true);
          props.configuration.setModuleValidation(
            TRIAL_ELEMENTS,
            errorCountFlag > 0 ? false : true
          );
          props.configuration.setCardVisibility(TRIAL_ELEMENTS, true);

          // if (ref && ref.current) {
          //   ref.current.style.display = 'block';
          // }
        } else {
          props.required && props.configuration.setModuleValidation(TRIAL_ELEMENTS, false);
        }
      } else {
        setTimeElementsMessage(trialElementGetAPIData.data.message);
        setTrialElementData([]);
        setIsValidData(false);
        // dispatch(showBanner({ variant: 'error', message: trialElementsData.data.message }));
      }
    })();
  }, []);

  const confirmOnDelete = () => {
    trialElementUploadedFile.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          onConfirm: () => {
            setConfirmAlert({
              enabled: false
            });
            deleteUploadedFile();
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'TE',
      mappingRuleVersionID: mappingRuleVersionId
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
    setTrialElementUploadedFile([]);
    setTrialElementPreview(true);
    setTrialElementData([]);
    // cardItems.push({ name: 'Trial Elements', module: 'trial-elements' });
    // setCardItems(cardItems);
    const index = cards.findIndex((el) => el === TRIAL_ELEMENTS);
    cards.splice(index, 1);

    props.configuration.setCardVisibility(TRIAL_ELEMENTS, false);

    // props.triggerGetApi();
  };

  const downloadSheet = () => {
    jsonToExcel(trialElementsColumn, 'TrialElements.xlsx');
    // setIsFileUploadDisable(false);
  };

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const handleUpload = async (selectedFiles) => {
    setUploadProgress(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJSON = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);

      const isError = fileValidation(fileJSON, headers);
      if (!isError) {
        let newFileArr = [];
        fileJSON.map((obj) => {
          const newObj = { ...trialElementsColumn[0], ...obj };
          var lowerObj = _.transform(newObj, function (result, val, key) {
            result[key.toLowerCase()] = val;
          });
          lowerObj.id = uuidv4();
          newFileArr.push(lowerObj);
        });
        setError('');
        const validationResult = TrialElementsValidator(newFileArr);
        const errorCountFlag = validationResult.getErrorCount();

        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);

        setTrialElementData(newFileArr);
        setTrialElementValidationResult(validationResult);

        setReferenceData('trial-element', newFileArr);
        setReferenceData('trial-element-validationResult', validationResult);
        setReferenceData('trial-element-getErrorCount', validationResult.getErrorCount);
        setReferenceData('trial-element-getErrorRowId', validationResult.getErrorRowId);

        setTrialElementUploadedFile([selectedFiles[0]]);
        setTrialElementPreview(false);
        navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/trial-element`);
      } else {
        setError(isError);
        // props.configuration.setModuleValidation('trial-elements', false, isError);
        setTrialElementUploadedFile([]);
        setTrialElementPreview(true);
        setTrialElementData([]);
      }
    }
    setUploadProgress(false);
  };

  const handleEditPreview = (isUploaded, newFileArr) => {
    if (isUploaded || trialElementData) {
      // navigate(
      //    `/product-designer/rule-editor/trial-element/${mappingId}`,
      //   {state: {
      //     trialArmsData: newFileArr
      //   }}
      // );
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/trial-element`);
    }
  };

  const handleDelete = () => {
    deleteUploadedFile();
    setDeleteFile(false);
    setError('');
  };

  const openDeleteModal = () => {
    setDeleteFile(true);
  };

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        required={props.required}
        refMsg={timeElementsMessage}
        handleDownload={downloadSheet}
        isEditDisable={trialElementPreview}
        setErrorFlag={errorFlag}
        handleEditPreview={() => handleEditPreview()}>
        <div style={{ margin: '0px 10px' }}>
          {uploadProgress && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={uploadProgress}
              value={trialElementUploadedFile}
              onUpload={handleUpload}
              onFileDelete={openDeleteModal}
              // disabled={isFileUploadDisable}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
              //   {...this.props}
            />
          </div>

          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        // handlePrimaryAction={() => handleDelete()}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </Grid>
  );
};

export default TrialElements;
