import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const saveDataProduct = createAsyncThunk(
  'addDataProduct/saveDataProduct',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/SaveDataProduct`, payload);
  }
);
