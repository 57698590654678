import { makeStyles } from '@mui/styles';
import { neutral1 } from 'apollo-react/colors';
import Grid from 'apollo-react/components/Grid';
import { BannerExample } from 'Components/Banner/Banner';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import '../GlobalLibraryLayout/GlobalLibraryLayout.css';
import { Refresh } from 'apollo-react-icons';
import Button from 'apollo-react/components/Button';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: neutral1,
    boxSizing: 'border-box'
  },
  library: {
    backgroundColor: 'white',
    fontFamily: 'Proxima Nova',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0px',
    lineHeight: '48px',
    padding: '10px 2.7%'
  },
  divContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: `calc(100vh - 113px)`
  }
});

const DataProductSummaryLayout = (props) => {
  const classes = useStyles();
  const childFunc = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Pipelines'
        }
      ])
    );
  }, []);

  return (
    <>
      <Header />
      <BannerExample />
      <Breadcrumb />
      <Grid container spacing={2} className={classes.library}>
        <Grid item xs={10}>
          <div>Data Product Pipelines</div>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <Button
            variant="secondary"
            icon={<Refresh />}
            size="small"
            style={{ marginRight: 10 }}
            onClick={() => {
              childFunc.current?.refreshDataGrid();
            }}>
            Refresh
          </Button>
        </Grid>
      </Grid>

      <div className={classes.divContainer}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          style={{ padding: '2.5rem', justifyContent: 'center' }}>
          <Grid item xs={12}>
            {React.cloneElement(props.children, { ref: childFunc }, null)}
          </Grid>
        </Grid>
        <Footer />
      </div>
    </>
  );
};
export default DataProductSummaryLayout;
