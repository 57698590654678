/*eslint-disable*/
import Table from 'apollo-react/components/Table';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Checkbox from 'apollo-react/components/Checkbox';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import MenuButton from 'apollo-react/components/MenuButton';
import Footer from 'Components/Footer';
import { columns } from './columns.data';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import RulesetSelector from 'Components/RuleSet/RulesetSelector';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'apollo-react/components/Modal';
import {
  setNextEnabled,
  setWarningModalConfig,
  setErrorDomains
} from 'Redux/Slice/RuleEditorSlice';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { GetAssignedRulesetsData, SaveAssignedRulesetsData } from 'Redux/Service/RulesetsService';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from 'apollo-react/components/Loader';
import { uuidv4 } from 'Utils';
import Cookies from 'js-cookie';
import useDisplayName from 'Utils/useDisplayName';
const useStyles = makeStyles({
  paper: {
    marginTop: '1rem',
    borderRadius: '4px',
    overflow: 'hidden',
    '& table': {
      width: '100%'
    }
  },
  headerCheckbox: {
    '& .MuiCheckbox-root': {
      top: 0
    },
    '& .MuiFormControlLabel-label': {
      display: 'none'
    }
  },
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  },
  errorModal: {
    paper: {
      '&': {
        width: '1257px',
        maxWidth: 'none'
      }
    }
  },
  detailsLabel: {
    color: '#444444',
    fontSize: '14px',
    marginBottom: '0.5rem'
    // fontWeight: '400'
  },
  detailsContent: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: '500'
  },
  details: {
    margin: '1rem 2rem 1rem 0',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif',
    minWidth: '150px'
  }
});

const EMPTY_RULESET = {
  libraryID: null,
  libraryVersion: null
};

const CustomButtonHeader = (props) => {
  const { onApply, onRemove, actionsDisabled, removeDisabled } = props;

  const menuItems = [
    {
      text: 'Apply Ruleset',
      onClick: onApply
    },
    {
      text: 'Remove Ruleset',
      onClick: onRemove,
      disabled: removeDisabled
    }
  ];

  return (
    <Box>
      <Button
        variant="secondary"
        icon={Filter}
        onClick={props.toggleFilters}
        style={{ marginRight: '0.5rem' }}
        size={'small'}>
        Filter
      </Button>
      <MenuButton
        buttonText={'Actions'}
        menuItems={menuItems}
        size={'small'}
        disabled={actionsDisabled}
      />
    </Box>
  );
};

const CheckboxCell = ({ row: { selectedRows, rowId, handleSelectRow } }) => {
  return (
    <Checkbox
      style={{ marginRight: 0 }}
      checked={!!selectedRows[rowId]}
      onChange={(event) => handleSelectRow(rowId, event.nativeEvent.shiftKey)}
    />
  );
};

const ApplyRuleset = (props) => {
  const classes = useStyles();
  const [allRowsSelected, setAllRowsSelected] = useState(null);
  const [showSelectRuleset, setShowSelectRuleset] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedRulesetLibrary, setSelectedRulesetLibrary] = useState({});
  const [cdrLibrariesDomainData, setCDRLibrariesDomainData] = useState([]);
  const [confirmDailog, setConfirmDailog] = useState();
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();
  const userLoginID = Cookies.get('user.id');
  const getDisplayName = useDisplayName();

  const {
    protocol: { protocolNumber }
  } = useSelector((state) => state.StudyLibraryData);
  const { errorDomains } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const getAssignedRulesetsData = async () => {
    setLoading(true);
    dispatch(setNextEnabled(false));
    const resp = await dispatch(GetAssignedRulesetsData(mappingRuleVersionID)).then(unwrapResult);
    if (resp?.data?.success) {
      const _cdrLibrariesDomainData = resp.data.cdrLibrariesDomainData?.map((domainData) => {
        const {
          mappingRuleStudyLibXrefId,
          source,
          libraryType,
          clinicalDataType,
          clinicalDataFlow,
          datasetTypeDisplayName,
          ruleset: { libraryName: rulesetName, libraryVersion: rulesetVersion }
        } = domainData;
        return {
          domain: source,
          libraryType,
          clinicalDataType,
          datasetTypeDisplayName: datasetTypeDisplayName || 'N/A',
          clinicalDataFlow,
          ruleset: rulesetName,
          rulesetVersion,
          rowId: mappingRuleStudyLibXrefId,
          key: uuidv4()
        };
      });
      let countOfRecords = 0;
      let rulesetMappedRecords = 0;
      let rulesetErrorsList =
        resp.data.itemValidation !== null ? Object.keys(resp.data.itemValidation) : [];
      dispatch(setErrorDomains([...new Set(rulesetErrorsList)]));
      _cdrLibrariesDomainData.forEach((domainObj) => {
        if (resp.data.itemValidation && resp.data.itemValidation[domainObj.domain]) {
          let errorDetails = resp.data.itemValidation[domainObj.domain];
          domainObj['errorInfo'] = Object.values(errorDetails);
        }
        rulesetMappedRecords =
          toString(domainObj.rulesetVersion).trim().length && countOfRecords + 1;
      });
      rulesetMappedRecords === 0 && dispatch(setNextEnabled(true));
      rulesetErrorsList.length > 0 &&
        dispatch(
          showBanner({
            variant: 'error',
            message: `Errors found in ${rulesetErrorsList.length} of ${_cdrLibrariesDomainData.length} domains while applying the ruleset. Select a new ruleset, fix the errors or remove the ruleset to proceed.`
          })
        );
      setCDRLibrariesDomainData(_cdrLibrariesDomainData);
    } else {
      dispatch(showBanner({ variant: 'error', message: resp?.data?.message }));
    }
    setSelectedRulesetLibrary({});
    setSelectedRows({});
    setLoading(false);
  };

  const saveAssignedRulesetsData = async (ruleset) => {
    setLoading(true);
    const payload = { mappingRuleVersionID, cdrStudyLibraries: [] };
    for (let row of cdrLibrariesDomainData) {
      const now = new Date().toISOString();
      if (selectedRows[row.rowId]) {
        const { rowId, domain } = row;
        payload.cdrStudyLibraries.push({
          mappingRuleStudyLibXrefId: rowId,
          mappingRuleVersionID,
          iqActiveFlag: true,
          source: domain,
          iqCreateDate: now,
          iqUpdateDate: now,
          iqAuditDate: now,
          iqCreatedBy: userLoginID,
          iqUpdatedBy: userLoginID,
          iqAuditType: 'UPDATE',
          protocolNumber,
          ruleset: ruleset
            ? {
                libraryID: ruleset.libraryID,
                libraryVersion: ruleset.version
              }
            : EMPTY_RULESET
        });
      }
    }

    const resp = await dispatch(SaveAssignedRulesetsData(payload)).then(unwrapResult);
    if (resp?.data?.success) {
      dispatch(showBanner({ variant: 'success', message: resp?.data?.message }));
      getAssignedRulesetsData();
    } else {
      dispatch(showBanner({ variant: 'error', message: resp?.data?.message }));
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssignedRulesetsData();
  }, []);

  const onCheckboxHeaderChange = () => {
    setSelectedRows(() => {
      const selected = {};
      cdrLibrariesDomainData.forEach((datasetRow) => {
        selected[datasetRow.rowId] = !Boolean(allRowsSelected);
      });
      return selected;
    });
  };

  const handleSelectRow = (rowId, selected) => {
    setSelectedRows({ ...selectedRows, [rowId]: !selectedRows[rowId] });
  };

  const showRulesetSelector = () => {
    setShowSelectRuleset(true);
  };

  const rowData = useMemo(
    () => [
      ...cdrLibrariesDomainData.map((datasetRow) => ({
        ...datasetRow,
        selectedRows,
        handleSelectRow,
        libraryTypeDisplayName: getDisplayName(datasetRow.libraryType)
      }))
    ],
    [selectedRows, cdrLibrariesDomainData, allRowsSelected]
  );

  const [selectedRowCount, selectedDatasetsCount, datasetsWithRuleset] = useMemo(() => {
    const selectedCount = Object.values(selectedRows).filter(Boolean).length;
    if (selectedCount === cdrLibrariesDomainData.length) {
      setAllRowsSelected(true);
    } else if (selectedCount === 0) {
      setAllRowsSelected(false);
    } else {
      setAllRowsSelected(null);
    }
    return [
      selectedCount,
      cdrLibrariesDomainData.filter((datasetRow) => selectedRows[datasetRow.rowId]).length,
      cdrLibrariesDomainData.filter(
        (datasetRow) => selectedRows[datasetRow.rowId] && datasetRow.ruleset
      ).length
    ];
  }, [cdrLibrariesDomainData, selectedRows]);

  useEffect(() => {
    dispatch(setNextEnabled(!errorDomains.length));
    dispatch(
      setWarningModalConfig({
        displayWarningModal: !cdrLibrariesDomainData.some((data) => data.ruleset),
        displayWarningModalTitle: 'Continue without ruleset?',
        displayWarningModalSubtitle: `No ruleset has been applied to any domain.\nAre you sure you want to continue?`
      })
    );
  }, [cdrLibrariesDomainData]);

  const handleApplyRuleset = (ruleset) => {
    setSelectedRulesetLibrary(ruleset);
    setShowSelectRuleset(false);
    if (datasetsWithRuleset > 0) {
      setConfirmDailog('overwrite');
    } else {
      saveAssignedRulesetsData(ruleset);
    }
  };

  const handleRemoveRulesets = () => {
    setConfirmDailog('remove');
  };

  const confirmModalButtonProps = [
    {
      label: confirmDailog === 'overwrite' ? 'Cancel' : 'Keep',
      onClick: () => {
        setConfirmDailog();
      }
    },
    {
      label: confirmDailog === 'overwrite' ? 'Overwrite ruleset' : 'Remove ruleset',
      onClick: () => {
        saveAssignedRulesetsData(confirmDailog === 'overwrite' && selectedRulesetLibrary);
        setConfirmDailog();
      }
    }
  ];

  const checkboxColumn = {
    header: (
      <Checkbox
        indeterminate={allRowsSelected === null}
        checked={!!allRowsSelected}
        onChange={onCheckboxHeaderChange}
        style={{ minHeight: 0, marginRight: 0 }}
        className={classes.headerCheckbox}
      />
    ),
    accessor: '',
    customCell: CheckboxCell,
    width: 45
  };

  const columnsConfig = useMemo(() => [checkboxColumn, ...columns], [allRowsSelected]);

  return (
    <>
      {isLoading ? <Loader isInner overlayClassName={classes.loader}></Loader> : null}
      <Box px={3}>
        <Typography variant="h3">{'Apply Rulesets'}</Typography>

        <Table
          classes={classes}
          title={'List of domains'}
          subtitle={'Apply a ruleset to the following domains using the actions menu'}
          columns={columnsConfig}
          rows={rowData}
          rowId={'domain'}
          defaultRowsPerPage={10}
          rowsPerPageOptions={[10, 50, 100, 'All']}
          hasScroll
          maxHeight={650}
          initialSortedColumn="domain"
          initialSortOrder="asc"
          tablePaginationProps={{
            labelDisplayedRows: DisplayedRowsLabel,
            truncate: true
          }}
          CustomHeader={CustomButtonHeader}
          headerProps={{
            onApply: showRulesetSelector,
            onRemove: handleRemoveRulesets,
            actionsDisabled: selectedRowCount <= 0,
            removeDisabled: datasetsWithRuleset <= 0
          }}
        />
      </Box>
      <Footer width={'100%'} />

      {showSelectRuleset ? (
        <RulesetSelector
          studyOnly
          returnLibrary
          protocolNumber={protocolNumber}
          open={showSelectRuleset}
          setOpen={setShowSelectRuleset}
          onApplyRuleset={handleApplyRuleset}
          modalTitle={'Select a Ruleset'}
          modalSubtitle={'Choose a study ruleset to apply to the selected domains'}
          helperText={'Select a ruleset from the following list'}
          tableHeader={'Rulesets'}
          primaryButtonText={`Apply to ${selectedDatasetsCount} domains`}
        />
      ) : (
        <></>
      )}

      {confirmDailog ? (
        <Modal
          open={confirmDailog}
          variant={'warning'}
          title={confirmDailog === 'overwrite' ? 'Overwrite Ruleset?' : 'Remove Ruleset?'}
          data-testid="confirm-modal"
          buttonProps={confirmModalButtonProps}
          onClose={() => {
            setConfirmDailog();
          }}>
          {`You are about to ${confirmDailog} the ruleset from ${datasetsWithRuleset} domains. Are you sure you want to continue?`}
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default ApplyRuleset;
