import Validator from './Validator';

const routes = [
  {
    path: '/val',
    component: Validator,
    layout: Validator,
    exact: true,
    authentication: false
  }
];
export default routes;
