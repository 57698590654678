/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { useOutletContext, useLocation, useNavigate } from 'react-router-dom';
import StudyInfo from 'Components/SourceStudyDisplay/StudyDisplayInfo';
import Grid from 'apollo-react/components/Grid';
import { Caption, StudyInfoWrapper, TableWrapper, Title } from '../../Common';
import styled from 'styled-components';
import { uuidv4 } from 'Utils';
import Table from 'apollo-react/components/Table';
import { columns, ExpandedRow } from './columns.data';
import { unwrapResult } from '@reduxjs/toolkit';
import { GetStudyLibrariesForCloneDP } from 'Redux/Service/CloneDataProductService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useDispatch, useSelector } from 'react-redux';
import useDisplayName from 'Utils/useDisplayName';

const SelectStudyLibraries = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { setNextDisabled } = useOutletContext();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const getDisplayName = useDisplayName();

  const [isLoading, setLoading] = useState(false);
  const [isCDRTestFlow, setCDRTestFlow] = useState();
  const [sourceStudyLibraries, setSourceStudyLibraries] = useState([]);
  const [targetStudyLibraries, setTargetStudyLibraries] = useState([]);

  const handleExpandRow = (id) => {
    setSourceStudyLibraries((_rowData) => {
      const _modifiedRowData = [..._rowData];
      return _modifiedRowData.map((row) => {
        if (row.id === id) {
          return { ...row, expanded: !row.expanded };
        }
        return row;
      });
    });
  };

  const handleTargetStudyLibraryChange = (id, value) => {
    setCDRTestFlow();
    setSourceStudyLibraries((_rowData) => {
      const _modifiedRowData = [..._rowData];
      return _modifiedRowData.map((row) => {
        if (row.id === id) {
          const modifiedSourceDatasetMapping = { ...row.sourceDatasetMapping };
          for (let key in modifiedSourceDatasetMapping) {
            modifiedSourceDatasetMapping[key] = '';
          }
          return {
            ...row,
            targetStudyLibrary: value || '',
            sourceDatasetMapping: modifiedSourceDatasetMapping,
            expanded: row?.libraryType === 'CDR Tabular' && true
          };
        }
        return row;
      });
    });
    value?.library?.libraryType === 'CDR Tabular' && setCDRTestFlow(value?.library?.isCDRTestFlow);
  };

  const handleTargetDatasetChange = (id, key, value) => {
    setSourceStudyLibraries((_rowData) => {
      const _modifiedRowData = [..._rowData];
      return _modifiedRowData.map((row) => {
        if (row.id === id) {
          return { ...row, sourceDatasetMapping: { ...row.sourceDatasetMapping, [key]: value } };
        }
        return row;
      });
    });
  };

  const getStudyLibrariesForCloneDP = async () => {
    const cloneDataMappings = location?.state?.cloneDataMappings || [];
    const payload = {
      sourceMappingRuleVersionId: location?.state?.mappingVersionID,
      targetProtocolNumber: protocol.protocolNumber
    };
    const studyLibrariesForCloneDP = await dispatch(GetStudyLibrariesForCloneDP(payload)).then(
      unwrapResult
    );
    if (studyLibrariesForCloneDP?.data?.success) {
      const _sourceStudyLibraries = {};
      const _targetStudyLibraries = [];

      studyLibrariesForCloneDP?.data?.targetStudyLibraries?.forEach((_targetLib) => {
        if (_targetLib?.libraryType === 'Ruleset' || !_targetLib?.isactive) return;

        _targetStudyLibraries.push({
          ..._targetLib,
          datasets: _targetLib.source?.split(',')
        });
      });

      let _isCDRTestFlow = undefined;
      studyLibrariesForCloneDP?.data?.sourceStudyLibraries?.forEach((_sourceLib) => {
        if (_sourceLib?.libraryType === 'Ruleset') return;
        const _savedMapping = cloneDataMappings.find(
          (_mapping) => _mapping.sourceLibraryID === _sourceLib.libraryID
        );

        let _targetLibrary;

        if (['CDR Tabular', 'CDISC ODM'].includes(_sourceLib?.libraryType)) {
          _targetLibrary = _targetStudyLibraries.find(
            (_targetLib) => _savedMapping?.targetLibraryID === _targetLib.libraryID
          );
          if (
            _sourceLib?.libraryType === 'CDR Tabular' &&
            typeof _targetLibrary?.isCDRTestFlow === 'boolean' &&
            typeof _isCDRTestFlow !== 'boolean'
          ) {
            _isCDRTestFlow = _targetLibrary?.isCDRTestFlow;
          }
        } else {
          _targetLibrary = _targetStudyLibraries.find(
            (_targetLib) => _targetLib?.libraryType === _sourceLib?.libraryType
          );
        }

        const sourceDatasetMapping = {};
        _sourceLib.source
          ?.split(',')
          .forEach(
            (_s) =>
              (sourceDatasetMapping[_s] = _savedMapping?.datasetMapping?.[_s]
                ? { label: _savedMapping?.datasetMapping?.[_s] }
                : '')
          );

        if (_sourceStudyLibraries[_sourceLib?.libraryID]) {
          _sourceStudyLibraries[_sourceLib?.libraryID].sourceDatasetMapping[_sourceLib.source] =
            _savedMapping?.datasetMapping?.[_sourceLib.source]
              ? { label: _savedMapping?.datasetMapping?.[_sourceLib.source] }
              : '';
        } else {
          _sourceStudyLibraries[_sourceLib?.libraryID] = {
            ..._sourceLib,
            id: uuidv4(),
            key: uuidv4(),
            targetStudyLibrary: _targetLibrary
              ? { label: _targetLibrary.libraryName, library: _targetLibrary }
              : '',
            sourceDatasetMapping: {
              [_sourceLib.source]: _savedMapping?.datasetMapping?.[_sourceLib.source]
                ? { label: _savedMapping?.datasetMapping?.[_sourceLib.source] }
                : ''
            }
          };
        }
      });

      for (let libId in _sourceStudyLibraries) {
        const _sourceLib = _sourceStudyLibraries[libId];
        if (!['CDR Tabular', 'CDISC ODM'].includes(_sourceLib?.libraryType)) {
          const _targetLibrary = _targetStudyLibraries.find(
            (_targetLib) => _targetLib?.libraryType === _sourceLib?.libraryType
          );
          _sourceLib.targetStudyLibrary = { library: _targetLibrary };
          _sourceStudyLibraries[libId] = _sourceLib;
        }
      }

      setCDRTestFlow(_isCDRTestFlow);
      setTargetStudyLibraries(_targetStudyLibraries);
      setSourceStudyLibraries(Object.values(_sourceStudyLibraries));
    } else {
      dispatch(showBanner({ variant: 'error', message: studyLibrariesForCloneDP?.data?.message }));
    }
  };

  useEffect(() => {
    (async () => {
      setNextDisabled(true);
      setLoading(true);
      try {
        await getStudyLibrariesForCloneDP();
      } catch (error) {
        console.log('Error while fetching Study libraries for Clone DP', error);
      }
      setLoading(false);
    })();
  }, []);

  const studyLibraryMappings = useMemo(() => {
    console.log('sourceStudyLibraries', sourceStudyLibraries);

    const cloneDataMappings = [];
    let isValidMapping = false;

    sourceStudyLibraries.forEach((_sourceLib) => {
      if (_sourceLib.libraryType === 'CDR Tabular') {
        if (Object.values(_sourceLib.sourceDatasetMapping).some(Boolean)) {
          isValidMapping = isValidMapping || true;
          const datasetMapping = { ..._sourceLib.sourceDatasetMapping };
          for (let key in datasetMapping) {
            datasetMapping[key] = datasetMapping[key]?.label;
            !datasetMapping[key] && delete datasetMapping[key];
          }
          cloneDataMappings.push({
            sourceLibraryID: _sourceLib.libraryID,
            sourceLibraryVersion: _sourceLib.libraryVersion,
            sourceType: _sourceLib.libraryType,
            datasetMapping,
            targetLibraryID: _sourceLib?.targetStudyLibrary?.library?.libraryID,
            targetLibraryVersion: _sourceLib?.targetStudyLibrary?.library?.libraryVersion
          });
        }
      } else {
        if (_sourceLib.targetStudyLibrary?.library) {
          isValidMapping = isValidMapping || true;
          cloneDataMappings.push({
            sourceLibraryID: _sourceLib.libraryID,
            sourceLibraryVersion: _sourceLib.libraryVersion,
            sourceType: _sourceLib.libraryType,
            datasetMapping: {
              [_sourceLib.source]: _sourceLib?.targetStudyLibrary?.library?.source
            },
            targetLibraryID: _sourceLib?.targetStudyLibrary?.library?.libraryID,
            targetLibraryVersion: _sourceLib?.targetStudyLibrary?.library?.libraryVersion
          });
        }
      }
    });

    navigate('.', {
      state: {
        ...(location?.state || {}),
        cloneDataMappings
      }
    });

    setNextDisabled(!isValidMapping);
    return sourceStudyLibraries.map((_sourceLib) => ({
      ..._sourceLib,
      targetStudyLibraries: targetStudyLibraries.filter(
        (_targetLib) =>
          _targetLib.libraryType === _sourceLib.libraryType &&
          (typeof isCDRTestFlow === 'boolean' ? _targetLib.isCDRTestFlow === isCDRTestFlow : true)
      ),
      libraryTypeDisplayName: getDisplayName(_sourceLib.libraryType),
      handleExpandRow,
      handleTargetStudyLibraryChange,
      handleTargetDatasetChange
    }));
  }, [sourceStudyLibraries, targetStudyLibraries, isCDRTestFlow]);
  console.log('location?.state:', location?.state);
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Title>Select Study Libraries</Title>
        </Grid>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo
            productInfo={location?.state?.selectedSourceStudy}
            label={'Source Study Information'}
          />
        </StudyInfoWrapper>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo
            productInfo={location?.state?.selectedDataProduct}
            label={'Source Data Product & Mapping Spec Information'}
          />
        </StudyInfoWrapper>
        <Grid item xs={12}>
          <Caption sx={{ fontSize: '16px', paddingTop: '1rem' }}>
            The mapping spec selected in the previous step is currently associated with the study
            libraries listed below. Map each study library to a library in your target study to use
            in your clone data product’s draft. If a data set is not mapped it will be ignored.
          </Caption>
        </Grid>
        <TableWrapper item xs={12}>
          <Table
            title={'Study Libraries'}
            subtitle={`${sourceStudyLibraries.length} Study Libraries`}
            isLoading={isLoading}
            columns={columns}
            rows={studyLibraryMappings}
            rowsPerPageOptions={[10, 50, 100, 'All']}
            hasScroll
            ExpandableComponent={ExpandedRow}
            maxHeight={650}
            tablePaginationProps={{
              truncate: true
            }}
            defaultRowsPerPage={10}
          />
        </TableWrapper>
      </Grid>
    </>
  );
};

export default SelectStudyLibraries;
