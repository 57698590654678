/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import StudyInfo from 'Components/SourceStudyDisplay/StudyDisplayInfo';
import Grid from 'apollo-react/components/Grid';
import { StudyInfoWrapper, TableWrapper, Title } from '../../Common';
import { downloadGlobalLibrary } from 'Redux/Service/GlobalLibraryService';
import columns from 'Pages/DataStandardLibrary/AddGlobalLibrary/filterData';
import Table from 'apollo-react/components/Table';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useDispatch } from 'react-redux';

const PreviewTargetModel = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { setNextDisabled } = useOutletContext();
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setNextDisabled(false);
  }, []);

  const getTargetModelData = async () => {
    setLoading(true);
    try {
      if (location.state?.targetDataModelID && location.state?.targetDataModelVersion) {
        const globalLibraryResponse = await downloadGlobalLibrary({
          libraryId: location.state?.targetDataModelID,
          libraryVersion: location.state?.targetDataModelVersion
        });
        if (globalLibraryResponse?.data?.success) {
          setRowData(globalLibraryResponse?.data?.downloadDomainColumns || []);
        } else {
          throw res?.data?.message;
        }
      }
    } catch (error) {
      console.log('Error while fetching Target Modal Data ::', error);
      dispatch(showBanner({ variant: 'error', message: error?.message || error }));
      setNextDisabled(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTargetModelData();
  }, [location.state?.targetDataModelID, location.state?.targetDataModelVersion]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Title>Preview Target Model</Title>
        </Grid>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo
            productInfo={location?.state?.selectedSourceStudy}
            label={'Source Study Information'}
          />
        </StudyInfoWrapper>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo
            productInfo={location?.state?.selectedDataProduct}
            label={'Source Data Product & Mapping Spec Information'}
          />
        </StudyInfoWrapper>
        <TableWrapper item xs={12}>
          <Table
            title={location?.state?.targetDataModelName || 'Target Data Model'}
            isLoading={isLoading}
            columns={columns}
            rows={rowData}
            rowsPerPageOptions={[10, 50, 100, 'All']}
            hasScroll
            showFilterIcon
            maxHeight={650}
            tablePaginationProps={{
              truncate: true
            }}
            defaultRowsPerPage={10}
          />
        </TableWrapper>
      </Grid>
    </>
  );
};

export default PreviewTargetModel;
