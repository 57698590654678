/* eslint-disable */
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PreviewTable from './PreviewTable';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Select, TextField } from 'Components/Controls';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import CustomModal from '../../../Components/Modal';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  GetLibraryCategory,
  GetLibraryType,
  getVariableRuleSets
} from 'Redux/Service/AddGlobalLibraryService';
import { getDomainColumnAttributes, editGlobalLibrary } from 'Redux/Service/GlobalLibraryService';
import {
  jsonToExcel,
  convertExcelToJson,
  isNull,
  fieldLength,
  checkDataType,
  extractExcelColsHavingFormula,
  getBufferString,
  fileByteToFileObject
} from 'Utils';
import { BASE_OBJ, columnMappings } from './constants';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { makeStyles } from '@mui/styles';
import AddGlobalSDTM from './Components/AddGlobalSDTM';
import RuleSetEditor from 'Components/RuleSet/RuleSetEditor';
import { ruleSetValidator } from 'Components/RuleSet/ruleSetValidator';

const MAX_CHAR_ALLOWED = 'Maximum ${max} characters are allowed';

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required')
    .max(50, MAX_CHAR_ALLOWED)
    .matches(
      /^[a-zA-Z0-9\\(\\[)\]\\ \\._-]+$/g,
      'Only alphanumeric and -,.,_,(),[] are allowed for Library name'
    ),
  type: yup.string().required('Type is required').max(50, MAX_CHAR_ALLOWED),
  category: yup.string().required('Category is required').max(50, MAX_CHAR_ALLOWED),
  description: yup.string().max(400, MAX_CHAR_ALLOWED),
  comments: yup.string().max(400, MAX_CHAR_ALLOWED)
});
const allowedTypes = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'csv'];

const globalLibraryColumn = [
  {
    versionName: '',
    seqForOrder: '',
    observationClass: '',
    domainPrefix: '',
    domainName: '',
    domainStructure: '',
    variableNameminusdomainprefix: '',
    variableName: '',
    variableLabel: '',
    type: '',
    controlledTerms: '',
    role: '',
    cdiscNotesfordomainsDescriptionforGeneralClasses: '',
    core: '',
    data_length: '',
    data_precision: '',
    data_scale: '',
    is_sdtm_yn: '',
    mapped_By: ''
  }
];

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    fontSize: '13px',
    marginLeft: '12px',
    // marginTop: '25px',
    color: '#0768FD',
    textDecoration: 'underline',
    cursor: 'pointer',
    textDecorationStyle: 'dotted',
    textUnderlinePosition: 'under',
    float: 'right'
  }
});
export const AddGlobalLibrary = forwardRef((props, ref) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uploadLibrary, setUploadLibrary] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const { rowData } = useSelector((state) => state.EditGlobalData);
  const [domainColumnAttributes, setDomainColumnAttributes] = useState({});
  const [byte, setByte] = useState(null);
  const [row, setRowData] = useState([]);
  const [isAddForm, setIsAddForm] = useState(true);
  const [deleteFile, setDeleteFile] = useState(false);
  const [category, setCategory] = useState([]);
  const [libraryType, setLibraryType] = useState([]);
  const [uploadLibraryRuleSet, setUploadLibraryRuleSet] = useState([]);
  const [fileUploadInfoRuleSet, setFileUploadInfoRuleSet] = useState([]);
  const [errorCount, setErrorCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      category: '',
      description: '',
      comments: '',
      reasonForChange: ''
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      /* istanbul ignore next */
      if (uploadLibrary.length !== 0 && errorCount === 0) {
        setIsPreview(true);
        // setUploadLibraryRuleSet([]);
      } else if (uploadLibraryRuleSet.length !== 0 && errorCount === 0) {
        setIsPreview(true);
        setUploadLibrary([]);
      }
    }
  });

  const [formsObj, setFormsObj] = useState({});
  const [codeListDataMapping, setCodeListDataMapping] = useState({});
  const [serviceResult, setServiceResult] = useState({});
  const [fileValidationResult, setFileValidationResult] = useState({});
  const [ruleSetValidationResult, setRuleSetValidationResult] = useState({});

  /* istanbul ignore next */
  useEffect(() => {
    (async () => {
      try {
        const _libraryType = [];
        const getLibraryTypeData = await dispatch(GetLibraryType()).then(unwrapResult);
        if (getLibraryTypeData && getLibraryTypeData.data && getLibraryTypeData.data.success) {
          getLibraryTypeData.data.libraryTypes.map((el) => {
            _libraryType.push({
              value: el,
              label: el
            });
          });
          setLibraryType(_libraryType);
        } else {
          dispatch(showBanner({ variant: 'error', message: getLibraryTypeData?.data?.message }));
          setLibraryType([]);
        }
      } catch (err) {
        dispatch(showBanner({ variant: 'error', message: getLibraryTypeData?.data?.message }));
      }
      try {
        const getLibraryCategoryData = await dispatch(GetLibraryCategory()).then(unwrapResult);
        const _category = [];
        if (getLibraryCategoryData.data.success) {
          getLibraryCategoryData.data.libraryTypes.map((el) => {
            _category.push({
              value: el.toUpperCase(),
              label: el
            });
          });
          setCategory(_category);
        } else {
          dispatch(
            showBanner({ variant: 'error', message: getLibraryCategoryData?.data?.message })
          );
          setCategory([]);
        }
      } catch (err) {
        dispatch(showBanner({ variant: 'error', message: getLibraryCategoryData?.data?.message }));
      }

      getDomainColumnAttributes()
        .then((res) => {
          setDomainColumnAttributes(res.data.domainColumnAttributes);
        })
        .catch((err) => console.log(err));
      if (
        !location.pathname
          .substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length)
          .startsWith('add')
      ) {
        setIsAddForm(false);
        const editGlobalData = editGlobalLibrary(rowData);
        editGlobalData
          .then((res) => {
            setByte('data:application/octet-stream;base64,' + res.data.fileBytes);
            fileByteToFileObject(
              'data:application/octet-stream;base64,' + res.data.fileBytes,
              rowData.fileName
            ).then((fileObject) => setUploadLibrary([fileObject]));
          })
          .catch((err) => console.log(err));
        const { category, comments, description, libraryType, libraryName } = rowData;
        formik.setFormikState((prevState) => {
          return {
            ...prevState,
            values: { name: libraryName, type: libraryType, category, comments, description }
          };
        });
      }
    })();
  }, []);
  /* istanbul ignore next */
  useEffect(() => {
    /** Adding this useEffect to check the edit scenario of ruleset when we change the type from ruleset to any other. */
    formik.values.type !== '' &&
      formik.values.type !== 'Ruleset' &&
      uploadLibrary[0]?.name === '' &&
      setUploadLibrary([]);
    setFileValidationResult({});
  }, [formik.values.type]);

  /* istanbul ignore next */
  const validateRuleSet = () => {
    let count = 0;
    if (uploadLibraryRuleSet.length > 0) {
      const validation = ruleSetValidator(
        uploadLibraryRuleSet,
        codeListDataMapping,
        formsObj,
        serviceResult
      );
      count = validation.getErrorCount();
      setRuleSetValidationResult(validation);
      setErrorCount(count);
    }
    return count;
  };
  /* istanbul ignore next */
  const hasValidate = () => {
    if (formik.values.type !== 'Ruleset') {
      if (!formik.dirty || Object.keys(formik.errors).length > 0 || uploadLibrary.length === 0) {
        uploadLibrary.length === 0 && setErrorMessage({ uploadLibrary: 'Library is required' });
        setIsPreview(false);
        return false;
      }
    } else {
      if (
        !formik.dirty ||
        Object.keys(formik.errors).length > 0 ||
        uploadLibraryRuleSet.some((item) => item.expression === '')
      ) {
        return false;
      }
      let count = validateRuleSet();
      if (count !== 0) {
        return false;
      }
    }

    return true;
  };
  /* istanbul ignore next */
  useImperativeHandle(ref, () => ({
    handleSubmit: formik.handleSubmit,
    hasValidate,
    validateRuleSet,
    handleStepperback: () => {
      setIsPreview(false);
    },
    resetForm: () => {
      setUploadLibrary([]);
      formik.resetForm();
    },
    getPayload: () => {
      if (formik.values.type !== 'Ruleset') {
        return {
          ...formik.values,
          fileByte: byte,
          fileName: uploadLibrary[0].name
        };
      } else {
        return {
          ...formik.values,
          fileByte: null,
          variableRulesets: uploadLibraryRuleSet
        };
      }
    }
  }));

  /* istanbul ignore next */
  const columnValidate = (value, rules, modifiedRow) => {
    if (rules) {
      let isCoreValueCorrect = true;
      let isMappedByValueCorrect = true;
      let isTypeColumnValueCorrect = true;
      let isLengthColumnValueCorrect = true;
      let isPrecisionColumnValueCorrect = true;
      const isNullable = isNull(rules.isNullable, value);
      const isLength = fieldLength(parseInt(rules.dataLength), value);
      const isDataType = checkDataType(rules.dataType, value);
      !(isDataType && isNullable && isLength) && console.log(value, rules, 'check');
      !(isDataType && isNullable && isLength) &&
        console.table({ isDataType, isNullable, isLength });
      if (
        rules.columnName === 'constrnt' &&
        !(
          value?.toLowerCase() === 'req' ||
          value?.toLowerCase() === 'exp' ||
          value?.toLowerCase() === 'perm'
        )
      ) {
        isCoreValueCorrect = false;
      }
      if (
        rules.columnName === 'mapped_by' &&
        !(
          value?.toLowerCase() === 'r' ||
          value?.toLowerCase() === 'd' ||
          value?.toLowerCase() === 's'
        )
      ) {
        isMappedByValueCorrect = false;
      }
      if (
        rules.columnName === 'data_type' &&
        !(
          value?.toLowerCase() === 'char' ||
          value?.toLowerCase() === 'num' ||
          value?.toLowerCase() === 'date'
        )
      ) {
        isTypeColumnValueCorrect = false;
      }
      if (
        rules.columnName === 'data_length' &&
        modifiedRow.type?.toLowerCase() === 'char' &&
        !(Number.isInteger(value) && value >= 1)
      ) {
        isLengthColumnValueCorrect = false;
      }
      if (
        rules.columnName === 'data_precision' &&
        modifiedRow.type?.toLowerCase() === 'num' &&
        !(Number.isInteger(value) && value >= 1)
      ) {
        isPrecisionColumnValueCorrect = false;
      }
      return {
        isDataType: isDataType,
        isNullable: isNullable,
        isLength: isLength,
        isCoreCorrect: isCoreValueCorrect,
        isTypeColumnCorrect: isTypeColumnValueCorrect,
        isLengthColumnCorrect: isLengthColumnValueCorrect,
        isPrecisionColumnCorrect: isPrecisionColumnValueCorrect,
        isMappedByCorrect: isMappedByValueCorrect,
        valid:
          isDataType &&
          isNullable &&
          isLength &&
          isCoreValueCorrect &&
          isMappedByValueCorrect &&
          isTypeColumnValueCorrect &&
          isLengthColumnValueCorrect &&
          isPrecisionColumnValueCorrect
      };
    }
    return false;
  };

  /* istanbul ignore next */
  const fileValidation = (fileData, excelColsHavingFormula) => {
    let validationResult = {
      isCoreCorrect: true,
      isTypeColumnCorrect: true,
      isMappedByCorrect: true,
      isDataPresent: true,
      isNoFormulaPresent: excelColsHavingFormula,
      mandatoryColumns: [],
      missingColumns: [],
      incorrectDataType: [],
      incorrectDataLength: [],
      valid: true
    };
    for (let row of fileData) {
      let modifiedRow = {};
      Object.keys(row).forEach((key) => {
        modifiedRow[key.trim().toLowerCase()] = row[key];
      });
      const uploadedTemplateCols = Object.keys(modifiedRow);
      for (let key in BASE_OBJ) {
        if (uploadedTemplateCols.includes(key)) {
          const rules = domainColumnAttributes[columnMappings[key]];
          const columnValidationResult = columnValidate(modifiedRow[key], rules, modifiedRow);
          if (columnValidationResult.valid) {
            continue;
          }
          if (
            !columnValidationResult.isNullable &&
            !validationResult?.mandatoryColumns.includes(key)
          )
            validationResult.mandatoryColumns.push(key);
          if (
            !columnValidationResult.isDataType &&
            !validationResult?.incorrectDataType.includes(key)
          )
            validationResult.incorrectDataType.push(key);
          if (
            !columnValidationResult.isLength &&
            !validationResult?.incorrectDataLength.includes(key)
          )
            validationResult.incorrectDataLength.push(key);
          if (!columnValidationResult.isCoreCorrect) validationResult.isCoreCorrect = false;
          if (!columnValidationResult.isMappedByCorrect) validationResult.isMappedByCorrect = false;
          if (!columnValidationResult.isTypeColumnCorrect)
            validationResult.isTypeColumnCorrect = false;
          if (!columnValidationResult.isLengthColumnCorrect)
            validationResult.isLengthColumnCorrect = false;
          if (!columnValidationResult.isPrecisionColumnCorrect)
            validationResult.isPrecisionColumnCorrect = false;
        } else if (!validationResult?.missingColumns.includes(key)) {
          validationResult.missingColumns.push(key);
        }
        validationResult.valid = false;
      }
    }
    if (fileData.length === 0) {
      validationResult.isDataPresent = false;
      validationResult.valid = false;
    }
    setFileValidationResult(validationResult);
    return validationResult;
  };

  /* istanbul ignore next */
  const handleUpload = (selectedFiles) => {
    setErrorMessage({});
    setFileValidationResult({});
    const size = selectedFiles[0].size;
    if (size < 10 * 1024 * 1024) {
      const file = selectedFiles[0].name.split('.');
      const extension = file[file.length - 1];
      if (allowedTypes.includes(extension)) {
        let excelColsHavingFormula = [];
        extractExcelColsHavingFormula(selectedFiles[0]).then((response) => {
          if (response?.length > 0) {
            excelColsHavingFormula = response;
          }
        });
        convertExcelToJson(selectedFiles[0], { defval: null }).then((response) => {
          setRowData(response);
          const isFileValid = fileValidation(response, excelColsHavingFormula);
          if (isFileValid?.valid) {
            setUploadLibrary([selectedFiles[0]]);
            getBufferString(new Blob(selectedFiles)).then((res) => {
              setByte(res);
            });
            setErrorMessage({});
          }
        });
      } else {
        setErrorMessage({ uploadLibrary: `Only excel file is supported` });
      }
    } else {
      setErrorMessage({ uploadLibrary: `File Size too large` });
    }
  };
  /* istanbul ignore next */
  const openDeleteModal = () => {
    setDeleteFile(true);
  };
  /* istanbul ignore next */
  const handleDelete = () => {
    setDeleteFile(false);
    setUploadLibrary([]);
  };
  /* istanbul ignore next */
  const handleClose = () => {
    setDeleteFile(false);
  };

  const error = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };
  /* istanbul ignore next */
  const handleDownload = () => {
    jsonToExcel(globalLibraryColumn, 'GlobalLibrary.xlsx');
  };
  /* istanbul ignore next */
  const getVersionInfo = () => {
    return rowData && rowData.version ? rowData.version : 'New';
  };
  /* istanbul ignore next */
  const getLibraryIDInfo = () => {
    return rowData && rowData.libraryID ? rowData.libraryID : '';
  };
  /* istanbul ignore next */
  const getVariableRuleSetsOnEdit = async (rowData) => {
    const variableRuleSets = await dispatch(getVariableRuleSets(rowData)).then(unwrapResult);
    if (variableRuleSets?.data?.success) {
      setRowData(variableRuleSets.data.variableRulesets);
      setUploadLibraryRuleSet(variableRuleSets.data.variableRulesets);
    } else {
      setRowData([]);
      dispatch(showBanner({ variant: 'error', message: variableRuleSets?.data?.message }));
    }
  };
  /* istanbul ignore next */
  useEffect(() => {
    rowData !== null &&
      Object.keys(rowData).length > 0 &&
      rowData.libraryID &&
      rowData.libraryType &&
      getVariableRuleSetsOnEdit(rowData);
  }, [rowData]);

  return (
    <>
      <Box pt={0} pl={3} pr={3}>
        <Typography variant="h3">
          {isPreview && formik.values.type === 'Ruleset'
            ? 'Preview'
            : !isAddForm
            ? 'Edit Global Library'
            : 'Add Global Library'}
        </Typography>
        {!isPreview ? (
          <>
            <Box mt={2}>
              <Grid container spacing={0}>
                <Grid item xs={5}>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    inputProps={{ 'data-testid': 'name-input' }}
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant="body2">Version</Typography>
                  <Typography variant="title">{getVersionInfo()}</Typography>
                </Grid>
                {rowData && (
                  <Grid item xs={5}>
                    <Typography variant="body2">Library ID</Typography>
                    <Typography variant="title">{getLibraryIDInfo()}</Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box width={'100%'}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Select
                    id="type"
                    label="Type"
                    name="type"
                    data-testid="type-input"
                    required
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    fullWidth
                    items={libraryType}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Select
                    id="category"
                    label="Category"
                    data-testid="category-input"
                    name="category"
                    required
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.category && Boolean(formik.errors.category)}
                    helperText={formik.touched.category && formik.errors.category}
                    items={category}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            {formik.values.type !== '' &&
              (formik.values.type === 'Ruleset' ? (
                <RuleSetEditor
                  formik={formik}
                  ruleSetValidationResult={ruleSetValidationResult}
                  setUploadLibraryRuleSet={setUploadLibraryRuleSet}
                  setFileUploadInfoRuleSet={setFileUploadInfoRuleSet}
                  uploadLibraryRuleSet={uploadLibraryRuleSet}
                />
              ) : (
                <AddGlobalSDTM
                  handleUpload={handleUpload}
                  openDeleteModal={openDeleteModal}
                  handleDownload={handleDownload}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  uploadLibrary={uploadLibrary}
                  errorMessage={errorMessage}
                  error={error}
                  title={classes.title}
                  formik={formik}
                  fileValidationResult={fileValidationResult}
                />
              ))}
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <TextField
                  id="description"
                  label="Description (Optional)"
                  name="description"
                  data-testid="description-input"
                  multiline
                  value={formik?.values.description}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={Boolean(formik?.errors.description)}
                  helperText={formik?.errors.description}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        ) : formik.values.type !== 'Ruleset' ? (
          <PreviewTable
            title={formik.values.name}
            subtitle={uploadLibrary[0]?.name}
            fileByte={byte}
            rows={row}
            fileName={uploadLibrary[0]?.name}
            selectedFile={uploadLibrary[0]}
          />
        ) : (
          <Box mt={2}>
            <RuleSetEditor
              preview
              previewTitle={formik?.values.name}
              uploadLibraryRuleSet={uploadLibraryRuleSet}
              setUploadLibraryRuleSet={setUploadLibraryRuleSet}
            />
          </Box>
        )}
      </Box>
      <CustomModal
        display={deleteFile}
        data={row}
        title={'Delete File'}
        buttonPrimaryLabel={'Ok'}
        message={'Are you sure you would like to delete this file?'}
        handlePrimaryAction={() => handleDelete()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => handleClose()}
        variant={'warning'}
      />
    </>
  );
});
