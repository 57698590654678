import { DATA_FLOW_TYPES } from "Constants/DataFlowTypes";

export const handleSelectedSources = (selectSourceDetails) => {
  let data = selectSourceDetails?.masterDataSources?.filter(
    (item) => item.selected === true && item.connection === true
  );

  const dataSources = data?.map((item) => {
    if (item.parentSourceName === 'CDR') {
      const cdrData = selectSourceDetails.masterDataSources?.filter(
        (item) => item.sourceName === 'CDR'
      )[0];
      return {
        databaseName: cdrData.databaseName,
        sourceName: cdrData.sourceName,
        userID: cdrData.userID,
        dataSourceID: cdrData.dataSourceID,
      };
    } else {
      return {
        databaseName: item.databaseName,
        sourceName: item.sourceName,
        userID: item.userID,
        dataSourceID: item.dataSourceID,
      };
    }
  });

  const set = new Set();
  const uniqueDataSources = dataSources?.filter((el) => {
    const duplicate = set.has(el.sourceName);
    set.add(el.sourceName);
    return !duplicate;
  });
  return uniqueDataSources;
};

export const handleTrialItems = (selectedSourceData) => {
  let obj = {};
  selectedSourceData.map(item => {
    // if(item.sourceName !== 'CDR')
      obj[item.sourceName] = item.trialName
  });

  return obj;
}

export const handleTrialItemsForDisplay = (selectedSourceData) => {
  let obj = {};
  selectedSourceData.map((item) => {
    obj[item.sourceName] = DATA_FLOW_TYPES[item.trialName] || item.trialName;
  });
  return obj;
};

export const getEcrfTrialName = (selectedSourceData) => {
  let ecrfTrialName = '';
  selectedSourceData?.map((item) => {
    ecrfTrialName = ecrfTrialName || item.ecrfTrialName;
  });
  return ecrfTrialName;
};