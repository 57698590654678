import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from 'apollo-react/components/Typography';
import { Modal } from 'apollo-react';

const SessionTimeoutDialog = ({ open, countdown, onContinue, onLogout }) => {
  const styles = {
    modal: {
      width: '980px'
    }
  };

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <Modal
      variant="error"
      title="Session Timeout"
      open={open}
      className={classes.modal}
      buttonProps={[
        { label: 'Logout', onClick: onLogout },
        { label: 'Continue Session', onClick: onContinue }
      ]}
      message="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor">
      <Typography variant="h5">{`Would you like to continue the session?`}</Typography>
      <Typography variant="h4">
        The current session is about to expire in <span style={{ color: 'red' }}>{countdown}</span>{' '}
        seconds.
      </Typography>
    </Modal>
  );
};

export default SessionTimeoutDialog;
