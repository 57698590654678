import React, { useState, useEffect, useCallback } from 'react';
import Blade from 'apollo-react/components/Blade';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';
import DropMenu from 'Components/DropMenu/CustomDropMenu';
import CustomModal from '../../../../Components/Modal/Modal';

import MapSpecPublished from 'Images/mapping-spec-published.svg';
import MapSpec from 'Images/mapping-spec-version-color.svg';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import TimeLine from './Components/TimeLine';
import { useLocation, useNavigate } from 'react-router-dom';

import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { setMappingSpecDetails } from 'Redux/Slice/PublishMappingSpecSlice';
import {
  GetMappingSpecVersions,
  DownloadMappingSpecData,
  CopyDataProduct
} from 'Redux/Service/PublishMappingSpecService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { downloadFile } from './publishUtils';
import { dateFormatByType } from 'Utils';
import { useMappingSpecActivity } from './Components/UseMappingSpecActivity';
import { getRowDataForRuleEditor } from '../../../../Redux/Slice/DataProductLibrarySlice';
import nomappingspec from 'Images/nomappingspec.svg';
import moment from 'moment';
import { hasNoAccess } from 'service/AppPermissions';
import { GetWorkflowModel } from 'Redux/Service/RuleEditorService';
import Cookies from 'js-cookie';
import useDisplayName from 'Utils/useDisplayName';
import Switch from 'apollo-react/components/Switch';
import { toggleMappingSpecFullListStatus } from 'Redux/Service/PublishDataProductService';

const useStyles = makeStyles({
  expandPanelButton: {
    display: 'none'
  },
  backdrop: {
    zIndex: 3999
  },
  paper: {
    zIndex: 4000,
    fontFamily: 'Proxima Nova',
    background: 'white'
  },
  mapSpecDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem'
  },
  mapSpecDetailsTitle: { color: '#999999', fontSize: '1rem', fontWeight: '400' },
  mapSpecDetailsDescription: { color: '#000000', fontSize: '1rem', fontWeight: '500' },
  activityLogTitle: {
    fontSize: 'larger',
    fontWeight: 'bold',
    marginTop: '1.5rem',
    opacity: 0.7
  }
});

const handleDateSort = (mappingSpecVersions) => {
  let sortedMappingSpecVersions = mappingSpecVersions;
  sortedMappingSpecVersions?.sort((a, b) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
    return dateB - dateA;
  });
  return sortedMappingSpecVersions;
};

const MappingSpecDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();
  const location = useLocation();
  let path = location.pathname;

  const {
    displayMappingSpecOverlay,
    toggleMappingSpecOverlay,
    productNnemonic: productMnemonic,
    productID,
    productVersion,
    targetDataModelName,
    description,
    fetchList,
    protocolName,
    targetDataModelVersion,
    viewOnly,
    studyLibraries,
    targetDataModelType,
    mappingRuleversion,
    getStudyLibrary
  } = props;
  const { mappingSpecDetails } = useSelector((state) => state.PublishMappingSpecData);
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const [selectedMappingSpec, selectMappingSpec] = useState(fetchList ? {} : mappingSpecDetails);
  const [mappingSpecVersionList, setMappingSpecList] = useState([]);
  const [viewLoaderId, setViewLoaderId] = useState();
  const [publishLoaderId, setPublishLoaderId] = useState();
  const [handleFetchMappingSpecActivity] = useMappingSpecActivity();
  const [warningEnabled, setWarningEnabled] = useState(false);
  const [selectedMapSpec, setSelectedMapSpec] = useState({});
  const [showAllMappingSpecsFlag, setShowAllMappingSpecsFlag] = useState(false);

  const fetchMappingSpecVersions = async () => {
    if (fetchList) {
      const {
        data: { success, isToggleOn, mappingSpecVersions = [] }
      } = await dispatch(
        GetMappingSpecVersions({
          dataProductID: productID,
          ProtoclNumber: protocolName,
          IsFullMappingList: viewOnly ? true : false
        })
      ).then(unwrapResult);
      if (success) {
        const sortedMappingSpecVersions = handleDateSort(mappingSpecVersions);
        setShowAllMappingSpecsFlag(isToggleOn);
        setMappingSpecList(sortedMappingSpecVersions);
      }
    }
  };

  useEffect(() => {
    fetchMappingSpecVersions();
  }, [productID]);

  useEffect(() => {
    document.getElementsByTagName('body')[0].style = 'padding-right: 17px; overflow: hidden;';
    return () => {
      document.getElementsByTagName('body')[0].style = undefined;
    };
  }, []);

  const onDownload = async (mapSpec) => {
    const targetDataModelType = studyLibraries?.filter(
      (item) =>
        item.productNnemonic === productMnemonic && item.targetDataModelName === targetDataModelName
    )[0].targetDataModelType;

    const downloadResult = await dispatch(
      DownloadMappingSpecData({
        mappingRuleVersionID: mapSpec.mappingRuleVersionID
      })
    ).then(unwrapResult);
    if (downloadResult && downloadResult.data && downloadResult.data.success) {
      const params = {
        protocolName: protocolName,
        projectCode: protocol.projectCode,
        sponsor: protocol.sponsor,
        mappingSpecVersionName: mapSpec.mappingSpecVersionName,
        productMnemonic: productMnemonic,
        targetDataModelName: targetDataModelName,
        targetDataModelVersion: targetDataModelVersion,
        targetDataModelType: targetDataModelType,
        getDisplayName
      };
      downloadFile(
        downloadResult.data,
        params,
        `${protocolName}_${productMnemonic}_${mapSpec.mappingSpecVersionName}`,
        {
          createdBy: mapSpec.createdBy,
          createdDate: moment(mapSpec.createdDate).format('DD-MM-YYYY hh:mm A')
        }
      );
    } else {
      dispatch(showBanner({ variant: 'error', message: 'Something Went Wrong!' }));
    }
  };

  const menuItems = [
    {
      text: 'Publish',
      disabled: hasNoAccess(protocolName, 'mappingSpecPublish'),
      onClick: async (mapSpec) => {
        setPublishLoaderId(mapSpec.mappingRuleVersionID);
        const mappingSpecActivity = await handleFetchMappingSpecActivity(mapSpec);
        dispatch(
          setMappingSpecDetails({
            ...mapSpec,
            productMnemonic,
            productID,
            productVersion,
            targetDataModelName,
            protocolName,
            description,
            activity: mappingSpecActivity,
            targetDataModelType
          })
        );
        navigate('/product-designer/manage-data-pipeline-version');
        setPublishLoaderId();
      }
    },
    {
      text: 'Copy to Draft',
      onClick: (mapSpec) => {
        setWarningEnabled(true);
        setSelectedMapSpec(mapSpec);
        console.log('mapSpec.mappingRuleVersionID', mapSpec.mappingRuleVersionID);
      }
    },
    {
      text: 'Download',
      disabled: hasNoAccess(protocolName, 'mappingSpecDownload'),
      onClick: (mapSpec) => {
        onDownload(mapSpec);
      }
    }
  ];

  const handleView = async (mapSpec) => {
    setViewLoaderId(mapSpec.mappingRuleVersionID);
    const mappingSpecActivity = await handleFetchMappingSpecActivity(mapSpec);
    selectMappingSpec({
      ...mapSpec,
      productMnemonic,
      productID,
      productVersion,
      targetDataModelName,
      protocolName,
      description,
      activity: mappingSpecActivity
    });
    setViewLoaderId();
  };

  const handleCopyDataProduct = async (mapSpec) => {
    const now = new Date().toISOString();
    const payload = {
      draftMappingSpecVersionRuleID: mappingRuleversion,
      prodMappingSpecVersionRuleID: mapSpec.mappingRuleVersionID,
      protocolName: protocolName,
      createdDate: now,
      createdBy: Cookies.get('user.id')
    };
    const response = await dispatch(CopyDataProduct(payload)).then(unwrapResult);
    if (response.data.success) {
      getStudyLibrary();
      dispatch(showBanner({ variant: 'success', message: response.data.message }));
      handleView(selectedMapSpec);
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
  };

  const renderTitle = (step) => {
    if (step?.environmentName)
      return (
        <span style={{ color: 'black' }}>
          Published to <span style={{ fontWeight: '700' }}>{step?.environmentName}</span> by{' '}
          <span style={{ fontWeight: '700' }}>{step?.createdBy}</span> on{' '}
          {dateFormatByType(step?.createdDate, 'Publish')}
        </span>
      );
    else if (step.comments === 'Copied to draft')
      return (
        <span>
          Mapping Spec{' '}
          <span style={{ color: 'black' }}>
            {' '}
            <span style={{ fontWeight: '700' }}>Copied to draft </span>by{' '}
            <span style={{ fontWeight: '700' }}>{step?.createdBy}</span> on{' '}
            {dateFormatByType(step?.createdDate, 'Publish')}
          </span>
        </span>
      );
    else
      return (
        <span style={{ color: 'black' }}>
          Created by <span style={{ fontWeight: '700' }}>{step?.createdBy}</span> on{' '}
          {dateFormatByType(step?.createdDate, 'Publish')}
        </span>
      );
  };

  /**
   * Memoized function to fetch title
   */
  const getTitle = useCallback(() => {
    if (fetchList) {
      return selectedMappingSpec?.mappingSpecVersionName
        ? 'View Mapping Spec Version'
        : 'Mapping Spec Versions';
    } else return 'Mapping Spec Version';
  }, [fetchList, selectedMappingSpec?.mappingSpecVersionName]);

  /**
   * Memoized function to fetch subtitle
   */
  const getSubTitle = useCallback(() => {
    if (fetchList) {
      return selectedMappingSpec?.mappingSpecVersionName
        ? `Mapping Spec Version: ${selectedMappingSpec?.mappingSpecVersionName}`
        : `Data Product: ${productMnemonic}`;
    } else return `${selectedMappingSpec?.mappingSpecVersionName}`;
  }, [fetchList, selectedMappingSpec?.mappingSpecVersionName, productMnemonic]);

  const renderBackButton = useCallback(() => {
    return fetchList ? (
      <Button
        icon={<ChevronLeft />}
        style={{
          marginRight: '0.5rem',
          width: 'fit-content',
          fontWeight: 'bold',
          backgroundColor: '#9991'
        }}
        variant={'text'}
        size={'small'}
        onClick={() => selectMappingSpec({})}
        data-testid="view">
        {'Return to Mapping Versions'}
      </Button>
    ) : null;
  }, [fetchList]);

  const navigateToRuleEditor = async (rowData) => {
    if (rowData.mappingRuleversion) {
      dispatch(getRowDataForRuleEditor(rowData));
      let isSuccess = false;
      let payload;
      if (rowData.targetDataModelType === 'CTP') {
        payload = 'IQVIA-CTP';
      } else {
        payload = rowData.targetDataModelType;
      }
      const workflowData = await dispatch(GetWorkflowModel(payload)).then(unwrapResult);
      if (workflowData) {
        if (workflowData.functionData && workflowData.functionData.length > 0) {
          isSuccess = true;
          let workflow = workflowData.functionData;
          workflow = workflow.sort((a, b) => a.order - b.order);
          navigate(
            `/product-designer/rule-editor/${rowData.mappingRuleversion}/${workflow[0].moduleName}`
          );
        }
      }
      if (!isSuccess) {
        dispatch(showBanner({ variant: 'error', message: 'Error Getting Workflow Model' }));
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: 'MappingRuleVersionID Not Found' }));
    }
  };

  const handlePublishSwitch = async (e) => {
    setShowAllMappingSpecsFlag(e.target.checked);
    const payload = {
      protocolNumber: protocol.protocolNumber
    };
    const toggleMappinSpecResponse = await dispatch(toggleMappingSpecFullListStatus(payload)).then(
      unwrapResult
    );
    if (toggleMappinSpecResponse?.data?.success) {
      fetchMappingSpecVersions();
    } else {
      dispatch(showBanner({ variant: 'error', message: toggleMappinSpecResponse.data.message }));
    }
  };

  const renderPlaceholder = useCallback(() => {
    return mappingSpecVersionList?.length === 0 ? (
      <React.Fragment>
        <div style={{ marginTop: '20%', display: 'grid', textAlign: 'center' }}>
          <img
            src={`${nomappingspec}`}
            style={{ display: 'block', margin: '0 auto', paddingBottom: '2%' }}
          />
          <p style={{ fontSize: '21px', fontWeight: '600' }}>No Mapping Specs</p>
        </div>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            paddingRight: '6rem',
            paddingLeft: '6rem',
            color: '#898989',
            boxSizing: 'border-box',
            position: 'relative',
            bottom: '2rem'
          }}>
          <p>Currently there are no mapping specs for this particular data product</p>
        </span>
        <Button
          variant="primary"
          onClick={() => navigateToRuleEditor(props)}
          style={{
            display: 'block',
            position: 'relative',
            bottom: '2.2rem',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
          Go to Rule Editor
        </Button>
      </React.Fragment>
    ) : null;
  }, [mappingSpecVersionList?.length]);

  return (
    <>
      <Blade
        open={displayMappingSpecOverlay}
        onClose={() => toggleMappingSpecOverlay(false)}
        title={getTitle()}
        subtitle={getSubTitle()}
        data-testid="Mapping Spec"
        side="right"
        classes={{
          expandPanelButton: classes.expandPanelButton,
          backdrop: classes.backdrop,
          paper: classes.paper
        }}
        width={'550px'}
        hasBackdrop>
        {selectedMappingSpec?.mappingSpecVersionName ? (
          <>
            <Box display={'flex'} flexDirection={'column'}>
              {renderBackButton()}
              <Box display={'flex'}>
                <Grid container>
                  <Grid item xs={6} className={classes.mapSpecDetails}>
                    <Typography className={classes.mapSpecDetailsTitle}>
                      Product Mnemonic
                    </Typography>
                    <Typography className={classes.mapSpecDetailsDescription}>
                      {selectedMappingSpec.productMnemonic}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.mapSpecDetails}>
                    <Typography className={classes.mapSpecDetailsTitle}>Created by</Typography>
                    <Typography className={classes.mapSpecDetailsDescription}>
                      {selectedMappingSpec.createdBy}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.mapSpecDetails}>
                    <Typography className={classes.mapSpecDetailsTitle}>Date Created</Typography>
                    <Typography className={classes.mapSpecDetailsDescription}>
                      {dateFormatByType(selectedMappingSpec.createdDate, 'Publish')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.mapSpecDetails}>
                    <Typography className={classes.mapSpecDetailsTitle}>Title</Typography>
                    <Typography className={classes.mapSpecDetailsDescription}>
                      {selectedMappingSpec.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.mapSpecDetails}>
                    <Typography className={classes.mapSpecDetailsTitle}>
                      Target Data Model
                    </Typography>
                    <Typography className={classes.mapSpecDetailsDescription}>
                      {selectedMappingSpec.targetDataModelName}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                style={{
                  marginTop: '1.5rem',
                  borderTop: '1px solid #6661'
                }}>
                <Typography className={classes.activityLogTitle}>Activity Log</Typography>
                <TimeLine activity={selectedMappingSpec?.activity} renderTitle={renderTitle} />
              </Box>
            </Box>
          </>
        ) : (
          <>
            {mappingSpecVersionList?.length !== 0 && path === '/product-designer' ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                style={{
                  borderBottom: '1px solid #E9E9E9',
                  minHeight: '3rem',
                  width: '100%',
                  paddingBottom: '1rem'
                }}>
                <Typography
                  style={{ fontSize: '14px', margin: '0px 6px 0px 0px' }}
                  variant="subtitle"
                  gutterBottom>
                  {'Show All Mapping Specs'}
                </Typography>
                <Switch
                  checked={showAllMappingSpecsFlag}
                  size="small"
                  onChange={(e) => handlePublishSwitch(e)}
                />
              </Box>
            ) : null}
            {mappingSpecVersionList?.map((mapSpec) => {
              return (
                <Box
                  key={mapSpec?.mappingSpecVersionName}
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  style={{ borderBottom: '1px solid #E9E9E9', minHeight: '5rem', width: '100%' }}>
                  <img
                    alt={'icon'}
                    src={`${mapSpec.isDeployed === 'Y' ? MapSpecPublished : MapSpec}`}
                    style={{
                      height: mapSpec.isDeployed === 'Y' ? '2.5rem' : '2.2rem',
                      color: '#0557D5',
                      width: '12%',
                      objectPosition: '5px',
                      objectFit: 'contain'
                    }}
                  />
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    style={{ width: '88%' }}>
                    <Box>
                      <span style={{ fontWeight: 'bold' }}>{mapSpec?.mappingSpecVersionName}</span>{' '}
                      by {mapSpec.createdBy}
                      <div>Date Created: {dateFormatByType(mapSpec.createdDate, 'Publish')}</div>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      {[viewLoaderId, publishLoaderId].includes(mapSpec.mappingRuleVersionID) && (
                        <CircularProgress
                          size={20}
                          style={{
                            position: 'absolute',
                            marginLeft:
                              viewLoaderId === mapSpec.mappingRuleVersionID
                                ? 'calc(2rem - 10px)'
                                : 'calc(7rem - 10px)'
                          }}
                        />
                      )}
                      <Button
                        style={{ marginRight: '0.5rem', width: '4rem' }}
                        variant={viewOnly ? 'primary' : 'text'}
                        size={'small'}
                        disabled={viewLoaderId || publishLoaderId}
                        onClick={() => handleView(mapSpec)}
                        data-testid="view">
                        View
                      </Button>
                      {viewOnly !== true && (
                        <DropMenu
                          disabled={viewLoaderId || publishLoaderId}
                          item={mapSpec}
                          size={'small'}
                          menuItems={menuItems}
                          variant="secondary"
                          label={'Actions'}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
            {renderPlaceholder()}
          </>
        )}
      </Blade>
      <CustomModal
        style={{ zIndex: 4001 }}
        display={warningEnabled}
        variant={'warning'}
        title={
          <Box display={'flex'} flexDirection={'row'}>
            <StatusExclamationIcon />
            <span style={{ marginLeft: '0.5rem' }}>{'Copy mapping spec to draft?'}</span>
          </Box>
        }
        message={
          'Are you sure you want to copy the contents of this mapping spec to the current draft? This action will overwrite the existing draft'
        }
        handlePrimaryAction={() => {
          setWarningEnabled(false);
          handleCopyDataProduct(selectedMapSpec);
        }}
        buttonPrimaryLabel={`Confirm Copy`}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => setWarningEnabled(false)}
      />
    </>
  );
};

export default MappingSpecDetails;
