/*eslint-disable */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Radio from 'apollo-react/components/Radio';
import RadioGroup from 'apollo-react/components/RadioGroup';
import Typography from 'apollo-react/components/Typography';
import TextField from 'Components/Controls/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetEnvironments, GetSchedularTypes } from 'Redux/Service/PublishMappingSpecService';
import {
  saveEnvironments,
  setSelectedEnvironment,
  setSelectedScehduler
} from 'Redux/Slice/PublishMappingSpecSlice';
import { hasNoAccess, setProtocolPermissions } from 'service/AppPermissions';
import Environment from './Environment';
import Scheduler from './Scheduler';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { getProtocolAuth } from 'Redux/Service/StudyLibraryGridService';

const useStyles = makeStyles({
  sectionTitle: {
    height: '24px',
    width: '280px',
    fontFamily: 'Proxima Nova',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '48px'
  },
  envCard: {
    width: '25%',
    height: '12.5rem',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.04)',
    backgroundColor: 'white',
    margin: '2rem 0rem 2rem 0rem',
    padding: '0rem 1rem',

    '&>.MuiTypography-root': {
      padding: '0rem',
      paddingLeft: '1rem',
      height: '100%'
    },
    '&:hover': {
      border: '1px solid #0768FD'
    }
  },
  commentTextBox: {
    minHeight: '160px',
    minWidth: '100% !important'
  }
});

const SelectEnvironment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [nextDisabled, setNextDisabled] = useState(true);
  const { handleCancel, handleNext } = props;

  const {
    mappingSpecDetails = {},
    selectedEnvironment = {},
    savedEnvironments = [],
    selectedScehduler = {}
  } = useSelector((state) => state.PublishMappingSpecData);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [comments, setComments] = useState(selectedEnvironment.comments);
  const [environments, setEnvironments] = useState(savedEnvironments);
  const [selectedEnvID, setSelectedEnvID] = useState(selectedEnvironment.environmentID);
  const [schedulerTypes, setSchedulerTypes] = useState([]);
  const [selectedSchedulerType, setSelectedSchedulerType] = useState(
    selectedScehduler.schedulerType || 'Standard'
  );

  const getEnvironments = async () => {
    const {
      data: { success, environments: envs, message }
    } = await dispatch(
      GetEnvironments({
        ProductId: mappingSpecDetails?.productID
      })
    ).then(unwrapResult);
    if (success) {
      setEnvironments(envs);
      dispatch(saveEnvironments(envs));
    } else {
      console.log('Failed to retrive environments :: ', message);
    }
  };

  const getSchedularTypes = async () => {
    const response = await dispatch(
      GetSchedularTypes({ mappingRuleVersionID: mappingSpecDetails?.mappingRuleVersionID })
    ).then(unwrapResult);
    if (response.data.success) {
      setSchedulerTypes(response.data.jobSchedulerTypes);
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
      console.log('Failed to retrive SchedulerTypes :: ', response.data);
    }
  };

  const getStudyPermissions = async (protocol) => {
    const permissionsInfo = await dispatch(getProtocolAuth(protocol)).then(unwrapResult);
    let protocolObj = {};
    if (permissionsInfo.data.success) {
      let permissionJson = permissionsInfo.data.protocolAuth;
      protocolObj[protocol] = permissionJson;
      setProtocolPermissions(protocolObj);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      await getStudyPermissions(protocol.protocolNumber);
      await getEnvironments();
      await getSchedularTypes();
    })();
  }, [mappingSpecDetails?.mappingRuleVersionID, mappingSpecDetails?.productID]);

  useEffect(() => {
    setNextDisabled(!selectedEnvID);
  }, [selectedEnvID]);

  const handleSaveAndNext = () => {
    const env = environments.find((env) => env.environmentID === selectedEnvID);
    const scheduler = schedulerTypes.find(
      (scheduler) => scheduler.schedulerType === selectedSchedulerType
    );
    dispatch(setSelectedEnvironment({ ...env, comments }));
    dispatch(setSelectedScehduler({ ...scheduler }));
    handleNext();
  };

  const checkEnvAccessDisabled = (environment) => {
    const NonProdAccess = hasNoAccess(
      protocol.protocolNumber,
      'mappingSpecDeploymentNonProduction'
    );
    const ProdAccess = hasNoAccess(protocol.protocolNumber, 'mappingSpecDeploymentProduction');
    console.log('checkEnvAccess', environment, ProdAccess, NonProdAccess);
    switch (environment !== '') {
      case environment === 'Prod':
        return ProdAccess;
      case environment !== 'Prod':
        return NonProdAccess;
      default:
        return true;
    }
  };

  return (
    <Box>
      <Typography className={classes.sectionTitle}>Choose a Logical Environment</Typography>
      <RadioGroup
        aria-label="label"
        name="label"
        value={selectedEnvID}
        onChange={(e) => setSelectedEnvID(e.target.value)}>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          {environments.map((env, index) => (
            <Radio
              disabled={checkEnvAccessDisabled(env.environmentName)}
              key={`env-${env.environmentID}`}
              value={env.environmentID}
              label={<Environment {...env} selected={env.environmentID === selectedEnvID} />}
              className={classes.envCard}
              style={
                env.environmentID === selectedEnvID
                  ? { backgroundColor: '#ECF3FF', borderColor: '#0768FD' }
                  : checkEnvAccessDisabled(env.environmentName)
                  ? { opacity: '0.6' }
                  : {}
              }
            />
          ))}
          {environments?.length === 0 && <span style={{ height: '10rem' }}></span>}
        </Grid>
      </RadioGroup>

      <Typography className={classes.sectionTitle}>Choose a Scheduler Type</Typography>
      <RadioGroup
        aria-label="label"
        name="label"
        value={selectedSchedulerType}
        onChange={(e) => setSelectedSchedulerType(e.target.value)}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: schedulerTypes.length == 2 ? '62.5%' : ''
          }}>
          {schedulerTypes.map((scheduler) => (
            <Radio
              key={`scheduler-${scheduler.schedulerType}`}
              value={scheduler.schedulerType}
              label={
                <Scheduler
                  {...scheduler}
                  selected={scheduler.schedulerType === selectedSchedulerType}
                />
              }
              disabled={!scheduler.isEnable}
              className={classes.envCard}
              style={
                scheduler.schedulerType === selectedSchedulerType
                  ? {
                      backgroundColor: '#ECF3FF',
                      borderColor: '#0768FD',
                      width: schedulerTypes.length == 2 ? '40%' : ''
                    }
                  : !scheduler.isEnable
                  ? { opacity: '0.6', width: schedulerTypes.length == 2 ? '40%' : '' }
                  : { width: schedulerTypes.length == 2 ? '40%' : '' }
              }
            />
          ))}
          {schedulerTypes?.length === 0 && <span style={{ height: '10rem' }}></span>}
        </Grid>
      </RadioGroup>

      <Typography className={classes.sectionTitle} style={{ marginBottom: '16px' }}>
        Comments/Notes (Optional)
      </Typography>

      <TextField
        id="comments"
        label=""
        placeholder="Add comments here..."
        name="comments"
        data-testid="comments-input"
        multiline
        sizeAdjustable
        value={comments}
        inputProps={{ className: classes.commentTextBox }}
        onChange={(e) => setComments(e.target.value)}
        fullWidth></TextField>

      <Box container display={'flex'} justifyContent={'space-between'} mt={'1rem'}>
        <Button
          variant="text"
          size="small"
          onClick={handleCancel}
          data-testid="cancel"
          style={{ marginRight: '1rem' }}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={handleSaveAndNext}
          data-testid="save_progress"
          disabled={nextDisabled}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default SelectEnvironment;
