import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import Button from 'apollo-react/components/Button';
import ArrowUp from 'apollo-react/icons/ArrowUp';
import ArrowDown from 'apollo-react/icons/ArrowDown';

import { makeStyles } from '@mui/styles';

const useStyles = (overflowedViewPort) =>
  makeStyles({
    root: {
      zIndex: '4001 !important'
    },
    menuPaper: {
      marginTop: overflowedViewPort ? '0' : '48px'
    }
  });

export default function DropMenu(props) {
  const { menuClasses, popoverClasses, label, menuItems = [], item, ...restProps } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [overflowedViewPort, setViewPortOverflowed] = React.useState(0);
  const menuButton = React.useRef();
  const classes = useStyles(overflowedViewPort)();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    //calculating the offset of menu from viewport
    setViewPortOverflowed(
      window.innerHeight <= //windows inner height
        menuButton.current.getBoundingClientRect().bottom + 16 + 40 * menuItems.length // menu button location in viewport + menu padding + sum of height for number of element in menu
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        ref={menuButton}
        data-testid="fade-button"
        id="fade-button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...restProps}>
        {label || 'Custom Menu'}
        {open ? (
          <ArrowUp
            style={{
              fontSize: '1rem',
              padding:
                props.size === 'small' ? '0.2rem 0rem 0.2rem 0.2rem' : '0.2rem 0rem 0.2rem 0.4rem',
              marginRight: props.size === 'small' ? '-0.2rem' : '-0.4rem'
            }}
          />
        ) : (
          <ArrowDown
            style={{
              fontSize: '1rem',
              padding:
                props.size === 'small' ? '0.2rem 0rem 0.2rem 0.2rem' : '0.2rem 0rem 0.2rem 0.4rem',
              marginRight: props.size === 'small' ? '-0.2rem' : '-0.4rem'
            }}
          />
        )}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        className={menuClasses?.paper || classes.menuPaper}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PopoverClasses={popoverClasses || { root: classes.root }}
        TransitionComponent={Fade}>
        {menuItems.map(({ text, onClick, ...rest }, index) => (
          <MenuItem
            key={`${text}-${index}`}
            onClick={() => {
              handleClose();
              onClick && onClick(item);
            }}
            {...rest}>
            {text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
