/*eslint-disable */
import React, { useEffect, useState } from 'react';

import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Grid from 'apollo-react/components/Grid';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Panel from 'apollo-react/components/Panel';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import PublishMappingSpecSidebar from 'Components/PublishMappingSpecSidebar';
import MappingSpecDetails from 'Pages/ProductDesigner/Components/PublishMappingSpec/MappingSpecDetails';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import { useLocation } from 'react-router-dom';
import { BannerExample } from 'Components/Banner/Banner';
import { setStepperStatus } from 'Redux/Slice/PublishMappingSpecSlice';

const useStyles = (displaySidebar) =>
  makeStyles({
    publishSidebar: {
      backgroundColor: '#fff',
      borderRight: '1px solid #ccc',
      marginTop: '60px'
    },
    publishBody: {
      padding: '0rem 2rem'
    },
    main: {
      display: 'flex',
      height: 'calc(100vh - 113px)',
      overflow: 'auto',
      '&> div:nth-child(1)': {
        position: 'sticky',
        top: '0px',
        minHeight: 'calc(100vh - 113px)',
        height: 'fit-content',
        zIndex: 1
      },
      '&> div:nth-child(2)': {
        width: `calc(100% - ${displaySidebar ? '354px' : '26px'}) !important`,
        backgroundColor: '#f5f6fa'
      }
    },
    hidden: {
      visibility: 'hidden'
    },
    breadCrumbs: {
      backgroundColor: 'transparent'
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '113px'
    },
    panelRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: `calc(100vh - 113px)`
    }
  });

export const PublishMappingSpecLayout = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
   const { stepperStatus } = useSelector((state) => state.PublishMappingSpecData);

  const [displaySidebar, toggleSidebar] = useState(true);
  const [displayMappingSpecOverlay, toggleMappingSpecOverlay] = useState(false);
  const [activeStep, setActiveStep] = useState();

  const classes = useStyles(displaySidebar)();

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title:
            location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
              ? 'Manage Data Pipelines'
              : 'Data Products',
          path:
            location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
              ? '/manage-data-pipeline'
              : '/product-designer'
        },
        {
          title: 'Publish'
        }
      ])
    );
  }, [activeStep]);

  const handleStepperClick = (step) => {
    if (stepperStatus.isCurrentPageEdit) {
      dispatch(setStepperStatus({ ...stepperStatus, newStep: step }));
    } else {
      setActiveStep(step);
    }
  };

  return (
    <Grid className={classes.root}>
      <Header />
      {protocol !== undefined && <ProjectStudyHeader />}
      <BannerExample />
      <Grid item xs={12} className={classes.main}>
        <Panel
          open={displaySidebar}
          onClose={() => {
            toggleSidebar(false);
          }}
          onOpen={() => {
            toggleSidebar(true);
          }}
          width={350}
          key={displaySidebar ? 'open' : 'close'}>
          <Grid
            item
            xs={12}
            className={classes.publishSidebar + displaySidebar ? '' : `${classes.hidden}`}>
            <PublishMappingSpecSidebar
              onShowMoreInfo={() => toggleMappingSpecOverlay(true)}
              activeStep={activeStep}
              onStepperClick={handleStepperClick}
            />
          </Grid>
        </Panel>
        <Panel hideButton className={classes.panelRight}>
          <Grid
            item
            className={displaySidebar ? `${classes.compactPreview}` : `${classes.expandedPreview}`}
            style={{ display: 'flex', flexDirection: 'column' }}>
            {activeStep !== 5 && (
              <Breadcrumb style={{ backgroundColor: 'transparent', padding: '1rem 2rem' }} />
            )}
            <Grid className={classes.publishBody}>
              {React.cloneElement(props.children, {
                activeStep,
                setActiveStep,
                toggleSidebar
              })}
            </Grid>
          </Grid>
          <Footer />
        </Panel>
      </Grid>
      {displayMappingSpecOverlay ? (
        <MappingSpecDetails
          displayMappingSpecOverlay={displayMappingSpecOverlay}
          toggleMappingSpecOverlay={toggleMappingSpecOverlay}
        />
      ) : null}
    </Grid>
  );
};

export default PublishMappingSpecLayout;
