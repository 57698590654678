import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DataExportCard from './components/DataExportCard';
import EnvironmentCard from './components/EnvironmentCard';
import MappingSpecCard from './components/MappingSpecCard';
import SourceConnectionCard from './components/SourceConnCard';
import TrialNameCard from './components/TrialNameCard';
import { handleSelectedSources } from './utilities';
import { Notification } from 'Components/Common/Notification';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  notification: {
    marginRight: '1rem',
    '&>.wrapper': {
      display: 'flex',
      flexDirection: 'row',
      '&>.icon': {
        display: 'flex',
        flexDirection: 'row'
      },

      '&>.icon:before': {
        margin: '4px !important'
      }
    }
  }
});

const ReviewAndPublish = (props) => {
  const classes = useStyles();
  const {
    selectSourceDetails,
    dataExportDetails,
    mappingSpecDetails,
    selectedEnvironment,
    selectedScehduler,
    selectedSourceData
  } = useSelector((state) => state.PublishMappingSpecData);
  const [sources, setSources] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const { activeStep, setActiveStep } = props;

  const handleDataExport = () => {
    const data = dataExportDetails
      ?.filter((item) => item.isActive === true && item.selected)
      ?.map((el) => {
        return {
          destination: el.destination,
          location: el.location,
          userName: el.userName
        };
      });
    setDataExport(data);
  };

  useEffect(() => {
    setSources(handleSelectedSources(selectSourceDetails));
    handleDataExport();
  }, []);

  return (
    <React.Fragment>
      <Typography
        variant="title1"
        gutterBottom
        style={{
          marginTop: '1rem',
          fontSize: '20px',
          color: '#000000',
          lineHeight: '48px',
          letterSpacing: 0,
          fontFamily: 'Proxima Nova'
        }}>
        Review And Publish
      </Typography>
      <MappingSpecCard mappingSpecDetails={mappingSpecDetails} />
      <EnvironmentCard
        selectedEnvironment={selectedEnvironment}
        selectedScehduler={selectedScehduler}
        handleClick={() => setActiveStep(0)}
      />
      <SourceConnectionCard sources={sources} handleClick={() => setActiveStep(1)} />
      <TrialNameCard trialName={selectedSourceData} handleClick={() => setActiveStep(2)} />
      <DataExportCard dataExport={dataExport} handleClick={() => setActiveStep(4)} />
      <Notification
        className={classes.notification}
        header={''}
        message={
          <>
            <span style={{ fontWeight: '600' }}>Note: </span>
            Once the mapping specification has been published successfully, a new data pipeline will
            be initialized. You can monitor its status and performance via the dashboard or within
            the manage data pipelines page.
          </>
        }
      />
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          padding: 0,
          justifyContent: 'space-between',
          marginTop: '4%',
          marginBottom: '3rem'
        }}>
        <Button
          variant="text"
          size="small"
          style={{ padding: 0 }}
          onClick={() => setActiveStep(activeStep - 1)}>
          Previous
        </Button>
        <Button variant="primary" size="small" onClick={() => setActiveStep(activeStep + 1)}>
          Publish Mapping Spec
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default ReviewAndPublish;
