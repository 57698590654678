import ERROR from './messages';
import store from 'Redux/store';

export const getBracketsCordinates = (s) => {
  let i = 0;
  let arr = [];
  let result = {};
  let rootBrackets = [];

  while (i < s.length) {
    let popOrPush = 0;
    if (s[i] === '{' || s[i] === '(' || s[i] === '[') {
      arr.push({ index: i, char: s[i] });
      popOrPush = 1;
    } else if (s[i] === '}' && arr[arr.length - 1] && arr[arr.length - 1].char === '{') {
      arr.pop();
      popOrPush = 2;
    } else if (s[i] === ')' && arr[arr.length - 1] && arr[arr.length - 1].char === '(') {
      arr.pop();
      popOrPush = 2;
    } else if (s[i] === ']' && arr[arr.length - 1] && arr[arr.length - 1].char === '[') {
      arr.pop();
      popOrPush = 2;
    } else if (s[i] === '"' && arr.length > 0 && arr[arr.length - 1].char === '"') {
      arr.pop();
      popOrPush = 2;
    } else if (
      s[i] === '"' &&
      ((arr.length > 0 && arr[arr.length - 1].char !== '"') || arr.length === 0)
    ) {
      arr.push({ index: i, char: '"' });
      popOrPush = 1;
    }
    if (popOrPush === 1 && arr.length === 1) {
      rootBrackets.push({ start: i, char: s[i] });
    } else if (popOrPush === 2 && arr.length === 0) {
      rootBrackets[rootBrackets.length - 1].end = i;
    }

    i++;
  }

  if (arr.length === 0) {
    result.isValid = true;
    result.message = ERROR.BLANK;
    result.details = rootBrackets;
  } else {
    result.isValid = false;
    result.message = ERROR.MISSING_BRACKETS + arr[arr.length - 1].char;
    result.details = arr[arr.length - 1];
    result.cordinates = {
      start: arr[arr.length - 1].index,
      end: arr[arr.length - 1].index
    };
  }

  return result;
};
function getArgsBetweenMin(str, index, cordinates = []) {
  const decodeMinBracketsCordinates = cordinates.find((a) => a.start === index + 3);
  let argsBetweenMin = str.substring(
    decodeMinBracketsCordinates.start + 1,
    decodeMinBracketsCordinates.end
  );
  return argsBetweenMin;
}

function getArgsBetweenMax(str, index, cordinates = []) {
  const decodeMaxBracketsCordinates = cordinates.find((a) => a.start === index + 3);
  let argsBetweenMax = str.substring(
    decodeMaxBracketsCordinates.start + 1,
    decodeMaxBracketsCordinates.end
  );
  return argsBetweenMax;
}

function getFormAndItem(str, index, defaultForm, cordinates = [], decodeMethod) {
  const decodeBracketsCordinates = cordinates.find((a) => a.start === index + decodeMethod.length);
  const argsBetweenDecode = str.substring(
    decodeBracketsCordinates.start + 1,
    decodeBracketsCordinates.end
  );
  let lastIndexOfCloseBracket = str.lastIndexOf(']', index);
  let lastIndexOfOpenBracket = str.lastIndexOf('[', lastIndexOfCloseBracket);
  const item = str.substring(lastIndexOfOpenBracket + 1, lastIndexOfCloseBracket);

  let form = defaultForm;
  if (lastIndexOfOpenBracket - 2 > 0 && str.charAt(lastIndexOfOpenBracket - 2) === ']') {
    lastIndexOfCloseBracket = lastIndexOfOpenBracket - 2;
    lastIndexOfOpenBracket = str.lastIndexOf('[', lastIndexOfCloseBracket);
    form = str.substring(lastIndexOfOpenBracket + 1, lastIndexOfCloseBracket);
  }

  return {
    item: item.toLowerCase(),
    form: form.toLowerCase(),
    argsBetweenDecode: argsBetweenDecode.toLowerCase()
  };
}

function validateGenericAndFormBasedDecode(form, item, argsBetweenDecode) {
  const state = store.getState();
  const result = {
    isValid: true,
    message: ''
  }
  const { formItemCodelistMap = {}, sourceCodelistMap = {} } = state.ReferenceData;
  if (item && form) {
    const key = `[${item.toLowerCase()}].[${form.toLowerCase()}]`;
    if (formItemCodelistMap[key] && !Object.keys(sourceCodelistMap).length) {
      if (argsBetweenDecode && argsBetweenDecode.trim()) {
        result.isValid = false;
        result.message = ERROR.ARGUMENT_NOT_ALLOWED;
      }
    } else if (formItemCodelistMap[key] && Object.keys(sourceCodelistMap).length > 0) {
      return result;
    } else {
      if (!argsBetweenDecode && !argsBetweenDecode.trim()) {
        result.isValid = false;
        result.message = ERROR.INVALID_DECODE_ARG;
      }
    }
  }
  return result;
}

let typesOfDecode = ['decode', 'decodesrc', 'decodemodifiedtext'];

const getIndexAndMethodFromExpression = (str) => {
  let splitExpression = str.split('.');
  let decodeMethod = splitExpression[splitExpression.length - 1].split('(')[0].toLowerCase();
  let index = typesOfDecode.includes(decodeMethod) ? str.toLowerCase().indexOf(decodeMethod + '(') : -1;
  return {decodeMethod, index }
};

export function validateDecodeMethodMDS(str, defaultForm, mapping, itemName) {
  const state = store.getState();
  const { sourceCodelistMap = {} } = state.ReferenceData;
  let decodeObj = {}
  let {decodeMethod, index} = getIndexAndMethodFromExpression(str);
  while (index !== -1) {
    const cordinates = getBracketsCordinates(str);
    const { item, form, argsBetweenDecode } = getFormAndItem(
      str,
      index,
      defaultForm,
      cordinates.details,
      decodeMethod
    );
    let noCodeListData = {
      isValid: false,
      message: ERROR.DECODE_CODE_LIST,
    };
    let formItemNotFound = {
      isValid: false,
      message: ERROR.FROM_ITEM_NOT_FOUND,
    };
    let validCodelist = {
      isValid: true
    }
    const result = validateGenericAndFormBasedDecode(item, form, argsBetweenDecode);
    if (!Object.keys(mapping).length && !Object.keys(sourceCodelistMap).length) {
      decodeObj[item] = noCodeListData;
    }
    else if (!argsBetweenDecode.trim().length && !(`${form}#-#${item}` in mapping)) {
      decodeObj[item] = {
        isValid: false,
        message: ERROR.DECODE_CODE_LIST,
      }
    }
    else if (result && result.isValid) {
      if ((!argsBetweenDecode.trim().length && !Object.keys(mapping).length)) {
        decodeObj[item] = noCodeListData;
      } else if (Object.keys(mapping).length) {
        if (!argsBetweenDecode.trim()) {
          const key = `${form}#-#${item}`;
          if (!(key in mapping)) {
            decodeObj[item] = formItemNotFound;
          } else {
            decodeObj[item] = validCodelist;
          }
        }
        else if (!sourceCodelistMap[argsBetweenDecode]) {
          decodeObj[item] = {
            isValid: false,
            message: `${argsBetweenDecode} ${ERROR.INVALID_SOURCE_CODE_LIST}`,
            cordinates: {
              start: index - 1,
              end: str.toLowerCase().indexOf(argsBetweenDecode + ')', index) + argsBetweenDecode.length + 1
            },
            item: item,
            form: form
          };
        }
        else {
          decodeObj[item] = {
            isValid: true
          }
        }
      }
    } else {
      const errorObj = { ...noCodeListData, message: result.message };
      decodeObj[item] = errorObj;
    }
    str = str.substring(index);
    index = (str).toLowerCase().indexOf(decodeMethod + '(', index + 1);
  }
  if (index === -1 && Object.keys(decodeObj).length) {
    return decodeObj
  } else {
    decodeObj[itemName.toLowerCase()] = { isValid: true };
    return decodeObj
  }
}

export function validateDecodeMethod(str, defaultForm, mapping) {
  const state = store.getState();
  const { sourceCodelistMap = {} } = state.ReferenceData;
  let {decodeMethod, index} = getIndexAndMethodFromExpression(str);
  const cordinates = getBracketsCordinates(str);
  while (index !== -1) {
    const { item, form, argsBetweenDecode } = getFormAndItem(
      str,
      index,
      defaultForm,
      cordinates.details, decodeMethod);
    const result = validateGenericAndFormBasedDecode(item, form, argsBetweenDecode);
    if (!Object.keys(mapping).length && !Object.keys(sourceCodelistMap).length) {
      return {
        isValid: false,
        message: ERROR.DECODE_CODE_LIST,
      };
    }
    else if (!argsBetweenDecode.trim().length && !(`${form}#-#${item}` in mapping)) {
      return {
        isValid: false,
        message: ERROR.DECODE_CODE_LIST,
      }
    }
    if (result && !result.isValid) {
      return result;
    }
    if ((!argsBetweenDecode.trim().length && !Object.keys(mapping).length)) {
      return {
        isValid: false,
        message: ERROR.DECODE_CODE_LIST,
        cordinates: {
          start: index - 1,
          end:
            str.toLowerCase().indexOf(argsBetweenDecode + ')', index + 1) + argsBetweenDecode.length + 1
        },
        item: item,
        form: form
      }
    }
    if (!argsBetweenDecode.trim()) {
      const key = `${form}#-#${item}`;
      if (!(key in mapping)) {
        return {
          isValid: false,
          message: ERROR.FROM_ITEM_NOT_FOUND,
          cordinates: {
            start: index - 1,
            end:
              str.toLowerCase().indexOf(argsBetweenDecode + ')', index + 1) + argsBetweenDecode.length + 1
          },
          item: item,
          form: form
        }
      } else {
        index = str.toLowerCase().indexOf(decodeMethod + '(', index + 1);
        continue;
      }
    }
    if (!sourceCodelistMap[argsBetweenDecode]) {
      return {
        isValid: false,
        message: `${argsBetweenDecode} ${ERROR.INVALID_SOURCE_CODE_LIST}`,
        cordinates: {
          start: index - 1,
          end: str.toLowerCase().indexOf(argsBetweenDecode + ')', index) + argsBetweenDecode.length + 1
        },
        item: item,
        form: form
      };
    }
    index = str.toLowerCase().indexOf(decodeMethod + '(', index + 1);
  }
  return { isValid: true };
}

export function validateMinMethod(str, serviceResult) {
  let minIndex = str.toLowerCase().indexOf('min(');

  while (minIndex !== -1) {
    const cordinates = getBracketsCordinates(str);
    let argsBetweenMin = getArgsBetweenMin(str, minIndex, cordinates.details)
    if (!argsBetweenMin.trim()) {
      minIndex = str.toLowerCase().indexOf('min(', minIndex + 1);
      continue;
    }
    if (argsBetweenMin) {
      let result = serviceResult.isValidVisitName(argsBetweenMin);
      if (!result) {
        return {
          isValid: false,
          message: ERROR.SOURCE_VISIT_NAME,
          cordinates: {
            start: minIndex - 1,
            end: str.indexOf(argsBetweenMin + ')', minIndex + 1) + argsBetweenMin.length + 1
          }
        };
      }
    }
    else {
      return {
        isValid: false,
        message: ERROR.INVALID_ARGTYPE,
        cordinates: {
          start: minIndex - 1,
          end: str.indexOf(argsBetweenMin + ')', minIndex + 1) + argsBetweenMin.length + 1
        }
      };
    }
    minIndex = str.toLowerCase().indexOf('min(', minIndex + 1);
  }
  return { isValid: true };
}

export function validateMaxMethod(str, serviceResult) {
  let maxIndex = str.toLowerCase().indexOf('max(');

  while (maxIndex !== -1) {
    const cordinates = getBracketsCordinates(str);
    let argsBetweenMax = getArgsBetweenMax(str, maxIndex, cordinates.details)
    if (!argsBetweenMax.trim()) {
      maxIndex = str.toLowerCase().indexOf('max(', maxIndex + 1);
      continue;
    }
    if (argsBetweenMax) {
      let result = serviceResult.isValidVisitName(argsBetweenMax)
      if (!result) {
        return {
          isValid: false,
          message: ERROR.SOURCE_VISIT_NAME,
          cordinates: {
            start: maxIndex - 1,
            end:
              str.indexOf(argsBetweenMax + ')', maxIndex + 1) +
              argsBetweenMax.length +
              1
          }
        };
      }
    }
    else {
      return {
        isValid: false,
        message: ERROR.INVALID_ARGTYPE,
        cordinates: {
          start: maxIndex - 1,
          end: str.indexOf(argsBetweenMax + ')', maxIndex + 1) + argsBetweenMax.length + 1
        }
      };
    }
    maxIndex = str.toLowerCase().indexOf('max(', maxIndex + 1);
  }
  return { isValid: true };
}
