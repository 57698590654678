/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import { GetTrialArmsData } from 'Redux/Service/ReferenceDataCardsService';
import PlusIcon from 'apollo-react-icons/Plus';
import CustomModal from 'Components/Modal';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Trash from 'apollo-react-icons/Trash';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Switch from 'apollo-react/components/Switch';
import { jsonToExcel, uuidv4 } from 'Utils';
import Table from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveTrialArmsData } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { trialArmsValidator } from 'Validators/TrialArms.Validator';
import { getTypeBasedColumnProps } from '../CodeListUtils';
import { trialArmsColumnNames } from '../TrialArmsUtils';
import { TableNewRow, useNewRow } from './TableNewRow';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import useGetTitle from '../../../hooks/useGetTitle';
import Loader from 'Components/Loader/Loader';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Cookies from 'js-cookie';
const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const ActionCell = ({ row: { id, editMode, onDelete }, data }) => {
  let isDisabled = data.length < 2;
  return (
    editMode || (
      <Tooltip title={!isDisabled ? 'Delete' : 'Table cannot be empty'} disableFocusListener>
        <Button
          size="small"
          onClick={!isDisabled ? () => onDelete(id) : null}
          style={isDisabled ? { cursor: 'not-allowed' } : {}}>
          <Trash
            style={
              isDisabled
                ? {
                    color: 'grey'
                  }
                : {
                    color: 'black'
                  }
            }
          />
        </Button>
      </Tooltip>
    )
  );
};

const TrialArmsPreview = () => {
  const { cardTitle } = useGetTitle('trial-arms');
  const params = useParams();
  const navigate = useNavigate();
  const [trialArmsData, setTrialArmsData] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [trialArmsValidationResult, setTrialArmsValidationResult] = useState({});
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [trialArmsErrorCount, setTrialArmsErrorCount] = useState({});
  const [trialArmsErrorOnRecords, setTrialArmsErrorOnRecords] = useState({});
  const mappingId = params.id;
  const [errorFlags, setErrorFlags] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [errorFlag, handleErrorData] = useReducer((state, e) => {
    //eslint-disable-line
    return e.target.checked;
  }, false);
  const [newRow, setNewRow, editNewRow] = useNewRow({});
  const [isAdding, setIsAdding] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const editMode = editedRows.length > 0;
  const classes = useStyles();
  const TRIAL_ARM_VALIDATION_RESULT = 'trial-arm-validationResult';

  //For fetching data and validation
  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingId
      };
      //direct uploaded file
      if (getReferenceData('trial-arm') && getReferenceData('trial-arm').length > 0) {
        let newData = getReferenceData('trial-arm');
        setTrialArmsData(newData);
        let validationResult = getReferenceData(TRIAL_ARM_VALIDATION_RESULT);
        setTrialArmsValidationResult(validationResult);
        setIsLoading(false);
        setIsPreviewDataUploaded(true);
        setEnableSave(true);
      }
      //file from API
      else {
        const trialArmsGetAPIData = await dispatch(GetTrialArmsData(payload)).then(unwrapResult);
        if (trialArmsGetAPIData && trialArmsGetAPIData.data && trialArmsGetAPIData.data.success) {
          if (trialArmsGetAPIData.data.trailArms && trialArmsGetAPIData.data.trailArms.length > 0) {
            const newData = trialArmsGetAPIData.data.trailArms.map((item) => {
              let trialArmsItem = {};
              for (let apiLabel of Object.values(trialArmsColumnNames)) {
                trialArmsItem[apiLabel] = item[apiLabel];
              }
              trialArmsItem.id = uuidv4();
              return trialArmsItem;
            });
            setTrialArmsData(newData);
            setIsLoading(false);
            const validationResult = trialArmsValidator(newData);
            const errorCountFlag = validationResult.errorCount;
            errorCountFlag > 0 ? setErrorFlags(true) : setErrorFlags(false);
            setTrialArmsValidationResult(validationResult);
            setIsPreviewDataUploaded(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    })();
  }, []);

  //useEffect for updating errors
  useEffect(() => {
    if (trialArmsValidationResult && Object.keys(trialArmsValidationResult).length > 0) {
      setTrialArmsErrorOnRecords(trialArmsValidationResult.errorOnRecords);
      setTrialArmsErrorCount(trialArmsValidationResult.errorCount);
    }
  }, [trialArmsValidationResult]);

  const getSavePayload = (data, auditType) => {
    let trialArmsApiColumns = Object.values(trialArmsColumnNames);
    const trailArms = data.map((currentRowItem) => {
      return {
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: auditType,
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        mappingTrialArmId: 'TA',
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingId,
        domainNm: '',
        ...trialArmsApiColumns.reduce((acc, item) => {
          acc[item] = currentRowItem[item];
          return acc;
        }, {})
      };
    });
    const payload = {
      trailArms
    };
    return payload;
  };

  const onSave = async () => {
    let newErrorResult = trialArmsValidator(editMode ? editedRows : trialArmsData);
    setOpenConfirmModal(false);
    setTrialArmsValidationResult(newErrorResult);
    if (newErrorResult.errorCount > 0) {
      dispatch(showBanner({ variant: 'error', message: 'Data still has some error' }));
      return;
    }
    let payload;
    if (editMode) {
      setTrialArmsData(editedRows);
      payload = getSavePayload(editedRows, 'UPDATE');
      setEditedRows([]);
    }
    //  else if (isAdding) {
    //   const trialArmsDataNew = [{ ...newRow, id: uuidv4() }, ...trialArmsData];
    //   setTrialArmsData(trialArmsDataNew);
    //   payload = getSavePayload(trialArmsDataNew, 'INSERT');
    // }
    else {
      payload = getSavePayload(trialArmsData, 'INSERT');
    }

    const saveResponse = await dispatch(SaveTrialArmsData(payload));
    if (
      saveResponse &&
      saveResponse.payload &&
      saveResponse.payload.data &&
      saveResponse.payload.data.success
    ) {
      setIsPreviewDataUploaded(false);
      setReferenceData('trial-arm', []);
      setReferenceData(TRIAL_ARM_VALIDATION_RESULT, {});
      dispatch(showBanner({ variant: 'success', message: saveResponse.payload.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: 'something went wrong' }));
    }
    setEditedRows([]);
    setEnableSave(false);
  };

  const getconfirmationBeforeSave = () => {
    setConfirmAlert({
      enabled: true,
      title: 'Do you want to Save the Data?',
      message:
        'Please check the information before submitting as the edits/deletes cannot be retrieved once the data is saved',
      variant: 'warning',
      onConfirm: () => {
        setConfirmAlert({
          enabled: false
        });
        onSave();
      },
      onCancel: () => {
        setOpenConfirmModal(false);
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  useEffect(() => {
    if (openConfirmModal) {
      getconfirmationBeforeSave();
    }
  }, [openConfirmModal]);

  const CustomButtonHeader = (props) => {
    const {
      onEditAll,
      toggleFilters,
      trialArmsData,
      handleStepValue,
      handleShowPreview,
      setIsPreview,
      trialArmsErrorCount,
      errorFlag,
      handleErrorData,
      setIsAdding,
      isAdding,
      setConfirmAlert,
      isPreviewDataUploaded,
      editMode,
      onCancel
    } = props;

    const onHandleDownload = () => {
      let newFileArr = [];
      trialArmsData.map((obj) => {
        delete obj['id'];
        let upperObj = _.transform(obj, function (result, val, key) {
          result[trialArmsColumnNames[key]] = val;
        });
        newFileArr.push(upperObj);
      });
      jsonToExcel(newFileArr, 'TrialArms.xlsx');
    };

    const returnToReferenceData = () => {
      if (isPreviewDataUploaded) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Uploaded data would not saved',
          variant: 'warning',
          onConfirm: () => {
            setReferenceData('trial-arm', []);
            setReferenceData(TRIAL_ARM_VALIDATION_RESULT, {});
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);
            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else if (trialArmsErrorCount) {
        setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to return to reference data?',
          message: 'Added or Edited data would not be saved',
          variant: 'warning',
          onConfirm: () => {
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);
            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else {
        navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
        setIsPreview(false);
      }
    };

    return (
      <>
        <div style={{ width: '100%' }}>
          <div>
            <Button
              icon={<ChevronLeft />}
              size="small"
              onClick={returnToReferenceData}
              style={{
                marginRight: 10,
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Return to reference data upload
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 600,
                      fontSize: '20px',
                      float: 'left',
                      marginRight: '20px'
                    }}>
                    {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
                  </Typography>
                  {trialArmsErrorCount > 0 && (
                    <div
                      style={{
                        backgroundColor: '#E20000',
                        float: 'left',
                        width: '83px',
                        paddingBottom: '2px',
                        paddingLeft: '2px',
                        border: '1px solid white',
                        borderRadius: '4px'
                      }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                        <span
                          style={{
                            color: 'white'
                          }}>
                          <StatusExclamation
                            style={{
                              color: 'white',
                              float: 'left',
                              height: '16px',
                              marginTop: '2px'
                            }}
                          />
                          {`${trialArmsErrorCount} errors`}
                        </span>
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={9} style={{ marginRight: '4px' }}>
                    {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                  </Grid>

                  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="secondary" size="small" onClick={toggleFilters}>
                      <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} />{' '}
                      Filter
                    </Button>
                    <Button size="small" onClick={onHandleDownload} style={{ color: 'black' }}>
                      <Download />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <hr style={{ margin: '22px 0px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
                variant="subtitle"
                gutterBottom>
                {errorFlag ? 'Show only rows with errors' : 'Show All'}
              </Typography>
              <Switch size={'small'} checked={errorFlag} onChange={(e) => handleErrorData(e)} />
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={isAdding || editMode}
                size="small"
                variant="secondary"
                icon={PlusIcon}
                onClick={() => setIsAdding((adding) => !adding)}
                style={{ marginRight: 8 }}>
                {'Add Row'}
              </Button>
              {!editMode && (
                <Button size="small" variant="primary" onClick={onEditAll}>
                  {'Edit all'}
                </Button>
              )}
              {editMode && (
                <Button size="small" onClick={onCancel}>
                  {'Cancel'}
                </Button>
              )}
              {(enableSave || editMode || isPreviewDataUploaded) && (
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  variant="primary"
                  onClick={() => setOpenConfirmModal(true)}>
                  {'Save'}
                </Button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  };

  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      return row.editMode ? (
        <TextField
          size="small"
          fullWidth
          value={row[key]}
          onChange={(e) => row.editRow(row.id, key, e.target.value)}
          {...fieldStyles}
          {...(trialArmsErrorOnRecords &&
          trialArmsErrorOnRecords[row.id] &&
          trialArmsErrorOnRecords[row.id][key]
            ? {
                helperText: trialArmsErrorOnRecords[row.id][key],
                error: true
              }
            : {})}
        />
      ) : trialArmsErrorOnRecords &&
        trialArmsErrorOnRecords[row.id] &&
        trialArmsErrorOnRecords[row.id][key] ? (
        <Tooltip title={trialArmsErrorOnRecords[row.id][key]}>
          <Typography
            variant="title2"
            style={{
              fontSize: '14px',
              backgroundColor: '#f9cccc',
              color: '#595959',
              padding: '15px 11px'
            }}>
            {typeof row[key] === 'undefined' ||
            (typeof row[key] === 'string' && row[key].trim() === '')
              ? 'No Data'
              : row[key]}
          </Typography>
        </Tooltip>
      ) : (
        row[key]
      );
    },
    [trialArmsErrorOnRecords, trialArmsData]
  );

  const columns = useMemo(() => {
    let thisColumns = [];
    for (let accessor of Object.values(trialArmsColumnNames)) {
      const column_props = trialArmsColumnNames[accessor + '_props'];
      thisColumns.push({
        header: trialArmsColumnNames[accessor],
        accessor,
        customCell: EditableCell,
        fixedWidth: false,
        ...getTypeBasedColumnProps({
          type: column_props.type,
          accessor,
          TextFieldFilter,
          IntegerFilter
        })
      });
    }
    thisColumns.push({
      accessor: 'action',
      customCell: function ActionCellWithData(props) {
        return <ActionCell {...props} data={trialArmsData} />;
      },
      align: 'right'
    });
    return thisColumns;
  }, [EditableCell]);

  const onEditAll = () => {
    setEditedRows(trialArmsData);
  };

  const onCancel = () => {
    setEditedRows([]);
  };

  const onDelete = (id) => {
    let updatedRows = trialArmsData.filter((row) => row.id !== id);
    const validationResult = trialArmsValidator(updatedRows);
    setTrialArmsValidationResult(validationResult);
    setTrialArmsData(updatedRows);
    setEnableSave(true);
  };

  const editRow = (id, key, value) => {
    setEditedRows((trialArmsData) =>
      trialArmsData.map((row) => (row.id === id ? { ...row, [key]: value } : row))
    );
  };

  const onAddNewRowSave = () => {
    const trialArmsApiColumns = Object.values(trialArmsColumnNames);
    const copyTrialArmsData = [...trialArmsData];
    if (newRow && Object.keys(newRow).length > 0) {
      const obj = {
        id: uuidv4(),
        ...trialArmsApiColumns.reduce((acc, columnName) => {
          acc[columnName] = newRow[columnName];
          return acc;
        }, {})
      };
      copyTrialArmsData.unshift(obj);
      const validationResult = trialArmsValidator(copyTrialArmsData);
      setTrialArmsValidationResult(validationResult);
      setTrialArmsData(copyTrialArmsData);
      if (validationResult?.errorCount === 0) {
        setOpenConfirmModal(true);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={(editMode && (errorFlag || !errorFlag) ? editedRows : trialArmsData)
                .filter((row) => {
                  if (errorFlag) {
                    if (typeof trialArmsErrorOnRecords[row.id] === 'undefined') {
                      return false;
                    }
                    return true;
                  }
                  return true;
                })
                .map((row) => ({
                  ...row,
                  onDelete,
                  editRow,
                  editMode,
                  key: row.id
                }))}
              initialSortedColumn="arm"
              initialSortOrder="asc"
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                //   labelDisplayedRows: ({ from, to, count }) =>
                //     `${count === 1 ? 'Employee' : 'Employees'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={CustomButtonHeader}
              headerProps={{
                onEditAll,
                onSave,
                onCancel,
                editMode,
                trialArmsData,
                // handleStepValue,
                // handleShowPreview,
                setIsPreview,
                trialArmsErrorCount,
                trialArmsErrorOnRecords,
                errorFlag,
                handleErrorData,
                setIsAdding,
                isAdding,
                setConfirmAlert,
                isPreviewDataUploaded,
                getconfirmationBeforeSave
              }}
              CustomSubHeader={() => (
                <TableNewRow
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  onSave={onAddNewRowSave}
                  newRow={newRow}
                  editNewRow={editNewRow}
                  setNewRow={setNewRow}
                  columns={columns}
                />
              )}
            />
          </Card>
          <CustomModal
            display={confirmAlert.enabled}
            title={confirmAlert.title}
            message={confirmAlert.message}
            body={confirmAlert.body}
            variant={confirmAlert.variant}
            buttonPrimaryLabel={'Ok'}
            handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
            buttonSecondardyLabel={'Cancel'}
            handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
          />
        </>
      )}
    </>
  );
};

export default TrialArmsPreview;
