/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

// import RulesJson from './../../MockApis/rule.json'

export const LoginFormSlice = createSlice({
  name: 'LoginForm',
  initialState: {
    userLoginID: '',
    disableComponentsBasedOnRole : [],
    userRoleType : '',
    cardName : ''
  },
  reducers: {
    loginFormData(state, { payload }) {
      state.userLoginID = payload;
    },
    setComponentsDisable(state, { payload }) {
      state.disableComponentsBasedOnRole = payload;
    },
    setUserType(state, { payload }) {
      state.userRoleType = payload;
    },
    passCardName(state, { payload }) {
      state.cardName = payload;
    },
  }
});

export const { loginFormData, setComponentsDisable, setUserType, cardName, passCardName } =
  LoginFormSlice.actions;
