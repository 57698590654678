import { useEffect, useState } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const useSelectedDatasourcesData = (payload) => {
  const url = `${API_URL}/api/RuleEditor/GetProductDataSourcesByMappingRuleID?MappingRuleVersionID=${payload}`;
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return { loading, data, error };
};

export default useSelectedDatasourcesData;
