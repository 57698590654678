import Box from '@mui/material/Box';
import Step from 'apollo-react/components/Step';
import StepLabel from 'apollo-react/components/StepLabel';
import Stepper from 'apollo-react/components/Stepper';
import DomainHeader from 'Pages/ProductDesigner/Components/Preview/Components/DomainHeader';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  setselectedDomainSourceData,
  setSelectedSubjects,
  setSubjectsData
} from 'Redux/Slice/DomainPeviewSlice';
import DomainData from './Components/DomainData';
import SubjectData from './Components/SubjectData';
import VisitData from './Components/VisitData';
import EditTrailName from './Components/EditTrailName';
import SelectSubjects from './Components/SelectSubjects';
import SourceData from './Components/SourceData';
import { setSelectedSourceData } from 'Redux/Slice/PublishMappingSpecSlice';

const DomainPreview = () => {
  const [activeStep, setActiveStep] = useState();
  const { id: mappingRuleVersionID } = useParams();
  const [domainCode, setDomainCode] = useState('');
  const [nodeId, setNodeId] = useState('');

  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const SUBJECT_SETTING_PREVIEW = 'subject-setting-preview';
  const VISIT_SETTING_PREVIEW = 'visit-setting-preview';
  const DOMAIN_PREVIEW = 'domain-preview';
  const SOURCE_DATA = 'Source Data';
  const SELECT_SUBJECT = 'Select Subjects';
  const MDS_DOMAIN_PREVIEW = 'mds-domain-preview';
  const DOMAIN_DATA = 'Domain Data';

  let steps;
  currentPath === SUBJECT_SETTING_PREVIEW
    ? (steps = [SOURCE_DATA, 'Trial Name', SELECT_SUBJECT, 'Subjects Data'])
    : currentPath === VISIT_SETTING_PREVIEW
    ? (steps = [SOURCE_DATA, 'Trial Name', SELECT_SUBJECT, 'Visit Data'])
    : (steps = [SOURCE_DATA, 'Select Trial Version', SELECT_SUBJECT, DOMAIN_DATA]);

  currentPath === MDS_DOMAIN_PREVIEW
    ? (steps = [SOURCE_DATA, DOMAIN_DATA])
    : (steps = [SOURCE_DATA, 'Select Trial Version', SELECT_SUBJECT, DOMAIN_DATA]);

  useEffect(() => {
    if (currentPath === SUBJECT_SETTING_PREVIEW) {
      setDomainCode('SUBJECT');
    } else if (currentPath === VISIT_SETTING_PREVIEW) {
      setDomainCode('SVM');
    } else if (currentPath === DOMAIN_PREVIEW || currentPath === MDS_DOMAIN_PREVIEW) {
      setDomainCode(location.state?.domainCode);
      setNodeId(location.state?.nodeId);
    }
  }, [currentPath]);

  useEffect(() => {
    dispatch(setselectedDomainSourceData({}));
    dispatch(setSubjectsData([]));
    dispatch(setSelectedSubjects([]));
    dispatch(setSelectedSourceData([]));
    setActiveStep(0);
  }, []);

  const handleNext = () => {
    if (activeStep === 0 || activeStep < 3) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const onStepClick = (index) => {
    if (activeStep > index) {
      setActiveStep(index);
    }
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={'column'}
        style={{ padding: '0rem 2rem', width: '100%', boxSizing: 'border-box' }}>
        <DomainHeader
          domainCode={domainCode}
          mappingRuleVersionID={mappingRuleVersionID}
          nodeId={nodeId}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ background: 'none', marginBottom: '2rem', width: '70%' }}>
            {steps.map((label, i) => (
              <Step key={label} style={{ cursor: 'pointer' }} onClick={() => onStepClick(i)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === 0 && (
          <SourceData
            handleNext={handleNext}
            type="Preview"
            mdsDomainActive={true}
            domainCode={domainCode}
          />
        )}
        {activeStep === 1 && currentPath === MDS_DOMAIN_PREVIEW ? (
          <DomainData
            handleBack={handleBack}
            domainCode={domainCode}
            nodeId={nodeId}
            mdsDomainActive={true}
          />
        ) : (
          activeStep === 1 && <EditTrailName handleNext={handleNext} handleBack={handleBack} />
        )}
        {activeStep === 2 && <SelectSubjects handleNext={handleNext} handleBack={handleBack} />}
        {activeStep === 3 && currentPath === SUBJECT_SETTING_PREVIEW ? (
          <SubjectData handleBack={handleBack} domainCode={domainCode} />
        ) : activeStep === 3 && currentPath === VISIT_SETTING_PREVIEW ? (
          <VisitData handleBack={handleBack} domainCode={domainCode} />
        ) : activeStep === 3 && currentPath === DOMAIN_PREVIEW ? (
          <DomainData handleBack={handleBack} domainCode={domainCode} nodeId={nodeId} />
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export default DomainPreview;
