/*eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Stepper from 'apollo-react/components/Stepper';
import { useParams, useNavigate } from 'react-router-dom';
import StepLabel from 'apollo-react/components/StepLabel';
import Step from 'apollo-react/components/Step';
import Button from 'apollo-react/components/Button';
import CustomModal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';
import Tooltip from 'apollo-react/components/Tooltip';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';
import { useLeafPathName } from 'Utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  modal: {
    width: 450
  }
});

export default function SelectSourcesSideBar1() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [cards, setCards] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const { workFlowModel, nextEnabled, warningModalConfig, errorDomains } = useSelector((state) => {
    return state.RuleEditorData;
  });
  const mappingRuleVersionId = params.id;
  const nextStepper = cards[activeStep + 1];
  const moduleName = useLeafPathName();

  useEffect(() => {
    if (workFlowModel && Object.keys(workFlowModel).length > 0) {
      const functionData = workFlowModel.functionData;

      if (functionData && functionData.length > 0) {
        const index = functionData.findIndex((data) => {
          return data?.moduleName === moduleName;
        });
        setCards(functionData);
        setActiveStep(index);
        if (!functionData[index]?.isRequired) {
          dispatch(setNextEnabled(true));
        }
      }
    }
  }, [workFlowModel, moduleName]);

  const nextDisabledMessage = useMemo(() => {
    if (cards?.[activeStep]?.moduleName === 'mds-domain-rules' && errorDomains?.length) {
      return `Codelist is not configured for ${errorDomains.toString()}`;
    } else if (cards?.[activeStep]?.moduleName === 'apply-ruleset' && errorDomains?.length) {
      return ` Please re-apply/remove the ruleset for the domain${
        errorDomains.length > 1 ? 's' : ''
      } : ${errorDomains.toString()}`;
    }
  }, [cards, nextEnabled, errorDomains]);

  const modalButtonProps = [
    {
      label: 'Cancel',
      onClick: () => {
        setOpenWarningModal(false);
      }
    },
    {
      label: `Continue to ${nextStepper?.displayName}`,
      onClick: () => {
        setOpenWarningModal(false);
        const nextElement = cards[activeStep + 1];
        setActiveStep(activeStep + 1);
        navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/${nextElement.moduleName}`);
        dispatch(setNextEnabled(false));
      }
    }
  ];

  const openModal = async () => {
    setConfirmAlert(true);
  };
  const handleReset = () => {
    setConfirmAlert(false);
    navigate('/product-designer');
  };

  const handleClose = () => {
    setConfirmAlert(false);
  };
  const handleBack = () => {
    const nextElement = cards[activeStep - 1];
    setActiveStep(activeStep - 1);
    navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/${nextElement.moduleName}`);
  };

  const handleNext = () => {
    if (
      ['apply-ruleset', 'data-sources', 'mastering-rules'].includes(
        cards?.[activeStep]?.moduleName
      ) &&
      warningModalConfig?.displayWarningModal
    ) {
      setOpenWarningModal(true);
    } else {
      const nextElement = cards[activeStep + 1];
      setActiveStep(activeStep + 1);
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/${nextElement.moduleName}`);

      dispatch(setNextEnabled(false));
    }
  };

  const onStepClick = (moduleName) => {
    const index = cards.findIndex((card) => {
      return card.moduleName === moduleName;
    });
    if (activeStep > index) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/${cards[index].moduleName}`);
      setActiveStep(index);
      dispatch(setNextEnabled(false));
    }
  };

  return (
    <>
      <Box>
        <Box pt={3} pb={3} px={3} style={{ borderBottom: '1px solid #e9e9e9' }}>
          <Typography variant="h3" data-testid="rule">
            Rule Editor
          </Typography>
        </Box>
        <Box style={{ padding: '30px 20px' }}>
          <div style={{ maxWidth: 400 }}>
            <Stepper box activeStep={activeStep ? activeStep : 0}>
              {cards.map((card) => (
                <Step
                  style={{
                    padding: '1rem',
                    cursor: activeStep ? activeStep : 0 >= card.order && 'pointer'
                  }}
                  key={card.order}
                  onClick={() => onStepClick(card.moduleName)}>
                  <StepLabel>
                    {card.displayName}
                    {card.isRequired ? (
                      <></>
                    ) : (
                      <div style={{ fontWeight: '400', fontSize: '14px', color: '#595959' }}>
                        Optional
                      </div>
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button
                  flex={0.4}
                  variant="text"
                  size="small"
                  label="Back"
                  onClick={openModal}
                  style={{ paddingLeft: 0 }}
                  data-testid="cancel">
                  Cancel
                </Button>
                <Box display="flex" justifyContent="flex-end" flex={0.6}>
                  {activeStep !== 0 && (
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={handleBack}
                      style={{ marginRight: '16px' }}
                      data-testid="back">
                      Back
                    </Button>
                  )}
                  {activeStep !== cards.length - 1 && (
                    <>
                      <Tooltip title={nextDisabledMessage}>
                        <span>
                          <Button
                            variant="primary"
                            size="small"
                            onClick={handleNext}
                            data-testid="next"
                            disabled={!nextEnabled}>
                            Next
                          </Button>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
      <CustomModal
        display={confirmAlert}
        title={'Leave Page?'}
        buttonPrimaryLabel={'Ok'}
        message={
          'Changes could not be saved if you leave this page, Would you still like to leave?'
        }
        handlePrimaryAction={() => handleReset()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => handleClose()}
      />
      {openWarningModal && (
        <CustomModal
          display={openWarningModal}
          variant={'warning'}
          title={
            <Box display={'flex'} flexDirection={'row'}>
              <StatusExclamationIcon />
              <span style={{ marginLeft: '0.5rem' }}>
                {warningModalConfig?.displayWarningModalTitle}
              </span>
            </Box>
          }
          message={
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {warningModalConfig?.displayWarningModalSubtitle}
            </Typography>
          }
          buttonProps={modalButtonProps}
          handleClose={() => {
            setOpenWarningModal(false);
          }}
          width={classes.modal}
        />
      )}
    </>
  );
}
