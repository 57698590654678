/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
// import { uuidv4 } from 'Utils';
import domainAdapter from '../Entity/Domain';
import domainRuleAdapter from '../Entity/DomainRule';
import variableAdapter from '../Entity/Variable';
import variableRuleAdapter from '../Entity/VariableRule';
// import RulesJson from './../../MockApis/rule.json'

export const DomainAndVariableSlice = createSlice({
  name: 'DomainAndVariable',
  initialState: {
    loading: false,
    error: '',
    domain: domainAdapter.getInitialState(),
    variable: variableAdapter.getInitialState(),
    domainRule: domainRuleAdapter.getInitialState(),
    variableRule: variableRuleAdapter.getInitialState(),
    selectedDomain: '',
    selectedVariable: '',
    domainRuleTree: []
  },
  reducers: {
    selectDomain(state, { payload }) {
      state.selectedDomain = payload;
    },
    selectVariable(state, { payload }) {
      state.selectedVariable = payload;
    },
    upsertDomainRule(state, { payload }) {
      domainRuleAdapter.upsertMany(state.domainRule, payload);
    },
    upsertVariableRule(state, { payload }) {
      variableRuleAdapter.upsertMany(state.variableRule, payload);
    }
  }
  /* comment out this section for timebeing not using currenty */
  /*
  extraReducers: {
    [GetDomainVariables.rejected](state) {
      state.loading = false;
    },
    [GetDomainVariables.fulfilled](state, { payload }) {
      state.loading = false;

      if (payload.data.domainColumnsDict) {
        let response = payload.data.domainColumnsDict;
        let domain = [];
        let variable = [];
        let domainRuleTree = [];
        Object.keys(response).forEach((key) => {
          let node = { nodeId: key, nodeText: key, childNodes: [] };
          let tempDomain = { id: key, variableIds: [] };
          response[key].forEach((variableArg) => {
            tempDomain.variableIds.push(variableArg);
            node.childNodes.push({ nodeId: variableArg, nodeText: variableArg });
            variable.push({ id: variableArg, domainId: key });
          });
          domainRuleTree.push(node);
          domain.push(tempDomain);
        });
        domainAdapter.setAll(state.domain, domain);
        variableAdapter.setAll(state.variable, variable);
        state.domainRuleTree = domainRuleTree;
      }
    },
    [GetDomainVariables.pending](state) {
      state.loading = true;
    },

    [GetDomainVariablesRules.rejected](state) {
      state.loading = false;
    },
    [GetDomainVariablesRules.fulfilled](state, { payload }) {
      state.loading = false;
      const rules = generateRuleData(payload.data);
      domainRuleAdapter.setAll(state.domainRule, rules.domainRules);
      variableRuleAdapter.setAll(state.variableRule, rules.variableRules);
    },
    [GetDomainVariablesRules.pending](state) {
      state.loading = true;
    },

    [PostDomainVariableRules.pending](state) {
      state.loading = true;
    },
    [PostDomainVariableRules.rejected](state) {
      state.loading = false;
    }
  }*/
});
/*function extractData(exp) {
  if (exp[exp.indexOf(']') + 1] === '.') {
    let x = exp.substring(exp.indexOf('[') + 1, exp.indexOf(']'));
    let y = exp.substring(exp.indexOf(']') + 2, exp.indexOf(')') + 1);
    return { variable: x, method: y };
  } else {
    let x = exp.substring(exp.indexOf('[') + 1, exp.indexOf(']'));
    return { variable: x, method: '' };
  }
}

function generateRuleData(args) {
  args = JSON.parse(JSON.stringify(args));
  let domainRules = [];
  let variableRules = [];
  if (args && args.mappingRule && args.mappingRule.domainVariableRulesDic) {
    let domainVariableRulesDic = args.mappingRule.domainVariableRulesDic;
    Object.keys(domainVariableRulesDic).forEach((key1) => {
      if (domainVariableRulesDic[key1] && domainVariableRulesDic[key1].mappingRows) {
        let mappingRows = domainVariableRulesDic[key1].mappingRows;
        Object.keys(mappingRows).forEach((key2) => {
          let domainRule = { id: key2, domainId: key1 };

          if (mappingRows[key2]) {
            mappingRows[key2].forEach((arg) => {
              let data = extractData(arg.expression);
              let formVariable = data.variable.split('.');
              let variable = '';
              let form = '';
              if (formVariable.length === 2) {
                form = formVariable[0];
                variable = formVariable[1];
              } else {
                variable = formVariable[0];
              }

              if (arg.variable_name === 'Q_ROW_CONDITION') {
                domainRule.defaultForm = form ? form : arg.default_form;
                domainRule.expression = arg.expression;
                domainRule.condition = '';
                domainRule.method = data.method;
                domainRule.variable = variable;
                domainRule.variable_name = arg.variable_name;
                domainRule.row_seq = arg.row_seq;
                domainRule.rule_seq = arg.rule_seq;
                domainRule.uuid = uuidv4();
                domainRule.concatenate = arg.concatenate;
                domainRule.createdDate = arg.createdDate;
              } else {
                arg.id = key2 + '-' + arg.variable_name;
                arg.domainId = key1;
                arg.domainRuleId = key2;
                arg.variableId = arg.variable_name;
                arg.variable = variable;
                arg.method = data.method;
                variableRules.push(arg);
              }
            });
          }
          domainRules.push(domainRule);
        });
      }
    });
  }
  return { domainRules, variableRules };
} 
*/
export const {
  selectDomain,
  selectVariable,
  upsertDomainRule,
  generatePayload,
  upsertVariableRule
} = DomainAndVariableSlice.actions;

export const DomainSelector = domainAdapter.getSelectors((state) => state.DomainAndVariable.domain);
export const VariableSelector = variableAdapter.getSelectors(
  (state) => state.DomainAndVariable.variable
);
export const DomainRuleSelector = domainRuleAdapter.getSelectors(
  (state) => state.DomainAndVariable.domainRule
);
export const VariableRuleSelector = variableRuleAdapter.getSelectors(
  (state) => state.DomainAndVariable.variableRule
);
