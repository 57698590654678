/* eslint-disable */
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import Download from 'apollo-react-icons/Download';
import Table from 'apollo-react/components/Table';
import TerminalIcon from '@mui/icons-material/Terminal';
import Grid from 'apollo-react/components/Grid';
import DataVizCard from 'apollo-react/components/DataVizCard';
import React, { useState } from 'react';
import Box from 'apollo-react/components/Box';
import { neutral3, red } from 'apollo-react/colors';
import { jsonToExcel } from 'Utils';

const useStyles = makeStyles({
  emptyTableHeader: {
    padding: '16px',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    border: '1px solid #4442',
    alignItems: 'center',
    backgroundColor: '#ffffff'
  },
  customTableHeader: {
    alignItems: 'center',
    padding: '0px',
    height: '64px'
  },
  root: {
    '& .MuiToolbar-root': {
      height: '68.6px',
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
      border: '1px solid #4442'
    }
  },
  dataVizCard: {
    borderRadius: '0px 0px 4px 4px',
    '& .MuiCardHeader-root': {
      height: '0px',
      padding: '0px'
    }
  },
  fw400: { fontWeight: '400' }
});

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959',
  height: 'auto'
};
const EmptyTableTypographyStyleTitle2 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '5px',
  height: 'auto'
};

const CustomButtonHeader = ({ error, queryResult }) => {
  const classes = useStyles();
  const handleDownload = () => {
    jsonToExcel(queryResult, 'QueryDetails.xlsx', 'queryDetails');
  };
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      className={queryResult ? classes.customTableHeader : classes.emptyTableHeader}>
      <Grid item xs={6}>
        <Typography variant="title1" style={{ fontSize: '16px' }}>
          Query Output{!error && <span className={classes.fw400}> (20 results max)</span>}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.2rem' }}>
          <Button
            size="small"
            variant="secondary"
            icon={<Download />}
            disabled={error || !queryResult?.length}
            onClick={handleDownload}
            style={{ marginRight: 15 }}>
            Download CSV
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

const QueryOutput = ({ queryColumns, queryResult, queryError }) => {
  const classes = useStyles();

  if (queryError) {
    return (
      <Box pt={0} pl={2.5} pr={3}>
        <CustomButtonHeader error={queryError} />
        <Box
          component={'div'}
          sx={{
            whiteSpace: 'pre-line',
            fontFamily: 'Menlo, Courier New, Monospace',
            px: '22px',
            py: '10px',
            backgroundColor: neutral3,
            color: red
          }}
          dangerouslySetInnerHTML={{ __html: queryError }}
        />
      </Box>
    );
  } else if (!queryResult) {
    return (
      <Box pt={0} pl={2.5} pr={3}>
        <CustomButtonHeader />
        <DataVizCard className={classes.dataVizCard}>
          <TerminalIcon fontSize="large" style={{ margin: '10px 0px', cursor: 'pointer' }} />
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            Query results will be displayed here
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Click the run button to execute the query
          </Typography>
        </DataVizCard>
      </Box>
    );
  } else {
    return (
      <Box pt={0} pl={2.5} pr={3}>
        <Table
          classes={{ root: classes.root }}
          columns={queryColumns?.split(',').map((column) => ({
            header: column,
            accessor: column
          }))}
          rows={queryResult?.slice(0, 20)}
          hasScroll
          maxHeight={650}
          hidePagination
          CustomHeader={CustomButtonHeader}
          headerProps={{ queryResult }}
        />
      </Box>
    );
  }
};

export default QueryOutput;
