
export const getTimePointsSheet = (timePointsData) => {
  let timePointsDataSheet = [];
  const getByLabel = (key) => {
    switch (key) {
      case 'qlab':
        return 'QLAB';
      case 'qecg':
        return 'QECG';
        case 'odm':
       return 'ODM'
    }
  };

  Object.entries(timePointsData).forEach(([key, value]) => {
    if (typeof (value) === 'object') {
      let sourceKey = getByLabel(key);
      value.forEach((item) => {
        timePointsDataSheet.push({
          SourceSystem: sourceKey,
          SourceSystemVisitName: item.visitName || item.trailVisitName || item.eventName,
          TimepointName: item.visitEventCode || item.trialVisitID || item.eventOID,
          Unscheduled: item.visitType || item.scheduledFlag || item.eventType
        });
      });
    }
  });
  return (timePointsDataSheet);
};
