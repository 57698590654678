import React, { useState } from 'react';
import { Select } from 'Components/Controls';
import Grid from 'apollo-react/components/Grid';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Autocomplete from 'apollo-react/components/Autocomplete';
import { useEffect } from 'react';

const AddStudyLibrarySDTM = (props) => {
  const sourceLabelName = props.formik.values.type === '' ? 'Trial Name/Data Sets' : 'Trial Name';
  const venderLabel = props.vender ? props.vender : 'N/A';
  const studySourceValues =
    props.studySourceValues && props.studySourceValues.length > 0 ? props.studySourceValues : [];
  const [savedMetaDataVersions, setSavedMetaDataVersions] = useState([]);

  useEffect(() => {
    if (props.editPage && !savedMetaDataVersions?.length) {
      const savedVersions = props.studyLibraryMetaDataVersion
        .filter((_version) => _version.disabled)
        .map((_version) => _version.value);
      setSavedMetaDataVersions(
        props.formik.values.metadataVersion.filter((_version) => savedVersions.includes(_version))
      );
    }
  }, [props.editPage, props.studyLibraryMetaDataVersion]);

  const handleChange = (e, selectedMetaDataVersions) => {
    props.handleMetaDataVersion();
    const metadataVersion = selectedMetaDataVersions?.map((_version) => _version.value);
    savedMetaDataVersions.forEach((_version) => {
      const versionIndex = metadataVersion.indexOf(_version);
      if (versionIndex != -1) {
        metadataVersion.splice(versionIndex, 1);
      }
    });
    props.formik.setValues({
      ...props.formik.values,
      metadataVersion: [...savedMetaDataVersions, ...metadataVersion]
    });
  };

  return (
    <>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            {props.formik.values.type === props.CDRTabular ? (
              props.multiSelectType()
            ) : (
              <Select
                id="source"
                label={sourceLabelName}
                name="source"
                data-testid="type-input"
                // required
                value={props.formik.values.source}
                onChange={(e) => {
                  props.handleMetaDataVersion();
                  props.formik.handleChange(e);
                }}
                onBlur={props.formik.handleBlur}
                error={props.formik.touched.source && Boolean(props.formik.errors.source)}
                helperText={props.formik.touched.source && props.formik.errors.source}
                fullWidth
                disabled={props.editPage}
                items={studySourceValues}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            {props.formik.values.type !== props.CDRTabular && (
              <Autocomplete
                id="metadataVersion"
                label="Metadata Version"
                name="metadataVersion"
                data-testid="type-input"
                value={props.formik.values.metadataVersion.map((_version) => ({
                  label: _version.split(',').pop(),
                  value: _version
                }))}
                onChange={handleChange}
                onBlur={props.formik.handleBlur}
                error={
                  props.formik.touched.metadataVersion &&
                  Boolean(props.formik.errors.metadataVersion)
                }
                helperText={
                  props.formik.touched.metadataVersion && props.formik.errors.metadataVersion
                }
                fullWidth
                multiple
                source={props.studyLibraryMetaDataVersion}
                disabled={
                  props.formik.values.type === 'Q2LAB' || props.formik.values.type === 'QECG'
                }
                filterSelectedOptions={false}
                showSelectAll
                limitChips={3}
                alwaysLimitChips
              />
            )}
          </Grid>
          {props.formik.values.type === 'CDISC ODM' ||
          props.formik.values.type === 'Q2LAB' ||
          props.formik.values.type === 'QECG' ? (
            <Grid item xs={5} style={{ marginTop: '18px', marginLeft: '42px' }}>
              <Typography variant="body2">Vendor</Typography>
              <Typography variant="title">{venderLabel}</Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default AddStudyLibrarySDTM;
