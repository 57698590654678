import React from 'react';
import Button from 'apollo-react/components/Button';
import { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import { LoaderStatus } from 'Pages/Dashboard/Components/Status';
import ElapsedTime from 'Pages/Dashboard/Components/ElapsedTime';
import { compareDates, dateFilter } from 'Utils';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { DateFilter } from 'Components/Common/DateFilter';

/**
 * Component to render ViewLogButton button for each record
 * @props {object} row
 */
export const ViewLogButton = ({ row }) => {
  return (
    <div>
      {row?.jobRunID && (
        <Button size="small" data-testid={`${row.jobRunID}-ViewLogButton`} onClick={row.onViewLog}>
          {row.jobRunID}
        </Button>
      )}
    </div>
  );
};

/**
 * Columns config for the Refresh History table
 */
const columns = [
  {
    header: 'Job Run ID',
    accessor: 'jobRunID',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('jobRunID'),
    fixedWidth: false,
    customCell: ViewLogButton
  },
  {
    header: 'Job start date time',
    accessor: 'startDateTime',
    sortFunction: compareDates,
    filterComponent: DateFilter,
    filterFunction: dateFilter('startDateTime'),
    fixedWidth: false
  },
  {
    header: 'Job end date time',
    accessor: 'endDateTime',
    sortFunction: compareDates,
    filterComponent: DateFilter,
    filterFunction: dateFilter('endDateTime'),
    fixedWidth: false
  },
  {
    header: 'Refresh Type',
    accessor: 'refreshType',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('refreshType'),
    fixedWidth: false
  },
  {
    header: 'Elapsed Time',
    accessor: 'elapsedTime',
    customCell: ElapsedTime,
    align: 'right',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('elapsedTime'),
    fixedWidth: false
  },
  {
    header: 'Status',
    accessor: 'loaderStatusMessageLable',
    customCell: LoaderStatus,
    sortFunction: compareStrings,
    align: 'center',
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('loaderStatusMessageLable'),
    fixedWidth: false
  },
  {
    header: '# of Subjects Processed',
    accessor: 'subjectProcessed',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('subjectProcessed'),
    align: 'right',
    fixedWidth: false
  }
];

export default columns;
