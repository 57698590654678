import Radio from 'apollo-react/components/Radio';
import styled from 'styled-components';
import { useHighlightedText } from 'Utils';

const ActionCell = ({ row }) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Radio
        value={row.protocolNumber}
        checked={row.protocolNumber === row.selectedStudy}
        onChange={() => row.handleSelectRow(row.protocolNumber)}
      />
    </div>
  );
};

const TableTextCell = styled('span')(() => ({
  display: 'block',
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
  maxWidth: '200px',
  overflow: 'hidden',
  overflowWrap: 'anywhere'
}));

const FormatedText = ({ row, column: { accessor } }) => {
  const formatedText = useHighlightedText(row?.searchText, row?.[accessor]);
  return <TableTextCell>{formatedText}</TableTextCell>;
};

export const columns = [
  {
    accessor: 'action',
    customCell: ActionCell,
    width: 57
  },
  {
    header: 'Protocol Number',
    accessor: 'protocolNumber',
    customCell: FormatedText
  },
  {
    header: 'Sponsor',
    accessor: 'sponsor',
    customCell: FormatedText
  },
  {
    header: 'Project Code',
    accessor: 'projectCode',
    customCell: FormatedText
  },
  {
    header: 'Therapeutic Area',
    accessor: 'therapeuticArea',
    customCell: FormatedText
  },
  {
    header: 'Data Sources',
    accessor: 'sourceDisplayName',
    customCell: FormatedText
  }
];
