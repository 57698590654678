/*eslint-disable*/
import CircularProgress from '@mui/material/CircularProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import FileUpload from 'apollo-react/components/FileUpload';
import Grid from 'apollo-react/components/Grid';
import CustomModal from 'Components/Modal';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeleteReferenceData,
  getCodeLists,
  saveCodeLists
} from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import {
  getImportFromStudyLibrary,
  setImportedData,
  setNextEnabled
} from 'Redux/Slice/RuleEditorSlice';
import { setReferenceData } from 'service/reference-data.service';
import { getCodeListReferenceData } from 'Services/CodeListReferenceData.Service';
import {
  convertExcelToJson,
  getHeaders,
  jsonToExcel,
  jsonToFileData,
  uuidv4,
  dateFormatByType
} from 'Utils';
import { codeListsValidator } from 'Validators/CodeLists.Validator';
import useSelectedDatasourcesData from '../../hooks/useSelectedDatasourcesData';
import Card from './Card';
import {
  codeListColumnNames,
  validateIsGenericCodeList,
  defaultGenericColumns,
  codeListObj
} from './CodeListUtils';
import { checkColumns, checkFileData, checkFileType } from '../Utils/FileValidationUtils';
import './Trials.css';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import Sync from 'apollo-react-icons/Sync';
import Tooltip from 'apollo-react/components/Tooltip';
import { syncProductCodelist } from 'Redux/Service/ReferenceDataCardsService';
import Cookies from 'js-cookie';
import moment from 'moment';

const CodeLists = (props, childRef) => {
  const navigate = useNavigate();

  const { id: mappingRuleVersionId } = useParams();

  const {
    data: selectedDataSourcesData,
    loading,
    error: apiError
  } = useSelectedDatasourcesData(mappingRuleVersionId);

  const {
    productNnemonic,
    targetDataModelName,
    targetDataModelVersion,
    protocolName,
    targetDataModelType
  } = useSelector((state) => {
    return state.DataProductStudyLibrary.rowDataForRuleEditor;
  });
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const userId = Cookies.get('user.id');
  const [dataSourceData, setDataSourceData] = useState([]);
  const [error, setError] = useState();
  const [codeListsUploadedFile, setCodeListsUploadedFile] = useState([]);
  const [codeListsPreview, setCodeListsPreview] = useState(true);
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const dispatch = useDispatch();
  const [codeListMessage, setCodeListMessage] = useState('');
  const [cards, setCardArray] = useState([]);
  const [codeListsData, setCodeListsData] = useState([]);
  const [codeListsValidationResult, setCodeListsValidationResult] = useState({});
  const [mappingError, setMappingError] = useState('');
  const [edcOdmInfo, setEdcOdmInfo] = useState({});
  const [isValidData, setIsValidData] = useState(false);
  const [isImportStudyLibrary, setIsImportStudyLibrary] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [isGeneric, setGeneric] = useState(false);
  const [newcolumns, setColumns] = useState({});
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [loaderFlag, setLoaderFlag] = useState(false);
  const CODE_LIST = 'code-lists';

  useEffect(() => {
    (async () => {
      setLoaderFlag(true);
      let isEproInfo = '';
      if (!loading) {
        if (
          selectedDataSourcesData &&
          selectedDataSourcesData.data &&
          selectedDataSourcesData.data.success
        ) {
          setDataSourceData(
            selectedDataSourcesData.data.ruleStudyLibrary.filter(
              (row) => row.isMappingRuleConfigured
            )
          );
          isEproInfo = await getCodeListReferenceData(
            selectedDataSourcesData.data.ruleStudyLibrary.filter(
              (row) => row.isMappingRuleConfigured
            ),
            {
              protocolNumber: protocol.protocolNumber,
              neededLibraries: ['CDISC ODM'],
              neededVendors: ['CLINICAL_INK', 'MERGE']
            }
          );
          //eslint-disable-next-line
          globalThis.cdassm = {
            edcOdmInfo: isEproInfo.edcOdmInfo,
            cdrInfo: isEproInfo.cdrInfo
          };
          setEdcOdmInfo(isEproInfo);
          setReferenceData('code-list-edcOdmInfo', isEproInfo);
          setLoaderFlag(false);
        } else {
          setLoaderFlag(false);
          apiError && dispatch(showBanner({ variant: 'error', message: apiError }));
        }
      }
    })();
  }, [selectedDataSourcesData, loading]);

  const confirmOnDelete = () => {
    codeListsUploadedFile.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          onConfirm: () => {
            deleteUploadedFile();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'CDList',
      mappingRuleVersionID: mappingRuleVersionId
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
    setCodeListsUploadedFile([]);
    setCodeListsPreview(true);
    setIsImportStudyLibrary(false);
    setCodeListsData([]);
    setImportedData([]);
    const index = cards.findIndex((el) => el === CODE_LIST);

    props.configuration.setCardVisibility(CODE_LIST, false);

    cards.splice(index, 1);
  };

  useEffect(() => {
    dispatch(getImportFromStudyLibrary(isImportStudyLibrary));
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['CodeList']) {
      if (!codeListsPreview) {
        const element = document.getElementById('anchor-preview');
        element.click();
        setMappingError(props.createMappingObj['message']);
        props.createMappingObj['CodeList'] = false;
      }
    }
  }, [codeListsPreview]);

  useEffect(() => {
    if (edcOdmInfo && Object.keys(edcOdmInfo).length > 0 && codeListsData.length > 0) {
      const validationResult = codeListsValidator(codeListsData, edcOdmInfo);
      const errorCountFlag = validationResult.errorCount;
      errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
      setCodeListsValidationResult(validationResult);
      props.configuration.setModuleValidation(CODE_LIST, errorCountFlag > 0 ? false : true);
    } else if (props.visible) {
      props.configuration.setModuleValidation(CODE_LIST, false);
    } else if (!props.visible) {
      props.configuration.setModuleValidation(CODE_LIST, true);
    }
  }, [edcOdmInfo, codeListsData, props.visible]);
  const getCodelistData = async () => {
    let payload = {
      mappingRuleVersionID: mappingRuleVersionId,
      isImport: false
    };
    const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
    props.configuration.setIsCompleted(CODE_LIST, true);
    props.configuration.setModuleValidation(CODE_LIST, true);
    setLoaderFlag(true);
    if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
      if (
        codeListsGetAPIData.data.ruleCodeLists &&
        codeListsGetAPIData.data.ruleCodeLists.length > 0
      ) {
        setImportedData(codeListsGetAPIData?.data?.ruleCodeLists);
        setIsImportStudyLibrary(
          codeListsGetAPIData.data.ruleCodeLists.find((ele) => (ele.isImported ? true : false))
        );
        const newData = codeListsGetAPIData.data.ruleCodeLists.map((item) => {
          let codeListItem = {};
          for (let label in codeListColumnNames) {
            if (Object.prototype.hasOwnProperty.call(codeListColumnNames, label)) {
              let apiLabel = codeListColumnNames[label];
              codeListItem[apiLabel] =
                apiLabel === 'iqUpdateDate'
                  ? dateFormatByType(item[apiLabel], 'Table')
                  : item[apiLabel];
            }
          }
          codeListItem.id = uuidv4();
          codeListItem.isImported = item.isImported;
          return codeListItem;
        });
        setCodeListsData(newData);
        let fileObj = jsonToFileData(newData, 'CodeLists.xlsx');
        setCodeListsUploadedFile([fileObj]);
        setCodeListsPreview(false);
        setIsValidData(true);
        props.configuration.setModuleValidation(CODE_LIST, true);
        props.configuration.setCardVisibility(CODE_LIST, true);
        setLoaderFlag(false);
      } else {
        setLoaderFlag(false);
        props.required && props.configuration.setModuleValidation(CODE_LIST, false);
      }
    } else {
      setCodeListMessage(codeListsGetAPIData.data.message);
      setCodeListsData([]);
      setIsValidData(false);
      setIsImportStudyLibrary(false);
    }
    props.configuration.setIsCompleted(CODE_LIST, true);
    setLoaderFlag(false);
  };

  useEffect(() => {
    getCodelistData();
  }, []);

  const allowedExtensions = ['xlsx', 'xls'];
  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  const downloadSheet = () => {
    let codeListEmptyRow = {};
    for (let displayName in codeListColumnNames) {
      if (
        displayName !== 'Updated Date' &&
        Object.prototype.hasOwnProperty.call(codeListColumnNames, displayName)
      ) {
        codeListEmptyRow[displayName] = '';
      }
    }
    jsonToExcel([codeListEmptyRow], 'CodeList.xlsx');
  };

  const columns = [
    'Source Form',
    'Source Field',
    'Source Field ID',
    'Source Codelist',
    'Question Text',
    'Source Value',
    'Source Translated text',
    'Modified Translated text'
    // 'Updated Date'
  ];

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  /*checkUploadAvailble function will check already data imported or not.
     If imported data available we showing popup msg and ask user to overwrite existing data */
  const checkUploadAvailble = (selectedFiles) => {
    if (codeListsData?.length) {
      setConfirmAlert({
        enabled: true,
        title: 'Are you sure you want to overwrite codelist?',
        message: 'This file will overwrite any previously imported codelist',
        variant: 'warning',
        onConfirm: () => {
          handleUpload(selectedFiles);
          setConfirmAlert({
            enabled: false
          });
        },
        onCancel: () => {
          setConfirmAlert({
            enabled: false
          });
        }
      });
    } else {
      handleUpload(selectedFiles);
    }
  };

  const handleUpload = async (selectedFiles) => {
    setLoaderFlag(true);
    let hasGenericCodeList = false;
    // let columns = {...codeListColumnNames};
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJSON = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);
      const isError = fileValidation(fileJSON, headers);
      if (!isError) {
        let newFileArr = [];
        let filteredFileArr = [];
        fileJSON.map((obj) => {
          obj = { ...codeListObj, ...obj };
          let lowerObj = _.transform(obj, function (result, val, key) {
            result[codeListColumnNames[key]] = val;
          });
          lowerObj.id = uuidv4();
          lowerObj.iqUpdateDate = new Date().toISOString();
          lowerObj.isImported = false;
          lowerObj = { ...defaultGenericColumns[0], ...lowerObj };
          newFileArr.push(lowerObj);
          lowerObj.iqUpdateDate = dateFormatByType(new Date().toISOString(), 'Table');
          filteredFileArr.push(lowerObj);
        });
        // if (validateIsGenericCodeList(newFileArr)) {
        //   hasGenericCodeList = true;
        // }
        const validationResult = codeListsValidator(newFileArr, edcOdmInfo);
        const errorCountFlag = validationResult.errorCount;
        // setGeneric(hasGenericCodeList);
        setColumns(columns);
        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        setCodeListsData(errorCountFlag > 0 ? filteredFileArr : newFileArr);
        setError('');
        setCodeListsValidationResult(validationResult);
        setCodeListsUploadedFile([selectedFiles[0]]);
        setCodeListsPreview(false);
        setReferenceData('code-list', newFileArr);
        setReferenceData('code-list-validationResult', validationResult);
        // setReferenceData('code-list-generic', hasGenericCodeList);
        dispatch(getImportFromStudyLibrary(false));
        if (errorCountFlag === 0) {
          onSave(newFileArr, 'upload', true);
        }
      } else {
        setError(isError);
        setCodeListsUploadedFile([]);
        setCodeListsPreview(true);
        setCodeListsData([]);
        setLoaderFlag(false);
      }
    }
    setLoaderFlag(false);
  };

  const openDeleteModal = () => {
    setDeleteFile(true);
  };

  const handleEditPreview = (isUploaded) => {
    if (isUploaded || codeListsData) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/code-list`);
    }
  };

  /*checkSyncAvailable function will used to open Alert msg and call Sync function*/
  const checkSyncAvailable = () => {
    setConfirmAlert({
      enabled: true,
      title: `${isImportStudyLibrary ? 'Update' : 'Import'} Codelist from Study Library?`,
      message:
        'This action will add any new available data in the study library into your existing codelist',
      variant: 'warning',
      onConfirm: () => {
        handleImportStudyLibrary('sync', true);
        setConfirmAlert({
          enabled: false
        });
      },
      onCancel: () => {
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  const getSavePayload = (data, auditType, type) => {
    let codeListApiColumns = Object.values(codeListColumnNames);
    return {
      ruleCodeLists: data.map((currentRowItem) => {
        /* comparing data with imported data */
        const cdiscImportedData = (type === 'import' ? data : importedData).find(
          (ele) =>
            `${ele.value}-${ele.odmItem}` === `${currentRowItem.value}-${currentRowItem.odmItem}`
        );

        return {
          ...codeListApiColumns.reduce((acc, item) => {
            acc[item] = currentRowItem[item];
            return acc;
          }, {}),
          iqCreateDate:
            currentRowItem.iqCreatedBy === null ||
            currentRowItem.iqCreatedBy === undefined ||
            currentRowItem.iqCreatedBy === ''
              ? new Date().toISOString()
              : currentRowItem.iqCreateDate,
          iqCreatedBy: userId,
          iqUpdatedBy: userId,
          iqUpdateDate:
            currentRowItem.iqCreatedBy === null ||
            currentRowItem.iqCreatedBy === undefined ||
            currentRowItem.iqCreatedBy === ''
              ? new Date().toISOString()
              : currentRowItem.iqUpdateDate,
          iqAuditType: auditType,
          iqAuditDate:
            currentRowItem.iqCreatedBy === null ||
            currentRowItem.iqCreatedBy === undefined ||
            currentRowItem.iqCreatedBy === ''
              ? new Date().toISOString()
              : currentRowItem.iqAuditDate,
          iqActiveFlag: true,
          mappingCodeListId: '',
          isImported: cdiscImportedData !== undefined ? cdiscImportedData.isImported : false,
          protocolNumber: protocol.protocolNumber,
          mappingRuleVersionId: mappingRuleVersionId,
          domainNm: 'CodeList'
        };
      })
    };
  };

  const onSave = async (data, type, loadFlag) => {
    const payload = getSavePayload(data, 'INSERT', type);

    const saveResponse = await dispatch(saveCodeLists(payload));
    if (
      saveResponse &&
      saveResponse.payload &&
      saveResponse.payload.data &&
      saveResponse.payload.data.success
    ) {
      setImportedData(payload);
      setCodeListsData(payload);
      setReferenceData('code-list', payload);
      setReferenceData('code-list-validationResult', edcOdmInfo);
      if (type !== 'upload') {
        setIsImportStudyLibrary(true);
        dispatch(getImportFromStudyLibrary(true));
        navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/code-list`);
      }
      setLoaderFlag(!loadFlag);
      getCodelistData();
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/code-list`);
      dispatch(showBanner({ variant: 'success', message: saveResponse.payload.data.message }));
    } else {
      setLoaderFlag(!loadFlag);
      dispatch(showBanner({ variant: 'error', message: 'something went wrong' }));
    }
  };
  const handleImportStudyLibrary = async (type, flag) => {
    setLoaderFlag(flag);
    let libId, protocolNumber;
    let filteredDataSource = [];
    filteredDataSource = dataSourceData.filter(
      (item) => item.libraryType === 'CDISC ODM' && item.isMappingRuleConfigured
    );
    filteredDataSource.forEach((el) => {
      libId = el.libraryID;
      protocolNumber = el.studyProtocolNumber;
    });

    let payload = {
      studyLibraryID: libId,
      protocolName: protocolNumber,
      isImport: true
    };
    let CODELIST_DATA;
    if (type === 'sync') {
      /*API call for Sync Data*/
      const payloadData = {
        mappingRuleVersionID: mappingRuleVersionId
      };
      CODELIST_DATA = await dispatch(syncProductCodelist(payloadData)).then(unwrapResult);
    } else {
      /*API call for import data*/
      CODELIST_DATA = await dispatch(getCodeLists(payload)).then(unwrapResult);
    }

    if (CODELIST_DATA?.data?.success) {
      if (CODELIST_DATA.data.ruleCodeLists?.length > 0) {
        const codelistData = CODELIST_DATA.data.ruleCodeLists.map((item) => {
          return {
            ...item,
            id: uuidv4()
          };
        });
        if (codelistData?.length) {
          if (type === 'sync') {
            let checkSyncInData = codelistData.some((item) => item.isSynced);
            if (checkSyncInData) {
              onSave(codelistData, 'import', flag);
            } else {
              dispatch(showBanner({ variant: 'warning', message: 'No updates found' }));
              setLoaderFlag(!flag);
            }
          } else {
            onSave(codelistData, 'import', flag);
          }
        }
        setLoaderFlag(!flag);
      } else {
        dispatch(showBanner({ variant: 'warning', message: 'No Data available for Import' }));
        setLoaderFlag(!flag);
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: CODELIST_DATA.data.message }));
    }
  };

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        targetDataModelType={targetDataModelType}
        deleteCardInParent={confirmOnDelete}
        required={props.required}
        refMsg={codeListMessage}
        setErrorFlag={errorFlag}
        cardName={props.title}
        handleImportStudyLibrary={handleImportStudyLibrary}
        selectedSourceData={dataSourceData}
        handleDownload={downloadSheet}
        isEditDisable={codeListsPreview}
        isEdcInfoReady={!loaderFlag}
        handleEditPreview={() => handleEditPreview()}
        disableImportText={loaderFlag}>
        <div style={{ margin: '0px 10px' }}>
          {loaderFlag && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}

          <div className="fileUpload">
            <FileUpload
              disabled={loaderFlag}
              value={isImportStudyLibrary ? [] : codeListsUploadedFile}
              onUpload={checkUploadAvailble}
              onFileDelete={openDeleteModal}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
            />
            <div
              style={{
                display: 'flex',
                justifyContent: `${isImportStudyLibrary ? 'space-between' : 'end'}`
              }}>
              {isImportStudyLibrary && (
                <p
                  style={{
                    height: '24px',
                    color: 'black',
                    fontFamily: 'Proxima Nova',
                    fontSize: '14px',
                    fontWeight: 400,
                    letterSpacing: 0,
                    lineHeight: '24px'
                  }}>
                  Codelist Imported
                </p>
              )}
              <div
                style={{
                  marginTop: `${isImportStudyLibrary ? '0.7rem' : '-2rem'}`,
                  marginRight: `${isImportStudyLibrary ? '0' : '2rem'}`
                }}>
                {targetDataModelType !== 'Ruleset' && !codeListsPreview && (
                  <Tooltip
                    variant="dark"
                    title={`${
                      !isImportStudyLibrary ? 'Import' : 'Update'
                    } codelist from study library`}
                    placement="top">
                    <Sync
                      style={{ color: '#015FF1', cursor: 'pointer' }}
                      onClick={checkSyncAvailable}
                    />
                  </Tooltip>
                )}
                {isImportStudyLibrary && (
                  <StatusCheck
                    style={{ color: '#00C221', width: '20px', height: '20px', marginLeft: '1rem' }}
                    fontSize="small"
                  />
                )}
              </div>
            </div>
          </div>
          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </Grid>
  );
};
export default CodeLists;
