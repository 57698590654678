import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const GetPreviewSources = createAsyncThunk(
  'RuleEditor/GetPreviewSources',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetPreviewSources`, payload);
  }
);

export const GetPreviewSubjectIDs = createAsyncThunk(
  'RuleEditor/GetDomainPreviewSubjectIds',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetDomainPreviewSubjectIds`, payload);
  }
);

export const getSubjectDomainPreviewData = createAsyncThunk(
  '/RuleEditor/GetSubjectDomainPreviewData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetSubjectDomainPreviewData`, payload);
  }
);

export const SourcesTestConnection = createAsyncThunk(
  'Protocol/TestStudyConfiguration',
  async (payload) => {
    return await axios.post(`${API_URL}/api/Protocol/TestStudyConfiguration`, payload);
  }
);

export const GetStudyLibrarySourcesByTypes = createAsyncThunk(
  'Protocol/GetStudyLibrarySourcesByTypes',
  async (payload) => {
    return await axios.post(`${API_URL}/api/Protocol/GetStudyLibrarySourcesByTypes`, payload);
  }
);

export const GetCDRECRFDatasetTrials = createAsyncThunk(
  'Protocol/GetCDRECRFDatasetTrials',
  async (protocolNumber) => {
    return axios.get(
      `${API_URL}/api/Protocol/GetCDRECRFDatasetTrials?ProtocolNumber=${protocolNumber}`
    );
  }
);