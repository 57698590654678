/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { GetViewGlobalLibraries } from '../Service/GlobalLibraryService';
import { getAuditHistory } from '../Service/ModalService';

export const GlobalLibrarySlice = createSlice({
  name: 'GlobalLibrary',
  initialState: {
    loading: false,
    error: '',
    globalLibraries: [],
    history: [],
    updatedGlobalLibraries: false
  },

  reducers: {
    getUpdatedGlobalLibraries(state, { payload }) {
      state.updatedGlobalLibraries = payload;
    }
  },
  extraReducers: {
    [GetViewGlobalLibraries.rejected](state) {
      state.loading = false;
    },
    [GetViewGlobalLibraries.fulfilled](state, { payload }) {
      state.loading = false;
      state.globalLibraries = payload;
    },
    [GetViewGlobalLibraries.pending](state) {
      state.loading = true;
    },
    [getAuditHistory.rejected](state) {
      state.loading = false;
    },
    [getAuditHistory.fulfilled](state, { payload }) {
      state.loading = false;
      state.history = payload;
    },
    [getAuditHistory.pending](state) {
      state.loading = true;
    }
  }
});

export const { getUpdatedGlobalLibraries } = GlobalLibrarySlice.actions;
