import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import ContentHolder from './ContentHolder';
import { DATAFLOWTYPE_PAYLOAD_VALUES, DATA_FLOW_TYPES } from 'Constants/DataFlowTypes';
import useDisplayName from 'Utils/useDisplayName';
import { DATA_SET_TYPES } from 'Constants/DataSetTypes';

const TrialContent = ({ row }) => {
  const getDisplayName = useDisplayName();
  let trialName = row.trialName;
  if (row.parentSourceName === 'CDR') {
    trialName = DATA_FLOW_TYPES[row.trialName] || row.trialName;
    if (
      row.trialName === DATAFLOWTYPE_PAYLOAD_VALUES.TEST &&
      row.dataSetType === DATA_SET_TYPES.eCRF_VIEW.NAME
    ) {
      trialName = `${trialName} - ${row.ecrfTrialName}`;
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
      <Grid item xs={3}>
        <ContentHolder title={'Source'} value={getDisplayName(row.sourceName)} />
      </Grid>
      <Grid item xs={3}>
        <ContentHolder title={'Database'} value={row.databaseName} />
      </Grid>
      <Grid item xs={3}>
        <ContentHolder title={'Trial Name/Data Flow Type'} value={trialName} />
      </Grid>
    </div>
  );
};

const SourceConnectionCard = ({ handleClick, trialName }) => {
  return (
    <Card style={{ width: '100%', marginBottom: '2rem' }}>
      <Grid container spacing={2} style={{ margin: '2%' }}>
        <Grid item xs={3}>
          <Typography variant="title1">
            <p style={{ marginBottom: '0.5rem' }}>Trial Name/Data Flow Type</p>
          </Typography>
          <Button variant="text" style={{ padding: 0 }} onClick={handleClick} size="small">
            Change Trial Name/Data Flow Type
          </Button>
        </Grid>
        <Grid item xs={9}>
          {trialName?.map((row) => {
            return <TrialContent row={row} key={row.sourceName} />;
          })}
        </Grid>
      </Grid>
    </Card>
  );
};

export default SourceConnectionCard;
