import { useMemo } from 'react';
import Button from 'apollo-react/components/Button';
import Box from 'apollo-react/components/Box';
import Typography from 'apollo-react/components/Typography';
import { neutral7 } from 'apollo-react/colors';

import Search from 'apollo-react-icons/Search';
import Refresh from 'apollo-react-icons/Refresh';
import SearchDBWhite from 'Images/search-db-white.svg';

import styled from 'styled-components';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'sticky',
  left: '0px',
  maxWidth: 'calc(100vw - 7.25rem)',
  fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
});

const SearchIcon = styled(Search)({
  height: '32px',
  width: '32px',
  marginBottom: '1rem',
  color: neutral7
});

const ContentText = styled(Typography)({ fontSize: '18px', fontWeight: '600', color: neutral7 });
const MessageText = styled(Typography)({
  fontSize: '14px',
  width: 'clamp(200px, 40%, 400px)',
  marginBottom: '1rem'
});

const EmptyTableComp = ({
  isDataFilteredLocal,
  isDataFilteredRemotely,
  handleSearchRemotely,
  handleClearRemoteFilters,
  displayStandardMessage
}) => {
  const [message, btnLabel, btnAction, Icon] = useMemo(() => {
    if (isDataFilteredRemotely && !isDataFilteredLocal) {
      return [
        `Adjust your search parameters or click the button below to reset the table data to the initial 50 records`,
        'Reset Table Data',
        handleClearRemoteFilters,
        () => <Refresh key={'Reset Table Data'} style={{ height: '18px' }} />
      ];
    } else {
      return [
        `Click the button below to run a database search using the specified criteria. 
      The results might take up to a minute to load`,
        'Search in database',
        handleSearchRemotely,
        () => (
          <img
            key={'Search in database'}
            src={SearchDBWhite}
            style={{ height: '15px', marginRight: '0.5rem' }}
          />
        )
      ];
    }
  }, [
    isDataFilteredLocal,
    isDataFilteredRemotely,
    handleSearchRemotely,
    handleClearRemoteFilters,
    displayStandardMessage
  ]);

  return (
    <Container>
      {displayStandardMessage ||
      (isDataFilteredLocal === undefined && isDataFilteredRemotely === undefined) ? (
        'No data to display'
      ) : (
        <>
          <SearchIcon />
          <ContentText>No results found</ContentText>
          <MessageText>{message}</MessageText>
          <Button variant={'primary'} onClick={btnAction}>
            <Icon />
            {btnLabel}
          </Button>
        </>
      )}
    </Container>
  );
};

export default EmptyTableComp;
