import Button from 'apollo-react/components/Button';
import Box from 'apollo-react/components/Box';
import Typography from 'apollo-react/components/Typography';
import { neutral7 } from 'apollo-react/colors';
import PlusIcon from 'apollo-react-icons/Plus';

import NoRulesetsIcon from 'Images/no-codelists.svg';

import styled from 'styled-components';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'sticky',
  top: '40%',
  height: 'calc(40%)',
  width: 'calc(100%)',
  fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
});

const SearchIcon = styled.img({
  height: '32px',
  width: '32px',
  marginBottom: '1rem',
  color: neutral7
});

const ContentText = styled(Typography)({ fontSize: '18px', fontWeight: '600', color: neutral7 });
const MessageText = styled(Typography)({
  fontSize: '14px',
  width: 'clamp(200px, 40%, 400px)',
  marginBottom: '1rem',
  whiteSpace: 'pre-line'
});

const NoCodelistMessage = ({ handleAddRow }) => {
  return (
    <Container>
      <SearchIcon src={NoRulesetsIcon} />
      <ContentText>No rows in code list</ContentText>
      <MessageText>
        {
          'Looks like no rows have been added to this code list.\nClick the button below to add one.'
        }
      </MessageText>
      <Button size={'small'} variant={'secondary'} onClick={handleAddRow} icon={PlusIcon}>
        Add row to code list
      </Button>
    </Container>
  );
};

export default NoCodelistMessage;
