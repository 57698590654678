import StudySpecificDashboard from 'Pages/StudySpecificDashboard/StudySpecificDashboard';
import PublishLayout from '../../Layout/PublishLayout/PublishLayout'

const routes = [
    {
    path: '/manage-data-pipeline',
    component:  StudySpecificDashboard ,
    layout: PublishLayout ,
    exact: true,
    authentication: true
  },
];

export default routes;
