import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Custom async thunk: GetCDMNameMappings. Fetches CDM naming conversions lookup data from the API.
 * - Usage: `dispatch(GetCDMNameMappings())`
 */
export const GetCDMNameMappings = createAsyncThunk(
  'api/Protocol/CDMNamingConversionsLookUp',
  async () => {
    return await axios.get(`${API_URL}/api/Protocol/CDMNamingConversionsLookUp`);
  }
);

export const GetGlobalMessages = createAsyncThunk('api/Utility/GetGlobalMessages', async () => {
  return await axios.get(`${API_URL}/api/Utility/GetGlobalMessages`);
});

export const GetGlobalKeys = createAsyncThunk('api/Utility/GetGlobalKeys', async () => {
  return await axios.get(`${API_URL}/api/Utility/GetGlobalKeys`);
});

