import Divider from 'apollo-react/components/Divider';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
const UpdateView = (props) => {
  const [viewUpdateList, setViewUpdateList] = useState('');
  useEffect(() => {
    let newArray = '';
    if (props && props.data && props.data.viewUpdates !== null) {
      let copyViewUpdates = JSON.parse(JSON.stringify(props.data.viewUpdates));
      newArray = copyViewUpdates.sort((a, b) => {
        return Date.parse(b.updatedDate) - Date.parse(a.updatedDate);
      });

      setViewUpdateList(newArray);
    }
  }, [props]);
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {viewUpdateList &&
            viewUpdateList.map((el, i) => {
              const isLast = i === props.data.viewUpdates.length - 1;
              let date = moment(el.updatedDate).format('DD-MMM-YYYY hh:mm A');
              return (
                <>
                  <Typography
                    variant="title2"
                    style={{
                      color: '#444444',
                      fontFamily: 'Proxima Nova',
                      fontSize: '16px',
                      fontWeight: 600,
                      letterSpacing: 0,
                      lineHeight: '24px'
                    }}>
                    {el.updateDescription}
                  </Typography>
                  <Grid>
                    <Typography
                      variant="body3"
                      style={{
                        height: '24px',
                        width: '151px'
                      }}>
                      {date}
                    </Typography>
                    {isLast ? null : <Divider style={{ margin: '8px 0 8px 0' }} />}
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateView;
