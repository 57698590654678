import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import ContentHolder from './ContentHolder';

const DataExportCard = ({ dataExport, handleClick }) => {
  return (
    <Card style={{ width: '100%', marginBottom: '2rem' }}>
      <Grid container spacing={2} style={{ margin: '2%' }}>
        <Grid item xs={3}>
          <Typography variant="title1">Data Export</Typography>
          <Button variant="text" size="small" style={{ padding: 0 }} onClick={handleClick}>
            Change Export Destinations
          </Button>
        </Grid>
        <Grid item xs={9}>
          {dataExport?.map((data) => {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }} key={data.location}>
                <Grid item xs={3}>
                  <ContentHolder title={'Destination'} value={data.destination} />
                </Grid>
                <Grid item xs={3}>
                  <ContentHolder title={'Database/Location'} value={data.location} />
                </Grid>
                <Grid item xs={3}>
                  <ContentHolder title={'Username'} value={data.userName} />
                </Grid>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </Card>
  );
};

export default DataExportCard;
