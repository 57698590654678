export const columnMappings = {
  seqfororder: 'domain_column_id',
  domainprefix: 'domain_code',
  variablenameminusdomainprefix: 'var_nm_domain_prefix',
  variablelabel: 'variable_label',
  type: 'data_type',
  controlledterms: 'codelist',
  role: 'variable_type',
  cdiscnotesfordomainsdescriptionforgeneralclasses: 'cdisc_note',
  core: 'constrnt',
  data_length: 'data_length',
  data_precision: 'data_precision',
  data_scale: 'data_scale',
  is_sdtm_yn: 'is_sdtm_yn',
  mapped_by: 'mapped_by',
  versionname: 'lib_version',
  observationclass: 'observation_class',
  variablename: 'variable_name',
  domainname: 'domain_name',
domainstructure : 'domain_structure'
};

export const BASE_OBJ = {
  seqfororder: undefined,
  domainprefix: undefined,
  variablenameminusdomainprefix: undefined,
  variablelabel: undefined,
  type: undefined,
  controlledterms: undefined,
  role: undefined,
  cdiscnotesfordomainsdescriptionforgeneralclasses: undefined,
  core: undefined,
  data_length: undefined,
  data_precision: undefined,
  data_scale: undefined,
  is_sdtm_yn: undefined,
  mapped_by: undefined,
  versionname: undefined,
  observationclass: undefined,
  variablename: undefined,
  domainname: undefined,
domainstructure : undefined
};
