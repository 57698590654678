import {
  codeListColumnNames,
  validateIsGenericCodeList
} from 'Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Components/CodeListUtils';
import { ErrorConstants } from './ErrorConstants';

const { mandatoryError, uniqueCodeListError, uniqueGenericCodeListError } = ErrorConstants;

export const codeListsValidator = (records, edcCdrInfo) => {
  //eslint-disable-line
  let errorCount = 0;
  let errorOnRecords = {};
  let uniqueCheck = {};
  let apiColumnNames = Object.values(codeListColumnNames);
  // let isGeneric = false;
  // if (records && records.length > 0) {
  //     if (validateIsGenericCodeList(records)) {
  //         isGeneric = true;
  //     }
  // }
  // if (isGeneric) {
  //     apiColumnNames = {...genericCodeListColumnNames};
  // }

  const setErrorOnRecords = (recordId, columnName, message) => {
    errorCount++;
    if (typeof errorOnRecords[recordId] === 'undefined') {
      errorOnRecords[recordId] = {};
    }
    if (typeof errorOnRecords[recordId][columnName] !== 'undefined') {
      errorCount--;
      message = `${errorOnRecords[recordId][columnName]}; ${message}`;
    }
    errorOnRecords[recordId][columnName] = message;
  };

  const checkUnique = (record) => {
    let recordId = record.id;
    let value = `${record.odmForm?.toString()}-${record.itemOid?.toString()}-${record.value?.toString()}`;
    if (Object.keys(uniqueCheck).length > 0 && typeof uniqueCheck[value] !== 'undefined') {
      let uniqueCodeListErrorManipulated = uniqueCodeListError
        .replace('$itemOid', codeListColumnNames.itemOid)
        .replace('$odmForm', codeListColumnNames.odmForm)
        .replace('$value', codeListColumnNames.value);

      setErrorOnRecords(recordId, 'odmForm', uniqueCodeListErrorManipulated);
      setErrorOnRecords(recordId, 'itemOid', uniqueCodeListErrorManipulated);
      setErrorOnRecords(recordId, 'value', uniqueCodeListErrorManipulated);
      if (typeof uniqueCheck[value] === 'string') {
        let pastRecordId = uniqueCheck[value];
        if (typeof errorOnRecords[pastRecordId] === 'undefined') {
          errorOnRecords[pastRecordId] = {};
        }
        setErrorOnRecords(pastRecordId, 'odmForm', uniqueCodeListErrorManipulated);
        setErrorOnRecords(pastRecordId, 'itemOid', uniqueCodeListErrorManipulated);
        setErrorOnRecords(pastRecordId, 'value', uniqueCodeListErrorManipulated);
        uniqueCheck[value] = true;
      }
    } else {
      uniqueCheck[value] = recordId;
    }
  };

  const updateStrings = (record) => {
    let apiColumnNames = Object.values(codeListColumnNames);
    for (let column of apiColumnNames) {
      if (codeListColumnNames[`${column}_props`]?.type === 'STRING') {
        return Object.assign({}, record, { column: record[column]?.toString() });
        // record[column] = record[column]?.toString();
      }
    }
  };

  const validateMandatoryValuesForGeneric = (record, keys) => {
    if (keys && keys.length > 0) {
      keys.forEach((key) => {
        if (!record[key] && record[key] !== 0) {
          setErrorOnRecords(record.id, key, mandatoryError);
        }
      });
    }
  };

  const validateUniqueValuesForGeneric = (record) => {
    const { odmCodeListName, value } = record;
    validateMandatoryValuesForGeneric(record, ['odmCodeListName', 'value']);
    const uniqueValue = `${odmCodeListName?.toString()}-${value?.toString()}`;
    if (uniqueCheck && Object.keys(uniqueCheck).length > 0 && uniqueCheck[uniqueValue]) {
      let message = uniqueGenericCodeListError
        .replace('$odmCodeListname', codeListColumnNames['odmCodeListName'])
        .replace('$value', codeListColumnNames['value']);
      setErrorOnRecords(record.id, 'odmCodeListName', message);
      setErrorOnRecords(record.id, 'value', message);
      if (
        uniqueCheck[uniqueValue] !== null ||
        uniqueCheck[uniqueValue] !== undefined ||
        uniqueCheck[uniqueValue].trim() !== ''
      ) {
        const pastRecordId = uniqueCheck[uniqueValue];
        setErrorOnRecords(pastRecordId, 'odmCodeListName', message);
        setErrorOnRecords(pastRecordId, 'value', message);
      }
    } else {
      uniqueCheck[uniqueValue] = record.id;
    }
  };

  const doesNotMatchError = `It does not match with EDC source metadata`;
  const doesNotMatchErrorCDR = `It does not match with CDR source metadata`;
  const invalidSourceInfo = `Invalid Data`;
  const neededLibraries = ['CDISC ODM'],
    neededVendors = ['CLINICAL_INK', 'MERGE'];
  // if (!isGeneric) {
  records.forEach((record) => {
    let recordId = record.id;
    errorOnRecords[recordId] = {};
    const isGeneric = validateIsGenericCodeList(record);
    if (isGeneric) {
      validateUniqueValuesForGeneric(record);
      validateMandatoryValuesForGeneric(record, ['odmCodelistLabel']);
    } else {
      checkUnique(record);
      updateStrings(record);
      let edcOdmInfoRecord = Object.keys(edcCdrInfo.edcOdmInfo).length && edcCdrInfo.edcOdmInfo;
      let isEdcRecordAvailable = edcOdmInfoRecord !== 0 && typeof edcOdmInfoRecord !== 'undefined';
      let cdrInfoRecord =
        Object.keys(edcCdrInfo.cdrInfo).length && edcCdrInfo.cdrInfo[record.itemOid];
      let isCdrRecordAvailable = cdrInfoRecord !== 0 && typeof cdrInfoRecord !== 'undefined';

      if (isCdrRecordAvailable) {
        for (let key of apiColumnNames) {
          let value = record[key];
          if (typeof value !== 'undefined') {
            switch (key) {
              case 'odmForm':
                if (key !== 'itemOid' && value !== cdrInfoRecord[key]) {
                  setErrorOnRecords(recordId, key, doesNotMatchErrorCDR);
                }
                break;
              case 'odmItem':
                if (key !== 'itemOid' && value !== cdrInfoRecord[key]) {
                  setErrorOnRecords(recordId, key, doesNotMatchErrorCDR);
                }
                break;
            }
          } else {
            record[key] = '';
            if (codeListColumnNames[`${key}_props`].isMandatory()) {
              setErrorOnRecords(recordId, key, mandatoryError);
            }
            switch (key) {
              case 'odmCodeListName':
              case 'value':
              case 'label':
              case 'odmForm':
                break;
            }
          }
        }
      } else if (isEdcRecordAvailable) {
        edcOdmInfoRecord = edcOdmInfoRecord[record.itemOid];
        if (edcOdmInfoRecord === undefined) {
          setErrorOnRecords(recordId, 'itemOid', `It should be a valid source field ID`);
          return;
        }
        for (let key of apiColumnNames) {
          let value = record[key];
          if (typeof value !== 'undefined') {
            switch (key) {
              case 'isEpro':
                if (
                  isEdcRecordAvailable &&
                  neededLibraries.includes(edcOdmInfoRecord.libraryType) &&
                  neededVendors.includes(edcOdmInfoRecord.vendor)
                ) {
                  if (value.toLowerCase() !== edcOdmInfoRecord.isEpro.toLowerCase().trim()) {
                    setErrorOnRecords(
                      recordId,
                      'isEpro',
                      `It should be a ${edcOdmInfoRecord.isEpro.toLowerCase().trim()}`
                    );
                  }
                } else {
                  if (typeof value === 'undefined') {
                    record[key] = '';
                  }
                  setErrorOnRecords(recordId, 'isEpro', `It should be empty`);
                }
                break;
              case 'itemOid':
              case 'odmForm':
                if (key !== 'itemOid' && value !== edcOdmInfoRecord[key]) {
                  setErrorOnRecords(recordId, key, doesNotMatchError);
                }
                break;
              case 'value':
              case 'label':
              case 'odmCodeListName':
                if (!edcOdmInfoRecord[`${key}_${value}`]) {
                  setErrorOnRecords(recordId, key, doesNotMatchError);
                }
                break;
            }
          } else {
            record[key] = '';
            if (codeListColumnNames[`${key}_props`].isMandatory()) {
              setErrorOnRecords(recordId, key, mandatoryError);
            }
            switch (key) {
              case 'odmCodeListName':
              case 'value':
              case 'label':
              case 'odmForm':
              case 'isEpro':
                let value_elsecase = record[key]; //eslint-disable-line
                if (
                  isEdcRecordAvailable &&
                  neededLibraries.includes(edcOdmInfoRecord.library) &&
                  neededVendors.includes(edcOdmInfoRecord.vendor) &&
                  value_elsecase !== edcOdmInfoRecord[key]
                ) {
                  setErrorOnRecords(recordId, key, doesNotMatchError);
                }
                break;
            }
          }
        }
      } else {
        for (let key of apiColumnNames) {
          let value = record[key];
          if (typeof value !== 'undefined') {
            switch (key) {
              case 'odmForm':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              case 'odmItem':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              case 'odmCodeListName':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              case 'value':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              case 'label':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              case 'sdmCodelistLabel':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              case 'odmCodelistLabel':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              case 'questionText':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
              // case "iqUpdateDate":
              // setErrorOnRecords(recordId, key, invalidSourceInfo);
              // break;
              case 'itemOid':
                setErrorOnRecords(recordId, key, invalidSourceInfo);
                break;
            }
          } else {
            record[key] = '';
            if (codeListColumnNames[`${key}_props`].isMandatory()) {
              setErrorOnRecords(recordId, key, invalidSourceInfo);
            }
            switch (key) {
              case 'odmCodeListName':
              case 'value':
              case 'label':
              case 'odmForm':
                break;
            }
          }
        }
      }
    }

    if (Object.keys(errorOnRecords[recordId]).length === 0) {
      delete errorOnRecords[recordId];
    }
  });
  return {
    errorCount,
    errorOnRecords
  };
};

export default { codeListsValidator };