/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
// import { GetStudyLibraryType, GetStudyLibrarySource } from '../Service/AddStudyLibraryService';

export const AddStudyLibrarySlice = createSlice({
  name: 'addStudyLibrary',

  initialState: {
    studyLibraryType: [],
    studyLibrarySources: [],
    studyObj: {},
    disableFinishButton : false,
    dataSourceTrailValues: {},
    disableAddSQLButton : true,
    silentProperties: {}
  },
  reducers: {
    getStudyFormObject(state, { payload }) {
      if (payload && payload.packages && payload.packages.length > 0 && payload.source && payload.source.length > 0) {
        payload.source = payload.source.filter((f) => {
          return !payload.packages.includes(f);
        })
      }
      const metaData = payload && payload.metadataVersion ? payload.metadataVersion : [];
      const studyId = [];
      const studyVersion = [];
      const studyMetadataVersion = [];
      metaData.forEach((item) => {
        const data = item.split(',');
        studyId.push(data[0]);
        studyVersion.push(data[1]);
        studyMetadataVersion.push(data[2]);
      });
      const newPayload = {
        ...payload,
        studyId,
        studyVersion,
        studyMetadataVersion
      };
      state.studyObj = newPayload;
    },
    clearStudyLibrarySources(state) {
      state.studyObj.source = [];
    },
    setDisableFinishButton(state, { payload }) {
      state.disableFinishButton = payload;
    },
    setDataSourceTrailValues(state, { payload }) {
      state.dataSourceTrailValues = payload;
    },
    setDisableAddSQLButton(state, { payload }) {
      state.disableAddSQLButton = payload;
    },
    setSilentProperties(state, { payload }) {
      state.silentProperties = payload;
    },
  },
 /* comment out this section for timebeing not using currenty */
  /*
  extraReducers: (builder) => {
    builder.addCase(GetStudyLibraryType.fulfilled, (state, { payload }) => {
      //   state.studyLibraryType = payload;
    });
    builder.addCase(GetStudyLibraryType.pending, (state) => {
      //   state.loading = true;
    });
    builder.addCase(GetStudyLibraryType.rejected, (state, action) => {

      //   state.error = action.error.message;
    });

    builder.addCase(GetStudyLibrarySource.fulfilled, (state, { payload }) => {
      //   state.studyLibraryType = payload;
    });
    builder.addCase(GetStudyLibrarySource.pending, (state) => {
      //   state.loading = true;
    });
    builder.addCase(GetStudyLibrarySource.rejected, (state, action) => {
      //   state.error = action.error.message;
    });
  }
  */
});

export const { getStudyFormObject, clearStudyLibrarySources, setDisableFinishButton, setDataSourceTrailValues, setDisableAddSQLButton, setSilentProperties } = AddStudyLibrarySlice.actions;
