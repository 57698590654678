/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import {
  PostGlobalLibraryPage,
  PostGlobalLibrary,
  GetLibraryType,
  GetLibraryCategory
} from '../Service/AddGlobalLibraryService';

export const GlobalLibrarySliceAdd = createSlice({
  name: 'library',
  initialState: {
    globalLibraryPageData: [],
    loading: true,
    error: '',
    success: null,
    status: null,
    addGlobalLibraryFormData: {},
    getLibraryCategory: [],
    getLibraryType: []
  },
  reducers: {
    setAddGlobalLibraryData(state, { payload }) {}
  },
  extraReducers: (builder) => {
    builder.addCase(GetLibraryCategory.fulfilled, (state, { payload }) => {
      state.getLibraryCategory = payload;
    });
    builder.addCase(GetLibraryCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetLibraryCategory.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(GetLibraryType.fulfilled, (state, { payload }) => {
      state.getLibraryType = payload;
    });
    builder.addCase(GetLibraryType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetLibraryType.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(PostGlobalLibraryPage.fulfilled, (state, action) => {
      state.success = action.payload.data.success;
    });
    builder.addCase(PostGlobalLibraryPage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PostGlobalLibraryPage.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(PostGlobalLibrary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PostGlobalLibrary.rejected, (state, action) => {
      state.error = action.error.message;
    });
  }
});

export const { setAddGlobalLibraryData } = GlobalLibrarySliceAdd.actions;