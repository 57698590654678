/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetGlobalRulesets = createAsyncThunk(
  'GlobalLibraryPage/GetGlobalRulesets',
  async (flag = false, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/api/Library/GetGlobalRulesets?IsActive=${flag}`);
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const GetAllRuleSets = createAsyncThunk(
  'RuleEditor/GetAllRuleSets',
  async ({ protocol, ExcludeGlobal } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/api/RuleEditor/GetAllRuleSets`, {
        params: {
          IsActive: false,
          protocol,
          ExcludeGlobal
        }
      });
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const GetAssignedRulesetsData = createAsyncThunk(
  'RuleEditor/GetAssignedRulesetsData',
  async (mappingRuleVersionID, { rejectWithValue }) => {
    try {
      console.log('GetAssignedRulesetsData');
      return await axios.get(
        `${API_URL}/api/RuleEditor/GetAssignedRulesetsData?MappingRuleVersionID=${mappingRuleVersionID}`
      );
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const SaveAssignedRulesetsData = createAsyncThunk(
  'RuleEditor/SaveAssignedRulesetsData',
  async (payload, { rejectWithValue }) => {
    try {
      return await axios.post(`${API_URL}/api/RuleEditor/SaveAssignedRulesetsData`, payload);
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);
