/*eslint-disable */
import React, { useContext, useMemo, useState } from 'react';
import { MasteringRulesContext } from '../../MasteringRulesProvider';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { createStringSearchFilter } from 'apollo-react/components/Table';

import Table from 'apollo-react/components/Table';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Notification } from 'Components/Common/Notification';
import Tooltip from 'apollo-react/components/Tooltip';
import Button from 'apollo-react/components/Button';

import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';

import Typography from 'apollo-react/components/Typography';
import { getSourceName } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { SaveVariableMappingRules } from 'Redux/Service/MasteringRuleService';
import { unwrapResult } from '@reduxjs/toolkit';
import { MASTERING_TYPE_MAPPING } from '../../MasteringRules';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import CustomModal from 'Components/Modal';
import Cookies from 'js-cookie';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';
import Autocomplete from 'apollo-react/components/Autocomplete';
import useDisplayName from 'Utils/useDisplayName';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto',
    '&>div': {
      width: '100%'
    }
  }
});
const VISIT_SETTING = 'Visit Setting';
const CDR_TABULAR = 'CDR Tabular';

const checkIsDisabled = (edcDefaultForms, cdrDefaultForms, parentNodeName) => {
  if (parentNodeName === VISIT_SETTING) {
    let message =
      edcDefaultForms[VISIT_SETTING].length > 0 ||
      Object.values(cdrDefaultForms[VISIT_SETTING]).flat().length > 0
        ? ''
        : 'Atleast one source form should be selected to proceed.';

    const edcSaved =
      !edcDefaultForms._edcDefaultForms ||
      JSON.stringify(edcDefaultForms._edcDefaultForms[VISIT_SETTING].sort()) ===
        JSON.stringify(edcDefaultForms[VISIT_SETTING].sort());

    const cdrSaved =
      !cdrDefaultForms._cdrDefaultForms ||
      JSON.stringify(
        Object.values(cdrDefaultForms._cdrDefaultForms[VISIT_SETTING]).flat().sort()
      ) === JSON.stringify(Object.values(cdrDefaultForms[VISIT_SETTING]).flat().sort());

    return [(edcSaved && cdrSaved) || Boolean(message), message];
  } else {
    return [
      !edcDefaultForms._edcDefaultForms ||
        edcDefaultForms._edcDefaultForms[parentNodeName] === edcDefaultForms[parentNodeName]
    ];
  }
};

const SaveButton = ({
  libraryType,
  onSaveDefaultForms,
  edcDefaultForms,
  cdrDefaultForms,
  parentNodeName
}) => {
  let [isDisabled, disabledMessage] = checkIsDisabled(
    edcDefaultForms,
    cdrDefaultForms,
    parentNodeName
  );

  return (
    <Tooltip title={disabledMessage || 'Save'} disableFocusListener>
      <span>
        <Button
          disabled={isDisabled}
          size="small"
          variant="primary"
          style={{ color: 'white !important' }}
          onClick={onSaveDefaultForms}>
          Save
        </Button>
      </span>
    </Tooltip>
  );
};

const useSelectStyles = makeStyles({
  select: {
    margin: '0',
    '& .MuiInputBase-root': {
      maxWidth: '400px !important',
      minWidth: '200px !important'
    }
  }
});

const DefaultFormSelect = ({ row, column: { accessor } }) => {
  const classes = useSelectStyles();
  const { libraryType, parentNodeName } = row;
  console.log('row.forms', libraryType, row.forms, row[accessor]);
  if (
    libraryType !== 'CDISC ODM' &&
    (!libraryType.startsWith(CDR_TABULAR) || parentNodeName !== VISIT_SETTING)
  )
    return (
      <Tooltip title={row[accessor][parentNodeName]}>
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959'
          }}>
          {row[accessor][parentNodeName]}
        </Typography>
      </Tooltip>
    );
  return (
    <Autocomplete
      className={classes.select}
      value={
        row.libraryType === 'CDISC ODM'
          ? Array.isArray(row[accessor][parentNodeName])
            ? row[accessor][parentNodeName]?.map((form) => ({ label: form }))
            : { label: row[accessor][parentNodeName] }
          : row[accessor][parentNodeName][row.libraryName]?.map((form) => ({ label: form }))
      }
      source={row.forms?.map((form) => ({ label: form }))}
      size="small"
      limitChips={2}
      alwaysLimitChips
      filterSelectedOptions={false}
      multiple={parentNodeName === VISIT_SETTING}
      disableClearable={true}
      onChange={(e, value) =>
        Array.isArray(value)
          ? row?.onDefaultFormChange(
              value.map((_form) => _form.label),
              row
            )
          : row?.onDefaultFormChange(value?.label, row)
      }
      showSelectAll={parentNodeName === VISIT_SETTING}
      placeholder={parentNodeName === VISIT_SETTING ? 'Select Source Form' : 'Select Default Form'}>
      {/* {row.forms?.map((form, index) => (
          <MenuItem key={form + index} value={form}>
            {form}
          </MenuItem>
        ))} */}
    </Autocomplete>
  );
};

const columns = [
  {
    header: 'Source Name',
    accessor: 'sourceName',
    filterFunction: createStringSearchFilter('sourceName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Library Type',
    accessor: 'libraryTypeDisplayName',
    filterFunction: createStringSearchFilter('libraryTypeDisplayName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Library Name',
    accessor: 'libraryName',
    filterFunction: createStringSearchFilter('libraryName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Default Form',
    accessor: 'selectedForm',
    customCell: DefaultFormSelect,
    maxWidth: '300px'
  }
];

const getModifiedColumns = (parentNodeName) => {
  const _columns = [...columns];
  if (parentNodeName === VISIT_SETTING) {
    _columns[3].header = 'Source Form';
    _columns.splice(0, 1);
  } else _columns[3].header = 'Default Form';
  return _columns;
};

const DefaultFormSettings = (props) => {
  const classes = useStyles();
  const contextData = useContext(MasteringRulesContext);
  const {
    parentNodeName,
    expressions,
    edcDefaultForms,
    setEDCDefaultForms,
    cdrDefaultForms,
    setCDRDefaultForms
  } = contextData;

  const { defaultFormMapping, getVariableMappingRules } = props;
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { id: mappingRuleVersionID } = useParams();
  const [confirmAlertEnabled, showConfirmAlert] = useState(false);
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();

  const userId = Cookies.get('user.id');

  const onSaveDefaultForms = async () => {
    const payload = { itemMappingRules: [] };
    let newRowSeq;
    defaultFormMapping.forEach(({ libraryName, libraryType, rowSeq, selectedForm, forms }) => {
      newRowSeq = newRowSeq || Math.max(...Object.values(rowSeq), 0) + 1;
      if (parentNodeName === VISIT_SETTING && libraryType === 'CDISC ODM') {
        const odmVisitSettingsPayload = selectedForm[parentNodeName]
          .filter((form) => forms.includes(form))
          .map((form, index) => {
            return {
              iqCreateDate: new Date().toISOString(),
              iqUpdateDate: new Date().toISOString(),
              iqCreatedBy: userId,
              iqUpdatedBy: userId,
              iqAuditType: 'INSERT',
              iqAuditDate: new Date().toISOString(),
              iqActiveFlag: true,
              protocolNumber: protocol.protocolNumber,
              mappingRuleVersionId: mappingRuleVersionID,
              variableName: 'Q_ROW_CONDITION',
              rowName: `CDISC ODM_${form}`,
              sourceName: getSourceName(libraryType),
              sourceSYSID: 0,
              domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
              ruleSeq: 0,
              expression: '"$NOMAP"',
              concatenate: 'N',
              rowSeq: rowSeq[`CDISC ODM_${form}`] || newRowSeq++,
              isSuppqual: 'N',
              defaultForm: form,
              isMapRowActive: true
            };
          });
        payload.itemMappingRules.push(...odmVisitSettingsPayload);
      } else if (parentNodeName === VISIT_SETTING && libraryType.startsWith(CDR_TABULAR)) {
        const cdrVisitSettingsPayload = [];
        selectedForm[parentNodeName][libraryName]?.map((form, index) => {
          if (forms.includes(form)) {
            cdrVisitSettingsPayload.push({
              iqCreateDate: new Date().toISOString(),
              iqUpdateDate: new Date().toISOString(),
              iqCreatedBy: userId,
              iqUpdatedBy: userId,
              iqAuditType: 'INSERT',
              iqAuditDate: new Date().toISOString(),
              iqActiveFlag: true,
              protocolNumber: protocol.protocolNumber,
              mappingRuleVersionId: mappingRuleVersionID,
              variableName: 'Q_ROW_CONDITION',
              rowName: `CDR Tabular_${form}`,
              sourceName: getSourceName(libraryType),
              sourceSYSID: 0,
              domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
              ruleSeq: 0,
              expression: '"$NOMAP"',
              concatenate: 'N',
              rowSeq: rowSeq[`CDR Tabular_${form}`] || newRowSeq++,
              isSuppqual: 'N',
              defaultForm: form,
              isMapRowActive: true
            });
          }
        });

        payload.itemMappingRules.push(...cdrVisitSettingsPayload);
      } else {
        payload.itemMappingRules.push({
          iqCreateDate: new Date().toISOString(),
          iqUpdateDate: new Date().toISOString(),
          iqCreatedBy: userId,
          iqUpdatedBy: userId,
          iqAuditType: 'INSERT',
          iqAuditDate: new Date().toISOString(),
          iqActiveFlag: true,
          protocolNumber: protocol.protocolNumber,
          mappingRuleVersionId: mappingRuleVersionID,
          variableName: 'Q_ROW_CONDITION',
          rowName:
            libraryType === 'CDR Tabular'
              ? `CDR Tabular_${selectedForm[parentNodeName]}`
              : libraryType === 'CDISC ODM'
              ? `CDISC ODM_${selectedForm[parentNodeName]}`
              : libraryType,
          sourceName: getSourceName(libraryType),
          sourceSYSID: 0,
          domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
          ruleSeq: 0,
          expression: '"$NOMAP"',
          concatenate: 'N',
          rowSeq:
            libraryType === 'CDISC ODM'
              ? rowSeq[`CDISC ODM_${selectedForm[parentNodeName]}`] || newRowSeq++
              : rowSeq,
          isSuppqual: 'N',
          defaultForm: selectedForm[parentNodeName],
          isMapRowActive: true
        });
      }
    });
    const saveExpression = await dispatch(SaveVariableMappingRules(payload)).then(unwrapResult);

    if (saveExpression && saveExpression.data.success) {
      dispatch(showBanner({ variant: 'success', message: saveExpression.data.message }));
      setEDCDefaultForms((defForms) => {
        const _defForms = { ...defForms, saved: true, _edcDefaultForms: undefined };
        return _defForms;
      }, getVariableMappingRules);
    } else {
      dispatch(showBanner({ variant: 'error', message: saveExpression.data.message }));
    }
    showConfirmAlert(false);
  };

  const rowData = useMemo(() => {
    const edcRows = [];
    const otherRows = [];

    const cdrData = {};

    defaultFormMapping.forEach((_row) => {
      if (_row.libraryType === CDR_TABULAR && parentNodeName === VISIT_SETTING) {
        if (cdrData[_row.libraryName]) {
          cdrData[_row.libraryName].forms.push(..._row.forms);
        } else {
          cdrData[_row.libraryName] = {
            ..._row,
            forms: [..._row.forms],
            parentNodeName,
            cdrDefaultForms,
            key: _row.libraryType + _row.libraryName,
            onDefaultFormChange: (value, row) => {
              if (
                JSON.stringify(value.sort()) ===
                JSON.stringify(cdrDefaultForms[parentNodeName][row.libraryName]?.sort())
              )
                return;

              setCDRDefaultForms((_cdrDefaultForms) => {
                const modifiedFormSelection = {
                  ..._cdrDefaultForms[parentNodeName],
                  [row.libraryName]: value
                };
                return {
                  ..._cdrDefaultForms,
                  [parentNodeName]: modifiedFormSelection,
                  saved: false,
                  _cdrDefaultForms: _cdrDefaultForms._cdrDefaultForms || _cdrDefaultForms
                };
              });
            }
          };
        }
      } else if (_row.libraryType === 'CDISC ODM') {
        edcRows.push({
          ..._row,
          parentNodeName,
          edcDefaultForms,
          onDefaultFormChange: (value) => {
            if (
              parentNodeName === VISIT_SETTING
                ? JSON.stringify(value.sort()) ===
                  JSON.stringify(edcDefaultForms[parentNodeName].sort())
                : value === edcDefaultForms[parentNodeName]
            )
              return;

            setEDCDefaultForms((_edcDefaultForms) => {
              return {
                ..._edcDefaultForms,
                [parentNodeName]: value,
                saved: false,
                _edcDefaultForms: _edcDefaultForms._edcDefaultForms || _edcDefaultForms
              };
            });
          },
          key: _row.libraryType + _row.libraryName + _row.sourceName
        });
      } else {
        otherRows.push({
          ..._row,
          parentNodeName,
          key: _row.libraryType + _row.libraryName + _row.sourceName
        });
      }
    });
    otherRows.sort((rowA, rowB) => {
      if (rowA.libraryType === CDR_TABULAR) {
        if (rowB.libraryType === CDR_TABULAR) return 0;
        else {
          return -1;
        }
      } else return +1;
    });
    return [...edcRows, ...Object.values(cdrData), ...otherRows].map((_row) => ({
      ..._row,
      libraryTypeDisplayName: _row.cdrTabularLibTypeDisplayName
        ? getDisplayName(_row.cdrTabularLibTypeDisplayName)
        : getDisplayName(_row.libraryType)
    }));
  }, [defaultFormMapping, parentNodeName]);

  const hideNotification = useMemo(() => {
    return !rowData.some((_row) => _row.libraryType === 'CDISC ODM');
  }, [rowData]);

  return (
    <>
      <Box flex flexDirection={'column'} style={{ width: '100%' }}>
        <Table
          classes={classes}
          title={
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}>
              <span>Default Form by Source</span>
              <SaveButton
                onSaveDefaultForms={() => showConfirmAlert(true)}
                edcDefaultForms={edcDefaultForms}
                cdrDefaultForms={cdrDefaultForms}
                parentNodeName={parentNodeName}
              />
            </Box>
          }
          subtitle={
            <Notification
              hide={hideNotification}
              variant={'warning'}
              message={
                parentNodeName === VISIT_SETTING
                  ? 'To access visit variables you must select a source form'
                  : 'To access subject variables you must select a default form for CDISC-ODM Source'
              }
            />
          }
          columns={getModifiedColumns(parentNodeName)}
          rows={rowData}
          rowId="value"
          rowsPerPageOptions={[5, 10, 15, 'All']}
          tablePaginationProps={{
            labelDisplayedRows: DisplayedRowsLabel,
            truncate: true
          }}
          maxHeight={650}
          fullWidth
          hasScroll
          showFilters={false}
          CustomHeader={() => <></>}
        />
      </Box>

      <CustomModal
        display={confirmAlertEnabled}
        title={
          <Box display={'flex'} flexDirection={'row'}>
            <StatusExclamationIcon />
            <span style={{ marginLeft: '0.5rem' }}>{'Save source form selection'}</span>
          </Box>
        }
        message={
          'Are you sure you want to update the source form selection? All expressions and configuration associated with the unselected source forms will be lost.'
        }
        variant={'warning'}
        buttonPrimaryLabel={'Save'}
        handlePrimaryAction={onSaveDefaultForms}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => showConfirmAlert(false)}
      />
    </>
  );
};

export default DefaultFormSettings;
