import { useState } from 'react';
import Tooltip from 'apollo-react/components/Tooltip';
import { Typography } from '@mui/material';

export const compareStrings = (accessor, sortOrder) => {
  return function (rowA, rowB) {
    let stringA = rowA[accessor] ? rowA[accessor].toString().toUpperCase() : '';
    let stringB = rowB[accessor] ? rowB[accessor].toString().toUpperCase() : '';
    if (sortOrder === 'asc') {
      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      return 0;
    } else {
      if (stringA < stringB) {
        return 1;
      }
      if (stringA > stringB) {
        return -1;
      }
      return 0;
    }
  };
};

export const iconDisplay = {
  height: "17px", width: "15px", padding: "2px 2px 0 0"
}
export const warningIconDisplay = {
  height: "15px", width: "15px", padding: "2px 2px 0 0"
}

export const domainDisplayBesideIcon = {
  position: "absolute", left: "38px", top: "11px"
}

export const EllipsisTextCell = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <span
      key={row.key}
      style={{
        whiteSpace: 'nowrap',
        width: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
      <Tooltip key={row.key} variant="light" title={row.domainName} placement="bottom" open={open}>
        <Typography
          style={{ color: '#595959', fontSize: '14px' }}
          noWrap
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {row.domainName}
        </Typography>
      </Tooltip>
    </span>
  );
};
