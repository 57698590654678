/* eslint-disable */
import { ErrorConstants } from './ErrorConstants';
const errors = [];
let uniqueObjects = {};

const {
  trialVisitUniqueError,
  flagValueError,
  numberError,
  mandatoryError,
  blankError,
  referencePointError
} = ErrorConstants;
const allowedValues = ['yes', 'no'];

const checkUnique = (record) => {
  let value = `${record.visit?.toString().toLowerCase()}-${record.visitnum
    ?.toString()
    .toLowerCase()}-${record.tvgrpid?.toString().toLowerCase()}-${record.tvclrid
    ?.toString()
    .toLowerCase()}-${record.armcd?.toString().toLowerCase()}-${record.arm
    ?.toString()
    .toLowerCase()}`;

  if (Object.keys(uniqueObjects).length > 0 && uniqueObjects[value]) {
    return {
      visit: { unique: trialVisitUniqueError },
      visitnum: { unique: trialVisitUniqueError },
      tvgrpid: { unique: trialVisitUniqueError },
      tvclrid: { unique: trialVisitUniqueError },
      armcd: { unique: trialVisitUniqueError },
      arm: { unique: trialVisitUniqueError }
    };
  } else {
    uniqueObjects[value] = true;
    return {};
  }
};

const checkFlagValue = (record) => {
  const { tvblflg, tvupflg } = record;
  let obj = {};
  if (!allowedValues.includes(tvblflg.toLowerCase())) {
    obj.tvblflg = { flag: flagValueError };
  }
  if (!allowedValues.includes(tvupflg.toLowerCase())) {
    obj.tvupflg = { flag: flagValueError };
  }

  return obj;
};

const isEmptyString = (value) => {
  if (value === '' || value === null || value === undefined) {
    return true;
  }
  return false;
};

const isNumberAndGreaterThanZero = (value) => {
  if (isEmptyString(value) || (!isEmptyString(value) && !isNaN(value) && value > 0)) {
    return true;
  }
  return false;
};

const checkIsNumber = (record) => {
  const { visitnum, tvpsttol, tvpentol, tvmsttol, tvmentol } = record;
  let obj = {};

  if (!isNumberAndGreaterThanZero(visitnum)) {
    obj.visitnum = { number: numberError };
  }
  if (!isNumberAndGreaterThanZero(tvpsttol)) {
    obj.tvpsttol = { number: numberError };
  }
  if (!isNumberAndGreaterThanZero(tvpentol)) {
    obj.tvpentol = { number: numberError };
  }
  if (!isNumberAndGreaterThanZero(tvmsttol)) {
    obj.tvmsttol = { number: numberError };
  }
  if (!isNumberAndGreaterThanZero(tvmentol)) {
    obj.tvmentol = { number: numberError };
  }
  return obj;
};

const checkMandatory = (record, flag) => {
  const { visit, visitnum, visitdy, tvblflg, tvupflg } = record;
  let obj = {};
  if (flag) {
    if (!visit) {
      obj.visit = { mandatory: mandatoryError };
    }
    if (!visitnum) {
      obj.visitnum = { mandatory: mandatoryError };
    }
    if (!tvblflg) {
      obj.tvblflg = { mandatory: mandatoryError };
    }
    if (!tvupflg) {
      obj.tvupflg = { mandatory: mandatoryError };
    }
  } else {
    if (!visit) {
      obj.visit = { mandatory: mandatoryError };
    }
    if (visitnum !== 0 && !visitnum) {
      obj.visitnum = { mandatory: mandatoryError };
    }
    if (!tvblflg) {
      obj.tvblflg = { mandatory: mandatoryError };
    }
    if (!tvupflg) {
      obj.tvupflg = { mandatory: mandatoryError };
    }
    if (!visitdy) {
      obj.visitdy = { mandatory: mandatoryError };
    }
  }
  return obj;
};

const checkIsBlank = (record) => {
  const { epoch, tvpsttol, tvpentol, tvmsttol, tvmentol, tvrfpnt, visitdy } = record;
  let obj = {};
  if (epoch) {
    obj.epoch = { blank: blankError };
  }
  if (tvpsttol) {
    obj.tvpsttol = { blank: blankError };
  }
  if (tvpentol) {
    obj.tvpentol = { blank: blankError };
  }
  if (tvmsttol) {
    obj.tvmsttol = { blank: blankError };
  }
  if (tvmentol) {
    obj.tvmentol = { blank: blankError };
  }
  if (tvrfpnt) {
    obj.tvrfpnt = { blank: blankError };
  }
  if (visitdy) {
    obj.visitdy = { blank: blankError };
  }
  return obj;
};

const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

const checkIsValidRefPoint = (record, visitName) => {
  if (record.tvrfpnt && !visitName.includes(record.tvrfpnt)) {
    return {
      tvrfpnt: { referencePoint: referencePointError }
    };
  }
};

const plannedVisitMapping = (record, plannedMapping) => {
  let value = `${record.visit}-${record.visitnum}-${record.tvgrpid}-${record.tvclrid}`;
  plannedMapping[value] = record.tvupflg;
};

export const trialVisitValidator = (records) => {
  let errorColumnCount = 0;
  let errorRowsId = [];
  let plannedMapping = {};
  let validVisitNames = records.map((record) => {
    return record.visit;
  });
  validVisitNames.push('"$MAXVISITDY"');
  let error = {};
  records.forEach((record, index) => {
    let obj = {};
    const unique = checkUnique(record);
    const flagValue = checkFlagValue(record);
    const isNumber = checkIsNumber(record);
    let mandatory = {};
    let isBlank = {};
    let validReferencePoint = {};
    if (record.tvupflg.trim().toLowerCase() === 'yes') {
      mandatory = checkMandatory(record, true);
      isBlank = checkIsBlank(record);
    } else {
      mandatory = checkMandatory(record, false);
      validReferencePoint = checkIsValidRefPoint(record, validVisitNames);
    }

    plannedVisitMapping(record, plannedMapping);
    mergeObject(obj, unique);
    mergeObject(obj, flagValue);
    mergeObject(obj, isNumber);
    mergeObject(obj, mandatory);
    mergeObject(obj, isBlank);
    mergeObject(obj, validReferencePoint);
    if (obj && Object.keys(obj).length > 0) {
      errorRowsId.push(record.id);
      errorColumnCount = errorColumnCount + Object.keys(obj).length;
    }

    error[record.id] = obj;
  });

  uniqueObjects = {};

  //count of the error data

  const errorCount = () => {
    let count = 0;
    for (let i = 0; i <= error.length; i++) {
      if (error[i]) {
        count = count + Object.keys(error[i]).length;
      }
    }
    return count;
  };

  const errorKeys = () => {
    let array = [];
    error &&
      error.map((el, index) => {
        if (Object.keys(el).length > 0) {
          array.push(index);
        }
      });

    return array;
  };

  const isColumnValid = (uuid, columnName) => {
    let columnError = '';
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      columnError = columnName && row[columnName] ? row[columnName] : '';
    }
    return columnError;
  };

  const isRowValid = (uuid) => {
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row[uuid]).length > 0) {
      return false;
    }
    return true;
  };

  const getErrorRowId = () => {
    return errorRowsId;
  };

  const getColumnErrorCount = () => {
    return errorColumnCount;
  };

  const isVisitPlanned = (key) => {
    return plannedMapping[key];
  };

  return {
    isColumnValid: isColumnValid,
    isRowValid: isRowValid,
    errorCount: errorCount,
    errorKeys: errorKeys,
    getErrorRowId: getErrorRowId,
    getErrorCount: getColumnErrorCount,
    getIsVisitPlanned: isVisitPlanned
  };
};

export default { trialVisitValidator };
