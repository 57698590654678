/* eslint-disable */
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import ArrowDown from 'apollo-react-icons/ArrowDown';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Select, TextField } from 'Components/Controls';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@mui/styles';
import PreviewTabs from '../Components/PreviewTabs';
import { getStudyFormObject, setDisableFinishButton } from 'Redux/Slice/AddStudyLibrarySlice';
import DataVizCard from 'apollo-react/components/DataVizCard';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import { TreeSelect } from 'Components/@swb/tree-select';
import {
  GetStudyLibraryType,
  GetStudyLibrarySource,
  getDataSetTypes,
  GetStudyLibrarySourceByDataSetType
} from '../../../../Redux/Service/AddStudyLibraryService';
import { getVariableRuleSets } from 'Redux/Service/AddGlobalLibraryService';
import { unwrapResult } from '@reduxjs/toolkit';
import PreviewTable from '../../DataStandardLibraryMain/PreviewStudyTable';
import { showLoader, closeLoader } from 'Redux/Slice/LoaderSlice';
import Loader from 'Components/Loader';
import { showBanner } from 'Redux/Slice/BannerSlice';
import './AddStudyLibrary.css';
import RuleSetEditor from 'Components/RuleSet/RuleSetEditor';
import AddStudyLibrarySDTM from 'Pages/DataStandardLibrary/AddGlobalLibrary/Components/AddStudyLibrarySDTM';
import { useLocation } from 'react-router-dom';
import { ruleSetValidator } from 'Components/RuleSet/ruleSetValidator';
import { SELECT_ALL } from './../constants';
import CustomSqlLibrary from './CustomSqlLibrary';
import { getPreSQLQueries } from 'Redux/Service/PreSQLEditorService';
import PreSQLPreviewTable from './PreSQLNewDataset/PreSQLPreviewTable';
import { Notification } from 'Components/Common/Notification';
import DataSourceTrailName from './DataSourceTrailName';
import { setDataSourceTrailValues } from 'Redux/Slice/AddStudyLibrarySlice';
import { DATA_FLOW_TYPES, DATAFLOWTYPE_PAYLOAD_VALUES } from 'Constants/DataFlowTypes';
import useDisplayName from 'Utils/useDisplayName';
import { DATA_SET_TYPES } from 'Constants/DataSetTypes';

const useStyles = makeStyles({
  label: {
    color: '#444444',
    display: 'block',
    lineHeight: '1.5',
    fontSize: '14px',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '7px',
    height: '100%'
  },
  treeSelect: {
    display: 'flex',
    background: 'white',
    borderRadius: '4px',
    border: '1px solid #d9d9d9',
    position: 'relative',
    marginTop: '5px',
    '&:hover': {
      borderColor: '#0768fd'
    },
    '& div[data-testid="qa-tree_select-list"] div.MuiButtonBase-root.Mui-disabled': {
      pointerEvents: 'auto'
    },
    '& div[data-testid="qa-tree_select-trigger"] div.MuiChip-root.Mui-disabled': {
      pointerEvents: 'auto'
    },
    '& > div:first-child': {
      width: '100%',
      zIndex: '1',
      '& > div': {
        width: '100%',
        background: 'transparent',
        '& > div': {
          border: 'none'
        }
      }
    },
    '& > div:nth-child(2)': {
      zIndex: '1'
    },
    '& svg.MuiChip-deleteIcon': {
      display: ({ hideDeleteIcon }) => (hideDeleteIcon ? 'none' : undefined)
    },
    '& span.MuiChip-label': {
      padding: ({ hideDeleteIcon }) => (hideDeleteIcon ? '7px' : undefined)
    }
  }
});

const AddStudyLibrary = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const editPage = location.pathname === '/data-standard-library/edit-study-library';
  const classes = useStyles({
    hideDeleteIcon: editPage
  });
  const [opened, setOpened] = useState(false);
  const [cdrSourceValues, setCdrSourceValues] = useState([]);
  const selectedProtocol = useSelector((state) => state.StudyLibraryData.protocol);
  const { studyObj } = useSelector((state) => state.AddStudyLibrary);

  const [studySourceValues, setStudySourceValues] = useState([]);
  const [studyLibraryTypeValues, setStudyLibraryTypeValues] = useState([]);
  const [studyLibraryMetaDataVersion, setStudyLibraryMetaDataVersion] = useState([]);
  const [sourcesNotConfigured, setSourcesNotConfigured] = useState(false);
  const PRE_SQL_DATA_SOURCES = ['CDISC ODM', 'CDR Tabular'];

  const [isStudyPreview, setIsPreviewStudy] = useState(false);
  const { studyLibraryRowData } = useSelector((state) => state.EditStudyData);
  const [studyLibrarySourcesResp, setStudyLibrarySourcesResp] = useState([]);
  const [cdrLibrarySource, setCdrLibrarySource] = useState([]);
  const [isAddForm, setIsAddForm] = useState(true);
  const [cdiscData, setCdiscData] = useState([]);
  const [rowsCount, setRowsCount] = useState(10);
  const [sortedColumn, setSortedColumn] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [paginationFilter, setPaginationFilter] = useState([]);
  const [filteredChildArrays, setFilteredChildArrays] = useState([]);
  const [CDRType, setCDRType] = useState('');
  /**
   * CDRDatasetType holds enum(based in backend) value
   * 1 - CDI tabular
   * 2 - eCRF View
   * 3 - CDM Standardized object
   */
  const [CDRDatasetType, setCDRDatasetType] = useState('');

  const [eCRFTrialName, seteCRFTrialName] = useState('');
  const [eCRFTrialNameList, seteCRFTrialNameList] = useState([]);
  const [datasetTypes, setDatasetTypes] = useState([]);

  const [vender, setVender] = useState('');
  const [uploadLibraryRuleSet, setUploadLibraryRuleSet] = useState(
    location?.state?.rulesetData || []
  );
  const [fileUploadInfoRuleSet, setFileUploadInfoRuleSet] = useState([]);
  const [ruleSetValidationResult, setRuleSetValidationResult] = useState({});
  const [formsObj, setFormsObj] = useState({});
  const [codeListDataMapping, setCodeListDataMapping] = useState({});
  const [serviceResult, setServiceResult] = useState({});
  const [errorCount, setErrorCount] = useState(0);

  const studyLibraryData = useSelector(
    (state) => state.StudyLibraryData?.studyLibraries?.data?.studyLibraries
  );
  const [previewPreSQLData, setPreviewPreSQLData] = useState([]);
  const NAME_REQUIRED = 'Name is required';
  const MAX_CHAR_ALLOWED = 'Maximum ${max} characters are allowed';
  const ALPHANUMERIC_ALLOWED = 'Only alphanumeric and -,.,_,(),[] are allowed for Library name';
  const CDR_TABULAR = 'CDR Tabular';
  const RULESET = 'Ruleset';
  const SOURCE_REQUIRED = 'Trial Name is Required';
  const TYPE_REQUIRED = 'Type is required';
  const DATA_SOURCE_REQUIRED = 'Data Source is Required';
  const TRAIL_NAME_REQUIRED = 'Trial Name is Required';
  const METADATA_VERSION_REQUIRED = 'Metadata Version is Required';
  const SELECT_ALL = 'Select All';
  const PRESQL = 'Custom SQL Library';
  const CDR_TRIAL_NAMES = [
    { value: DATA_FLOW_TYPES.TEST, label: DATA_FLOW_TYPES.TEST },
    { value: DATA_FLOW_TYPES.PROD, label: DATA_FLOW_TYPES.PROD }
  ];
  const { dataSourceTrailValues } = useSelector((state) => state.AddStudyLibrary);
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(NAME_REQUIRED)
      .max(50, MAX_CHAR_ALLOWED)
      .matches(/^[a-zA-Z0-9\\(\\[)\]\\ \\._-]+$/g, ALPHANUMERIC_ALLOWED),
    type: yup.string().required(TYPE_REQUIRED).max(50, MAX_CHAR_ALLOWED),
    metadataVersion: yup.array().required(METADATA_VERSION_REQUIRED),
    source: yup.string().required(SOURCE_REQUIRED),
    description: yup.string().max(400, MAX_CHAR_ALLOWED),
    selectedDataSource: yup.string().required(DATA_SOURCE_REQUIRED),
    selectedTrialName: yup.object().required(TRAIL_NAME_REQUIRED)
  });

  const [formValidationSchema, setFormValidationSchema] = useState(validationSchema);
  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      source: '',
      metadataVersion: [],
      description: '',
      isCDRTestFlow: undefined,
      vender: '',
      dataSet: [],
      selectedDataSource: '',
      selectedTrialName: {}
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      const isValid = values.type !== RULESET && checkStudyLibraryValidation();

      if (values.type === CDR_TABULAR) {
        if (cdrSourceValues && cdrSourceValues.length > 0 && !isValid) {
          setIsPreviewStudy(true);
        } else {
          setIsPreviewStudy(false);
        }
      } else if (values.type === RULESET) {
        if (uploadLibraryRuleSet.length !== 0 && !isValid && errorCount === 0) {
          setIsPreviewStudy(true);
        }
      } else if (values.type === PRESQL) {
        setIsPreviewStudy(values?.dataSet?.length > 0);
      } else if (!isValid) {
        setIsPreviewStudy(true);
      }
    }
  });
  const loading = useSelector((state) => state.LoaderData.loading);
  const paginationData = { sortOrder, pageNumber, sortedColumn, rowsCount, paginationFilter };
  const paginationFunctions = {
    setRowsCount,
    setSortOrder,
    setSortedColumn,
    setPageNumber,
    setPaginationFilter
  };

  const checkStudyLibraryValidation = () => {
    let filteredData = studyLibraryData.filter((item) => {
      return item.libraryType === formik.values.type && item.isactive === true;
    });
    let isPresent = false;
    if (formik.values.type === CDR_TABULAR) {
      if (isAddForm) {
        isPresent = filteredData.find((item) => {
          return (
            item.libraryType === formik.values.type &&
            formik.values.source?.includes(item.source) &&
            item.isCDRTestFlow === (CDRType === DATA_FLOW_TYPES.TEST ? true : false)
            // && item.dataSetTypeID === CDRDatasetType
          );
        });
      }
    } else {
      if (isAddForm) {
        isPresent = filteredData.find((item) => {
          return (
            `${selectedProtocol.protocolNumber}${item.libraryType}${item.source}` ===
            `${selectedProtocol.protocolNumber}${formik.values.type}${formik.values.source}`
          );
        });
      } else {
        filteredData = filteredData.filter((item) => {
          return item.libraryID !== studyLibraryRowData.libraryID;
        });
        isPresent = filteredData.find((item) => {
          return (
            `${selectedProtocol.protocolNumber}${item.libraryType}${item.source}` ===
            `${selectedProtocol.protocolNumber}${formik.values.type}${formik.values.source}`
          );
        });
      }
    }
    if (isPresent) {
      dispatch(showBanner({ variant: 'error', message: 'The Study Library Already Exists' }));
      return true;
    } else if (formik.values?.type === PRESQL && formik.values?.dataSet?.length < 1) {
      dispatch(showBanner({ variant: 'error', message: 'No datasets added' }));
    } else {
      return false;
    }
  };
  const [defaultSend, setDefaultSend] = useState([]);
  const getDisplayName = useDisplayName();
  /**
   * setting breadcrumbs for current page & disabling next button on landing
   */
  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Standard Libraries',
          path: '/data-standard-library/study-library'
        },
        {
          title: editPage ? 'Edit Study Library' : 'Add Study Library',
          path: '/data-standard-library/add-study-library'
        }
      ])
    );
    dispatch(setDisableFinishButton(false));
  }, []);

  /**
   * Fetching Study library types on landing
   */
  useEffect(() => {
    dispatch(
      GetStudyLibraryType({
        protocolNumber: selectedProtocol.protocolNumber
      })
    )
      .then(unwrapResult)
      .then((studyLibraryTypesResp) => {
        if (
          studyLibraryTypesResp &&
          studyLibraryTypesResp.data &&
          studyLibraryTypesResp.data.message === 'Success'
        ) {
          let studyLibraryTypes = studyLibraryTypesResp.data.studyLibraryTypes;
          const libraryTypeValues = [];
          for (let key in studyLibraryTypes) {
            libraryTypeValues.push({
              value: key,
              label: getDisplayName(key),
              key: studyLibraryTypes[key]
            });
          }
          setStudyLibraryTypeValues(libraryTypeValues);
          setSourcesNotConfigured(
            !PRE_SQL_DATA_SOURCES.some((source) => studyLibraryTypes[source])
          );
        }
      });
  }, []);

  useEffect(() => {
    const { name, type, dataSet, description } = studyObj;
    const { selectedDataSource, selectedTrialName } = dataSourceTrailValues || {};
    if (
      !location.pathname
        .substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length)
        .startsWith('add')
    ) {
      setIsAddForm(false);
      const {
        libraryName,
        libraryType,
        libraryDescription,
        source,
        metaDataVersion,
        studyId,
        studyVersion
      } = studyLibraryRowData;
      setDefaultSend(source.split(','));
      let metadataVersionValue = '';
      if (libraryType === 'CDISC ODM') {
        const studyVersionValue = studyVersion.split(',');
        const metaDataVersionValue = metaDataVersion.split(',');
        metadataVersionValue = metaDataVersionValue.map((item, i) => {
          return `${studyId},${studyVersionValue[i]},${item}`;
        });
      }
      formik.setFormikState((prevState) => {
        return {
          ...prevState,
          values: {
            name: libraryName,
            type: libraryType,
            isCDRTestFlow: undefined,
            source: libraryType === CDR_TABULAR ? source.split(',') : source,
            metadataVersion: metadataVersionValue ? metadataVersionValue : [],
            description: libraryDescription,
            dataSet: dataSet,
            selectedTrialName: selectedTrialName,
            selectedDataSource: selectedDataSource
          }
        };
      });
      if (libraryType === CDR_TABULAR) {
        setCdrSourceValues(source.split(','));
      }
    }
    if (type === PRESQL) {
      formik.setFormikState((prevState) => {
        return {
          ...prevState,
          values: {
            name: name,
            type: type,
            description: description,
            dataSet: dataSet,
            selectedTrialName: selectedTrialName,
            selectedDataSource: selectedDataSource
          }
        };
      });
    }
  }, [studyObj?.type === PRESQL && studyObj?.dataSet?.length]);

  const getVariableRuleSetsOnEdit = async (studyLibraryRowData) => {
    const variableRuleSets = await dispatch(getVariableRuleSets(studyLibraryRowData)).then(
      unwrapResult
    );
    if (variableRuleSets?.data?.success) {
      setUploadLibraryRuleSet(variableRuleSets.data.variableRulesets);
    } else {
      dispatch(showBanner({ variant: 'error', message: variableRuleSets?.data?.message }));
    }
  };
  const validateRuleSet = () => {
    let count = 0;
    if (uploadLibraryRuleSet.length > 0) {
      const validation = ruleSetValidator(
        uploadLibraryRuleSet,
        codeListDataMapping,
        formsObj,
        serviceResult
      );
      count = validation.getErrorCount();
      setRuleSetValidationResult(validation);
      setErrorCount(count);
    }
    return count;
  };

  useEffect(() => {
    studyLibraryRowData !== null &&
      studyLibraryRowData !== '' &&
      Object.keys(studyLibraryRowData).length > 0 &&
      studyLibraryRowData.libraryID &&
      getVariableRuleSetsOnEdit(studyLibraryRowData);
  }, [studyLibraryRowData]);

  useEffect(() => {
    const { dataSet } = studyObj;
    const dataSetRecord = [];
    const { protocolNumber, sponsor } = selectedProtocol;
    dataSet?.forEach((data) => {
      const { datasetName, queryColumns, source, studyID, isQueryActive } = data;
      if (isQueryActive) {
        const columns = queryColumns.split(',').map((item) => item.trim());
        columns.forEach((column) => {
          dataSetRecord.push({
            protocolNumber: protocolNumber.toString(),
            sponsor: sponsor.toString(),
            datasetName: datasetName.toString(),
            studyID: studyID?.toString(),
            queryColumns: column.toString(),
            source: getDisplayName(source.toString())
          });
        });
      }
    });
    setPreviewPreSQLData(dataSetRecord);
  }, [studyObj?.dataSet]);

  useEffect(() => {
    if (
      formik.values.type !== '' &&
      formik.values.type !== RULESET &&
      formik.values.type !== PRESQL &&
      formik.values.type !== CDR_TABULAR
    ) {
      dispatch(showLoader());
      setStudySourceValues([]);
      setVender('');

      if (isAddForm) {
        formik.setFormikState((prevState) => {
          return {
            ...prevState,
            values: { ...prevState.values, source: '' },
            touched: { ...prevState.touched, source: false }
          };
        });
      }

      dispatch(
        GetStudyLibrarySource({
          protocolNumber: selectedProtocol.protocolNumber,
          type: formik.values.type
        })
      )
        .then(unwrapResult)
        .then((studyLibrarySources) => {
          if (
            studyLibrarySources &&
            studyLibrarySources.data &&
            studyLibrarySources.data.message === 'Success'
          ) {
            dispatch(closeLoader());
            let librarySourceValues = [];
            let metaDataVersionType = [];
            if (formik.values.type === 'Q2LAB') {
              const validationSchema = yup.object({
                name: yup
                  .string()
                  .required(NAME_REQUIRED)
                  .max(50, MAX_CHAR_ALLOWED)
                  .matches(/^[a-zA-Z0-9\\(\\[)\]\\ \\._-]+$/g, ALPHANUMERIC_ALLOWED),
                type: yup.string().required(TYPE_REQUIRED).max(50, MAX_CHAR_ALLOWED),
                metadataVersion: yup.array().required(METADATA_VERSION_REQUIRED),
                source: yup.string().required(SOURCE_REQUIRED),
                description: yup.string().max(400, MAX_CHAR_ALLOWED)
              });
              setFormValidationSchema(validationSchema);
              formik.setFormikState((prevState) => {
                return {
                  ...prevState,
                  values: { ...prevState.values }
                };
              });
              librarySourceValues =
                studyLibrarySources.data.q2LABSources.length > 0
                  ? studyLibrarySources.data.q2LABSources.map((q2lab) => {
                      return q2lab.vendorAssignedName;
                    })
                  : [];
              setVender(
                studyLibrarySources.data.labODSVendorName !== null
                  ? studyLibrarySources.data.labODSVendorName
                  : ''
              );
            } else if (formik.values.type === 'QECG') {
              const validationSchema = yup.object({
                name: yup
                  .string()
                  .required(NAME_REQUIRED)
                  .max(50, MAX_CHAR_ALLOWED)
                  .matches(/^[a-zA-Z0-9\\(\\[)\]\\ \\._-]+$/g, ALPHANUMERIC_ALLOWED),
                type: yup.string().required(TYPE_REQUIRED).max(50, MAX_CHAR_ALLOWED),
                metadataVersion: yup.array().required(METADATA_VERSION_REQUIRED),
                source: yup.string().required(SOURCE_REQUIRED),
                description: yup.string().max(400, MAX_CHAR_ALLOWED)
              });
              setFormValidationSchema(validationSchema);
              formik.setFormikState((prevState) => {
                return {
                  ...prevState,
                  values: { ...prevState.values }
                };
              });
              librarySourceValues =
                studyLibrarySources.data.qecgSources.length > 0
                  ? studyLibrarySources.data.qecgSources.map((qecg) => {
                      return qecg.vendorAssignedName;
                    })
                  : [];
              setVender(
                studyLibrarySources.data.qecgVendorName !== null
                  ? studyLibrarySources.data.qecgVendorName
                  : ''
              );
            } else if (formik.values.type === 'CDISC ODM') {
              const validationSchema = yup.object({
                name: yup
                  .string()
                  .required(NAME_REQUIRED)
                  .max(50, MAX_CHAR_ALLOWED)
                  .matches(/^[a-zA-Z0-9\\(\\[)\]\\ \\._-]+$/g, ALPHANUMERIC_ALLOWED),
                type: yup.string().required(TYPE_REQUIRED).max(50, MAX_CHAR_ALLOWED),
                metadataVersion: yup.array().min(1, METADATA_VERSION_REQUIRED),
                source: yup.string().required(SOURCE_REQUIRED),
                description: yup.string().max(400, MAX_CHAR_ALLOWED)
              });
              setFormValidationSchema(validationSchema);
              formik.setFormikState((prevState) => {
                return {
                  ...prevState,
                  values: { ...prevState.values }
                };
              });
              let getCdiscData =
                studyLibrarySources.data.odmMetedataSources.length > 0
                  ? studyLibrarySources.data.odmMetedataSources
                  : [];
              setCdiscData(getCdiscData && getCdiscData.length > 0 ? getCdiscData : []);
              setVender(getCdiscData && getCdiscData.length > 0 ? getCdiscData[0].vendorName : '');

              librarySourceValues =
                studyLibrarySources.data.odmMetedataSources.length > 0
                  ? studyLibrarySources.data.odmMetedataSources.map((odm) => {
                      return odm.edcTrialName;
                    })
                  : [];

              metaDataVersionType =
                studyLibrarySources.data.odmMetedataSources.length > 0
                  ? studyLibrarySources.data.odmMetedataSources.map((odm) => {
                      return {
                        studyVersion: odm.studyVersion,
                        studyId: odm.edcStudyID,
                        source: odm.edcTrialName,
                        studyVersionOID: odm.studyVersionOID
                      };
                    })
                  : [];

              librarySourceValues = librarySourceValues.length > 0 && [
                ...new Set(librarySourceValues)
              ];
            }
            let sourceOptions = [];
            sourceOptions =
              librarySourceValues && librarySourceValues.length > 0
                ? librarySourceValues.map((source) => {
                    return {
                      value: source,
                      label: source
                    };
                  })
                : dispatch(showBanner({ variant: 'error', message: 'No Source Available' }));
            setStudySourceValues(sourceOptions);
          } else {
            dispatch(closeLoader());
            dispatch(showBanner({ variant: 'error', message: studyLibrarySources.data.message }));
          }
        });
    } else if (formik.values.type === PRESQL) {
      if (!isAddForm && !studyObj.editInProgress) {
        dispatch(showLoader());
        console.log('studyLibraryRowData', studyLibraryRowData);
        let preSQLQueriesData = [];
        dispatch(
          getPreSQLQueries({
            libraryID: studyLibraryRowData.libraryID,
            version: studyLibraryRowData.libraryVersion
          })
        )
          .then(unwrapResult)
          .then((response) => {
            if (response && response.data && response.data.success) {
              response.data?.preSQLQueries?.forEach((query) => {
                preSQLQueriesData.push({
                  queryID: query.queryID,
                  libraryID: query.libraryID,
                  libraryVersion: query.libraryVersion,
                  datasetName: query.datasetName,
                  source: query.source,
                  isAzureEnv: query.isAzureEnv,
                  dataBaseName: query.dataBaseName,
                  trialName: query.trialName,
                  studyID: query.studyID,
                  datasetDescription: query.datasetDescription,
                  queryString: query.queryString,
                  queryType: query.queryType,
                  queryColumns: query.queryColumns,
                  executionOrder: query.executionOrder,
                  isQueryActive: query.isQueryActive,
                  iqActiveFlag: query.iqActiveFlag,
                  iqAuditType: query.iqAuditType,
                  iqAuditDate: query.iqAuditDate,
                  iqCreateBy: query.iqCreateBy,
                  isSavedInDB: true
                });
              });
              dispatch(
                getStudyFormObject({
                  isCDRTestFlow: preSQLQueriesData[0]?.trialName == DATAFLOWTYPE_PAYLOAD_VALUES.TEST ? true : false,
                  name: studyLibraryRowData.libraryName,
                  type: studyLibraryRowData.libraryType,
                  description: studyLibraryRowData.libraryDescription,
                  dataSet: preSQLQueriesData,
                  editInProgress: true
                })
              );
              dispatch(
                setDataSourceTrailValues({
                  selectedDataSource: preSQLQueriesData[0]?.source,
                  selectedTrialName: {
                    trialName: DATA_FLOW_TYPES[preSQLQueriesData[0]?.trialName],
                    studyID: preSQLQueriesData[0]?.studyID
                  }
                })
              );
              formik.setFormikState((prevState) => {
                return {
                  ...prevState,
                  values: {
                    ...prevState.values,
                    selectedDataSource: preSQLQueriesData[0]?.source,
                    selectedTrialName: {
                      trialName: DATA_FLOW_TYPES[preSQLQueriesData[0]?.trialName],
                      studyID: preSQLQueriesData[0]?.studyID
                    }
                  }
                };
              });
            } else {
              dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
            }
          });
        dispatch(closeLoader());
      }
    } else if (formik.values.type === CDR_TABULAR) {
      const validationSchema = yup.object({
        name: yup
          .string()
          .required(NAME_REQUIRED)
          .max(50, MAX_CHAR_ALLOWED)
          .matches(/^[a-zA-Z0-9\\(\\[)\]\\ \\._-]+$/g, ALPHANUMERIC_ALLOWED),
        type: yup.string().required(TYPE_REQUIRED).max(50, MAX_CHAR_ALLOWED),
        metadataVersion: yup.array(),
        description: yup.string().max(400, MAX_CHAR_ALLOWED)
      });
      setFormValidationSchema(validationSchema);
      if (editPage) {
        const { trialName, dataSetTypeID, ecrfTrialName } = studyLibraryRowData;
        setCDRType(trialName);
        setCDRDatasetType(dataSetTypeID);
        seteCRFTrialName(ecrfTrialName);
      }
    } else {
      const validationSchema = yup.object({
        name: yup
          .string()
          .required(NAME_REQUIRED)
          .max(50, MAX_CHAR_ALLOWED)
          .matches(/^[a-zA-Z0-9\\(\\[)\]\\ \\._-]+$/g, ALPHANUMERIC_ALLOWED),
        type: yup.string().required(TYPE_REQUIRED).max(50, MAX_CHAR_ALLOWED),
        // metadataVersion: yup.array(),
        // source: yup.string(),
        description: yup.string().max(400, MAX_CHAR_ALLOWED)
      });
      setFormValidationSchema(validationSchema);
    }
  }, [formik.values.type]);

  useEffect(() => {
    if (formik.values && formik.values.source !== '') {
      const versions = [];

      const { metaDataVersion, studyId, studyVersion } = studyLibraryRowData;
      const studyVersionValue = studyVersion?.split(',') || [];
      let metaDataVersionValue = metaDataVersion?.split(',') || [];
      metaDataVersionValue = metaDataVersionValue.map((item, i) => {
        return `${studyId},${studyVersionValue[i]},${item}`;
      });

      cdiscData.forEach((el) => {
        if (el.edcTrialName === formik.values.source) {
          versions.push({
            value: `${el.edcStudyID + ',' + el.studyVersion + ',' + el.studyVersionOID}`,
            label: el.studyVersionOID,
            disabled:
              editPage &&
              metaDataVersionValue.includes(
                `${el.edcStudyID + ',' + el.studyVersion + ',' + el.studyVersionOID}`
              )
          });
        }
      });

      versions.sort((a, b) => b.label - a.label);
      setStudyLibraryMetaDataVersion(versions);
    }
    if (formik.values.type === CDR_TABULAR) {
      formik.setFormikState((prevState) => {
        return {
          ...prevState,
          values: { ...prevState.values, source: cdrSourceValues }
        };
      });
      if (Array.isArray(formik.values.source) && formik.values.source.length > 0) {
        const { source } = formik.values;
        const result = source?.map((item) => {
          let a = item.split(':');
          return a[a.length - 1];
        });
        formik.values.source = result;
      }
    }
    CDRType === DATA_FLOW_TYPES.TEST
      ? (formik.values.isCDRTestFlow = true)
      : (formik.values.isCDRTestFlow = false);

    let packages = [];
    if (cdrLibrarySource && cdrLibrarySource.length > 0) {
      cdrLibrarySource.forEach((item) => {
        if (item.children.length > 0) packages.push(item.id);
      });
    }
    if (!studyObj?.editInProgress || studyObj.type !== PRESQL)
      dispatch(getStudyFormObject({ ...formik.values, packages: packages }));
    else if (studyObj.type === PRESQL) {
      const { type, dataSet, editInProgress } = studyObj;
      dispatch(
        getStudyFormObject({
          name: formik.values.name,
          type: type,
          dataSet: dataSet,
          isCDRTestFlow:
            dataSet[0]?.trialName?.toUpperCase() == DATAFLOWTYPE_PAYLOAD_VALUES.TEST.toUpperCase()
              ? true
              : false,
          description: formik.values.description,
          editInProgress: editInProgress
        })
      );
    }
  }, [
    formik.values.source,
    formik.values.metadataVersion,
    formik.values.name,
    formik.values.description,
    cdrSourceValues,
    CDRType,
    CDRDatasetType,
    vender
  ]);

  useEffect(() => {
    if (formik.values.type === CDR_TABULAR) {
      (async () => {
        const getDataSetTypesResp = await dispatch(getDataSetTypes()).then(unwrapResult);
        if (getDataSetTypesResp?.data?.success) {
          const cdrDatasetTypes = [];
          getDataSetTypesResp?.data?.cdrDatasetTypes?.forEach((el) => {
            cdrDatasetTypes.push({
              value: el.datasetTypeID,
              label: el.datasetTypeDisplayName
            });
          });
          setDatasetTypes(cdrDatasetTypes);
        }
      })();
    }
  }, [formik.values.type]);

  useEffect(() => {
    if (formik.values.type === CDR_TABULAR && Boolean(CDRDatasetType)) {
      (async () => {
        dispatch(showLoader());
        const studyLibrarySources = await dispatch(
          GetStudyLibrarySourceByDataSetType({
            protocolNumber: selectedProtocol.protocolNumber,
            type: formik.values.type,
            datasetTypeId: CDRDatasetType
          })
        ).then(unwrapResult);
        dispatch(closeLoader());
        if (studyLibrarySources?.data?.message === 'Success') {
          setStudyLibrarySourcesResp(studyLibrarySources);
        } else {
          dispatch(showBanner({ variant: 'error', message: studyLibrarySources?.data?.message }));
        }
      })();
    }
  }, [CDRDatasetType]);

  useEffect(() => {
    console.log('eCRFTrialNameList useEffect ', studyLibrarySourcesResp, CDRType, CDRDatasetType);
    if (studyLibrarySourcesResp?.data?.message === 'Success' && CDRType && CDRDatasetType) {
      let _cdrTabularSources = [];
      if (CDRType === DATA_FLOW_TYPES.TEST && CDRDatasetType === DATA_SET_TYPES.eCRF_VIEW.ID) {
        if (eCRFTrialName) {
          _cdrTabularSources = studyLibrarySourcesResp?.data?.cdrTabularSources.filter(
            (source) => source.ecrfTrialName === eCRFTrialName
          );
        }
        if (!eCRFTrialName || editPage) {
          const _eCRFTrialNames = {};
          studyLibrarySourcesResp?.data?.cdrTabularSources?.forEach((source) => {
            if (CDRType === source.trailName) {
              _eCRFTrialNames[source?.ecrfTrialName] = true;
            }
          });
          seteCRFTrialNameList(
            Object.keys(_eCRFTrialNames).map((_trialName) => ({
              value: _trialName,
              label: _trialName
            }))
          );
          if (!editPage) {
            return;
          }
        }
      } else if (
        CDRDatasetType !== DATA_SET_TYPES.eCRF_VIEW.ID ||
        CDRType !== DATA_FLOW_TYPES.TEST
      ) {
        _cdrTabularSources = studyLibrarySourcesResp?.data?.cdrTabularSources;
      }

      let cdrLibrarySourceValues = [];
      _cdrTabularSources.forEach((item) => {
        const existObj = cdrLibrarySourceValues.find(
          (e) => e.id === item.datapackagename && e.trialName === item.trailName
        );
        if (existObj) {
          existObj.children.push({ id: `${item.datasetname}`, comment: '' });
        } else {
          cdrLibrarySourceValues.push({
            id: item.datapackagename ? item.datapackagename : `${item.datasetname}`,
            comment: '',
            trialName: item.trailName,
            children: item.datapackagename ? [{ id: `${item.datasetname}`, comment: '' }] : []
          });
        }
      });

      setCdrLibrarySource(cdrLibrarySourceValues);
      if (cdrLibrarySourceValues.length <= 0) {
        dispatch(showBanner({ variant: 'error', message: 'No Source Available' }));
      }
    } else {
    }
  }, [CDRType, CDRDatasetType, eCRFTrialName, studyLibrarySourcesResp]);

  const hasValidate = () => {
    const isValid = formik.values.type !== RULESET && checkStudyLibraryValidation();
    if (formik.values.type !== RULESET && isValid) {
      return false;
    }
    if (formik.values.type === CDR_TABULAR) {
      return cdrSourceValues.length > 0 && formik.values.name && !formik.errors.name ? true : false;
    } else if (formik.values.type === RULESET) {
      if (
        !formik.dirty ||
        Object.keys(formik.errors).length > 0 ||
        uploadLibraryRuleSet.some((item) => item.expression === '')
      ) {
        return false;
      }
      let count = validateRuleSet();
      if (count !== 0) {
        return false;
      }
    } else if (!formik.dirty || Object.keys(formik.errors).length > 0) {
      return false;
    } else if (formik.values.type === PRESQL && formik?.values?.dataSet?.length < 1) {
      return false;
    }
    return true;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: formik.handleSubmit,
    hasValidate,
    validateRuleSet,
    getPayload: () => {
      let { source, type } = formik.values;
      let filteredSource =
        type === 'CDR Tabular' ? source.filter((item) => item !== SELECT_ALL) : source;
      if (type === CDR_TABULAR) {
        if (cdrLibrarySource && cdrLibrarySource.length > 0 && Array.isArray(filteredSource)) {
          const sources = cdrLibrarySource.map((item) => {
            return item.children.length > 0 ? item.id : '';
          });
          filteredSource = filteredSource.filter((f) => {
            return !sources.includes(f);
          });
        }
        const result = Array.isArray(filteredSource)
          ? filteredSource.map((item) => {
              let a = item.split(':');
              return a[a.length - 1];
            })
          : [];
        formik.values.source = result;
      }
      if (type === RULESET) {
        return {
          ...formik.values,
          vendor: vender,
          variableRulesets: uploadLibraryRuleSet?.length ? uploadLibraryRuleSet : []
        };
      } else if (type === PRESQL) {
        return {
          ...studyObj,
          dataSourceTrailValues
        };
      } else {
        return {
          ...formik.values,
          vendor: vender,
          CDRDatasetType: CDRDatasetType,
          eCRFTrialName,
          dataSetDisplayName: datasetTypes?.find((el) => el.value === CDRDatasetType)?.label
        };
      }
    },
    handleStepperback: () => {
      setIsPreviewStudy(false);
    }
  }));

  const handleMetaDataVersion = () => {
    formik.setFormikState((prevState) => {
      return {
        ...prevState,
        values: { ...prevState.values, metadataVersion: [] },
        touched: { ...prevState.touched, metadataVersion: false }
      };
    });
  };

  const multiSelectType = () => {
    console.log(CDRType, 'cdrtype', formik.values);
    let CDRFlag = CDRType == DATA_FLOW_TYPES.TEST ? true : false;
    let sourcesEnabled = [];
    console.log('studyLibraryData', studyLibraryData, formik);
    const savedSources = studyLibraryData.reduce((accSources, item) => {
      if (
        item.libraryType === formik.values.type &&
        item.isactive === true &&
        item.isCDRTestFlow === CDRFlag &&
        (CDRDatasetType !== DATA_SET_TYPES.eCRF_VIEW.ID ||
          (item.dataSetTypeID === CDRDatasetType && item.ecrfTrialName === eCRFTrialName))
      ) {
        accSources = accSources.concat(item.source.split(','));
      }
      return accSources;
    }, []);
    console.log('savedSources', savedSources);

    const handleCDRTrialNameType = (e) => {
      const { source, trialName } = studyLibraryRowData;
      const defaultValues = source?.split(',');
      setCDRType(e.target.value);
      editPage && e.target.value === trialName
        ? setCdrSourceValues(defaultValues)
        : setCdrSourceValues([]);
      setFilteredChildArrays([]);
      // setCDRDatasetType('');
      seteCRFTrialName('');
      seteCRFTrialNameList([]);
    };
    const handleCDRDatsetType = (e) => {
      const { source, dataSetTypeID } = studyLibraryRowData;
      const defaultValues = source?.split(',');
      setCDRDatasetType(e.target.value);
      editPage && e.target.value === dataSetTypeID
        ? setCdrSourceValues(defaultValues)
        : setCdrSourceValues([]);
      seteCRFTrialName('');
      seteCRFTrialNameList([]);
    };

    const handleeCRFTrialName = (e) => {
      seteCRFTrialName(e.target.value);
    };

    const getCDRSourceValues = (values, entry) => {
      const { source, trialName } = studyLibraryRowData;
      const defaultValues = source?.split(',');
      let newFilteredArray = [];
      let filteredValues = values.filter((el) => el !== SELECT_ALL);

      /** filteredValues is used when we remove a chip which is already selected */
      /** filteredChildArrays gives the list of elements if
       * 1. the selected event (entry.id) is already present in the array, then we filter it and assign the rest to "newFilteredArray"
       2. the selected event (entry.id) is not present in the array, then we push it to "newFilteredArray"
        */

      if (!values.length && editPage && CDRType === trialName) {
        newFilteredArray = defaultValues;
      } else if (entry === undefined || !values.length) {
        newFilteredArray = values.includes(SELECT_ALL)
          ? filteredValues
          : !values.includes(SELECT_ALL) && editPage && CDRType === trialName
          ? studyLibraryRowData.source.split(',')
          : !values.includes(SELECT_ALL) && cdrSourceValues.includes(SELECT_ALL)
          ? []
          : values.filter(() => true);
      } else if (entry.id !== SELECT_ALL && !editPage) {
        newFilteredArray = values;
      } else if (entry.id !== SELECT_ALL && editPage) {
        newFilteredArray = values.length
          ? values.filter(() => true)
          : studyLibraryRowData.source.split(',');
        const valueNotPresent = source.split(',').filter((i) => !newFilteredArray.includes(i));
        newFilteredArray =
          CDRType === trialName ? [...valueNotPresent, ...newFilteredArray] : newFilteredArray;
      } else if (!values.includes(SELECT_ALL) && entry.id === SELECT_ALL) {
        newFilteredArray = editPage ? studyLibraryRowData.source.split(',') : [];
      } else {
        // newFilteredArray = sourcesEnabled.filter(() => true);
        newFilteredArray = filteredValues.filter(() => true);
      }

      setFilteredChildArrays(newFilteredArray);
      let newArray = newFilteredArray.filter((row) => row !== SELECT_ALL);

      if (values.includes(SELECT_ALL) && values.length !== cdrSourceValues.length) {
        !cdrSourceValues.includes(SELECT_ALL)
          ? setCdrSourceValues([...newFilteredArray, SELECT_ALL])
          : setCdrSourceValues(newArray);
      } else if (!values.includes(SELECT_ALL) && newFilteredArray.includes(SELECT_ALL)) {
        setCdrSourceValues(newArray);
      } else {
        setCdrSourceValues(newFilteredArray);
      }
    };
    console.log('PPPPP', savedSources, cdrLibrarySource);
    const modifiedSources = cdrLibrarySource
      .map((source) => {
        const modifiedChildren = source?.children;
        modifiedChildren?.forEach((child, index) => {
          let childId = child.id.split(':');
          modifiedChildren[index].disabled = savedSources.includes(childId[childId.length - 1]);
        });
        // let sourceId = source && source.id && source.id.split(':');
        return {
          disabled: savedSources.includes(source?.id),
          ...source,
          children: modifiedChildren
        };
      })
      .filter((source) => CDRType === source.trialName);

    // setModifiedSources(modifiedSources);

    modifiedSources.forEach((childSource) => {
      let subSources = childSource.children;
      subSources.length
        ? subSources.forEach((source) => {
            !source.disabled && sourcesEnabled.push(source.id);
          })
        : !childSource.disabled && sourcesEnabled.push(childSource.id);
    });

    modifiedSources.splice(0, 0, {
      id: 'Select All',
      comment: '',
      children: [],
      disabled: sourcesEnabled.length ? false : true
    });

    return (
      <>
        <Select
          id="trialType"
          label={'Data Flow Type'}
          name="trialType"
          data-testid="trialType-input"
          // required
          value={CDRType}
          onChange={(e) => {
            handleCDRTrialNameType(e);
          }}
          fullWidth
          disabled={editPage}
          items={CDR_TRIAL_NAMES}
        />
        <Select
          id="datesetType"
          label={'Data Set Type'}
          name="datasetType"
          data-testid="datasetType-input"
          value={CDRDatasetType}
          onChange={(e) => {
            handleCDRDatsetType(e);
          }}
          fullWidth
          disabled={editPage || !CDRType}
          items={datasetTypes}
        />
        {console.log('eCRFTrialName', eCRFTrialName, eCRFTrialNameList)}
        {CDRType === DATA_FLOW_TYPES.TEST && CDRDatasetType === DATA_SET_TYPES.eCRF_VIEW.ID && (
          <Select
            id="eCRFTrialName"
            label={'eCRF Trial Name'}
            name="eCRFTrialName"
            data-testid="eCRFTrialName-input"
            value={eCRFTrialName}
            onChange={(e) => {
              handleeCRFTrialName(e);
            }}
            fullWidth
            disabled={editPage || !CDRType || !CDRDatasetType}
            items={eCRFTrialNameList}
          />
        )}
        {CDRType &&
          CDRDatasetType &&
          (CDRType !== DATA_FLOW_TYPES.TEST ||
            CDRDatasetType !== DATA_SET_TYPES.eCRF_VIEW.ID ||
            eCRFTrialName) &&
          cdrLibrarySource.length > 0 && (
            <React.Fragment>
              <label className={classes.label}>Data Sets</label>
              <div className={classes.treeSelect} id="check">
                <TreeSelect
                  className="tree-select"
                  entries={modifiedSources}
                  defaultValues={defaultSend}
                  opened={opened}
                  setOpened={(value) => {
                    setOpened(value);
                  }}
                  values={cdrSourceValues}
                  listProps={{ disableBranchNodes: true, dense: false }}
                  onChange={(values, entry) => {
                    getCDRSourceValues(values, entry);
                  }}
                  onDelete={(values) => {
                    getCDRSourceValues(values, {});
                  }}
                />
                <div className={classes.icon}>
                  <ArrowDown
                    fontSize="extraSmall"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpened(!opened)}
                  />
                </div>
              </div>
              {!cdrSourceValues.length > 0 ? (
                <span style={{ color: '#e20000', fontSize: '13px' }}>Data Sets is Required</span>
              ) : null}
            </React.Fragment>
          )}
      </>
    );
  };
  const displayAddStudyLibrary = () => {
    if (!isStudyPreview) {
      return (
        <>
          <Box mt={2}>
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <TextField
                  id="name"
                  label="Name"
                  name="name"
                  inputProps={{ 'data-testid': 'name-input' }}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="body2">Version</Typography>
                <Typography variant="title">
                  {!isAddForm && studyLibraryRowData && studyLibraryRowData.libraryVersion
                    ? studyLibraryRowData.libraryVersion
                    : 'New'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box width={'100%'}>
            <Grid container spacing={2}>
              <Grid item xs={formik.values.type === PRESQL ? 4 : 5}>
                <Select
                  id="type"
                  label="Type"
                  name="type"
                  data-testid="type-input"
                  disabled={!isAddForm}
                  value={formik.values.type}
                  onChange={(e) => {
                    handleMetaDataVersion();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                  fullWidth
                  items={studyLibraryTypeValues}
                  page={'AddStudyLib'}
                />
              </Grid>
              {formik.values.type === PRESQL && (
                <Grid item xs={8}>
                  <DataSourceTrailName formik={formik} isAddForm={isAddForm} />
                </Grid>
              )}
            </Grid>
          </Box>
          {!isAddForm && (
            <Box width={'100%'}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant="body2">Library ID</Typography>
                  <Typography variant="title">
                    {!isAddForm ? studyLibraryRowData.libraryID : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          {formik.values.type !== '' &&
            (formik.values.type === RULESET ? (
              <RuleSetEditor
                isStudyLibrary
                ruleSetValidationResult={ruleSetValidationResult}
                setUploadLibraryRuleSet={setUploadLibraryRuleSet}
                setFileUploadInfoRuleSet={setFileUploadInfoRuleSet}
                uploadLibraryRuleSet={uploadLibraryRuleSet}
              />
            ) : formik.values.type === PRESQL ? (
              <>
                {sourcesNotConfigured && (
                  <Notification
                    variant={'info'}
                    message={
                      'Add SQL Query will be enabled only when either CDISC ODM or CDR Tabular is configured for the Study'
                    }
                  />
                )}
                <CustomSqlLibrary editPage={editPage} sourcesNotConfigured={sourcesNotConfigured} />
              </>
            ) : (
              <AddStudyLibrarySDTM
                formik={formik}
                handleMetaDataVersion={handleMetaDataVersion}
                multiSelectType={multiSelectType}
                CDRTabular={CDR_TABULAR}
                vender={vender}
                studySourceValues={studySourceValues}
                studyLibraryMetaDataVersion={studyLibraryMetaDataVersion}
                editPage={editPage}
              />
            ))}
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <TextField
                id="description"
                label="Description (Optional)"
                name="description"
                data-testid="description-input"
                multiline
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.description)}
                helperText={formik.errors.description}
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (formik.values.type === 'CDISC ODM') {
      return <PreviewTabs location={location.pathname} />;
    } else if (formik.values.type === PRESQL) {
      return (
        <PreSQLPreviewTable
          isPreview
          value={formik.values.name}
          version={studyLibraryRowData.libraryVersion}
          previewPreSQLData={previewPreSQLData}
        />
      );
    } else if (formik.values.type === RULESET) {
      return (
        <RuleSetEditor
          preview
          previewTitle={formik?.values.name}
          uploadLibraryRuleSet={uploadLibraryRuleSet}
        />
      );
    } else {
      return (
        <PreviewTable
          rowDataInfo={formik.values.source}
          paginationData={paginationData}
          paginationFunctions={paginationFunctions}
          data={formik.values.type}
          isTestFlow={CDRType === DATA_FLOW_TYPES.TEST ? true : false}
          CDRDatasetType={CDRDatasetType}
          eCRFTrialName={eCRFTrialName}
          location={location.pathname}
        />
      );
    }
  };
  return (
    <>
      <Box pt={0} pl={3} pr={3}>
        <Typography variant="h3">
          {' '}
          {isAddForm ? 'Add Study Library' : 'Edit Study Library'}
        </Typography>
        {loading ? (
          <DataVizCard>
            <Loader />
          </DataVizCard>
        ) : (
          <>{displayAddStudyLibrary()}</>
        )}
      </Box>
    </>
  );
});

export default AddStudyLibrary;
