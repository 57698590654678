import DataProductSummaryLayout from 'Layout/DataProductSummaryLayout';
import DashboardLayout from '../../Layout/DashboardLayout/index';
import { DashboardPage } from './ComponentDashboard/DashboardPage';
import DataProductSummary from './DataProductSummary/DataProductSummary';
import DataRefreshHistoryPage from './DataRefreshHistory';

const routes = [
  {
    path: '/dashboard',
    component: DashboardPage,
    layout: DashboardLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/dashboard/data-product-pipelines/data-refresh-history',
    component: DataRefreshHistoryPage,
    layout: DashboardLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/manage-data-pipelines/data-refresh-history',
    component: DataRefreshHistoryPage,
    layout: DashboardLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/dashboard/data-product-pipelines',
    component: DataProductSummary,
    layout: DataProductSummaryLayout,
    exact: true,
    authentication: true
  }
];

export default routes;
