/* eslint-disable no-unused-vars */
import { unwrapResult } from '@reduxjs/toolkit';
import Download from 'apollo-react-icons/Download';
import Button from 'apollo-react/components/Button';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Modal from 'apollo-react/components/Modal';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import Loader from 'Components/Loader/Loader';
import Failure from 'Images/status-failure.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectDomainPreviewData } from 'Redux/Service/DomainPreviewService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setCacheFlag } from 'Redux/Slice/DomainPeviewSlice';
import ErrorsDataTable from './ErrorsDataTable';
import MasteredSubjectsTable from './MasteredSubjectsTable';
import { downloadPreviewData } from './utils';
import { handlePayload } from './ValidateSourcePayload';
import { useNavigate, useParams } from 'react-router-dom';
import DomainDataTable from './DomainDataTable';
import Cookies from 'js-cookie';

const SubjectData = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isModel, setIsModel] = useState(false);
  const [errorlist, setErrorList] = useState([]);
  const [masteredData, setMasteredData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadFunction, setReloadFunction] = useState(false);
  const dispatch = useDispatch();
  const { selectedDomainSourceData, selectedSubjects, cacheFlag } = useSelector(
    (state) => state.DomainPreviewData
  );
  const userLoginID = Cookies.get('user.id');
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { selectedSourceData } = useSelector((state) => state.PublishMappingSpecData);
  const mappingRuleVersionID = params.id;

  const { domainCode } = props;

  const downloadPayload = {
    selectedDomainSourceData,
    protocol,
    errorlist,
    subjectData,
    domainCode,
    masteredData,
    rowDataForRuleEditor
  };

  const handleChangeTab = (event, value) => {
    setValue(value);
  };

  const handleReturnButton = () => {
    setIsModel(!isModel);
  };

  const handleClose = () => {
    setIsModel(!isModel);
  };

  const handleMasterData = (data) => {
    if (data.previewSubjectDomainOutputData?.masteredSubjects?.length) {
      const masteredSubjectsData = data.previewSubjectDomainOutputData.masteredSubjects.map(
        (item) => {
          return {
            usubjid: item.usubjid,
            locators: item.locators,
            sex: item.sex,
            groupId: item.grouP_ID,
            clusterId: item.clusteR_ID,
            brthdtc: item.brthdtc,
            lastUpdated: item.lastUpdateDate
          };
        }
      );
      return masteredSubjectsData;
    } else {
      return [];
    }
  };

  const handleErrorData = (data) => {
    if (data.previewSubjectDomainOutputData?.errorLists?.length) {
      const errorListData = data.previewSubjectDomainOutputData?.errorLists.map((item) => {
        return {
          errorCode: item.errorCode,
          category: item.category,
          message: item.message,
          logType: item.logType,
          isError: item.logType === 'Error' ? 'Yes' : 'No'
        };
      });
      return errorListData;
    } else {
      return [];
    }
  };

  const handleSubjectLocatorData = (data) => {
    const noData = [undefined, null, ''];

    if (!noData.includes(data.previewSubjectDomainOutputData?.subjectLocatorJson)) {
      return JSON.parse(data.previewSubjectDomainOutputData?.subjectLocatorJson);
    } else {
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const payload = handlePayload(
        selectedDomainSourceData,
        selectedSubjects,
        domainCode,
        rowDataForRuleEditor.productNnemonic,
        userLoginID,
        selectedSourceData
      );
      payload.useCache = cacheFlag;
      const responseData = await dispatch(getSubjectDomainPreviewData(payload)).then(unwrapResult);
      if (responseData.data.success) {
        const data = JSON.parse(JSON.stringify(responseData.data));
        setMasteredData(handleMasterData(data));
        setErrorList(handleErrorData(data));
        setSubjectData(handleSubjectLocatorData(data));
        dispatch(setCacheFlag(true));
        setLoading(false);
      } else {
        dispatch(showBanner({ variant: 'error', message: responseData.data.message }));
        setErrorList(handleErrorData(responseData.data));
        setLoading(false);
      }
    })();
  }, [reloadFunction]);

  const renderTab = (props) => {
    return (
      <span style={{ display: 'flex', flexDirection: 'row' }} {...props}>
        {errorlist?.length > 0 && (
          <img src={Failure} style={{ height: '15px', marginRight: '5px' }} />
        )}
        <span>{props.children}</span>
      </span>
    );
  };

  const renderParentTabs = (errorCount) => {
    let dynamicTabs;
    if (errorCount === 0) {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label="Mastered Subjects" />
          <Tab label="Subject Locators" />
        </Tabs>
      );
    } else {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label="Mastered Subjects" />
          <Tab label="Subject Locators" />
          <Tab label={`Errors (${errorlist.length})`} component={(props) => renderTab(props)} />
        </Tabs>
      );
    }
    return dynamicTabs;
  };

  return (
    <React.Fragment>
      {loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Download
              style={{
                cursor: 'pointer'
              }}
              onClick={() => downloadPreviewData(downloadPayload)}
            />
          </div>
          {renderParentTabs(errorlist.length)}
        </div>
      )}
      <div style={{ marginTop: '10px' }}>
        {value === 0 && !loading && (
          <MasteredSubjectsTable domainCode={domainCode} masteredData={masteredData} />
        )}
        {value === 1 && !loading && (
          <DomainDataTable domainCode={domainCode} domainData={subjectData} />
        )}
        {value === 2 && !loading && (
          <ErrorsDataTable
            ErrorData={errorlist}
            reloadFunction={reloadFunction}
            setReloadFunction={setReloadFunction}
          />
        )}
        <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
          <Modal
            open={isModel}
            variant="warning"
            onClose={() => handleClose()}
            title="Leave Preview?"
            message="Are you sure you want to leave this page?"
            buttonProps={[
              { label: 'Cancel', onClick: () => handleClose() },
              {
                label: 'Ok',
                onClick: () =>
                  navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/mastering-rules`)
              }
            ]}
            id="warning"
          />
          <Button onClick={handleReturnButton} variant="primary" size={'small'} disabled={loading}>
            {'Return to Rule Editor'}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubjectData;
