/* eslint-disable */
import { Box, Grid } from '@mui/material';
import { getAllFormsAndItems, getHtmlString } from 'Utils';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import Button from 'apollo-react/components/Button';
import ClipboardSearch from 'apollo-react-icons/ClipboardSearch';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  row: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderBottom: '1px solid #4441',
    fontSize: '14px'
  },
  column: {
    padding: '1rem'
  },
  domainVariable: { fontWeight: '600' },
  expressionCol: {
    height: '100%',
    paddingBottom: '6px',
    fontWeight: 'bold',
    fontFamily: 'Courier New'
  },
  viewCodelist: {
    width: 'fit-content',
    marginTop: '1rem'
  }
});

const VariableRow = (props) => {
  const {
    variable = '',
    expression = '',
    isRulesetVariable,
    setCodelistEditorDisplay,
    error,
    errorMessage
  } = props;
  const classes = useStyles();
  const [decodeInfo, setDecodeInfo] = useState({});
  const dispatch = useDispatch();

  const handleViewCodelist = () => {
    setCodelistEditorDisplay({
      display: true,
      formItems: getAllFormsAndItems(expression)
    });
  };

  return (
    <Grid container className={classes.row}>
      <Grid xs={3} className={[classes.column, classes.domainVariable]}>
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          {variable}
          {isRulesetVariable ? (
            <Button
              variant="secondary"
              onClick={handleViewCodelist}
              size={'small'}
              className={classes.viewCodelist}
              data-testid="save"
              icon={ClipboardSearch}>
              View Codelist
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Grid>

      <Grid xs={9} className={[classes.column]}>
        <Box className={classes.expressionCol}>
          <CustomeTextField
            id="expression"
            name="expression"
            data-testid="expression-input"
            disabled={true}
            customProps={{
              style: {
                height: '100%',
                backgroundColor: '#F2F2F2',
                border: '1px solid #D9D9D9',
                borderRadius: '4px',
                whiteSpace: 'pre-line'
              },
              placeholder: 'abc'
            }}
            error={error}
            helperText={error ? errorMessage : null}>
            {getHtmlString(`${expression}`, [], '', 3)}
          </CustomeTextField>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VariableRow;
