/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showLoader, closeLoader } from '../Slice/LoaderSlice';
const API_URL = process.env.REACT_APP_API_URL;

export const GetDomainVariables = createAsyncThunk('/domainData/GetDomainVariables', async () => {
  return await axios.get(`${API_URL}/api/Library/GetDomainVariables?libraryVersion=SDTM34`);
});

export const getDataProductGrid = createAsyncThunk(
  'DataProductGridSlice/getDataProductGrid',
  async (protocol, { dispatch, rejectWithValue }) => {
    // dispatch(showLoader());
    try {
      const response = await axios.get(
        `${API_URL}/api/DataProduct/GetDataProducts?ProtocolNumber=${protocol}`
      );
      // dispatch(closeLoader());
      return response;
    } catch (error) {
      // dispatch(closeLoader());
      rejectWithValue([], error);
    }
  }
);
