import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useState } from 'react';
import PreviewTable from '../../DataStandardLibraryMain/PreviewStudyTableRow';

const PreviewTabs = (props) => {
  const [value, setValue] = React.useState(0);
  const [tabName, setTabName] = React.useState('');
  const [tabs, setTabs] = React.useState([]);
  const [rowsCount, setRowsCount] = useState(10);
  const [sortedColumn, setSortedColumn] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [paginationFilter, setPaginationFilter] = useState([]);

  const paginationData = { sortOrder, pageNumber, sortedColumn, rowsCount, paginationFilter };
  const paginationFunctions = {
    setRowsCount,
    setSortOrder,
    setSortedColumn,
    setPageNumber,
    setPaginationFilter
  };

  const handleChangeTab = (event, value) => {
    setRowsCount(10);
    setSortedColumn();
    setPageNumber(0);
    setSortOrder('asc');
    setPaginationFilter([]);
    setValue(value);
    setTabName(event.target.innerText);
  };

  useEffect(() => {
    let tabArr = [];
    if (props && props.data === 'CDISC ODM') {
      tabArr = ['Visits', 'CRF Metadata', 'Code lists', 'Measurement Units'];
      setTabs(tabArr);
      setTabName(tabArr[0]);
    }
  }, [props.data]);

  return (
    <div>
      <Tabs value={value} onChange={handleChangeTab} truncate>
        {tabs &&
          tabs.map((el) => {
            return <Tab label={el} key={el}></Tab>;
          })}
      </Tabs>
      <div style={{ padding: 24 }}>
        {value !== null ? (
          <Typography variant="body2">
            <PreviewTable
              data={tabName}
              paginationData={paginationData}
              paginationFunctions={paginationFunctions}
            />
          </Typography>
        ) : null}
      </div>
    </div>
  );
};
export default PreviewTabs;
