/*eslint-disable*/
import React, { useState } from 'react';
import Button from 'apollo-react/components/Button';
import TextField from 'apollo-react/components/TextField';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

const useStyles = makeStyles({
  subHeaderRow: {
    backgroundColor: '#f8f9fb',
    position: 'sticky',
    top: '120px',
    '&:nth-child(2)': {
      top: '160px'
    },
    zIndex: '2'
  },
  subHeaderActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    marginBottom: '16px'
  }
});

export const useNewRow = (row = {}) => {
  const [newRow, setNewRow] = useState(row);

  const editNewRow = (key, value) => {
    setNewRow((newRow = {}) => {
      const _newRow = { ...newRow };
      _newRow[key] = value;
      delete _newRow.hasError;
      return _newRow;
    });
  };

  return [newRow, setNewRow, editNewRow];
};

const CustomTextField = styled(TextField)(({ hasError }) => ({
  '& .MuiInputBase-input': {
    border: hasError && '1px solid #E20000'
  }
}));

export const CodelistNewRow = (props) => {
  const classes = useStyles();
  const {
    isAdding,
    setIsAdding,
    newRow,
    editNewRow,
    setNewRow,
    onSave,
    onCancel,
    columns,
    textFieldProps = {},
    acionProps = {}
  } = props;

  const onSaveNewRow = async (andAdd = false) => {
    try {
      await onSave();
      setNewRow({});
      setIsAdding(andAdd);
    } catch (error) {
      console.log('Error while saving new row :: ', error);
    }
  };

  const onCancelNewRow = () => {
    if (typeof onCancel === 'function') onCancel();
    setNewRow({});
    setIsAdding(false);
  };

  const isRequiredFilled = columns.every((column) =>
    column.required ? !!newRow[column.accessor] : true
  );

  if (!isAdding) return null;
  return (
    <React.Fragment key={'new-row-group'}>
      <TableRow className={classes.subHeaderRow} key={'new-row'}>
        {columns.map((column, index) => (
          <TableCell key={`datatable-column-item-${index}`}>
            {column?.header && (
              <CustomTextField
                className={column.className}
                component="span"
                inline="true"
                placeholder={column?.placeholder}
                value={newRow[column.accessor] || ''}
                key={column.accessor}
                onChange={(e) => {
                  editNewRow(column.accessor, e.target.value);
                }}
                hasError={['odmCodeListName', 'value'].includes(column.accessor) && newRow.hasError}
                {...textFieldProps}>
                {column.header}
              </CustomTextField>
            )}
            {column?.showAction && column?.customCell ? (
              <column.customCell row={{ ...newRow, ...acionProps }} key={column.accessor} />
            ) : null}
          </TableCell>
        ))}
      </TableRow>
      <TableRow className={classes.subHeaderRow} key={'new-row-actions'}>
        <TableCell colSpan={columns.length}>
          <div className={classes.subHeaderActions}>
            <Button size="small" variant="text" onClick={onCancelNewRow} style={{ marginRight: 8 }}>
              {'Cancel'}
            </Button>
            <Button
              size="small"
              variant="secondary"
              onClick={() => onSaveNewRow(true)}
              style={{ marginRight: 8 }}
              disabled={!isRequiredFilled}>
              {'Save and add another'}
            </Button>
            <Button
              size="small"
              variant="secondary"
              onClick={() => onSaveNewRow(false)}
              disabled={!isRequiredFilled}>
              {'Save Row'}
            </Button>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
