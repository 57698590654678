/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import Grid from 'apollo-react/components/Grid';
import Card from './Card';
import FileUpload from 'apollo-react/components/FileUpload';
import { jsonToExcel, convertExcelToJson, jsonToFileData, uuidv4, getHeaders } from 'Utils';
import { GetProductTimePoints } from 'Redux/Service/ReferenceDataCardsService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { getTrialVisit } from 'Redux/Service/ReferenceDataCardsService';
import { DeleteReferenceData } from 'Redux/Service/ReferenceDataCardsService';
import _ from 'lodash';
import CustomModal from 'Components/Modal';
import { timePointValidator } from 'Validators/TimePoints.Validator';
import CircularProgress from '@mui/material/CircularProgress';
import { trialVisitValidator } from 'Validators/TrialVisits.Validator';
import { GetTimePointsData } from 'Redux/Service/ReferenceDataCardsService';
import { getTimePointsSheet } from '../Utils/CtpSvtUtils';
import './Trials.css';
import { useNavigate, useParams } from 'react-router-dom';
import { setReferenceData } from 'service/reference-data.service';
import useSelectedDatasourcesData from '../../hooks/useSelectedDatasourcesData';
import { checkColumns, checkFileData, checkFileType } from '../Utils/FileValidationUtils';
const timePointsColumn = [
  {
    VISIT: '',
    VISITNUM: '',
    TVGRPID: '',
    TVCLRID: '',
    TPTMPNT: '',
    TPTMNUM: '',
    TPTMREF: '',
    TPUPDES: '',
    TPELTM: '',
    EDC: '',
    QLAB: '',
    QECG: '',
    TABULAR: ''
  }
];

const columns = [
  'VISIT',
  'VISITNUM',
  'TVGRPID',
  'TVCLRID',
  'TPTMPNT',
  'TPTMNUM',
  'TPTMREF',
  'TPUPDES',
  'TPELTM',
  'EDC',
  'QLAB',
  'QECG',
  'TABULAR'
];

const allowedExtensions = ['xlsx', 'xls'];

const TimePoints = (props) => {
  const { id: mappingRuleVersionID } = useParams();
  const {
    data: selectedDataSourcesData,
    loading,
    error: apiError
  } = useSelectedDatasourcesData(mappingRuleVersionID);
  const navigate = useNavigate();
  const [isValidData, setIsValidData] = useState(false);
  const [timePointsData, setTimePointsData] = useState([]);
  const [timePointValidationResult, setTimePointValidationResult] = useState({});
  const [mappingError, setMappingError] = useState('');
  const [trialVisitValidationResult, setTrialVisitValidationResult] = useState({});
  const [trialVisitData, setTrialVisitData] = useState([]);
  const [cards, setCardArray] = useState([]);
  const [dataSourceData, setDataSourceData] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const TIME_POINTS = 'time-points';

  useEffect(() => {
    if (!loading) {
      if (
        selectedDataSourcesData &&
        selectedDataSourcesData.data &&
        selectedDataSourcesData.data.success
      ) {
        setDataSourceData(
          selectedDataSourcesData.data.ruleStudyLibrary.filter((row) => row.isMappingRuleConfigured)
        );
      } else {
        apiError && dispatch(showBanner({ variant: 'error', message: apiError }));
      }
    }
  }, [selectedDataSourcesData, loading]);

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingRuleVersionID
      };
      const trialVisitGetAPIData = await dispatch(getTrialVisit(payload)).then(unwrapResult);
      props.configuration.setIsCompleted(TIME_POINTS, true);
      if (trialVisitGetAPIData && trialVisitGetAPIData.data && trialVisitGetAPIData.data.success) {
        if (
          trialVisitGetAPIData.data.trialVisits &&
          trialVisitGetAPIData.data.trialVisits.length > 0
        ) {
          setError('');
          const newData = trialVisitGetAPIData.data.trialVisits.map((item) => {
            return {
              visit: item.visit,
              visitnum: item.visitnum,
              visitdy: isNull(item.visitdy) ? '' : item.visitdy,
              armcd: item.armcd,
              arm: item.arm,
              epoch: item.epoch,
              tvpsttol: isNull(item.tvpsttol) ? '' : item.tvpsttol,
              tvpentol: isNull(item.tvpentol) ? '' : item.tvpentol,
              tvmsttol: isNull(item.tvmsttol) ? '' : item.tvmsttol,
              tvmentol: isNull(item.tvmentol) ? '' : item.tvmentol,
              tvrfpnt: item.tvrfpnt,
              tvgrpid: item.tvgrpid,
              tvclrid: item.tvclrid,
              tvblflg: item.tvblflg,
              tvenrl: item.tvenrl,
              tvstrl: item.tvstrl,
              tvupflg: item.tvupflg,
              id: uuidv4()
            };
          });
          let validationResult = trialVisitValidator(newData);
          setTrialVisitValidationResult(validationResult);
          setTrialVisitData(newData);
          setIsValidData(true);
          props.configuration.setCardVisibility(TIME_POINTS, true);
        } else {
          setIsValidData(false);
          setTrialVisitData([]);
        }
      } else {
        setIsValidData(false);
        setTrialVisitData([]);
      }
    })();
  }, []);

  const [isEditDisable, setIsEditDisable] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);
  const [error, setError] = useState();
  const [timePointsPopulatedData, setTimePointsPopulatedData] = useState([]);
  const [deleteFile, setDeleteFile] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [timePointsMessage, setTimePointsMessage] = useState('');
  const dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(false);

  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  const isNull = (value) => {
    if (value === null) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['TimePoints']) {
      if (!isEditDisable) {
        const element = document.getElementById('anchor-preview');
        element.click();
        setMappingError(props.createMappingObj['message']);
        props.createMappingObj['TimePoints'] = false;
      }
    }
  }, [isEditDisable]);

  useEffect(() => {
    (async () => {
      setReferenceData('time-points-title', props.title);
      let payload = {
        mappingRuleVersionID: mappingRuleVersionID
      };
      const timePointsData = await dispatch(GetProductTimePoints(payload)).then(unwrapResult);
      if (timePointsData && timePointsData.data && timePointsData.data.success) {
        if (timePointsData.data.timePoints && timePointsData.data.timePoints.length > 0) {
          setError('');
          const newData = timePointsData.data.timePoints.map((item) => {
            return {
              visit: item.visit,
              visitnum: item.vistnum,
              tvgrpid: item.tvgrpid,
              tvclrid: item.tvclrid,
              tpupdes: item.tpupdes,
              tptmref: item.tptmref,
              tptmpnt: item.tptmpnt,
              tptmnum: isNull(item.tptmnum) ? '' : item.tptmnum,
              tpeltm: item.tpeltm,
              edc: item.edc,
              tabular: item.tabular,
              qecg: item.ecg,
              qlab: item.qlims,
              id: uuidv4()
            };
          });
          setTimePointsData(newData);
          let fileObj = jsonToFileData(newData, 'TimePoints.xlsx');
          setSelectedFile([fileObj]);
          setIsEditDisable(false);
          props.configuration.setModuleValidation(TIME_POINTS, true);
        } else {
          props.configuration.setModuleValidation(TIME_POINTS, false);
          setTimePointsData([]);
          setIsEditDisable(true);
        }
      } else {
        props.configuration.setModuleValidation(TIME_POINTS, false);
        setTimePointsMessage(timePointsData.data.message);
        setTimePointsData([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // validate Timespoint visit
      if (timePointsData.length && trialVisitData.length && dataSourceData.length > 0) {
        const validationResult = await timePointValidator(
          timePointsData,
          trialVisitValidationResult,
          dataSourceData,
          trialVisitData
        );
        setTimePointValidationResult(validationResult);
        const errorCountFlag = validationResult.getErrorCount();
        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        props.configuration.setModuleValidation(TIME_POINTS, errorCountFlag > 0 ? false : true);
      }
    })();
  }, [timePointsData, trialVisitValidationResult, trialVisitData, dataSourceData]);

  useEffect(() => {
    if (trialVisitData.length === 0) {
      setIsDisable(true);
      setIsEditDisable(true);
    } else if (timePointsData.length > 0) {
      setIsDisable(false);
      setIsEditDisable(false);
    } else {
      setIsDisable(false);
    }

    const newTimePointData = trialVisitData.map((item) => {
      let newObj = {
        VISIT: item.visit,
        VISITNUM: item.visitnum,
        TVGRPID: item.tvgrpid,
        TVCLRID: item.tvclrid
      };
      return { ...timePointsColumn[0], ...newObj };
    });
    if (newTimePointData.length > 0) {
      setTimePointsPopulatedData(newTimePointData);
    } else {
      setTimePointsPopulatedData(timePointsColumn);
    }
  }, [trialVisitData, timePointsData]);

  const downloadSheet = () => {
    jsonToExcel(timePointsPopulatedData, 'TimePoints.xlsx');
  };

  const handleTimePointsSheetDownload = async () => {
    const timePointsData = await dispatch(
      GetTimePointsData({
        mappingRuleVersionID: mappingRuleVersionID
      })
    ).then(unwrapResult);
    if (timePointsData?.data?.success) {
      const finalTimePointsData = getTimePointsSheet(timePointsData.data);
      jsonToExcel(finalTimePointsData, 'Source Event.xlsx', 'Source Event');
    } else dispatch(showBanner({ variant: 'error', message: timePointsData.data.message }));
  };

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const handleUpload = async (selectedFiles) => {
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID
    };
    setUploadProgress(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJsonData = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);
      const isError = fileValidation(fileJsonData, headers);
      if (!isError) {
        let newFileArr = [];
        fileJsonData.map((obj) => {
          const newObj = { ...timePointsColumn[0], ...obj };
          var lowerObj = _.transform(newObj, function (result, val, key) {
            result[key.toLowerCase()] = val;
          });
          lowerObj.id = uuidv4();
          newFileArr.push(lowerObj);
        });
        // const validationResult = await timePointValidator(
        //   newFileArr,
        //   trialVisitValidationResult,
        //   dataSourceData,
        //   trialVisitData
        // );
        // const errorCountFlag = validationResult.getErrorCount();
        // errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        // setTimePointValidationResult(validationResult);
        setError('');
        setTimePointsData(newFileArr);
        setSelectedFile([selectedFiles[0]]);
        setIsEditDisable(false);
        setReferenceData('time-point', newFileArr);
        // setReferenceData('time-point-validationResult', validationResult);
        navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/time-point`);
      } else {
        setTimePointsData([]);
        setSelectedFile([]);
        setError(isError);
        setIsEditDisable(true);
      }
    }
    setUploadProgress(false);
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'TP',
      mappingRuleVersionID: mappingRuleVersionID
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
      setDeleteFile(false);
      setError('');
      setSelectedFile([]);
      setTimePointsData([]);
      setIsEditDisable(true);
      cardItems.push({ name: 'Time Points', module: TIME_POINTS });
      setCardItems(cardItems);
      const index = cards.findIndex((el) => el === TIME_POINTS);
      props.configuration.setCardVisibility(TIME_POINTS, false);
      cards.splice(index, 1);
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
  };

  const handleEditPreview = (isUploaded) => {
    if (isUploaded || timePointsData) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/time-point`);
    }
  };

  const confirmOnDelete = () => {
    setDeleteFile(true);
    timePointsData.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          onConfirm: () => {
            deleteUploadedFile();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        refMsg={timePointsMessage}
        setErrorFlag={errorFlag}
        cardTitle={props.title}
        required={props.required}
        handleDownload={downloadSheet}
        handleTimePointsSheetDownload={handleTimePointsSheetDownload}
        isEditDisable={isEditDisable}
        handleEditPreview={() => handleEditPreview()}>
        <div style={{ margin: '0px 10px' }}>
          {(uploadProgress || loading) && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={uploadProgress}
              value={selectedFile}
              // disabled={isDisable}
              onUpload={handleUpload}
              // onFileDelete={openDeleteModal}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
              //   {...this.props}
            />
          </div>

          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        // handlePrimaryAction={() => handleDelete()}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </Grid>
  );
};

export default TimePoints;
