/* eslint-disable no-unused-vars */
import { codeListColumnNames, getTypeBasedColumnProps } from '../CodeListUtils';
import { useState, useReducer, useMemo, useCallback, useEffect } from 'react';
import { jsonToExcel, uuidv4, dateFormatByType } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { codeListsValidator } from 'Validators/CodeLists.Validator';
import { getCodeListReferenceData } from 'Services/CodeListReferenceData.Service';
//framework imports
import { useDispatch, useSelector } from 'react-redux';

// UI imports
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Filter from 'apollo-react-icons/Filter';
import Download from 'apollo-react-icons/Download';
import Card from 'apollo-react/components/Card';
import Table from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Switch from 'apollo-react/components/Switch';

// Icon imports
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';

//new imports
import { unwrapResult } from '@reduxjs/toolkit';
import { getCodeLists } from 'Redux/Service/ReferenceDataCardsService';
import CustomModal from 'Components/Modal';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import useGetTitle from '../../../hooks/useGetTitle';
import useSelectedDatasourcesData from '../../../hooks/useSelectedDatasourcesData';
import DatePicker from 'apollo-react/components/DatePicker';
import Loader from 'Components/Loader/Loader';
import DataVizCard from 'apollo-react/components/DataVizCard';
import { DateFilter } from 'Components/Common/DateFilter';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};

const CODE_LIST_VALIDATION_RESULT = 'code-list-validationResult';

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const CustomButtonHeader = ({
  handleStepValue,
  handleShowPreview,
  setIsPreview,
  toggleFilters,
  codeListsData,
  codeListErrorCount,
  errorFlag,
  handleErrorData,
  isPreviewDataUploaded,
  editMode,
  setOpenConfirmModal,
  cardTitle
}) => {
  const params = useParams();
  const mappingId = params.id;
  const navigate = useNavigate();
  const onHandleDownload = () => {
    const codeListApiColumns = Object.values(codeListColumnNames);
    const filteredCols = codeListApiColumns.filter((el) => el !== 'iqUpdateDate');
    let downloadableArray = codeListsData.map((obj) => {
      return {
        ...filteredCols.reduce((acc, item) => {
          acc[codeListColumnNames[item]] = obj[item];
          return acc;
        }, {})
      };
    });
    jsonToExcel(downloadableArray, 'CodeLists.xlsx');
  };

  const returnToReferenceData = () => {
    if (isPreviewDataUploaded) {
      setReferenceData('code-list', []);
      setReferenceData(CODE_LIST_VALIDATION_RESULT, {});
      navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
      setIsPreview(false);
    } else if (codeListErrorCount) {
      navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
      setIsPreview(false);
    } else {
      navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
      setIsPreview(false);
    }
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <div>
          <Button
            icon={<ChevronLeft />}
            size="small"
            onClick={returnToReferenceData}
            style={{
              marginRight: 10,
              marginBottom: '10px',
              marginTop: '10px'
            }}>
            Return to reference data upload
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 600,
                    fontSize: '20px',
                    float: 'left',
                    marginRight: '20px'
                  }}>
                  {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
                </Typography>
                {codeListErrorCount > 0 && (
                  <div
                    style={{
                      backgroundColor: '#E20000',
                      float: 'left',
                      width: '83px',
                      paddingBottom: '2px',
                      paddingLeft: '2px',
                      border: '1px solid white',
                      borderRadius: '4px'
                    }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                      <span
                        style={{
                          color: 'white'
                        }}>
                        <StatusExclamation
                          style={{
                            color: 'white',
                            float: 'left',
                            height: '16px',
                            marginTop: '2px'
                          }}
                        />
                        {`${codeListErrorCount} errors`}
                      </span>
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={9} style={{ marginRight: '4px' }}>
                  {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                </Grid>

                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="secondary" size="small" onClick={toggleFilters}>
                    <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} /> Filter
                  </Button>
                  <Button size="small" onClick={onHandleDownload} style={{ marginLeft: '0.5rem' }}>
                    <Download style={{ color: 'primary' }} />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <hr style={{ margin: '22px 0px' }} />
        <div
          style={{
            display: 'flex',
            justifyContent: `${codeListErrorCount > 0 ? 'space-between' : 'end'}`,
            flexDirection: 'row'
          }}>
          {codeListErrorCount > 0 && (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
                variant="subtitle"
                gutterBottom>
                {'Show only rows with errors'}
              </Typography>
              <Switch size={'small'} checked={errorFlag} onChange={(e) => handleErrorData(e)} />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

const CodeListsPreview = () => {
  const params = useParams();
  const mappingId = params.id;
  const {
    data: selectedDataSourcesData,
    loading,
    apiError
  } = useSelectedDatasourcesData(mappingId);
  const { cardTitle } = useGetTitle('code-lists');
  const [codeListsData, setCodeListsData] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [codeListsValidationResult, setCodeListsValidationResult] = useState({});
  const [edcOdmInfo, setEdcOdmInfo] = useState({});
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [codeListErrorCount, setCodeListErrorCount] = useState({});
  const [codeListErrorOnRecords, setCodeListErrorOnRecords] = useState({});
  const [dataSourceData, setDataSourceData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [errorFlag, handleErrorData] = useReducer((state, e) => {
    //eslint-disable-line
    return e.target.checked;
  }, false);
  const [codeListMessage, setCodeListMessage] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const editMode = editedRows.length > 0;

  useEffect(() => {
    (async () => {
      if (!isLoading) {
        if (
          selectedDataSourcesData &&
          selectedDataSourcesData.data &&
          selectedDataSourcesData.data.success
        ) {
          setDataSourceData(
            selectedDataSourcesData.data.ruleStudyLibrary.filter(
              (row) => row.isMappingRuleConfigured
            )
          );
          if (getReferenceData('code-list-edcOdmInfo')) {
            setEdcOdmInfo(getReferenceData('code-list-edcOdmInfo'));
          } else {
            let isEproInfo = await getCodeListReferenceData(
              selectedDataSourcesData.data.ruleStudyLibrary.filter(
                (row) => row.isMappingRuleConfigured
              ),
              {
                protocolNumber: protocol.protocolNumber,
                neededLibraries: ['CDISC ODM'],
                neededVendors: ['CLINICAL_INK', 'MERGE']
              }
            );
            setEdcOdmInfo(isEproInfo);
          }
        } else {
          apiError && dispatch(showBanner({ variant: 'error', message: apiError }));
        }
      }
    })();
  }, [selectedDataSourcesData, isLoading]);

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingId
      };

      if (getReferenceData('code-list') && getReferenceData('code-list').length > 0) {
        setCodeListsData(getReferenceData('code-list'));
        setCodeListsValidationResult(getReferenceData(CODE_LIST_VALIDATION_RESULT));
        setIsPreviewDataUploaded(true);
        setIsLoading(false);
      } else {
        const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
        if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
          if (
            codeListsGetAPIData.data.ruleCodeLists &&
            codeListsGetAPIData.data.ruleCodeLists.length > 0
          ) {
            const newData = codeListsGetAPIData.data.ruleCodeLists.map((item) => {
              let codeListItem = {};
              for (let label in codeListColumnNames) {
                if (Object.prototype.hasOwnProperty.call(codeListColumnNames, label)) {
                  let apiLabel = codeListColumnNames[label];
                  codeListItem[apiLabel] =
                    apiLabel === 'iqUpdateDate'
                      ? dateFormatByType(item[apiLabel], 'Table')
                      : item[apiLabel];
                }
              }
              codeListItem.id = uuidv4();
              codeListItem.isImported = item.isImported;
              return codeListItem;
            });
            setCodeListsData(newData);
            setIsPreviewDataUploaded(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setCodeListMessage(codeListsData?.data?.message);
          setCodeListsData([]);
        }
      }
    })();
  }, []);
  //for validation of data from api
  useEffect(() => {
    if (edcOdmInfo && Object.keys(edcOdmInfo).length > 0 && codeListsData.length > 0) {
      const validationResult = codeListsValidator(codeListsData, edcOdmInfo);
      setCodeListsValidationResult(validationResult);
    }
  }, [edcOdmInfo, codeListsData]);

  //useEffect for updating errors
  useEffect(() => {
    if (codeListsValidationResult && Object.keys(codeListsValidationResult).length > 0) {
      setCodeListErrorCount(codeListsValidationResult.errorCount);
      setCodeListErrorOnRecords(codeListsValidationResult.errorOnRecords);
    }
  }, [codeListsValidationResult]);

  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      if (
        codeListsValidationResult !== null &&
        codeListErrorOnRecords !== null &&
        codeListsValidationResult !== undefined &&
        codeListErrorOnRecords !== undefined
      ) {
        return Object.keys(codeListsValidationResult).length &&
          Object.keys(codeListErrorOnRecords).length &&
          codeListErrorOnRecords?.[row.id] &&
          codeListErrorOnRecords?.[row.id][key] ? (
          <Tooltip title={codeListErrorOnRecords[row.id][key]}>
            <Typography
              variant="title2"
              style={{
                fontSize: '14px',
                backgroundColor: '#f9cccc',
                color: '#595959',
                padding: '15px 11px'
              }}>
              {typeof row[key] === 'undefined' || row[key].toString().trim() === ''
                ? 'No Data'
                : row[key]}
            </Typography>
          </Tooltip>
        ) : (
          row[key]
        );
      } else {
        return row[key];
      }
    },
    [codeListErrorOnRecords, codeListsValidationResult]
  );

  let columns = useMemo(() => {
    let thisColumns = [];
    for (let displayName in codeListColumnNames) {
      if (Object.prototype.hasOwnProperty.call(codeListColumnNames, displayName)) {
        const accessor = codeListColumnNames[displayName];
        const column_props = codeListColumnNames[accessor + '_props'];
        thisColumns.push({
          header: displayName,
          accessor,
          customCell: EditableCell,
          fixedWidth: false,
          ...getTypeBasedColumnProps({
            type: column_props.type,
            accessor,
            TextFieldFilter,
            IntegerFilter,
            DateFilter
          })
        });
      }
    }
    return thisColumns;
  }, [EditableCell, codeListsData]);

  return (
    <>
      {isLoading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={codeListsData
                .filter((row) => {
                  if (errorFlag) {
                    if (typeof codeListErrorOnRecords[row.id] === 'undefined') {
                      return false;
                    }
                    return true;
                  }
                  return true;
                })
                .map((row) => {
                  return {
                    ...row,
                    editMode,
                    key: row.id
                  };
                })}
              initialSortedColumn="iqUpdateDate"
              initialSortOrder="desc"
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                //   labelDisplayedRows: ({ from, to, count }) =>
                //     `${count === 1 ? 'Employee' : 'Employees'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={(props) => <CustomButtonHeader {...props} cardTitle={cardTitle} />}
              headerProps={{
                editMode,
                codeListsData,
                // handleStepValue,
                // handleShowPreview,
                setIsPreview,
                codeListErrorCount,
                errorFlag,
                handleErrorData,
                isAdding,
                setIsAdding,
                isPreviewDataUploaded,
                setOpenConfirmModal
              }}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default CodeListsPreview;
