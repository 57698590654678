import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetMasteringType = createAsyncThunk(
  'RuleEditor/GetMasteringType',
  async ({ mappingRuleVersionID }) => {
    return await axios.post(
      `${API_URL}/api/RuleEditor/GetMasteringType?MappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const GetRuleMetadataFormItems = createAsyncThunk(
  'RuleEditor/GetRuleMetadataFormItems',
  async (data) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetRuleMetadataFormItems`, data);
  }
);

export const GetMasteringSettings = createAsyncThunk(
  'RuleEditor/GetMasteringSettings',
  async (isFullMastering) => {
    return await axios.get(
      `${API_URL}/api/RuleEditor/GetMasteringSettings?IsFullMastering=${isFullMastering}`
    );
  }
);

export const SaveMasteringType = createAsyncThunk(
  'RuleEditor/SaveMasteringType',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveMasteringType`, payload);
  }
);

export const SaveVariableMappingRules = createAsyncThunk(
  'RuleEditor/SaveVariableMappingRules',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveVariableMappingRules`, payload);
  }
);

export const GetVariableMappingRules = createAsyncThunk(
  'RuleEditor/GetVariableMappingRules',
  async (data) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetVariableMappingRules`, data);
  }
);

export const GetRuleExpressionMethodsAndConstants = createAsyncThunk(
  'RuleEditor/GetRuleExpressionMethodsAndConstants',
  async () => {
    return await axios.get(`${API_URL}/api/RuleEditor/GetRuleMethodsConstants`);
  }
);

export const GetDerivedVisitNameData = createAsyncThunk(
  'RuleEditor​/GetDerivedVisitNameData',
  async (mappingRuleVersionID) => {
    return await axios.get(
      `${API_URL}/api/RuleEditor/GetDerivedVisitNameData?MappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const SaveDerivedVisitNameData = createAsyncThunk(
  'RuleEditor​/SaveDerivedVisitNameData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveDerivedVisitNameData`, payload);
  }
);
