import { createSlice } from '@reduxjs/toolkit';

export const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState: {
    reloadData: { type: '', selectedRow: {} },
    view: false,
    rowData: [],
    cardName: '',
    isAllFlag: false,
    callDashboardApis : false
  },
  reducers: {
    setReloadData(state, { payload }) {
      state.reloadData = payload;
    },
    setView(state, { payload }) {
      state.view = payload;
    },
    setRowdata(state, { payload }) {
      state.rowData = payload;
    },
    passCardName(state, { payload }) {
      state.cardName = payload;
    },
    setIsAll(state, { payload }) {
      state.isAllFlag = payload;
    },
    setDashboardApis(state, { payload }) {
      state.callDashboardApis = payload;
    },
  }
});

export const { setReloadData, setView, setRowdata, passCardName, setIsAll, setDashboardApis } = DashboardSlice.actions;
