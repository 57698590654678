/*eslint-disable */
import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import EnvTest from 'Images/env-test.svg';
import EnvPreProd from 'Images/env-pre-prod.svg';
import EnvProd from 'Images/env-prod.svg';

const useStyles = (horizontal) =>
  makeStyles({
    envCardContent: {
      display: 'flex',
      flexDirection: horizontal ? 'row' : 'column',
      height: '100%',
      alignItems: 'center',
      justifyContent: horizontal ? 'flex-start' : 'center'
    },
    envCardDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: horizontal ? 'flex-start' : 'center',
      justifyContent: 'center',
      marginLeft: horizontal ? '1rem' : '0'
    },
    envType: {
      fontFamily: 'Proxima Nova',
      fontSize: horizontal ? '1rem' : '1.2rem',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: horizontal ? '1.5rem' : '3rem'
    },
    versionTitle: {
      fontSize: horizontal ? '1rem' : '0.8rem'
    },
    version: {
      fontSize: horizontal ? '1rem' : '0.8rem',
      fontWeight: horizontal ? 600 : 'bold'
    }
  });

const Environment = (props) => {
  const { environmentName, mappingSpecName, selected, horizontal } = props;
  const classes = useStyles(horizontal)();

  const [icon, name] = useMemo(() => {
    switch (environmentName) {
      case 'Test': {
        return [EnvTest, 'Test'];
      }
      case 'Preprod': {
        return [EnvPreProd, 'Pre-Production'];
      }
      case 'Prod': {
        return [EnvProd, 'Production'];
      }
      default: {
        return [EnvPreProd, environmentName];
      }
    }
  }, [environmentName]);

  return (
    <Box
      className={classes.envCardContent}
      style={selected ? { color: '#0768FD' } : { color: '#595959' }}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img
          alt={'env-icon'}
          src={icon}
          style={{
            height: horizontal ? '2.5rem' : '3rem',
            filter: selected
              ? 'invert(0.5) sepia(1) saturate(91) hue-rotate(217deg)'
              : 'invert(0.349) hue-rotate(0deg)'
            // blue -- 'invert(0.5) sepia(1) saturate(91) hue-rotate(217deg)'
            // gray -- 'invert(0.349) hue-rotate(0deg)'
          }}
        />
      </Box>
      <Box className={classes.envCardDetails}>
        <span className={classes.envType}>{name}</span>
        <Box className={classes.versionTitle}>Active Mapping Spec:</Box>
        <Box className={classes.version}>{mappingSpecName || 'No Active Mapping Spec'}</Box>
      </Box>
    </Box>
  );
};

export default Environment;
