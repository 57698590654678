// import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { closeLoader } from 'Redux/Slice/LoaderSlice';
import { showLoader } from 'Redux/Slice/LoaderSlice';
import Cookies from 'js-cookie';
import { getHeadersFromCookies } from 'service/header.service';

const API_URL = process.env.REACT_APP_API_URL;

// export const GetAssignedStudies = createAsyncThunk('studyLibrary/GetAssignedStudies', async () => {
//   return axios.get(`${API_URL}/api/Protocol/GetAssignedStudies`);
// });

export const GetStudyLibraries = createAsyncThunk(
  'studyLibrary/GetStudyLibraries',
  async (protocolNumber) => {
    return axios.get(
      `${API_URL}/api/Protocol/GetStudyLibraries?studyProtocolNumber=${protocolNumber}`
    );
  }
);

export const getAssignedStudies = createAsyncThunk(
  'studyLibrary/getAssignedStudies',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(showLoader());
    try {
      const response = axios.get(`${API_URL}/api/Protocol/GetAssignedStudies?userId=${Cookies.get('user.id')}`);
      dispatch(closeLoader());
      return response;
    } catch (error) {
      dispatch(closeLoader());
      rejectWithValue([], error);
    }
  }
);

// export const getAssignedStudies = async () => {
//   return axios.get(`${API_URL}/api/Protocol/GetAssignedStudies`);
// };

export const getStudiesSources = async (data) => {
  return axios.get(
    `${API_URL}/api/Protocol/GetStudiesSources?protocol=${data.protocol}&isDataSourceConfigured=${data.isDataSourceConfigured}`
  );
};

export const saveTestConnections = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/TestStudyConfiguration`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};

export const saveConfigConnections = async (payload) => {
  const api = await fetch(`${API_URL}/api/Protocol/SaveStudyConfiguration`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
      ...getHeadersFromCookies()
    },
    body: JSON.stringify(payload)
  });
  return api;
};
