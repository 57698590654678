import { trialArmsColumnNames } from "Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Components/TrialArmsUtils";
import { ErrorConstants } from './ErrorConstants';

const { 
    mandatoryError,
    uniqueTrialArmsError
} = ErrorConstants;

export const trialArmsValidator = (records) => {
    let errorCount = 0;
    let errorOnRecords = {};
    let uniqueCheck = {};
    let apiColumnNames = Object.values(trialArmsColumnNames);

    const setErrorOnRecords = (recordId,columnName,message) => {
        errorCount++;
        if(typeof errorOnRecords[recordId] === "undefined"){
            errorOnRecords[recordId] = {};
        }
        if(typeof errorOnRecords[recordId][columnName] !== "undefined"){
            errorCount--;
            message = `${errorOnRecords[recordId][columnName]}; ${message}`;
        }
        errorOnRecords[recordId][columnName] = message;
    }

    const checkTypes = (record) => {
        apiColumnNames.forEach((columnName) => {
            let column_props = trialArmsColumnNames[`${columnName}_props`];
            if(column_props.type === "NUMBER"){
                if(typeof record[columnName] !== "undefined" && isNaN(parseInt(record[columnName])) && record[columnName].trim() !== ""){
                    setErrorOnRecords(record.id,columnName,`It should be of type ${column_props.type}`);
                }
            }
        })
    }

    const checkUnique = (record) => {
        let recordId = record.id;
        let value = `${record.armcd?.toLowerCase()}-${record.taetord}`;
        if (Object.keys(uniqueCheck).length > 0 && typeof uniqueCheck[value] !== "undefined") {
            let uniqueTrialArmsErrorManipulated = uniqueTrialArmsError
                                                    .replace("$armcd",trialArmsColumnNames.armcd)
                                                    .replace("$taetord",trialArmsColumnNames.taetord);
            setErrorOnRecords(record.id,'armcd',uniqueTrialArmsErrorManipulated);
            setErrorOnRecords(record.id,'taetord',uniqueTrialArmsErrorManipulated);
            if(typeof uniqueCheck[value] === "string"){
                let pastRecordId = uniqueCheck[value];
                setErrorOnRecords(pastRecordId,'armcd',uniqueTrialArmsErrorManipulated);
                setErrorOnRecords(pastRecordId,'taetord',uniqueTrialArmsErrorManipulated);
                uniqueCheck[value] = true;
            }
        } else {
            uniqueCheck[value] = recordId;
        }
    };

    records.forEach(record => {
        let recordId = record.id;
        errorOnRecords[recordId] = {};
        checkTypes(record);
        checkUnique(record);
        for(let key of apiColumnNames){
            let value = record[key];
            if(typeof value === "undefined" || (typeof value === "string" && value.trim() === "")){
                record[key] = "";
                if(trialArmsColumnNames[`${key}_props`].isMandatory()){
                    setErrorOnRecords(recordId,key,mandatoryError);
                }
            }
            else{
                switch(key){
                    case "armcd":
                      if(value.length > 20){
                        setErrorOnRecords(recordId,key,"It cannot be greater than 20 characters");
                      }
                    break;
                    case "etcd":
                      if(value.length > 8){
                        setErrorOnRecords(recordId,key,"It cannot be greater than 8 characters");
                      }
                    break;
                }
            }
        }
        if(Object.keys(errorOnRecords[recordId]).length === 0){
            delete errorOnRecords[recordId];
        }
    });
    
    return {
        errorCount,
        errorOnRecords
    };
};
  
export default { trialArmsValidator };
