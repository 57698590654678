/*eslint-disable*/
import Modal from 'apollo-react/components/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SourceDetails from './SourceDetails';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Trash from 'apollo-react-icons/Trash';
import Table from 'apollo-react/components/Table';
import PlusIcon from 'apollo-react-icons/Plus';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import { createStringSearchFilter } from 'apollo-react/components/Table';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { getCodeLists } from 'Redux/Service/ReferenceDataCardsService';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { CodelistNewRow, useNewRow } from './CodelistNewRow';
import { DeleteMappingCodeList } from 'Redux/Service/ReferenceDataCardsService';
import { UpdateMappingCodeList } from 'Redux/Service/ReferenceDataCardsService';
import Loader from 'apollo-react/components/Loader';
import Banner from 'apollo-react/components/Banner';
import Fade from '@mui/material/Fade';
import Cookies from 'js-cookie';
import Box from 'apollo-react/components/Box';
import ButtonGroup from 'apollo-react/components/ButtonGroup';
import styled from 'styled-components';
import Typography from 'apollo-react/components/Typography';
import StatusNegative from 'apollo-react-icons/StatusNegative';
import { showBanner } from 'Redux/Slice/BannerSlice';
import NoCodelistMessage from './NoCodelistMessage';

const CustomTextField = styled(TextField)(({ hasError }) => ({
  '& .MuiInputBase-input': {
    border: hasError && '1px solid #E20000'
  },
  margin: '0px'
}));

const useSelectStyles = makeStyles({
  root: {
    margin: '0',
    '& >div': {
      backgroundColor: 'transparent',
      marginTop: '0'
    },
    '& .Mui-disabled': {
      opacity: '0.5',
      color: '#0768FD',
      backgroundColor: 'transparent'
    }
  },
  selectRoot: {
    color: 'blue',
    fontWeight: '500'
  },
  select: {
    border: '0px',
    padding: '0px'
  }
});
const BORDER_STYLE = '1px solid #4442';
const useStyles = makeStyles({
  modalContainer: {
    height: '90vh',
    overflow: 'hidden',
    maxWidth: '90vw',
    width: '80vw',
    transition: 'all ease 300ms',
    '&>.MuiDialogContent-root': {
      paddingBottom: '0px',
      marginBottom: '4.5rem',
      borderBottom: BORDER_STYLE
    }
  },
  subtitle: {
    color: 'red',
    fontWeight: '600'
  },
  dropdown: {
    width: 'calc(20% - 2rem)',
    marginRight: '2rem'
  },
  stickyHeader: {
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    border: BORDER_STYLE
    // borderBottom: 'none'
  },
  root: {
    '& > div > table': {
      borderTop: 'none',
      border: BORDER_STYLE
    }
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: '5vh',
    left: '10vw',
    width: '80vw',
    padding: '1rem 1.5rem'
  }
});

const CustomButtonHeader = ({
  editMode,
  handleEditAll,
  handleCancel,
  handleSave,
  displayAddRow,
  setAddRowDisplay,
  translateAllRows,
  isEDCSource,
  rowCount
}) => {
  const classes = useSelectStyles();

  return (
    <div>
      <Tooltip title={'Copy and Format Source Translated Text for all rows'} placement={'top'}>
        <span>
          <Select
            disabled={displayAddRow || editMode || !rowCount}
            className={classes.root}
            SelectProps={{
              classes: { root: classes.selectRoot, select: classes.select }
            }}
            size="small"
            onChange={translateAllRows}
            placeholder="Aa">
            <MenuItem value="uc">{'UPPERCASE'}</MenuItem>
            <MenuItem value="lc">{'lowercase'}</MenuItem>
            <MenuItem value="cc">{'Capitalize Words'}</MenuItem>
            <MenuItem value="sc">{'Sentence case'}</MenuItem>
          </Select>
        </span>
      </Tooltip>
      <Tooltip title={isEDCSource && 'New records cannot be added for EDC'}>
        <span>
          <Button
            size="small"
            variant="secondary"
            icon={PlusIcon}
            style={{ marginLeft: 8 }}
            disabled={displayAddRow || editMode || isEDCSource}
            onClick={() => setAddRowDisplay(true)}>
            {'Add row'}
          </Button>
        </span>
      </Tooltip>

      {editMode ? (
        <>
          <Button size="small" variant="text" style={{ marginLeft: 8 }} onClick={handleCancel}>
            {'Cancel'}
          </Button>
          <Button size="small" variant="secondary" style={{ marginLeft: 8 }} onClick={handleSave}>
            {'Save Changes'}
          </Button>
        </>
      ) : (
        <Tooltip title={isEDCSource && 'Edit all is not allowed for EDC'}>
          <span>
            <Button
              size="small"
              variant="secondary"
              onClick={handleEditAll}
              disabled={displayAddRow || editMode || isEDCSource || !rowCount}
              style={{ marginLeft: 8 }}>
              {'Edit all'}
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

/**
 * Function to translate text to different cases
 * @param {"uc" | "ls" | "cc" | "sc"} value
 * @param {*} row
 * @returns
 */
const translateText = (value, row) => {
  let translatedText = row?.odmCodelistLabel;
  if (!translatedText) return '';
  if (value === 'uc') {
    translatedText = row?.odmCodelistLabel.toUpperCase();
  } else if (value === 'lc') {
    translatedText = row?.odmCodelistLabel.toLowerCase();
  } else if (value === 'cc') {
    let wordList = row?.odmCodelistLabel.split(' ');
    if (wordList?.length) {
      wordList.forEach((word, index) => {
        wordList[index] = word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
      });
    }
    translatedText = wordList.join(' ');
  } else if (value === 'sc') {
    let wordList = row?.odmCodelistLabel.split('.');
    if (wordList?.length) {
      wordList.forEach((word, index) => {
        wordList[index] = word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
      });
    }
    translatedText = wordList.join('.');
  } else {
    return row?.sdmCodelistLabel;
  }
  return translatedText;
};

const ActionCell = ({ row }) => {
  const classes = useSelectStyles();
  const handleTranslation = ({ target: { value } }) => {
    const translatedText = translateText(value, row);
    row.editRow(row.mappingCodeListId, 'sdmCodelistLabel', translatedText || '');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <Tooltip title={'Copy and Format Source Translated Text'} disableFocusListener>
        <Select
          className={classes.root}
          SelectProps={{ classes: { root: classes.selectRoot, select: classes.select } }}
          size="small"
          onChange={handleTranslation}
          placeholder="Aa">
          <MenuItem value="uc">{'UPPERCASE'}</MenuItem>
          <MenuItem value="lc">{'lowercase'}</MenuItem>
          <MenuItem value="cc">{'Capitalize Words'}</MenuItem>
          <MenuItem value="sc">{'Sentence case'}</MenuItem>
        </Select>
      </Tooltip>
    </div>
  );
};

const ModifiedTranslatedText = ({ row, column: { accessor } }) => {
  return (
    <TextField
      inputProps={{ 'data-testid': accessor }}
      value={row[accessor]}
      name={accessor}
      onChange={(e) => row.editRow(row.mappingCodeListId, accessor, e.target.value)}
      fullWidth
      size="small"
      style={{ margin: '0px' }}
    />
  );
};

const EditableCell = ({ row, column: { accessor } }) => {
  return row.editMode ? (
    <CustomTextField
      inputProps={{ 'data-testid': accessor }}
      value={row[accessor]}
      name={accessor}
      onChange={(e) => row.editRow(row.mappingCodeListId, accessor, e.target.value)}
      fullWidth
      size="small"
      hasError={
        (['odmCodeListName', 'value'].includes(accessor) && row.hasError) ||
        row.hasError === 'isManditory'
      }
    />
  ) : (
    row[accessor] || ''
  );
};

const DeleteActionCell = ({ row: { mappingCodeListId, editMode, onDelete, isEDCSource } }) => {
  return (
    editMode || (
      <Tooltip
        title={isEDCSource ? 'EDC records cannot be deleted' : 'Delete'}
        disableFocusListener>
        <span>
          <Button size="small" disabled={isEDCSource} onClick={() => onDelete(mappingCodeListId)}>
            <Trash
              style={{
                color: '#555'
              }}
            />
          </Button>
        </span>
      </Tooltip>
    )
  );
};

const columns = [
  {
    header: 'Source Value',
    accessor: 'value',
    filterFunction: createStringSearchFilter('value'),
    filterComponent: TextFieldFilter,
    customCell: EditableCell,
    required: true
  },
  {
    header: 'Source Translated Text',
    accessor: 'odmCodelistLabel',
    filterFunction: createStringSearchFilter('odmCodelistLabel'),
    filterComponent: TextFieldFilter,
    customCell: EditableCell,
    required: true
  },
  {
    accessor: 'action',
    customCell: ActionCell,
    width: 70,
    showAction: true
  },
  {
    header: 'Modified Translated Text',
    accessor: 'sdmCodelistLabel',
    filterFunction: createStringSearchFilter('sdmCodelistLabel'),
    filterComponent: TextFieldFilter,
    customCell: ModifiedTranslatedText
  },
  {
    accessor: 'action',
    customCell: DeleteActionCell,
    width: 20
  }
];
const genericCodelistColumns = [
  {
    header: 'Source Code List Name',
    accessor: 'odmCodeListName',
    filterFunction: createStringSearchFilter('odmCodeListName'),
    filterComponent: TextFieldFilter,
    customCell: EditableCell,
    required: true
  },
  ...columns
];

const CodelistEditor = (props) => {
  const { display = false, formItems = [], setCodelistEditorDisplay, mappingRuleVersionID } = props;
  const dispatch = useDispatch();

  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const userId = Cookies.get('user.id');

  const [codelistData, setCodelistData] = useState([]);
  const [genericCodelistData, setGenericCodelistData] = useState([]);
  const [editMode, toggleEditMode] = useState(false);
  const [displayAddRow, setAddRowDisplay] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [selectedFormItem, setSelectedFormItem] = useState(formItems?.length ? formItems[0] : {});
  const [newRow, setNewRow, editNewRow] = useNewRow({});
  const [errorCount, setErrorCount] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [showMessage, setMessage] = useState({
    show: false,
    message: ''
  });
  const [banner, setBanner] = useState({ enabled: false, variant: 'info', message: '' });
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [codelistType, setCodelistType] = useState('sourceForm');
  const [modifiedTranslatedTexts, setModifiedTranslatedTexts] = useState({});
  const classes = useStyles();

  const isCDRSource = selectedFormItem.libraryType?.startsWith('CDR Tabular');
  const isEDCSource =
    selectedFormItem.libraryType?.startsWith('CDISC ODM') && codelistType !== 'generic';

  useEffect(() => {
    if (banner.enabled && banner.variant === 'success') {
      setTimeout(() => {
        setBanner({ enabled: false, variant: 'info', message: '' });
      }, 3000);
    }
  }, [banner]);

  const sourceDetails = useMemo(() => {
    return codelistData?.length
      ? codelistData[0]
      : isCDRSource
      ? {
          odmForm: selectedFormItem.formName,
          odmItem: selectedFormItem.itemName,
          itemOid: `${selectedFormItem.formName}.${selectedFormItem.itemName}`,
          odmCodeListName: selectedFormItem.itemName,
          questionText: selectedFormItem.itemName
        }
      : {};
  }, [codelistData]);

  useEffect(() => {
    formItems?.length && setSelectedFormItem(formItems[0]);
  }, [formItems]);

  useEffect(() => {
    setNewRow({});
    setAddRowDisplay(false);
    setEditedRows(codelistType === 'generic' ? genericCodelistData : codelistData);
    setModifiedTranslatedTexts({});
    toggleEditMode(false);
  }, [codelistType]);

  const editRow = (mappingCodeListId, key, value) => {
    if (key === 'sdmCodelistLabel') {
      setModifiedTranslatedTexts((_modifiedTranslatedTexts) => ({
        ..._modifiedTranslatedTexts,
        [mappingCodeListId]: value
      }));
    }
    setErrorCount();
    setEditedRows((rowData) =>
      rowData.map((row) =>
        row.mappingCodeListId === mappingCodeListId
          ? { ...row, [key]: value, edited: true, hasError: undefined }
          : { ...row, hasError: undefined }
      )
    );
  };

  useEffect(() => {
    setSaveDisabled(
      !(codelistType === 'generic' ? genericCodelistData : codelistData).some(
        (row) =>
          modifiedTranslatedTexts?.[row.mappingCodeListId] &&
          modifiedTranslatedTexts[row.mappingCodeListId] !== row.sdmCodelistLabel
      )
    );
  }, [modifiedTranslatedTexts, codelistType]);

  const translateAllRows = ({ target: { value } }) => {
    setEditedRows((_rowData) => {
      const _modifiedTranslatedTexts = { ...modifiedTranslatedTexts };
      const _editedRows = _rowData.map((_row) => {
        const modifiedRow = { ..._row };
        const translatedText = translateText(value, modifiedRow);
        modifiedRow.sdmCodelistLabel = translatedText;
        modifiedRow.edited = true;
        _modifiedTranslatedTexts[_row.mappingCodeListId] = translatedText;
        return modifiedRow;
      });
      setModifiedTranslatedTexts(_modifiedTranslatedTexts);
      return _editedRows;
    });
  };

  const fetchCodeList = async (disableLoader) => {
    disableLoader || setIsLoading(true);
    try {
      const payload = {
        mappingRuleVersionID,
        formName: selectedFormItem.formName,
        itemName: selectedFormItem.itemName,
        isImport: false
      };
      const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
      if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
        const { ruleCodeLists = [] } = codeListsGetAPIData.data || {};
        let _codelistData = [],
          _genericCodelistData = [];
        ruleCodeLists.forEach((codeData) => {
          if (codeData.odmForm && codeData.odmItem) {
            _codelistData.push({
              ...codeData,
              editRow,
              onDelete,
              isEDCSource
            });
          } else {
            _genericCodelistData.push({
              ...codeData,
              editRow,
              onDelete
            });
          }
        });
        setCodelistData(_codelistData);
        setGenericCodelistData(_genericCodelistData);
        setEditedRows(codelistType === 'generic' ? _genericCodelistData : _codelistData);
        if (!_codelistData?.length) {
          isCDRSource ||
            setMessage({
              show: true,
              message: 'No Codelist configured for the selected Source Form & Source Field.'
            });
        }
      } else {
        setBanner({ enabled: true, variant: 'error', message: codeListsGetAPIData?.message });
      }
    } catch (error) {
      console.log(error);
    }
    disableLoader || setIsLoading(false);
  };

  useEffect(() => {
    //To stop fetching codelist data incase Source Form, Source Item is not selected
    if (!selectedFormItem?.formName || !selectedFormItem?.itemName) {
      setMessage({
        show: true,
        message: 'No Valid Source Form, Source Item combination present in the expression.'
      });
      setIsLoading(false);
      return;
    }
    fetchCodeList();
  }, [selectedFormItem, formItems]);

  const handleClose = useCallback(() => {
    setCodelistEditorDisplay({ display: false, formItems: [], triggerCodelist: true });
  }, [setCodelistEditorDisplay]);

  const rowData = useMemo(() => {
    if (editMode || editedRows.some((_row) => _row.edited))
      return editedRows.map((_row) => ({
        ..._row,
        editMode,
        key: _row.mappingCodeListId,
        sdmCodelistLabel: modifiedTranslatedTexts[_row.mappingCodeListId] || _row.sdmCodelistLabel
      }));

    return (codelistType === 'generic' ? genericCodelistData : codelistData).map((_row) => ({
      ..._row,
      editMode,
      key: _row.mappingCodeListId,
      sdmCodelistLabel: modifiedTranslatedTexts[_row.mappingCodeListId] || _row.sdmCodelistLabel
    }));
  }, [
    codelistData,
    genericCodelistData,
    editMode,
    editedRows,
    codelistType,
    modifiedTranslatedTexts
  ]);

  const validateCodeList = () => {
    const manditoryFieldsError = {};
    rowData.forEach(({ value, odmCodeListName, odmCodelistLabel, mappingCodeListId }) => {
      if (!value || !odmCodelistLabel || (codelistType === 'generic' && !odmCodeListName)) {
        manditoryFieldsError[mappingCodeListId] = 'isManditory';
      }
    });
    if (Object.keys(manditoryFieldsError)?.length) {
      return { manditory_fields_error: true, ...manditoryFieldsError };
    }

    const duplicateValues = {};
    const sourceValueMap = {};

    rowData.forEach(({ value, odmCodeListName, mappingCodeListId }) => {
      if (odmCodeListName + value in sourceValueMap) {
        sourceValueMap[odmCodeListName + value].count += 1;
        sourceValueMap[odmCodeListName + value].mappingCodeListIds.push(mappingCodeListId);
      } else
        sourceValueMap[odmCodeListName + value] = {
          mappingCodeListIds: [mappingCodeListId],
          odmCodeListName,
          value,
          count: 1
        };
    });

    for (let value in sourceValueMap) {
      if (sourceValueMap[value]?.count > 1) {
        sourceValueMap[value]?.mappingCodeListIds.forEach(
          (mappingCodeListId) => (duplicateValues[mappingCodeListId] = sourceValueMap[value])
        );
      }
    }
    return duplicateValues;
  };

  const saveCodelist = async () => {
    setIsLoading(true);
    const errors = validateCodeList();
    const _errorCount = Object.keys(errors)?.length;
    if (_errorCount) {
      if (errors.manditory_fields_error) {
        setErrorCount(_errorCount - 1);
        setBanner({
          enabled: true,
          variant: 'error',
          message: `Error found in ${_errorCount - 1} row${_errorCount === 2 ? '' : 's'}, ${
            codelistType === 'generic' ? 'source codelist name, ' : ''
          }source value and source translated text are manditory`
        });
      } else {
        setErrorCount(_errorCount);
        setBanner({
          enabled: true,
          variant: 'error',
          message: `Error found in ${_errorCount} row${
            _errorCount === 1 ? '' : 's'
          }, source value must be unique`
        });
      }
      setEditedRows((_rowData) =>
        _rowData.map((row) => ({ ...row, hasError: errors[row.mappingCodeListId] }))
      );
      setIsLoading(false);
      return;
    }
    const _editedCodelist = [];
    rowData.forEach((_row) => {
      // if (_row.edited) {
      let _modifiedRow = {
        ..._row,
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: 'UPDATE',
        iqAuditDate: new Date().toISOString(),
        isImported: isEDCSource,
        isSynced: false
      };

      delete _modifiedRow.key;
      delete _modifiedRow.editMode;
      delete _modifiedRow.edited;
      delete _modifiedRow.isEpro;
      delete _modifiedRow.tabName;

      _editedCodelist.push(_modifiedRow);
      // }
    });

    const saveResponse = await dispatch(
      UpdateMappingCodeList({ ruleCodeLists: _editedCodelist })
    ).then(unwrapResult);

    if (saveResponse?.data?.success) {
      await fetchCodeList(true);
      toggleEditMode(false);
      setModifiedTranslatedTexts({});
      setBanner({ enabled: true, variant: 'success', message: saveResponse.data.message });
    } else {
      setBanner({ enabled: true, variant: 'error', message: saveResponse?.message });
    }
    setErrorCount();
    setIsLoading(false);
  };

  const onNewRowSave = async () => {
    if (
      rowData.some(
        ({ value, odmCodeListName }) =>
          (odmCodeListName + value) ===
          (
            (codelistType === 'generic' ? newRow.odmCodeListName : sourceDetails.odmCodeListName) +
            newRow.value
          )
      )
    ) {
      setErrorCount(1);
      const _error = 'Error found in 1 row, source value must be unique';
      setBanner({
        enabled: true,
        variant: 'error',
        message: _error
      });
      setNewRow((_newRow) => ({ ..._newRow, hasError: true }));
      throw new Error(_error);
    }
    setIsLoading(true);

    const _newRow = {
      iqCreateDate: new Date().toISOString(),
      iqUpdateDate: new Date().toISOString(),
      iqCreatedBy: userId,
      iqUpdatedBy: userId,
      iqAuditType: 'INSERT',
      iqAuditDate: new Date().toISOString(),
      iqActiveFlag: true,
      mappingCodeListId: null,
      protocolNumber: protocol.protocolNumber,
      mappingRuleVersionId: mappingRuleVersionID,
      domainNm: 'CodeList',
      value: newRow.value,
      odmCodelistLabel: newRow.odmCodelistLabel,
      sdmCodelistLabel: newRow.sdmCodelistLabel,
      isImported: false,
      isSynced: false
    };
    if (codelistType === 'generic') {
      _newRow.odmCodeListName = newRow.odmCodeListName;
      _newRow.odmForm = '';
      _newRow.odmItem = '';
      _newRow.itemOid = '';
      _newRow.questionText = '';
    } else {
      _newRow.odmCodeListName = sourceDetails.odmCodeListName;
      _newRow.odmForm = sourceDetails.odmForm;
      _newRow.odmItem = sourceDetails.odmItem;
      _newRow.itemOid = sourceDetails.itemOid;
      _newRow.questionText = sourceDetails.questionText;
    }

    const saveResponse = await dispatch(UpdateMappingCodeList({ ruleCodeLists: [_newRow] })).then(
      unwrapResult
    );

    if (saveResponse?.data?.success) {
      setBanner({ enabled: true, variant: 'success', message: saveResponse.data.message });
      await fetchCodeList();
    } else {
      setBanner({ enabled: true, variant: 'error', message: saveResponse?.message });
    }
    setErrorCount();
    setIsLoading(false);
  };

  const onDelete = (mappingCodeListId) => {
    setConfirmModal({ display: true, id: mappingCodeListId });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const deleteResponse = await dispatch(DeleteMappingCodeList([confirmModal.id])).then(
      unwrapResult
    );
    if (deleteResponse?.data?.success) {
      setBanner({ enabled: true, variant: 'success', message: deleteResponse.data.message });
      await fetchCodeList();
      setConfirmModal({ display: false });
    }
    setIsLoading(false);
  };

  const handleEditAll = () => {
    toggleEditMode(true);
  };

  const handleCancel = () => {
    setEditedRows(codelistType === 'generic' ? genericCodelistData : codelistData);
    setErrorCount();
    setBanner({ enabled: false });
    toggleEditMode(false);
  };

  const deleteModalProps = [
    { label: 'Cancel', onClick: () => setConfirmModal({ display: false }) },
    { label: 'Delete', onClick: handleDelete }
  ];

  const renderLoaderOrMessage = () => {
    return (
      <div
        style={{
          minHeight: '50vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        }}>
        {loading ? <Loader isInner /> : showMessage?.show ? showMessage?.message : null}
      </div>
    );
  };

  const buttonGroupProps = [
    {
      label: 'Source Form Code List',
      onClick: () => setCodelistType('sourceForm'),
      variant: codelistType === 'sourceForm' ? 'primary' : 'secondary',
      style: {
        borderRadius: 0,
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
        width: '12rem',
        size: 'small'
      }
    },
    {
      label: 'Default Code List',
      onClick: () => setCodelistType('generic'),
      variant: codelistType === 'generic' ? 'primary' : 'secondary',
      style: {
        borderRadius: 0,
        margin: 0,
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        width: '12rem',
        size: 'small'
      }
    }
  ];

  return (
    <Modal
      open={display}
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: '-56px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <span style={{ display: 'flex', flex: '1' }}>Codelist</span>
          <ButtonGroup
            style={{ display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}
            buttonProps={buttonGroupProps}
          />
          <span style={{ display: 'flex', flex: '1' }}></span>
        </Box>
      }
      data-testid="tabel"
      hideButtons
      className={classes.modalContainer}
      onClose={handleClose}>
      {/* {renderBody()} */}
      <Banner
        variant={banner.variant}
        open={banner.enabled}
        onClose={() => setBanner({ enabled: false })}
        message={banner.message}
        style={{ position: 'absolute' }}
        TransitionComponent={Fade}
      />
      {codelistType !== 'generic' && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'flex-end'}
          flexWrap={'wrap'}
          mb={1}>
          {formItems?.length > 1 ? (
            <Select
              className={classes.dropdown}
              value={selectedFormItem.formatedString}
              size="small"
              label={'Select Source Field'}
              canDeselect={false}
              disabled={loading}
              onChange={({ target: { value } }) => {
                setSelectedFormItem(formItems.find((_fi) => _fi.formatedString === value));
              }}>
              {formItems.map((_fi, index) => (
                <MenuItem
                  key={_fi.formatedString + index}
                  value={_fi.formatedString}>{`${_fi.formName}.${_fi.itemName}`}</MenuItem>
              ))}
            </Select>
          ) : (
            <></>
          )}
          {loading || showMessage?.show ? <></> : <SourceDetails sourceDetails={sourceDetails} />}
        </Box>
      )}
      {loading || (showMessage?.show && codelistType !== 'generic') ? (
        renderLoaderOrMessage()
      ) : (
        <>
          <Table
            key={codelistType}
            title={codelistType === 'generic' ? 'Default Code List' : 'Source Form Code List'}
            subtitle={
              <Box display={'flex'}>
                {rowData?.length} rows in code list
                {errorCount > 0 && (
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: '#E20000',
                      fontWeight: 600,
                      fontSize: '13px',
                      marginTop: '1px',
                      border: '1px solid white',
                      borderRadius: '4px',
                      paddingRight: '0.5rem',
                      marginLeft: '0.5rem',
                      lineHeight: '20px'
                    }}>
                    <span
                      style={{
                        color: 'white'
                      }}>
                      <StatusNegative
                        style={{
                          color: 'white',
                          float: 'left',
                          height: '16px',
                          marginTop: '2px'
                        }}
                      />
                      {`${errorCount} error${errorCount === 1 ? '' : 's'}`}
                    </span>
                  </Typography>
                )}
              </Box>
            }
            columns={codelistType === 'generic' ? genericCodelistColumns : columns}
            rows={rowData}
            rowId="value"
            classes={{ stickyHeader: classes.stickyHeader, root: classes.root }}
            rowsPerPageOptions={[20, 50, 100, 500, 1000]}
            defaultRowsPerPage={rowData?.length ? 20 : 10}
            hidePagination={!rowData?.length}
            tablePaginationProps={{
              labelDisplayedRows: ({ from, to, count }) =>
                `${count === 1 ? 'Item' : 'Items'} ${from}-${to} of ${count}`,
              truncate: true
            }}
            isSticky
            CustomHeader={CustomButtonHeader}
            headerProps={{
              editMode,
              toggleEditMode,
              handleEditAll,
              handleCancel,
              handleSave: saveCodelist,
              displayAddRow,
              setAddRowDisplay,
              translateAllRows,
              isEDCSource,
              rowCount: rowData?.length
            }}
            CustomSubHeader={CodelistNewRow}
            subHeaderProps={{
              key: 'TableNewRow',
              isAdding: displayAddRow,
              setIsAdding: setAddRowDisplay,
              onSave: onNewRowSave,
              onCancel: setErrorCount,
              newRow: newRow,
              editNewRow: (key, value) => {
                setErrorCount();
                editNewRow(key, value);
              },
              setNewRow: setNewRow,
              columns: codelistType === 'generic' ? genericCodelistColumns : columns,
              textFieldProps: { margin: 'none', fullWidth: true },
              acionProps: {
                editRow: (id, key, value) => {
                  setErrorCount();
                  editNewRow(key, value);
                }
              }
            }}
            emptyProps={{
              content: <NoCodelistMessage handleAddRow={() => setAddRowDisplay(true)} />
            }}
          />
          <Modal
            open={confirmModal.display}
            variant={'warning'}
            title={'Delete'}
            data-testid="delet-modal"
            buttonProps={deleteModalProps}
            onClose={() => setConfirmModal({ display: false })}>
            {'Are you sure, you want to delete the codelist record?'}
          </Modal>
        </>
      )}
      <div className={classes.modalFooter}>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Tooltip
          title={
            (saveDisabled || editMode || displayAddRow) &&
            'Please finish editing before saving the code list'
          }
          placement="top">
          <span style={{ display: 'inline-block', marginLeft: '1rem' }}>
            <Button
              variant="primary"
              disabled={saveDisabled || editMode || displayAddRow}
              onClick={async () => {
                await saveCodelist();
                dispatch(
                  showBanner({ variant: 'success', message: 'Codelist saved successfully' })
                );
                handleClose();
              }}>
              Save Codelist
            </Button>
          </span>
        </Tooltip>
      </div>
    </Modal>
  );
};

export default CodelistEditor;
