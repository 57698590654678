import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const SaveMappingSpec = createAsyncThunk(
  'RuleEditor/CreateMappingSpec',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/CreateMappingSpec`, payload);
  }
);

export const GetValidateMappingSpec = createAsyncThunk(
  'RuleEditor/ValidateMappingRules',
  async (data) => {
    return await axios.post(`${API_URL}/api/RuleEditor/ValidateMappingRules`, data);
  }
);

export const GetValidateMappingSpecForClone = createAsyncThunk(
  'RuleEditor/api/RuleEditor/CloneDataProductSourceMapping',
  async (data) => {
    return await axios.post(`${API_URL}/api/RuleEditor/CloneDataProductSourceMapping`, data);
  }
);

