import { MessageConstants } from "./MessageConstants";

export const KeyConstants = {
  EXPORT_SUPPQUAL_TYPES: {
    TOPIC_NAME: 'ExportSuppQualTypes',
    VALUES: {
      EXPORT_IN_SEPERATE_TABLE: {
        KEY: 'EXPORT_IN_SEPERATE_TABLE',
        LABEL: MessageConstants.EXPORT_SEPERATE_TABLE_TITLE,
        DESC: MessageConstants.EXPORT_SEPERATE_TABLE_DESC,
      },
      EXPORT_IN_PRIMARY_TABLE: {
        KEY: 'EXPORT_IN_PRIMARY_TABLE',
        LABEL: MessageConstants.EXPORT_PRIMARY_TABLE_TITLE,
        DESC: MessageConstants.EXPORT_PRIMARY_TABLE_DESC
      },
      EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE: {
        KEY: 'EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE',
        LABEL: MessageConstants.EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_TITLE,
        DESC: MessageConstants.EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_DESC
      },
      DO_NOT_EXPORT: {
        KEY: 'DO_NOT_EXPORT',
        LABEL: MessageConstants.DO_NOT_EXPORT_TITLE,
        DESC: MessageConstants.DO_NOT_EXPORT_DESC
      }
    }
  },

  EXPORT_TYPES: {
    TOPIC_NAME: 'ExportTypes',
    VALUES: {
      CUMULATIVE: {
        KEY: 'CUMULATIVE',
        LABEL: MessageConstants.EXPORT_TYPE_CUMULATIVE_TITLE,
        DESC: MessageConstants.EXPORT_TYPE_CUMULATIVE_DESC
      },
      INCREMENTAL: {
        KEY: 'INCREMENTAL',
        LABEL: MessageConstants.EXPORT_TYPE_INCREMENTAL_TITLE,
        DESC: MessageConstants.EXPORT_TYPE_INCREMENTAL_DESC
      }
    }
  }
};
