// eslint-disable-next-line
import React from 'react';
import Family from 'Images/Family.jpg';
import Grid from 'apollo-react/components/Grid';
const LeftView = () => {
  const styles = {
    paperContainer: {
      backgroundImage: `url(${Family})`,
      height: '100vh',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }
  };
  return (
    <div>
      <Grid container style={{ position: 'relative' }}>
        <div
          style={{
            background:
              'radial-gradient(circle, #0076AE 0%, #250056 62.04%, #1B1F6D 100%, #1B1F6D 100%)',
            opacity: 0.6,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100%'
          }}></div>
        <Grid item xs={12} style={styles.paperContainer}></Grid>
      </Grid>
    </div>
  );
};

export default LeftView;
