import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetVLCRules = createAsyncThunk(
  'RuleEditor/GetVLCRules',
  async (mappingRuleVersionID) => {
    return await axios.get(
      `${API_URL}/api/RuleEditor/GetVLCRules?MappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const SaveVLCRules = createAsyncThunk('RuleEditor/SaveVLCRules', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/SaveVLCRules`, payload);
});

export const DeleteVLCRules = createAsyncThunk('RuleEditor/DeleteVLCRules', async (VLCRuleID) => {
  return await axios.post(`${API_URL}/api/RuleEditor/DeleteVLCRules?VLCRuleID=${VLCRuleID}`);
});

export const ToggleVLCRules = createAsyncThunk('RuleEditor/ToggleVLCRules', async (payload) => {
  return await axios.post(
    `${API_URL}/api/RuleEditor/ToggleVLCRules?VLCRuleID=${payload.vlcRuleID}&isActive=${payload.isActive}`
  );
});
