/*eslint-disable */
import React, { useState } from 'react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import Button from 'apollo-react/components/Button';
import SupplementalCard from './SupplementalCard';
import { SupplementalQualifierPreview } from './PreviewComponents/index';
import CustomModal from 'Components/Modal';
import { useStateWithCB } from 'Utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIndexBasedOnModuleName } from './RuleEditorUtils';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';

const SupplementalQualifier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: mappingRuleVersionId } = useParams();
  const [SQPreviewData, setSQPreviewData] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [saveFlag, setSaveFlag] = useState(false);
  const [DmRulesArray, setDmRulesArray] = useState([]);
  const [SQValidationDataCheck, setSQValidationDataCheck] = useStateWithCB();
  const [mappingError, setMappingError] = useState('');
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  // const [createMappingObj, SetCreateMappingObj] = useState({});
  const [stepperValidate, SetStepperValidate] = useState(false);
  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const { createMappingObj = {} } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const handleEditPreview = () => {
    setIsPreview(true);
  };

  const handleStepValue = () => {};

  const handleSQData = (data, cb) => {
    setSQPreviewData(data);
    setIsPreview(true);
  };

  const handleErrorFlag = (data) => {
    if (!data) {
      setSaveFlag(false);
    } else {
      setSaveFlag(true);
    }
  };

  const handleDmData = (data) => {
    setDmRulesArray(data);
  };

  const passRowData = (data) => {
    setSQPreviewData(data);
  };

  return (
    <>
      <Box pt={0} pl={3} pr={3}>
        <Typography variant="h3">Supplemental Qualifiers (Optional)</Typography>
        <Box mt={2}>
          <Grid container spacing={0} style={{ marginTop: '1rem' }}>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Product Mnemonic
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Description
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.description}
              </Typography>
            </Grid>
          </Grid>
          {isPreview ? (
            <Typography style={{ color: 'red', marginTop: '10px' }}>
              {mappingError ? mappingError : ''}
            </Typography>
          ) : null}
        </Box>
        {isPreview ? (
          <SupplementalQualifierPreview
            data={SQPreviewData}
            handleStepValue={handleStepValue}
            handleShowPreview={() => setIsPreview(false)}
            validationData={SQValidationDataCheck}
            handleSQPreviewFlag={() => {}}
            DmRulesData={DmRulesArray}
            handleNewValidationData={setSQValidationDataCheck}
            passRowData={passRowData}
            setConfirmAlert={setConfirmAlert}
            isPreviewDataUploaded={isPreviewDataUploaded}
            setIsPreviewDataUploaded={setIsPreviewDataUploaded}
            // mappingError={mappingError}
          />
        ) : (
          <>
            <Grid container xs={12}>
              <Grid item xs={4} style={{ marginTop: '50px' }}>
                <SupplementalCard
                  handlePreview={handleEditPreview}
                  handleSQData={handleSQData}
                  handleSQValidationData={setSQValidationDataCheck}
                  handleSQPreviewFlag={() => {}}
                  handleErrorFlag={handleErrorFlag}
                  passDmData={handleDmData}
                  createMappingObj={createMappingObj}
                  setMappingError={setMappingError}
                  setIsPreviewDataUploaded={setIsPreviewDataUploaded}
                  handleStepperValidate={SetStepperValidate}
                  // handleSetRows={handleSetRows}
                  // mappingId={mappingId}
                  // SQData={data !== undefined ? props.handleSQData(data) : handleSQData }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>

      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </>
  );
};

export default SupplementalQualifier;
