/* eslint-disable no-unused-vars */
import React from 'react'

const PinIcon = ({color}) => {
  return (
    <div style={{marginTop:"5px", cursor:"pointer"}}>
        <svg width="18" height="18" viewBox="0 0 24 24" fill='none' xmlns="http://www.w3.org/2000/svg"><path d="M23.4918 6.27505L17.7278 0.510052C17.41 0.191225 16.9644 0.0342527 16.517 0.0834743C16.0695 0.132696 15.6687 0.382767 15.4278 0.763053L11.3278 7.20605C9.46991 6.76373 7.51314 7.05001 5.85982 8.00605C5.46347 8.23744 5.19689 8.63961 5.13816 9.09479C5.07943 9.54997 5.23518 10.0066 5.55982 10.3311L13.6648 18.4311C13.9905 18.7528 14.4453 18.9082 14.8998 18.8531C15.355 18.7946 15.7575 18.5288 15.9898 18.1331C16.95 16.4816 17.2366 14.5234 16.7898 12.6661L23.2328 8.56605C23.6117 8.32522 23.8607 7.92545 23.9099 7.47925C23.9591 7.03304 23.8031 6.58863 23.4858 6.27105L23.4918 6.27505ZM17.2918 3.42705L15.1718 6.76005C15.0208 6.98891 14.7154 7.05662 14.4818 6.91305C14.2488 6.76501 14.1799 6.45613 14.3278 6.22305L16.4488 2.89105C16.5429 2.7365 16.7131 2.6448 16.8939 2.65128C17.0747 2.65775 17.2379 2.7614 17.3206 2.92229C17.4033 3.08318 17.3927 3.27622 17.2928 3.42705H17.2918Z" fill={color}></path><path d="M6.45389 15.428L0.513887 21.368C-0.0716862 21.9538 -0.0716862 22.9033 0.513887 23.489C1.10342 24.0654 2.04535 24.0654 2.63489 23.489L8.57489 17.549C9.14334 16.9605 9.13521 16.0249 8.55661 15.4463C7.97801 14.8677 7.04245 14.8596 6.45389 15.428Z" fill={color}></path>
    </svg>
    </div>
  )
}

export default PinIcon