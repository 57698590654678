/* eslint-disable*/
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import FilterIcon from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Table from 'apollo-react/components/Table';
import React, { useEffect, useState } from 'react';
import { convertExcelToJson } from '../../../Utils';
import column from './filterData';

const useStyles = makeStyles({
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  }
});

const CustomButtonHeader = ({ toggleFilters }) => {
  return (
    <div>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: 15 }}
        onClick={toggleFilters}>
        Filter
      </Button>
    </div>
  );
};

const PreviewTable = (props) => {
  const { rows, selectedFile, title, subtitle } = props;
  const [rowData, setRowData] = useState(rows || []);
  const classes = useStyles();
  useEffect(() => {
    if (rowData && rowData.length === 0) {
      convertExcelToJson(selectedFile)
        .then((res) => {
          setRowData(res);
        })
        .catch((e) => {
          console.log('ERROR :: ', e);
        });
    }
  }, []);

  return (
    <Box mt={2} className={classes.tableHorizontalOverflow}>
      <Table
        classes={classes.tableHorizontalOverflow}
        title={title}
        subtitle={subtitle}
        columns={column}
        rows={rowData}
        // columnSettings={{ enabled: true, frozenColumnsEnabled: true }}
        CustomHeader={(props) => <CustomButtonHeader {...props} />}
        hasScroll
        maxHeight={650}
        initialSortedColumn="versionName"
        initialSortOrder="asc"
        rowsPerPageOptions={[10, 20, 50, 100, 'All']}
        tablePaginationProps={{
          // labelDisplayedRows: ({ from, to, count }) =>
          //   `${count === 1 ? 'item' : 'items'} ${from}-${to} of ${count}`,
          truncate: true
        }}
        defaultRowsPerPage={10}
      />
    </Box>
  );
};

export default PreviewTable;
