/*eslint-disable */
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Button from 'apollo-react/components/Button';
import SourceData from '../Preview/Components/SourceData';
import EditTrailName from '../Preview/Components/EditTrailName';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReviewAndPublish from './ReviewAndPublish/ReviewAndPublish';
import PublishStatus from './PublishStatus/PublishStatus';
import SelectEnvironment from './SelectEnvironment/SelectEnvironment';
import PulishDataExport from './DataExport/DataExport';
import ValidateSource from './Validation/ValidateSource';
import {
  setSelectedEnvironment,
  setDataExportDetails,
  setSelectSourceDeatils,
  setSelectedScehduler,
  saveEnvironments,
  setSelectedSourceData,
  setStepperStatus,
  setExportTypeMetaData,
  setDomainAndColumnMetaData,
  setScheduleFrequencies,
  setSelectedPreferences
} from 'Redux/Slice/PublishMappingSpecSlice';
import { useDispatch } from 'react-redux';
import CustomModal from 'Components/Modal';
import { setselectedDomainSourceData } from 'Redux/Slice/DomainPeviewSlice';

const PublishMappingSpec = (props) => {
  const location = useLocation();
  const { activeStep, setActiveStep, toggleSidebar } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  useEffect(() => {
    dispatch(saveEnvironments([]));
    dispatch(setSelectedEnvironment({}));
    dispatch(setSelectedScehduler({}));
    
    dispatch(setSelectSourceDeatils({}));
    dispatch(setSelectedSourceData([]));
    dispatch(setStepperStatus({}));
    
    dispatch(setDataExportDetails([]));
    dispatch(setDomainAndColumnMetaData([]));
    dispatch(setExportTypeMetaData([]));
    dispatch(setScheduleFrequencies([]));
    dispatch(setSelectedPreferences({}));
    //clearing cached Domain Source Data for select soruces stepper
    dispatch(setselectedDomainSourceData({}));
    setActiveStep(0);
  }, []);

  const handleReturn = (returnTo) => {
    let path = '';
    if (returnTo === 'DASHBOARD') {
      path = '/dashboard';
    } else {
      path =
        location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
          ? '/manage-data-pipeline'
          : '/product-designer';
    }
    setConfirmAlert({
      enabled: true,
      variant: 'default',
      title: 'Leave Page?',
      message: 'Changes could not be saved if you leave this page, Would you still like to leave?',
      onConfirm: () => {
        setConfirmAlert({
          enabled: false
        });
        navigate(path);
      },
      onCancel: () => {
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  const handleNext = () => {
    setActiveStep((step) => step + 1);
  };

  const handlePrevious = () => {
    setActiveStep((step) => step - 1);
  };

  const renderSteps = () => {
    switch (props.activeStep) {
      case 0:
        return <SelectEnvironment {...props} handleCancel={handleReturn} handleNext={handleNext} />;
      case 1:
        return (
          <SourceData
            type="Publish"
            handleNext={handleNext}
            handleBack={handlePrevious}
            publishPathflag
          />
        );
      case 2:
        return <EditTrailName type="Publish" handleNext={handleNext} handleBack={handlePrevious} />;
      case 3:
        return (
          <ValidateSource type="Publish" handleNext={handleNext} handleBack={handlePrevious} />
        );
      case 4:
        return (
          <PulishDataExport {...props} handleReturn={handleReturn} toggleSidebar={toggleSidebar} />
        );
      case 5:
        return <ReviewAndPublish {...props} />;
      case 6:
        return <PublishStatus {...props} />;
    }
  };
  return (
    <>
      <div {...props}>
        {activeStep !== 5 && activeStep !== 4 && (
          <Button
            icon={
              <ChevronLeft
                style={{
                  height: '15px'
                }}
              />
            }
            style={{
              backgroundColor: 'white',
              color: '#0768FD',
              fontFamily: 'Proxima Nova',
              fontSize: '13px',
              fontWeight: 600,
              marginBottom: '1rem'
            }}
            variant={'text'}
            size={'small'}
            onClick={handleReturn}
            data-testid="return-to-data-products">
            {location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
              ? 'Return to Manage Data Pipelines'
              : 'Return to data products'}
          </Button>
        )}
        {renderSteps()}
      </div>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </>
  );
};

export default PublishMappingSpec;
