import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Table, { createStringSearchFilter } from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import moment from 'moment';

const DateCell = ({ row, column: { accessor } }) => {
  const rowValue = row[accessor];
  const date =
    rowValue && moment(rowValue, 'M/DD/YYYY').isValid()
      ? moment(rowValue, 'M/DD/YYYY').format('M/D/YYYY')
      : rowValue;
  return <span>{date}</span>;
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const compareStrings = (accessor, sortOrder) => {
  return function (rowA, rowB) {
    let stringA = rowA[accessor] ? rowA[accessor].toString().toUpperCase() : '';
    let stringB = rowB[accessor] ? rowB[accessor].toString().toUpperCase() : '';
    if (sortOrder === 'asc') {
      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      return 0;
    } else {
      if (stringA < stringB) {
        return 1;
      }
      if (stringA > stringB) {
        return -1;
      }
      return 0;
    }
  };
};

const columns = [
  {
    header: 'USUBJID',
    accessor: 'usubjid',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('usubjid'),
    width: 200,
    fixedWidth: false
  },
  {
    header: 'BRTHDTC',
    accessor: 'brthdtc',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('brthdtc'),
    filterComponent: TextFieldFilter,
    customCell: DateCell,
    width: 200,
    fixedWidth: false
  },
  {
    header: 'SEX',
    accessor: 'sex',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('sex'),
    width: 100,
    fixedWidth: false
  },
  {
    header: 'GRPID',
    accessor: 'groupId',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('groupId'),
    width: 100,
    fixedWidth: false
  },
  {
    header: 'CLSTRID',
    accessor: 'clusterId',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('clusterId'),
    width: 100,
    fixedWidth: false
  },
  {
    header: 'Locators',
    accessor: 'locators',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('locators'),
    width: 300,
    fixedWidth: false
  },
  {
    header: 'Last Updated',
    accessor: 'lastUpdated',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('lastUpdated'),
    width: 300,
    fixedWidth: false
  }
];

const CustomButtonHeader = (props) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="title1" gutterBottom>
          Mastered Subjects
        </Typography>

        <Typography variant="body2" gutterBottom>
          {props.domainCode}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="secondary" icon={Filter} onClick={props.toggleFilters} size={'small'}>
            Filter
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

const MasteredSubjectsTable = (props) => {
  const { domainCode, masteredData } = props;
  let currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  return (
    <Table
      columns={columns}
      rows={masteredData.map((row) => ({
        ...row,
        key: row.key
      }))}
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      initialSortedColumn="lastUpdated"
      initialSortOrder="desc"
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      CustomHeader={(prop) => (
        <CustomButtonHeader {...prop} currentPath={currentPath} domainCode={domainCode} />
      )}
    />
  );
};

export default MasteredSubjectsTable;
