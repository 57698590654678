import Button from 'apollo-react/components/Button';
import Box from 'apollo-react/components/Box';
import Typography from 'apollo-react/components/Typography';
import { neutral7 } from 'apollo-react/colors';

import NoRulesetsIcon from 'Images/no-rulesets.svg';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  top: 'calc(55%)',
  transform: 'translateY(-50%)',
  width: 'calc(100% - 20vw - 4rem)',
  fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
});

const SearchIcon = styled.img({
  height: '32px',
  width: '32px',
  marginBottom: '1rem',
  color: neutral7
});

const ContentText = styled(Typography)({ fontSize: '18px', fontWeight: '600', color: neutral7 });
const MessageText = styled(Typography)({
  fontSize: '14px',
  width: 'clamp(200px, 40%, 400px)',
  marginBottom: '1rem'
});

const NoRulesetsMessage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <SearchIcon src={NoRulesetsIcon} />
      <ContentText>No rulesets added</ContentText>
      <MessageText>
        Looks like no ruleset has been added for this study, Click the button below to visit the
        study library page to create one.
      </MessageText>
      <Button
        variant={'primary'}
        onClick={() => navigate('/data-standard-library/study-library')}>
        Go to Study Library Page
      </Button>
    </Container>
  );
};

export default NoRulesetsMessage;
