import Modal from 'apollo-react/components/Modal';
import React from 'react';
const CustomModal = (props) => {
  const handleButtonProp = () => {
    if (props.type === 'Preview' || props.type === 'History') {
      return [{ label: props.buttonSecondardyLabel, onClick: props.handleSecondaryAction }];
    } else if (props.type === 'Delete') {
      return [
        { label: props.buttonSecondardyLabel, onClick: props.handleSecondaryAction },
        {
          label: props.buttonPrimaryLabel,
          onClick: props.handlePrimaryAction,
          disabled: !props.show
        }
      ];
    } else if (props.type === 'disable') {
      return [
        { label: props.buttonSecondardyLabel, onClick: props.handleSecondaryAction },
        { label: props.buttonPrimaryLabel, onClick: props.handlePrimaryAction, disabled: true }
      ];
    }
    return [
      { label: props.buttonSecondardyLabel, onClick: props.handleSecondaryAction },
      { label: props.buttonPrimaryLabel, onClick: props.handlePrimaryAction }
    ];
  };

  return (
    <Modal
      {...props}
      open={props.display}
      onClose={props.handleClose}
      title={props.title}
      subtitle={props.subtitle}
      message={props.message}
      buttonProps={props.buttonProps || handleButtonProp()}
      id={props.type}
      className={props.width}
      variant={props.variant}
      data-testid="tabel">
      {props.body}
    </Modal>
  );
};

export default CustomModal;
