import Typography from 'apollo-react/components/Typography';
import React from 'react';

const ContentHolder = (props) => {
  const { title, value } = props;
  return (
    <React.Fragment>
      <Typography
        variant="body2"
        style={{
          fontSize: '16px',
          color: '#595959',
          letterSpacing: 0,
          lineHeight: '24px',
          fontFamily: 'Proxima Nova'
        }}>
        {title}
      </Typography>
      <Typography
        variant="title"
        style={{
          color: '#000000',
          fontSize: '16px',
          letterSpacing: 0,
          lineHeight: '24px',
          fontFamily: 'Proxima Nova',
          overflowWrap: 'break-word'
        }}>
        {value}
      </Typography>
    </React.Fragment>
  );
};

export default ContentHolder;
