/* eslint-disable */

import CloneDataProductRoutes from './routes';

export default [
  {
    path: '/product-designer/clone-data-product/*',
    component: CloneDataProductRoutes,
    authentication: true,
    layout: (props) => props.children
  }
];
