/* eslint-disable */
import Breadcrumbs from 'apollo-react/components/Breadcrumbs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Breadcrumb = (props) => {
  const { items } = useSelector((state) => state.breadCrumbData);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const handleClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const newState = items.map((item) => {
      return {
        ...item,
        onClick: item.isHome ? item.handleClick : () => handleClick(item.path),
        href: 'javascript:void(0);'
      };
    });
    setData(newState);
  }, [items]);

  return (
    <div className="sm-crumbs">
      <Breadcrumbs
        className={`sm-breadcrumb ${props.className}`}
        style={
          props.sidebar
            ? {
                background: 'transparent',
                padding: '13px 15px'
              }
            : props.addStudy
            ? { background: 'transparent', padding: '13px 15px' }
            : props.sourceConfig
            ? { background: 'transparent', padding: '13px 15px', marginTop: '40px' }
            : props.studyGrid
            ? { background: '#FFFFFF', padding: '13px 35px', marginTop: '15px' }
            : props.studyGridPin
            ? { background: '#FFFFFF', padding: '13px 18px' }
            : props.preview
            ? { background: 'none', padding: '0', marginTop: '10%' }
            : props.style || { background: '#FFFFFF', padding: '13px 18px' }
        }
        items={data}
      />
    </div>
  );
};

export default Breadcrumb;
