/* eslint-disable no-unused-vars */
import METHODS from './methods';
import Parser from './Parser';
import { validateDecodeMethod, validateMaxMethod, validateMinMethod } from './utils';
import ValidateExpression from './ValidateExpression';
import ValidateMateredDataSetExpression from './ValidateMateredDataSetExpression';

const validate = (expression, defaultForm, codeListDataMapping, formsObject = {}, serviceResult, type = 1) => {
  let result;
  if (defaultForm === undefined) {
    defaultForm = '';
  }
  if (expression) {
    expression = expression.trim();
  }
  const parser = new Parser(expression, formsObject, defaultForm, type);
  const res = parser.verify();
  if (res) {
    result = parser.validateExpression();
    if (result.isValid && type !== 2) {
      result = validateDecodeMethod(expression, defaultForm, codeListDataMapping);
      if (result.isValid) {
        result = validateMinMethod(expression, serviceResult);
        if (result.isValid) {
          result = validateMaxMethod(expression, serviceResult);
        }
      }
    }
  } else {
    const expressionObj =
      type === 2
        ?
        new ValidateMateredDataSetExpression(
          expression,
          METHODS,
        )
        :
        new ValidateExpression(
          expression,
          METHODS,
          formsObject,
          `[${defaultForm}]`,
          type
        );
    result = expressionObj.parse();
  }

  return result;
};

export default validate;
