const studySearch = (value, protocols) => {
    let inp = value.toString().toLowerCase();
    const data = [];

    protocols.forEach(item => {
        let filteredItem = (item.protocolNumber).concat(item.projectCode, item.sponsor);
        if (filteredItem.toString().toLowerCase().indexOf(inp) !== -1) {
            data.push(item)
        }
    })

    return {data : data, flag : data.length ? false : true};
}

export default studySearch