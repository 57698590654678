/*eslint-disable */
import { unwrapResult } from '@reduxjs/toolkit';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCodeLists } from 'Redux/Service/ReferenceDataCardsService';
import { getSourceName, useStateWithCB, uuidv4 } from 'Utils';
import Header from 'Components/Header';
import { BannerExample } from 'Components/Banner/Banner';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { neutral1 } from 'apollo-react/colors';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import { GetAutoMappingConfig } from 'Redux/Service/AutoMappingService';

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginTop: '113px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: neutral1,
    boxSizing: 'border-box',
    justifyContent: 'flex-start'
  }
});

export const BulkEditContext = createContext();

export const BulkEditLayout = (props) => {
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();

  const [filters, setFilters] = useStateWithCB({});

  const [domainData, setDomainData] = useState([]);
  const [domainFormattedData, setDomainFormattedData] = useState({});
  const [newDomainFormattedData, setNewDomainFormattedData] = useState({});

  const [formMetaData, setFormMetaData] = useState({});
  const [methodsConstants, setMethodsAndConstants] = useState([]);

  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [bulkEditDomain, setBulkEditDomain] = useState({});
  const [bulkEditDomainInitial, setBulkEditDomainInitial] = useState({});
  const [isVariablesSame, setIsVariablesSame] = useState(false);
  const [selectedFilterNameInBulkScreen, setSelectedFilterNameInBulkScreen] = useState(0);
  const [bulkEditFilters, setbulkEditFilters] = useState([]);
  const [filterToVariablesMapping, setFilterToVariablesMapping] = useState({});
  const [codeListDataMapping, setCodeListDataMapping] = useState(null);
  const [autoMappingConfig, setAutoMappingConfig] = useState({});

  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const projectHeaderDisplayed = useMemo(() => {
    return Boolean(protocol && Object.keys(protocol).length !== 0);
  }, [protocol]);

  const classes = useStyles();

  const { state: { domainCode } = {} } = useLocation();

  useEffect(() => {
    (async function () {
      if (!codeListDataMapping) {
        try {
          const payload = {
            mappingRuleVersionID
          };
          const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
          if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
            const mapping = {};
            const codelists = codeListsGetAPIData.data.ruleCodeLists || [];

            for (let { odmForm, odmItem, sdmCodelistLabel } of codelists) {
              const key = `${odmForm.toLowerCase()}#-#${odmItem.toLowerCase()}`;
              if (!(key in mapping)) {
                mapping[key] = [];
              }
              if (sdmCodelistLabel.trim()) mapping[key].push(sdmCodelistLabel.toLowerCase());
            }
            setCodeListDataMapping(mapping);
          }
        } catch (error) {
          setCodeListDataMapping({});
          console.log(error);
        }
      }
    })();
  }, [codeListDataMapping]);

  useEffect(() => {
    (async function () {
      const response = await dispatch(GetAutoMappingConfig()).then(unwrapResult);
      if (response && response.data && response.data.success) {
        setAutoMappingConfig({
          autoMappingDomains: response.data.autoMappingDomains,
          allowedTargets: response.data.allowedTargets,
          allowedSources: response.data.allowedSources?.map((source) => getSourceName(source))
        });
      }
    })();
  }, []);

  const [sourceFormItems, formatedFormItemData] = useMemo(() => {
    const formatedFormItemData = [];
    const sourceFormItems = {};
    for (let libraryType in formMetaData) {
      for (let formData of formMetaData[libraryType]) {
        if (formData.itemName) {
          formatedFormItemData.push({
            formName: formData.formName,
            itemName: formData.itemName,
            libraryType,
            formatedString: `[${formData.formName}].[${formData.itemName}]`
          });
          if (sourceFormItems[formData.formName])
            sourceFormItems[formData.formName].items.push({
              srcItemName: formData.itemName,
              srcItemOid: formData.itemOID
            });
          else
            sourceFormItems[formData.formName] = {
              items: [{ srcItemName: formData.itemName, srcItemOid: formData.itemOID }],
              sourceName: getSourceName(libraryType),
              srcFormOid: formData.formOID
            };
        }
      }
    }
    return [sourceFormItems, formatedFormItemData];
  }, [formMetaData]);

  const getCompleteness = (variables, index) => {
    const prevVariables = bulkEditFilters.length
      ? JSON.parse(JSON.stringify(bulkEditFilters[index].variables))
      : '';
    let complete = 0;
    let incomplete = 0;
    let count = 0;

    for (const key in variables) {
      count++;
      if (variables[key].noMap || variables[key].expressions.length) {
        complete++;
      } else {
        incomplete++;
      }
    }

    if (Object.values(prevVariables).length === 0) {
      return 0;
    } else if (Object.values(prevVariables).length < count) {
      return 0.5;
    } else if (complete === count) {
      return 1;
    } else if (incomplete === count) {
      return 0;
    } else {
      return 0.5;
    }
  };

  const setAllVariables = (domain, bulkEditFilters) => {
    let map = {};
    bulkEditFilters.forEach((filter, index) => {
      const prevVariables = JSON.parse(JSON.stringify(bulkEditFilters[index].variables));
      for (let key in prevVariables) {
        prevVariables[key].disable = false;
      }

      /*
      types:
      S => system defined
      R => reserved
      */
      const allVariables = Object.fromEntries(
        JSON.parse(JSON.stringify(domainFormattedData[domain]))
          .filter((v) => v.mappedBy !== 'R')
          .map((v) => {
            return [
              v.variableName,
              {
                noMap: true,
                expressions: [],
                disable: v.mappedBy === 'S',
                seqForOrder: v.seqForOrder,
                isSUPPQUAL: v?.isSUPPQUAL,
                role: v?.role,
                autoNoMap: true
              }
            ];
          })
      );

      for (const key in allVariables) {
        if (key in prevVariables) {
          prevVariables[key].disable = allVariables[key].disable;
          prevVariables[key].seqForOrder = allVariables[key].seqForOrder;
          prevVariables[key].isSUPPQUAL = allVariables[key]?.isSUPPQUAL;
          prevVariables[key].role = allVariables[key]?.role;
        } else {
          prevVariables[key] = allVariables[key];
        }
      }

      // set the nomap
      for (const key in prevVariables) {
        if (prevVariables[key].disable) {
          prevVariables[key].noMap = false;
        } else {
          if (!('noMap' in prevVariables[key])) {
            prevVariables[key].noMap = true;
          }
        }
      }

      delete prevVariables[''];
      const status = getCompleteness(prevVariables, index);
      map[filter.filterName] = { variables: prevVariables, status };
    });

    setFilterToVariablesMapping(map);
    return map[bulkEditFilters[selectedFilterNameInBulkScreen].filterName].variables;
  };

  const handleVariableUpdate = (type, variableName, sourceName, active, index, value) => {
    switch (type) {
      case 'TOGGLE_CHECKBOX': {
        bulkEditDomain[variableName].noMap = !bulkEditDomain[variableName].noMap;
        if (!bulkEditDomain[variableName].noMap) {
          if (bulkEditDomain[variableName].expressions.length === 0) {
            bulkEditDomain[variableName].expressions.push({
              expression: '',
              id: uuidv4(),
              isValid: false,
              inProgress: false,
              sourceName,
              active
            });
          }
        } else {
          const domainExpressions = bulkEditDomain[variableName].expressions;
          if (
            domainExpressions.length &&
            !domainExpressions[domainExpressions.length - 1].expression
          ) {
            domainExpressions.pop();
          }
        }
        setBulkEditDomain({ ...bulkEditDomain });
        break;
      }
      case 'ADD_INPUT_FIELD': {
        bulkEditDomain[variableName].expressions.push({
          expression: '',
          id: uuidv4(),
          isValid: false,
          inProgress: false,
          sourceName,
          active
        });
        setBulkEditDomain({ ...bulkEditDomain });
        break;
      }
      case 'DELETE_INPUT_FIELD': {
        bulkEditDomain[variableName].expressions.splice(index, 1);
        setBulkEditDomain({ ...bulkEditDomain });
        break;
      }
      case 'TYPE_INPUT_FIELD': {
        bulkEditDomain[variableName].expressions[index].expression = value;
        bulkEditDomain[variableName].expressions[index].sourceName =
          sourceName || bulkEditDomain[variableName].expressions[index].sourceName;
        setBulkEditDomain({ ...bulkEditDomain });
        break;
      }
      case 'AUTOMAP_EXPRESSION_SELECT': {
        if (
          bulkEditDomain[variableName].noMap ||
          bulkEditDomain[variableName].expressions.length === 0
        ) {
          bulkEditDomain[variableName].noMap = false;
          bulkEditDomain[variableName].expressions.push({
            expression: value,
            id: uuidv4(),
            isValid: false,
            inProgress: false,
            sourceName,
            active
          });
        } else {
          bulkEditDomain[variableName].expressions[index].expression = value;
          bulkEditDomain[variableName].expressions[index].sourceName =
            sourceName || bulkEditDomain[variableName].expressions[index].sourceName;
        }
        setBulkEditDomain({ ...bulkEditDomain });
        break;
      }
      default: {
        break;
      }
    }
  };

  const contextData = {
    filters,
    setFilters,
    formMetaData,
    setFormMetaData,
    domainData,
    setDomainData,
    domainFormattedData,
    setDomainFormattedData,
    newDomainFormattedData,
    setNewDomainFormattedData,
    methodsConstants,
    setMethodsAndConstants,
    showBulkEdit,
    setShowBulkEdit,
    bulkEditFilters,
    setbulkEditFilters,
    codeListDataMapping,
    setCodeListDataMapping,
    bulkEditDomain,
    setBulkEditDomain,
    filterToVariablesMapping,
    setFilterToVariablesMapping,
    selectedFilterNameInBulkScreen,
    setSelectedFilterNameInBulkScreen,

    sourceFormItems,
    formatedFormItemData,
    domainCode,

    setAllVariables,
    handleVariableUpdate,
    isVariablesSame,
    setIsVariablesSame,
    bulkEditDomainInitial,
    setBulkEditDomainInitial,
    autoMappingConfig
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Header />
        {projectHeaderDisplayed && <ProjectStudyHeader />}
        <BannerExample />
        <div className={`${classes.container}`}>
          <BulkEditContext.Provider value={contextData}>{props.children}</BulkEditContext.Provider>
        </div>
      </Grid>
    </>
  );
};
