export const MessageConstants = {
  // DataExport - Domain & Columns
  EXPORT_OVERWRITE_DATA_FILENAME_LABEL: 'EXPORT_OVERWRITE_DATA_FILENAME_LABEL',
  EXPORT_OVERWRITE_DATA_FILENAME_TOOLTIP: 'EXPORT_OVERWRITE_DATA_FILENAME_TOOLTIP',
  EXPORT_OVERWRITE_DATA_FILENAME_WARNING_TITLE: 'EXPORT_OVERWRITE_DATA_FILENAME_WARNING_TITLE',
  EXPORT_OVERWRITE_DATA_FILENAME_WARNING_DESC: 'EXPORT_OVERWRITE_DATA_FILENAME_WARNING_DESC',
  EXPORT_DOMAIN_DATA_FILENAME_COLUMN_LABEL: 'EXPORT_DOMAIN_DATA_FILENAME_COLUMN_LABEL',

  // DataExport - SuppQual Dropdown values
  EXPORT_SEPERATE_TABLE_TITLE: 'EXPORT_SEPERATE_TABLE_TITLE',
  EXPORT_SEPERATE_TABLE_DESC: 'EXPORT_SEPERATE_TABLE_DESC',
  EXPORT_PRIMARY_TABLE_TITLE: 'EXPORT_PRIMARY_TABLE_TITLE',
  EXPORT_PRIMARY_TABLE_DESC: 'EXPORT_PRIMARY_TABLE_DESC',
  EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_TITLE: 'EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_TITLE',
  EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_DESC: 'EXPORT_IN_PRIMARY_AND_SEPERATE_TABLE_DESC',
  DO_NOT_EXPORT_TITLE: 'DO_NOT_EXPORT_TITLE',
  DO_NOT_EXPORT_DESC: 'DO_NOT_EXPORT_DESC',

  // DataExport - Export Type
  EXPORT_TYPE_CUMULATIVE_TITLE: 'EXPORT_TYPE_CUMULATIVE_TITLE',
  EXPORT_TYPE_CUMULATIVE_DESC: 'EXPORT_TYPE_CUMULATIVE_DESC',
  EXPORT_TYPE_INCREMENTAL_TITLE: 'EXPORT_TYPE_INCREMENTAL_TITLE',
  EXPORT_TYPE_INCREMENTAL_DESC: 'EXPORT_TYPE_INCREMENTAL_DESC',

  // DataExport - Errors
  EXPORT_ERROR_DATA_FILENAME_EXIST: 'EXPORT_ERROR_DATA_FILENAME_EXIST',
  EXPORT_ERROR_COLUMN_ERROR_EXIST: 'EXPORT_ERROR_COLUMN_ERROR_EXIST'
};