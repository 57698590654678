/* eslint-disable */
import React, { useEffect } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import StudyInfo from 'Components/SourceStudyDisplay/StudyDisplayInfo';
import TabComponent from 'Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/MappingSpecComponents/TabComponent';
import { Grid } from 'apollo-react';
import { Title, StudyInfoWrapper } from '../../Common';
const Validation = () => {
  const location = useLocation();
  const { setNextDisabled } = useOutletContext();
  useEffect(() => {
    setNextDisabled(true);
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Title>
            Validation
          </Title>
        </Grid>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo productInfo={location?.state?.selectedSourceStudy} label={'Source Study Information.'} />
        </StudyInfoWrapper>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo productInfo={location?.state?.selectedDataProduct} label={'Source Data Product & Mapping Spec Information'} />
        </StudyInfoWrapper>
        <TabComponent cloned disableMappingButton={setNextDisabled} />
      </Grid>
    </>
  )
}

export default Validation;
