import { makeStyles } from '@mui/styles';
import Clock from 'apollo-react-icons/Clock';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import StatusNegative from 'apollo-react-icons/StatusNegative';
import { green, orange, primary, red } from 'apollo-react/colors';
import InProgress from 'Images/status-inprogress.svg';
import Queued from 'Images/status-queued.svg';
import CalendarCheck from 'apollo-react-icons/CalendarCheck';
import React from 'react';

const useLoaderStatusStyles = makeStyles({
  status: {
    maxWidth: '7rem',
    minHeight: '1.6rem',
    backgroundColor: 'gray',
    borderRadius: '0.2rem',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&>svg': {
      height: '1.2rem',
      paddingRight: '0.2rem'
    },
    '&>span': {
      maxWidth: '5rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#FFFFFF !important'
    }
  },
  successStatus: {
    backgroundColor: green
  },
  inProgressStatus: {
    backgroundColor: primary
  },
  warningStatus: {
    backgroundColor: orange
  },
  failedStatus: {
    backgroundColor: red
  }
});

/**
 * Component to render status for the job record/row
 * @props {object} row
 */

const TooltipStatus = ({ row }) => (
  <span>
    {row.loaderStatusMessageLable}
    <span className="tooltiptext tooltip-bottom2"> {row.loaderStatusMessageLable}</span>
  </span>
);

const TooltipDataSetStatus = ({ row }) => (
  <span>
    {row.datasetStatus}
    <span className="tooltiptext tooltip-bottom2"> {row.datasetStatus}</span>
  </span>
);

export const DataSetStatus = ({ row }) => {
  const classes = useLoaderStatusStyles();
  switch (row.datasetStatus?.toLowerCase()) {
    case 'successful': {
      return (
        <div className={`${classes.status} ${classes.successStatus} tooltip`}>
          <StatusCheck />
          <TooltipDataSetStatus row={row} />
        </div>
      );
    }
    case 'warning': {
      return (
        <div className={`${classes.status} ${classes.warningStatus} tooltip`}>
          <StatusExclamation />
          <TooltipDataSetStatus row={row} />
        </div>
      );
    }
    case 'failed': {
      return (
        <div className={`${classes.status} ${classes.failedStatus} tooltip`}>
          <StatusNegative />
          <TooltipDataSetStatus row={row} />
        </div>
      );
    }
    default: {
      return '';
    }
  }
};

export const LoaderStatus = ({ row }) => {
  const classes = useLoaderStatusStyles();
  switch (row.loaderStatusType?.toLowerCase()) {
    case 'processed': {
      return (
        <div className={`${classes.status} ${classes.successStatus} tooltip`}>
          <StatusCheck />
          <TooltipStatus row={row} />
        </div>
      );
    }
    case 'inprogress':
    case 'loaderinprogress': {
      return (
        <div className={`${classes.status} ${classes.inProgressStatus} tooltip`}>
          <Clock />
          <TooltipStatus row={row} />
        </div>
      );
    }
    case 'warnings': {
      return (
        <div className={`${classes.status} ${classes.warningStatus} tooltip`}>
          <StatusExclamation />
          <TooltipStatus row={row} />
        </div>
      );
    }
    case 'failed': {
      return (
        <div className={`${classes.status} ${classes.failedStatus} tooltip`}>
          <StatusNegative /> <TooltipStatus row={row} />
        </div>
      );
    }
    case undefined:
    case null:
    case '': {
      return '';
    }
    default: {
      return (
        <span className={`${classes.status} tooltip`}>
          <TooltipStatus row={row} />
        </span>
      );
    }
  }
};

const useQueueStatusStyles = makeStyles({
  status: {
    minWidth: '8rem',
    width: 'fit-content',
    height: '1.6rem',
    borderRadius: '0.2rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&>img': {
      paddingLeft: '0.3rem',
      paddingRight: '0.2rem'
    }
  },
  successStatus: {
    '&>svg': {
      color: green,
      height: '1.2rem',
      paddingRight: '0.2rem'
    }
  }
});

export const QueueStatus = ({ row }) => {
  const classes = useQueueStatusStyles();
  switch (row.queueStatusType?.toLowerCase()) {
    case 'completed': {
      return (
        <div className={`${classes.status} ${classes.successStatus}`}>
          <StatusCheck /> {row.queueStatusMessageLable}
        </div>
      );
    }
    case 'queued': {
      return (
        <div className={`${classes.status}`}>
          <img
            src={`${Queued}`}
            alt={'processing'}
            style={{ height: '15px', marginRight: '5px' }}
          />{' '}
          {row.queueStatusMessageLable}
        </div>
      );
    }
    case 'inprogress':
    case 'queueinprogress': {
      return (
        <div className={`${classes.status}`}>
          <img
            src={`${InProgress}`}
            alt={'processing'}
            style={{ height: '15px', marginRight: '5px' }}
          />{' '}
          {row.queueStatusMessageLable}
        </div>
      );
    }
    case 'scheduled': {
      return (
        <div className={`${classes.status}`}>
          <CalendarCheck style={{ height: '18px', marginRight: '3px', color: '#9e54b0' }} />{' '}
          {row.queueStatusMessageLable}
        </div>
      );
    }

    case 'notscheduled': {
      return (
        <div className={`${classes.status}`}>
          <svg
            width="16"
            viewBox="0 0 14 14"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: '3px' }}>
            <path
              d="M4.90778 11.2L6.80556 9.492L8.70333 11.2L9.52778 10.458L7.63 8.75L9.52778 7.042L8.70333 6.3L6.80556 8.008L4.90778 6.3L4.08333 7.042L5.98111 8.75L4.08333 10.458L4.90778 11.2ZM12.4444 1.4H11.6667V0H10.1111V1.4H3.88889V0H2.33333V1.4H1.55556C0.692222 1.4 0.00777777 2.03 0.00777777 2.8L0 12.6C0 13.37 0.692222 14 1.55556 14H12.4444C13.3 14 14 13.37 14 12.6V2.8C14 2.03 13.3 1.4 12.4444 1.4ZM12.4444 12.6H1.55556V4.9H12.4444V12.6Z"
              fill="#999999"
            />
          </svg>{' '}
          {row.queueStatusMessageLable}
        </div>
      );
    }
    default: {
      return <span className={classes.status}>{row.queueStatusMessageLable}</span>;
    }
  }
};
