/*eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Typography from 'apollo-react/components/Typography';
import Stepper from 'apollo-react/components/Stepper';
import StepLabel from 'apollo-react/components/StepLabel';
import Step from 'apollo-react/components/Step';
import Button from 'apollo-react/components/Button';
import Tooltip from 'apollo-react/components/Tooltip';

import Box from '@mui/material/Box';
import { useCloneWorkflow } from '../Common';
import { useLeafPathName } from 'Utils';
import CustomModal from 'Components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';

export default function CloneDataProductSidebar({ isNextDisabled, setNextDisabled }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [nextDisabledMessage, setNextDisabledMessage] = useState(0);
  const [cards, setCards] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const cloneDPWorkflow = useCloneWorkflow();
  const moduleName = useLeafPathName();

  useEffect(() => {
    if (moduleName === 'clone-data-product') {
      navigate(`/product-designer/clone-data-product/${cloneDPWorkflow[0].moduleName}`, {
        state: { ...location.state }
      });
    } else {
      const index = cloneDPWorkflow?.findIndex((data) => {
        return data?.moduleName === moduleName;
      });

      if (index !== undefined && index !== -1) {
        setCards(cloneDPWorkflow);
        setActiveStep(index);
        setNextDisabled(cloneDPWorkflow[index]?.isRequired);
        setNextDisabledMessage(
          `Please complete ${cloneDPWorkflow[index]?.displayName} to proceed to next.`
        );
        navigate(cloneDPWorkflow[index].moduleName, { state: { ...location.state } });
      }

      const breadcrumbPathName =
        cloneDPWorkflow.find((module) => module?.moduleName === moduleName)?.displayName ||
        'Clone Data Product';

      dispatch(
        breadCrumbData([
          { path: '/dashboard' },
          {
            title: 'Data Product Designer',
            path: '/product-designer'
          },
          {
            title: breadcrumbPathName
          }
        ])
      );
    }
  }, [cloneDPWorkflow, moduleName]);

  const handleBack = () => {
    const nextElement = cards[activeStep - 1];
    setActiveStep(activeStep - 1);
    navigate(`/product-designer/clone-data-product/${nextElement.moduleName}`, {
      state: location.state
    });
  };

  const handleNext = () => {
    const nextElement = cards[activeStep + 1];
    setActiveStep(activeStep + 1);
    navigate(`/product-designer/clone-data-product/${nextElement.moduleName}`, {
      state: location.state
    });
    setNextDisabled(false);
  };

  const onStepClick = (moduleName) => {
    const index = cards.findIndex((card) => {
      return card.moduleName === moduleName;
    });
    if (activeStep > index) {
      navigate(`/product-designer/clone-data-product/${cards[index].moduleName}`, {
        state: location.state
      });
      setActiveStep(index);
      setNextDisabled(false);
    }
  };

  const handleConfirm = () => {
    setConfirmAlert(false);
    navigate('/product-designer');
  };

  return (
    <>
      <Box>
        <Box pt={3} pb={3} px={3} style={{ borderBottom: '1px solid #e9e9e9' }}>
          <Typography variant="h3" data-testid="rule">
            Clone Data Product
          </Typography>
        </Box>
        <Box style={{ padding: '30px 20px' }}>
          <div style={{ maxWidth: 400 }}>
            <Stepper box activeStep={activeStep ? activeStep : 0}>
              {cards.map((card, cardIndex) => (
                <Step
                  style={{
                    padding: '1rem',
                    cursor: cardIndex < activeStep && 'pointer'
                  }}
                  key={card.order}
                  onClick={() => onStepClick(card.moduleName)}>
                  <StepLabel>
                    {card.displayName}
                    {/* {card.isRequired ? (
                      <></>
                    ) : (
                      <div style={{ fontWeight: '400', fontSize: '14px', color: '#595959' }}>
                        Optional
                      </div>
                    )} */}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button
                  flex={0.4}
                  variant="text"
                  size="small"
                  label="Back"
                  onClick={() => setConfirmAlert(true)}
                  style={{ paddingLeft: 0 }}
                  data-testid="cancel">
                  Cancel
                </Button>
                <Box display="flex" justifyContent="flex-end" flex={0.6}>
                  {activeStep !== 0 && (
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={handleBack}
                      data-testid="back">
                      Back
                    </Button>
                  )}
                  {activeStep !== cards.length - 1 && (
                    <>
                      <Tooltip title={isNextDisabled && nextDisabledMessage}>
                        <span>
                          <Button
                            variant="primary"
                            size="small"
                            onClick={handleNext}
                            data-testid="next"
                            style={{ marginLeft: '16px' }}
                            disabled={isNextDisabled}>
                            Next
                          </Button>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
      <CustomModal
        display={confirmAlert}
        title={'Leave Page?'}
        variant={'warning'}
        buttonPrimaryLabel={'Ok'}
        message={
          'Changes could not be saved if you leave this page, Would you still like to leave?'
        }
        data-testid="custom-modal"
        handlePrimaryAction={() => handleConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => setConfirmAlert(false)}
      />
    </>
  );
}
