import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Action Creator to Get DomainMap type for provided metadata
 * @param payload - { mappingRuleVersionID, domainName, protocolNumber }
 */
export const GetDomainMapType = createAsyncThunk('RuleEditor/GetDomainMapType', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/GetDomainMapType`, payload);
});

/**
 * Action Creator to Save DomainMap type for provided metadata
 * @param payload - { mappingRuleVersionID, domainName, protocolNumber, domainMapType: 1 - System Defined, 2 - User Defined & 3 - No Mapping }
 */
export const SaveDomainMapType = createAsyncThunk(
  'RuleEditor/SaveDomainMapType',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveDomainMapType`, payload);
  }
);

export const SaveSequenceOrder = createAsyncThunk(
  'RuleEditor/saveSequenceOrder',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveSeqOrder`, payload);
  }
);

export const GetSequenceOrder = createAsyncThunk('RuleEditor/GetSequenceOrder', async (data) => {
  return await axios.post(`${API_URL}/api/RuleEditor/GetSeqOrder`, data);
});

/**
 * Action Creator to Get expressions templates
 */
export const GetRuleExpressionTemplates = createAsyncThunk(
  'RuleEditor/GetRuleExpressionTemplates',
  async (payload) => await axios.get(`${API_URL}/api/RuleEditor/GetRuleExpressionTemplates?IsRuleSet=${payload}`)
);

/**
 * Action Creator to Toggle Mapping Row Active
 */
export const ToggleItemMappingRow = createAsyncThunk(
  'RuleEditor/ToggleItemMappingRow',
  async (payload) => await axios.post(`${API_URL}/api/RuleEditor/ToggleItemMappingRow`, payload)
);

/**
 * Action Creator to Delete Domain & Variable Rules Filter
 */
export const DeleteDomainVariableRule = createAsyncThunk(
  'RuleEditor/DeleteDomainVariableRule',
  async (payload) => await axios.post(`${API_URL}/api/RuleEditor/DeleteDomainVariableRule`, payload)
);

/**
 * Action Creator to Delete Domain & Variable Rules expression
 */
export const DeleteVariableRule = createAsyncThunk(
  'RuleEditor/DeleteVariableRule',
  async (payload) => await axios.post(`${API_URL}/api/RuleEditor/DeleteVariableRule`, payload)
);

/**
 * Action Creator to Get the list of System Defined Domains and their mapping types
 */
export const GetSystemDefinedDomains = createAsyncThunk(
  'RuleEditor/GetSystemDefinedDomains',
  async () => await axios.get(`${API_URL}/api/RuleEditor/GetSystemDefinedDomains`)
);

/**
 * Action Creator to Get Domain Message
 */
export const GetDomainMessages = createAsyncThunk(
  'RuleEditor/GetDomainMessages', 
  async () => await axios.get(`${API_URL}/api/RuleEditor/GetDomainMessages`)
);