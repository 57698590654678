import React, { useMemo, useState } from 'react';
import Table from 'apollo-react/components/Table';

import columns from './columns.data';

import { GetRefreshLogDetails } from 'Redux/Service/DataRefreshHistoryService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { readAsText } from 'Utils';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Switch from 'apollo-react/components/Switch';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';

const CustomButtonHeader = (props) => {
  const { isSwitchOn, setIsSwitchOn, mappingSpecVersion } = props;

  return (
    <Grid container style={{ padding: 0 }}>
      <Grid item xs={6}>
        <Typography
          variant="title1"
          gutterBottom
          style={{ fontSize: '16px', fontFamily: 'Proxima Nova' }}>
          Data Refresh Log
        </Typography>
        <Typography gutterBottom>{`Mapping Spec Version: ${mappingSpecVersion}`}</Typography>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.2rem' }}>
          <div style={{ display: 'inline-flex' }}>
            <Box>
              <Typography
                style={{
                  marginRight: '10px',
                  paddingTop: '7px',
                  fontFamily: 'Proxima Nova',
                  fontSize: '14px',
                  color: '#444444',
                  position: 'relative',
                  top: '4px'
                }}
                variant="subtitle"
                gutterBottom>
                {'Show only items with Latency Warnings'}
              </Typography>
              <Switch
                size="small"
                onChange={() => setIsSwitchOn(!isSwitchOn)}
                checked={isSwitchOn}
              />
            </Box>
            <Button
              variant="secondary"
              icon={Filter}
              onClick={props.toggleFilters}
              style={{ padding: '0 8px', maxHeight: '32px' }}>
              Filter
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

/**
 * Component to render table with refresh history for the selected mapping spec/environment
 */
const RefreshHistoryTable = ({ setViewLogConfig, historyData, mappingSpecVersion }) => {
  const dispatch = useDispatch();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const onViewLog = async ({ jobRunID }) => {
    const {
      data: { success, message, viewLogData }
    } = await dispatch(GetRefreshLogDetails({ jobRunID })).then(unwrapResult);

    try {
      const fileData = readAsText(viewLogData);
      if (success) setViewLogConfig({ open: true, ...fileData });
      else dispatch(showBanner({ variant: 'error', message }));
    } catch (error) {
      dispatch(showBanner({ variant: 'error', message: error.message }));
    }
  };

  const mappedRows = useMemo(
    () => historyData.map((row) => ({ ...row, onViewLog: () => onViewLog(row) })),
    [historyData]
  );

  const filterDataByLatency = (rows) => {
    return rows.filter((item) => item.isSLABreached);
  };

  return (
    <Table
      // title="Data Refresh Log"
      // subtitle={`Mapping Spec Version: ${mappingSpecVersion}`}
      columns={columns}
      rows={isSwitchOn ? filterDataByLatency(mappedRows) : mappedRows}
      rowId="jobRunID"
      initialSortedColumn="startDateTime"
      initialSortOrder="desc"
      defaultRowsPerPage={10}
      rowsPerPageOptions={[10, 50, 100, 'All']}
      hasScroll
      maxHeight={650}
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      CustomHeader={(prop) => (
        <CustomButtonHeader
          {...prop}
          setIsSwitchOn={setIsSwitchOn}
          isSwitchOn={isSwitchOn}
          mappingSpecVersion={mappingSpecVersion}
        />
      )}
    />
  );
};

export default RefreshHistoryTable;
