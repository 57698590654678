import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CustomizeCreateDataProducts } from './Components/CustomizeCreateDataProducts';
import { PreviewTargetModel } from './Components/PreviewTargetModel';
import { SelectDataProductMappingSpecs } from './Components/SelectDataProductMappingSpec';
import { SelectSourceStudy } from './Components/SelectSourceStudy';
import { SelectStudyLibraries } from './Components/SelectStudyLibraries';
import { Validation } from './Components/Validation';
import CloneDataProductLayout from './Layout';

function CloneDataProductRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CloneDataProductLayout />}>
        <Route path="/select-source-study" element={<SelectSourceStudy />} />
        <Route
          path="/select-data-product-mapping-specs"
          element={<SelectDataProductMappingSpecs />}
        />
        <Route path="/select-study-libraries" element={<SelectStudyLibraries />} />
        <Route path="/validation" element={<Validation />} />
        <Route path="/preview-target-model" element={<PreviewTargetModel />} />
        <Route path="/customize-create-data-products" element={<CustomizeCreateDataProducts />} />
      </Route>
    </Routes>
  );
}

export default CloneDataProductRoutes;
