import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import ContentHolder from './ContentHolder';
import useDisplayName from 'Utils/useDisplayName';

const SourceContent = ({ row }) => {
  const getDisplayName = useDisplayName();
  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
      <Grid item xs={3}>
        <ContentHolder title={'Source'} value={getDisplayName(row.sourceName)} />
      </Grid>
      <Grid item xs={3}>
        <ContentHolder title={'Database'} value={row.databaseName} />
      </Grid>
      <Grid item xs={3}>
        <ContentHolder title={'Username'} value={row.userID} />
      </Grid>
    </div>
  );
};

const SourceConnectionCard = ({ handleClick, sources }) => {
  return (
    <Card style={{ width: '100%', marginBottom: '2rem' }}>
      <Grid container spacing={2} style={{ margin: '2%' }}>
        <Grid item xs={3}>
          <Typography variant="title1">
            <p style={{ marginBottom: '0.5rem' }}>Source</p> Connections
          </Typography>
          <Button variant="text" style={{ padding: 0 }} onClick={handleClick} size="small">
            Change Source Connections
          </Button>
        </Grid>
        <Grid item xs={9}>
          {sources?.map((row) => {
            return <SourceContent row={row} key={row.sourceName} />;
          })}
        </Grid>
      </Grid>
    </Card>
  );
};

export default SourceConnectionCard;
