import ERROR from './messages';
/*eslint-disable */
// const isoRegArray = ["DD-MM-YYYY", "YYYY", "MMM-YYYY", "DDMMMYYYY", "MM-DD-YY/YYYY", "MM-DD-YYYY", "YYYY-MM-DD", "DD-MMM-YY", "DD-MMM-YYYY", "yyyy-MM-dd HH:mm:ss"];
// let flags = "gi";
// new RegExp(`isodatetime\\("(${isoRegArray.join('|')})"\\)`,flags),
// `\\.isodatetime\\("(${isoRegArray.join('|')})"\\)`
class Parser {
  methodsObj = {
    max: {
      args: 0 - 1,
      regex: /Max\((\s*"[^"]*"\s*|)\)/gi,
      strRegex: '\\.Max\\((\\s*"[^"]*"\\s*|)\\)'
    },
    min: {
      args: 0 - 1,
      regex: /Min\((\s*"[^"]*"\s*|)\)/gi,
      strRegex: '\\.Min\\((\\s*"[^"]*"\\s*|)\\)'
    },
    replace: {
      args: 2,
      regex: /Replace\(\s*"[^"]*"\s*,\s*"[^"]*"\s*\)/gi,
      strRegex: '\\.Replace\\(\\s*"[^"]*"\\s*,\\s*"[^"]*"\\s*\\)'
    },
    doesvalueexist: {
      args: 2,
      regex: /DoesValueExist\(\s*"[^"]*"\s*,\s*"[^"]*"\s*(,\s*("\$AND"|"\$OR")\s*)?\)/gi,
      strRegex:
        '\\.DoesValueExist\\(\\s*"[^"]*"\\s*,\\s*"[^"]*"\\s*(,\\s*("\\$AND"|"\\$OR")\\s*)?\\)'
    },
    units: {
      args: 1,
      regex: /Units\(([]*|TEXT|NAME)\)/gi,
      strRegex: '\\.Units\\(([]*|TEXT|NAME)\\)'
    },
    choose: {
      args: 2,
      regex: /Choose\([^,]{1,1},\d+\)/gi,
      strRegex: '\\.Choose\\([^,]{1,1},\\d+\\)'
    },
    decodeSRC: {
      args: 0 - 1,
      regex: /DecodeSRC\(([]*|[\s\w]+)\)/gi,
      strRegex: '\\.DecodeSRC\\(([]*|[\\s\\w]+)\\)'
    },
    decode: {
      args: 0 - 1,
      regex: /Decode\(([]*|[\s\w]+)\)/gi,
      strRegex: '\\.Decode\\(([]*|[\\s\\w]+)\\)'
    },
    decodemodifiedtext: {
      args: 0 - 1,
      regex: /DecodeModifiedText\(([]*|[\s\w]+)\)/gi,
      strRegex: '\\.DecodeModifiedText\\(([]*|[\\s\\w]+)\\)'
    },
    doesvisitexist: {
      args: 1,
      regex: /DoesVisitExist\(\"[^\"]*\"\)/gi,
      strRegex: '\\.DoesVisitExist\\(\\"[^\\"]*\\"\\)'
    },
    length: {
      args: 0,
      regex: /Length\(\)/gi,
      strRegex: '\\.Length\\(\\)'
    },
    odmeventcreatedate: {
      args: 0,
      regex: /ODMEventCreateDate\(\)/gi,
      strRegex: '\\.ODMEventCreateDate\\(\\)'
    },
    upcase: {
      args: 0,
      regex: /Upcase\(\)/gi,
      strRegex: '\\.Upcase\\(\\)'
    },
    remove: {
      args: -1,
      regex: /Remove\(([^,\)]+){1}(,[^,\)]+)*\)/gi,
      strRegex: '\\.Remove\\(([^,\\)]+){1}(,[^,\\))]+)*\\)'
    },
    specifyvalue: {
      args: 0,
      regex: /SpecifyValue\(\)/gi,
      strRegex: '\\.SpecifyValue\\(\\)'
    },
    substr: {
      args: 2,
      regex: /Substr\(\s*\d+\s*,\s*\d+\s*\)/gi,
      strRegex: '\\.substr\\(\\s*\\d+\\s*,\\s*\\d+\\s*\\)'
    },
    datepart: {
      args: 0,
      regex: /DatePart\(\)/gi,
      strRegex: '\\.DatePart\\(\\)'
    },
    timepart: {
      args: 0,
      regex: /TimePart\(\)/gi,
      strRegex: '\\.TimePart\\(\\)'
    },
    odmeventsequence: {
      args: 0 - 1,
      regex: /ODMEventSequence\(\d+\)/gi,
      strRegex: '\\.ODMEventSequence\\(\\d+\\)'
    },
    pad: {
      args: 3,
      regex: /Pad\(\d+,.,(left|right)\)/gi,
      strRegex: '\\.Pad\\(\\d+,.,(left|right)\\)'
    },
    last: {
      args: 0,
      regex: /Last\(\d+\)/gi,
      strRegex: '\\.Last\\(\\d+\\)'
    },
    dataentrydatetime: {
      args: 0,
      regex: /DataEntryDateTime\(\)/gi,
      strRegex: '\\.DataEntryDateTime\\(\\)'
    },
    odmeventname: {
      args: 0,
      regex: /ODMEventName\(\)/gi,
      strRegex: '\\.ODMEventName\\(\\)'
    },
    isodatetime: {
      args: 0 - 1,
      regex: /isodatetime\(([]*|\s*"[^"]*"\s*)\)/gi,
      strRegex: '\\.isodatetime\\(([]*|\\s*"[^"]*"\\s*)\\)'
    }
  };

  operators = ['>', '>=', '<=', '<', '<>', '=', 'IN', 'NOT IN', 'LIKE', 'NOT LIKE', 'CONTAINS'];

  literalRegex = '\\"[^\\"]+\\"';

  constructor(str, forms, defaultForm, validatorType = 1) {
    this.validatorType = validatorType;
    this.str = str?.replaceAll('\n', ' ');
    this.forms = forms;
    this.expRegex = '';
    this.conditionExp = '';
    this.regex = '';
    this.noMap = '\\$NOMAP';
    this.result = '';
    this.defaultForm = defaultForm;
    this.createRegex();
  }

  createRegex = () => {
    const expressionRegex = this.buildExpression();
    this.expRegex = expressionRegex;
    const conditionRegexWithJoin = this.buildCondition();
    this.conditionExp = conditionRegexWithJoin;
    this.regex = this.buildRegex();
  };

  validateExpression = () => {
    const reg = new RegExp(this.expRegex, 'gmi');
    const matchedExp = this.str.match(reg);
    const keys = Object.keys(this.methodsObj);
    if (matchedExp && matchedExp.length > 0) {
      for (let i = 0; i < matchedExp.length; i++) {
        for (let j = 0; j < keys.length; j++) {
          let match = matchedExp[i].match(this.methodsObj[keys[j]].regex);
          if (
            match &&
            match[0].toLowerCase().startsWith('doesvisitexist') &&
            matchedExp[i][
              matchedExp[i].toLowerCase().indexOf('doesvisitexist') + match[0].length
            ] === '.'
          ) {
            return {
              isValid: false,
              message: ERROR.CANNOT_CHAIN_DoesVisitExist_METHOD
            };
          }

          if (match && match.length === 1) {
            match = match[0].toLowerCase().split(keys[j]).filter(Boolean);
          }

          if (match && match.length > 1) {
            return {
              isValid: false,
              message: ERROR.DUPLICATE_METHOD + keys[j]
            };
          }
        }
      }
      return {
        isValid: true,
        message: ERROR.BLANK
      };
    } else {
      return {
        isValid: true,
        message: ERROR.BLANK
      };
    }
  };

  buildRegex = () => {
    const expressionPlusCondition = `${this.expRegex}|${this.conditionExp}|${this.noMap}|${this.literalRegex}`;
    return `(((\\s*(${expressionPlusCondition}))(\\s*\\+\\s*(${expressionPlusCondition})){0,}))`;
  };

  buildCondition = () => {
    const operatorRegex = this.buildOperators();
    const literalOrExpRegex = this.buildLiteralOrExpression();
    const conditionExp = `\\s*${this.expRegex}${operatorRegex}${literalOrExpRegex}\\s*`;
    const joinOperatorRegex = this.buildJoinOperatorRegex(conditionExp);
    const conditionRegexWithJoin = conditionExp + joinOperatorRegex;
    const conditionRegexWithJoinBraces = `\\s*(${conditionRegexWithJoin}|\\{${conditionRegexWithJoin}\\})\\s*`;
    const conditionRegexWithJoinBracesJoin = this.buildJoinOperatorRegexBraces(
      conditionRegexWithJoinBraces
    );
    const finalConditionalRegex = conditionRegexWithJoinBraces + conditionRegexWithJoinBracesJoin;
    const returnRegex = this.buildReturnRegex();
    return 'IF' + finalConditionalRegex + 'THEN' + returnRegex;
  };

  buildReturnRegex = () => {
    return `\\s*(${this.expRegex}|${this.literalRegex}\\s*)`;
  };

  buildJoinOperatorRegex = (conditionExp) => {
    return `((\\$AND|\\$OR)${conditionExp}){0,}`;
  };
  buildJoinOperatorRegexBraces = (conditionExpWithBraces) => {
    return `((\\$AND|\\$OR)${conditionExpWithBraces}){0,}`;
  };

  buildLiteralOrExpression = () => {
    const literalOrExpRegex = `(${this.literalRegex}|${this.expRegex})`;
    return literalOrExpRegex;
  };

  // TODO: one space before and after
  buildOperators = () => {
    const str = this.operators.reduce((prev, cur) => prev + cur + '|', '');
    return `\\s*(${str.substring(0, str.length - 1)})\\s*`;
  };

  buildExpression = () => {
    let formItemRegex;
    if (this.validatorType === 1) {
      formItemRegex = this.buildFormAndItemName();
    } else {
      formItemRegex = this.buildFormWithPatient();
    }
    const methodRegex = this.buildMethods();
    return formItemRegex + methodRegex;
  };

  buildFormAndItemName = () => {
    let allFormCombination = '';
    let allItems = '';
    for (let f in this.forms) {
      for (let i = 0; i < this.forms[f].length; i++) {
        const item = this.forms[f][i];
        allFormCombination += `\\[${f}\\]\\.\\[${item}\\]|`;
        // if (f === this.defaultForm) allItems += `${item}|`;
      }
    }

    allFormCombination = allFormCombination.substring(0, allFormCombination.length - 1);
    allItems = allItems
      .substring(0, allItems.length - 1)
      .replaceAll(/\(/g, '\\(')
      .replaceAll(/\)/g, '\\)');

    return `(${allFormCombination})`;
  };

  buildFormWithPatient = () => {
    return `\\{FORM\\.OID\\}\\.\\[[^\\]]+\\]`;
  };

  buildMethods = () => {
    const keys = Object.keys(this.methodsObj);
    const str = keys.reduce((prevStr, key) => {
      prevStr += this.methodsObj[key].strRegex + '|';
      return prevStr;
    }, '');

    return `(${str.substring(0, str.length - 1)}){0,${keys.length}}`;
  };

  verify() {
    const pattern = new RegExp('^' + this.regex + '$', 'igm');
    return pattern.test(this.str);
  }
}

export default Parser;
