/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { GetCDMNameMappings, GetGlobalKeys, GetGlobalMessages } from 'Redux/Service/AppService';

/**
 * Application Slice - To handle the redux operations for common data, that is used accross the application
 */
export const AppSlice = createSlice({
  name: 'ApplicationCommonData',

  initialState: {
    nameMappings: {},
    globalMessages: {},
    globalKeys: {}
  },
  reducers: {},

  extraReducers: {
    [GetCDMNameMappings.fulfilled](state, { payload }) {
      if (payload?.data?.cdmNamingConversionsLookUp) {
        const _nameMappings = {};
        payload.data.cdmNamingConversionsLookUp.forEach((nameMapping) => {
          _nameMappings[nameMapping.actualValue] = nameMapping.displayValue;
        });
        state.nameMappings = _nameMappings;
        console.log('nameMappings', state.nameMappings);
      }
    },
    [GetGlobalMessages.fulfilled](state, { payload }) {
      if (payload?.data?.globalMessages) {
        const _globalMessages = {};
        payload.data.globalMessages.forEach((item) => {
          _globalMessages[item.key] = item.message;
        });
        state.globalMessages = _globalMessages;
        console.log('globalMessages :: ', state.globalMessages);
      }
    },
    [GetGlobalKeys.fulfilled](state, { payload }) {
      if (payload?.data?.globalKeys) {
        const _globalKeys = {};
        payload.data.globalKeys.forEach((item) => {
          if (!_globalKeys[item.topicName]) {
            _globalKeys[item.topicName] = [];
          }
          _globalKeys[item.topicName].push(item.key);
        });
        state.globalKeys = _globalKeys;
        console.log('globalKeys :: ', state.globalKeys);
      }
    }
  }
});
