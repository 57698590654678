import { useSelector } from 'react-redux';

/**
 * Custom hook:
 * If Key exists in 'globalMessages', it returns the mapped message; otherwise, it returns the Key.
 * 
 * @returns {function} - Returns a function to get the global message for a given key.
 - Usage: `const getMessage = useGlobalMessage(); const message = getMessage('Key')`;
 */
export const useGlobalMessage = () => {
  const { globalMessages = {} } = useSelector((state) => state.AppData);
  return (originalValue) => globalMessages[originalValue] || originalValue;
};

export default useGlobalMessage;
