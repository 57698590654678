export const getDomainInfoFormatted = (domainColumnList) => {
  let domainInformation = [];
  domainColumnList?.forEach((domain) => {
    if (domain.checked) {
      const variableInformation = [];
      domain.exportVariables
        ?.filter((column) => column.checked)
        .forEach((column, index) => {
          variableInformation.push({
            columnOrder: index + 1,
            isSystemColumn: column.isSystemColumn,
            isSuppQual: column.isSuppQual,
            originalColumnName: column.originalColumnName,
            updatedColumnName: column.updatedColumnName
          });
        });
      domainInformation.push({
        domainCode: domain.domainCode,
        domainType: domain.domainType,
        dataFileName: domain.dataFileName,
        variableInformation
      });
    }
  });
  return domainInformation;
};


export const getDomainInfoFormattedForSavePreference = (domainColumnList) => {
  const domainAndVariables = [];
  domainColumnList.forEach((domain) => {
    if (domain.checked) {
      const variableInfo = [];
      domain.exportVariables
        ?.filter((column) => column.checked)
        .forEach((column, index) => {
          variableInfo.push({
            columnOrder: index + 1,
            isSystemColumn: column.isSystemColumn,
            isSuppQual: column.isSuppQual,
            originalColumnName: column.originalColumnName,
            updatedColumnName: column.updatedColumnName
          });
        });
      domainAndVariables.push({
        domainCode: domain.domainCode,
        domainType: domain.domainType,
        dataFileName: domain.dataFileName,
        variableInfo
      });
    }
  });
  return domainAndVariables;
};
