/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import Blade from 'apollo-react/components/Blade';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import Radio from 'apollo-react/components/Radio';
import RadioGroup from 'apollo-react/components/RadioGroup';
import { GetSchedularTypes, UpdateJobSchedulerType } from 'Redux/Service/PublishMappingSpecService';
import Scheduler from 'Pages/ProductDesigner/Components/PublishMappingSpec/SelectEnvironment/Scheduler';
import styled from 'styled-components';
import { showBanner } from 'Redux/Slice/BannerSlice';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Modal from 'apollo-react/components/Modal';
import { useCallback } from 'react';

const Label = styled(Typography)(() => ({
  fontWeight: '400',
  lineHeight: '24px',
  color: '#595959'
}));

const Text = styled(Typography)(() => ({
  fontWeight: '500',
  lineHeight: '24px',
  color: '#444444'
}));

const useStyles = makeStyles({
  expandPanelButton: {
    display: 'none'
  },
  backdrop: {
    zIndex: 3999
  },
  paper: {
    zIndex: 4000,
    fontFamily: 'Proxima Nova',
    background: 'white',
    paddingBottom: '4.5rem'
  },
  schedulersTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#444444'
  },
  schedulerCard: {
    width: '100%',
    height: '6rem',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.04)',
    backgroundColor: 'white',
    margin: '1rem 0rem 0rem 0rem',
    padding: '0rem 1rem',

    '&> span:nth-child(2)': {
      padding: '0rem',
      paddingLeft: '1rem',
      height: '100%'
    },

    '&:hover': {
      border: '1px solid #0768FD'
    }
  },
  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '1rem',
    borderTop: '1px solid #E9E9E9',
    width: 'calc(100%)',
    display: 'flex',
    justifyContent: 'flex-end',
    background: 'white'
  }
});

const ChangeScheduler = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showConfirmDailog, setConfirmDailog] = useState(false);

  const { displayOverlay, closeChangeScheduler, selectedRow = {} } = props;

  const {
    mappingRuleVersionID,
    mappingSpecVersionName,
    productMnemonic,
    createdBy,
    createdDate,
    targetDataModelType,
    mappingRuleDeployID,
    schedulerTypeID
  } = selectedRow;

  const [schedulerTypes, setSchedulerTypes] = useState([]);
  const [displaySkeleton, setDisplaySkeleton] = useState(false);

  const [selectedSchedulerID, setSelectedSchedulerID] = useState(schedulerTypeID);

  useEffect(() => {
    document.getElementsByTagName('body')[0].style = 'padding-right: 17px; overflow: hidden;';
    return () => {
      document.getElementsByTagName('body')[0].style = undefined;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setDisplaySkeleton(true);
      const {
        data: { success, jobSchedulerTypes, message }
      } = await dispatch(GetSchedularTypes({ mappingRuleVersionID })).then(unwrapResult);
      if (success) {
        setSchedulerTypes(jobSchedulerTypes);
      } else {
        dispatch(showBanner({ variant: 'error', message }));
        console.log('Failed to retrive SchedulerTypes :: ', message);
      }
      setDisplaySkeleton(false);
    })();
  }, []);

  const handleChangeScheduler = async () => {
    const {
      data: { success, message }
    } = await dispatch(
      UpdateJobSchedulerType({
        mappingRuleDeployID,
        schedulerTypeID: selectedSchedulerID
      })
    ).then(unwrapResult);
    if (success) {
      dispatch(showBanner({ variant: 'success', message }));
    } else {
      dispatch(showBanner({ variant: 'error', message }));
      console.log('Failed to UpdateJobSchedulerType :: ', message);
    }
    closeChangeScheduler(true);
  };

  const handleClose = useCallback(() => closeChangeScheduler(), []);
  const handleWarningClose = useCallback(() => setConfirmDailog(false), []);

  const confirmModalButtonProps = [
    {
      label: 'Cancel',
      'data-testid': 'cancel-change-scheduler-btn-modal',
      onClick: handleWarningClose
    },
    {
      label: 'Change Scheduler',
      'data-testid': 'change-scheduler-btn-modal',
      onClick: () => {
        handleChangeScheduler();
        setConfirmDailog(false);
      }
    }
  ];

  return (
    <>
      <Blade
        open={displayOverlay}
        onClose={handleClose}
        title={'Change Scheduler Type'}
        subtitle={`Mapping Spec Version: ${mappingSpecVersionName}`}
        side="right"
        classes={{
          expandPanelButton: classes.expandPanelButton,
          backdrop: classes.backdrop,
          paper: classes.paper
        }}
        width={'550px'}
        hasBackdrop>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Label>Product Mnemonic</Label>
            <Text>{productMnemonic} </Text>
          </Grid>
          <Grid item xs={6}>
            <Label>Created by</Label>
            <Text>{createdBy} </Text>
          </Grid>
          <Grid item xs={6}>
            <Label>Date Created</Label>
            <Text>{createdDate} </Text>
          </Grid>
          <Grid item xs={6}>
            <Label>Target Data Model Type</Label>
            <Text>{targetDataModelType} </Text>
          </Grid>
        </Grid>
        <Typography className={classes.schedulersTitle}>Scheduler Type:</Typography>
        <RadioGroup
          aria-label="label"
          name="label"
          value={selectedSchedulerID}
          onChange={(e) => setSelectedSchedulerID(e.target.value)}>
          <Grid container>
            {displaySkeleton ? (
              <Stack spacing={3} width={'100%'} mt={2}>
                <Skeleton variant="rounded" width={'100%'} height={95} />
                <Skeleton variant="rounded" width={'100%'} height={95} />
                <Skeleton variant="rounded" width={'100%'} height={95} />
              </Stack>
            ) : (
              schedulerTypes.map((scheduler) => (
                <Radio
                  key={`scheduler-${scheduler.schedulerTypeID}`}
                  value={scheduler.schedulerTypeID}
                  label={
                    <Scheduler
                      {...scheduler}
                      selected={scheduler.schedulerTypeID === selectedSchedulerID}
                      horizontal
                    />
                  }
                  disabled={!scheduler.isEnable}                               
                  className={classes.schedulerCard}
                  style={
                    scheduler.schedulerTypeID === selectedSchedulerID
                      ? { backgroundColor: '#ECF3FF', borderColor: '#0768FD' }
                      : !scheduler.isEnable ? { opacity: '0.6' }
                      : {}
                  }
                />
              ))
            )}
          </Grid>
        </RadioGroup>
        <Box className={classes.btnContainer}>
          <Button
            data-testid="cancel-change-scheduler-btn"
            variant={'secondary'}
            onClick={handleClose}
            style={{ marginRight: '1rem' }}>
            Cancel
          </Button>
          <Button
            disabled={schedulerTypeID === selectedSchedulerID}
            data-testid="change-scheduler-btn"
            variant={'primary'}
            onClick={() => setConfirmDailog(true)}>
            Change Scheduler
          </Button>
        </Box>
        <Modal
          style={{ zIndex: 4000 }}
          open={showConfirmDailog}
          variant={'warning'}
          title={
            <Box display={'flex'} flexDirection={'row'}>
              <StatusExclamationIcon />
              <span style={{ marginLeft: '0.5rem' }}>Change Scheduler Type?</span>
            </Box>
          }
          data-testid="confirm-modal"
          buttonProps={confirmModalButtonProps}
          onClose={handleWarningClose}>
          Are you sure you want to update the scheduler type? The next job will be processed using
          the newly selected scheduler type.
        </Modal>
      </Blade>
    </>
  );
};

export default ChangeScheduler;
