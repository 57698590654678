import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Service to fetch log file for the specific Refresh Data job
 * @returns {Promise}
 */
export const GetDataProductSummeriesHistory = createAsyncThunk(
  'DataRefreshHistory/GetDataProductSummeriesHistory',
  (payload) => axios.post(`${API_URL}/api/Dashboard/GetDataProductSummeriesHistory`, payload)
);

/**
 * Service to fetch log file for the specific Refresh Data job
 * @returns {Promise}
 */
export const GetRefreshLogDetails = createAsyncThunk(
  'DataRefreshHistory/GetRefreshLogDetails',
  async ({ jobRunID }) => axios.get(`${API_URL}/api/Dashboard/GetViewLog?JobRunID=${jobRunID}`)
);
