/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'apollo-react/components/Card';
import SelectSourceTable from '../SelectSourceTable';
import {
  GetProductDataSourcesByMappingRuleVersionID,
  SaveDataSources
} from 'Redux/Service/RuleEditorService';
import { dateFormatByType, uuidv4 } from 'Utils';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import {
  getSelectedRowsForSave,
  setNextEnabled,
  setWarningModalConfig
} from 'Redux/Slice/RuleEditorSlice';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { isModuleRequired } from './RuleEditorUtils';
import Cookies from 'js-cookie';
import Footer from 'Components/Footer';
import { useRef } from 'react';
import CustomModal from '../../../../../../Components/Modal';
import { useMemo } from 'react';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';

const SelectDataSource = () => {
  const location = useLocation();
  const { id: mappingRuleVersionID } = useParams();
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const dispatch = useDispatch();
  const [selectedRowsForSave, setSelectedRowsForSave] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const savedDataSources = useRef();
  const [warningEnabled, setWarningEnabled] = useState(false);
  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const getSourceData = async () => {
    const getTableData = await dispatch(
      GetProductDataSourcesByMappingRuleVersionID(mappingRuleVersionID)
    ).then(unwrapResult);
    let tableData = [];
    if (getTableData.data.success) {
      getTableData.data.ruleStudyLibrary.length > 0 &&
        getTableData.data.ruleStudyLibrary.forEach((el) => {
          if (el.libraryType !== 'Ruleset') {
            tableData.push({
              libraryName: el.libraryName,
              libraryType: el.libraryType,
              source: el.source,
              isCDRTestFlow: el.isCDRTestFlow,
              version: el.libraryVersion,
              dataSetType: el.dataSetType || 'N/A',
              vendor: el.vendorName || '-',
              date: dateFormatByType(el.auditDate, 'Table'),
              isMappingRuleConfigured: el.isMappingRuleConfigured,
              libraryID: el.libraryID,
              studyLibraryUserID: el.studyLibraryUserID,
              auditDate: el.auditDate,
              studyLibraryDescription: el.studyLibraryDescription,
              key: uuidv4(),
              mappingRuleVersionID,
              isCustomLibrary: el.isCustomLibrary,
              customSQLDataSource: el.customSQLDataSource,
              edcTrailName: el.edcTrailName,
              displayName: el.displayName,
              ecrfTrialName: el.ecrfTrialName
            });
          }
        });
    } else {
      dispatch(showBanner({ variant: 'error', message: getTableData.data.message }));
    }
    savedDataSources.current = tableData.filter((row) => row.isMappingRuleConfigured);
    setDataSources(tableData);
  };

  useEffect(() => {
    getSourceData();
  }, []);

  useEffect(() => {
    if (selectedRowsForSave && selectedRowsForSave.length > 0) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [selectedRowsForSave && selectedRowsForSave.length > 0]);

  useEffect(() => {
    dispatch(getSelectedRowsForSave([]));
    setDisableSave(true);
  }, []);

  useEffect(() => {
    if (workFlowModel && Object.keys(workFlowModel).length > 0) {
      if (!isModuleRequired(workFlowModel.functionData, currentPath)) {
        dispatch(setNextEnabled(true));
        return;
      }
    }
  }, [workFlowModel]);

  useEffect(() => {
    let nextEnable = false;
    if (selectedRowsForSave && selectedRowsForSave.length > 0) {
      selectedRowsForSave.forEach((item) => {
        if (item.isMappingRuleConfigured) {
          nextEnable = true;
        }
      });
    }
    dispatch(setNextEnabled(nextEnable));
    dispatch(getSelectedRowsForSave(savedDataSources.current));
  }, [selectedRowsForSave]);

  const handleSourceSave = async () => {
    let ruleDataSources = [];
    selectedRowsForSave.forEach((el) => {
      ruleDataSources.push({
        iqCreateDate: el.isMappingRuleConfigured ? el.auditDate : new Date().toISOString(),
        iqUpdateDate: el.isMappingRuleConfigured ? el.auditDate : new Date().toISOString(),
        iqCreatedBy: el.isMappingRuleConfigured ? el.studyLibraryUserID : Cookies.get('user.id'),
        iqUpdatedBy: el.isMappingRuleConfigured ? el.studyLibraryUserID : Cookies.get('user.id'),
        iqAuditType: mappingRuleVersionID ? 'INSERT' : 'UPDATE',
        iqAuditDate: el.isMappingRuleConfigured ? el.auditDate : new Date().toISOString(),
        iqActiveFlag: true,
        mappingRuleStudyLibXrefID: '',
        protocolNumber: rowDataForRuleEditor.protocolName,
        mappingRuleVersionID: mappingRuleVersionID,
        studyLibraryID: el.libraryID,
        dataProductID: rowDataForRuleEditor.productID,
        dataProductVersion: rowDataForRuleEditor.productVersion,
        studyLibraryVersion: el.version,
        selectedDatasets: el.source
      });
    });
    let payload = {
      ruleDataSources: ruleDataSources
    };
    const saveSourceData = await dispatch(SaveDataSources(payload)).then(unwrapResult);
    if (saveSourceData && saveSourceData.data.success) {
      dispatch(showBanner({ variant: 'success', message: saveSourceData.data.message }));
      dispatch(getSelectedRowsForSave(selectedRowsForSave));
      getSourceData();
      return;
    } else {
      dispatch(showBanner({ variant: 'error', message: saveSourceData.data.message }));
      return;
    }
  };

  const haveUnsavedRecords = useMemo(() => {
    return (
      savedDataSources.current?.length !== selectedRowsForSave.length ||
      !savedDataSources.current?.every((_ds) =>
        selectedRowsForSave.find((row) => row.key === _ds.key)
      )
    );
  }, [savedDataSources.current, selectedRowsForSave]);

  useEffect(() => {
    dispatch(
      setWarningModalConfig({
        displayWarningModal: haveUnsavedRecords,
        displayWarningModalTitle: 'Continue without saving',
        displayWarningModalSubtitle:
          'Unsaved changes in Data Sources will be lost. Would you like to continue?'
      })
    );
  }, [haveUnsavedRecords]);

  const handleSave = () => {
    if (savedDataSources.current?.length && haveUnsavedRecords) {
      setWarningEnabled(true);
    } else {
      handleSourceSave();
    }
  };
  return (
    <>
      <Box pt={0} pl={3} pr={3}>
        <Typography variant="h3">{'Data Sources'}</Typography>
        <>
          <Box mt={2}>
            <Grid container spacing={0} style={{}}>
              <Grid item xs={3}>
                <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                  Product Mnemonic
                </Typography>
                <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                  {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                  Description
                </Typography>
                <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                  {rowDataForRuleEditor && rowDataForRuleEditor.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <Card>
                <SelectSourceTable
                  dataSources={dataSources}
                  setSelectedRowsForSave={setSelectedRowsForSave}
                  selectedRowsForSave={selectedRowsForSave}
                  disableSave={disableSave}
                  haveUnsavedRecords={haveUnsavedRecords}
                  handleSave={handleSave}
                />
              </Card>
            </Grid>
          </Grid>
        </>
      </Box>
      <Footer width={'100%'} />
      <CustomModal
        variant={'warning'}
        display={warningEnabled}
        title={
          <Box display={'flex'} flexDirection={'row'}>
            <StatusExclamationIcon />
            <span style={{ marginLeft: '0.5rem' }}>{'Update data source selection?'}</span>
          </Box>
        }
        buttonPrimaryLabel={'Update Data Sources'}
        message={
          'Are you sure you want to update the data source selection? \nAll expressions and settings related to unselected data sources will be lost'
        }
        handlePrimaryAction={() => {
          setWarningEnabled(false);
          handleSourceSave();
        }}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => setWarningEnabled(false)}
      />
    </>
  );
};
export default SelectDataSource;
