import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import Filter from 'apollo-react-icons/Filter';
import { primaryLight } from 'apollo-react/colors';
import ApolloProgress from 'apollo-react/components/ApolloProgress';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import Switch from 'apollo-react/components/Switch';
import Tab from 'apollo-react/components/Tab';
import Table, { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import Tabs from 'apollo-react/components/Tabs';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Loader from 'Components/Loader/Loader';
import Failure from 'Images/status-failure.svg';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPreviewSubjectIDs } from 'Redux/Service/DomainPreviewService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setSelectedSubjects, setSubjectsData } from 'Redux/Slice/DomainPeviewSlice';
import ErrorsDataTable from './ErrorsDataTable';
import { handleErrorData } from './SourceUtilities';
import useDisplayName from 'Utils/useDisplayName';

const styles = {
  selected: {
    '& td': {
      backgroundColor: primaryLight
    }
  },
  error: {
    '& td': {
      backgroundColor: 'rgba(226,0,0,0.06)'
    }
  }
};
const useStyles = makeStyles(styles);

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const CheckboxCell = (props) => {
  const { handleSelectRow, isDisabled, isChecked } = props.row;
  return (
    <div className="tooltip">
      <input
        type={'checkbox'}
        checked={isChecked}
        style={{ width: '16px', height: '16px' }}
        disabled={isDisabled && !isChecked}
        onChange={(e) => handleSelectRow(props.row, e)}
      />
      {isDisabled && !isChecked && (
        <span className="tooltiptext tooltip-right">Only 50 Subjects can be selected</span>
      )}
    </div>
  );
};

const columns = [
  {
    accessor: 'action',
    customCell: CheckboxCell,
    width: 40
  },
  {
    header: 'Subject ID',
    accessor: 'subjectid',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('subjectid'),
    width: 300,
    fixedWidth: false
  },
  {
    header: 'Source',
    accessor: 'sourceDisplayName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('sourceDisplayName'),
    fixedWidth: false
  }
];

const CustomButtonHeader = (props) => {
  const { selectedRows, setSelectedSubjects, isSwitchOn, setIsSwitchOn, dispatch } = props;
  const cancelSelection = () => {
    dispatch(setSelectedSubjects([]));
  };
  const handleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="title1" gutterBottom>
          Subjects
        </Typography>
        <Typography variant="body2" gutterBottom style={{ paddingTop: '0px' }}>
          {`${selectedRows.length} Subjects Selected`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{ marginRight: '10px', paddingTop: '7px' }}
              variant="subtitle"
              gutterBottom>
              {'Show only selected subjects'}
            </Typography>
            <Switch size="small" onChange={handleSwitch} checked={isSwitchOn} />
          </Box>
          <Button
            onClick={cancelSelection}
            disabled={selectedRows.length === 0}
            style={{ marginRight: '10px', marginLeft: '5px' }}>
            Cancel Selection
          </Button>
          <Button variant="secondary" icon={Filter} onClick={props.toggleFilters}>
            Filter
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

const SelectSubjects = (props) => {
  const dispatch = useDispatch();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [value, setValue] = useState(0);
  const [errorlist, setErrorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadFunction, setReloadFunction] = useState(false);
  const [apiCallProcess, setApiCallProcess] = useState(false);
  const [rowsCount, setRowsCount] = useState(10);
  const [sortedColumn, setSortedColumn] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationFilter, setPaginationFilter] = useState([]);
  const screenSizeRef = useRef(null);
  const renderRef = useRef(false);
  const getDisplayName = useDisplayName();

  const { subjectsData, selectedSubjects, selectedDomainSourceData } = useSelector(
    (state) => state.DomainPreviewData
  );

  const { selectedSourceData } = useSelector((state) => state.PublishMappingSpecData);

  const classes = useStyles();
  const { handleNext } = props;

  let payloadData = selectedDomainSourceData.masterDataSources.filter(
    (item) => item.selected === true && item.connection === true
  );

  const selectedDataSets = payloadData
    .filter((item) => item.parentSourceName === 'CDR')
    .map((el) => el.sourceName);

  const dataSources = payloadData.map((item) => {
    if (item.parentSourceName === 'CDR') {
      const cdrData = selectedDomainSourceData.masterDataSources.filter(
        (item) => item.sourceName === 'CDR'
      )[0];
      return {
        dataSourceID: cdrData.dataSourceID,
        databaseName: cdrData.databaseName,
        sourceID: cdrData.sourceID,
        sourceName: cdrData.sourceName,
        userID: cdrData.userID,
        password: cdrData.passwordValue.slice(1)
      };
    } else {
      return {
        dataSourceID: item.dataSourceID,
        databaseName: item.databaseName,
        sourceID: item.sourceID,
        sourceName: item.sourceName,
        userID: item.userID,
        password: item.passwordValue.slice(1)
      };
    }
  });

  const set = new Set();
  const uniqueDataSources = dataSources.filter((el) => {
    const duplicate = set.has(el.sourceName);
    set.add(el.sourceName);
    return !duplicate;
  });

  let trialNames = {};
  let ecrfTrialName = '';
  selectedSourceData?.map((item) => {
    trialNames[item.sourceName] = item.trialName;
    ecrfTrialName = ecrfTrialName || item.ecrfTrialName;
  });
  let payload = {
    mappingRuleID: selectedDomainSourceData.mappingRuleVersionID,
    protocolNumber: selectedDomainSourceData.studyDataSources[0].protocolNumber,
    dataSources: uniqueDataSources,
    selectedDataSets: selectedDataSets,
    pageNumber: pageNumber + 1,
    pageSize: rowsCount === 'All' ? totalRowCount : rowsCount,
    filters: paginationFilter,
    trialNames: trialNames ? trialNames : null,
    isDataSourceDummy: selectedDomainSourceData?.isDataSourceDummy,
    ecrfTrialName
  };

  const handleSubjectResponse = (data) => {
    const subjectData = data.subjectIds.map((item) => {
      return {
        source: item.source,
        sourceDisplayName: item.source
          ?.split(',')
          .map((source) => getDisplayName(source))
          .join(','),
        subjectid: item.subjectId,
        key: `${item.subjectId}-${item.source}`
      };
    });
    return subjectData;
  };

  const updateFilter = (data) => {
    const updatedData = Object.keys(data).map((key) => {
      return {
        columnName: key,
        text: data[key]
      };
    });
    setPaginationFilter(updatedData);
  };

  function sortDataFunction(data, accessor, sortOrder) {
    return data.sort((rowA, rowB) => {
      let stringA = rowA[accessor] ? rowA[accessor].toUpperCase() : '';
      let stringB = rowB[accessor] ? rowB[accessor].toUpperCase() : '';
      if (sortOrder === 'asc') {
        if (stringA < stringB) {
          return -1;
        }
        if (stringA > stringB) {
          return 1;
        }
        return 0;
      } else {
        if (stringA < stringB) {
          return 1;
        }
        if (stringA > stringB) {
          return -1;
        }
        return 0;
      }
    });
  }

  useEffect(() => {
    (async () => {
      if (!renderRef.current || reloadFunction) {
        setLoading(true);
        const responseData = await dispatch(GetPreviewSubjectIDs(payload)).then(unwrapResult);
        if (responseData.data.success) {
          setTotalRowCount(responseData.data.totalCount);
          const data = handleSubjectResponse(responseData.data);
          setErrorList(handleErrorData(responseData.data));
          dispatch(setSubjectsData(data));
          setLoading(false);
        } else {
          dispatch(showBanner({ variant: 'error', message: responseData.data.message }));
          setLoading(false);
        }
        renderRef.current = true;
        setReloadFunction(false);
      } else {
        setApiCallProcess(true);
        const responseData = await dispatch(GetPreviewSubjectIDs(payload)).then(unwrapResult);
        if (responseData.data.success) {
          setTotalRowCount(responseData.data.totalCount);
          const data = handleSubjectResponse(responseData.data);
          setErrorList(handleErrorData(responseData.data));
          if (sortedColumn !== undefined) {
            const sortedData = sortDataFunction(data, sortedColumn, sortOrder);
            dispatch(setSubjectsData(sortedData));
          } else {
            dispatch(setSubjectsData(data));
          }
          setApiCallProcess(false);
        } else {
          dispatch(showBanner({ variant: 'error', message: responseData.data.message }));
          setApiCallProcess(false);
        }
      }
    })();
  }, [reloadFunction, pageNumber, rowsCount, sortOrder, sortedColumn, paginationFilter]);

  const handleChangeTab = (event, value) => {
    setValue(value);
  };

  const handleSelectRow = (row) => {
    const findUnselected =
      selectedSubjects.length && selectedSubjects.find((item) => item.key === row.key);

    if (selectedSubjects.length === 0) {
      dispatch(setSelectedSubjects([row]));
    } else if (findUnselected && findUnselected !== undefined) {
      const filteredRows = selectedSubjects.filter((item) => item.key !== row.key);
      dispatch(setSelectedSubjects([...filteredRows]));
    } else if (selectedSubjects.length < 50) {
      dispatch(setSelectedSubjects([...selectedSubjects, row]));
    } else {
      return;
    }
  };

  const renderTab = (props) => {
    return (
      <span style={{ display: 'flex', flexDirection: 'row' }} {...props}>
        {errorlist?.length > 0 && (
          <img src={Failure} style={{ height: '15px', marginRight: '5px' }} />
        )}
        <span>{props.children}</span>
      </span>
    );
  };

  const formatedRows = useMemo(() => {
    return (isSwitchOn ? selectedSubjects : subjectsData).map((row) => {
      const isChecked = selectedSubjects.findIndex((item) => item.key === row.key) !== -1;
      const isDisabled = selectedSubjects.length >= 50;
      return {
        ...row,
        handleSelectRow,
        key: row.key,
        className: isChecked && classes.selected,
        isChecked,
        isDisabled
      };
    });
  }, [isSwitchOn, selectedSubjects, subjectsData]);

  const renderParentTabs = (errorCount) => {
    let dynamicTabs;
    if (errorCount === 0) {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label="Subjects" />
        </Tabs>
      );
    } else {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label="Subjects" />
          <Tab label={`Errors (${errorlist.length})`} component={(props) => renderTab(props)} />
        </Tabs>
      );
    }
    return dynamicTabs;
  };

  return (
    <React.Fragment>
      <Typography variant="body2" gutterBottom style={{ margin: '10px 0px 10px 0px' }}>
        Select subjects (Max 50 records) to proceed to the next step
      </Typography>
      {loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        renderParentTabs(errorlist.length)
      )}
      {value === 0 && !loading && (
        <Card style={{ marginTop: '10px' }} ref={screenSizeRef}>
          {apiCallProcess && (
            <div
              style={{
                width: `${screenSizeRef.current?.offsetWidth}px`,
                height: `${screenSizeRef.current?.offsetHeight}px`,
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '9',
                backgroundColor: 'rgba(255,255,255,0.6)'
              }}>
              <ApolloProgress />
            </div>
          )}
          <Table
            columns={columns}
            rows={formatedRows}
            size={isSwitchOn ? selectedSubjects.length : totalRowCount}
            rowsPerPage={isSwitchOn ? 10 : rowsCount}
            sortOrder={sortOrder}
            sortedColumn={sortedColumn}
            page={isSwitchOn ? 0 : pageNumber}
            filterDelay={1500}
            initialSortedColumn={columns[1].accessor}
            initialSortOrder="asc"
            onChange={(args) => {
              setRowsCount(args.rowsPerPage);
              setSortedColumn(args.sortedColumn);
              updateFilter(args.filters);
              setSortOrder(args.sortOrder);
              setPageNumber(args.page);
            }}
            rowsPerPageOptions={
              totalRowCount > 2000 ? [10, 20, 50, 100, 1000] : [10, 20, 50, 100, 'All']
            }
            tablePaginationProps={{
              labelDisplayedRows: DisplayedRowsLabel,
              truncate: true
            }}
            hasScroll
            maxHeight={650}
            defaultRowsPerPage={10}
            CustomHeader={(prop) => (
              <CustomButtonHeader
                {...prop}
                selectedRows={selectedSubjects}
                dispatch={dispatch}
                setSelectedSubjects={setSelectedSubjects}
                setIsSwitchOn={setIsSwitchOn}
                isSwitchOn={isSwitchOn}
              />
            )}
          />
        </Card>
      )}
      {value !== 0 && !loading && (
        <ErrorsDataTable
          ErrorData={errorlist}
          loading={loading}
          reloadFunction={reloadFunction}
          setReloadFunction={setReloadFunction}
        />
      )}
      <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!selectedSubjects.length}
          onClick={handleNext}
          variant="primary"
          size="small">
          {'Next'}
        </Button>
      </div>
    </React.Fragment>
  );
};
export default SelectSubjects;
