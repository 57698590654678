/*eslint-disable */

import React, { useState } from 'react';
import Typography from 'apollo-react/components/Typography';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { makeStyles } from '@mui/styles';

import StepConnector from '@mui/material/StepConnector';
import Button from 'apollo-react/components/Button';

const stepConnectStyles = makeStyles({
  root: {
    paddingTop: '0rem !important'
  },
  lineVertical: {
    // padding: '0rem !important',
    borderLeft: '1px solid #d9d9d9  !important',
    background: 'transparent !important',
    minHeight: '1rem',
    marginLeft: '0px'
  }
});

const useStyles = makeStyles({
  timelineContainer: {
    margin: '2rem 0rem',
    background: 'transparent'
  },
  stepDesc: {
    marginTop: '0.5rem',
    border: '1px solid #D9D9D9',
    padding: '0.5rem'
  }
});

const StepIcon = (icon) => (props) => {
  return (
    <img
      alt={'step-icon'}
      src={icon}
      style={{
        height: '2rem',
        marginLeft: '-0.25rem'
      }}
      {...props}
    />
  );
};

const TimeLine = (props) => {
  const { renderTitle, activity = [], noOfActivityToDisplay = 4 } = props;
  const stepConnectClasses = stepConnectStyles();
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Stepper
        className={classes.timelineContainer}
        activeStep={activity.length}
        orientation="vertical"
        connector={<StepConnector classes={stepConnectClasses} />}>
        {activity
          .slice(0, showMore ? activity.length : noOfActivityToDisplay)
          .map((step, index) => (
            <Step expanded key={'step' + index}>
              <StepLabel StepIconComponent={StepIcon(step.icon)}>
                {renderTitle ? renderTitle(step) : step?.label}
              </StepLabel>
              {step.comments && step.comments !== 'Copied to draft' ? (
                <StepContent style={{ borderLeft: '1px solid #d9d9d9' }}>
                  <Typography className={classes.stepDesc}>{step?.comments}</Typography>
                </StepContent>
              ) : null}
            </Step>
          ))}
      </Stepper>
      {activity.length > noOfActivityToDisplay ? (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ padding: '0rem 1rem' }}
            variant={'text'}
            size={'small'}
            onClick={() => setShowMore(!showMore)}
            data-testid="view">
            {showMore ? 'Show less' : 'Show more'}
          </Button>
        </span>
      ) : null}
    </>
  );
};

export default TimeLine;
