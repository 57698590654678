/*eslint-disable */
import { Grid, Typography } from '@mui/material';
import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import DatePicker from 'apollo-react/components/DatePicker';
import Table, { createStringSearchFilter } from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { compareDates, dateFilter } from 'Utils';
import { compareStrings } from './utilites';
import { DateFilter } from 'Components/Common/DateFilter';

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};

const ItemsNotMapped = (props) => {
  const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
    return (
      <TextField
        value={filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        fullWidth
        margin="none"
        size="small"
      />
    );
  };

  const columns = [
    {
      header: 'Form Name',
      accessor: 'formName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('formName'),
      width: '20%',
      fixedWidth: false
    },
    {
      header: 'Question Text',
      accessor: 'questiontext',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('questiontext'),
      width: '20%',
      fixedWidth: false
    },
    {
      header: 'Item Name',
      accessor: 'itemName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('itemName'),
      width: '20%',
      fixedWidth: false
    },
    {
      header: 'Item OID',
      accessor: 'itemOID',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('itemOID'),
      width: '20%',
      fixedWidth: false
    },
    {
      header: 'Used in Filter Condition',
      accessor: 'usedInFilterCondition',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('usedInFilterCondition'),
      width: '20%',
      fixedWidth: false
    },
    {
      header: 'Created Date',
      accessor: 'createdDate',
      sortFunction: compareDates,
      filterFunction: dateFilter('createdDate'),
      filterComponent: DateFilter,
      width: '20%',
      fixedWidth: false
    }
  ];

  const CustomHeader = ({ toggleFilters }) => {
    return (
      <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography
            style={{
              color: '#000000',
              fontFamily: 'Proxima Nova',
              fontSize: '16px',
              fontWeight: 600,
              letterSpacing: 0,
              display: 'flex',
              alignItems: 'center'
            }}>
            Mapping Spec
          </Typography>
          <Typography
            style={{
              color: 'grey',
              fontFamily: 'Proxima Nova',
              fontSize: '14px',
              fontWeight: 400,
              letterSpacing: 0
            }}>
            Items not Mapped
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              variant="secondary"
              size="small"
              data-testid="filter-btn"
              onClick={toggleFilters}>
              <Filter
                style={{
                  fontSize: '18px',
                  marginRight: '8px'
                }}
              />
              Filter
            </Button>
          </div>
        </div>
      </Grid>
    );
  };

  const EmptyTableComponent = () => {
    return (
      <>
        <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
          No Data found
        </Typography>
      </>
    );
  };

  return (
    <>
      <Table
        columns={columns}
        rows={props.itemsNotMappedData?.map((row) => {
          return {
            ...row,
            key: row.uniqueKey
          };
        })}
        rowsPerPageOptions={[10, 50, 100, 'All']}
        hasScroll
        maxHeight={650}
        rowProps={{ hover: false }}
        initialSortOrder="desc"
        initialSortedColumn="createdDate"
        tablePaginationProps={{
          labelDisplayedRows: DisplayedRowsLabel,
          truncate: true
        }}
        CustomHeader={(props) => <CustomHeader {...props} />}
        emptyProps={{
          text: <EmptyTableComponent />
        }}
      />
    </>
  );
};

export default ItemsNotMapped;
