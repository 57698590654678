/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Grid from 'apollo-react/components/Grid';
import Box from '@mui/material/Box';
import FileUpload from 'apollo-react/components/FileUpload';
import { TextField } from 'Components/Controls';
import { Container, Typography } from 'apollo-react';
import StatusError from 'Images/status-error.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  validationErrorsContainer: {
    maxWidth: 'none',
    padding: '14px',
    border: '1px solid #d9d9d9',
    borderRadius: '5px',
    borderColor: '#E3E3E3',
    backgroundColor: '#FAF5F5',
    margin: '10px 0px 0px 0px'
  },
  errorTitle: {
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'left'
  },
  errorSubTitle: {
    marginTop: '6px',
    fontFamily: 'Proxima Nova',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '16px',
    textAlign: 'left'
  },
  errorContent: {
    fontFamily: 'Proxima Nova',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#E20000'
  }
});

const AddGlobalSDTM = (props) => {
  const classes = useStyles();
  const { formik, fileValidationResult } = props;
  const [errorMessage, setErrorMessage] = useState([]);

  useEffect(() => {
    let message = [];
    Object.entries(fileValidationResult).forEach(([key, value]) => {
      if (key === 'isDataPresent' && value === false)
        message.push(
          'The following fields are required and cannot be empty: seqForOrder, domainPrefix, variableName, variableLabel, role, core, is_sdtm_y/n.'
        );
      if (key === 'isCoreCorrect' && value === false)
        message.push('Invalid “CORE” value. Valid options are: REQ, EXP, and PERM.');
      else if (key === 'isTypeColumnCorrect' && value === false)
        message.push('Invalid “Type” value. Valid options are: Char, Num, and Date.');
      else if (key === 'isMappedByCorrect' && value === false)
        message.push('Invalid “Mapped By” value. Valid options are: R, D, and S.');
      else if (key === 'mandatoryColumns' && value.length > 0)
        message.push(
          `The following fields are required and cannot be empty: ${fileValidationResult.mandatoryColumns.join(
            ', '
          )}.`
        );
      else if (key === 'missingColumns' && value.length > 0)
        message.push(
          `Missing columns: Verify that the following columns are present in your file: ${fileValidationResult.missingColumns.join(
            ', '
          )}.`
        );
      else if (key === 'incorrectDataType' && value.length > 0)
        message.push(`Invalid data type for ${fileValidationResult.incorrectDataType.join(', ')}.`);
      else if (key === 'incorrectDataLength' && value.length > 0)
        message.push(
          `Incorrect data length for ${fileValidationResult.incorrectDataLength.join(', ')}.`
        );
      else if (key === 'isNoFormulaPresent' && value.length > 0)
        message.push(
          `Invalid Data: Formulas are referenced in the file under Column(s): ${fileValidationResult.isNoFormulaPresent.join(
            ', '
          )}, User cannot enter any values using Formulas.`
        );
      else if (key === 'isLengthColumnCorrect' && value === false)
        message.push('Invalid “data_length” value. Value must be whole number and greater than zero for CHAR datatype.');
      else if (key === 'isPrecisionColumnCorrect' && value === false)
        message.push('Invalid “data_precision” value. Value must be whole number and greater than zero for NUM datatype.');
    });
    setErrorMessage(message);
  }, [fileValidationResult]);

  const fileLabel = () => {
    return (
      <>
        {'Upload Library'}
        <span className={props.title} onClick={props.handleDownload}>
          Download Excel Template
        </span>
      </>
    );
  };

  return (
    <>
      <Box width="100%" mt={2} mb={1}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <FileUpload
              id="upload-library"
              label={fileLabel()}
              value={props.uploadLibrary}
              onUpload={props.handleUpload}
              data-testid="file-input"
              onFileDelete={props.openDeleteModal}
              fullWidth
              required
              dropAreaHeight={100}
              maxItems={1}
            />
            <span style={props.error}>{props.errorMessage?.uploadLibrary}</span>
          </Grid>
          <Grid item xs={12}>
            {errorMessage.length > 0 ? (
              <Container className={classes.validationErrorsContainer}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={StatusError}
                    alt
                    style={{ width: '14px', height: '14px', marginRight: '10px' }}
                  />{' '}
                  <Typography className={classes.errorTitle}>File Validation Errors:</Typography>
                </div>
                <div>
                  <Typography className={classes.errorSubTitle}>
                    Please resolve these following errors and reupload the files.
                  </Typography>
                </div>
                <ol style={{ paddingLeft: '13px', marginBottom: '0px', marginTop: '8px' }}>
                  {errorMessage.map((item) => (
                    <li className={classes.errorContent}>
                      <Typography className={classes.errorContent}>{item}</Typography>
                    </li>
                  ))}
                </ol>
              </Container>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <TextField
            id="comments"
            label="Comments (Optional)"
            name="comments"
            data-testid="comment-input"
            multiline
            value={props.formik.values.comments}
            onChange={props.formik.handleChange}
            onBlur={props.formik.handleBlur}
            error={Boolean(props.formik.errors.comments)}
            helperText={props.formik.errors.comments}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};
export default AddGlobalSDTM;
