/* eslint-disable */
import { Grid } from '@mui/material';
import Drag from 'apollo-react-icons/Drag';
import TextField from 'Components/Controls/TextField';
import { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Checkbox from 'apollo-react/components/Checkbox';
import Divider from 'apollo-react/components/Divider';
import { Box, Tooltip } from 'apollo-react';
import ErrorIcon from 'Images/error.svg';
import { IS_SUPPQUAL } from 'Utils/Constants';

const useStyles = makeStyles({
  row: {
    transition: 'all ease 100ms',
    borderTop: '0px solid #0768fd',
    borderBottom: '0px solid #0768fd'
  },
  collapse: {
    width: '100%'
  },
  dragTopRow: {
    width: '100%',
    borderTop: '3px solid #0768fd'
  },
  dragBottomRow: {
    width: '100%',
    borderBottom: '3px solid #0768fd'
  }
});

const ColumnVariable = (props) => {
  const {
    index,
    userDefinedVarCount,
    originalColumnName,
    isSystemColumn,
    onMoveVariableRow,
    checked,
    rowData,
    handleSelectVariableCheckbox,
    readOnly,
    columnNameErrorList,
    handleColumnNameChange,
    updatedColumnName,
    style,
    setMoveToIndexState,
    draggedRowIndex,
    setDraggedRowIndex,
    isSuppQual
  } = props;

  const classes = useStyles();
  const rowref = useRef();
  const [dragPosition, setDragPosition] = useState('');
  const [draggable, setDraggable] = useState(false);
  const [keyDraggable, setKeyDraggable] = useState(false);
  useEffect(() => {
    draggedRowIndex === undefined && setDragPosition('');
  }, [draggedRowIndex === undefined]);

  const handleKeyPress = (event) => {
    const regex = new RegExp('^[a-zA-Z0-9_]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
    }
  };

  const handleOnDragOver = (e) => {
    if (!draggable && index <= userDefinedVarCount) {
      e.dataTransfer.dropEffect = 'move';
      const {
        nativeEvent: { offsetY }
      } = e;
      const offsetHeight = rowref.current.offsetHeight;
      if (offsetY > offsetHeight / 2 && index === userDefinedVarCount - 1) {
        setDragPosition('bottom');
        setMoveToIndexState(index);
      } else if (offsetY < offsetHeight / 2 && index === 0) {
        setDragPosition('top');
        setMoveToIndexState(index);
      } else if (index < draggedRowIndex) {
        setDragPosition('bottom');
        setMoveToIndexState(index + 1);
      } else if (index > draggedRowIndex) {
        setDragPosition('top');
        setMoveToIndexState(index - 1);
      }
    }
    e.preventDefault();
  };

  const handleTransition = (e) => {
    e.target.parentElement.parentElement.style.border = '2px solid #0768fd';
    setTimeout(() => {
      e.target.parentElement.parentElement.style.border = '';
    }, 1000);
  };

  return (
    <Box
      data-testid="box"
      style={style}
      draggable={draggable && !readOnly && !isSystemColumn}
      className={`${
        dragPosition === 'bottom' && !isSystemColumn
          ? classes.dragBottomRow
          : dragPosition === 'top' && !isSystemColumn
          ? classes.dragTopRow
          : classes.collapse
      } ${classes.row}`}
      onDragStart={() => setDraggedRowIndex(index)}
      onDragLeave={() => setDragPosition('')}
      onDragOver={(e) => handleOnDragOver(e)}
      ref={rowref}>
      <Grid item xs={12} style={{ alignItems: 'center', display: 'flex', height: '50px' }}>
        <Grid item xs={1} sx={{ px: 2 }}>
          {!isSystemColumn && userDefinedVarCount > 1 && isSuppQual !== IS_SUPPQUAL.Y ? (
            <Drag
              data-testid="drag"
              tabIndex={userDefinedVarCount <= 1 ? '-1' : '0'}
              onMouseDown={() => !readOnly && setDraggable(true)}
              onMouseUp={() => {
                setDragPosition('');
                setKeyDraggable(false);
              }}
              onClick={() => !readOnly && setKeyDraggable(true)}
              onFocus={() => !readOnly && setKeyDraggable(true)}
              onBlur={() => setKeyDraggable(false)}
              onKeyDown={
                keyDraggable
                  ? (e) => {
                      if (e.key === 'ArrowUp' && index !== 0) {
                        let moveToIndex = index - 1;
                        onMoveVariableRow(index, moveToIndex);
                        handleTransition(e);
                      } else if (e.key === 'ArrowDown' && index < rowData.length - 1) {
                        let moveToIndex = index + 1;
                        onMoveVariableRow(index, moveToIndex);
                        handleTransition(e);
                      }
                    }
                  : undefined
              }
              style={{
                size: 'large',
                display: 'flex',
                flexDirection: 'row',
                padding: '3%',
                background: keyDraggable ? '#d9d9d9' : 'white',
                outline: 0,
                cursor: !readOnly ? (draggable ? 'grabbing' : 'grab') : 'arrow'
              }}
            />
          ) : !isSystemColumn && userDefinedVarCount <= 1 && isSuppQual !== IS_SUPPQUAL.Y ? (
            <Drag
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '3%',
                color: '#b5b5b5'
              }}></Drag>
          ) : (
            ''
          )}
        </Grid>
        <Grid xs={1}>
          <Checkbox
            data-testid="checkbox"
            checked={checked}
            onChange={(e) => handleSelectVariableCheckbox(index)}
            disabled={isSystemColumn || readOnly || isSuppQual === IS_SUPPQUAL.Y}
          />
        </Grid>
        {isSuppQual === IS_SUPPQUAL.Y ? (
          <Tooltip title="Supplemental Qualifiers">
            <Grid xs={3} style={{ display: 'flex' }}>
              {originalColumnName}
              <span
                style={{
                  background: '#FFF',
                  border: '1px solid #888',
                  borderRadius: '30px',
                  padding: '0px 8px',
                  marginLeft: '5px',
                  fontSize: '14px'
                }}>
                SQ
              </span>
            </Grid>
          </Tooltip>
        ) : (
          <Grid xs={3} style={{ fontSize: '14px' }}>
            {originalColumnName}
          </Grid>
        )}
        <Grid xs={3} style={{ alignItems: 'center', display: 'flex' }}>
          <TextField
            value={readOnly ? '' : updatedColumnName}
            label=""
            InputProps={{
              inputProps: {
                maxLength: 100
              }
            }}
            onKeyDown={(e) => {
              // console.log('keydown', e.key);
              // e.preventDefault();
              // e.stopPropagation();
            }}
            placeholder=""
            style={{ width: '145px', marginTop: '0px' }}
            disabled={isSystemColumn || readOnly || !checked || isSuppQual === IS_SUPPQUAL.Y}
            onChange={(e) => handleColumnNameChange(index, e)}
            error={columnNameErrorList.includes(updatedColumnName?.toUpperCase())}
            onKeyPress={handleKeyPress}
          />
          {columnNameErrorList.includes(updatedColumnName?.toUpperCase()) ? (
            <Tooltip title="This name already exist." placement="right">
              <img alt="error-icon" style={{ paddingLeft: '5px' }} src={ErrorIcon} />
            </Tooltip>
          ) : null}
        </Grid>
        <Grid xs={4} style={{ fontSize: '14px' }}>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {isSystemColumn ? 'System Fields' : ''}
          </span>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default ColumnVariable;
