/*eslint-disable*/
import { makeStyles } from '@mui/styles';
import React from 'react';
import Table from 'apollo-react/components/Table';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import Modal from 'apollo-react/components/Modal';
import { errorColumns } from './columns.data';
import { detailsObj } from '../../MDSDomainRules/detailsObject';
const useStyles = makeStyles({
  errorModal: {
    maxWidth: '800px',
    maxHeight: '550px'
  },
  ...detailsObj
});

const InvalidRulesetComp = ({
  ruleset,
  errorInfo,
  domain,
  rulesetVersion,
  openModal,
  setOpenModal
}) => {
  const classes = useStyles();
  return (
    <>
      {openModal && (
        <Modal
          className={classes.errorModal}
          open={openModal}
          title={'Error list '}
          data-testid="confirm-modal"
          onClose={() => {
            setOpenModal(false);
          }}
          hideButtons={true}>
          <>
            <Box
              display={'flex'}
              flexDirection={'row'}
              width={'100%'}
              justifyContent={'space-between'}>
              <Box display={'flex'} flexDirection={'row'}>
                <Box className={classes.details}>
                  <Typography variant="body2" className={classes.detailsLabel}>
                    Domain Name
                  </Typography>
                  <Typography variant="title" className={classes.detailsContent}>
                    {domain}
                  </Typography>
                </Box>
                <Box className={classes.details}>
                  <Typography variant="body2" className={classes.detailsLabel}>
                    Ruleset Name
                  </Typography>
                  <Typography variant="title" className={classes.detailsContent}>
                    {ruleset}
                  </Typography>
                </Box>
                <Box className={classes.details}>
                  <Typography variant="body2" className={classes.detailsLabel}>
                    Ruleset Version
                  </Typography>
                  <Typography variant="title" className={classes.detailsContent}>
                    {rulesetVersion}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Table
              title={'Errors'}
              subtitle={`${errorInfo.length} Error${errorInfo.length > 1 ? 's' : ''} found`}
              columns={errorColumns}
              rows={errorInfo}
              defaultRowsPerPage={10}
              rowsPerPageOptions={[10, 50, 100, 'All']}
              // hasScroll
              initialSortedColumn="variableName"
              initialSortOrder="asc"
            />
          </>
        </Modal>
      )}
    </>
  );
};

export default InvalidRulesetComp;
