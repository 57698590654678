import { astMapper, parse } from 'pgsql-ast-parser';

const validateNoOfQueries = (ast) => {
  if (ast?.length === 0) {
    throw `Provide a valid SQL query.`;
  } else if (ast?.length > 1) {
    throw `Only one statement is allowed at a time.`;
  }
};

const validateQueryTypes = (ast, isExistingDomain) => {
  const validQueryTypes = [];
  if (isExistingDomain) {
    validQueryTypes.push('update');
  } else {
    validQueryTypes.push('select', 'with');
  }
  if (!validQueryTypes.includes(ast?.type)) {
    throw `The following query statement types are not allowed for ${
      isExistingDomain ? 'Existing Domains' : 'Custom Domains'
    }: <strong>${ast?.type}</strong>`;
  }
};

const validateWildCardSelection = (ast) => {
  const mapper = astMapper(() => ({
    ref: (exp) => {
      if (exp.type === 'ref' && exp.name === '*') {
        throw '<strong>select *</strong> is not allowed';
      }
      return exp;
    }
  }));
  mapper.statement(ast);
};

const isColumnValid = (validTablesMapping) => (column, table) => {
  if (table === '*') {
    return Object.values(validTablesMapping).some((variables) => variables[column.toUpperCase()]);
  } else {
    return validTablesMapping[table?.toUpperCase()]?.[column.toUpperCase()];
  }
};

const validateQueryMetadata = (ast, domainVairableMapping,isExistingDomain, domainCode) => {
  //{DM:{AGE:true, RACE:true}}
  const validTablesMapping = {};
  domainVairableMapping.forEach((domain) => {
    validTablesMapping[domain.domainCode?.toUpperCase()] = {};
    domain.variableName.forEach((variable) => {
      validTablesMapping[domain.domainCode?.toUpperCase()][variable?.toUpperCase()] = true;
    });
    if (['select', 'with'].includes(ast?.type) && !isExistingDomain)
      domain.reservedVariables?.forEach((resVariable) => {
        validTablesMapping[domain.domainCode?.toUpperCase()][resVariable?.toUpperCase()] = true;
      });
  });

  //{[alias/tableName]:[tableName]}
  const parsedTablesWithAlias = {};
  const invalidTables = [];
  const tableMapper = astMapper((map) => ({
    tableRef: (table) => {
      let _map = map.super().tableRef(table);
      // console.log('table', table);
      if (table.alias) {
        parsedTablesWithAlias[table.alias] = table.name;
      }
      parsedTablesWithAlias[table.name] = table.name;
      if (!validTablesMapping[table.name.toUpperCase()]) {
        invalidTables.push(table.name);
      }
      return _map;
    },
    fromStatement: (exp) => {
      console.log('fromStatement', exp);
      let _map = map.super().fromStatement(exp);

      parsedTablesWithAlias[exp.alias] = exp.alias;
      if (!validTablesMapping[exp.alias.toUpperCase()]) {
        invalidTables.push(exp.alias);
      }
      return _map;
    }
  }));
  tableMapper.statement(ast);

  const invalidColumns = [];
  const checkIsColumnValid = isColumnValid(validTablesMapping);
  const columnMapper = astMapper((map) => ({
    ref: (column) => {
      const columnName = column.name,
        tableName = column.table?.name;

      if (!checkIsColumnValid(columnName, tableName ? parsedTablesWithAlias[tableName] : '*')) {
        invalidColumns.push(tableName ? `${tableName}.${columnName}` : columnName);
      }

      return column;
    },
    update: (updateExp) => {
      if (!updateExp?.table?.name?.match(new RegExp(`(${domainCode})`, 'ig'))?.length) {
        throw `The table name [${updateExp?.table?.name}] used in query do not match the selected domain, 
        only [${domainCode}] table can be updated.`;
      }
      map.super().update(updateExp);
      return updateExp;
    },
    set: (setExp) => {
      if (!checkIsColumnValid(setExp.column.name, domainCode)) {
        invalidColumns.push(setExp.column.name);
      }
      return setExp;
    }
  }));
  columnMapper.statement(ast);
  if (invalidTables?.length || invalidColumns.length) {
    throw `The following references in the query are not valid \n${
      invalidTables?.length
        ? ['<em>Table references:</em><strong>', invalidTables?.join(', '), '</strong>\n'].join(' ')
        : ''
    }${
      invalidColumns?.length
        ? ['<em>Column references:</em><strong>', invalidColumns?.join(', '), '</strong>'].join(' ')
        : ''
    }`;
  }
};

const validateTableRelation = (ast, domainVairableMapping,isExistingDomain, domainCode) => {
  //{DM:{AGE:true, RACE:true}}
  const _domainVairableMapping = [...domainVairableMapping];

  const mapper = astMapper((map) => ({
    with: (withExp) => {
      let _map = map.super().with(withExp);
      console.log('with', withExp);
      withExp.bind?.forEach((exp) => {
        _domainVairableMapping?.push({
          domainCode: exp.alias?.name?.toUpperCase(),
          variableName: exp?.statement?.columns?.map((column) =>
            (column?.alias?.name || column?.expr?.name)?.toUpperCase()
          )
        });
      });
      return _map;
    },
    fromStatement: (exp) => {
      console.log('fromStatement', exp);
      let _map = map.super().fromStatement(exp);
      try {
        _domainVairableMapping?.push({
          domainCode: exp.alias?.toUpperCase(),
          variableName: exp?.statement?.columns?.map((column) => column?.expr?.name?.toUpperCase())
        });

        const _exp = { ...exp };
        _exp.name = { name: exp.alias, alias: exp.alias };
        _exp.type = 'table';
        delete _exp.alias;
        delete _exp.statement;
        return _exp;
      } catch (error) {
        console.log('Error validating fromStatement ::', error);
      }
      return _map;
    }
  }));
  mapper.statement(ast);
  validateQueryMetadata(ast, _domainVairableMapping,isExistingDomain, domainCode);
};

export const validatePgSqlQuery = (query, domainVairableMapping, isExistingDomain, domainCode) => {
  const ast = parse(query);
  validateNoOfQueries(ast);
  validateQueryTypes(ast[0], isExistingDomain);
  validateWildCardSelection(ast[0]);
  validateTableRelation(ast[0], domainVairableMapping,isExistingDomain, domainCode);
  return ast[0];
};

export const formatErrorMessage = (error) => {
  let errorMessage = '';
  if (typeof error === 'string') {
    errorMessage = error;
  } else {
    if (error.message.includes('Unexpected end of input')) {
      errorMessage = 'Provide a valid SQL query.';
    } else if (error?.message?.includes('Instead,')) {
      errorMessage = error.message.split('Instead,')[0];
    } else if (error?.message?.includes('I did not')) {
      errorMessage = error.message.split('I did not')[0];
    } else {
      errorMessage = error.message;
    }
  }
  return errorMessage;
};
