import {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import { createTwoWayObject, dateFilter, compareDates } from 'Utils';
/*
***
createTwoWayObject - refer full comments about it in src/Utils/index.js

Quick usage review
In the returned value we could access both ways like below,
1. codeListColumnNames.odmForm => ODM form / Data Set Mnemonic
2. codeListColumnNames["ODM form / Data Set Mnemonic"] = odmForm

In addition, any property provided with key can be accessed as below,
1. codeListColumnNames.odmForm_props.type  // => STRING

These additional props can be used to sort, validate or filter wherever required 
*/

const codeListColumnNames = createTwoWayObject({
  'Source Form': {
    key: 'odmForm',
    type: 'STRING',
    isMandatory: () => true
  },
  'Source Field': {
    key: 'odmItem',
    type: 'STRING',
    isMandatory: () => true
  },
  'Question Text': {
    key: 'questionText',
    type: 'STRING',
    isMandatory: () => true
  },
  'Source Codelist': {
    key: 'odmCodeListName',
    type: 'STRING',
    isMandatory: () => true
  },
  'Source Value': {
    key: 'value',
    type: 'STRING',
    isMandatory: () => true
  },
  'Source Translated text': {
    key: 'odmCodelistLabel',
    type: 'STRING',
    isMandatory: () => true
  },
  'Modified Translated text': {
    key: 'sdmCodelistLabel',
    type: 'STRING',
    isMandatory: () => false
  },
  'Source Field ID': {
    key: 'itemOid',
    type: 'STRING',
    isMandatory: () => true
  },
  'Updated Date': {
    key: 'iqUpdateDate',
    type: 'DATE',
    isMandatory: () => false
  }
});

const codeListObj = {
  'Source Form':'',
  'Source Field':'',
  'Source Codelist':'',
  'Question Text':'',
  'Source Value':'',
  'Source Translated text':'',
  'Modified Translated text':'',
  'Source Field ID':''
}

const genericCodeListColumnNames = createTwoWayObject({
  'Source Codelist': {
    key: 'odmCodeListName',
    type: 'STRING',
    isMandatory: () => true
  },
  'Source Value': {
    key: 'value',
    type: 'STRING',
    isMandatory: () => true
  },
  'Source Translated text': {
    key: 'odmCodelistLabel',
    type: 'STRING',
    isMandatory: () => true
  },
  'Modified Translated text': {
    key: 'sdmCodelistLabel',
    type: 'STRING',
    isMandatory: () => false
  },
  'Updated Date': {
    key: 'iqUpdateDate',
    type: 'DATE',
    isMandatory: () => false
  }
});

const defaultGenericColumns = [{
  'odmForm': "",
  'odmItem': "",
  'questionText': "",
  'itemOid': "",
  'sdmCodelistLabel': "",
  'odmCodelistLabel': "",
  'odmCodeListName': "",
  '': ""
}]

const getTypeBasedColumnProps = ({ type, accessor, TextFieldFilter, IntegerFilter, DateFilter }) => {
  switch (type) {
    case 'STRING':
      return {
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter(accessor),
        sortFunction: compareStrings
      };
    case 'NUMBER':
      return {
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter(accessor),
        sortFunction: compareNumbers
      };
    case 'DATE':
      return {
        filterComponent: DateFilter,
        filterFunction: dateFilter(accessor),
        sortFunction: compareDates,
      }
  }
};

const keysToDifferentiate = ['odmForm',
'odmItem',
'questionText',
'itemOid'];

const validateIsGenericCodeList = (record) => {
  let isGeneric = true;
    keysToDifferentiate.forEach((key) => {
      if (record[key]) {
        isGeneric = false;
      }
    });
  return isGeneric;
}

export { codeListColumnNames, getTypeBasedColumnProps, genericCodeListColumnNames,defaultGenericColumns, validateIsGenericCodeList, codeListObj };
