import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Tooltip from 'apollo-react/components/Tooltip';

export const EllipsisCell = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '10rem' }}>
      <Tooltip variant="light" title={row.message} placement="bottom" open={open}>
        <Typography
          noWrap
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {row.message}
        </Typography>
      </Tooltip>
    </div>
  );
};
