import React, { useCallback, useState } from 'react';
import Button from 'apollo-react/components/Button';
import TextField from 'apollo-react/components/TextField';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  subHeaderRow: {
    backgroundColor: '#f8f9fb'
  },
  subHeaderActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    marginBottom: '16px'
  }
});

export const useNewRow = (row = {}) => {
  const [newRow, setNewRow] = useState(row);

  const editNewRow = useCallback(
    (key, value) => {
      setNewRow((newRow = {}) => {
        newRow[key] = value;
        return newRow;
      });
    },
    [newRow, setNewRow]
  );

  return [newRow, setNewRow, editNewRow];
};

export const TableNewRow = (props) => {
  const classes = useStyles();
  const { isAdding, setIsAdding, newRow, editNewRow, setNewRow, onSave, columns } = props;

  const onSaveNewRow = (andAdd = false) => {
    onSave();
    setNewRow({});
    setIsAdding(andAdd);
  };

  const onCancel = () => {
    setNewRow({});
    setIsAdding(false);
  };

  return (
    <>
      {isAdding && (
        <>
          <TableRow className={classes.subHeaderRow}>
            {columns.map((item, index) => (
              <TableCell key={`datatable-column-item-${index}`}>
                {item?.header && (
                  <TextField
                    className={item.className}
                    component="span"
                    inline="true"
                    placeholder={item?.placeholder}
                    value={newRow[item.accessor]}
                    onChange={(e) => editNewRow(item.accessor, e.target.value)}>
                    {item.header}
                  </TextField>
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow className={classes.subHeaderRow}>
            <TableCell colSpan={columns.length}>
              <div className={classes.subHeaderActions}>
                <Button size="small" variant="text" onClick={onCancel} style={{ marginRight: 8 }}>
                  {'Cancel'}
                </Button>
                <Button
                  size="small"
                  variant="secondary"
                  onClick={() => onSaveNewRow(true)}
                  style={{ marginRight: 8 }}>
                  {'Save and add another'}
                </Button>
                <Button size="small" variant="primary" onClick={() => onSaveNewRow(false)}>
                  {'Save'}
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};
