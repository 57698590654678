export const getIndexBasedOnModuleName = (functionData, moduleName, mappingRuleVersionId) => {
  const index = functionData.findIndex((data) => {
    return data.moduleName === moduleName;
  });
  const nextElement = functionData[index + 1];
  return `/product-designer/rule-editor/${mappingRuleVersionId}/${nextElement.moduleName}`;
};

export const isModuleRequired = (functionData, moduleName) => {
  const index = functionData.findIndex((data) => {
    return data.moduleName === moduleName;
  });
  if (index !== -1) {
    const nextElement = functionData[index];
    return nextElement.isRequired;
  }
  return true;
};

export default { getIndexBasedOnModuleName, isModuleRequired };
