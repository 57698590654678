/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getPreSQLQueries = createAsyncThunk(
  'Protocol/GetPreSQLQueries',
  async ({ libraryID, version }) => {
    return await axios.get(
      `${API_URL}/api/Protocol/GetPreSQLQueries?LibraryID=${libraryID}&Version=${version}`
    );
  }
);

export const RunPreSQLQueries = createAsyncThunk(
  'Protocol/RunPreSQLQueries',
  async (payload) => {
    return await axios.post(
      `${API_URL}/api/Protocol/RunPreSQLQueries`, payload
    );
  }
);

export const GetStudyDataSetName = createAsyncThunk(
  'Protocol/GetStudyDataSetNames',
  async ({ protocolName }) => {
    return await axios.get(
      `${API_URL}/api/Protocol/GetStudyDataSetNames?protocolName=${protocolName}`
    );
  }
)

export const GetCustomSQLQueryIntellisense = createAsyncThunk(
  'Protocol/GetCustomSQLQueryIntellisense',
  async (payload) => {
    return await axios.get(
      `${API_URL}/api/Protocol/GetCustomSQLQueryIntellisense`, {params: payload}
    );
  }
)
