import Table, { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import React from 'react';
import { ExpressionCell } from '../VLCRules.columns';
import { useSelector } from 'react-redux';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';

const EllipsisCell = ({ row, column: { accessor } }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '25rem' }}>
      <Tooltip variant="light" title={row[accessor]} placement="bottom" open={open}>
        <Typography
          variant="bodyDefault"
          noWrap
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {row[accessor]}
        </Typography>
      </Tooltip>
    </div>
  );
};

export const columns = [
  {
    header: 'VLC Rule Name',
    accessor: 'vlcRuleName',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('vlcRuleName'),
    filterComponent: TextFieldFilter,
    width: 250
  },
  {
    header: 'Columns to Raise Issue Against (Values)',
    accessor: 'columnValues',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('columnValues'),
    filterComponent: TextFieldFilter,
    customCell: EllipsisCell,
    width: 500
  },
  {
    header: 'VLC Action Condition',
    accessor: 'vlcActionCondition',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('vlcActionCondition'),
    filterComponent: TextFieldFilter,
    width: 200
  },
  {
    header: 'VLC Action Message',
    accessor: 'vlcActionMessage',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('vlcActionMessage'),
    filterComponent: TextFieldFilter,
    customCell: EllipsisCell,
    width: 200
  },
  {
    header: 'Expression',
    accessor: 'expression',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('expression'),
    filterComponent: TextFieldFilter,
    customCell: ExpressionCell,
    width: 200
  }
];

const VLCResultsTable = (props) => {
  const { vlcResultsData } = props;
  const { selectedVLCRules } = useSelector((state) => state.DomainPreviewData);
  return (
    <Table
      title={'VLC Results'}
      subtitle={`Dataset: ${selectedVLCRules?.dataset}`}
      columns={columns}
      rows={vlcResultsData}
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      initialSortedColumn="vlcRuleName"
      initialSortOrder="asc"
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      hasScroll
      maxHeight={650}
    />
  );
};

export default VLCResultsTable;
