let userToken = {};

export const setUserToken = (key, value) => {
  if (typeof key === 'undefined') throw new Error('KEY CANNOT BE NULL');

  userToken[key] = value;
  return value;
};

export const getUserToken = (key) => {
  if (typeof key === 'undefined') throw new Error('KEY CANNOT BE NULL');
  return userToken[key];
};
