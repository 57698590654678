/* eslint-disable */
import { unwrapResult } from '@reduxjs/toolkit';
import Download from 'apollo-react-icons/Download';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import Loader from 'Components/Loader/Loader';
import Failure from 'Images/status-failure.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectDomainPreviewData } from 'Redux/Service/DomainPreviewService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setCacheFlag } from 'Redux/Slice/DomainPeviewSlice';
import DomainDataTable, { useDomainDataTableConfig } from './DomainDataTable';
import ErrorsDataTable from './ErrorsDataTable';
import MasteredSubjectsTable from './MasteredSubjectsTable';
import { downloadPreviewData } from './utils';
import { handlePayload } from './ValidateSourcePayload';
import { Notification } from 'Components/Common/Notification';
import { makeStyles } from '@mui/styles';
import Cookies from 'js-cookie';
import VLCResultsTable from '../../RuleEditor/VLCRules/VLCRulesPreview/VLCResultsTable';
import { PREVIEW_TYPES } from './PreviewTypes';

const useStyles = makeStyles({
  notification: {
    marginRight: '1rem',
    '&>.wrapper': {
      display: 'flex',
      flexDirection: 'row',
      '&>.icon': {
        display: 'flex',
        flexDirection: 'row'
      },

      '&>.icon:before': {
        margin: '4px !important'
      }
    }
  }
});

const DomainData = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [errorlist, setErrorList] = useState([]);
  const [masteredData, setMasteredData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [vlcResultsData, setVlcResultsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mastData, setMastData] = useState([]);
  const [houseKeepingPrefix, setHouseKeepingPrefix] = useState('-');
  const [domainDataHeaders] = useDomainDataTableConfig(domainData, mastData, houseKeepingPrefix);
  const [suppQualData, setSuppQualData] = useState([]);

  const dispatch = useDispatch();
  const { selectedDomainSourceData, selectedSubjects, cacheFlag, selectedVLCRules } = useSelector(
    (state) => state.DomainPreviewData
  );
  const userLoginID = Cookies.get('user.id');
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { selectedSourceData } = useSelector((state) => state.PublishMappingSpecData);

  const { domainCode, mdsDomainActive = false, type = PREVIEW_TYPES.PREVIEW } = props;
  const domainFlag = true;
  const handleChangeTab = (event, value) => {
    setValue(value);
  };

  const handleMasterData = (data) => {
    if (data.previewSubjectDomainOutputData?.masteredSubjects?.length) {
      const masteredSubjectsData = data.previewSubjectDomainOutputData.masteredSubjects.map(
        (item) => {
          return {
            usubjid: item.usubjid,
            locators: item.locators,
            groupId: item.grouP_ID,
            clusterId: item.clusteR_ID,
            sex: item.sex,
            brthdtc: item.brthdtc,
            lastUpdated: item.lastUpdateDate
          };
        }
      );
      return masteredSubjectsData;
    } else {
      setLoading(false);
      return [];
    }
  };

  const handleErrorData = (data) => {
    if (data.previewSubjectDomainOutputData?.errorLists?.length) {
      const errorListData = data.previewSubjectDomainOutputData?.errorLists.map((item) => {
        return {
          errorCode: item.errorCode,
          category: item.category,
          message: item.message,
          logType: item.logType,
          isError: item.logType === 'Error' ? 'Yes' : 'No'
        };
      });
      return errorListData;
    } else {
      setLoading(false);
      return [];
    }
  };

  const handleDomainData = (data) => {
    let _domainData = data.previewSubjectDomainOutputData?.subjectDomainDataJSONString;
    let _suppQualData = [];
    let _mastData = data.previewSubjectDomainOutputData?.masteredRulesetVariables;
    if (_mastData !== undefined && _mastData !== '' && _mastData !== null) {
      setMastData(_mastData);
    }
    if (_domainData !== undefined && _domainData !== '') {
      const domainSuppQualValue = JSON.parse(_domainData)
        .flatMap((obj) => Object.keys(obj))
        .filter((item) =>
          data.previewSubjectDomainOutputData?.domainSuppQualVariables?.includes(item)
        );
      _suppQualData.push(...new Set(domainSuppQualValue));
      setSuppQualData(_suppQualData);
      return JSON.parse(_domainData);
    } else {
      setLoading(false);
      return [];
    }
  };

  const handleVlcResultsData = (data) => {
    let _vlcData = data?.previewSubjectDomainOutputData?.vlcIssueDataJSONString;
    if (_vlcData) {
      return JSON.parse(_vlcData);
    } else {
      return [];
    }
  };

  const getDomainPreviewData = async (filters = {}, isExactMatch = false) => {
    setLoading(true);
    const payload = handlePayload(
      selectedDomainSourceData,
      selectedSubjects,
      domainCode,
      rowDataForRuleEditor.productNnemonic,
      userLoginID,
      selectedSourceData,
      rowDataForRuleEditor.targetDataModelType,
      domainFlag
    );
    payload.useCache = cacheFlag;
    payload.rulesetFilterValues = filters;
    payload.isExactMatch = isExactMatch;
    if (type === PREVIEW_TYPES.VLCRULES) {
      payload.vlcRulesID = selectedVLCRules?.vlcRules
        ? selectedVLCRules.vlcRules.map((item) => item.vlcRuleID)
        : [];
    }
    const responseData = await dispatch(getSubjectDomainPreviewData(payload)).then(unwrapResult);
    if (responseData.data) {
      const data = JSON.parse(JSON.stringify(responseData.data));
      setMasteredData(handleMasterData(data));
      setErrorList(handleErrorData(data));
      setDomainData(handleDomainData(data));
      setVlcResultsData(handleVlcResultsData(data))
      setHouseKeepingPrefix(
        data?.previewSubjectDomainOutputData?.hkVariablePrefixName?.toLowerCase()
      );
      dispatch(setCacheFlag(true));
      setLoading(false);
    } else {
      dispatch(showBanner({ variant: 'error', message: responseData.data.message }));
      setErrorList(handleErrorData(responseData.data));
      setLoading(false);
    }
  };

  useEffect(() => {
    getDomainPreviewData();
  }, []);

  const renderTab = (props) => {
    return (
      <span style={{ display: 'flex', flexDirection: 'row' }} {...props}>
        {errorlist?.length > 0 && (
          <img src={Failure} style={{ height: '15px', marginRight: '5px' }} />
        )}
        <span>{props.children}</span>
      </span>
    );
  };

  const renderParentTabs = (errorCount) => {
    let dynamicTabs;
    if (mdsDomainActive) {
      let tabList = [
        <Tab label="Domain Data" />,
        <Tab label="VLC Results" />,
        <Tab label={`Errors (${errorlist.length})`} component={(props) => renderTab(props)} />
      ];
      if (errorCount === 0) {
        tabList.splice(2, 1);
      }
      if (type !== PREVIEW_TYPES.VLCRULES) {
        tabList.splice(1, 1);
      }
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          {tabList}
        </Tabs>
      );
    } else if (errorCount === 0) {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label="Mastered Subjects" />
          <Tab label="Domain Data" />
        </Tabs>
      );
    } else {
      dynamicTabs = (
        <Tabs value={value} onChange={handleChangeTab} truncate>
          <Tab label="Mastered Subjects" />
          <Tab label="Domain Data" />
          <Tab label={`Errors (${errorlist.length})`} component={(props) => renderTab(props)} />
        </Tabs>
      );
    }
    return dynamicTabs;
  };

  const handleSearchInDB = (filters, isExactMatch) => {
    return getDomainPreviewData(filters, isExactMatch);
  };

  const handleResetTableData = () => {
    return getDomainPreviewData({});
  };

  const downloadPayload = {
    selectedDomainSourceData,
    protocol,
    errorlist,
    domainData,
    domainCode,
    masteredData,
    rowDataForRuleEditor,
    domainDataHeaders,
    vlcResultsData,
    selectedVLCRules: selectedVLCRules?.vlcRules
  };

  return (
    <React.Fragment>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Notification
            className={classes.notification}
            hide={!mdsDomainActive}
            header={''}
            message={
              <>
                <span style={{ fontWeight: '600' }}>Note: </span>
                By default, the system shows only the first 50 records from the domain data for the
                Ruleset target data model; if you need to search for a record that is not among
                these, use the column filters along with the '
                <span style={{ fontWeight: '600' }}>Search in database</span>' button under the
                Domain Data tab.
              </>
            }
          />
          <Download
            style={
              loading
                ? {
                    color: 'grey'
                  }
                : {
                    cursor: 'pointer'
                  }
            }
            onClick={() => !loading && downloadPreviewData(downloadPayload)}
          />
        </div>
      </div>
      <div>
        {loading ? (
          <DataVizCard
            style={{
              position: 'absolute',
              zIndex: 10,
              width: 'calc(100% - 4rem)',
              height: (mdsDomainActive ? value === 0 : value === 1 && domainData?.length > 0)
                ? 'calc(730px + 4rem)'
                : 'calc(656px + 4rem)',
              boxShadow: 'none',
              border: 'none'
            }}>
            <Loader />
          </DataVizCard>
        ) : (
          <></>
        )}
        <div style={{ marginBottom: '1rem', wodth: '100%' }}>
          {renderParentTabs(errorlist.length)}
        </div>
        {value === 0 && !mdsDomainActive && (
          <MasteredSubjectsTable domainCode={domainCode} masteredData={masteredData} />
        )}
        {(mdsDomainActive ? value === 0 : value === 1) && (
          <DomainDataTable
            handleSearchInDB={handleSearchInDB}
            handleResetTableData={handleResetTableData}
            domainCode={domainCode}
            domainData={domainData}
            masteredVarData={mastData}
            mdsDomainActive={mdsDomainActive}
            isErrorResponse={errorlist?.length && !domainData?.length}
            houseKeepingPrefix={houseKeepingPrefix}
            suppQualData={suppQualData}
          />
        )}
        {mdsDomainActive && type === PREVIEW_TYPES.VLCRULES && value === 1 && (
          <VLCResultsTable vlcResultsData={vlcResultsData} />
        )}
        {mdsDomainActive && type !== PREVIEW_TYPES.VLCRULES
          ? value === 1
          : value === 2 && (
              <ErrorsDataTable
                ErrorData={errorlist}
                setReloadFunction={() => getDomainPreviewData()}
              />
            )}
      </div>
    </React.Fragment>
  );
};

export default DomainData;
