import React from 'react';
import Grid from 'apollo-react/components/Grid';
import { makeStyles } from '@mui/styles';
import Typography from 'apollo-react/components/Typography';
import Tooltip from 'apollo-react/components/Tooltip';

const useStyles = makeStyles({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
  },
  content: {
    minWidth: '25%'
  },
  questionContent: {
    minWidth: '20%',
    padding: '2px'
  },
  header: {
    color: '#595959',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  value: {
    color: '#595959',
    fontSize: '14px',
    lineHeight: '24px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

/**
 * SourceDetails - Component to render the provided source details
 * @props {*} sourceDetails
 */
const SourceDetails = ({ sourceDetails }) => {
  const { odmForm, odmItem, itemOid, odmCodeListName, questionText } = sourceDetails;
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.container}>
        <Grid item className={classes.content}>
          <Typography className={classes.header}>Source Form</Typography>
          <Tooltip title={odmForm}>
            <Typography className={classes.value}>{odmForm}</Typography>
          </Tooltip>
        </Grid>

        <Grid item className={classes.content}>
          <Typography className={classes.header}>Source Field</Typography>
          <Tooltip title={odmItem}>
            <Typography className={classes.value}>{odmItem}</Typography>
          </Tooltip>
        </Grid>

        <Grid item className={classes.content}>
          <Typography className={classes.header}>Source Field ID</Typography>
          <Tooltip title={itemOid}>
            <Typography className={classes.value}>{itemOid}</Typography>
          </Tooltip>
        </Grid>

        <Grid item className={classes.content}>
          <Typography className={classes.header}>Source Codelist Name</Typography>
          <Tooltip title={odmCodeListName}>
            <Typography className={classes.value}>{odmCodeListName}</Typography>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid item className={classes.questionContent}>
        <Typography className={classes.header}>Question Text:</Typography>
        <Tooltip title={questionText}>
          <Typography className={classes.value}>{questionText}</Typography>
        </Tooltip>
      </Grid>
    </>
  );
};

export default SourceDetails;
