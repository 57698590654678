import { Modal, Typography } from 'apollo-react';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { goToCore } from 'App';

const Logout = () => {
  const location = useLocation();
  const onLogin = async () => {
    goToCore();
  };
  useEffect(() => {
    if (!location?.state?.logout) {
      goToCore();
    }
  }, [location.state]);

  window.history.replaceState({}, document.title);

  const styles = {
    modal: {
      width: '980px'
    }
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <>
      <img src="images/logoutBackground.webp" alt="..." />
      <Modal
        variant="default"
        title="Logged out"
        open={true}
        className={classes.modal}
        buttonProps={[{ label: 'Log in', onClick: onLogin, variant: 'primary' }]}>
        <Typography variant="h5">
          Thank you for using the Clinical Data Analytics Suite. You are now logged out.
        </Typography>
      </Modal>
    </>
  );
};

export default Logout;
