/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { uuidv4 } from 'Utils';

import {
  GetRuleMetadataFormItems,
  GetVariableMappingRules,
  GetRuleExpressionMethodsAndConstants
} from 'Redux/Service/MasteringRuleService';
import { unwrapResult } from '@reduxjs/toolkit';
import { downloadGlobalLibrary } from 'Redux/Service/GlobalLibraryService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useNavigate, useParams } from 'react-router-dom';

import { GetProductDataSourcesByMappingRuleVersionID } from 'Redux/Service/RuleEditorService';
import BulkEditScreen from 'Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/DomainRules/BulkEditScreen';
import { BulkEditContext } from './BulkEditLayout';

import Loader from 'apollo-react/components/Loader';

const CDR_TABULAR = 'CDR Tabular';

const BulkEditPage = () => {
  const contextData = useContext(BulkEditContext);
  const {
    filters,
    setFilters,
    setFormMetaData,
    setDomainData,
    domainData,
    setDomainFormattedData,
    domainFormattedData,
    setNewDomainFormattedData,
    newDomainFormattedData,
    setMethodsAndConstants,
    showBulkEdit,
    setShowBulkEdit,
    setAllVariables,
    setbulkEditFilters,
    domainCode,
    setBulkEditDomain,
    setBulkEditDomainInitial,
    bulkEditDomain
  } = contextData;

  const dispatch = useDispatch();

  const { id: mappingRuleVersionID } = useParams();

  const [productDataSources, setDataSources] = useState([]);

  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const [domainRulesData, setDomainRules] = useState([]);
  const navigate = useNavigate();

  const getSourceData = async () => {
    const getTableData = await dispatch(
      GetProductDataSourcesByMappingRuleVersionID(mappingRuleVersionID)
    ).then(unwrapResult);
    if (getTableData.data.success) {
      setDataSources(getTableData.data.ruleStudyLibrary);
    } else {
      dispatch(showBanner({ variant: 'error', message: getTableData?.data?.message }));
    }
  };

  useEffect(() => {
    getSourceData();
  }, [mappingRuleVersionID]);

  useEffect(() => {
    const _domainFilterData = filters[domainCode]?.filter(Boolean);
    if (_domainFilterData) {
      setShowBulkEdit(true);
      const variables = setAllVariables(domainCode, _domainFilterData);
      setBulkEditDomain(variables);
      setbulkEditFilters(_domainFilterData);
    } else if (Object.keys(filters).length > 0) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/domain-rules`);
    }
  }, [filters, filters[domainCode], domainCode]);

  useEffect(() => {
    const _domainFilterData = filters[domainCode]?.filter(Boolean);
    if (_domainFilterData) {
      const allVariables = setAllVariables(domainCode, _domainFilterData);
      if (filters) {
        const vb = Object.entries(allVariables);
        vb.forEach(([key, data]) => {
          if (data.disable && !data.expressions.length) {
            allVariables[key].expressions.push({
              expression: '',
              id: bulkEditDomain[key]?.expressions[0]?.id,
              isValid: false,
              inProgress: false,
              sourceName: bulkEditDomain[key]?.expressions[0]?.sourceName,
              active: bulkEditDomain[key]?.expressions[0]?.active
            });
          }
        });
        setBulkEditDomainInitial({ ...allVariables });
      }
    }
  }, [bulkEditDomain]);

  const getDomainData = () => {
    downloadGlobalLibrary({
      libraryId: rowDataForRuleEditor.targetDataModelID,
      libraryVersion: rowDataForRuleEditor.targetDataModelVersion,
      isAllColumns: true,
      mappingRuleVersionID: mappingRuleVersionID
    })
      .then((res) => {
        if (res.data.success) {
          let { downloadDomainColumns } = res.data;
          let modifiedDomainObj = {};

          downloadDomainColumns.forEach((item) => {
            if (!modifiedDomainObj[item.domainPrefix]) modifiedDomainObj[item.domainPrefix] = [];

            if (
              modifiedDomainObj[item.domainPrefix].findIndex(
                (domain) => domain.variableName === item.variableName
              ) !== -1
            )
              return;

            modifiedDomainObj[item.domainPrefix].push({
              domainName: item.domainPrefix,
              variableName: item.variableName,
              mappedBy: item.mapped_By,
              core: item.core,
              seqForOrder: item.seqForOrder,
              role: item?.role
            });
          });
          /**
           * Extract Key from the object
           * Check whether the new object has the key or not
           * Sort the data set based on the role type
           * sort both suppalData and not suppual data
           * merge them to the data
           */

          let objectKeys = Object.keys(modifiedDomainObj);
          let sortedDomainObj = {};

          for (let key of objectKeys) {
            if (sortedDomainObj[key] === undefined) {
              sortedDomainObj[key] = [];
              let suppqualData = [];
              let notSuppqualData = [];

              modifiedDomainObj[key].forEach((item) => {
                if (item?.role === 'SUPPQUAL') {
                  suppqualData.push({ ...item, isSUPPQUAL: true });
                } else {
                  notSuppqualData.push({ ...item, isSUPPQUAL: false });
                }
              });

              suppqualData = suppqualData.sort(
                (variable1, variable2) => variable1?.seqForOrder - variable2?.seqForOrder
              );
              notSuppqualData = notSuppqualData.sort(
                (variable1, variable2) => variable1?.seqForOrder - variable2?.seqForOrder
              );

              sortedDomainObj = {
                ...sortedDomainObj,
                [key]: [...notSuppqualData, ...suppqualData]
              };
            }
          }

          modifiedDomainObj = sortedDomainObj;
          setDomainFormattedData(modifiedDomainObj);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setNewDomainFormattedData({});
  }, []);

  const generateChildArray = (item, idx) => {
    let childArray = [];
    let lastIndex = idx;
    item.forEach((el, index) => {
      childArray.push({
        nodeId: index + idx,
        nodeText: el.variableName,
        childNodes: [],
        progress: el.mappedBy === 'R' ? 1 : 0,
        required: el.core,
        mappedBy: el.mappedBy,
        isSUPPQUAL: el?.isSUPPQUAL
      });
      lastIndex = index + idx;
    });

    return { childArray, lastIndex };
  };

  useEffect(() => {
    let objIndex = 1;
    let filteredDomainData =
      Object.keys(newDomainFormattedData).length > 0 ? newDomainFormattedData : domainFormattedData;
    let filteredData = Object.keys(filteredDomainData);
    let domainTreeView = [];
    filteredData.forEach((el) => {
      const childObj = generateChildArray(filteredDomainData[el], objIndex + 1);
      const childrenArray = filteredDomainData[el] ? childObj.childArray : [];
      const childCounter = filteredDomainData[el] ? childObj.lastIndex : undefined;
      domainTreeView.push({
        nodeId: objIndex,
        nodeText: el,
        childNodes: childrenArray
      });
      objIndex = childCounter ? childCounter + 1 : objIndex + 1;
    });

    let domainTreeData = JSON.parse(JSON.stringify(domainTreeView));
    let filtersList = Object.keys(filters);
    filtersList.forEach((eachFilter) => {
      domainTreeData.forEach((domain) => {
        if (domain.nodeText === eachFilter) {
          domain.childNodes.forEach((childNode) => {
            if (childNode.progress === 1 && childNode.mappedBy === 'R') {
              return;
            } else {
              let count = 0;
              filters &&
                filters[eachFilter].forEach((filter) => {
                  if (
                    filter?.variables[childNode.nodeText] &&
                    (filter.variables[childNode.nodeText].expressions.length > 0 ||
                      filter.variables[childNode.nodeText].noMap)
                  ) {
                    count = count + 1;
                  }
                });
              if (count !== 0 && count === filters[eachFilter].length) {
                childNode.progress = 1;
              } else if (count !== 0 && filters[eachFilter].length !== count) {
                childNode.progress = 0.5;
              } else {
                childNode.progress = 0;
              }
            }
          });
        }
      });
    });

    setDomainData(domainTreeData);
  }, [domainFormattedData, newDomainFormattedData, filters]);

  const getRuleMetadataFormItems = async () => {
    try {
      const libraryTypes = [];
      const dataSources = [];
      for (let library of productDataSources) {
        libraryTypes.includes(library.libraryType) ||
          (library.isMappingRuleConfigured &&
            libraryTypes.push(library.libraryType) &&
            dataSources.push(library.source));
      }

      const payload = {
        mappingRuleVersionID,
        forms: []
      };
      libraryTypes.includes('CDISC ODM') && payload.forms.push(1);
      libraryTypes.includes(CDR_TABULAR) && payload.forms.push(2);
      libraryTypes.includes('Q2LAB') && payload.forms.push(3);
      libraryTypes.includes('QECG') && payload.forms.push(4);

      const { data: { formItemsDict } = { formItemsDict: {} } } = await dispatch(
        GetRuleMetadataFormItems(payload)
      ).then(unwrapResult);

      const ruleMetaDataFormItems = {};
      const PSEUDO_FORMS = 'PSeudo Forms';

      if (formItemsDict['CDISC ODM'])
        ruleMetaDataFormItems['CDISC ODM'] = formItemsDict['CDISC ODM'];
      if (formItemsDict['ECG']) ruleMetaDataFormItems['QECG'] = formItemsDict['ECG'];
      if (formItemsDict['LAB']) ruleMetaDataFormItems['Q2LAB'] = formItemsDict['LAB'];
      if (formItemsDict[CDR_TABULAR])
        ruleMetaDataFormItems[CDR_TABULAR] = formItemsDict[CDR_TABULAR];

      if (formItemsDict[PSEUDO_FORMS])
        ruleMetaDataFormItems[PSEUDO_FORMS] = formItemsDict[PSEUDO_FORMS];

      setFormMetaData(ruleMetaDataFormItems);
    } catch (error) {
      console.log('Failed to retrive FormItems MetaData :: ', error);
    }
  };

  const getVariableMappingRules = async (specificDomain) => {
    let mappedDomains = Object.keys(domainFormattedData);
    let payload = {
      mappingRuleVersionID,
      mappedDomains
    };
    let domainRules = {};
    if (specificDomain) {
      payload.mappedDomains = [specificDomain];
      domainRules = { ...filters };
      delete domainRules[specificDomain];
    }
    const MappingRules =
      mappedDomains.length && (await dispatch(GetVariableMappingRules(payload)).then(unwrapResult));
    if (MappingRules && MappingRules.data && MappingRules.data.success) {
      let rules = MappingRules.data.itemMappingRules;

      setDomainRules(rules);
      rules.forEach((expression) => {
        if (!domainRules[expression.domainCode]) {
          domainRules[expression.domainCode] = [];
        }

        if (domainRules[expression.domainCode][expression.rowSeq - 1]) {
          if (expression.variableName === 'Q_ROW_CONDITION') {
            domainRules[expression.domainCode][expression.rowSeq - 1].expressions.push({
              id: expression.variableRuleId || uuidv4(),
              expression: expression.expression,
              isValid: true,
              inProgress: false,
              sourceName: expression.sourceName
            });
          } else if (expression.variableName === 'Q_ROW_CONDITION_OP') {
            domainRules[expression.domainCode][expression.rowSeq - 1].operator =
              expression.expression;
            domainRules[expression.domainCode][expression.rowSeq - 1].sourceName =
              expression.sourceName;
            domainRules[expression.domainCode][expression.rowSeq - 1].active =
              expression.isMapRowActive;
            domainRules[expression.domainCode][expression.rowSeq - 1].defaultForm =
              expression.defaultForm;
            domainRules[expression.domainCode][expression.rowSeq - 1].filterName =
              expression.rowName;
            domainRules[expression.domainCode][expression.rowSeq - 1].id =
              expression.variableRuleId;
            domainRules[expression.domainCode][expression.rowSeq - 1].rowSeq = expression.rowSeq;
          } else {
            if (
              domainRules[expression.domainCode][expression.rowSeq - 1].variables[
                expression.variableName
              ]
            ) {
              domainRules[expression.domainCode][expression.rowSeq - 1].variables[
                expression.variableName
              ].expressions.push({
                id: expression.variableRuleId || uuidv4(),
                expression: expression.expression,
                isValid: true,
                inProgress: false,
                sourceName: expression.sourceName
              });
            } else {
              domainRules[expression.domainCode][expression.rowSeq - 1].variables[
                expression.variableName
              ] = {
                noMap: expression.expression === '"$NOMAP"',
                expressions:
                  expression.expression === '"$NOMAP"'
                    ? []
                    : [
                        {
                          id: expression.variableRuleId || uuidv4(),
                          expression: expression.expression,
                          isValid: true,
                          inProgress: false,
                          sourceName: expression.sourceName
                        }
                      ]
              };
            }
          }
        } else {
          if (expression.variableName === 'Q_ROW_CONDITION') {
            domainRules[expression.domainCode][expression.rowSeq - 1] = {
              id: expression.variableRuleId || uuidv4(),
              expressions: [
                {
                  id: expression.variableRuleId || uuidv4(),
                  expression: expression.expression,
                  isValid: true,
                  inProgress: false,
                  sourceName: expression.sourceName
                }
              ],
              filterName: expression.rowName,
              operator: '',
              active: expression.isMapRowActive,
              inProgress: false,
              defaultForm: expression.defaultForm,
              variables: {}
            };
          } else if (expression.variableName === 'Q_ROW_CONDITION_OP') {
            domainRules[expression.domainCode][expression.rowSeq - 1] = {
              id: expression.variableRuleId || uuidv4(),
              expressions: [],
              filterName: expression.rowName,
              operator: expression.expression,
              active: expression.isMapRowActive,
              inProgress: false,
              defaultForm: expression.defaultForm,
              variables: {},
              sourceName: expression.sourceName,
              rowSeq: expression.rowSeq
            };
          } else {
            domainRules[expression.domainCode][expression.rowSeq - 1] = {
              id: expression.variableRuleId || uuidv4(),
              expressions: [],
              filterName: expression.rowName,
              operator: '',
              inProgress: false,
              defaultForm: expression.defaultForm,
              variables: {
                [expression.variableName]: {
                  noMap: expression.expression === '"$NOMAP"',
                  expressions:
                    expression.expression === '"$NOMAP"'
                      ? []
                      : [
                          {
                            id: expression.variableRuleId || uuidv4(),
                            expression: expression.expression,
                            isValid: true,
                            inProgress: false,
                            sourceName: expression.sourceName
                          }
                        ]
                }
              }
            };
          }
        }
      });
    }

    setFilters(domainRules);
  };

  useEffect(() => {
    let copyOfDomainData = JSON.parse(JSON.stringify(domainData));
    if (copyOfDomainData.length && domainRulesData.length) {
      domainRulesData.forEach((variableRule) => {
        copyOfDomainData.forEach((domain) => {
          if (domain.nodeText === variableRule.domainCode) {
            domain.childNodes.forEach((childNode) => {
              if (
                childNode.nodeText === variableRule.variableName &&
                variableRule.expression !== ''
              ) {
                childNode.progress = 1;
              }
            });
          }
        });
      });
    }
  }, [domainRulesData]);

  const getMethodsAndConstants = async () => {
    const methodsAndConstantsResponse = await dispatch(GetRuleExpressionMethodsAndConstants()).then(
      unwrapResult
    );
    if (methodsAndConstantsResponse?.data?.success) {
      let methodsAndConstants = [];
      methodsAndConstantsResponse.data.listMethodsConstants.forEach((item) => {
        methodsAndConstants.push(item.displayName);
      });
      setMethodsAndConstants(methodsAndConstants);
    } else {
      dispatch(showBanner({ variant: 'error', message: methodsAndConstantsResponse.data.message }));
    }
  };

  useEffect(() => {
    getDomainData();
    getMethodsAndConstants();
  }, []);

  useEffect(() => {
    getRuleMetadataFormItems();
  }, [productDataSources]);

  useEffect(() => {
    getVariableMappingRules();
  }, [domainFormattedData]);

  return showBulkEdit ? (
    <BulkEditScreen getVariableMappingRules={getVariableMappingRules} />
  ) : (
    <div
      style={{
        display: 'flex',
        overflow: 'hidden',
        width: '100vw',
        height: 'calc(100vh - 113px)',
        position: 'relative'
      }}>
      <Loader isInner />
    </div>
  );
};

export default BulkEditPage;
