import { createSlice } from '@reduxjs/toolkit';

export const CopyDataProductSlice = createSlice({
  name: 'CopyDataProduct',
  initialState: {
    mappingSpecVersionId : '',
    refreshDataProductsForClone : false
  },
  reducers: {
    setMappingSpecVersionId(state) {
        state.mappingSpecVersionId = '';
    },
    setRefreshDataProductsForClone(state) {
        state.refreshDataProductsForClone = '';
    }
  }
});

export const { setMappingSpecVersionId, setRefreshDataProductsForClone } = CopyDataProductSlice.actions;
