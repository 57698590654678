/*eslint-disable */
import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { GetMasteringType, SaveMasteringType } from 'Redux/Service/MasteringRuleService';
import { MasteringRulesContext } from '../../MasteringRulesProvider';
import Radio from 'apollo-react/components/Radio';

import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { showBanner } from 'Redux/Slice/BannerSlice';
export const GeneralSettings = ({ mappingRuleVersionID }) => {
  const [saveFlag, setSaveFlag] = useState(false);
  const dispatch = useDispatch();
  const context = useContext(MasteringRulesContext);
  const { setMasteringType, masteringType } = context;

  const getMasteringTypeData = async () => {
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID
    };
    const MasteringType = await dispatch(GetMasteringType(payload)).then(unwrapResult);
    if (MasteringType && MasteringType.data && MasteringType.data.success) {
      let data = MasteringType.data.masteringType;
      if (data !== '') {
        setSaveFlag(false);
        setMasteringType(data);
      } else {
        setSaveFlag(true);
      }
    }
  };

  useEffect(() => {
    getMasteringTypeData();
  }, []);

  useEffect(() => {
    if (saveFlag) {
      saveProgressOfEachNode(null, masteringType);
    }
  }, [saveFlag, masteringType]);

  const saveProgressOfEachNode = async (e, value) => {
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID,
      masteringType: e === null ? value : e.target.value
    };
    const saveMasteringType = await dispatch(SaveMasteringType(payload)).then(unwrapResult);
    if (saveMasteringType && saveMasteringType.data.success) {
      setMasteringType(e === null ? value : e.target.value);
      getMasteringTypeData();
      return;
    }
    //   else {
    //     dispatch(showBanner({ variant: 'error', message: saveMasteringType.data.message }));
    //     return;
    // }
  };

  return (
    <div>
      <Grid container spacing={0} style={{ marginTop: '60px' }}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            style={{ color: '#595959', fontSize: '16px', marginBottom: '16px' }}>
            Subject Mastering Type
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Radio
            value="Full"
            label="Full"
            checked={masteringType === 'Full'}
            onChange={(e) => saveProgressOfEachNode(e)}
          />
        </Grid>
        <Grid item xs={2}>
          <Radio
            value="Partial"
            label="Partial"
            checked={masteringType === 'Partial'}
            onChange={(e) => saveProgressOfEachNode(e)}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </div>
  );
};

export default GeneralSettings;