/*eslint-disable */
import Grid from 'apollo-react/components/Grid';
import { makeStyles } from '@mui/styles';
import { neutral1 } from 'apollo-react/colors';
import Panel from 'apollo-react/components/Panel';
import { BannerExample } from 'Components/Banner/Banner';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import './AddSQLQuery.css';
import { useLocation } from 'react-router-dom';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import AddNewDataSet from 'Pages/DataStandardLibrary/DataStandardLibraryMain/Components/AddNewDataSet';

const useStyles = ([projectHeaderVisible, panelOpen]) =>
  makeStyles({
    container: {
      width: '100%',
      marginTop: projectHeaderVisible ? '113px' : '57px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f5f6fa',
      boxSizing: 'border-box',
      justifyContent: 'flex-start'
    },
    root: {
      backgroundColor: '#f5f6fa'
    },
    panelRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: `calc(100vh - ${projectHeaderVisible ? '113px' : '57px'})`
    },
    panelsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',

      '&> div:nth-child(1)': {
        height: `calc(100vh - ${projectHeaderVisible ? '113px' : '57px'})`,
        position: 'sticky',
        top: projectHeaderVisible ? '113px' : '57px',
        zIndex: 1,
        overflowY: 'clip'
      },

      '&> div:nth-child(2)': {
        width: `calc(100% - ${panelOpen ? '354px' : '26px'}) !important`,
        backgroundColor: '#f5f6fa'
      }
    }
  });

const AddSQLQueryLayout = () => {
  const dispatch = useDispatch();
  const { protocol, openPanelDSL, protocols } = useSelector((state) => state.StudyLibraryData);
  const location = useLocation();
  let path = location.pathname;
  const ADD_STUDY_LIBRARY_ADD_NEW_DATASET = '/data-standard-library/add-study-library/add-new-dataset';
  const projectHeaderDisplayed = useMemo(() => {
    return Boolean(protocol && Object.keys(protocol).length !== 0);
  }, [protocol]);

  const classes = useStyles([projectHeaderDisplayed, openPanelDSL])();

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title:
            location.pathname === ADD_STUDY_LIBRARY_ADD_NEW_DATASET
              ? 'Add Study Library'
              : 'Edit Study Library',
          path:
            location.pathname === ADD_STUDY_LIBRARY_ADD_NEW_DATASET
              ? '/data-standard-library/add-study-library'
              : '/data-standard-library/edit-study-library'
        },
        {
          title: 'Add New Dataset',
          path:
            location.pathname === ADD_STUDY_LIBRARY_ADD_NEW_DATASET
              ? ADD_STUDY_LIBRARY_ADD_NEW_DATASET
              : '/data-standard-library/edit-study-library/add-new-dataset'
        }
      ])
    );
  }, []);

  return (
    <>
      <Grid container className={classes.root}>
        <Header />
        {projectHeaderDisplayed && <ProjectStudyHeader />}
        <BannerExample />

        <div className={`${classes.container}`}>
          <Breadcrumb addStudy={true} />
          <div className="standard-library1" style={{ width: '100%' }}>
            <div className="standard-container">
              <AddNewDataSet />
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default AddSQLQueryLayout;
