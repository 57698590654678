import { createSlice } from '@reduxjs/toolkit';

export const DomainPreviewSlice = createSlice({
  name: 'DomainPreview',
  initialState: {
    subjectsData: [],
    selectedSubjects: [],
    selectedDomainSourceData: {},
    cacheFlag: false,
    selectedVLCRules: { dataset: '', vlcRules: [] },
  },
  reducers: {
    setSubjectsData(state, { payload }) {
      state.subjectsData = payload;
    },
    setSelectedSubjects(state, { payload }) {
      state.selectedSubjects = payload;
    },
    setselectedDomainSourceData(state, { payload }) {
      state.selectedDomainSourceData = payload;
    },
    setCacheFlag(state, { payload }) {
      state.cacheFlag = payload;
    },
    setSelectedVLCRules(state, { payload }) {
      state.selectedVLCRules = payload;
    }
  }
});

export const { setSubjectsData, setSelectedSubjects, setselectedDomainSourceData, setCacheFlag, setSelectedVLCRules } =
  DomainPreviewSlice.actions;
