import { createTwoWayObject } from 'Utils';

// import {
//     compareNumbers,
//     numberSearchFilter,
//     compareStrings,
//     createStringSearchFilter
// } from 'apollo-react/components/Table';

const trialArmsColumnNames = createTwoWayObject({
    "ARMCD": {
        key: 'armcd',
        type: 'STRING',
        isMandatory: () => true
    },
    "ARM": {
        key: 'arm',
        type: 'STRING',
        isMandatory: () => true
    },
    "TAETORD": {
        key: 'taetord',
        type: 'NUMBER',
        isMandatory: () => true
    },
    "ETCD": {
        key: 'etcd',
        type: 'STRING',
        isMandatory: () => true
    },
    "ELEMENT": {
        key: 'taelement',
        type: 'STRING',
        isMandatory: () => false
    },
    "TABRANCH": {
        key: 'tabranch',
        type: 'STRING',
        isMandatory: () => false
    },
    "TATRANS": {
        key: 'tatrans',
        type: 'STRING',
        isMandatory: () => false
    },
    "EPOCH": {
        key: 'epoch',
        type: 'STRING',
        isMandatory: () => true
    }
});

export { 
    trialArmsColumnNames
}