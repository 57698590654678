/* eslint-disable */
import { getEdcOdmVisits, getQLAB, getQECG, getCDR } from 'Redux/Service/PreviewService';
import { ErrorConstants } from 'Validators/ErrorConstants';
const {
  qecgError,
  qlabError,
  edcError,
  qecgBlankError,
  qlabBlankError,
  edcBlankError,
  cdrBlankError,
  mandatoryErrorCDR,
  mandatoryErrorEDC,
  mandatoryErrorEDCorCDR
} = ErrorConstants;

const getVisitsData = async (libraryID, version) => {
  return getEdcOdmVisits({ studyLibraryID: libraryID, studyLibraryVersion: version })
    .then((res) => res.json())
    .then((response) => {
      if (response.success) {
        return response.edcOdmVisits;
      } else {
        return [];
      }
    });
};

const getLabData = async (libraryID, version) => {
  return getQLAB({ studyLibraryID: libraryID, studyLibraryVersion: version })
    .then((res) => res.json())
    .then((response) => {
      if (response.success) {
        return response.qlapVisits;
      } else {
        return [];
      }
    });
};

const getQECGData = async (libraryID, version) => {
  return getQECG({ studyLibraryID: libraryID, studyLibraryVersion: version })
    .then((res) => res.json())
    .then((response) => {
      if (response.success) {
        return response.ecgVisits;
      } else {
        return [];
      }
    });
};

const getCDRTabularData = async (libraryID, version) => {
  return getCDR({ studyLibraryID: libraryID, studyLibraryVersion: version })
    .then((res) => res.json())
    .then((response) => {
      if (response.success) {
        return response.cdrDataSetColumns;
      } else {
        return [];
      }
    });
};

const getEventOID = (data) => {
  let eventName = [];
  let eventOid = [];
  data.forEach((item) => {
    eventName.push(item.eventName);
    eventOid.push(item.eventOid);
  });
  return { eventOid, eventName };
};

const getEventTypeAndCode = (data) => {
  const eventTypeAndCode = [];
  const visitName = [];
  data.forEach((item) => {
    eventTypeAndCode.push(item.visitEventCode);
    visitName.push(item.visitName);
  });
  return { eventTypeAndCode, visitName };
};

const getVisitIdentifier = (data) => {
  const visitIdentifier = [];
  const trlVstNm = [];
  data.forEach((item) => {
    visitIdentifier.push(item.trlVstId);
    trlVstNm.push(item.trlVstNm);
  });
  return { visitIdentifier, trlVstNm };
};

const getDataFlowName = (data) => {
  const dataFlowName = data.map((item) => {
    return item.dataFlowName;
  });
  return dataFlowName;
};

export const referenceDataService = async (selectedDataSources) => {
  let eventOidData = [];
  let eventTypeAndCode = [];
  let qecgVisitIdentifier = [];
  let cdrSourceIdentifier = [];
  let visitName = [];
  let dataSourcesConfigured = {};

  if (selectedDataSources && selectedDataSources.length > 0) {
    const uniqueSelectedDataSources = [
      ...[...new Map(selectedDataSources.map((item) => [item['libraryType'], item])).values()]
    ];
    for (let record of uniqueSelectedDataSources) {
      const { libraryID, libraryType, libraryVersion: version } = record;
      if (libraryType === 'CDISC ODM') {
        const visitData = await getVisitsData(libraryID, version);
        let data = getEventOID(visitData);
        eventOidData = data.eventOid;
        visitName.push(...data.eventName);
      }
      if (libraryType === 'CDR Tabular') {
        cdrSourceIdentifier = await getCDRTabularData(libraryID, version);
        visitName.push(...getDataFlowName(cdrSourceIdentifier));
      }
      if (libraryType === 'Q2LAB') {
        const labData = await getLabData(libraryID, version);
        let data = getEventTypeAndCode(labData);
        eventTypeAndCode = data.eventTypeAndCode;
        visitName.push(...data.visitName);
      }
      if (libraryType === 'QECG') {
        const qecgData = await getQECGData(libraryID, version);
        let data = getVisitIdentifier(qecgData);
        qecgVisitIdentifier = data.qecgVisitIdentifier;
        visitName.push(...data.trlVstNm);
      }
      dataSourcesConfigured[libraryType] = true;
    }
  }

  const isValidEDC = (value) => {
    let obj = {};
    if (eventOidData && eventOidData.length > 0) {
      const EDCdata = value.split('+');
      EDCdata.forEach((item) => {
        if (item && !eventOidData.includes(item.trim())) {
          obj.edc = { edc: edcError };
        }
      });
    }
    return obj;
  };

  const isValidLab = (value) => {
    let obj = {};
    if (eventTypeAndCode && eventTypeAndCode.length > 0) {
      const items = value.split('+');
      items.forEach((item) => {
        if (item && !eventTypeAndCode.includes(item.trim())) {
          obj.qlab = { qlab: qlabError };
        }
      });
    }
    return obj;
  };

  const isValidQecg = (value) => {
    let obj = {};
    if (qecgVisitIdentifier && qecgVisitIdentifier.length > 0) {
      const items = value.split('+');
      items.forEach((item) => {
        if (item && !qecgVisitIdentifier.includes(item.trim())) {
          obj.qecg = { qecg: qecgError };
        }
      });
    }
    return obj;
  };

  const checkIsEDCSelected = (edcValue, qlabValue, qecgValue, cdrValue) => {
    let obj = {};

    if (eventOidData && eventOidData.length <= 0) {
      if (edcValue) {
        obj.edc = { edc: edcBlankError };
      }
    }
    if (eventTypeAndCode && eventTypeAndCode.length <= 0) {
      if (qlabValue) {
        obj.qlab = { qlab: qlabBlankError };
      }
    }
    if (qecgVisitIdentifier && qecgVisitIdentifier.length <= 0) {
      if (qecgValue) {
        obj.qecg = { qecg: qecgBlankError };
      }
    }
    if (cdrSourceIdentifier && cdrSourceIdentifier.length <= 0) {
      if (cdrValue) {
        obj.tabular = { tabular: cdrBlankError };
      }
    }
    return obj;
  };

  const checkDefaultFormForEDCandCDR = (edcdefaultform, cdrdefaultform) => {
    let obj = {};
    if (
      dataSourcesConfigured['CDISC ODM'] &&
      !edcdefaultform &&
      !dataSourcesConfigured['CDR Tabular']
    ) {
      //When EDC source is configured for data product but no value provided in edcdefaultform column
      obj.edcdefaultform = { edcdefaultform: mandatoryErrorEDC };
    } else if (
      dataSourcesConfigured['CDR Tabular'] &&
      !cdrdefaultform &&
      !dataSourcesConfigured['CDISC ODM']
    ) {
      //When CDR source is configured for data product but no value provided in cdrdefaultform column
      obj.cdrdefaultform = { cdrdefaultform: mandatoryErrorCDR };
    } else if (
      dataSourcesConfigured['CDISC ODM'] &&
      dataSourcesConfigured['CDR Tabular'] &&
      !edcdefaultform &&
      !cdrdefaultform
    ) {
      //When EDC & CDR sources are configured for data product but no value provided in either edcdefaultform nor cdrdefaultform columns
      obj.edcdefaultform = { edcdefaultform: mandatoryErrorEDCorCDR };
      obj.cdrdefaultform = { cdrdefaultform: mandatoryErrorEDCorCDR };
    }
    return obj;
  };

  const isValidVisitName = (args) => {
    let result = false;
    if (visitName && visitName.length > 0) {
      args = args.trim().substring(1, args.trim().length - 1);
      result = visitName.includes(args);
      if (!result) {
        let obj = isValidEDC(args);
        if (Object.keys(obj).length === 0) {
          result = true;
        }
      }
    }
    return result;
  };

  return {
    isValidEDC: isValidEDC,
    isValidLab: isValidLab,
    isValidQecg: isValidQecg,
    isValidVisitName: isValidVisitName,
    checkIsEDCSelected: checkIsEDCSelected,
    checkDefaultFormForEDCandCDR: checkDefaultFormForEDCandCDR
  };
};

export default { referenceDataService };
