/*eslint-disable*/
import React, { useState } from 'react';
import StatusNegative from 'apollo-react-icons/StatusNegative';
import IconButton from 'apollo-react/components/IconButton';
import InvalidRulesetComp from './InvalidRulesetComp';
import Tooltip from 'apollo-react/components/Tooltip';
import Button from 'apollo-react/components/Button';
const RulesetErrorDisplay = (props) => {
    const { ruleset, errorInfo } = props.row;
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            {ruleset && errorInfo ? <>
                <Tooltip interactive={true} placement={"top"} title={<><StatusNegative size={"small"} style={{ color: "#E82B2B", height: '12px' }} />{errorInfo.length} error{`${errorInfo.length > 1 ? 's' : ''}`} found</>} subtitle={<Button onClick={() => setOpenModal(true)} size={"small"}>View error{`${errorInfo.length > 1 ? 's' : ''}`}</Button>} variant="light">
                    <IconButton style={{
                        width: '13.33px',
                        height: '13.33px',
                        color: '#E82B2B',
                        marginRight: '10px'
                    }} size="small"
                        onClick={() => setOpenModal(true)}
                    >
                        <StatusNegative />
                    </IconButton>
                </Tooltip>
                {ruleset}
            </> : ruleset || 'N/A'
            }

            <InvalidRulesetComp {...props.row} openModal={openModal} setOpenModal={setOpenModal} />

        </>
    )
};

export default RulesetErrorDisplay
