import Grid from 'apollo-react/components/Grid';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import Typography from 'apollo-react/components/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showProjectHeaderFromDataProducts } from 'Redux/Slice/DataProductLibrarySlice';
import { getProtocol } from 'Redux/Slice/StudyLibrarySlice';
const StandardLibraryHeader = (props) => {
  const navigate = useNavigate();
  const { tabValue } = props;
  const [tab, setTab] = useState(tabValue);
  const dispatch = useDispatch();

  const handleChangeTabs = (event, value) => {
    if (value === 0) {
      dispatch(showProjectHeaderFromDataProducts(false));
      dispatch(getProtocol({}));
      navigate('/data-standard-library/study-library');
    } else if (value === 1) {
      navigate('/data-standard-library/global-library');
    }
    setTab(value);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} style={{ padding: '0 24px' }}>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              color: '#000000',
              fontFamily: 'Proxima Nova',
              fontSize: '20px',
              fontWeight: 600,
              letterSpacing: 0
            }}>
            Libraries
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={tab} className="sm-tabs" onChange={handleChangeTabs}>
            <Tab label="Study" className="sm-tab" />
            <Tab label="Global" className="sm-tab" />
          </Tabs>
        </Grid>
      </Grid>
    </div>
  );
};

export default StandardLibraryHeader;
