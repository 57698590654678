import { getTrimmedValue } from 'Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Components/FormsheetUtils';
import { ErrorConstants } from './ErrorConstants';

const {
  flagValueError,
  mandatoryError,
  domainError,
  charLengthError,
  formNameError,
  formRepeatError
} = ErrorConstants;
const allowedValues = ['Yes', 'No'];

const checkDomainPrefixValid = (record, validDomains) => {
  let obj = {};

  if (!validDomains.includes(getTrimmedValue(record.defaultDomain))) {
    obj.defaultDomain = { domain: domainError };
  }
  let _otherDomains = getTrimmedValue(record.otherDomains);
  if (_otherDomains) {
    const inValidDomains = _otherDomains
      .split(',')
      .filter((domain) => !validDomains.includes(domain));
    if (inValidDomains.length > 0) {
      obj.otherDomains = { domain: domainError };
    }
  }
  return obj;
};

const checkFlagValue = (record) => {
  let _repeatFlag = getTrimmedValue(record.repeatFlag);
  let _isEpro = getTrimmedValue(record.isEpro);
  let obj = {};
  if (_repeatFlag && !allowedValues.includes(_repeatFlag)) {
    obj.repeatFlag = { flag: flagValueError };
  }
  if (_isEpro && !allowedValues.includes(_isEpro)) {
    obj.isEpro = { flag: flagValueError };
  }

  return obj;
};

const checkMandatory = (record) => {
  const { defaultDomain } = record;
  let obj = {};
  if (!getTrimmedValue(defaultDomain)) {
    obj.defaultDomain = { mandatory: mandatoryError };
  }
  return obj;
};

const checkTextCharLimit = (record) => {
  const { comments } = record;
  let obj = {};
  if (getTrimmedValue(comments)?.length > 400) {
    obj.comments = { charLength: charLengthError };
  }
  return obj;
};

const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

export const formSheetValidator = (records, validForms, validDomains) => {
  let errorColumnCount = 0;
  let errorRowsId = [];
  let error = {};

  let currentFormObj = {};
  let inValidFormNames = [];
  let inValidFormRepeats = [];
  let deletedFormNames = [];
  const formSheetObj = validForms.reduce((acc, item) => {
    acc[item.formName] = { ...item };
    return acc;
  }, {});

  records.forEach((record) => {
    let obj = {};
    let _formName = getTrimmedValue(record.formName);
    let _formRepeatFlag = getTrimmedValue(record.formRepeatFlag);

    //check isValid FormName - No data/Added or modified/isDuplicate FormName
    if (!_formName || !formSheetObj[_formName] || currentFormObj[_formName]) {
      obj.formName = { inValidFormName: formNameError };
      inValidFormNames.push(_formName);
    } else {
      currentFormObj[_formName] = {
        formName: _formName,
        formRepeatFlag: _formRepeatFlag
      };

      //check isValid FormRepeat - No data/isValid Value/isMissMatch
      if (!_formRepeatFlag || !allowedValues.includes(_formRepeatFlag)) {
        obj.formRepeatFlag = { flag: flagValueError };
        inValidFormRepeats.push(_formName);
      } else if (_formRepeatFlag !== formSheetObj[_formName].formRepeatFlag) {
        obj.formRepeatFlag = { inValidFormRepeat: formRepeatError };
        inValidFormRepeats.push(_formName);
      }
    }

    const mandatory = checkMandatory(record);
    const validDefaultDomain = checkDomainPrefixValid(record, validDomains);
    const flagValue = checkFlagValue(record);
    const textCharLimit = checkTextCharLimit(record);

    mergeObject(obj, mandatory);
    mergeObject(obj, validDefaultDomain);
    mergeObject(obj, flagValue);
    mergeObject(obj, textCharLimit);
    if (obj && Object.keys(obj).length > 0) {
      errorRowsId.push(record.id);
      errorColumnCount = errorColumnCount + Object.keys(obj).length;
    }
    error[record.id] = obj;
  });

  //check isDeleted
  Object.keys(formSheetObj).forEach((formName) => {
    if (!currentFormObj[formName]) {
      deletedFormNames.push(formName);
    }
  });

  const isColumnValid = (uuid, columnName) => {
    let columnError = '';
    let row = error[uuid] ? error[uuid] : {};
    if (row && Object.keys(row).length > 0) {
      columnError = columnName && row[columnName] ? row[columnName] : '';
    }
    return columnError;
  };

  const isFormMissMatch = () => {
    return (
      deletedFormNames.length > 0 || inValidFormNames.length > 0 || inValidFormRepeats.length > 0
    );
  };

  const getFormMissMatchError = () => {
    let errorStr = '';
    if (isFormMissMatch()) {
      errorStr =
        '$errorColumns Mismatch between the Source and Uploaded data. Re-upload with the valid $errorColumns of the Source';
      let errorColumns = [];

      if (deletedFormNames.length > 0 || inValidFormNames.length > 0) {
        errorColumns.push('Form Name');
      }
      if (inValidFormRepeats.length > 0) {
        errorColumns.push('Form Repeat');
      }
      errorStr = errorStr.replaceAll(
        '$errorColumns',
        errorColumns.length === 2 ? `${errorColumns[0]} and ${errorColumns[1]}` : errorColumns[0]
      );

      if (deletedFormNames.length > 0) {
        errorStr = `${errorStr} \nMissing Forms: ${deletedFormNames.join(',')}`;
      }
    }
    return errorStr;
  };

  const getInvalidFormNames =() =>{
    return inValidFormNames;
  }
  const getInvalidFormRepeats = () => {
    return inValidFormRepeats;
  };
  const getDeletedFormNames = ()=>{
    return deletedFormNames;
  }
  const getErrorRowId = () => {
    return errorRowsId;
  };
  const getColumnErrorCount = () => {
    return errorColumnCount;
  };

  return {
    isColumnValid: isColumnValid,
    getErrorRowId: getErrorRowId,
    getErrorCount: getColumnErrorCount,
    isFormMissMatch: isFormMissMatch,
    getFormMissMatchError:getFormMissMatchError,
    getInvalidFormNames:getInvalidFormNames,
    getInvalidFormRepeats:getInvalidFormRepeats,
    getDeletedFormNames: getDeletedFormNames,
  };
};

export default { formSheetValidator };
