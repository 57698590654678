import React, { useEffect } from 'react';

import Banner from 'apollo-react/components/Banner';
import { useSelector, useDispatch } from 'react-redux';
import { closeBanner, getUrl, clearPropagation } from '../../Redux/Slice/BannerSlice';
import { useLocation } from 'react-router-dom';

export const BannerExample = () => {
  const { variant, message, open, url, propagate } = useSelector((state) => state.Banner);
  const dispatch = useDispatch();
  const location = useLocation();
  let path = location.pathname;

  const snackBarPos = {
    position: 'fixed',
    zIndex: '1001',
    top: '56px',
    minHeight: 'auto'
  };

  let timeout;

  const handleClose = () => {
    dispatch(closeBanner());
  };

  useEffect(() => {
    if (open) dispatch(getUrl(path));
    if (open && variant !== 'error') {
      timeout = setTimeout(() => handleClose(), 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  useEffect(() => {
    if (propagate) dispatch(clearPropagation());
    else if (path !== url) dispatch(closeBanner());
  }, [path]);

  return (
    <>
      <Banner
        variant={variant}
        open={open}
        onClose={handleClose}
        message={message}
        style={snackBarPos}
      />
    </>
  );
};
