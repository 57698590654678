import React from 'react';
import { Dashboard } from './Component/Dashboard';
import {useLocation} from 'react-router-dom';
export const DashboardPage = () => {

  const location=useLocation();
  return (
    <React.Fragment>
      <Dashboard pathName={location.pathname}/>
    </React.Fragment>
  );
};
