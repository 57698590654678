/* eslint-disable */
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import { neutral7, neutral8 } from 'apollo-react/colors';
import ApolloAutocomplete from 'apollo-react/components/Autocomplete';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import { compareStrings } from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import styled from 'styled-components';
import Warning from 'Images/Warning.svg';
import Box from 'apollo-react/components/Box';

/**
 * ExpandCell - Cell component to map of datasest of source & target
 * @prop {object} row
 * @prop {object} column
 */
const ExpandCell = ({ row: { id, handleExpandRow, expanded, libraryType } }) => {
  if (libraryType !== 'CDR Tabular') return <></>;
  return (
    <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
      <IconButton id="expand" size="small" onClick={() => handleExpandRow(id)}>
        <ChevronRight style={{ transform: expanded && 'rotate(90deg)' }} />
      </IconButton>
    </Tooltip>
  );
};

const Autocomplete = styled(ApolloAutocomplete)(({ sx = {} }) => ({
  margin: '0px',
  '& .MuiInput-formControl': {
    marginTop: '0px'
  },
  ...sx
}));

/**
 * NoMatchingLibrary - Message component when there is no matching library type if found
 */
const NoMatchingLibrary = () => {
  return (
    <Box
      component={'span'}
      sx={{
        '&:before': {
          verticalAlign: '-20%',
          margin: '0.5rem 0.5rem 0.5rem 0rem',
          content: `url(${Warning})`
        },
        fontSize: '14px',
        color: neutral8
      }}>
      No matching library found
    </Box>
  );
};

/**
 * TargetStudyLibrary - Cell component
 * @prop {object} row
 * @prop {object} column
 */
const TargetStudyLibrary = ({ row }) => {
  const {
    id,
    libraryID,
    libraryType,
    targetStudyLibrary,
    targetStudyLibraries,
    handleTargetStudyLibraryChange
  } = row;

  if (!['CDR Tabular', 'CDISC ODM'].includes(libraryType) || !targetStudyLibraries?.length) {
    if (!targetStudyLibrary?.library) return <NoMatchingLibrary />;
    else return targetStudyLibrary?.library?.libraryName;
  }

  return (
    <Autocomplete
      id="targetStudyLibrary"
      data-testid="targetStudyLibrary-input"
      size="small"
      value={targetStudyLibrary || null}
      onChange={(e, value) => handleTargetStudyLibraryChange(id, value)}
      placeholder={'Select target study library'}
      noWrap
      fullWidth
      sx={{ maxWidth: '250px' }}
      source={targetStudyLibraries.map((option) => ({
        label:
          option.libraryType === 'CDR Tabular'
            ? `${option.libraryName} (${option.isCDRTestFlow ? 'TEST' : 'PROD'})`
            : option.libraryName,
        library: option
      }))}></Autocomplete>
  );
};

export const columns = [
  {
    accessor: 'expand',
    customCell: ExpandCell,
    width: 57
  },
  {
    header: 'Source Library Type',
    accessor: 'libraryTypeDisplayName',
    sortFunction: compareStrings
  },
  {
    header: 'Source Study Library',
    accessor: 'libraryName',
    sortFunction: compareStrings
  },
  {
    header: 'Target Study Library',
    accessor: 'targetStudyLibrary',
    customCell: TargetStudyLibrary,
    width: '35%'
  }
];

const Cell = styled(Grid)(({ header }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0px !important',
  height: header ? '48px' : '52px'
}));

export const ExpandedRow = ({
  row: {
    id,
    handleTargetDatasetChange,
    sourceDatasetMapping,
    targetStudyLibrary,
    targetStudyLibraries
  }
}) => {
  const targetDataSets = targetStudyLibrary?.library?.datasets || [];

  return (
    <Grid container spacing={2} style={{ margin: 0 }}>
      <Cell header item xs={1} />
      <Cell header item xs={5}>
        <Typography style={{ color: neutral7, fontWeight: 500, fontSize: '14px' }}>
          {'Source Data Set'}
        </Typography>
      </Cell>
      <Cell header item xs={5}>
        <Typography style={{ color: neutral7, fontWeight: 500, fontSize: '14px' }}>
          {'Target Data Set'}
        </Typography>
      </Cell>
      <Cell header item xs={1} />
      {Object.entries(sourceDatasetMapping).map((dataset, index) => {
        return (
          <React.Fragment key={dataset[0] + index}>
            <Cell item xs={1} />
            <Cell item xs={5}>
              <Typography style={{ color: neutral8, fontSize: '14px' }}>{dataset[0]}</Typography>
            </Cell>
            <Cell item xs={5}>
              <Tooltip title={!targetStudyLibrary && 'Select a target study library first'}>
                <span>
                  <Autocomplete
                    disabled={!targetStudyLibrary}
                    id="targetStudyLibrary"
                    data-testid="targetStudyLibrary-input"
                    size="small"
                    value={dataset[1] || null}
                    onChange={(e, value) => handleTargetDatasetChange(id, dataset[0], value)}
                    placeholder={'Select target data set'}
                    sx={{ width: '300px' }}
                    noWrap
                    fullWidth
                    source={targetDataSets
                      .filter(
                        (option) =>
                          option === dataset[1] ||
                          !Object.values(sourceDatasetMapping).find(
                            (mappings) => mappings?.label === option
                          )
                      )
                      .map((option) => {
                        return { label: option };
                      })}
                  />
                </span>
              </Tooltip>
            </Cell>
            <Cell item xs={1} />
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
