import { makeStyles } from '@mui/styles';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import Grid from 'apollo-react/components/Grid';
import Card from 'apollo-react/components/Card';
import Select from 'apollo-react/components/Select';
import MenuItem from 'apollo-react/components/MenuItem';
import Table, { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Checkbox from 'apollo-react/components/Checkbox';
import Button from 'apollo-react/components/Button';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import {
  ColumnIssueCell,
  createArrayStringSearchFilter,
  ExpressionCell
} from '../VLCRules.columns';
import { DateFilter } from 'Components/Common/DateFilter';
import { GetVLCRules } from 'Redux/Service/VLCRulesService';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { compareDates, dateFilter, dateFormatByType } from 'Utils';
import { Container, ContentText, InfoDatasetIcon, MessageText } from '../VLCRules.tableComps';
import InfoDataset from 'Images/info-dataset.svg';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Loader from 'Components/Loader';
import { setselectedDomainSourceData, setSelectedVLCRules } from 'Redux/Slice/DomainPeviewSlice';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem'
  },
  content: {
    minWidth: '10%'
  },
  header: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  value: {
    color: '#595959',
    fontSize: '14px',
    lineHeight: '24px'
  },
  select: {
    marginBottom: '6px'
  },
  headerCheckbox: {
    '& .MuiCheckbox-root': {
      top: 0
    },
    '& .MuiFormControlLabel-label': {
      display: 'none'
    }
  }
});

const CheckboxCell = ({ row }) => {
  return (
    <Checkbox
      style={{ marginRight: 0 }}
      checked={row.selected}
      onChange={() => row.handleSelectRow(row)}
    />
  );
};

export const columns = [
  {
    header: 'VLC Rule Name',
    accessor: 'vlcRuleName',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('vlcRuleName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Dataset',
    accessor: 'dataSet',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('dataSet'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Columns to Raise Issue Against',
    accessor: 'columns',
    filterFunction: createArrayStringSearchFilter('columns'),
    filterComponent: TextFieldFilter,
    customCell: ColumnIssueCell
  },
  {
    header: 'Expression',
    accessor: 'expression',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('expression'),
    filterComponent: TextFieldFilter,
    customCell: ExpressionCell
  },
  {
    header: 'VLC Action Condition',
    accessor: 'vlcActionCondition',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('vlcActionCondition'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Last Updated Date',
    accessor: 'iqAuditDate',
    sortFunction: compareDates,
    filterFunction: dateFilter('iqAuditDate'),
    filterComponent: DateFilter
  }
];

const EmptyTableComponent = () => (
  <Container>
    <InfoDatasetIcon src={InfoDataset} alt={'no-data-icon'} />
    <ContentText>No Dataset Selected</ContentText>
    <MessageText style={{ width: 'clamp(150px, 40%, 280px)' }}>
      Select a dataset to view the list of VLC rules.
    </MessageText>
  </Container>
);

const SelectVLCRules = (props) => {
  const { handleNext, mappingRuleVersionID } = props;
  const [isLoading, setLoading] = useState(true);
  const [vlcRulesByDataset, setVLCRulesByDataset] = useState({});
  const [vlcRules, setVLCRules] = useState([]);
  const [dataset, setDataset] = useState('');
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const { selectedVLCRules } = useSelector((state) => state.DomainPreviewData);

  const classes = useStyles();
  const dispatch = useDispatch();

  const updateTableRows = (updatedRows) => {
    updatedRows = updatedRows || [];
    setVLCRules(updatedRows);
    const selectedRows = updatedRows.filter((row) => row.selected);
    if (selectedRows.length === 0) setAllRowsSelected(false);
    else if (selectedRows.length === updatedRows.length) setAllRowsSelected(true);
    else setAllRowsSelected(null);
  };

  const handleSelectDataset = (e) => {
    vlcRulesByDataset[e.target.value]
      ? setVLCRules(JSON.parse(JSON.stringify(vlcRulesByDataset[e.target.value])))
      : setVLCRules([]);
    setAllRowsSelected(false);
    setDataset(e.target.value);
  };

  const handleSelectRow = (row) => {
    let updatedRows = [];
    vlcRules.forEach((item) => {
      let newItem = item;
      if (item.vlcRuleID === row.vlcRuleID) {
        newItem = { ...newItem, selected: !item.selected };
      }
      updatedRows.push(newItem);
    });
    updateTableRows(updatedRows);
  };

  const onHeaderCheckboxChange = () => {
    const _allRowsSelected = !allRowsSelected;
    setVLCRules((prev) => prev.map((item) => ({ ...item, selected: _allRowsSelected })));
    setAllRowsSelected(_allRowsSelected);
  };

  const handleNextButton = () => {
    dispatch(
      setSelectedVLCRules({
        dataset: dataset,
        vlcRules: vlcRules.filter((item) => item.selected)
      })
    );
    if (dataset !== selectedVLCRules?.dataset) {
      dispatch(setselectedDomainSourceData({}));
    }
    handleNext();
  };

  const handleVLCRulesResponse = (vlcRulesData) => {
    const _vlcRulesByDataset = {};
    vlcRulesData?.forEach((row) => {
      if (row.isActive === true) {
        if (!_vlcRulesByDataset[row.dataSet]) {
          _vlcRulesByDataset[row.dataSet] = [];
        }
        _vlcRulesByDataset[row.dataSet].push({
          ...row,
          selected: false,
          iqAuditDate: dateFormatByType(row.iqAuditDate, 'Table')
        });
      }
    });

    setVLCRulesByDataset(_vlcRulesByDataset);
    if (selectedVLCRules?.dataset && selectedVLCRules.vlcRules?.length) {
      setDataset(selectedVLCRules.dataset);
      const selectedRows = new Set(selectedVLCRules.vlcRules.map((item) => item.vlcRuleID));
      const allRows = JSON.parse(JSON.stringify(_vlcRulesByDataset[selectedVLCRules.dataset]));
      const updatedRows = allRows.map((item) => {
        if (selectedRows.has(item.vlcRuleID)) {
          return { ...item, selected: true };
        } else return { ...item };
      });
      updateTableRows(updatedRows);
    }
  };

  const fetchVLCRules = async () => {
    setLoading(true);
    const response = await dispatch(GetVLCRules(mappingRuleVersionID)).then(unwrapResult);
    if (response?.data?.success) {
      handleVLCRulesResponse(response.data.vlcRules);
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data.message }));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchVLCRules();
  }, []);

  const checkboxColumn = {
    header: (
      <Checkbox
        indeterminate={allRowsSelected === null}
        checked={!!allRowsSelected}
        onChange={onHeaderCheckboxChange}
        style={{ minHeight: 0, marginRight: 0 }}
        className={classes.headerCheckbox}
      />
    ),
    accessor: '',
    customCell: CheckboxCell,
    width: 45
  };
  const columnsConfig = useMemo(() => [checkboxColumn, ...columns], [allRowsSelected]);

  return (
    <>
      <Card style={{ padding: 0 }}>
        <Grid item className={classes.container}>
          <Grid item className={classes.content}>
            <Typography className={classes.header}>Dataset & VLC Rule</Typography>
            <Typography className={classes.value}>
              Select the Dataset and VLC Rules to preview result.
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {isLoading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <Grid container columnSpacing={1}>
          <Grid item xs={12} className={classes.select}>
            <Select
              label={<Typography className={classes.header}>{'Select Dataset'}</Typography>}
              placeholder="Select"
              sx={{ width: '40%' }}
              data-testid="dataset-select"
              canDeselect={false}
              value={dataset}
              onChange={(e) => handleSelectDataset(e)}>
              {Object.keys(vlcRulesByDataset)?.map((name) => (
                <MenuItem value={name} key={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Table
              title={'Select VLC Rules'}
              columns={columnsConfig}
              rows={vlcRules.map((row) => ({ ...row, handleSelectRow }))}
              defaultRowsPerPage={5}
              rowsPerPageOptions={[5, 10, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              initialSortedColumn="iqAuditDate"
              initialSortOrder="desc"
              tablePaginationProps={{
                labelDisplayedRows: DisplayedRowsLabel,
                truncate: true
              }}
              emptyProps={{
                content: <EmptyTableComponent />
              }}
            />
          </Grid>
        </Grid>
      )}
      <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={handleNextButton}
          variant="primary"
          size="small"
          disabled={!dataset || allRowsSelected === false}>
          {'Next'}
        </Button>
      </div>
    </>
  );
};

export default SelectVLCRules;
