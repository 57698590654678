/*eslint-disable*/
import { getEdcOdmCodelist, getCDR, getEdcOdmFormItemEproInfo } from 'Redux/Service/PreviewService';

const getFormsData = async ({
  libraryID,
  protocolNumber,
  isMappingRuleConfigured,
  libraryType,
  libraryVersion
}) => {
  let payload = {
    studyLibraryID: libraryID,
    studyLibraryVersion: libraryVersion,
    protocolName: protocolNumber,
    studyID: 0,
    studyVersions: [0]
  };

  let cdrPayload = {
    studyLibraryID: libraryID,
    studyLibraryVersion: libraryVersion
  };
  const odcEdmFormPreviewData =
    libraryType === 'CDISC ODM' &&
    isMappingRuleConfigured &&
    (await getEdcOdmFormItemEproInfo(payload));
  const odcEdmCodeListData =
    libraryType === 'CDISC ODM' &&
    isMappingRuleConfigured &&
    (await getEdcOdmCodelist(payload).then((res) => res.json()));
  const cdrFormItemListData =
    libraryType === 'CDR Tabular' &&
    isMappingRuleConfigured &&
    (await getCDR(cdrPayload).then((res) => res.json()));

  return {
    odcEdmFormPreviewData,
    odcEdmCodeListData,
    cdrFormItemListData
  };
};

export const getCodeListReferenceData = async (selectedDataSources, { protocolNumber }) => {
  let edcOdmInfo = {};
  let cdrInfo = {};

  const setCodeListInfo = (
    edcOdmFormsData,
    edcOdmCodeListsData,
    libraryType,
    vendor,
    cdrFormsData
  ) => {
    edcOdmCodeListsData?.forEach((record) => {
      if (typeof edcOdmInfo[record.itemOid] === 'undefined') {
        edcOdmInfo[record.itemOid] = {};
      }
      if (
        !edcOdmInfo[record.itemOid][`value_${record.stringValue}`] ||
        !edcOdmInfo[record.itemOid].studyVersion ||
        edcOdmInfo[record.itemOid].studyVersion < record.studyVersion
      ) {
        edcOdmInfo[record.itemOid][`odmCodeListName_${record.codelistName}`] = true;
        edcOdmInfo[record.itemOid].studyVersion = record.studyVersion;
      }
      edcOdmInfo[record.itemOid][`value_${record.stringValue}`] = true;
      edcOdmInfo[record.itemOid][`label_${record.translatedText}`] = true;
      edcOdmInfo[record.itemOid].odmForm = record.itemOid.split('.')[0];
      edcOdmInfo[record.itemOid].odmItem = record.itemOid.split('.')[1];
      edcOdmInfo[record.itemOid].libraryType = libraryType;
      edcOdmInfo[record.itemOid].vendor = vendor;
    });

    edcOdmFormsData?.forEach((record) => {
      if (typeof edcOdmInfo[record.itemOid] !== 'undefined') {
        edcOdmInfo[record.itemOid].isEpro = record.isEproForm;
      }
    });

    cdrFormsData !== undefined &&
      cdrFormsData.forEach((record) => {
        let key = `${record.datasetName}.${record.names}`;
        cdrInfo[key] = {
          odmForm: record.datasetName,
          odmItem: record.names
          // 'odmCodeListName' : record.odmCodeListName,
          // 'libraryType' : record.libraryType,
          // 'vendor' : record.vendor,
        };
      });
  };

  if (selectedDataSources && selectedDataSources.length > 0) {
    for (let record of selectedDataSources) {
      const { libraryID, libraryType, vendor, isMappingRuleConfigured, libraryVersion } = record;
      const {
        odcEdmFormPreviewData: formsPreviewData,
        odcEdmCodeListData: codeListData,
        cdrFormItemListData: cdrPreviewData
      } = await getFormsData({
        libraryID,
        protocolNumber,
        isMappingRuleConfigured,
        libraryType,
        libraryVersion
      });

      if (libraryType === 'CDISC ODM') {
        if (formsPreviewData.message === 'Success' && codeListData.message === 'Success') {
          setCodeListInfo(formsPreviewData.formsItems, codeListData.codeLists, libraryType, vendor);
        }
      }

      if (
        libraryType === 'CDR Tabular' &&
        cdrPreviewData.message === 'Success' &&
        isMappingRuleConfigured
      ) {
        setCodeListInfo(
          formsPreviewData.edcOdmForms,
          codeListData.codeLists,
          libraryType,
          vendor,
          cdrPreviewData.cdrDataSetColumns.length ? cdrPreviewData.cdrDataSetColumns : []
        );
      }
    }
  }

  return { edcOdmInfo, cdrInfo };
};

export default { getCodeListReferenceData };
