/*eslint-disable */
import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';

import debounce from 'lodash.debounce';
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import { neutral1, neutral7 } from 'apollo-react/colors';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import { FixedSizeList as List } from 'react-window';
import Box from '@mui/material/Box';
import CardContent from 'apollo-react/components/CardContent';
import Search from 'apollo-react/components/Search';
import Switch from 'apollo-react/components/Switch';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from 'apollo-react/components/Button';
import classNames from 'classnames';
import { setSelectedLibrary, getProtocol } from 'Redux/Slice/StudyLibrarySlice';
import Cookies from 'js-cookie';
import PinIcon from '../../../icons/PinIcon';
import { SavePinnedStudies, GetAssignedStudyLibraries } from './../../../Redux/Service/DataProductsService';

import { useDispatch, useSelector } from 'react-redux';
import Loader from 'Components/Loader/Loader';
import CustomModal from '../../../Components/Modal';
import ViewUpdate from '../../../Components/StudyLibraryDrawer/Components/ViewUpdate';
import { openDataProductPanel } from 'Redux/Slice/DataProductLibrarySlice';
import { openDataPipelinePanel } from 'Redux/Slice/PublishMappingSpecSlice';
import { showBanner } from 'Redux/Slice/BannerSlice';
import getFilteredProtocols from '../../../Utils/StudySearch';

const styles = {
  root: {
    display: 'flex',
    height: 400,
    backgroundColor: neutral1,
    padding: 24,
    boxSizing: 'content-box'
  },
  panelTitle: {
    padding: '12px 24px',
    fontWeight: 600,
    margin: 0,
    fontSize: '1rem'
  },
  panelSubTitle: {
    padding: '14px 40px 3px 24px'
  },
  panelSearch: {
    margin: '0.5rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 24px',
    height: '3rem',

    '&> .MuiFormControl-marginNormal': {
      margin: 0,
      '&> label + .MuiInput-formControl': {
        margin: 0
      }
    }
  },
  card: {
    margin: '8px 24px',
    cursor: 'pointer'
  },
  cardHighlight: {
    border: '2px solid #0768FD',
    '&:hover': {
      border: '2px solid #0768FD'
    },
    boxShadow: '0 8px 20px 0 rgba(0,0,0,0.08)'
  },
  bold: {
    fontWeight: 600
  },
  cardSubtitle: {
    color: neutral7,
    lineHeight: '24px'
  },
  panelContent: {
    overflow: 'auto',
    height: 'calc(100% - 156px)',
    width: 'fit-content'
  },
  textSize: {
    fontSize: '14px',
    color: '#595959'
  },
  textWieght: {
    fontWeight: '600',
    fontSize: '14px',
    color: '#444444'
  },
  switch: {
    flexDirection: 'row-reverse',
    alignItems: 'center',

    '&> span:nth-child(2)': {
      marginTop: '4px',
      paddingRight: '0.2rem'
    }
  }
};
const LIBRARY_CONFIGURED = 'Study Library Added';
const LIBRARY_NOT_CONFIGURED = 'No Study Library Added';
const DataProductDrawer = () => {
  const useStyles = makeStyles(styles);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [assignedStudyData, setAssignedStudyData] = useState([]);
  const [value, setValue] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toggleStudyPinned, setToggleStudyPinned] = useState(false);
  const { closePanelText } = useSelector((state) => state.StudyLibraryData);

  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const selectedLibrary = { ...protocol };

  const projectHeaderDisplayed = useMemo(() => {
    return Boolean(protocol && Object.keys(protocol).length !== 0);
  }, [protocol]);
  const { loading: mainLoader } = useSelector((state) => state.LoaderData);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setNoResults(false);
      const assignedStudies = await dispatch(GetAssignedStudyLibraries()).then(unwrapResult);

      if (assignedStudies.data.success) {
        const {
          data: { assignedStudyProducts }
        } = assignedStudies;
        const copyAssignedStudies = [...assignedStudyProducts];
        const filteredStudies = copyAssignedStudies.filter((item) => {
          return item.isActive === value;
        });

        //sorting alphabetically
        filteredStudies.sort((a, b) =>
          a.protocolNumber.localeCompare(b.protocolNumber, 'es', { sensitivity: 'base' })
        );

        // sorting pinned studies
        filteredStudies.sort((a, b) => {
          return b.isStudyPinned - a.isStudyPinned;
        });
        protocol &&
          Object.keys(protocol).length &&
          dispatch(
            getProtocol(
              filteredStudies.filter((item) => item.protocolNumber === protocol.protocolNumber)[0]
            )
          );

        setAssignedStudyData(filteredStudies);
        setFilteredData(filteredStudies);
        setLoading(false);
        if (!filteredStudies.length) {
          setNoResults(true);
        }
      } else {
        dispatch(showBanner({ variant: 'error', message: assignedStudies.data.message }));
        setLoading(false);
      }
    })();
  }, [value, toggleStudyPinned]);

  const handleChange = (e) => {
    setValue(e.target.checked);
  };

  const dataProductDrawerResultShow = () => {
    if (noResults) {
      return <p>No Study available for your search</p>;
    } else {
      return (
        <List
          useIsScrolling
          height={window.screen.height - (projectHeaderDisplayed ? 415 : 359)}
          itemCount={filteredData.length}
          itemSize={275}
          width={350}
          style={mainLoader ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
          {Row}
        </List>
      );
    }
  };

  const updateProtocolData = (event, protocol) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(setSelectedLibrary(protocol));
    dispatch(openDataProductPanel(false));
    dispatch(openDataPipelinePanel(false));
    dispatch(getProtocol(protocol));
  };

  const debouncedSave = useCallback(
    debounce((value, assignedStudyData) => {
      const { data, flag } = getFilteredProtocols(value, assignedStudyData);
      setFilteredData(data);
      setNoResults(flag);
    }, 1000),
    []
  );

  const Row = ({ style, index }) => (
    <div style={style}>
      {filteredData.length > 0 && (
        <CustomCard
          key={index}
          protocol={filteredData[index]}
          index={index}
          setToggleStudyPinned={setToggleStudyPinned}
          toggleStudyPinned={toggleStudyPinned}
        />
      )}
    </div>
  );

  const handleCardChange = ({ target: { value } }) => {
    debouncedSave(value, assignedStudyData);
  };

  const CustomCard = ({ protocol, index, setToggleStudyPinned, toggleStudyPinned }) => {
    const [viewUpdate, setViewUpdate] = useState(false);
    const [showViewUpdate, setShowViewUpdate] = useState(false);
    const [updateCountDisplay, setUpdateCountDisplay] = useState('');
    const [pinned, setPinned] = useState({ color: protocol.isStudyPinned ? '#0768FD' : '#999999' });
    const childRef = useRef(viewUpdate);

    const handleOpenViewUpdate = () => {
      setViewUpdate(true);
    };

    const handleClose = () => {
      setViewUpdate(childRef.current);
    };

    const getCurrentTimeStamp = () => {
      let date = new Date().toISOString();
      return date;
    };
    const getSavePayload = (isPinned) => {
      let payload = {};
      payload = {
        protocolName: protocol.protocolNumber,
        userId: Cookies.get('user.id'),
        isPinned: isPinned,
        createdDate: getCurrentTimeStamp()
      };
      return payload;
    };

    const handlePinned = async (event) => {
      event.stopPropagation();
      event.preventDefault();
      let payload = {};
      if (protocol.isStudyPinned) {
        payload = getSavePayload(false);

        const pinStudy = await dispatch(SavePinnedStudies(payload)).then(unwrapResult);

        if (pinStudy.data.success) {
          setToggleStudyPinned(!toggleStudyPinned);
          setPinned({ color: '#999999' });
        } else {
          dispatch(showBanner({ variant: 'error', message: pinStudy.data.message }));
        }
      } else {
        payload = getSavePayload(true);

        const pinStudy = await dispatch(SavePinnedStudies(payload)).then(unwrapResult);

        if (pinStudy.data.success) {
          setToggleStudyPinned(!toggleStudyPinned);
          setPinned({ color: '#0768FD' });
        } else {
          dispatch(showBanner({ variant: 'error', message: pinStudy.data.message }));
        }
      }
    };

    useEffect(() => {
      if (protocol.viewUpdates && protocol.viewUpdates.length > 0) {
        setUpdateCountDisplay(protocol.viewUpdates.length);
        setShowViewUpdate(true);
      } else {
        setUpdateCountDisplay('');
        setShowViewUpdate(false);
      }
    }, [protocol]);
    return (
      <Card
        // disabled={!protocol.isActive ? true : false}
        interactive
        // onClick={() => updateProtocolData(protocol)}
        className={classNames(
          classes.card,
          selectedLibrary &&
            protocol.protocolNumber === selectedLibrary.protocolNumber &&
            classes.cardHighlight
        )}>
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={12}
              onClick={(e) => {
                updateProtocolData(e, protocol);
              }}>
              <Box mb={2}>
                <Box display="flex" style={{ justifyContent: 'space-between' }}>
                  <Typography
                    style={{
                      backgroundColor: protocol.isStudyLibraryConfigured ? '#E9E9E9' : 'orange',
                      color: protocol.isStudyLibraryConfigured ? '#444444' : 'white',
                      padding: '3px 8px',
                      borderRadius: '2px'
                    }}
                    variant="caption">
                    {protocol.isStudyLibraryConfigured
                      ? LIBRARY_CONFIGURED
                      : LIBRARY_NOT_CONFIGURED}
                  </Typography>
                  <div onClick={(e) => handlePinned(e)}>
                    <PinIcon color={pinned.color} />
                  </div>
                </Box>
                <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0 0' }}>
                  {protocol.isActive ? (
                    <span
                      style={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '100%',
                        background: '#00C221',
                        marginRight: '4px'
                      }}></span>
                  ) : (
                    <span
                      style={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '100%',
                        background: 'grey',
                        marginRight: '4px'
                      }}></span>
                  )}

                  <Typography variant="title1" style={{ fontSize: '16px', margin: '3px 0' }}>
                    {protocol.protocolNumber}
                  </Typography>
                </div>
                <Typography className={classes.textSize} variant="caption">
                  {protocol.sponsor}
                </Typography>
              </Box>
            </Grid>
            <Grid container spacing={2} onClick={(e) => updateProtocolData(e, protocol)}>
              <Grid item xs={6}>
                <Box mb={1}>
                  <Typography className={classes.textSize}>Project Code</Typography>
                  <Typography className={classes.textWieght}>{protocol.projectCode}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={1}>
                  <Typography className={classes.textSize}>Study Status</Typography>
                  <Typography className={classes.textWieght}>{protocol.studyStatus}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} onClick={(e) => updateProtocolData(e, protocol)}>
              <Grid item xs={6}>
                <Typography className={classes.textSize}>Therapeutic Area</Typography>
                <Typography className={classes.textWieght}>{protocol.therapeuticArea}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textSize}>Phase</Typography>
                <Typography className={classes.textWieght}>{protocol.phase}</Typography>
              </Grid>
            </Grid>
            {showViewUpdate && (
              <Grid container spacing={2} onClick={handleOpenViewUpdate}>
                <Grid item xs={12}>
                  <div
                    style={{
                      position: 'relative',
                      float: 'left',
                      width: '100%',
                      height: '41px',
                      zIndex: 9999
                    }}>
                    <Button
                      variant="text"
                      style={{
                        color: '#0768FD',
                        fontSize: '14px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0
                      }}>
                      {`View updates (${updateCountDisplay})`}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
            <CustomModal
              display={viewUpdate}
              handleClose={handleClose}
              title={'Updates'}
              subtitle={protocol.protocolNumber}
              handleSecondaryAction={handleClose}
              buttonPrimaryLabel={'Ok'}
              buttonSecondardyLabel={'Cancel'}
              body={<ViewUpdate data={protocol} />}
            />
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          background: '#FFFFFF',
          borderRight: '1px solid #e9e9e9',
          padding: '0.5rem 0'
        }}>
        <Typography className={classes.panelTitle} gutterBottom>
          My Assigned Studies
        </Typography>
        <Box
          display="flex"
          flexDirection={'row'}
          alignItems="center"
          justifyContent={'space-between'}
          height={'2rem'}>
          <Typography
            style={{
              fontSize: '14px',
              paddingLeft: '24px',
              color: '#595959',
              marginBottom: '0.3em'
            }}
            variant="subtitle"
            gutterBottom>
            {closePanelText ? `${filteredData.length} Protocols` : ''}
          </Typography>
          {closePanelText ? (
            <Switch
              className={classes.switch}
              data-testid="active-studies-toggle"
              label={'Show only active studies '}
              checked={value}
              onChange={(e) => handleChange(e)}
              size="small"
            />
          ) : null}
        </Box>
        <div style={{ maxWidth: 400 }} className={classes.panelSearch}>
          <Search placeholder="Search" fullWidth onChange={(e) => handleCardChange(e)} />
        </div>
      </div>
      {loading ? (
        <div style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={classes.panelContent}>{dataProductDrawerResultShow()}</div>
        </>
      )}
    </div>
  );
};

export default DataProductDrawer;
