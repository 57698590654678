/*eslint-disable */
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { DataProductGridSlice } from '../Redux/Slice/DataProductGridSlice';
import { DomainAndVariableSlice } from '../Redux/Slice/DomainAndVariableSlice';
import { DataProductsSlice } from 'Redux/Slice/DataProductsSlice';
import { GlobalLibrarySlice } from '../Redux/Slice/GlobalLibrarySlice';
import { LoginFormSlice } from '../Redux/Slice/loginFormDetailsSlice';
import { BreadcrumbSlice } from 'Redux/Slice/BreadcrumbSlice';
import { EditGlobalLibrarySlice } from 'Redux/Slice/EditGlobalLibrarySlice';
import { GlobalLibrarySliceAdd } from 'Redux/Slice/AddGlobalLibrarySlice';
import { BannerSlice } from 'Redux/Slice/BannerSlice';
import { throttle } from 'Utils';
import { ModalSlice } from './Slice/ModalSlice';
import { StudyLibrarySlice } from './Slice/StudyLibrarySlice';
import { LoaderSlice } from './Slice/LoaderSlice';
import { AddStudyLibrarySlice } from './Slice/AddStudyLibrarySlice';
import { EditStudyLibrarySlice } from './Slice/EditStudyLibrarySlice';
import { DataProductLibrarySlice } from './Slice/DataProductLibrarySlice';
import { RuleEditorSlice } from './Slice/RuleEditorSlice';
import { DomainPreviewSlice } from './Slice/DomainPeviewSlice';
import { PublishMappingSpecSlice } from './Slice/PublishMappingSpecSlice';
import { DashboardSlice } from './Slice/DashboardSlice';
import { DataRefreshHistorySlice } from './Slice/DataRefreshHistorySlice';
import { DebounceSlice } from './Slice/DebounceSlice';
import { ReferenceDataSlice } from './Slice/ReferenceDataSlice';
import { CopyDataProductSlice } from './Slice/CopyDataProductSlice';
import { AppSlice } from './Slice/AppSlice';

const loadFromLocalStorage = () => {
  try {
    let state = JSON.parse(localStorage.getItem('state'));
    if (state === null) return undefined;
    return state;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch (e) {
    console.log('Error saving data in localstorage', e);
  }
};

const persistedData = loadFromLocalStorage();

const combinedReducer = combineReducers({
  AppData: AppSlice.reducer,
  ViewGlobalLibrary: GlobalLibrarySlice.reducer,
  DomainData: DataProductGridSlice.reducer,
  DomainAndVariable: DomainAndVariableSlice.reducer,
  DataProduct: DataProductsSlice.reducer,
  LoginFormData: LoginFormSlice.reducer,
  breadCrumbData: BreadcrumbSlice.reducer,
  EditGlobalData: EditGlobalLibrarySlice.reducer,
  GlobalCategoryAndLibraryType: GlobalLibrarySliceAdd.reducer,
  Banner: BannerSlice.reducer,
  ModalData: ModalSlice.reducer,
  StudyLibraryData: StudyLibrarySlice.reducer,
  LoaderData: LoaderSlice.reducer,
  AddStudyLibrary: AddStudyLibrarySlice.reducer,
  EditStudyData: EditStudyLibrarySlice.reducer,
  DataProductStudyLibrary: DataProductLibrarySlice.reducer,
  RuleEditorData: RuleEditorSlice.reducer,
  DomainPreviewData: DomainPreviewSlice.reducer,
  PublishMappingSpecData: PublishMappingSpecSlice.reducer,
  DashboardSliceData: DashboardSlice.reducer,
  DataRefreshHistory: DataRefreshHistorySlice.reducer,
  DebounceData: DebounceSlice.reducer,
  ReferenceData: ReferenceDataSlice.reducer,
  CopyMappingSpecData: CopyDataProductSlice.reducer
});

const rootReducer = (state, action) => {
  if (action.type === 'BreadcrumbData/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
  preloadedState: persistedData
});

store.subscribe(
  throttle(() => {
    saveToLocalStorage(store.getState());
  }, 3000)
);

export default store;
