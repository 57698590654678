/*eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { getCodeLists } from 'Redux/Service/ReferenceDataCardsService';
export const ReferenceDataSlice = createSlice({
  name: 'ReferenceData',

  initialState: {
    codelist:[],
    sourceCodelistMap:{},
    formItemCodelistMap: {}
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getCodeLists.fulfilled,(state,{payload})=>{
            if (payload && payload.data && payload.data.ruleCodeLists){
              state.codelist = payload.data.ruleCodeLists;
              let codelistMap={};
              let codelistFormMap = {};
              payload.data.ruleCodeLists.forEach(({odmCodeListName, odmForm, odmItem})=>{
                odmCodeListName=odmCodeListName.trim().toLowerCase();
                if (odmForm && odmItem) {
                  let key = `[${odmForm.trim().toLowerCase()}].[${odmItem.trim().toLowerCase()}]`; 
                  if(!codelistFormMap[key]) codelistFormMap[key]=true;
                }
                if (!odmForm && !odmItem) {
                  if(!codelistMap[odmCodeListName]) codelistMap[odmCodeListName]=true;
                }
              })
              state.sourceCodelistMap=codelistMap;
              state.formItemCodelistMap = codelistFormMap;
          }
    })
  }
});
