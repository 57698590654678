/* eslint-disable */
import { Box, Collapse, Grid, IconButton } from '@mui/material';
import { Typography } from 'apollo-react';
import Drag from 'apollo-react-icons/Drag';
import Trash from 'apollo-react-icons/Trash';
import Switch from 'apollo-react/components/Switch';
import TextField from 'Components/Controls/TextField';
import CustomTextField from 'Components/CustomeTextField/CustomeTextField';
import { useEffect, useRef, useState } from 'react';
import { generateErrorString, getHtmlString } from 'Utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  switch: {},
  textField: {
    '& input': {
      border: 'none',
      backgroundColor: '#FFFF'
    }
  },
  row: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderBottom: '1px solid #4441'
  },
  column: {
    padding: '1rem'
  },
  rowColapsed: {},
  expressionCol: {
    height: '100%',
    paddingBottom: '6px',
    fontWeight: 'bold',
    fontFamily: 'Courier New'
  },
  collapse: {
    width: '100%'
  },
  dragTopRow: {
    width: '100%',
    borderTop: '3px solid #0768fd'
  },
  dragBottomRow: {
    width: '100%',
    borderBottom: '3px solid #0768fd'
  },
  hidden: {
    display: 'none'
  }
});

const fieldStyles = {
  style: {
    margin: '3px 0px'
  }
};

const Variable = (props) => {
  const {
    index,
    variableName,
    expression,
    label,
    status,
    variableRuleSetID,
    onDelete,
    rowCount,
    editRow,
    preview,
    ruleSetValidationResult,
    handleCaretPositionChange,
    onMoveVariableRow,
    rowData
  } = props;

  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [variableNameError, setVariableNameError] = useState({});
  const [expressionError, setExpressionError] = useState({});
  const [labelError, setLabelError] = useState({});
  const [cordinates, setCordinates] = useState({
    start: 0,
    end: 0
  });
  const [draggable, setDraggable] = useState(false);
  const [keyDraggable, setKeyDraggable] = useState(false);
  const [specialCharExist, setSpecialCharExist] = useState({});

  useEffect(() => {
    setLoaded(true);
  }, []);
  const rowref = useRef();
  const [dragPosition, setDragPosition] = useState('');
  const handleDelete = () => {
    setLoaded(false);
    setTimeout(() => onDelete(variableRuleSetID), 600);
  };

  useEffect(() => {
    const getVariableNameError =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isColumnValid(variableRuleSetID, 'variableName')
        : variableNameError;
    const getLabelError =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isColumnValid(variableRuleSetID, 'label')
        : labelError;
    const getExpressionError =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isColumnValid(variableRuleSetID, 'expression')
        : expressionError;
    const getCordinates =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isExpressionValid(variableRuleSetID)
        : cordinates;
    const specialCharValue =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isSpecialCharPresent()
        : specialCharExist;

    setSpecialCharExist(specialCharValue);
    setVariableNameError(getVariableNameError);
    setLabelError(getLabelError);
    setExpressionError(getExpressionError);
    setCordinates(getCordinates);
  }, [ruleSetValidationResult]);

  const getErrorText = (data) => {
    return data.mandatory ? data.mandatory : data.unique || data.message;
  };

  const handleOnDragOver = (e) => {
    e.dataTransfer.dropEffect = 'move';
    const {
      nativeEvent: { offsetY }
    } = e;
    const offsetHeight = rowref.current.offsetHeight;
    if (offsetY > offsetHeight / 2) {
      setDragPosition('bottom');
    } else if (offsetY < offsetHeight / 2) {
      setDragPosition('top');
    }
    e.preventDefault();
  };

  const handleOnDragEnd = (e) => {
    setDraggable(false);
    setDragPosition('');
    const {
      target: {
        parentElement: { childNodes },
        offsetTop,
        offsetHeight
      },
      nativeEvent: { offsetY }
    } = e;
    const currentRowPosition = offsetTop + offsetY;
    const rowPositions = [];
    for (let child of childNodes) {
      rowPositions.push(child.offsetTop + child.offsetHeight / 2);
      child.classList.remove(classes.dragTopRow, classes.dragBottomRow);
      child.classList.add(classes.collapse);
    }

    let moveToIndex =
      offsetY > offsetHeight / 2
        ? rowPositions.reduce((previousIndex, rowPosition, rowIndex) => {
            if (currentRowPosition > rowPosition) {
              return rowIndex;
            }
            return previousIndex;
          }, index)
        : rowPositions.reduceRight((previousIndex, rowPosition, rowIndex) => {
            if (currentRowPosition < rowPosition) {
              return rowIndex;
            }
            return previousIndex;
          }, index);

    console.log('moveToIndex', offsetY, index, moveToIndex);
    if (index !== moveToIndex) onMoveVariableRow(index, moveToIndex);
    e.target.style.border = '2px solid #0768fd';
    setTimeout(() => {
      e.target.style.opacity = 1;
      e.target.style.border = '';
    }, 600);
  };

  const handleTransition = (e) => {
    e.target.parentElement.parentElement.style.border = '2px solid #0768fd';
    setTimeout(() => {
      e.target.parentElement.parentElement.style.border = '';
    }, 1000);
  };

  return (
    <Collapse
      in={loaded}
      draggable={draggable}
      className={
        dragPosition === 'bottom'
          ? classes.dragBottomRow
          : dragPosition === 'top'
          ? classes.dragTopRow
          : classes.collapse
      }
      onDragEnd={handleOnDragEnd}
      onDragLeave={() => setDragPosition('')}
      onDragOver={(e) => handleOnDragOver(e)}
      ref={rowref}>
      <Box display={'flex'} flexDirection={'row'}>
        {!preview && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{
              borderRight: '1px solid #4441',
              borderBottom: '1px solid #4441',
              background: 'white',
              width: '4%'
            }}>
            {rowCount > 1 ? (
              <Drag
                tabIndex={rowCount <= 1 ? '-1' : '0'}
                onMouseDown={() => setDraggable(true)}
                onMouseUp={() => setDraggable(false)}
                onClick={() => setKeyDraggable(true)}
                onFocus={() => setKeyDraggable(true)}
                onBlur={() => setKeyDraggable(false)}
                onKeyDown={
                  keyDraggable
                    ? (e) => {
                        if (e.key === 'ArrowUp' && index !== 0) {
                          let moveToIndex = index - 1;
                          onMoveVariableRow(index, moveToIndex);
                          handleTransition(e);
                        } else if (e.key === 'ArrowDown' && index < rowData.length - 1) {
                          let moveToIndex = index + 1;
                          onMoveVariableRow(index, moveToIndex);
                          handleTransition(e);
                        }
                      }
                    : undefined
                }
                style={{
                  size: 'large',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '3%',
                  background: draggable || keyDraggable ? '#d9d9d9' : 'white',
                  outline: 0,
                  cursor: draggable ? 'grabbing' : 'grab'
                }}
              />
            ) : (
              <Drag
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '3%',
                  color:'#b5b5b5'
                }}></Drag>
            )}
          </Box>
        )}
        <Grid
          container
          className={classes.row}
          style={{ width: preview ? '100%' : '96%' }}
          onMouseDown={() => setDraggable(false)}>
          <Grid xs={3} className={classes.column}>
            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
              <Typography
                style={{
                  color: '#444444',
                  display: 'block',
                  fontSize: '14px'
                }}>
                Active
              </Typography>
              <Switch
                className={classes.switch}
                checked={status}
                onChange={(e) => editRow(!status, 'status', variableRuleSetID)}
                size="small"
                disabled={preview}
              />
              <TextField
                autoFocus={true}
                size="small"
                fullWidth
                value={variableName}
                onChange={(e) => editRow(e.target.value, 'variableName', variableRuleSetID)}
                label={'Variable Name'}
                variant="standard"
                className={preview && classes.textField}
                InputProps={{ readOnly: preview }}
                disabled={preview}
                style={{ margin: '5px 0 0 0' }}
                {...(Object.keys(variableNameError).length > 0
                  ? {
                      helperText: (getErrorText(variableNameError) || getErrorText(specialCharExist)),
                      error: true
                    }
                  : {})}
              />
              <TextField
                fullWidth
                value={label}
                onChange={(e) => editRow(e.target.value, 'label', variableRuleSetID)}
                label={'Label Name'}
                className={preview && classes.textField}
                InputProps={{ readOnly: preview }}
                disabled={preview}
                style={{ margin: preview ? '0px' : '5px 0 0 0' }}
                {...(Object.keys(labelError).length > 0
                  ? {
                      helperText: getErrorText(labelError),
                      error: true
                    }
                  : {})}
              />
            </Box>
          </Grid>

          <Grid xs={preview ? 9 : 8} className={classes.column}>
            <Box className={classes.expressionCol}>
              <CustomTextField
                id="expression"
                onChange={(value) => editRow(value, 'expression', variableRuleSetID)}
                onCaretPositionchange={(pos) =>
                  handleCaretPositionChange(pos, variableRuleSetID, index)
                }
                name="expression"
                data-testid="expression-input"
                disabled={preview}
                helperText={
                  Object.keys(expressionError).length > 0 && !expressionError?.isValid
                    ? getErrorText(expressionError)
                    : ''
                }
                error={Object.keys(expressionError).length > 0 ? !expressionError?.isValid : false}
                customProps={{
                  style: !preview
                    ? { height: '100%' }
                    : {
                        height: '100%',
                        backgroundColor: '#F2F2F2',
                        border: '1px solid #D9D9D9',
                        borderRadius: '4px'
                      },
                  placeholder: 'abc'
                }}>
                {Object.keys(expressionError).length > 0 && !expressionError?.isValid
                  ? generateErrorString(`${expression}`, cordinates.start, cordinates.end)
                  : getHtmlString(`${expression}`, [], '', 2)}
              </CustomTextField>
            </Box>
          </Grid>
          <Grid xs={preview ? 0 : 1} className={[classes.column, preview && classes.hidden]}>
            <IconButton
              size="small"
              data-testid="delete-btn"
              onClick={handleDelete}
              disabled={rowCount <= 1}>
              <Trash />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
};

export default Variable;
