/*eslint-disable */
import { unwrapResult } from '@reduxjs/toolkit';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCodeLists } from 'Redux/Service/ReferenceDataCardsService';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';
import { getSourceName, useStateWithCB } from 'Utils';
import DomainRules from './DomainRules';
import { GetProductDataSourcesByMappingRuleVersionID } from 'Redux/Service/RuleEditorService';
import { referenceDataService } from 'Services/ReferenceData.Service';

export const DomainRulesContext = createContext();

export const DomainRulesProvider = (props) => {
  // console.log("GGGGG", useLocation());
  const location = useLocation();
  const { domainCode: domain, nodeId } = location.state || {};
  const dispatch = useDispatch();

  const [treeView, setTreeView] = useState([]);
  const [selected, setSelected] = useState(nodeId ? [+nodeId] : [1]);
  const [selectNodeText, setSelectedNodeText] = useState('');
  const [expanded, setExpanded] = useState(nodeId ? [+nodeId] : []);
  const [parentNodeID, setParentNodeID] = useState(nodeId ? [+nodeId] : '');
  const [parentNodeName, setParentNodeName] = useState(domain || '');
  const [filters, setFilters] = useStateWithCB({});
  const [formMetaData, setFormMetaData] = useState({});
  const [domainData, setDomainData] = useState([]);
  const [domainFormattedData, setDomainFormattedData] = useState({});
  const [newDomainFormattedData, setNewDomainFormattedData] = useState({});
  const [methodsConstants, setMethodsAndConstants] = useState([]);
  const [description, setDescription] = useState('');
  const [isSuppQual, setIsSuppQual] = useState(false);
  const [disableVariable, setDisableVariable] = useState(false);
  const [expressionTemplates, setExpressionTemplates] = useState([]);
  const [searchFieldsEnabled, displaySearchFields] = useState({
    enabled: false,
    onAdd: undefined,
    onCancel: undefined
  });
  const [mappingError, setMappingError] = useState('');
  const [codeListDataMapping, setCodeListDataMapping] = useState(null);

  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const { id: mappingRuleVersionID } = useParams();

  const [referenceData, setReferenceData] = useState({});

  const initializeReferenceDataService = async () => {
    const selectedDataSourceData = await dispatch(
      GetProductDataSourcesByMappingRuleVersionID(mappingRuleVersionID)
    ).then(unwrapResult);
    if (
      selectedDataSourceData &&
      selectedDataSourceData.data &&
      selectedDataSourceData.data.success
    ) {
      let result = await referenceDataService(selectedDataSourceData.data.ruleStudyLibrary);
      setReferenceData(result);
    }
  };

  useEffect(() => {
    initializeReferenceDataService();
  }, []);

  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const getCodeListDataMapping = async (forceUpdate = false) => {
    if (!codeListDataMapping || forceUpdate) {
      try {
        const payload = {
          mappingRuleVersionID
        };
        const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
        if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
          const mapping = {};
          const codelists = codeListsGetAPIData.data.ruleCodeLists || [];

          for (let { odmForm, odmItem, sdmCodelistLabel } of codelists) {
            const key = `${odmForm.toLowerCase()}#-#${odmItem.toLowerCase()}`;
            if (!(key in mapping)) {
              mapping[key] = [];
            }
            if (sdmCodelistLabel.trim()) mapping[key].push(sdmCodelistLabel.toLowerCase());
          }
          setCodeListDataMapping(mapping);
        }
      } catch (error) {
        setCodeListDataMapping({});
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCodeListDataMapping();
  }, [codeListDataMapping]);

  const [sourceFormItems, formatedFormItemData] = useMemo(() => {
    const formatedFormItemData = [];
    const sourceFormItems = {};
    for (let libraryType in formMetaData) {
      for (let formData of formMetaData[libraryType]) {
        formatedFormItemData.push({
          formName: formData.formName,
          itemName: formData.itemName,
          libraryType,
          formatedString: `[${formData.formName}].[${formData.itemName}]`
        });
        if (sourceFormItems[formData.formName])
          sourceFormItems[formData.formName].items.push(formData.itemName);
        else
          sourceFormItems[formData.formName] = {
            items: [formData.itemName],
            sourceName: getSourceName(libraryType)
          };
      }
    }
    return [sourceFormItems, formatedFormItemData];
  }, [formMetaData]);

  const isOptional = useMemo(() => {
    const moduleName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (workFlowModel && Object.keys(workFlowModel).length > 0) {
      const functionData = workFlowModel.functionData;
      if (functionData && functionData.length > 0) {
        const index = functionData.findIndex((data) => {
          return data.moduleName === moduleName;
        });
        if (!functionData[index]?.isRequired) {
          dispatch(setNextEnabled(true));
          return true;
        } else return false;
      }
    }
  }, [workFlowModel]);

  const handleStepperValidate = (isDisabled) => {
    dispatch(setNextEnabled(isOptional || !isDisabled));
  };

  const contextData = {
    selected,
    setSelected,
    expanded,
    setExpanded,
    setSelectedNodeText,
    selectNodeText,
    setParentNodeID,
    parentNodeID,
    setParentNodeName,
    parentNodeName,
    filters,
    setFilters,
    formMetaData,
    setFormMetaData,
    confirmAlert,
    setConfirmAlert,
    setDomainData,
    domainData,
    setDomainFormattedData,
    domainFormattedData,
    treeView,
    setTreeView,
    setNewDomainFormattedData,
    methodsConstants,
    setMethodsAndConstants,
    newDomainFormattedData,
    setDescription,
    description,
    disableVariable,
    setDisableVariable,
    expressionTemplates,
    setExpressionTemplates,
    sourceFormItems,
    searchFieldsEnabled,
    displaySearchFields,
    mappingError,
    setMappingError,
    formatedFormItemData,
    codeListDataMapping,
    setCodeListDataMapping,
    getCodeListDataMapping,
    isSuppQual,
    setIsSuppQual,
    referenceData
  };

  return (
    <>
      <DomainRulesContext.Provider value={contextData}>
        <DomainRules handleStepperValidate={handleStepperValidate} />
      </DomainRulesContext.Provider>
    </>
  );
};
