import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'apollo-react/components/MenuItem';
import ApolloSelect from 'apollo-react/components/Select';

const Select = (props) => {
  const {
    onChange,
    label,
    placeholder,
    name,
    items,
    value,
    fullWidth,
    error,
    helperText,
    disabled
  } = props;
  return (
    <ApolloSelect
      {...props}
      label={label}
      placeholder={placeholder}
      fullWidth={fullWidth}
      name={name}
      onChange={onChange}
      value={value}
      error={error}
      helperText={helperText}
      disabled={disabled}>
      {props.page === 'AddStudyLib' && props.label === 'Type'
        ? items.map((item) => (
            <MenuItem value={item.value} key={item.value} disabled={!item.key}>
              {item.label}
            </MenuItem>
          ))
        : items.map((item) => (
            <MenuItem {...item} value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
    </ApolloSelect>
  );
};

Select.defaultProps = {
  onChange: () => {},
  label: 'Select',
  placeholder: 'Select',
  items: [],
  fullWidth: true
};

Select.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool
};

export default Select;
