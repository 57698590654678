import DatePicker from 'apollo-react/components/DatePicker';
import styled from 'styled-components';
const DatePickerWrapper = styled('span')({
  '& .MuiFormControl-marginNormal': {
    marginTop: 0
  },
  '& .MuiInputBase-root': {
    marginTop: 0
  },
  '& .MuiPopper-root': {
    marginTop: '12px !important'
  }
});

/**
 * Component to render the Date input for filter
 * @props {string} accessor
 * @props {object} filters
 * @props {function} updateFilterValue
 */
export const DateFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <DatePickerWrapper>
      <DatePicker
        size={'small'}
        value={filters[accessor] || ''}
        name={accessor}
        dateFormat={'DD-MMM-YYYY'}
        onChange={(value) =>
          updateFilterValue({
            target: { name: accessor, value }
          })
        }
        placeholder="DD-MMM-YYYY"
      />
    </DatePickerWrapper>
  );
};
