/*eslint-disable */
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import PlusIcon from 'apollo-react-icons/Plus';
import Box from '@mui/material/Box';
// import TerminalIcon from '@mui/icons-material/Terminal';
import Grid from 'apollo-react/components/Grid';
import Card from 'apollo-react/components/Card';
import React from 'react';
import SqlLibraryDatasets from './SqlLibraryDatasets';
import { Link, useNavigate } from 'react-router-dom';
import InfoDataSet from 'Images/info-dataset.svg';
import { useSelector } from 'react-redux';
import Tooltip from 'apollo-react/components/Tooltip';

const useStyles = makeStyles({
  emptyTableHeader: {
    padding: '8px 16px',
    height: '66px',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    border: '1px solid #4442',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    marginTop: '16px'
  },
  card: {
    borderRadius: '0px 0px 4px 4px',
    '& .MuiCardHeader-root': {
      height: '0px',
      padding: '0px'
    }
  },
  icon: {
    filter: 'invert(0.349) hue-rotate(0deg)'
  }
});

const CustomSqlLibrary = (props) => {
  const { editPage, sourcesNotConfigured } = props;
  const { studyObj } = useSelector((state) => state.AddStudyLibrary);
  const { dataSet } = studyObj;
  const classes = useStyles();
  const navigate = useNavigate();
  const { disableAddSQLButton } = useSelector((state) => state.AddStudyLibrary)

  const handleAddNewSQLQuery = () => {
    navigate(
      !editPage
        ? '/data-standard-library/add-study-library/add-new-dataset'
        : '/data-standard-library/edit-study-library/add-new-dataset'
    );
  };
  return (
    <>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        className={classes.emptyTableHeader}>
        <Grid item xs={6}>
          <Typography variant="title1" style={{ fontSize: '16px', marginBottom: '4px' }}>
            Datasets
          </Typography>
          <Typography style={{ fontSize: '14px', fontWeight: '400px' }}>
            {' '}
            {dataSet?.length} Datasets{' '}
          </Typography>
        </Grid>
      </Grid>
      {dataSet?.length ? (
        <>
          {dataSet.map((row) => (
            <SqlLibraryDatasets key={row.queryID} {...row} />
          ))}
          <Box
            flexDirection={'column'}
            style={{
              marginLeft: '48%'
            }}>
            <Box
              style={{
                marginLeft: '-35px'
              }}>
              <Button
                variant="secondary"
                size={'small'}
                style={{
                  padding: '10',
                  height: '40px',
                  radius: '8px'
                }}
                icon={<PlusIcon />}
                onClick={handleAddNewSQLQuery}>
                {'Add SQL query'}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Grid item xs={12}>
            <Card
              style={{ width: '100%', height: '290px', padding: '5%' }}
              className={classes.card}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={InfoDataSet}
                  alt
                  style={{ margin: '10px 0px', cursor: 'pointer' }}
                  className={classes.icon}
                />

                {/* <TerminalIcon fontSize="large" style={{ margin: '10px 0px', cursor: 'pointer' }} /> */}
              </div>
              <Typography
                variant="title2"
                style={{
                  fontSize: '18px',
                  fontWeight: 600,
                  letterSpacing: 0,
                  lineHeight: '22px',
                  color: '#595959',
                  height: 'auto',
                  textAlign: 'center'
                }}>
                No datasets created yet
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: '14px',
                  letterSpacing: 0,
                  lineHeight: '20px',
                  color: '#595959',
                  marginTop: '5px',
                  height: 'auto',
                  textAlign: 'center',
                  margin: '0 33% 0'
                }}>
                Click on “Add SQL Query” to open the SQL editor and compose a query to create a new
                dataset.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2%',
                  marginBottom: '1%'
                }}>
                <Tooltip
                  title={
                    sourcesNotConfigured &&
                    'Add SQL Query will be enabled only when either CDISC ODM or CDR Tabular is configured for the Study'
                  }>
                  <span>
                    <Button
                      variant="secondary"
                      size={'small'}
                      style={{
                        padding: '10',
                        height: '40px',
                        radius: '8px'
                      }}
                      icon={<PlusIcon />}
                      disabled={sourcesNotConfigured || disableAddSQLButton}
                      onClick={handleAddNewSQLQuery}>
                      {'Add SQL query'}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CustomSqlLibrary;
