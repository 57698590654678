import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  switch: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px'
  },
  dragBottomRow: {
    width: '100%',
    borderBottom: '3px solid #0768fd'
  },
  dragTopRow: {
    width: '100%',
    borderTop: '3px solid #0768fd'
  },
  pencilIcon: {
    height: '20px',
    width: '20px',
    color: '#B5B5B5',
    marginRight: '1rem',
    fontSize: '1.2rem'
  },
  editIcon: {
    height: '20px',
    width: '20px',
    opacity: '0.4',
    marginRight: '1rem',
    fontSize: '1.2rem'
  },
  collapse: {
    width: '100%'
  },
  filterType: {
    width: '100%',
    borderRadius: '8px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    color: '#444444'
  },
  accordianSummaryHeight: {
    display: 'flex',
    // height: '104px',
    border: '1px solid #D9D9D9',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    '&> .MuiAccordionSummary-expandIconWrapper': {
      // top: '63px',
      // width: '20px'
    }
  },
  queryContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    marginRight: '-24px',
    color: '#444444'
  },
  accordianSummary: {
    display: 'flex',
    border: '1px solid #D9D9D9',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '0px 10px 0px 32px',
    '&> .MuiAccordionSummary-expandIconWrapper': {
      top: '30px',
      width: '20px'
    }
  },
  filterTypeEntryFields: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '97%'
  },
  filterTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterTypeTitle: {
    padding: '20px 8px 14px 0px',
    fontSize: '18px',
    fontWeight: '600'
  },
  filterExpressionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D9D9D9',
    padding: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    // overflow: 'hidden'
    // height: '214px'
  },
  modal: {
    width: 520
  },
  textFieldContainer: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400'
  },
  textFieldContainer1: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600'
  },
  textField: {
    '& ': {
      marginTop: '7.5px',
      fontSize: '13px',
      lineHeight: '0px'
    }
  },
  customDomainChip: {
    background: '#FECF33',
    color: '#444444',
    position: 'relative',
    borderRadius: '4px',
    marginLeft: '8px',
    padding: '2px 6px 2px 6px',
    fontWeight: '600'
  },
  addNewDomainButtonContainer: {
    borderTop: '1px solid #D9D9D9',
    padding: '0.1rem 0 0 0',
    margin: '0 0 -0.4rem -2rem',
    position: 'sticky',
    bottom: 0,
    background: 'white',
    height: '40px',
    '&:hover': {
    background: '#ecf3ff',
    transition: 'none'
    }
  },
  inlineErrorContainer: {
    margin: '7.5px 16px 0px 0px',
    fontSize: '13px',
    lineHeight: '0px',
    '&> .MuiFormHelperText-root.Mui-error': {
      // marginBottom: '-18px'
    }
  },
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  }
});

export { useStyles };
