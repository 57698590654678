/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Card from './Card';
import FileUpload from 'apollo-react/components/FileUpload';
import {
  jsonToExcel,
  convertExcelToJson,
  jsonToFileData,
  getHeaders,
  uuidv4
} from 'Utils';
import { checkFileData, checkFileType } from '../Utils/FileValidationUtils';
import {
  GetSupplementalQualifiersData,
  DeleteReferenceData,
  GetSDTMDomainPrefix
} from 'Redux/Service/ReferenceDataCardsService';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import _ from 'lodash';
import CustomModal from 'Components/Modal';
import { supplQualValidator } from 'Validators/SupplQual.Validator';
import { passDmData, setNextEnabled } from 'Redux/Slice/RuleEditorSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isModuleRequired } from './RuleEditorUtils';
import { setReferenceData } from 'service/reference-data.service';

const SupplQual = [
  {
    DOMAIN: '',
    VARIABLENAME: '',
    VARIABLELABEL: ''
  }
];

const columns = ['DOMAIN', 'VARIABLENAME', 'VARIABLELABEL'];
const allowedExtensions = ['xlsx', 'xls'];

const SupplementalCard = ({
  handleSQData,
  handleSQValidationData,
  handleSQPreviewFlag,
  handleErrorFlag,
  createMappingObj,
  setMappingError,
  setIsPreviewDataUploaded,
  handleStepperValidate
}) => {
  const { id: mappingRuleVersionId } = useParams();
  const navigate = useNavigate();
  const [SQUploadedFile, setSQUploadedFile] = useState([]);
  const [SQData, setSQData] = useState([]);
  const [SQValidationResult, setSQValidationResult] = useState();
  const [SQPreview, setSQPreview] = useState(true);
  const [error, setError] = useState();
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [domainPrefixes, setDomainPrefixes] = useState([]);
  const [suppQualMessage, setSuppQualMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(false);

  const dispatch = useDispatch();
  const { supplqualFlag, workFlowModel, nextEnabled } = useSelector(
    (state) => state.RuleEditorData
  );

  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  //TODO : uncomment the below and remove the refrence of createMappingObj from props once routing for supp qual in implemented.
  const location = useLocation();
  // const { createMappingObj = {} } = location?.state || {};
  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  useEffect(() => {
    if (Object.keys(createMappingObj).length > 0 && createMappingObj['suppQual']) {
      if (!SQPreview) {
        const element = document.getElementById('anchor-preview');
        if (element) {
          element.click();
          setMappingError(createMappingObj['message']);
          createMappingObj['suppQual'] = false;
        }
      }
    }
  }, [SQPreview]);

  useEffect(() => {
    if (workFlowModel && Object.keys(workFlowModel).length > 0) {
      if (!isModuleRequired(workFlowModel.functionData, currentPath)) {
        dispatch(setNextEnabled(true));
        return;
      }
    }
    if (
      SQData &&
      SQData.length > 0 &&
      SQValidationResult &&
      Object.keys(SQValidationResult).length > 0 &&
      SQValidationResult.getErrorCount() <= 0
    ) {
      dispatch(setNextEnabled(true));
      return;
    }
    dispatch(setNextEnabled(false));
  }, [error, SQValidationResult, SQData, workFlowModel]);

  /**
   * getDomainPrefixes - function to fetch the valid unique domains for the selected target data model
   * @returns {Promise<Array>} domainPrefixes
   */
  const getDomainPrefixes = async () => {
    let _domainPrefixes = [];

    const domainPrefixData = await dispatch(GetSDTMDomainPrefix(rowDataForRuleEditor)).then(
      unwrapResult
    );

    if (domainPrefixData?.data?.success) {
      _domainPrefixes = domainPrefixData.data.domainPrefix;
      setDomainPrefixes(_domainPrefixes);
      dispatch(passDmData(_domainPrefixes));
    }
    return _domainPrefixes;
  };

  /**
   * getSupplQualData - function to fetch the previously saved suppliment qualifiers data
   */
  const getSupplQualData = async () => {
    dispatch(setNextEnabled(false));
    // handleStepperValidate(true);
    let payload = {
      mappingRuleVersionID: mappingRuleVersionId
    };
    let _domainPrefixes = await getDomainPrefixes();

    const SupplQualData = await dispatch(GetSupplementalQualifiersData(payload)).then(unwrapResult);
    if (SupplQualData && SupplQualData.data && SupplQualData.data.success) {
      if (
        SupplQualData.data.supplementalQualifiers &&
        SupplQualData.data.supplementalQualifiers.length > 0
      ) {
        const newData = SupplQualData.data.supplementalQualifiers.map((item) => {
          return {
            domain: item.domainNm,
            variablename: item.variableName,
            variablelabel: item.variableLabel,
            id: uuidv4()
          };
        });
        setSQData(newData);
        let fileObj = jsonToFileData(newData, 'SupplQual.xlsx');
        setSQUploadedFile([fileObj]);
        setSQPreview(false);
        const validationResult = supplQualValidator(newData, _domainPrefixes);
        setSQValidationResult(validationResult);
        const errorCountFlag = validationResult.getErrorCount();
        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        errorCountFlag > 0 ? dispatch(setNextEnabled(false)) : dispatch(setNextEnabled(true));
        handleErrorFlag(errorFlag);
      } else {
        dispatch(setNextEnabled(true));
      }
    } else {
      setSQData([]);
      setSuppQualMessage(SupplQualData.data.message);
      dispatch(showBanner({ variant: 'error', message: SupplQualData.data.message }));
    }
  };

  useEffect(() => {
    getSupplQualData();
  }, [supplqualFlag]);

  const downloadSheet = () => {
    jsonToExcel(SupplQual, 'SupplQual.xlsx');
  };

  const checkColumns = (headers) => {
    let isColumnsValid = true;
    if (columns.length !== headers.length) {
      isColumnsValid = false;
    } else if (JSON.stringify(headers) !== JSON.stringify(columns)) {
      isColumnsValid = false;
    }
    return isColumnsValid;
  };

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const handleUpload = async (selectedFiles) => {
    setUploadProgress(true);
    // dispatch(setNextEnabled(false));
    // handleStepperValidate(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJSON = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);

      const isError = fileValidation(fileJSON, headers);
      if (!isError) {
        let newFileArr = [];
        fileJSON.map((obj) => {
          const newObj = { ...SupplQual[0], ...obj };
          var lowerObj = _.transform(newObj, function (result, val, key) {
            result[key.toLowerCase()] = val;
          });
          lowerObj.id = uuidv4();
          newFileArr.push(lowerObj);
        });
        const validationResult = supplQualValidator(newFileArr, domainPrefixes);

        const errorCountFlag = validationResult.getErrorCount();

        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        handleErrorFlag(errorFlag);
        setSQData(newFileArr);
        // handleSetRows(newFileArr, errorCountFlag);
        setError('');
        setSQUploadedFile([selectedFiles[0]]);
        setSQValidationResult(validationResult);
        handleSQValidationData(validationResult);
        // handleSQValidationData(validationResult, () => {
        handleSQData(newFileArr);
        setSQPreview(true);
        handleSQPreviewFlag(true);
        setIsPreviewDataUploaded(true);
        setReferenceData('supp-qual-data', newFileArr);
        setReferenceData('supp-qual-validation', validationResult);
        navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/supp-qual/preview`);
        // });
      } else {
        setError(isError);
        setSQUploadedFile([]);
        setSQPreview(true);
        setSQData([]);
        // handleSetRows([]);
      }
    }
    setUploadProgress(false);
    // dispatch(setNextEnabled(true));
    // handleStepperValidate(false);
  };

  const handlePreview = () => {
    // handlePreview(true);
    handleSQData(SQData);
    setSQPreview(true);
    handleSQPreviewFlag(true);
    if (!SQValidationResult) {
      const validationResult = supplQualValidator(SQData, domainPrefixes);
      setSQValidationResult(validationResult);
      handleSQValidationData(validationResult);
    } else {
      handleSQValidationData(SQValidationResult);
    }
    navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/supp-qual/preview`);
  };

  const handleDelete = async () => {
    let payload = {
      domainCode: 'SUPPQUAL',
      mappingRuleVersionID: mappingRuleVersionId
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
      setError('');
      setSQUploadedFile([]);
      setSQPreview(true);
      setSQData([]);
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
    setDeleteFile(false);
  };

  const openDeleteModal = () => {
    setDeleteFile(true);
  };

  return (
    <>
      <Card
        required={nextEnabled}
        refMsg={suppQualMessage}
        setErrorFlag={errorFlag}
        handleDownload={downloadSheet}
        isEditDisable={SQPreview}
        handleEditPreview={() => handlePreview()}
        SQData={SQData}>
        <div style={{ margin: '0px 10px' }}>
          {uploadProgress && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div>
            <FileUpload
              disabled={uploadProgress}
              value={SQUploadedFile}
              onUpload={handleUpload}
              onFileDelete={openDeleteModal}
              // disabled={isFileUploadDisable}
              label="Upload Supplemental Qualifiers"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
              data-testid="file-upload"
              //   {...this.props}
            />
          </div>

          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={deleteFile}
        // data={row}
        title={'Delete File'}
        buttonPrimaryLabel={'Ok'}
        message={'Are you sure you would like to delete this file?'}
        handlePrimaryAction={() => handleDelete()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => setDeleteFile(false)}
        variant={'warning'}
      />
    </>
  );
};

export default SupplementalCard;
