/*eslint-disable*/
import CircularProgress from '@mui/material/CircularProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import FileUpload from 'apollo-react/components/FileUpload';
import CustomModal from 'Components/Modal';

import _ from 'lodash';
import Grid from 'apollo-react/components/Grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteReferenceData, getTrialIE } from 'Redux/Service/ReferenceDataCardsService';
import { convertExcelToJson, getHeaders, jsonToExcel, jsonToFileData, uuidv4 } from 'Utils';
import { trialIEValidator } from 'Validators/TrialIE.Validator';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Card from './Card';
import './Trials.css';
import { useNavigate, useParams } from 'react-router-dom';
import { setReferenceData } from 'service/reference-data.service';
import { checkColumns, checkFileData, checkFileType } from '../Utils/FileValidationUtils';
const trialIEColumn = [
  {
    IETESTCD: '',
    IETEST: '',
    IECAT: '',
    IESCAT: '',
    IERL: '',
    IEVERS: ''
  }
];
// let initialItems = [
//   { name: 'Trial Visit', module: 'trial-visits', order: 1, required: false, visible: true },
//   { name: 'Time Point', module: 'time-points', order: 2, required: false, visible: true },
//   { name: 'Trial Arms', module: 'trial-arms', order: 6, required: false, visible: false },
//   { name: 'Trial IE', module: 'trial-IE', order: 3, required: false, visible: false },
//   { name: 'Trial Elements', module: 'trial-elements', order: 4, required: false, visible: false },
//   { name: 'Code List', module: 'code-lists', order: 5, required: false, visible: false }
// ];

const columns = ['IETESTCD', 'IETEST', 'IECAT', 'IESCAT', 'IERL', 'IEVERS'];

const allowedExtensions = ['xlsx', 'xls'];

const TrialIE = (props) => {
  const { id: mappingRuleVersionId } = useParams();
  const navigate = useNavigate();
  const [cards, setCardArray] = useState([]);
  const [trialIEValidationResult, setTrialIEValidationResult] = useState({});
  const [trialIEData, setTrialIEData] = useState([]);
  const [mappingError, setMappingError] = useState('');
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  const [trialIEUploadedFile, setTrialIEUploadedFile] = useState([]);

  const [trialIEPreview, setTrialIEPreview] = useState(true);
  const [error, setError] = useState();
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [trialIEMessage, setTrialIEMessage] = useState('');
  const dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(false);
  const [isValidData, setIsValidData] = useState(false);

  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  const isNull = (value) => {
    if (value === null) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['TrialIE']) {
      if (!trialIEPreview) {
        const element = document.getElementById('anchor-preview');
        element.click();
        setMappingError(props.createMappingObj['message']);
        props.createMappingObj['TrialIE'] = false;
      }
    }
  }, [trialIEPreview]);

  const confirmOnDelete = () => {
    trialIEUploadedFile.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          onConfirm: () => {
            deleteUploadedFile();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'TI',
      mappingRuleVersionID: mappingRuleVersionId
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
    setTrialIEUploadedFile([]);
    setTrialIEPreview(true);
    setTrialIEData([]);
    // cardItems.push({ name: 'Trial IE', module: 'trial-IE' });
    // setCardItems(cardItems);
    const index = cards.findIndex((el) => el === 'trial-IE');
    cards.splice(index, 1);

    props.configuration.setCardVisibility('trial-IE', false);

    // props.triggerGetApi();
  };

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingRuleVersionId
      };
      const trialIEGetAPIData = await dispatch(getTrialIE(payload)).then(unwrapResult);
      props.configuration.setIsCompleted('trial-IE', true);
      props.configuration.setModuleValidation('trial-IE', true);
      if (trialIEGetAPIData && trialIEGetAPIData.data && trialIEGetAPIData.data.success) {
        if (
          trialIEGetAPIData.data.trialinclusionExclusions &&
          trialIEGetAPIData.data.trialinclusionExclusions.length > 0
        ) {
          setError('');
          const newData = trialIEGetAPIData.data.trialinclusionExclusions.map((item) => {
            return {
              ietestcd: item.ietestcd,
              ietest: item.ietest,
              iecat: item.iecat,
              iescat: item.iescat,
              ierl: item.ierl,
              ievers: item.ievers,
              id: uuidv4()
            };
          });
          const validationResult = trialIEValidator(newData);
          const errorCountFlag = validationResult.getErrorCount();
          errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
          setTrialIEValidationResult(validationResult);
          setTrialIEData(newData);
          let fileObj = jsonToFileData(newData, 'TrialIE.xlsx');
          setTrialIEUploadedFile([fileObj]);
          setTrialIEPreview(false);
          setIsValidData(true);
          props.configuration.setModuleValidation('trial-IE', errorCountFlag > 0 ? false : true);
          props.configuration.setCardVisibility('trial-IE', true);
          // if (ref && ref.current) {
          //   ref.current.style.display = 'block';
          // }
        } else {
          props.required && props.configuration.setModuleValidation('trial-IE', false);
          setTrialIEData([]);
        }
      } else {
        setTrialIEMessage(trialIEGetAPIData.data.message);
        setTrialIEData([]);
        setIsValidData(false);
        // dispatch(showBanner({ variant: 'error', message: trialIEData.data.message }));
      }
    })();
  }, []);

  const downloadSheet = () => {
    jsonToExcel(trialIEColumn, 'TrialIE.xlsx');
    // setIsFileUploadDisable(false);
  };

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const handleUpload = async (selectedFiles) => {
    setUploadProgress(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJSON = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);

      const isError = fileValidation(fileJSON, headers);
      if (!isError) {
        let newFileArr = [];
        fileJSON.map((obj) => {
          const newObj = { ...trialIEColumn[0], ...obj };
          var lowerObj = _.transform(newObj, function (result, val, key) {
            result[key.toLowerCase()] = val;
          });
          lowerObj.id = uuidv4();
          newFileArr.push(lowerObj);
        });
        const validationResult = trialIEValidator(newFileArr);
        const errorCountFlag = validationResult.getErrorCount();

        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);

        setTrialIEData(newFileArr);
        setTrialIEValidationResult(validationResult);
        setError('');
        setTrialIEUploadedFile([selectedFiles[0]]);
        setTrialIEPreview(false);
        setReferenceData('trial-IE', newFileArr);
        setReferenceData('trial-IE-validationResult', validationResult);
        setReferenceData('trial-IE-errorCount', validationResult.getErrorCount());
        setReferenceData('trial-IE-ErrorRowId', validationResult.getErrorRowId());
        navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/trial-IE`);
      } else {
        // props.configuration.setModuleValidation('trial-IE', false, isError);
        setError(isError);
        setTrialIEUploadedFile([]);
        setTrialIEPreview(true);
        setTrialIEData([]);
      }
    }
    setUploadProgress(false);
  };
  // const handleCardsChange = (e) => {
  //   setCardArray([...cards, e.target.value]);
  //   const copyItems = [...items];
  //   setItems(copyItems.filter((el) => el.value !== e.target.value));
  // };

  const handleEditPreview = (isUploaded) => {
    if (isUploaded || trialIEData) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/trial-IE`);
    }
  };

  const handleDelete = () => {
    deleteUploadedFile();
    setDeleteFile(false);
    setError('');
  };

  const openDeleteModal = () => {
    setDeleteFile(true);
  };

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        required={props.required}
        refMsg={trialIEMessage}
        handleDownload={downloadSheet}
        isEditDisable={trialIEPreview}
        setErrorFlag={errorFlag}
        handleEditPreview={() => handleEditPreview()}>
        <div style={{ margin: '0px 10px' }}>
          {uploadProgress && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={uploadProgress}
              value={trialIEUploadedFile}
              onUpload={handleUpload}
              onFileDelete={openDeleteModal}
              // disabled={isFileUploadDisable}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
              //   {...this.props}
            />
          </div>

          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </Grid>
  );
};

export default TrialIE;
