import CircularProgress from '@mui/material/CircularProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import FileUpload from 'apollo-react/components/FileUpload';
import CustomModal from 'Components/Modal';
import Grid from 'apollo-react/components/Grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteReferenceData,
  GetFormRepeatFlag,
  GetFormSheetsData
} from 'Redux/Service/ReferenceDataCardsService';
import { convertExcelToJson, getHeaders, jsonToExcel, jsonToFileData, uuidv4 } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Card from './Card';
import './Trials.css';
import { useNavigate, useParams } from 'react-router-dom';
import { setReferenceData } from 'service/reference-data.service';
import { checkFileType, fileValidation } from '../Utils/FileValidationUtils';
import { formsheetColumnNames } from './FormsheetUtils';
import { GetSDTMDomainPrefix } from 'Redux/Service/ReferenceDataCardsService';
import { formSheetValidator } from 'Validators/FormSheet.Validator';

const columns = Object.keys(formsheetColumnNames);
const emptyFormSheetRow = Object.keys(formsheetColumnNames).reduce((acc, key) => {
  acc[key] = '';
  return acc;
}, {});

const FormSheet = (props) => {
  const [uploadProgress, setUploadProgress] = useState(false);
  const [formSheetUploadedFile, setFormSheetUploadedFile] = useState([]);
  const [error, setError] = useState();
  const [errorFlag, setErrorFlag] = useState(false);
  const [formSheetPreview, setFormSheetPreview] = useState(true);
  const [formSheetData, setFormSheetData] = useState([]);
  const [formSheetMessage, setFormSheetMessage] = useState('');
  const [allForms, setAllForms] = useState([]);
  const [domainPrefixes, setDomainPrefixes] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: mappingRuleVersionId } = useParams();
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const FORMS_SHEET = 'forms-sheet';
  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  const getFormNameAndFormRepeat = async () => {
    let formNameAndRepeats = [];
    let payload = {
      mappingRuleVersionID: mappingRuleVersionId
    };
    const response = await dispatch(GetFormRepeatFlag(payload)).then(unwrapResult);
    if (response?.data?.success) {
      formNameAndRepeats = response.data.formsDetails || [];
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
    return formNameAndRepeats;
  };

  const getDomainPrefixes = async () => {
    let domainPrefixes = [];
    const response = await dispatch(GetSDTMDomainPrefix(rowDataForRuleEditor)).then(unwrapResult);
    if (response?.data?.success) {
      domainPrefixes = response.data.domainPrefix || [];
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
    return domainPrefixes;
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'FORM_SHEET',
      mappingRuleVersionID: mappingRuleVersionId
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
      setFormSheetUploadedFile([]);
      setFormSheetPreview(true);
      setFormSheetData([]);
      props.configuration.setCardVisibility(FORMS_SHEET, false);
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
  };

  const confirmOnDelete = () => {
    formSheetUploadedFile.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          onConfirm: () => {
            deleteUploadedFile();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  const downloadSheet = () => {
    const allRows =
      allForms?.length > 0
        ? allForms.map((item) => {
            let formsheetItem = { ...emptyFormSheetRow };
            formsheetItem[formsheetColumnNames['formName']] = item.formName;
            formsheetItem[formsheetColumnNames['formRepeatFlag']] = item.formRepeatFlag;
            return formsheetItem;
          })
        : [emptyFormSheetRow];

    jsonToExcel(allRows, 'FormsSheet.xlsx');
  };

  const handleUpload = async (selectedFiles) => {
    setUploadProgress(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJSON = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);
      const isError = fileValidation(fileJSON, headers, columns);
      if (!isError) {
        let newFileArr = [];
        fileJSON.map((obj) => {
          const newObj = { ...emptyFormSheetRow, ...obj };
          let lowerObj = Object.keys(newObj).reduce((acc, key) => {
            acc[formsheetColumnNames[key]] = obj[key];
            return acc;
          }, {});
          lowerObj.id = uuidv4();
          newFileArr.push(lowerObj);
        });
        const validationResult = formSheetValidator(newFileArr, allForms, domainPrefixes);
        const errorCountFlag = validationResult.getErrorCount();
        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        setFormSheetData(newFileArr);
        setError('');
        setFormSheetUploadedFile([selectedFiles[0]]);
        setFormSheetPreview(false);
        setReferenceData('forms-sheet', newFileArr);
        setReferenceData('forms-sheet-validationResult', validationResult);
        setReferenceData('forms-sheet-errorCount', validationResult.getErrorCount());
        setReferenceData('forms-sheet-errorRowId', validationResult.getErrorRowId());
        navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/forms-sheet`);
      } else {
        props.configuration.setModuleValidation(FORMS_SHEET, false, isError);
        setError(isError);
        setFormSheetUploadedFile([]);
        setFormSheetPreview(true);
        setFormSheetData([]);
      }
    }
    setUploadProgress(false);
  };

  const handleEditPreview = (isUploaded) => {
    if (isUploaded || formSheetData) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionId}/forms-sheet`);
    }
  };

  useEffect(() => {
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['FormSheet']) {
      if (!formSheetPreview) {
        const element = document.getElementById('anchor-preview');
        element.click();
        props.createMappingObj['FormSheet'] = false;
      }
    }
  }, [formSheetPreview]);

  useEffect(() => {
    (async () => {
      const _allForms = await getFormNameAndFormRepeat();
      setAllForms(_allForms);
      const _domainPrefixes = await getDomainPrefixes();
      setDomainPrefixes(_domainPrefixes);

      let payload = {
        mappingRuleVersionID: mappingRuleVersionId
      };
      const formSheetGetAPIData = await dispatch(GetFormSheetsData(payload)).then(unwrapResult);
      props.configuration.setIsCompleted(FORMS_SHEET, true);
      props.configuration.setModuleValidation(FORMS_SHEET, true);
      if (formSheetGetAPIData?.data?.success) {
        if (formSheetGetAPIData.data.formSheet?.length > 0) {
          setError('');
          const newData = formSheetGetAPIData.data.formSheet.map((item) => {
            let formsheetItem = {};
            for (let apiLabel of Object.values(formsheetColumnNames)) {
              formsheetItem[apiLabel] = item[apiLabel];
            }
            formsheetItem.id = uuidv4();
            return formsheetItem;
          });

          const validationResult = formSheetValidator(newData, _allForms, _domainPrefixes);
          const errorCountFlag = validationResult.getErrorCount();
          errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
          setFormSheetData(newData);
          let fileObj = jsonToFileData(newData, 'FormsSheet.xlsx');
          setFormSheetUploadedFile([fileObj]);
          setFormSheetPreview(false);
          props.configuration.setModuleValidation(FORMS_SHEET, errorCountFlag > 0 ? false : true);
          props.configuration.setCardVisibility(FORMS_SHEET, true);
        } else {
          props.required && props.configuration.setModuleValidation(FORMS_SHEET, false);
          setFormSheetData([]);
        }
      } else {
        setFormSheetMessage(formSheetGetAPIData.data.message);
        setFormSheetData([]);
      }
    })();
  }, []);

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        required={props.required}
        refMsg={formSheetMessage}
        setErrorFlag={errorFlag}
        handleDownload={downloadSheet}
        isEditDisable={formSheetPreview}
        handleEditPreview={() => handleEditPreview()}>
        <div style={{ margin: '0px 10px' }}>
          {uploadProgress && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={uploadProgress}
              value={formSheetUploadedFile}
              onUpload={handleUpload}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
            />
          </div>
          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={'Ok'}
        handlePrimaryAction={() => confirmAlert?.onConfirm()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => confirmAlert?.onCancel()}
      />
    </Grid>
  );
};

export default FormSheet;
