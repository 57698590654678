import { Box } from 'apollo-react';
import { neutral3, red } from 'apollo-react/colors';

export const VLCErrorDetails = ({ rulesWithError = [] }) => {
  if (rulesWithError?.length)
    return (
      <Box
        sx={{
          borderLeft: '4px solid #E20000',
          backgroundColor: '#FCEBEB',
          borderRadius: '4px',
          width: '100%',
          padding: '1rem',
          mt: 2,
          whiteSpace: 'pre-line'
        }}>
        {rulesWithError.length} VLC Rules are having errors.{'\n'}
        {rulesWithError.join(', ')}
      </Box>
    );
  else return <></>;
};

export const ExpressionErrorDetails = ({ message }) => {
  if (message)
    return (
      <Box
        component={'div'}
        sx={{
          whiteSpace: 'pre',
          fontFamily: 'Menlo, Courier New, Monospace',
          px: '22px',
          py: '10px',
          backgroundColor: neutral3,
          color: red,
          textWrap: 'wrap',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px'
        }}
        dangerouslySetInnerHTML={{ __html: message }}
        data-testid="error-textbox"
      />
    );
  else return <></>;
};
