/*eslint-disable*/
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import DataProductSummary from 'Pages/Dashboard/DataProductSummary/DataProductSummary';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import 'Pages/Dashboard/ComponentDashboard/Component/Dashboard.css';
import Card from 'apollo-react/components/Card';
import StatusFailure from 'Images/status-failure.svg';
import StatusQueued from 'Images/status-queued.svg';
import Button from 'apollo-react/components/Button';
import { GetDashBoardCardData, GetDataProductSummary } from 'Redux/Service/DashboardService';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import ButtonGroup from 'apollo-react/components/ButtonGroup';
import { dateFormatByType, uuidv4 } from 'Utils';
import { setReloadData, setRowdata, passCardName, setIsAll } from 'Redux/Slice/DashboardSlice';
import { compareQueueStatus } from 'Pages/Dashboard/DataProductSummary/Components/columns';
import Cookies from 'js-cookie';

import IconButton from 'apollo-react/components/IconButton';
import Tooltip from 'apollo-react/components/Tooltip';
import { closeLoader, showLoader } from 'Redux/Slice/LoaderSlice';
import { Refresh, StatusCheck, StatusExclamation, Info } from 'apollo-react-icons';
import BookLibrary from 'Images/book-library.svg';
import StudySpecificDataPipeline from './StudySpecificDataPipeline';
import { setDashboardApis } from 'Redux/Slice/DashboardSlice';
import { openDataPipelinePanel } from 'Redux/Slice/PublishMappingSpecSlice';
import { setClosePanelText } from 'Redux/Slice/StudyLibrarySlice';

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};
const EmptyTableTypographyStyleTitle2 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '5px'
};

const statusStyle = {
  height: '20px',
  width: '20px'
};

const imageStyle = {
  // backgroundImage: `url(${bgImg})`,
  backgroundSize: '100%',
  // backgroundRepeat: 'no-repeat',
  margin: '0 auto',
  background: 'linear-gradient(270deg, #0076AE 0%, #250056 100%'
};

const titleStyle = {
  margin: '0.5rem 0 0 0.5rem',
  color: '#000000',
  fontFamily: '"Proxima Nova"',
  fontSize: '16px',
  fontWeight: '600',
  letterSpacing: '0',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center'
};

const dataStyle = {
  color: '#000000',
  fontFamily: 'Proxima Nova',
  fontSize: '36px',
  fontWeight: '500',
  letterSpacing: '0',
  lineHeight: '36px',
  textAlign: 'center'
};

const MAP_LOADER_RUN_TYPE = {
  INCR: 'Incremental',
  RELOAD: 'Reload'
};

const transformDataProductSummaries = (response) => {
  let transformedRecords = response.sort(compareQueueStatus('queueStatusMessageLable', 'desc'));

  transformedRecords = transformedRecords.map((item) => ({
    ...item,
    status: item.status,
    createdDate: dateFormatByType(item.createdDate, ''),
    startTime: dateFormatByType(item.startTime, 'Table'),
    endTime: dateFormatByType(item.endTime, 'Table'),
    dataSources: item.dataSources.toString(),
    loaderRunType: MAP_LOADER_RUN_TYPE[item.loaderRunType || item.runType]
  }));

  return transformedRecords;
};

const StudyDashboard = () => {
  const dispatch = useDispatch();
  const userLoginID = Cookies.get('user.id');
  const { rowData, isAllFlag, cardName, callDashboardApis } = useSelector(
    (state) => state.DashboardSliceData
  );
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const [cardsData, setCards] = useState({});
  const [defaultDataGrid, setDefaultDataGrid] = useState([]);
  const [filteredCardList, setFilteredCardList] = useState([]);
  const navigate = useNavigate();
  const summary_path = '/dashboard/data-product-summary';
  const dashboard_path = '/dashboard';

  const cards = [
    {
      id: 1,
      data: cardsData.noOfProducts,
      // path: summary_path,
      title: 'Data Product Pipelines',
      icon: <StatusCheck style={{ ...statusStyle, ...{ color: '#00C221' } }} />,
      name: 'View Data Product Pipelines',
      filter: 'all',
      tooltipDesc:
        'Count of the Active Data Products, ie, All the Data Products associated with the Published Mapping specs.'
    },
    {
      id: 2,
      data: cardsData.noOfRefreshAlert,
      name: 'View Data Refresh Alerts',
      // path: summary_path,
      icon: <img src={StatusFailure} style={{ ...statusStyle, ...{ color: '#E20000' } }} />,
      title: 'Data Refresh Alerts',
      filter: 'failed',
      tooltipDesc:
        'Count of all Mapping Specs for each environment whose Latest Job Status is Failed.'
    },
    {
      id: 3,
      data: cardsData.noOfInQueue,
      name: 'View Items In Queue',
      title: 'In Queue',
      // path: summary_path,
      icon: <img src={StatusQueued} style={{ ...statusStyle, ...{ color: '#E20000' } }} />,
      filter: 'queued',
      tooltipDesc:
        'Count of all Mapping Specs for each environment whose Latest Job Status is In Queue.'
    },
    {
      id: 4,
      name: 'View Data Latency Warnings',
      icon: <StatusExclamation style={{ ...statusStyle, ...{ color: '#FF9300' } }} />,
      title: 'Data Latency Warnings',
      data: cardsData.noOfDataLatency,
      // path: summary_path,
      filter: 'Data Latency Warnings',
      tooltipDesc:
        'Count of all Incremental Jobs which Breached the SLA (SLA = 3 hours) for the Latest Job Run.'
    }
  ];

  useEffect(() => {
    dispatch(setReloadData({ type: '', selectedRow: {} }));
    dispatch(passCardName(''));
    // dispatch(setClosePanelText(false));
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Manage Data Pipelines',
          path: '/manage-data-pipeline'
        }
      ])
    );
  }, []);

  // const isDashboard = location.pathname === dashboard_path;

  const getDataProductSummaryGrid = async () => {
    dispatch(showLoader());
    dispatch(setDashboardApis(false));
    // commenting below line to avaoid  closure of Confirm message on auto refresh
    // dispatch(setReloadData({ type: '', selectedRow: {} }));
    let payload = {
      userId: Cookies.get('user.id'),
      filters: [],
      protocolName: protocol.protocolNumber
    };
    const summaryData = await dispatch(GetDataProductSummary(payload)).then(unwrapResult);
    if (summaryData.data.success) {
      const productSummaryData = summaryData.data.dataProductSummaries;
      const protocolAuth = summaryData.data.protocolAuth;
      const protocolAuthKeys = Object.keys(protocolAuth);
      // Added new key in the productSummaryData object
      // from the protocolAuth Object
      // with key name as protocolNumber
      productSummaryData.forEach((el) => {
        if (protocolAuthKeys.includes(el.protocolNumber)) {
          el[el.protocolNumber] = protocolAuth[el.protocolNumber];
        }
        el.isActivePipeline = el.isActivePipeline === true ? 'Active' : 'Inactive';
        el.endTime = dateFormatByType(el.endTime, 'Table');
        el.startTime = dateFormatByType(el.startTime, 'Table');
        el.createdDate = dateFormatByType(el.createdDate);
        el.key = uuidv4();
        el.dataSources = el.dataSources.toString();
        el.trialName = el.trialName.toString();
        el.loaderRunType= MAP_LOADER_RUN_TYPE[el.loaderRunType || el.runType]
      });

      // setDefaultDataGrid(productSummaryData);
      getFormattedData(productSummaryData);
      dispatch(closeLoader());
    } else {
      dispatch(showBanner({ variant: 'error', message: summaryData.data.message }));
    }

    dispatch(closeLoader());
  };

  // const getFormattedData = (data, skipFilters = false) => {
  //     let formatedData = transformDataProductSummaries(data);
  //     if (!skipFilters) {
  //         if (!isAllFlag) {
  //             formatedData = formatedData.filter((row) => row.environment === 'PROD');
  //         }

  //         if (cardName === '') {
  //             formatedData = formatedData.slice(0, 10);
  //         }
  //     }
  //     dispatch(setRowdata(formatedData));
  // };

  const getDashboardCardData = async (protocolObj) => {
    dispatch(showLoader());
    dispatch(setDashboardApis(false));
    try {
      dispatch(setRowdata([]));
      let dashboardCardData;
      dashboardCardData = await dispatch(GetDashBoardCardData(protocolObj)).then(unwrapResult);
      if (dashboardCardData?.data?.success) {
        let data = dashboardCardData.data.dashboardCards;
        setCards(data);
        await getDataProductSummaryGrid();
      } else {
        dispatch(showBanner({ variant: 'error', message: dashboardCardData.data.message }));
      }
      dispatch(closeLoader());
      return true;
    } catch (err) {
      dispatch(closeLoader());
      console.log(err);
      return false;
    }
  };
  console.log(callDashboardApis, 'lllll');

  useEffect(() => {
    if (Object.keys(protocol).length > 0) {
      dispatch(setReloadData({ type: '', selectedRow: {} }));
      getDashboardCardData({ existingFlag: isAllFlag, protocolName: protocol.protocolNumber });
    }
  }, [isAllFlag, protocol]);

  useEffect(() => {
    callDashboardApis && getDataProductSummaryGrid();
  }, [callDashboardApis]);

  const navigateToSummaryPage = (cardFilter) => {
    dispatch(passCardName(cardFilter));
    if (cardFilter === 'all') {
      getDataProductSummaryGrid();
    }
    // onViewAll();
  };

  const getFormattedData = (defaultDataGrid) => {
    let filteredList = [];
    if (!isAllFlag) {
      filteredList = defaultDataGrid.filter((item) => item.environment === 'PROD');
    } else {
      filteredList = defaultDataGrid.filter((item) =>
        cardName === '' ? item : item.queueStatusType === cardName
      );
    }
    setDefaultDataGrid(filteredList);
  };

  useEffect(() => {
    let filteredList = [];
    if (cardName !== '') {
      filteredList = defaultDataGrid.filter((item) =>
        isAllFlag
          ? item.queueStatusType === cardName
          : item.queueStatusType === cardName && item.environment === 'PROD'
      );
      setDefaultDataGrid(filteredList);
    }
    console.log(filteredList, 'grid');
    // setDefaultDataGrid(filteredList)
  }, [cardName, isAllFlag]);

  return Object.keys(protocol).length > 0 ? (
    <>
      <div className="banner-style" style={imageStyle}>
        <Grid container style={{ padding: '0 1.5rem 0 1.5rem', justifyContent: 'center' }}>
          <Grid item xs={7}>
            <ButtonGroup
              style={{ justifyContent: 'end' }}
              buttonProps={[
                {
                  label: 'All',
                  onClick: () => dispatch(setIsAll(true)),
                  variant: isAllFlag ? 'primary' : 'secondary',
                  style: {
                    borderRadius: 0,
                    borderBottomLeftRadius: '4px',
                    borderTopLeftRadius: '4px',
                    width: '8rem'
                  }
                },
                {
                  label: 'Production',
                  onClick: () => dispatch(setIsAll(false)),
                  variant: !isAllFlag ? 'primary' : 'secondary',
                  style: {
                    borderRadius: 0,
                    margin: 0,
                    borderBottomRightRadius: '4px',
                    borderTopRightRadius: '4px',
                    width: '8rem'
                  }
                }
              ]}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              style={{ float: 'right', textAlign: 'right', marginTop: '5px' }}
              variant="secondary"
              icon={<Refresh />}
              size="small"
              onClick={() =>
                getDashboardCardData({
                  existingFlag: isAllFlag,
                  protocolName: protocol.protocolNumber
                })
              }>
              Refresh
            </Button>
          </Grid>
        </Grid>

        <Grid container style={{ justifyContent: 'center' }} className="cards-row">
          {cards.map((card, index) => (
            <Card
              className="card-block"
              style={{
                minWidth: '250px',
                maxWidth: '16%',
                minHeight: '200px'
              }}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ padding: '1.2rem' }}>
                  <Typography style={titleStyle} variant="body2">
                    {card.title}
                    <Tooltip
                      variant="dark"
                      title={card.tooltipDesc}
                      placement="bottom"
                      style={{ height: '1rem', width: '1rem', marginLeft: '0.5rem' }}>
                      <IconButton color="neutral7">
                        <Info style={{ width: '16px', maxHeight: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px 0'
                  }}>
                  {card.filter !== 'all' && (
                    <div style={{ paddingTop: '1px', paddingRight: '5px', marginTop: '5px' }}>
                      {card.icon}
                    </div>
                  )}
                  <div style={dataStyle}>{card.data}</div>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    size="small"
                    style={{ marginRight: 10 }}
                    onClick={() => navigateToSummaryPage(card.filter)}>
                    {card.name}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      </div>
      <div className="dashboard-wrapper">
        <StudySpecificDataPipeline
          // path={isDashboard}
          // onViewAll={onViewAll}
          rowData={defaultDataGrid}
          getDataProductSummaryGrid={getDataProductSummaryGrid}
          // hidePagination
        />
      </div>
    </>
  ) : (
    <Grid
      container
      style={{
        display: 'grid',
        placeItems: 'center',
        position: 'relative',
        padding: '13rem'
      }}>
      <img height="23" src={BookLibrary} style={{ marginBottom: '1rem' }} />
      <Grid xs={6}>
        <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
          Nothing to See Here
        </Typography>
      </Grid>
      <Grid xs={6}>
        <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
          Nothing to see here. Select a protocol to view data
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StudyDashboard;
