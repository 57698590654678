import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from 'apollo-react/components/TextField';
import Box from 'apollo-react/components/Box';
import Modal from 'apollo-react/components/Modal';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Banner from 'apollo-react/components/Banner';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { getDomainInfoFormattedForSavePreference } from '../DataExportUtils';
import { useDispatch, useSelector } from 'react-redux';
import { SaveSelectedPreferencesData } from 'Redux/Service/DataExportService';
import { unwrapResult } from '@reduxjs/toolkit';
import { setSelectedPreferences } from 'Redux/Slice/PublishMappingSpecSlice';
import Cookies from 'js-cookie';
import { showBanner } from 'Redux/Slice/BannerSlice';

const useStyles = makeStyles({
  modal: {
    maxWidth: '100%',
    height: '190vh'
  },
  containerModal: {
    '&>div:nth-child(2)>div:nth-child(1)': {
      borderBottom: '1px solid #D9D9D9',
      paddingBottom: '1rem',
      marginBottom: '1rem'
    },
    '&>div:nth-child(2)': {
      padding: '0px'
    },
    '&>div>div:nth-child(2)': {
      paddingBottom: '2px'
    }
  }
});

function SavePreference(props) {
  const { setIsApplyPreferenceConfig, appliedExportPreference, setAppliedExportPreference } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [preferenceName, setPreferenceName] = useState('');
  const [description, setDescription] = useState('');
  const [banner, setBanner] = useState({ enabled: false, variant: 'error', message: '' });
  const { mappingSpecDetails, exportTypeMetaData, scheduleFrequencies, selectedPreferences } =
    useSelector((state) => state.PublishMappingSpecData);
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClose = () => {
    !isLoading && setIsApplyPreferenceConfig(false);
  };
  const saveSelectedPreferencesData = async () => {
    setIsLoading(true);
    const newExportPreference = JSON.parse(JSON.stringify(appliedExportPreference || {}));
    if (Object.keys(newExportPreference).length) {
      const exportPrefvalue = [];
      const domainAndVariables = getDomainInfoFormattedForSavePreference(
        Object.values(newExportPreference.domainAndColumns || {})
      );
      if (domainAndVariables.length) {
        exportPrefvalue.push({
          preferenceXrefid: '',
          preferenceId: '',
          domainAndVariables
        });
      }

      const exportTypeID =
        exportTypeMetaData?.find(
          (exportType) => exportType.exportTypeName === newExportPreference?.exportType
        )?.exportTypeId || '';
      const scheduleTypeID =
        scheduleFrequencies?.find(
          (exportType) => exportType.scheduleFrequency === newExportPreference?.scheduleType
        )?.scheduleTypeID || '';

      const payload = {
        exportPreference: {
          preferenceId: '',
          preferenceName: preferenceName,
          preferenceDesc: description,
          protocolNumber: mappingSpecDetails.protocolName,
          dataProductId: mappingSpecDetails.productID,
          suppQualPreferenceKey: newExportPreference.suppQualPreferenceKey,
          exportTypeID: exportTypeID,
          scheduleTypeID: scheduleTypeID,
          iqActiveFlag: true,
          iqAuditType: 'INSERT',
          iqAuditDate: new Date().toISOString(),
          iqCreatedBy: Cookies.get('user.id')
        },
        exportPreferencesData: exportPrefvalue
      };
      const response = await dispatch(SaveSelectedPreferencesData(payload)).then(unwrapResult);
      if (response?.data?.success) {
        setIsApplyPreferenceConfig(false);
        dispatch(
          setSelectedPreferences({
            ...selectedPreferences,
            [newExportPreference.destination]: {
              exportType: newExportPreference?.exportType || exportTypeMetaData[0].exportTypeName,
              scheduleType: newExportPreference?.scheduleType || '',
              suppQualPreferenceKey: newExportPreference.suppQualPreferenceKey,
              domainInformation: newExportPreference.domainAndColumns
            }
          })
        );
        dispatch(
          showBanner({
            variant: 'success',
            message: `Preference ${preferenceName} has been saved successfully.`
          })
        );
        setPreferenceName('');
        setDescription('');
        setAppliedExportPreference({});
        setBanner({
          enabled: false,
          variant: '',
          message: ''
        });
      } else {
        setBanner({
          enabled: true,
          variant: 'error',
          message: response.data.message
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open={true}
      variant="success"
      onClose={handleClose}
      title="Success"
      buttonProps={[
        {
          label: 'Close',
          onClick: () => handleClose(),
          variant: 'primary',
          disabled: isLoading
        }
      ]}
      id="warning"
      width={classes.modal}
      className={classes.containerModal}>
      <Box style={{ paddingLeft: '1.5rem' }}>
        <Grid container xs={12}>
          <Banner
            variant={banner.variant}
            open={banner.enabled}
            onClose={() => setBanner({ enabled: false, variant: 'info', message: '' })}
            message={banner.message}
            style={{ position: 'absolute' }}
            TransitionComponent={Fade}
          />
          <Grid xs={9}>
            <Typography>{`Preferences for ${appliedExportPreference.destination} has been applied.`}</Typography>
          </Grid>
          <Grid xs={10} style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>
            <Typography>
              {'Would you like to save this preference for future use? (Optional)'}
            </Typography>
          </Grid>
          <Grid xs={10}>
            <Typography variant="caption">
              {`Saved preferences can be accessed in a preference list using 'load preference' for
              convenient access and reuse.`}
            </Typography>
          </Grid>
          <Grid xs={9}>
            <TextField
              label="Preferences Name"
              placeholder="Enter Text"
              fullWidth
              value={preferenceName}
              onChange={(event) => setPreferenceName(event.target.value)}
            />
          </Grid>
          <Grid xs={9} style={{ paddingTop: '0px' }}>
            <TextField
              label="Description"
              placeholder="Describe your preference configuration"
              fullWidth
              sizeAdjustable
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
          <Grid style={{ paddingTop: '1rem' }}>
            <Button
              variant="secondary"
              disabled={isLoading || preferenceName.length < 1}
              onClick={saveSelectedPreferencesData}
              icon={isLoading && <CircularProgress size={16} sx={{ mr: '5px' }} />}>
              Add to Preference List
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default SavePreference;
