import { AddGlobalLibrary } from './AddGlobalLibrary/AddGlobalLibrary';
import SideBarLayout from 'Layout/SideBarLayout';
import AddSQLQueryLayout from 'Layout/AddSQLQueryLayout';
import StudyLibrary from './GlobalStudyLibrary/Components/StudyLibrary';
import StudyConfigLibrary from './GlobalStudyLibrary/Components/StudyConfigLibrary';
import GlobalLibrary from './DataStandardLibraryMain/Components/GlobalLibrary';
import AddStudyLibrary from './DataStandardLibraryMain/Components/AddStudyLibrary';
import AddNewDataSet from './DataStandardLibraryMain/Components/AddNewDataSet';
import StudyLibraryLayout from 'Layout/StudyLibraryLayout';
import GlobalLibraryLayout from 'Layout/GlobalLibraryLayout';
import StudyConfigLayout from 'Layout/StudyConfigLayout';
import '../../style/layout.css'

const routes = [
  // {
  //   path: '/data-standard-library/global-library',
  //   component: GlobalLibrary,
  //   layout: AppLayout,
  //   exact: true,
  //   authentication: true
  // },
  {
    path: '/data-standard-library/global-library',
    component: GlobalLibrary,
    layout: GlobalLibraryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/add-global-library',
    component: AddGlobalLibrary,
    layout: SideBarLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/add-study-library',
    component: AddStudyLibrary,
    layout: SideBarLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/add-study-library/add-new-dataset',
    component: AddNewDataSet,
    layout: AddSQLQueryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/edit-study-library/add-new-dataset',
    component: AddNewDataSet,
    layout: AddSQLQueryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/edit-study-library',
    component: AddStudyLibrary,
    layout: SideBarLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/edit-global-library',
    component: AddGlobalLibrary,
    layout: SideBarLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/study-library',
    component: StudyLibrary,
    layout: StudyLibraryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/data-standard-library/source-configuration',
    component: StudyConfigLibrary,
    layout: StudyConfigLayout,
    exact: true,
    authentication: true
  }
];

export default routes;
