import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { dateFormatByType } from 'Utils';
import ContentHolder from './ContentHolder';

const MappingSpecCard = ({ mappingSpecDetails }) => {
  const {
    productMnemonic,
    createdBy,
    createdDate,
    description,
    targetDataModelName,
    mappingSpecVersionName
  } = mappingSpecDetails;

  return (
    <Card style={{ width: '100%', marginBottom: '2rem' }}>
      <Grid container spacing={2} style={{ margin: '2%' }}>
        <Grid item xs={3}>
          <Typography variant="title1">
            <p style={{ marginBottom: '0.5rem' }}>Mapping Spec</p>
            Overview
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Grid item xs={3}>
              <ContentHolder title={'Product Mnemonic'} value={productMnemonic} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Description'} value={description ? description : ''} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Mapping Spec Version'} value={mappingSpecVersionName} />
            </Grid>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
            <Grid item xs={3}>
              <ContentHolder title={'Created by'} value={createdBy} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Target Data Model'} value={targetDataModelName} />
            </Grid>
            <Grid item xs={3}>
              <ContentHolder title={'Date Created'} value={dateFormatByType(createdDate)} />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MappingSpecCard;
