/*eslint-disable */
import React, { useContext } from 'react';
import { useEffect } from 'react';
import TreeViewExample from 'Layout/RulesLayout/Components/TreeView';
import LinearProgress from 'apollo-react/components/LinearProgress';
import { MasteringRulesContext } from '../../MasteringRulesProvider';
export const MiddlePanel = () => {
  const context = useContext(MasteringRulesContext);
  const {
    treeView,
    selected,
    setSelected,
    expanded,
    setExpanded,
    setSelectedNodeText,
    setParentNodeID,
    setParentNodeName,
    parentNodeName,
    changesNotSaved,
    setChangesNotSaved
  } = context;

  const handleToggle = (e, nodeIds) => {
    if (nodeIds.includes(expanded[0])) {
      const index = nodeIds.indexOf(expanded[0]);
      if (index > -1) {
        nodeIds.splice(index, 1);
        setExpanded(nodeIds);
      }
    } else {
      setExpanded(nodeIds);
    }
  };

  const handleSelect = (e, nodeIds) => {
    if (
      changesNotSaved?.length > 0 &&
      !['Subject Setting', 'Visit Setting'].includes(e.target.outerText)
    ) {
      let confirmationMessage = 'Changes you made may not be saved.';
      let isConfirmed = confirm(confirmationMessage);

      if (!isConfirmed) return;
      setChangesNotSaved(false);
    }

    setParentNodeID('');
    setParentNodeName('');
    if (e.target.outerText === 'General Setting') {
      setParentNodeID(nodeIds);
      setParentNodeName(e.target.outerText);
      setSelectedNodeText('');
      setSelected(nodeIds);
      setExpanded('');
    } else if (e.target.outerText === 'Subject Setting' || e.target.outerText === 'Visit Setting') {
      setParentNodeID(nodeIds);
      setParentNodeName(e.target.outerText);
      setSelectedNodeText('');
      setSelected(nodeIds);
    } else {
      if (!e.target.outerText) {
        let parentEl = treeView.find((_node) => _node.nodeId === nodeIds[0]);
        if (parentEl) {
          setParentNodeID(nodeIds);
          setParentNodeName(parentEl.nodeText);
          setSelectedNodeText('');
          setSelected(nodeIds);
        }
      } else {
        treeView.map((el) => {
          if (el.childNodeList.length > 0 && el.childNodeList.includes(nodeIds[0])) {
            setParentNodeID(el.nodeId);
            setParentNodeName(el.nodeText);
            setSelected(nodeIds);
            setSelectedNodeText(e.target.outerText);
          }
        });
      }
    }
  };

  useEffect(() => {
    setExpanded([2]);
  }, []);

  return (
    <div
      style={{
        overflowY: 'auto',
        position: 'sticky',
        top: '0px',
        height: 'fit-content',
        maxHeight: 'calc(100vh - 113px)'
      }}>
      <div
        style={{
          padding: '1.5rem 0',
          borderBottom: '1px solid #e9e9e9',
          margin: '0 2rem',
          marginBottom: '1rem'
        }}>
        <span
          style={{
            fontSize: '16px',
            fontWeight: 500
          }}>
          Mastering Rules
        </span>
        {/* <LinearProgress variant="determinate" value={50} style={{ marginTop: '10px' }} /> */}
      </div>

      <TreeViewExample
        data={treeView}
        handleSelect={handleSelect}
        handleToggle={handleToggle}
        expanded={expanded}
        selected={selected}
      />
    </div>
  );
};

export default MiddlePanel;
