import React from 'react';
import PropTypes from 'prop-types';
import ApolloTextField from 'apollo-react/components/TextField';

const TextField = (props) => {
  const { onChange, value, label, placeholder, multiline, fullWidth, name, error, helperText } =
    props;
  return (
    <ApolloTextField
      {...props}
      label={label}
      placeholder={placeholder}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      onChange={onChange}
      value={value}
      error={error}
      helperText={helperText}
    />
  );
};

TextField.defaultProps = {
  onChange: () => {},
  label: 'TextField',
  placeholder: 'Enter Text',
  multiline: false,
  fullWidth: true,
  error: false
};

TextField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default TextField;
