import { Box, Card } from '@mui/material';
import { Button, Switch, Typography } from 'apollo-react';
import React, { useEffect, useState } from 'react';
import VisitCondition from './VisitCondition';
import { makeStyles } from '@mui/styles';

import Radio from 'apollo-react/components/Radio';
import RadioGroup from 'apollo-react/components/RadioGroup';
import Grid from 'apollo-react/components/Grid';
import Checkbox from 'apollo-react/components/Checkbox';
import TextField from 'Components/Controls/TextField';

import { SaveDerivedVisitNameData } from 'Redux/Service/MasteringRuleService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Loader from 'apollo-react/components/Loader';
import CustomModal from 'Components/Modal';
import { useStateWithCB } from 'Utils';

const useStyles = makeStyles({
  switch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px',
    marginRight: '5px'
  },

  conditionCard: {
    width: '100%',
    minHeight: '6rem',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.04)',
    backgroundColor: 'white',
    margin: '0 0 1rem 0',
    padding: '0 1rem 0 0',

    '&> span:nth-child(2)': {
      padding: '0rem',
      paddingLeft: '1rem',
      height: '100%'
    },

    '&:hover': {
      border: '1px solid #0768FD'
    }
  },
  textInput: {
    width: '300px',
    marginTop: '0',
    '& >label': { display: 'none' }
  }
});

const previewSampleText = {
  comma: `Visit Name 1, Visit Name 2, Visit Name 3`,
  firstvisit: `Visit Name 1`,
  lastvisit: `Visit Name 3`
};

const DerivedVisitNameConfig = ({
  mappingRuleVersionID,
  derivedVisitSetting,
  derivedVisits,
  userId,
  protocolNumber,
  getDerivedVisitNameData
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [confirmAlertEnabled, showConfirmAlert] = useStateWithCB(false);
  const [loading, setLoading] = useState(false);
  const [doNotMap, setNoMap] = useState(derivedVisitSetting?.isNotMapped === 'Y' || false);
  const [selectedDerivedVisitSettingKey, selectDerivedVisitSettingKey] = useState(
    derivedVisitSetting?.derivedVisitSetting || 'comma'
  );
  const [prefixOrSuffix, setPrefixOrSuffix] = useState(
    derivedVisitSetting?.prefixOrSuffix || 'prefix'
  );
  const [prefixSuffixEnabled, enablePrefixSuffix] = useState(
    Boolean(derivedVisitSetting?.text) || false
  );
  const [prefixSuffixText, setPrefixSuffixText] = useState(derivedVisitSetting?.text || '');

  const getPreviewText = () => {
    const text = [prefixSuffixText, previewSampleText[selectedDerivedVisitSettingKey]];
    return prefixOrSuffix === 'prefix' ? text.join(' ') : text.reverse().join(' ');
  };
  useEffect(() => {
    const { isNotMapped, derivedVisitSettingKey, prefixOrSuffix, text } = derivedVisitSetting;
    setNoMap(isNotMapped === 'Y');
    selectDerivedVisitSettingKey(derivedVisitSettingKey || 'comma');
    enablePrefixSuffix(Boolean(text));
    setPrefixOrSuffix(prefixOrSuffix || 'prefix');
    setPrefixSuffixText(text);
  }, [derivedVisitSetting]);

  const saveDerivedVisitNameData = async (
    isNotMapped,
    derivedVisitSettingKey = '',
    prefixOrSuffix = '',
    text = ''
  ) => {
    setLoading(true);

    const payload = {
      iqCreateDate: new Date().toISOString(),
      iqUpdateDate: new Date().toISOString(),
      iqCreatedBy: userId,
      iqUpdatedBy: userId,
      iqAuditType: 'UPDATE',
      iqAuditDate: new Date().toISOString(),
      iqActiveFlag: true,
      derivedVisitSetting: {
        isNotMapped: isNotMapped ? 'Y' : 'N',
        derivedVisitSettingKey,
        prefixOrSuffix,
        text,
        mappingRuleVersionId: mappingRuleVersionID,
        protocolID: protocolNumber
      }
    };
    const derivedVisitDataResponse = await dispatch(SaveDerivedVisitNameData(payload)).then(
      unwrapResult
    );

    if (derivedVisitDataResponse.data.success) {
      dispatch(showBanner({ variant: 'success', message: derivedVisitDataResponse.data.message }));
      await getDerivedVisitNameData();
    } else {
      dispatch(showBanner({ variant: 'error', message: derivedVisitDataResponse.data.message }));
    }

    setLoading(false);
  };

  const handleSave = () => {
    saveDerivedVisitNameData(
      false,
      selectedDerivedVisitSettingKey,
      prefixSuffixEnabled && prefixSuffixText ? prefixOrSuffix : '',
      prefixSuffixEnabled ? prefixSuffixText : ''
    );
  };

  return (
    <>
      <Card variant={'outlined'} style={{ width: '100%' }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              position: 'relative',
              zIndex: '2000'
            }}>
            <Loader isInner />
          </div>
        ) : null}
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Box>
            <Typography variant={'h3'} style={{ padding: '1rem 1rem 0.25rem 1rem' }}>
              Choose a Visit Condition
            </Typography>
            <Typography style={{ padding: '0.25rem 1rem 1rem 1rem' }}>
              When multiple visits occurred on the same day
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mr={3}>
            <Switch
              onChange={() => {
                doNotMap ? setNoMap(false) : showConfirmAlert(true);
              }}
              checked={doNotMap}
              size="small"
              className={classes.switch}
            />
            <span>Do Not Map</span>
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} style={{ pointerEvents: doNotMap && 'none' }}>
          <Box width={'50%'} padding={'0 0.5rem 0 1rem'}>
            <RadioGroup
              aria-label="label"
              name="label"
              value={selectedDerivedVisitSettingKey}
              onChange={(e) => selectDerivedVisitSettingKey(e.target.value)}>
              <Grid container>
                {derivedVisits.map((condition, index) => (
                  <Radio
                    key={`env-${index}`}
                    value={condition.derivedVisitSettingKey}
                    label={
                      <VisitCondition
                        {...condition}
                        selected={
                          condition.derivedVisitSettingKey === selectedDerivedVisitSettingKey
                        }
                        horizontal
                      />
                    }
                    className={classes.conditionCard}
                    style={
                      condition.derivedVisitSettingKey === selectedDerivedVisitSettingKey
                        ? {
                            backgroundColor: '#ECF3FF',
                            borderColor: '#0768FD',
                            opacity: doNotMap ? '0.5' : '1'
                          }
                        : {
                            opacity: doNotMap ? '0.5' : '1'
                          }
                    }
                  />
                ))}
              </Grid>
            </RadioGroup>
          </Box>
          <Box width={'50%'} padding={'0 1rem 0 0.5rem'}>
            <Card
              variant={'outlined'}
              style={{
                height: 'calc(100% - 1rem)',
                boxShadow: '0 4px 16px 0 rgba(0,0,0,0.04)',
                padding: '1rem',
                opacity: doNotMap ? '0.5' : '1'
              }}>
              <Typography
                variant={'h4'}
                style={{ fontWeight: '600', color: '#444444', marginBottom: '1rem' }}>
                Preview
              </Typography>

              <Typography
                style={{
                  fontWeight: '500',
                  color: '#595959',
                  whiteSpace: 'pre-line',
                  marginBottom: '1rem'
                }}>{`Example:
            Visit Name 1
            Visit Name 2
            Visit Name 3`}</Typography>

              <Typography
                style={{
                  fontWeight: '500',
                  color: '#595959',
                  whiteSpace: 'pre-line'
                }}>{`Sample Output`}</Typography>

              <Typography
                style={{
                  fontWeight: '500',
                  backgroundColor: '#F2F2F2',
                  border: '1px solid #D9D9D9',
                  color: '#595959',
                  whiteSpace: 'pre-line',
                  borderRadius: '4px',
                  padding: '0.5rem',
                  minHeight: '100px'
                }}>
                {getPreviewText()}
              </Typography>
            </Card>
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} padding={'0 1rem 1rem 1rem'}>
          <Box width={'50%'}>
            <Checkbox
              label="Add prefix or suffix to visit name"
              checked={prefixSuffixEnabled}
              onChange={() => {
                enablePrefixSuffix(!prefixSuffixEnabled);
                setPrefixOrSuffix('prefix');
                setPrefixSuffixText('');
              }}
              disabled={doNotMap}
            />
            {prefixSuffixEnabled ? (
              <Box padding={'0 1rem'}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Radio
                    value="prefix"
                    label="Prefix"
                    checked={prefixOrSuffix === 'prefix'}
                    onChange={() => setPrefixOrSuffix('prefix')}
                  />
                  <Radio
                    value="suffix"
                    label="Suffix"
                    checked={prefixOrSuffix === 'suffix'}
                    onChange={() => setPrefixOrSuffix('suffix')}
                  />
                </Box>
                <TextField
                  className={classes.textInput}
                  value={prefixSuffixText}
                  onChange={(e) => setPrefixSuffixText(e.target.value)}
                />
              </Box>
            ) : null}
          </Box>

          <Box width={'50%'} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'}>
            <Button variant={'primary'} onClick={handleSave} disabled={doNotMap}>
              Save
            </Button>
          </Box>
        </Box>
      </Card>

      <CustomModal
        display={confirmAlertEnabled}
        title={'Warning'}
        message={'Saved Derived Visit Name configuration would be cleared!'}
        variant={'warning'}
        buttonPrimaryLabel={'Save'}
        handlePrimaryAction={() => {
          showConfirmAlert(false, () => saveDerivedVisitNameData(true));
        }}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => showConfirmAlert(false)}
      />
    </>
  );
};

export default DerivedVisitNameConfig;
