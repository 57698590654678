import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Modal from 'apollo-react/components/Modal';
import Typography from 'apollo-react/components/Typography';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setCacheFlag } from 'Redux/Slice/DomainPeviewSlice';

const DomainHeader = (props) => {
  const { domainCode, mappingRuleVersionID, nodeId } = props;
  const [isModel, setIsModel] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const SUBJECT_SETTING_PREVIEW = 'subject-setting-preview';
  const VISIT_SETTING_PREVIEW = 'visit-setting-preview';
  const DOMAIN_PREVIEW = 'domain-preview';
  const MDS_DOMAIN_PREVIEW = 'mds-domain-preview';

  const dispatch = useDispatch();
  const handleReturnButton = () => {
    setIsModel(!isModel);
  };

  const handleClose = () => {
    setIsModel(!isModel);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
      <Modal
        open={isModel}
        variant="warning"
        onClose={() => handleClose()}
        title="Leave Preview?"
        message="Are you sure you want to leave this page?"
        buttonProps={[
          { label: 'Cancel', onClick: () => handleClose() },
          {
            label: 'Ok',
            onClick: () => {
              // eslint-disable-next-line no-constant-condition
              if (currentPath === SUBJECT_SETTING_PREVIEW) {
                navigate(
                  `/product-designer/rule-editor/${mappingRuleVersionID}/mastering-rules`,
                  { state: { parentNodeName: 'Subject Setting' } }
                );
              } else if (currentPath === VISIT_SETTING_PREVIEW) {
                navigate(
                  `/product-designer/rule-editor/${mappingRuleVersionID}/mastering-rules`,
                  { state: { parentNodeName: 'Visit Setting' } }
                );
              } else if (currentPath === DOMAIN_PREVIEW) {
                navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/domain-rules`, {
                  state: {
                    domainCode,
                    nodeId
                  }
                });
              } else if (currentPath === MDS_DOMAIN_PREVIEW) {
                navigate(
                  `/product-designer/rule-editor/${mappingRuleVersionID}/mds-domain-rules`,
                  {
                    state: {
                      domainCode
                    }
                  }
                );
              }
              // showPreview(false)
              dispatch(setCacheFlag(false));
            }
          }
        ]}
        id="warning"
      />
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Button
          variant="secondary"
          size="small"
          icon={ChevronLeft}
          style={{ marginRight: 10, color: '#0768FD', marginBottom: '10px' }}
          onClick={handleReturnButton}>
          {currentPath === SUBJECT_SETTING_PREVIEW
            ? 'Return to Subject Settings'
            : currentPath === VISIT_SETTING_PREVIEW
              ? 'Return to Visit Settings'
              : (currentPath === DOMAIN_PREVIEW || currentPath === MDS_DOMAIN_PREVIEW) &&
              'Return to Domain Rules'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="title1" gutterBottom>
          {currentPath === SUBJECT_SETTING_PREVIEW
            ? 'Subject Settings Preview'
            : currentPath === VISIT_SETTING_PREVIEW
              ? 'Visit Settings Preview'
              : (currentPath === DOMAIN_PREVIEW || currentPath === MDS_DOMAIN_PREVIEW) &&
              'Domain Preview'}
        </Typography>
        <Typography variant="title2" gutterBottom>
          {domainCode}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DomainHeader;
