export const DATA_FLOW_TYPES = Object.freeze({
  TEST: 'Test (Non Prod)',
  PROD: 'Prod'
});

export const DATAFLOWTYPE_PAYLOAD_MAPPING = Object.freeze({
  'Test (Non Prod)': 'TEST',
  'Prod': 'PROD'
});

export const DATAFLOWTYPE_PAYLOAD_VALUES = Object.freeze({
  TEST: 'TEST',
  PROD: 'PROD'
});
