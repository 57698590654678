/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// eslint-disable-next-line

const API_URL = process.env.REACT_APP_API_URL;

export const GetProductDataSources = createAsyncThunk(
  'RuleEditor/GetProductDataSources',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetProductDataSources`, payload);
  }
);

export const GetProductDataSourcesByMappingRuleVersionID = createAsyncThunk(
  'RuleEditor/GetProductDataSourcesMasteringRuleVersionID',
  async (mappingRuleVersionID) => {
    return await axios.get(
      `${API_URL}/api/RuleEditor/GetProductDataSourcesByMappingRuleID?MappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const SaveDataSources = createAsyncThunk('RuleEditor/SaveDataSources', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/SaveRuleProductDataSources`, payload);
});

export const GetWorkflowModel = createAsyncThunk('RuleEditor/GetWorkflowModel', async (payload) => {
  const response = await axios.get(
    `${API_URL}/api/RuleEditor/GetWorkFlowModel?LibraryType=${payload}`
  );
  if (response && response.data && response.data.success) {
    return response.data;
  } else {
    return false;
  }
});
