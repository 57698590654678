import ChartBar from 'apollo-react-icons/ChartBar';
import Typography from 'apollo-react/components/Typography';
import { neutral7 } from 'apollo-react/colors';

const EmptyTableTypographyStyle = { color: neutral7, lineHeight: '24px', margin: '0.5rem' };
const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959'
};

export const EmptyTablePlaceholder = () => (
  <>
    <ChartBar fontSize="large" style={EmptyTableTypographyStyle} />
    <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
      No data to display
    </Typography>
  </>
);
