/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

// import RulesJson from './../../MockApis/rule.json'

export const LoaderSlice = createSlice({
  name: 'Loader',
  initialState: {
    loading: false
  },
  reducers: {
    // getloaderComponent(state, { payload }) {
    //   alert('getloaderComponent');
    //   state.loading = payload;
    // },
    showLoader(state) {
      state.loading = true;
    },
    closeLoader(state) {
      state.loading = false;
    }
  }
});

export const { showLoader, closeLoader } = LoaderSlice.actions;
