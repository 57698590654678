/* eslint-disable*/
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import FilterIcon from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import React from 'react';
import TextField from 'apollo-react/components/TextField';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';

const useStyles = makeStyles({
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  }
});

const CustomButtonHeader = ({ toggleFilters }) => {
  return (
    <div>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: 15 }}
        onClick={toggleFilters}>
        Filter
      </Button>
    </div>
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

const PreviewRulesetTable = (props) => {
  const classes = useStyles();
  console.log(props.previewRulesetData);

  const columns = [
    {
      header: 'Domain',
      accessor: 'source',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('source'),
      fixedWidth: false
    },
    {
      header: 'Library Type',
      accessor: 'libraryTypeDisplayName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryTypeDisplayName'),
      fixedWidth: false
    },
    {
      header: 'Library ID',
      accessor: 'libraryID',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryID'),
      fixedWidth: false
    },
    {
      header: 'Library Name',
      accessor: 'libraryName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('libraryName'),
      fixedWidth: false
    },
    {
      header: 'Version',
      accessor: 'libraryVersion',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('libraryVersion'),
      fixedWidth: false
    },

    {
      header: 'Clinical Data Type',
      accessor: 'clinicalDataType',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('clinicalDataType'),
      fixedWidth: false
    },
    {
      header: 'Clinical Data Flow',
      accessor: 'clinicalDataFlow',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('clinicalDataFlow'),
      fixedWidth: false
    }
  ];

  return (
    <Box mt={2} className={classes.tableHorizontalOverflow}>
      <Table
        classes={classes.tableHorizontalOverflow}
        title={'CDR Library'}
        columns={columns}
        rows={props.previewRulesetData}
        // columnSettings={{ enabled: true, frozenColumnsEnabled: true }}
        // CustomHeader={(props) => <CustomButtonHeader {...props} />}
        hasScroll
        maxHeight={650}
        initialSortedColumn="libraryName"
        initialSortOrder="asc"
        rowsPerPageOptions={[10, 20, 50, 100, 'All']}
        tablePaginationProps={{
          // labelDisplayedRows: ({ from, to, count }) =>
          //   `${count === 1 ? 'item' : 'items'} ${from}-${to} of ${count}`,
          truncate: true
        }}
        defaultRowsPerPage={10}
      />
    </Box>
  );
};

export default PreviewRulesetTable;
