/*eslint-disable */
import React, { useImperativeHandle } from 'react';
import Editor from '@monaco-editor/react';
import Box from 'apollo-react/components/Box';
import { neutral1 } from 'apollo-react/colors';

const PreSQLEditor = (props) => {
  const { sqlQuery, onQueryChange, readOnly } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // pointerEvents: readOnly ? 'none' : undefined,
        '& section ': { pt: '5px', pb: '10px' },
        '& section, section >*>*': {
          backgroundColor: readOnly ? neutral1 : undefined
        },
        resize: 'vertical',
        height: '150px',
        overflow: 'auto'
      }}>
      <Editor
        defaultLanguage="sql"
        value={sqlQuery}
        onChange={onQueryChange}
        options={{ minimap: { enabled: false }, readOnly }}
      />
    </Box>
  );
};

export default PreSQLEditor;
