/* eslint-disable */
import { unwrapResult } from '@reduxjs/toolkit';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import IconButton from 'apollo-react/components/IconButton';
import { neutral8 } from 'apollo-react/colors';
// Icons
import Button from 'apollo-react/components/Button';

// UI Imports
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import Table from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import Loader from 'Components/Loader/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCDRECRFDatasetTrials, GetStudyLibrarySourcesByTypes } from 'Redux/Service/DomainPreviewService';
import { GetProductDataSourcesByMappingRuleID } from 'Redux/Service/PublishMappingSpecService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setselectedDomainSourceData } from 'Redux/Slice/DomainPeviewSlice';
import { setSelectSourceDeatils, setSelectedSourceData } from 'Redux/Slice/PublishMappingSpecSlice';
import { uuidv4 } from 'Utils';
// CSS
import './SourceData.css';
import { handlePayload } from '../Components/ValidateSourcePayload';
import { DATA_FLOW_TYPES, DATAFLOWTYPE_PAYLOAD_VALUES } from 'Constants/DataFlowTypes';
import useDisplayName from 'Utils/useDisplayName';
import { DATA_SET_TYPES } from 'Constants/DataSetTypes';

const EditTrailName = (props) => {
  const { handleNext, type, handleBack } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [eCRFTrialNames,setECRFTrialNames] = useState([]);
  const [disableNextButton,setDisableNextButton] = useState(false);
  const { selectSourceDetails, selectedSourceData, mappingSpecDetails } = useSelector(
    (state) => state.PublishMappingSpecData
  );
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const [CDRType, setCDRType] = React.useState();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const screenSizeRef = useRef(null);
  const getDisplayName = useDisplayName();

  let edcArray = [];
  let cdrArray = [
    { value: DATAFLOWTYPE_PAYLOAD_VALUES.TEST, label: DATA_FLOW_TYPES.TEST },
    { value: DATAFLOWTYPE_PAYLOAD_VALUES.PROD, label: DATA_FLOW_TYPES.PROD }
  ];
  let ecgArray = [];
  let labArray = [];
  let newArray = [];
  let subCdrSources = [];
  let cdrSourcesObj = {};

  const getProductDataSourcesByMappingRuleVersionID = async (
    edcArray,
    labArray,
    ecgArray,
    cdrSourcesObj
  ) => {
    let selectedData = selectSourceDetails.masterDataSources.filter(
      (item) => item.selected && item.connection
    );

    let SelectedSources = await dispatch(
      GetProductDataSourcesByMappingRuleID({
        mappingRuleVersionID: selectSourceDetails.mappingRuleVersionID,
        isDummySource: selectSourceDetails?.isDataSourceDummy
      })
    ).then(unwrapResult);
    const cdrDatasource = {};
    let selectedTrialNames = [];
    let selectedEcrfTrialName = '';
    selectedSourceData?.map((item) => {
      selectedTrialNames[item.sourceName] = item.trialName;
      selectedEcrfTrialName = selectedEcrfTrialName || item.ecrfTrialName;
    });
    selectedData.map((eachSource) => {
      SelectedSources.data.ruleStudyLibrary.map((library) => {
        if (
          library.isMappingRuleConfigured &&
          library.dataConfigSourceName ===
            (eachSource.parentSourceName ? eachSource.parentSourceName : eachSource.sourceName)
        ) {
          if (library.dataConfigSourceName === 'CDR') {
            setCDRType(library.isCDRTestFlow);
          }
          if (library.dataConfigSourceName === 'CDR') {
            if (!Object.keys(cdrDatasource).length > 0) {
              Object.assign(cdrDatasource, eachSource, {
                trialName:
                  selectedTrialNames[library.dataConfigSourceName] ||
                  (library.isCDRTestFlow
                    ? DATAFLOWTYPE_PAYLOAD_VALUES.TEST
                    : DATAFLOWTYPE_PAYLOAD_VALUES.PROD),
                sourceName: library.dataConfigSourceName,
                trialsList: [],
                dataSets: Object.values(cdrSourcesObj),
                databaseName: Object.keys(cdrSourcesObj)[0],
                dataSetType: library.dataSetType,
                ecrfTrialName: selectedEcrfTrialName || library.ecrfTrialName,
                id: uuidv4()
              });
            } else if (
              cdrDatasource.dataSetType !== DATA_SET_TYPES.eCRF_VIEW.NAME &&
              library.dataSetType === DATA_SET_TYPES.eCRF_VIEW.NAME
            ) {
              cdrDatasource.dataSetType = library.dataSetType;
              cdrDatasource.ecrfTrialName = selectedEcrfTrialName || library.ecrfTrialName;
            }
          } else {
            newArray.push(
              Object.assign({}, eachSource, {
                trialName: selectedTrialNames[library.dataConfigSourceName] || library.source,
                isCustomLibrary: library.isCustomLibrary,
                trialsList:
                  library.dataConfigSourceName === 'EDC_ODM'
                    ? edcArray
                    : library.dataConfigSourceName === 'Q2LAB'
                    ? labArray
                    : library.dataConfigSourceName === 'QECG'
                    ? ecgArray
                    : [],
                id: uuidv4()
              })
            );
          }
        }
      });
    });
    if (cdrDatasource.dataSetType === DATA_SET_TYPES.eCRF_VIEW.NAME) {
      const ecrfTrialNamesResp = await dispatch(
        GetCDRECRFDatasetTrials(protocol.protocolNumber)
      ).then(unwrapResult);
      if (
        ecrfTrialNamesResp?.data?.success === true &&
        ecrfTrialNamesResp.data.ecrfTrialNames?.length > 0
      ) {
        setECRFTrialNames(ecrfTrialNamesResp.data.ecrfTrialNames);
      }
    }
    if (Object.keys(cdrDatasource).length > 0) newArray.push(cdrDatasource);
    dispatch(setSelectedSourceData(newArray));
    validateEcrfTrialName(cdrDatasource);
    setLoading(false);
  };

  const getTrialNames = async (cdrSourcesObj) => {
    let filteredArray = handlePayload(
      selectSourceDetails,
      undefined,
      '',
      undefined,
      undefined,
      selectedSourceData,
      CDRType
    ).previewSelectedDatasources.filter((item) => item.sourceName !== 'CDR');

    let payload = {
      protocolNumber: protocol.protocolNumber,
      previewDataSources: filteredArray
    };

    let trialsList = await dispatch(GetStudyLibrarySourcesByTypes(payload)).then(unwrapResult);
    let item = trialsList.data;
    if (trialsList.data.success) {
      item.odmMetedataSources?.forEach((trialNames) => {
        if (!edcArray.length) {
          edcArray.push(trialNames.edcTrialName);
        } else if (edcArray.length > 0 && !edcArray.includes(trialNames.edcTrialName)) {
          edcArray.push(trialNames.edcTrialName);
        }
      });
      item.q2LABSources?.forEach((labItem) => {
        if (!labArray.length) {
          labArray.push(labItem.vendorAssignedName);
        } else if (labArray.length > 0 && !labArray.includes(labItem.vendorAssignedName)) {
          labArray.push(labItem.vendorAssignedName);
        }
      });
      item.qecgSources?.forEach((ecgItem) => {
        if (!ecgArray.length) {
          ecgArray.push(ecgItem.vendorAssignedName);
        } else if (ecgArray.length > 0 && !ecgArray.includes(ecgItem.vendorAssignedName)) {
          ecgArray.push(ecgItem.vendorAssignedName);
        }
      });
      getProductDataSourcesByMappingRuleVersionID(edcArray, labArray, ecgArray, cdrSourcesObj);
    } else {
      dispatch(showBanner({ variant: 'error', message: trialsList.data.message }));
    }
  };

  const getSubSourcesForCDR = async () => {
    let databaseName = '';
    selectSourceDetails.masterDataSources.forEach((item) => {
      if (item.sourceName === 'CDR' && item.parentSourceName === undefined) {
        databaseName = item.databaseName;
      }
      if (item.parentSourceName === 'CDR') {
        if (item.selected) {
          subCdrSources.push(!subCdrSources.includes(item.sourceName) && item.sourceName);
          cdrSourcesObj[databaseName] = subCdrSources;
        }
      }
    });
    getTrialNames(cdrSourcesObj);
  };

  useEffect(() => {
    getSubSourcesForCDR();
  }, []);

  const handleNextButton = () => {
    if (type === 'Publish') {
      dispatch(setSelectSourceDeatils(selectSourceDetails));
    } else {
      dispatch(setselectedDomainSourceData(selectSourceDetails));
    }
    handleNext();
  };

  const updateRow = (row, updateObj) => {
    let modifiedArray = [];
    let cdrDatasource = {};
    selectedSourceData.map((item) => {
      if (item.sourceName === row.sourceName) {
        let newItem = {};
        Object.assign(newItem, item, {
          trialName: updateObj.trialName ? updateObj.trialName : item.trialName,
          ecrfTrialName: updateObj.ecrfTrialName
            ? updateObj.ecrfTrialName
            : item.sourceName !== 'CDR' ||
              row.dataSetType !== DATA_SET_TYPES.eCRF_VIEW.NAME ||
              updateObj.trialName !== DATAFLOWTYPE_PAYLOAD_VALUES.TEST
            ? ''
            : item.ecrfTrialName
        });
        modifiedArray.push(newItem);
        if (item.sourceName === 'CDR') {
          cdrDatasource = { ...newItem };
        }
      } else {
        modifiedArray.push(item);
      }
    });
    validateEcrfTrialName(cdrDatasource);
    dispatch(setSelectedSourceData(modifiedArray));
  };

  const validateEcrfTrialName = (cdrDatasource) => {
    if (
      cdrDatasource.sourceName === 'CDR' &&
      cdrDatasource.dataSetType === DATA_SET_TYPES.eCRF_VIEW.NAME
    ) {
      if (
        cdrDatasource.trialName === DATAFLOWTYPE_PAYLOAD_VALUES.TEST &&
        !cdrDatasource.ecrfTrialName &&
        !disableNextButton
      ) {
        setDisableNextButton(true);
      } else if (disableNextButton) {
        setDisableNextButton(false);
      }
    }
  };

  const eCRFTrialNameCell = ({ row }) => {
    const isEnabled =
      row.trialName === DATAFLOWTYPE_PAYLOAD_VALUES.TEST &&
      row.dataSetType === DATA_SET_TYPES.eCRF_VIEW.NAME;
    return row.parentSourceName === 'CDR' ? (
      <Tooltip
        title={
          !isEnabled &&
          `eCRF Trial name selection applicable only for ${DATA_SET_TYPES.eCRF_VIEW.NAME} datasets with Data Flow Type ${DATA_FLOW_TYPES.TEST}`
        }>
        <div className="db_domain_preview" style={{ width: 'fit-content' }}>
          <Select
            style={{ width: '274px' }}
            value={row.trialName === DATAFLOWTYPE_PAYLOAD_VALUES.TEST ? row.ecrfTrialName : ''}
            onChange={(e) => updateRow(row, { ecrfTrialName: e.target.value })}
            disabled={!isEnabled}
            data-testid="ecrftrial-select">
            {eCRFTrialNames.map((name, index) => (
              <MenuItem value={name} key={index}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Tooltip>
    ) : (
      <div>N/A</div>
    );
  };

  const DatabaseCell = ({ row }) => {
    return row?.parentSourceName === 'CDR' ? (
      <div className="db_domain_preview" style={{ paddingRight: '20px' }}>
        <Select
          style={{ width: '274px' }}
          value={row.trialName}
          onChange={(e) => updateRow(row, { trialName: e.target.value })}
          data-testid="cdrtrial-select"
          // disabled={mappingSpecDetails.targetDataModelType === 'Ruleset' && type === 'Publish'}
        >
          {cdrArray.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    ) : row?.sourceName !== 'CDR Tabular' ? (
      <div className="db_domain_preview" style={{ paddingRight: '20px' }}>
        <Select
          style={{ width: '274px' }}
          value={row.trialName}
          onChange={(e) => updateRow(row, { trialName: e.target.value })}
          disabled={
            !row?.isCustomLibrary &&
            mappingSpecDetails.targetDataModelType === 'Ruleset' &&
            type === 'Publish'
          }>
          {(row?.trialsList).map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    ) : null;
  };

  const ExpandCell = ({ row: { dataSets, databaseName, handleToggleRow, expanded, id } }) => {
    return dataSets?.length ? (
      <div style={{ width: 12 }}>
        <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
          <IconButton id="expand" size="small" onClick={() => handleToggleRow(id)}>
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      </div>
    ) : null;
  };

  const DetailRow = ({ row }) => {
    return (
      row.dataSets.length && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', padding: '12px 0px 8px 40px', flexWrap: 'wrap' }}>
            <Typography style={{ fontWeight: 500, color: neutral8, width: '100%' }}>
              {`Data Sets : ${row.dataSets.toString()}`}
            </Typography>
          </Grid>
        </Grid>
      )
    );
  };

  const columns = [
    {
      accessor: 'expand',
      customCell: ExpandCell,
      width: 80
    },
    {
      header: 'Source',
      accessor: 'sourceDisplayName',
      fixedWidth: false
    },
    {
      header: 'Database',
      accessor: 'databaseName',
      fixedWidth: false
    },
    {
      header:
        type === 'Publish' ||
        rowDataForRuleEditor.targetDataModelType === 'CDISC-SDTM' ||
        rowDataForRuleEditor.targetDataModelType === 'IQVIA-CTP'
          ? 'Trial Name/Data Flow Type'
          : 'Trial Name',
      accessor: 'userID',
      customCell: DatabaseCell,
      width: 350,
      fixedWidth: false
    },
    {
      header: 'eCRF Trial Name',
      accessor: 'ecrfTrialName',
      customCell: eCRFTrialNameCell,
      fixedWidth: false
    }
  ];

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  return (
    <React.Fragment>
      <Typography
        variant="title1"
        gutterBottom
        style={{
          fontSize: '20px',
          color: '#000000',
          lineHeight: '48px',
          letterSpacing: 0,
          fontFamily: 'Proxima Nova',
          height: '24px'
        }}>
        Choose Trial Name
      </Typography>
      {loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <Card style={{ marginTop: '3%' }} ref={screenSizeRef}>
          <CardContent style={{ padding: '0px' }}>
            <Grid container style={{ padding: '20px' }}>
              <Grid item xs={6} style={{ display: 'flex' }}>
                <div>
                  <Typography
                    variant="title2"
                    gutterBottom
                    style={{
                      fontSize: '16px',
                      color: '#000000',
                      fontWeight: '600',
                      lineHeight: '24px',
                      fontFamily: 'Proxima Nova'
                    }}>
                    Source Configuration
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Select the sources you want to configure for this study
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Table
                  columns={columns}
                  rows={
                    selectedSourceData &&
                    selectedSourceData.map((row) => {
                      return {
                        ...row,
                        key: row.id,
                        expanded: expandedRows.includes(row.id) ? true : false,
                        handleToggleRow,
                        sourceDisplayName: getDisplayName(row.sourceName)
                      };
                    })
                  }
                  // rows={selectedSourceData}
                  rowsPerPageOptions={selectedSourceData?.length > 5 ? ['All'] : [5, 10, 'All']}
                  ExpandableComponent={DetailRow}
                  hidePagination
                  hasScroll
                  maxHeight={650}
                  tablePaginationProps={{
                    labelDisplayedRows: ({ from, to, count }) =>
                      `${count === 1 ? 'Connection' : 'Connections'} ${from}-${to} of ${count}`,
                    truncate: true
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="text"
          size="small"
          style={{ padding: 0, visibility: `${type !== 'Publish' ? 'hidden' : 'visible'}` }}
          onClick={handleBack}>
          Previous
        </Button>
        <Button
          onClick={handleNextButton}
          variant="primary"
          size="small"
          disabled={disableNextButton}>
          {'Next'}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default EditTrailName;
