import React, { useEffect } from 'react';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import { BannerExample } from 'Components/Banner/Banner';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import { getProtocol } from '../../Redux/Slice/StudyLibrarySlice';

const useStyles = makeStyles({
  divContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: `calc(100vh - 113px)`
  }
});

export const DashboardLayout = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { protocol = {} } = useSelector((state) => state.StudyLibraryData);

  let path = location.pathname;

  useEffect(() => {
    if (path === '/dashboard') {
      dispatch(getProtocol({}));
    }
  }, [path]);

  return (
    <React.Fragment>
      <Header />
      {Object.keys(protocol).length !== 0 && <ProjectStudyHeader />}
      <BannerExample />
      <div className={classes.divContainer}>
        <div style={{ margin: '0 auto', width: '100%' }}>{props.children}</div>
        <Footer width={'100%'} />
      </div>
    </React.Fragment>
  );
};
