/*eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import ClipboardSearch from 'apollo-react-icons/ClipboardSearch';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import EllipsisVertical from 'apollo-react-icons/EllipsisVertical';
import Button from 'apollo-react/components/Button';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import { DomainRulesContext } from '../../DomainRules/DomainRulesProvider';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import { generateErrorString, getFormsAndItems, getHtmlString } from 'Utils';
import { methodsAndConstantsMapping } from '../../../MethodandConstantMapping';
import Search from 'apollo-react-icons/Search';

const FilterExpression = ({
  defaultForm,
  sourceFieldList = [],
  expression,
  handleExpressionUpdate,
  id: expId,
  index,
  inProgress,
  isValid,
  checkExpLength,
  passStatusFlag,
  result,
  hideViewCodelist,
  setCodelistEditorDisplay
}) => {
  const contextData = useContext(DomainRulesContext);
  const {
    setConfirmAlert,
    methodsConstants,
    expressionTemplates,
    displaySearchFields,
    formatedFormItemData
  } = contextData;

  const [sourceField, setSourceField] = useState();
  const [caretPosition, setCaretPosition] = useState(0);
  const [searchFieldFormItem, setSearchFieldFormItem] = useState();

  useEffect(() => {
    passStatusFlag(inProgress, expression);
  }, [expression, inProgress]);

  const selectClasses = makeStyles({
    hint: {
      color: '#0768FD'
    },
    menuPlaceHolder: {
      '&:hover': {
        color: 'blue'
      }
    }
  })();

  const SEARCH_FIELDS = 'Search fields';
  const menuItems = [
    {
      text: 'Edit expression',
      onClick: () => handleExpressionUpdate('edit-exp', expId)
    },
    {
      text: 'Delete expression',
      onClick: () => {
        setConfirmAlert({
          enabled: true,
          variant: 'warning',
          title: 'Warning',
          message: 'Are you sure you want to delete this expression?',
          onConfirm: () => {
            handleExpressionUpdate('delete-exp', expId);
            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      },
      disabled: checkExpLength
    }
  ];

  const handleSourceFieldChange = (e) => {
    if (sourceField !== e.target.value && e.target.value && e.target.value !== SEARCH_FIELDS) {
      const exp = `${expression.slice(0, caretPosition)}[${defaultForm}].[${
        e.target.value
      }]${expression.slice(caretPosition)}`;
      setCaretPosition(exp.length);
      handleExpressionUpdate('save-exp', expId, exp);
      setSearchFieldFormItem(e.target.value);
    } else if (e.target.value === SEARCH_FIELDS) {
      displaySearchFields({
        enabled: true,
        onAdd: (formName, itemName, sourceName) => {
          const exp = `${expression.slice(
            0,
            caretPosition
          )}[${formName}].[${itemName}]${expression.slice(caretPosition)}`;
          setCaretPosition(exp.length);
          handleExpressionUpdate('save-source-name', expId, exp, sourceName);
          setSearchFieldFormItem(`${formName}/${itemName}`);
          displaySearchFields({ enabled: false });
        },
        onCancel: () => {
          displaySearchFields({ enabled: false });
        }
      });
    }
  };

  const handleMethodSelect = (e) => {
    const method = methodsAndConstantsMapping[e.target.value];
    const exp = `${expression.slice(0, caretPosition)}${method}${expression.slice(caretPosition)}`;
    handleExpressionUpdate('save-exp', expId, exp);
    setCaretPosition(exp.length);
  };

  const handleExpressionTemplateSelect = (e) => {
    const exp = `${expression.slice(0, caretPosition)}${e.target.value}${expression.slice(
      caretPosition
    )}`;
    handleExpressionUpdate('save-exp', expId, exp);
    setCaretPosition(exp.length);
  };

  const handleViewCodelist = () => {
    const fetchedFormItems = getFormsAndItems(expression, formatedFormItemData, defaultForm);
    setCodelistEditorDisplay({ display: true, formItems: fetchedFormItems });
  };

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '1rem 2rem'
      }}>
      <Card
        interactive
        style={{
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1rem 1rem 0rem 1rem'
          }}>
          <span data-testid="express-id">Expression {index + 1}</span>
          {/* <span> */}
          <div>
            {hideViewCodelist ? null : (
              <Button
                variant="secondary"
                onClick={handleViewCodelist}
                size={'small'}
                // disabled={inProgress}
                data-testid="save"
                icon={ClipboardSearch}>
                View Codelist
              </Button>
            )}
            <IconMenuButton
              id="actions"
              menuItems={menuItems}
              disabled={inProgress}
              size="small"
              data-testid={'variable-rules-menu-' + index}>
              <EllipsisVertical />
            </IconMenuButton>
          </div>
          {/* </span> */}
        </Grid>
        {inProgress ? (
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem 1rem',
              alignItems: 'flex-end'
            }}>
            <Grid xs={3} item style={{ marginRight: '1rem' }}>
              <Select
                label="Source Field"
                value={sourceField}
                onChange={handleSourceFieldChange}
                placeholder={searchFieldFormItem || 'Select one...'}
                style={{ width: '100%' }}
                required>
                {sourceFieldList?.items?.map((item, index) => (
                  <MenuItem value={item} key={`SourceField-${index}`}>
                    {item}
                  </MenuItem>
                ))}
                <MenuItem value={SEARCH_FIELDS} key={`SourceField-Serach-Fields`}>
                  <span class={selectClasses?.menuPlaceHolder} style={{ display: 'flex' }}>
                    <Search /> Search fields
                  </span>
                </MenuItem>
              </Select>
            </Grid>
            <Grid xs={3} item style={{ marginRight: '1rem' }}>
              <Select
                onChange={handleMethodSelect}
                placeholder="Add Method/Constant"
                classes={selectClasses}
                style={{ width: '100%' }}>
                {methodsConstants
                  .filter((m) => m !== 'QCVSEQ()')
                  .map((item, index) => (
                    <MenuItem value={item} key={`Method-${index}`}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid xs={3} item style={{ marginRight: '1rem' }}>
              <Select
                onChange={handleExpressionTemplateSelect}
                placeholder="Add Expression Template"
                classes={selectClasses}
                style={{ width: '100%' }}>
                {expressionTemplates
                  .filter((item) => item.templateExpressionType.includes('V'))
                  .map((item, index) => (
                    <MenuItem value={item.templateExpression} key={`Method-${index}`}>
                      {item.templateExpression}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <CustomeTextField
                id="expression"
                onChange={(value) => {
                  handleExpressionUpdate('save-exp', expId, value);
                }}
                onCaretPositionchange={(pos) => setCaretPosition(pos)}
                name="expression"
                data-testid="expression-input"
                error={!isValid}
                helperText={isValid ? null : result?.message}>
                {isValid
                  ? getHtmlString(expression, formatedFormItemData, defaultForm)
                  : generateErrorString(
                      expression,
                      result?.cordinates?.start,
                      result?.cordinates?.end
                    )}
              </CustomeTextField>
            </Grid>
          </Grid>
        ) : (
          <Grid style={{ padding: '1rem' }}>
            <CustomeTextField
              id="expression-preview"
              name="expression-preview"
              data-testid="expression-preview"
              disabled>
              {getHtmlString(expression, formatedFormItemData, defaultForm)}
            </CustomeTextField>
          </Grid>
        )}
      </Card>
    </Grid>
  );
};
export default React.memo(FilterExpression);
