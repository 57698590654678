import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const AIAAS_API_URL = process.env.REACT_APP_AIAAS_API_URL;
const API_URL = process.env.REACT_APP_API_URL;

export const GetAutoMappings = createAsyncThunk(
  'Aiaas/GetAutoMappings',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${AIAAS_API_URL}/cdm_mapper/`, payload, {
        headers: { tenant: payload['CUSTOMER'] }
      });

      return response.data || {};
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const GetAutoMappingConfig = createAsyncThunk(
  'Aiaas/GetAIMLGlobalConfigData',
  async () => await axios.get(`${API_URL}/api/Utility/GetAIMLGlobalConfigData`)
);
