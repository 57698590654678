/*eslint-disable*/
import React from 'react';
import StatusNegative from 'apollo-react-icons/StatusNegative';
import RulesetErrorDisplay from './RulesetErrorDisplay';
import {
  createStringSearchFilter,
  numberSearchFilter,
  compareNumbers,
  compareStrings
} from 'apollo-react/components/Table';
import { TextFieldFilter, IntegerFilter } from 'Pages/Dashboard/Components/Filters';

export const columns = [
  {
    header: 'Domain',
    accessor: 'domain',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('domain')
  },
  {
    header: 'Library Type',
    accessor: 'libraryTypeDisplayName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('libraryTypeDisplayName')
  },
  {
    header: 'Data Set Type',
    accessor: 'datasetTypeDisplayName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('datasetTypeDisplayName')
  },
  {
    header: 'Clinical Data Type',
    accessor: 'clinicalDataType',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('clinicalDataType')
  },
  {
    header: 'Clinical Data Flow',
    accessor: 'clinicalDataFlow',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('clinicalDataFlow')
  },
  {
    header: 'Ruleset',
    accessor: 'ruleset',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('ruleset'),
    customCell: RulesetErrorDisplay
  },
  {
    header: 'Ruleset Version',
    accessor: 'rulesetVersion',
    sortFunction: compareNumbers,
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('rulesetVersion')
  }
];

export const errorColumns = [
  {
    header: '',
    accessor: '',
    customCell: () => {
      return (
        <>
          <StatusNegative size={'small'} style={{ color: '#E82B2B', height: '0.8em' }} />
        </>
      );
    }
  },
  {
    header: 'Variable Name',
    accessor: 'variableName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('variableName')
  },
  {
    header: 'Error Description',
    accessor: 'errorDescription',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('errorDescription')
  }
];
