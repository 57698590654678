/* eslint-disable */
import { useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from 'apollo-react/components/Typography';
import Search from 'apollo-react/components/Search';
import { neutral1 } from 'apollo-react/colors';
import VariableRow from './VariableRow';
import { useEffect } from 'react';
import { EmptyTablePlaceholder } from 'Components/Common/EmptyTablePlaceholder';

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 0px 3px #0002',
    marginTop: '1rem'
  },
  rowsContainer: {},
  columnsHeader: {
    '&>p': {
      padding: '0 1rem',
      verticalAlign: 'middle',
      color: '#595959',
      fontSize: '14px',
      backgroundColor: neutral1
    }
  },
  stickyHeader: {
    position: 'sticky',
    top: '-1px',
    backgroundColor: neutral1,
    zIndex: '2'
  },
  header: {
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '4px 4px 0 0'
  },
  headerTitle: {
    fontWeight: '600'
  },
  headerSubTitle: {
    fontSize: '14px',
    color: '#444'
  }
});

const Variables = (props) => {
  const { title, variablesList, setCodelistEditorDisplay } = props;
  const [searchText, setSearchText] = useState('');
  const [filteredVariablesList, setFilteredVariablesList] = useState(variablesList);
  const [domainVariablesCount, setDomainVariablesCount] = useState([]);
  const classes = useStyles();

  const handleSearch = (e) => {
    const _searchValue = e.target.value.toLowerCase();
    setSearchText(e.target.value);

    setFilteredVariablesList([
      ...variablesList
        .filter((variable) => variable.domain === title)
        .filter((row) =>
          _searchValue ? row?.variable?.toLowerCase().includes(_searchValue) : true
        )
    ]);
  };

  useEffect(() => {
    setSearchText('');
    setFilteredVariablesList(
      title ? variablesList.filter((variable) => variable.domain === title) : variablesList
    );
    setDomainVariablesCount(
      title
        ? variablesList.filter((variable) => variable.domain === title).length
        : variablesList.length
    );
  }, [title, variablesList]);

  const subTitle = useMemo(() => {
    if (domainVariablesCount === filteredVariablesList?.length) {
      return `${domainVariablesCount} Variables`;
    } else {
      return `${filteredVariablesList?.length} out of ${domainVariablesCount} Variables`;
    }
  }, [domainVariablesCount, filteredVariablesList]);

  return (
    <Box className={classes.container}>
      <Box className={classes.stickyHeader}>
        <Box display={'flex'} flexDirection={'column'} className={classes.header}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
            alignItems={'center'}>
            <Box>
              <Typography className={classes.headerTitle}>{title}</Typography>
              <Typography className={classes.headerSubTitle}>{subTitle}</Typography>
            </Box>
            <Box>
              <Search
                size={'small'}
                placeholder="Search Domain Variable"
                fullWidth
                onChange={handleSearch}
                value={searchText}
                style={{ marginTop: '0', minWidth: '240px' }}
              />
            </Box>
          </Box>
        </Box>

        <Box width={'100%'} display={'flex'}>
          <Grid container className={classes.columnsHeader}>
            <Grid component={'p'} xs={3}>
              Domain Variable
            </Grid>
            <Grid component={'p'} xs={9}>
              Expression
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box width={'100%'} display={'flex'} className={classes.rowsContainer}>
        <Grid container style={{ overflow: 'auto', height: 'auto' }}>
          {filteredVariablesList.map((row) => {
            return (
              <VariableRow
                key={row.id}
                {...row}
                setCodelistEditorDisplay={setCodelistEditorDisplay}
              />
            );
          })}
          {!filteredVariablesList?.length ? (
            <Grid
              xs={12}
              style={{
                backgroundColor: 'white',
                minHeight: '200px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <EmptyTablePlaceholder />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Variables;
