import React from 'react';
import Header from 'Components/Header';
import Grid from 'apollo-react/components/Grid';
import './GlobalLibraryLayout.css';
import Footer from 'Components/Footer';
import StandardLibraryHeader from 'Components/StandardLibraryHeader';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import { BannerExample } from 'Components/Banner/Banner';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  divContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: `calc(100vh - 113px)`
  }
});

const GlobalLibraryLayout = (props) => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <BannerExample />
      <Breadcrumb />
      <div className="standard-library">
        <div className="standard-container">
          <StandardLibraryHeader tabValue={1} />
        </div>
      </div>
      <Grid className={classes.divContainer}>
        {props.children}
        <Footer />
      </Grid>
    </>
  );
};
export default GlobalLibraryLayout;
