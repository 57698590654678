/*eslint-disable */
import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import CommaSeperated from 'Images/comma-seperated.svg';
import FirstItem from 'Images/first-item.svg';
import LastItem from 'Images/last-item.svg';

const useStyles = (horizontal) =>
  makeStyles({
    conditionCardContent: {
      display: 'flex',
      flexDirection: horizontal ? 'row' : 'column',
      height: '100%',
      alignItems: 'center',
      justifyContent: horizontal ? 'flex-start' : 'center'
    },
    conditionCardDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: horizontal ? 'flex-start' : 'center',
      justifyContent: 'center',
      margin: '0.5rem',
      marginLeft: horizontal ? '1rem' : '0'
    },
    visitSettingName: {
      fontFamily: 'Proxima Nova',
      fontSize: horizontal ? '1rem' : '1.2rem',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: horizontal ? '1.5rem' : '3rem'
    },
    visitSettingDesc: {
      fontSize: horizontal ? '1rem' : '0.8rem'
    }
  });

const VisitCondition = (props) => {
  const {
    derivedVisitSettingName,
    derivedVisitSettingKey,
    description,
    selected,
    horizontal = true
  } = props;
  const classes = useStyles(horizontal)();

  const icon = useMemo(() => {
    switch (derivedVisitSettingKey) {
      case 'comma': {
        return CommaSeperated;
      }
      case 'firstvisit': {
        return FirstItem;
      }
      case 'lastvisit': {
        return LastItem;
      }
      default: {
        return CommaSeperated;
      }
    }
  }, [derivedVisitSettingKey]);

  return (
    <Box
      className={classes.conditionCardContent}
      style={selected ? { color: '#0768FD' } : { color: '#595959' }}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img
          alt={'env-icon'}
          src={icon}
          style={{
            height: horizontal ? '2.5rem' : '3rem',
            margin: '0.5rem',
            filter: selected
              ? 'invert(0.5) sepia(1) saturate(91) hue-rotate(217deg)'
              : 'invert(0.349) hue-rotate(0deg)'
            // blue -- 'invert(0.5) sepia(1) saturate(91) hue-rotate(217deg)'
            // gray -- 'invert(0.349) hue-rotate(0deg)'
          }}
        />
      </Box>
      <Box className={classes.conditionCardDetails}>
        <span className={classes.visitSettingName}>{derivedVisitSettingName}</span>
        <Box className={classes.visitSettingDesc}>{description}</Box>
      </Box>
    </Box>
  );
};

export default VisitCondition;
